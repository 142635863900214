import React from "react";
//import LogoWithoutText from './assets/MCT-logo-without-text';

import { Drawer, Layout, Menu } from "antd";
import {
  DownloadOutlined,
  HomeOutlined,
  FormOutlined,
  PoweroffOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined,
  TrophyOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "../styles/base.css";
import AdminNav from "./AdminNav";
import { ADMIN, MSSSTUD, STUDE } from "../utils/roles";
import StaffNav from "./StaffNav";
import moment from "moment";
import { APPRV, DOWN, INCOMP, NEW } from "../../sampoorna-siksha/utils/consts";
import { APP_URI } from "../../config/env";
import { MenuItem } from "@material-ui/core";
import StudAcctNav from "./StudAcctNav";
import Student_Identification from "../../assets/forms/Student_Identification.pdf";

const { Sider } = Layout;
const { SubMenu } = Menu;

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
    this.navigate = this.navigate.bind(this);
  }

  componentDidMount() {
    // this.props.fetchConfigByCode('SAMPLE');
  }

  showEdit = () => {
    let startDate = "";
    let endDate = "";
    const sysConfig = this.props.appConfig.systemConfig;
    for (const config of sysConfig) {
      if (config.configCd === "APP_START_DT") {
        startDate = moment(config.configVal);
      } else if (config.configCd === "APP_END_DT") {
        endDate = moment(config.configVal);
      }
    }

    return (
      startDate &&
      endDate &&
      moment().isSameOrAfter(startDate, 'day') &&
      moment().isSameOrBefore(endDate, 'day') &&
      (this.props.studentData.applicationForm.status === INCOMP ||
        this.props.studentData.applicationForm.status === NEW)
    );
  };

  showApply = () => {
    let startDate = "";
    let endDate = "";
    const sysConfig = this.props.appConfig.systemConfig;
    for (const config of sysConfig) {
      if (config.configCd === "APP_START_DT") {
        startDate = moment(config.configVal);
      } else if (config.configCd === "APP_END_DT") {
        endDate = moment(config.configVal);
      }
    }

    return (
      startDate &&
      endDate &&
      moment().isSameOrAfter(startDate, 'day') &&
      moment().isSameOrBefore(endDate, 'day')
    );
  };

  downloadSample = () => {
    const url =
      APP_URI +
      "/upload/download?path=" +
      encodeURIComponent(this.props.appConfig.currentConfig[0]?.configVal);
    window.open(url);
  };

  navigate(item) {
    this.props.toggleMainMenu();
    if (item.key === "faqs") {
      this.props.history.push("/faqs");
    } else if (item.key === "edit" || item.key === "apply") {
      this.props.history.push("/sampurnaSiksha/apply");
    } else if (item.key === "view") {
      this.props.history.push("/sampurnaSiksha/view");
    } else if (item.key === "home") {
      this.props.history.push("/home");
    } else if (item.key === "hallTicket") {
      this.props.history.push("/SampurnaSiksha/hallticket");
    } else if (item.key === "mss") {
      this.props.history.push("/mss");
    } else if (item.key === "mhh") {
      this.props.history.push("/mhh");
    } else if (item.key === "mnn") {
      this.props.history.push("/mnn");
    }  else if (item.key === "mvu") {
      this.props.history.push("/mvu");
    } else if (item.key === "login") {
      this.props.toggleLoginModal(true);
    } else {
      this.props.history.push(`/${item.key}`);
    }
  }

  render() {
    // if (!this.props.isLoggedIn) {
    //   return "";
    // }
    return (
      <div className="nav-div">
        {!this.props.isLoggedIn && (
          <Drawer
            id="navbar"
            className="nav-bar"
            placement="left"
            closable={false}
            visible={this.props.expandMenu}
            onClose={this.props.toggleMainMenu}
          >
            <Menu
              theme="dark"
              defaultSelectedKeys={["1"]}
              mode="inline"
              onClick={this.props.performMenuActions}
            >
              <Menu.Item key="about" onClick={this.navigate}>
                <InfoCircleOutlined />
                <span>About</span>
              </Menu.Item>
              <SubMenu
                key="1"
                title={
                  <span>
                    <FormOutlined />
                    <span>Programmes</span>
                  </span>
                }
              >
                <Menu.Item key="mss" onClick={this.navigate}>
                  <span>Medha Sampurna Siksha</span>
                </Menu.Item>
                <Menu.Item key="mnn" onClick={this.navigate}>
                  <span> Medha Naipunya Nirmana</span>
                </Menu.Item>
                <Menu.Item key="mhh" onClick={this.navigate}>
                  <span>Medha Helping Hands</span>
                </Menu.Item>
                {/* <Menu.Item key="mvu" onClick={this.navigate}>
                <span>Medha Vidya Unnati</span>
                </Menu.Item> */}
              </SubMenu>
              <Menu.Item key="achievements" onClick={this.navigate}>
                <TrophyOutlined />
                <span>Success Stories</span>
              </Menu.Item>
              <Menu.Item key="login" onClick={this.navigate}>
                <span>
                  <UserOutlined /> Login / Register
                </span>
              </Menu.Item>
            </Menu>
          </Drawer>
        )}
        {this.props.isLoggedIn && this.props.role === STUDE && (
          <Drawer
            id="navbar"
            className="nav-bar"
            placement="left"
            closable={false}
            visible={this.props.expandMenu}
            onClose={this.props.toggleMainMenu}
          >
            <Menu
              theme="dark"
              defaultSelectedKeys={["1"]}
              mode="inline"
              onClick={this.props.performMenuActions}
            >
              <Menu.Item key="home" onClick={this.navigate}>
                <HomeOutlined />
                <span>Home</span>
              </Menu.Item>
              <SubMenu
                key="1"
                title={
                  <span>
                    <FormOutlined />
                    <span>Sampurna Siksha</span>
                  </span>
                }
              >
                {!this.props.studentData.applicationForm.applicationId &&
                  !this.props.studentData.loading && this.showApply() && (
                    <Menu.Item key="apply" onClick={this.navigate}>
                      Apply
                    </Menu.Item>
                  )}
                {this.props.studentData.applicationForm.applicationId &&
                  !this.props.studentData.loading &&
                  this.showEdit() && (
                    <Menu.Item key="edit" onClick={this.navigate}>
                      Edit Form
                    </Menu.Item>
                  )}
                {this.props.studentData.applicationForm.applicationId &&
                  this.props.studentData.applicationForm.status !== INCOMP && (
                    <Menu.Item key="view" onClick={this.navigate}>
                      View Form
                    </Menu.Item>
                  )}
              </SubMenu>
              <SubMenu
                key="download"
                title={
                  <span>
                    <DownloadOutlined />
                    <span>Downloads</span>
                  </span>
                }
              >
                <Menu.Item key="download-identification-form">
                  <a 
                  href={Student_Identification}
                  // href={process.env.PUBLIC_URL + "/forms/Student_Identification.pdf"} 
                  target="_blank">
                    Student Identification Authorisation Form{" "}
                  </a>{" "}
                </Menu.Item>
                {this.props.studentData.applicationForm.applicationId &&
                  (this.props.studentData.applicationForm.status === APPRV ||
                    this.props.studentData.applicationForm.status === DOWN) && (
                    <Menu.Item key="hallTicket" onClick={this.navigate}>
                      View Hall Ticket
                    </Menu.Item>
                  )}
                {
                  this.props.appConfig.currentConfig[0]?.configCd ===
                    "SAMPLE" && (
                    <Menu.Item key="hallTicket" onClick={this.downloadSample}>
                      Download Sample Exam Papers
                      </Menu.Item> )
                  }
              </SubMenu>
              <Menu.Item key="faqs" onClick={this.navigate}>
                <QuestionCircleOutlined />
                <span>FAQs</span>
              </Menu.Item>
              <Menu.Item key="logout" onClick={this.props.logout}>
                <PoweroffOutlined />
                <span>Logout</span>
              </Menu.Item>
            </Menu>
          </Drawer>
        )}

        {this.props.isLoggedIn && this.props.role === ADMIN && (
          <AdminNav {...this.props} />
        )}
        {this.props.isLoggedIn && this.props.role === MSSSTUD && (
          <StudAcctNav {...this.props} />
        )}
        {this.props.isLoggedIn &&
          this.props.role &&
          ![ADMIN, STUDE, MSSSTUD].includes(this.props.role) && (
            <StaffNav {...this.props}></StaffNav>
          )}
      </div>
    );
  }
}

export default Navbar;

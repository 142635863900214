import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import moment from "moment";
import { formItemLayout } from "../../sampoorna-siksha/utils/consts";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { fetchStudentHostelById, saveStudHostelComments, saveStudHostelDetails } from "../actions/studentList";

const dateFormat = "DD/MM/YYYY";
const EDIT_STUD_HSTL = "EDIT_STUD_HSTL";
const STUD_HSTL_CMNTS = "STUD_HSTL_CMNTS";
const STUD_HSTL_COLS = [
  {
    title: "Hostel Name",
    dataIndex: "hostelName",
    key: "hostelName",
    render: (text, record) => record.hostel.hostelName,
    sorter: {
      compare: (a, b) => sortNames(a.hostel.hostelName, b.hostel.hostelName),
    },
    width: "200px",
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
    render: (text, record) => record.hostel.location,
    sorter: {
      compare: (a, b) => sortNames(a.hostel.location, b.hostel.location),
    },
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    render: (text, record) => record.hostel.address,
    sorter: {
      compare: (a, b) => sortNames(a.hostel.address, b.hostel.address),
    },
    width: "200px",
  },
  {
    title: "Owner",
    dataIndex: "owner",
    key: "owner",
    render: (text, record) => record.hostel.owner,
    sorter: {
      compare: (a, b) => sortNames(a.hostel.owner, b.hostel.owner),
    },
  },
  {
    title: "Phone Number",
    dataIndex: "phone1",
    key: "phone1",
    render: (text, record) => record.hostel.phone1,
    sorter: {
      compare: (a, b) => sortNames(a.hostel.phone1, b.hostel.phone1),
    },
  },
  {
    title: "Join Date",
    dataIndex: "joinDate",
    key: "joinDate",
    render: (text, record) =>
      record.joinDate ? moment(record.joinDate).format(dateFormat) : "",
    sorter: {
      compare: (a, b) => moment(a.joinDate).unix() - moment(b.joinDate).unix(),
    },
  },
  {
    title: "Leave Date",
    dataIndex: "joinDate",
    key: "joinDate",
    render: (text, record) =>
      record.leaveDate ? moment(record.leaveDate).format(dateFormat) : "",
    sorter: {
      compare: (a, b) =>
        moment(a.leaveDate).unix() - moment(b.leaveDate).unix(),
    },
  },
  // {
  //   title: "Last Fees Paid",
  //   dataIndex: "lastFee",
  //   key: "lastFee",
  //   render: (text, record) => record.lastFee,
  //   sorter: {
  //     compare: (a, b) => Number(a.lastFee) - Number(b.lastFee),
  //   },
  // },
  {
    title: "Comment",
    dataIndex: "comment",
    key: "comment",
    render: (text, record) =>
      record.comments && record.comments.length > 0
        ? record.comments[record.comments.length - 1].comment
        : "",
  },
];

const StudHostelComments = (props) => {
  const [currentRec, setCurrentRec] = useState({});
  const [commentsList, setCommentsList] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    const comments = props.currentRec.comments.sort(
      (a, b) => b.studHostelCmntId - a.studHostelCmntId
    );
    setCommentsList(comments);
    form.resetFields();
  }, [props.currentRec, form]);

  useEffect(() => {
    if (props.manage.currentProfile?.studentHostelDetails?.length > 0) {
      const currentHostel =
        props.manage.currentProfile.studentHostelDetails.filter(
          (h) => h.studHostelId === props.currentRec.studHostelId
        );
      if (currentHostel.length > 0) {
        const comments = currentHostel[0].comments.sort(
          (a, b) => b.studHostelCmntId - a.studHostelCmntId
        );
        setCommentsList(comments);
        form.resetFields();
      }
    }
  }, [props.manage.currentProfile.studentHostelDetails, props.currentRec.studHostelId, form]);

  const onFinish = (form) => {
    console.log("-------", form);
    form.studHostelId = props.currentRec.studHostelId;
    props.saveStudHostelComments(form, props.currentRec.studentProfileId);
  };
  const onCancel = () => {
    form.resetFields();
    props.toogleAddModal("");
  };
  const deleteRecord = (item, e) => {
    e.preventDefault();
    props.deleteStudHostelComments(
      item.studHostelCmntId,
      props.currentRec.studentProfileId
    );
  };
  return (
    <>
      <Modal
        visible={props.manage.showModal === STUD_HSTL_CMNTS}
        footer={null}
        onCancel={() => onCancel()}
      >
        <Card title="Comments">
          <Spin tip="Loading..." spinning={props.manage.loading}>
            <List
            className="scrolable-list"
              bordered
              dataSource={commentsList}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <a href="/" onClick={(e) => deleteRecord(item, e)}>
                      <DeleteOutlined />
                    </a>,
                  ]}
                >
                  <Row style={{ width: "100%" }}>
                    <Col sm={24} xs={24}>
                      {item.comment}
                    </Col>
                    <Col sm={24} xs={24}>
                      {item.createdAt
                        ? moment(item.createdAt).format("DD-MM-YYYY hh:mm A")
                        : ""}
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
            <div style={{ marginTop: "10px" }}>
              <Form initialValues={currentRec} onFinish={onFinish} form={form}>
                <Form.Item
                  {...formItemLayout}
                  name="studHostelId"
                  hidden={true}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="studentProfileId"
                  hidden={true}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="comment"
                  label="Comment"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a comment.",
                    },
                  ]}
                >
                  <Input.TextArea maxLength="100" />
                </Form.Item>
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button mg-l"
                  >
                    Add
                  </Button>
                  <Button
                    className="login-form-button mg-l"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </Spin>
        </Card>
      </Modal>
    </>
  );
};

const StudHostelForm = (props) => {
  const [formUpdated, setFormUpdated] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (form) => {
    console.log("-------", form);
    form.studentProfileId = props.manage.currentProfile.studentProfileId;
    props.saveStudHostelDetails(form);
  };

  const onCancel = () => {
    form.resetFields();
    props.toogleAddModal("");
  };

  const onValueChange = () => {
    setFormUpdated(true);
  };

  return (
    <>
      <Modal
        visible={props.manage.showModal === EDIT_STUD_HSTL}
        footer={null}
        onCancel={() => onCancel()}
      >
        <div>
          <Spin tip="Loading..." spinning={props.masterTable.loading}>
            <Card
              title={
                props.currentRec.studHostelId
                  ? "Edit Hostel Details"
                  : "Add Hostel Details"
              }
            >
              <div>
                <Form
                  initialValues={props.currentRec}
                  onFinish={onFinish}
                  form={form}
                  onValuesChange={(e) => onValueChange(e)}
                >
                  <Form.Item
                    {...formItemLayout}
                    name="studHostelId"
                    hidden={true}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    name="studentProfileId"
                    hidden={true}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    name="hostelId"
                    label="Hostel Name"
                    rules={[
                      {
                        required: true,
                        message: "Please select Hostel",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toUpperCase()
                          .includes(input.toUpperCase())
                      }
                      className="full-width"
                    >
                      {props.masterTable.hostels.map((hstl) => (
                        <Select.Option
                          key={hstl.hostelId}
                          value={hstl.hostelId}
                        >
                          {hstl.hostelName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {/* <Form.Item
                    {...formItemLayout}
                    name="joinDate"
                    label="Join Date"
                    rules={[
                      {
                        required: true,
                        message: "Please select join date",
                      },
                    ]}
                  >
                    <DatePicker
                      format={dateFormat}
                      className="full-width"
                      showToday={false}
                    />
                  </Form.Item> */}

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      // prevValues.applicationEndDate !== currentValues.applicationEndDate ||
                      prevValues.leaveDate !== currentValues.leaveDate
                    }
                  >
                    {({ getFieldValue }) => {
                      const leaveDate = getFieldValue(["leaveDate"]);

                      return (
                        <Form.Item
                          {...formItemLayout}
                          name="joinDate"
                          label="Join Date"
                          rules={[
                            {
                              required: true,
                              message: "Please select join date",
                            },
                          ]}
                        >
                          <DatePicker
                            format={dateFormat}
                            className="full-width"
                            showToday={false}
                            disabledDate={
                              leaveDate
                                ? (current) => current > leaveDate
                                : false
                            }
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      // prevValues.applicationEndDate !== currentValues.applicationEndDate ||
                      prevValues.joinDate !== currentValues.joinDate
                    }
                  >
                    {({ getFieldValue }) => {
                      const joinDate = getFieldValue(["joinDate"]);

                      return (
                        <Form.Item
                          {...formItemLayout}
                          name="leaveDate"
                          label="Leave Date"
                        >
                          <DatePicker
                            format={dateFormat}
                            className="full-width"
                            showToday={false}
                            disabledDate={(current) => current < joinDate}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    name="comment"
                    label="Comment"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            formUpdated &&
                            !value &&
                            getFieldValue("leaveDate")
                          ) {
                            return Promise.reject(
                              "Please enter a comment / reason for leaving."
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input.TextArea maxLength="60" />
                  </Form.Item>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button mg-l"
                      disabled={props.manage.loading}
                    >
                      Save
                    </Button>
                    <Button
                      className="login-form-button mg-l"
                      onClick={() => onCancel()}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </Card>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

const StudHostelDetails = (props) => {
  const [currentRecord, setCurrentRecord] = useState({});

  useEffect(() => {
    if (props.record.studentProfileId) {
      props.fetchStudentHostelDetails(props.record.studentProfileId);
    }
  }, []);

  const addRecord = () => {
    setCurrentRecord({});
    props.toogleAddModal(EDIT_STUD_HSTL);
  };

  const editRecord = (record, e) => {
    e.preventDefault();
    record.joinDate = moment(record.joinDate);
    if (record.leaveDate) {
      record.leaveDate = moment(record.leaveDate);
    }
    setCurrentRecord(record);
    props.toogleAddModal(EDIT_STUD_HSTL);
  };

  const deleteRecord = (record, e) => {
    e.preventDefault();
    Modal.confirm({
      title: "Do you want to delete this hostel?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        props.deleteStudHostelDetails(
          record.studHostelId,
          record.studentProfileId
        );
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  const showComments = (record, e) => {
    e.preventDefault();
    setCurrentRecord(record);
    props.toogleAddModal(STUD_HSTL_CMNTS);
  };

  if (STUD_HSTL_COLS.length < 9) {
    STUD_HSTL_COLS.push({
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a href="/" onClick={(e) => editRecord(record, e)}>
            <EditOutlined />
          </a>
          |
          <a href="/" onClick={(e) => deleteRecord(record, e)}>
            <DeleteOutlined />
          </a>
          |
          <a href="/" onClick={(e) => showComments(record, e)}>
            <CommentOutlined />
          </a>
        </Space>
      ),
    });
  }

  return (
    <div id="hostelDetailsContiner">
      <Card>
        <h2>
          Hostel Details - {props.loading}
          <Button onClick={addRecord} type="primary" style={{ float: "right" }}>
            Add
          </Button>
        </h2>
        <Spin spinning={props.manage.loading} delay={500}>
          <div>
            <Table
              columns={STUD_HSTL_COLS}
              dataSource={props.manage.currentProfile.studentHostelDetails}
              // rowKey={(record) => record.studHostelId}
              pagination={{ showSizeChanger: true }}
              loading={props.manage.loading}
            />
          </div>
        </Spin>
      </Card>
      {props.manage.showModal === EDIT_STUD_HSTL && (
        <StudHostelForm currentRec={currentRecord} {...props} />
      )}
      {props.manage.showModal === STUD_HSTL_CMNTS && (
        <StudHostelComments currentRec={currentRecord} {...props} />
      )}
      <div style={{ textAlign: "right" }}>
        <Button onClick={(e) => props.onClose(e)} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStudentHostelDetails(id) {
      dispatch(fetchStudentHostelById(id));
    },

    saveStudHostelDetails(form) {
      dispatch(saveStudHostelDetails(form)).then((res) => {
        this.fetchStudentHostelDetails(form.studentProfileId);
      });
    },

    saveStudHostelComments(form, studentProfileId) {
      dispatch(saveStudHostelComments(form)).then((res) => {
        this.fetchStudentHostelDetails(studentProfileId);
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { ManagementReducer } = state;
  return {
    loading: ManagementReducer.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudHostelDetails);

import { LOGIN, REGISTER, FORGOT_PASSWORD } from "../utils/const";
import { axiosInstance } from "../../Interceptor";


export const login = (loginform) => {
    return {
        type: LOGIN,
        payload: axiosInstance.post('/login', loginform)
    };
}

export const register = (registerForm) => {
    return {
        type: REGISTER,
        payload: axiosInstance.post('/register', registerForm)
    };
}

export const sendPassword = (username) => {
    return {
        type: FORGOT_PASSWORD,
        payload: axiosInstance.get('/forgotpassword?username=' + username)
    };
}
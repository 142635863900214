import { Button, Card, Checkbox, Col, Form, Row } from "antd";
import React from "react";
import { CONS_ITEMS } from "../../utils/consts";
import { createConsumerItemsData } from "../../utils/util";
import ReviewStep from "./ReviewStep";

class ConsumerItems extends React.Component {
  onFinish = (form) => {
    // console.log("Received values of form:", form);
    this.props.saveDetails(CONS_ITEMS, form);
  };

  render() {
    let consumerItems = this.props.review.reviewFormData[CONS_ITEMS];
    const fieldData = [];
    if (!consumerItems) {
      consumerItems = {
        consumerItemsList: [],
      };
    }
    fieldData.push(
      createConsumerItemsData(JSON.stringify(consumerItems.consumerItemsList))
    );

    return (
      <div className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Consumer Items"
          onFinish={(e) => this.onFinish(e)}
          {...this.props}
        >
          <Form.Item name="consumerItemsList">
            <Checkbox.Group>
              <Row>
                <Col span={6}>
                  <Checkbox value="TV" style={{ lineHeight: "32px" }}>
                    TV
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="HOME_THEATRE" style={{ lineHeight: "32px" }}>
                    Home Theatre
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="VCR" style={{ lineHeight: "32px" }}>
                    VCR / VCD Player
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="DVD" style={{ lineHeight: "32px" }}>
                    DVD Player
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="SET_TOP_BOX" style={{ lineHeight: "32px" }}>
                    Set-Top Box
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="MUSIC_SYSTEM" style={{ lineHeight: "32px" }}>
                    Music System
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="COMPUTER" style={{ lineHeight: "32px" }}>
                    Computer
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="REFRIGERATOR" style={{ lineHeight: "32px" }}>
                    Refrigerator
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="WASH_MACH" style={{ lineHeight: "32px" }}>
                    Washing Machine
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="AIR_COOLER" style={{ lineHeight: "32px" }}>
                    Air Cooler
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="AC" style={{ lineHeight: "32px" }}>
                    AC
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </ReviewStep>
      </div>
    );
  }
}

export default ConsumerItems;

import {
  Form,
  Col,
  Select,
  Row,
  Input,
} from "antd";
import { translate } from "../../base/actions/translate";
import React from "react";
import { toCapital } from "../../sampoorna-siksha/utils/util";

const {Option} = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
};

class StudentFilterFrom extends React.Component {

  state = {
    fieldData: {}
  }

  componentWillMount() {
    this.getConfig('ODIST', -1);
    this.getConfig('CASTE', -1);
    this.getConfig('EXAM_CENTER', -1);
  }

  getConfig(type, parentId) {
    this.props.fetchConfigByTypeAndParentId(type, parentId);
  }

  populateMandals = (value) => {
    this.props.getMandalsList(value);
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.distId = value;
    fieldData.mandalId = "";
    fieldData.schoolId = "";
    this.setState({ fieldData });
  };

  populateSchools = (value) => {
    this.props.getSchoolsList(value);
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.mandalId = value;
    fieldData.schoolId = "";
    this.setState({ fieldData });
  };

  render() {
    const statusList = this.props.statusList;
    return (
      <Row>
        <Col xs={24} sm={12}>
          <Form.Item
            {...formItemLayout}
            name="oldDistId"
            label={translate("oldDistrict")}
          >
            <Select className="full-width wrapped_select" onChange={value => this.getConfig('DIST', value)} mode="multiple">
              {this.props.oldDistrictsList.map((district) => (
                <Option key={district.id} value={district.id}>
                  {district.configVal}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            {...formItemLayout}
            name="distId"
            label="School New District"
          >
            <Select className="full-width wrapped_select" onChange={value => this.getConfig('MNDL', value)} mode="multiple">
              {this.props.districtsList.map((district) => (
                <Option key={district.id} value={district.id}>
                  {district.configVal}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            {...formItemLayout}
            name="mandalId"
            label={translate("mandal")}
          >
            <Select className="full-width wrapped_select" onChange={value => this.getConfig('SCHL', value)} mode="multiple">
              {this.props.mandalsList.map((mandal) => (
                <Option key={mandal.id} value={mandal.id}>
                  {mandal.configVal}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            {...formItemLayout}
            name="schoolId"
            label={translate("school")}
          >
            <Select className="wrapped_select" mode="multiple">
              {this.props.schoolsList.map((config) => (
                <Option key={config.id} value={config.id}>
                  {config.configVal}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            {...formItemLayout}
            name="examCenterId"
            label={translate("examCenter")}
          >
            <Select className="wrapped_select" mode="multiple">
              {this.props.examCenters.map((config) => (
                <Option key={config.id} value={config.id}>
                  {config.configVal}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            {...formItemLayout}
            name="casteId"
            label={translate("caste")}
          >
            <Select mode="multiple">
              {this.props.castesList.map((config) => (
                <Option key={config.id} value={config.id}>
                  {config.configVal}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item {...formItemLayout} name="status" label="Status">
            <Select className="full-width" mode="multiple">
              {statusList.map((statusCd) => (
                <Option key={statusCd.id} value={statusCd.id}>
                  {translate(statusCd.key)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            {...formItemLayout}
            name="applicationNo"
            label="Application Number"
            getValueFromEvent={(e) => toCapital(e)}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

export default StudentFilterFrom;

import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import React from "react";
import { COURSE, HOST, INST } from "../../mastertables/utils/const";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import { generateStudentId, getStatusString } from "../utils/utils";
import StudentDetails from "./StudentDetailes";
import "../styles/manage.css";
import { exportFile } from "../../common/utils/export";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const multiFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

class StudentList extends React.Component {
  state = {
    courses: [],
    record: {},
    showDetails: false,
    hostels: [],
    institutes: [],
    searchText: "",
  };

  formRef = React.createRef();

  componentDidMount() {
    this.props.fetchMasterTableData(INST);
    this.props.fetchMasterTableData(HOST);
    this.props.fetchMasterTableData(COURSE);
    this.props.fetchAllAvailableBatchList();
    this.props.resetStudentsList();
  }

  populateCourses = (val) => {
    let courses = [];
    for (const cat of this.props.masterTable.institutes) {
      if (cat.instituteId === val) {
        for (const ic of cat.instituteCourses) {
          courses.push(ic.course);
        }
      }
    }
    this.setState({ courses });
    if (this.formRef.current) {
      this.formRef.current.setFieldsValue({
        courseId: "",
      });
    }
  };

  searchStudentsList = (form) => {
    this.props.searchStudentProfiles(form);
  };

  resetForm = () => {
    if(this.formRef.current) {
      this.formRef.current.resetFields();
    }
  }

  showDetails = (row) => {
    console.log("SHow student details : ", row);
    this.setState({
      record: row,
      showDetails: true,
    });
  };

  onClose = () => {
    this.setState({ showDetails: false });
  };

  offlineSearch = (value) => {
    this.props.offlineSearch(value, this.props.manage.studentProfiles);
    this.setState({ searchText: value });
  };

  export = (cols, list) => {
    if (!cols || cols.length <= 0 || !list || list.length <= 0) {
      return;
    }
    exportFile(cols, list, "Students List");
  };

  render() {
    let courses = this.props.masterTable.courses;
    if (this.state.courses.length > 0) {
      courses = this.state.courses;
    }
    const { institutes, hostels } = this.props.masterTable;

    const STUD_LIST_COLS = [
      {
        title: "Student ID",
        dataIndex: "studentId",
        key: "studentId",
        render: (text, record) => generateStudentId(record),
        sorter: {
          compare: (a, b) =>
            sortNames(generateStudentId(a), generateStudentId(b)),
        },
        defaultSortOrder: 'ascend',
      },
      {
        title: "Student Name",
        dataIndex: "studentName",
        key: "studentName",
        render: (text, record) => record.firstName + " " + record.lastName,
        sorter: {
          compare: (a, b) =>
            sortNames(
              a.firstName + " " + a.lastName,
              b.firstName + " " + b.lastName
            ),
        },
      },
      {
        title: "Batch",
        dataIndex: "batch",
        key: "batch",
        render: (text, record) => record.batch,
        sorter: {
          compare: (a, b) => a.batch - b.batch,
        },
      },
      {
        title: "Course Name",
        dataIndex: "courseName",
        key: "courseName",
        render: (text, record) => record.courses.join(", "),
        sorter: {
          compare: (a, b) =>
            sortNames((a.courses ? a.courses.join(",") : a.courses), (b.courses ? b.courses.join(",") : b.courses)),
        },
      },
      {
        title: "Academic Year",
        dataIndex: "year",
        key: "year",
        render: (text, record) => record.batch + "-" + (record.batch + 1),
        sorter: {
          compare: (a, b) => sortNames(a.batch, b.batch),
        },
      },
      {
        title: "College Name",
        dataIndex: "instituteName",
        key: "instituteName",
        render: (text, record) => record.instituteNames.join(", "),
        sorter: {
          compare: (a, b) =>
            sortNames(a.instituteNames.join(","), b.instituteNames.join(",")),
        },
        width: "200px",
      },
      {
        title: "Hostel Name",
        dataIndex: "hostelName",
        key: "hostelName",
        render: (text, record) => record.hostelNames.join(", "),
        sorter: {
          compare: (a, b) =>
            sortNames(a.hostelNames.join(","), b.hostelNames.join(",")),
        },
        width: "200px",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => getStatusString(record.status),
        sorter: {
          compare: (a, b) => sortNames(getStatusString(a.status), getStatusString(b.status)),
        },
      },
    ];

    return (
      <div id="stud_prof_list_container">
        <Card title="MSS Students Management">
          <Form onFinish={this.searchStudentsList} ref={this.formRef}>
            <Row gutter={[16, 8]}>
              <Col xs={24} sm={3}>
                <Form.Item name="batches" label="Batch" {...formItemLayout}>
                  <Select mode="multiple">
                    {this.props.manage.batches
                    .sort((a, b) => sortNames(b, a)) //#637
                    .map((batch) => (
                      <Select.Option key={batch} value={batch}>
                        {batch}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name="instituteIds"
                  label="College"
                  {...multiFormItemLayout}
                >
                  <Select
                    showSearch
                    mode="multiple"
                    filterOption={(input, option) =>
                      option.children
                        .toUpperCase()
                        .includes(input.toUpperCase())
                    }
                    className="wrapped_select"
                    onChange={(val) => this.populateCourses(val)}
                  >
                    {institutes
                      .sort((a, b) => sortNames(a.name, b.name))
                      .map((cat) => (
                        <Select.Option
                          key={cat.instituteId}
                          value={cat.instituteId}
                        >
                          {cat.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name="courseIds"
                  label="Course"
                  {...multiFormItemLayout}
                >
                  {/**#631 */}
                  <Select showSearch mode="multiple"
                  filterOption={(input, option) =>
                    option.children
                      .toUpperCase()
                      .includes(input.toUpperCase())
                  }
                  >
                    {courses
                      .sort((a, b) => sortNames(a.courseName, b.courseName))
                      .map((cat) => (
                        <Select.Option key={cat.courseId} value={cat.courseId}>
                          {cat.courseName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name="hostelIds"
                  label="Hostel"
                  {...multiFormItemLayout}
                >
                  <Select
                    showSearch
                    mode="multiple"
                    filterOption={(input, option) =>
                      option.children
                        .toUpperCase()
                        .includes(input.toUpperCase())
                    }
                  >
                    {hostels
                      .sort((a, b) => sortNames(a.hostelName, b.hostelName))
                      .map((cat) => (
                        <Select.Option key={cat.hostelId} value={cat.hostelId}>
                          {cat.hostelName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={3}>
                <Form.Item name="statuses" label="Status" {...formItemLayout}>
                  {/**#631 */}
                  <Select
                    showSearch
                    mode="multiple"
                    filterOption={(input, option) =>
                      option.children
                        .toUpperCase()
                        .includes(input.toUpperCase())
                    }
                  >
                    <Select.Option key="13" value="13">
                      Active
                    </Select.Option>
                    <Select.Option key="14" value="14">
                      Completed
                    </Select.Option>
                    <Select.Option key="15" value="15">
                      Dropped Out
                    </Select.Option>
                    <Select.Option key="16" value="16">
                      Rejoined
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Space>
                <Button
                  type="primary"
                  onClick={(e) =>
                    this.export(
                      STUD_LIST_COLS,
                      this.props.manage.filteredProfiles
                    )
                  }
                >
                  Export
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="modal-form-submit-button"
                >
                  Search
                </Button>
                <Button type="primary" onClick={(e) => this.resetForm()}>Reset</Button> {/*#632 */}
              </Space>
            </div>
          </Form>
        </Card>
        <Card>
          <Row>
            <Col style={{ paddingRight: "10px" }} sm={24}>
              <Input.Search
                placeholder="Search"
                value={this.state.searchText}
                onChange={(e) => this.offlineSearch(e.target.value)}
                onSearch={(value) => this.offlineSearch(value)}
                enterButton
                style={{ marginBottom: "10px" }}
              />
            </Col>
          </Row>
          <Spin
            spinning={
              this.props.masterTable.loading || this.props.manage.loading
            }
            delay={500}
          >
            <div>
              <Table
                className="row-selection"
                columns={STUD_LIST_COLS}
                dataSource={this.props.manage.filteredProfiles}
                pagination={{
                  showSizeChanger: true,
                  showTotal: () => {
                    const total = this.props.manage.filteredProfiles.length;
                    return `Total ${total}`;
                  },
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      this.showDetails(record, rowIndex, e);
                    }, // click row
                  };
                }}
              />
            </div>
          </Spin>
        </Card>
        {this.state.showDetails && (
          <Drawer
            title={
              this.props.manage.currentProfile.studentName !== undefined && (
                <Space>
                  <div>{this.props.manage.currentProfile.studentName}</div>
                  <div>Status:</div>
                  <div>
                    {getStatusString(this.props.manage.currentProfile.status)}{" "}
                  </div>{" "}
                </Space>
              )
            }
            placement="right"
            closable={false}
            onClose={this.onClose}
            visible={this.state.showDetails}
            width="100%"
            // footer={
            //   <div
            //     style={{
            //       textAlign: "right",
            //     }}
            //   >
            //     <Button onClick={this.props.onClose} style={{ marginRight: 8 }}>
            //       Close
            //     </Button>
            //   </div>
            // }
          >
            <StudentDetails
              record={this.state.record}
              showDetails={this.state.showDetails}
              onClose={this.onClose}
              {...this.props}
            ></StudentDetails>
          </Drawer>
        )}
      </div>
    );
  }
}

export default StudentList;

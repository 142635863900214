import { connect } from "react-redux";
import { RESET_MESSAGES } from "../../base/utils/const";
import { draftImg, updateImageAngle, deleteDrafts, deleteFiles, approveDrafts } from "../../management/actions/managementUploads";
import { getMedicalRequests, saveMedicalRequest, saveMonitoryRequest, saveWelfareRequest } from "../../management/actions/studentList";
import { ADD_TO_DEL_MNG_FILES, SAVE_MED_REQ, SAVE_MON_REQ, SAVE_WEL_REQ } from "../../management/utils/const";
import { fetchRequestCategory, fetchRequestSubCategory } from "../../mastertables/actions/masterTables";
import { TOGGLE_ADD_MODAL } from "../../mastertables/utils/const";
import { changeStatus, changeStatusInBulk, changeStatusWithComment, getApprovalLevels, getTaskDetails, searchTask } from "../actions/TaskActions";
import TaskList from "../components/TaskList";
import { CHANGE_TASK_STATUS } from "../utils/const";

const mapDispatchToProps = (dispatch) => {
  return {

    getApprovalLevels() {
      dispatch(getApprovalLevels());
    },

    searchTasks(taskFilter) {
      dispatch(searchTask(taskFilter));
    },

    getTaskDetails(taskView) {
      dispatch(getTaskDetails(taskView));
    },

    changeTaskStatus(taskId, status, taskView, taskFilter) {
      dispatch(changeStatus(taskId, status)).then(
        resp => {
          if(resp.action.type === `${CHANGE_TASK_STATUS}_FULFILLED`) {
            this.getTaskDetails(taskView);
            this.searchTasks(taskFilter);
          }
        }
      );
    },

    changeTaskStatusWithComment(taskStatusForm, taskView, taskFilter) {
      dispatch(changeStatusWithComment(taskStatusForm)).then(
        resp => {
          if(resp.action.type === `${CHANGE_TASK_STATUS}_FULFILLED`) {
            this.getTaskDetails(taskView);
            this.searchTasks(taskFilter);
          }
        }
      );
    },

    changeTaskStatusInBulk(taskStatusForm, taskFilter) {
      dispatch(changeStatusInBulk(taskStatusForm)).then(
        resp => {
          if(resp.action.type === `${CHANGE_TASK_STATUS}_FULFILLED`) {
            this.searchTasks(taskFilter);
          }
        }
      );
    },

    saveMedicalRequest(form, taskView, taskFilter) {
      dispatch(saveMedicalRequest(form)).then((res) => {
        if(res.action.type === `${SAVE_MED_REQ}_FULFILLED`) {
          this.getTaskDetails(taskView);
          this.searchTasks(taskFilter);
        }
      });
    },

    saveMonitoryRequest(form, taskView, taskFilter) {
      dispatch(saveMonitoryRequest(form)).then((res) => {
        if(res.action.type === `${SAVE_MON_REQ}_FULFILLED`) {
          this.getTaskDetails(taskView);
          this.searchTasks(taskFilter);
        }
      });
    },

    saveWelfareRequest(form, taskView, taskFilter) {
      dispatch(saveWelfareRequest(form)).then((res) => {
        if(res.action.type === `${SAVE_WEL_REQ}_FULFILLED`) {
          this.getTaskDetails(taskView);
          this.searchTasks(taskFilter);
        }
      });
    },

    getReqCats(id) {
      dispatch(fetchRequestCategory(id));
    },

    getReqSubCats(id) {
      dispatch(fetchRequestSubCategory(id));
    },

    updateImageAngle(angle, uploadId) {
      dispatch(updateImageAngle(uploadId, angle));
    },

    draftUpload(studentProfileId, file, type, batch) {
      dispatch(draftImg(studentProfileId, file, type, batch));
    },

    deleteDraft(studentProfileId) {
      dispatch(deleteDrafts(studentProfileId));
    },

    deleteFiles(filesList) {
      dispatch(deleteFiles(filesList));
    },

    addToDeleteFiles(filesList) {
      dispatch({
        type: ADD_TO_DEL_MNG_FILES,
        payload: filesList,
      });
    },

    approveDrafts(studentProfileId, filesList) {
      dispatch(approveDrafts(studentProfileId, filesList));
    },

    toogleAddModal(modal) {
      dispatch({
        type: TOGGLE_ADD_MODAL,
        payload: modal,
      });
    },

    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    MasterTablesReducer,
    AppConfigReducer,
    ManagementUploadReducer,
    TaskReducer,
    ManagementReducer
  } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    masterTable: MasterTablesReducer,
    appConfig: AppConfigReducer,
    mgUploads: ManagementUploadReducer,
    manage: ManagementReducer,
    tasks: TaskReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);

import { connect } from "react-redux";
import { getExamCenterDetails, saveExamRooms, exportHallTickets, processSeatingManagement, saveExamCenter, deleteExamCenter } from "../actions/SSActions";
import { examCenterPredicate } from "../utils/util";
import { SEARCH_EXAM_CENTERS, TOGGLE_EXAM_ROOMS_MODAL, TOGGLE_ADD_EXAM_CENTER_MODAL } from "../utils/consts";
import { RESET_MESSAGES } from "../../base/utils/const";
import { fetchOldDistricts } from "../../base/actions/AppConfigActions";
import ExamCenterRoom from "../components/ExamCenterRooms";

const mapDispatchToProps = (dispatch, props) => {
  return {
    getExamCenterDetails() {
        dispatch(getExamCenterDetails());
    },

    offlineSearch(searchText, examCentersList) {
      if (searchText && searchText.length > 1) {
        const result = examCentersList.filter((examCenter) => {
          return examCenterPredicate(examCenter, searchText);
        });
        dispatch({
          type: SEARCH_EXAM_CENTERS,
          payload: result,
        });
      } else if (!searchText) {
        dispatch({
          type: SEARCH_EXAM_CENTERS,
          payload: examCentersList,
        });
      }
    },

    saveExamRooms(examRoom) {
      dispatch(saveExamRooms(examRoom)).then(res => {
        this.getExamCenterDetails();
      });
    },

    toggleExamCenterModal(flag) {
      dispatch({
        type: TOGGLE_EXAM_ROOMS_MODAL,
        payload: flag
      });
    },

    exportHallTickets() {
      dispatch(exportHallTickets());
    },

    processSeatingManagement() {
      dispatch(processSeatingManagement());
    },

    toggleAddModal(flag) {
      dispatch({
        type: TOGGLE_ADD_EXAM_CENTER_MODAL,
        payload: flag
      });
    },

    saveExamCenter(examCenter) {
      dispatch(saveExamCenter(examCenter)).then(
        res => {
          this.getExamCenterDetails()
        });
    },

    deleteExamCenter(sourceId, destId) {
      dispatch(deleteExamCenter(sourceId, destId)).then(
        res => {
          this.getExamCenterDetails()
        });
    },

    getOldDistricts() {
      dispatch(fetchOldDistricts());
    },

    resetMessage() {
      dispatch({
          type: RESET_MESSAGES
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, AdminReducer, SSReducer, AppConfigReducer } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    sampurnaSiksha: SSReducer,
    appConfig: AppConfigReducer
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamCenterRoom);

import { Card, Tabs } from "antd";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchReports } from "../actions/reports";
import { FIN_RPT, REQ_RPT, STUD_RPT } from "../utils/const";
import FinancialReports from "./FinancialReports";
import RequestsReports from "./RequestsReports";
import StudentsReports from "./StudentsReports";

const { TabPane } = Tabs;

const ReportsDashboard = (props) => {
  const [activeKey, setActiveKey] = React.useState(props.match.params.key);

  React.useEffect(() => {
    if (props.match.params.key) {
      setActiveKey(props.match.params.key);
    } else {
      setActiveKey(STUD_RPT);
    }
  }, [props.match.params.key]);

  const callback = (key) => {
    console.log(key);
    props.history.push(`/reports/${key}`);
  };

  return (
    <React.Fragment>
      <div id="master-tables-container">
        <Card>
          <Tabs onTabClick={(e) => callback(e)} activeKey={activeKey}>
            <TabPane tab="Students" key={STUD_RPT}>
              {activeKey === STUD_RPT && <StudentsReports {...props}/>}
            </TabPane>
            <TabPane tab="Requests" key={REQ_RPT}>
              {activeKey === REQ_RPT && <RequestsReports {...props}/>}
            </TabPane>
            <TabPane tab="Financial" key={FIN_RPT}>
              {activeKey === FIN_RPT && <FinancialReports {...props}/>}
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
    return {
      fetchReports(reportType, form) {
        dispatch(fetchReports(reportType, form));
      },
  
    };
  };

  const mapStateToProps = (state) => {
    const {
      AuthReducer,
      AdminReducer,
      MasterTablesReducer,
      AppConfigReducer,
      ReportsReducer
    } = state;
    return {
      profile: AuthReducer.profile,
      lang: AuthReducer.lang,
      role: AuthReducer.role,
      admin: AdminReducer,
      masterTable: MasterTablesReducer,
      appConfig: AppConfigReducer,
      reports: ReportsReducer
    };
  };

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportsDashboard));


// export default ReportsDashboard;

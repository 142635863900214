import { axiosInstance } from "../../Interceptor";
import {
  DELETE_CATEGORY,
  DELETE_COURSE,
  EDUCATION_TYPE,
  FETCH_CATEGORIES,
  FETCH_COURSES,
  SAVE_CATEGORY,
  SAVE_COURSE,
  FETCH_MASTER_TABLE,
  DELETE_MASTER_TABLE,
  SAVE_MASTER_TABLE,
  REQ_SUB_CAT,
  REQ_CAT,
  FETCH_MASTER_OLD_DISTRICTS,
  FIND_MASTER_TABLE,
} from "../utils/const";

export const fetchEducationTypes = () => {
  return {
    type: EDUCATION_TYPE,
    payload: axiosInstance.get("/mastertables/educationTypes"),
  };
};

export const fetchEducationCategories = () => {
  return {
    type: FETCH_CATEGORIES,
    payload: axiosInstance.get("/mastertables/categories"),
  };
};

export const saveEducationCategory = (categoryForm) => {
  return {
    type: SAVE_CATEGORY,
    payload: axiosInstance.post("/mastertables/categories", categoryForm),
  };
};

export const deleteEducationCategory = (id) => {
  return {
    type: DELETE_CATEGORY,
    payload: axiosInstance.delete("/mastertables/categories/" + id),
  };
};

export const fetchCourses = () => {
  return {
    type: FETCH_COURSES,
    payload: axiosInstance.get("/mastertables/courses"),
  };
};

export const fetchCoursesByCatId = (id) => {
  return {
    type: FETCH_COURSES,
    payload: axiosInstance.get("/mastertables/categories/courses/" + id),
  };
};

export const saveCourse = (courseForm) => {
  return {
    type: SAVE_COURSE,
    payload: axiosInstance.post("/mastertables/courses", courseForm),
  };
};

export const deleteCourses = (id) => {
  return {
    type: DELETE_COURSE,
    payload: axiosInstance.delete("/mastertables/courses/" + id),
  };
};

export const fetchMasterTableData = (dataType) => {
  return {
    type: FETCH_MASTER_TABLE,
    payload: axiosInstance.get(`/mastertables/${dataType}`),
    meta: dataType,
  };
};

export const fetchMasterTableRecord = (dataType, id) => {
  return {
    type: FIND_MASTER_TABLE,
    payload: axiosInstance.get(`/mastertables/${dataType}/${id}`),
    meta: dataType,
  };
};

export const saveMasterTableData = (dataType, courseForm) => {
  return {
    type: SAVE_MASTER_TABLE,
    payload: axiosInstance.post(`/mastertables/${dataType}`, courseForm),
    meta: dataType,
  };
};

export const deleteMasterTableData = (dataType, id) => {
  return {
    type: DELETE_MASTER_TABLE,
    payload: axiosInstance.delete(`/mastertables/${dataType}/` + id),
    meta: dataType,
  };
};

export const fetchRequestCategory = (id) => {
  return {
    type: FETCH_MASTER_TABLE,
    payload: axiosInstance.get("/mastertables/reqCat/" + id),
    meta: REQ_CAT,
  };
};

export const fetchRequestSubCategory = (id) => {
  return {
    type: FETCH_MASTER_TABLE,
    payload: axiosInstance.get("/mastertables/reqSubCat/" + id),
    meta: REQ_SUB_CAT,
  };
};

export const fetchOldDistricts = () => {
  return {
      type: FETCH_MASTER_OLD_DISTRICTS,
      payload: axiosInstance.get('/appconfig/ODIST/-1')
  };
}
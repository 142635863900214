import {
  FETCH_DISTRICTS,
  FETCH_MANDALS,
  FETCH_SCHOOLS,
  FETCH_CASTE,
  FETCH_OLD_DISTRICTS,
  FETCH_EXAM_CENTERS,
  RESET_CONFIG,
  SAVE_SYS_CONFIG,
  GET_SYS_CONFIG,
  FETCH_CONFIG,
  SAVE_CONFIG,
  DEL_CONFIG,
} from "../utils/const";
import { ADMIN_UPLOAD, RESET_MESSAGES } from "../../admin/utils/consts";
import { ALL_SURVEY_UPLOAD_CAT, MARKS_CONFIG } from "../../sampoorna-siksha/utils/consts";

const initialState = {
  loading: false,
  oldDistrictsList: [],
  oldDistrictsListLoading: false,
  districtsList: [],
  districtsListLoading: false,
  mandalsList: [],
  mandalsListLoading: false,
  schoolsList: [],
  schoolsListLoading: false,
  castesList: [],
  castesListLoading: false,
  examCenters: [],
  examCentersLoading: false,
  systemConfig: [],
  successMessage: "",
  currentConfig: {},
  marksConfig: [],
  serverDown: false,
};

const AppConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_OLD_DISTRICTS}_PENDING`: {
      return {
        ...state,
        loading: true,
        oldDistrictsListLoading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${FETCH_OLD_DISTRICTS}_FULFILLED`: {
      return {
        ...state,
        oldDistrictsList: action.payload,
        districtsList: [],
        mandalsList: [],
        schoolsList: [],
        // examCenters: [],
        loading: false,
        oldDistrictsListLoading: false,
      };
    }

    case `${FETCH_DISTRICTS}_PENDING`: {
      return {
        ...state,
        loading: true,
        districtsListLoading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${FETCH_DISTRICTS}_FULFILLED`: {
      return {
        ...state,
        districtsList: action.payload,
        loading: false,
        districtsListLoading: false,
      };
    }

    case `${FETCH_MANDALS}_PENDING`: {
      return {
        ...state,
        loading: true,
        mandalsListLoading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${FETCH_MANDALS}_FULFILLED`: {
      return {
        ...state,
        mandalsList: action.payload,
        loading: false,
        mandalsListLoading: false,
      };
    }

    case `${FETCH_SCHOOLS}_PENDING`: {
      return {
        ...state,
        loading: true,
        schoolsListLoading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${FETCH_SCHOOLS}_FULFILLED`: {
      return {
        ...state,
        schoolsList: action.payload,
        loading: false,
        schoolsListLoading: false,
      };
    }

    case `${FETCH_CASTE}_PENDING`: {
      return {
        ...state,
        loading: true,
        castesListLoading: true,
      };
    }

    case `${FETCH_CASTE}_FULFILLED`: {
      return {
        ...state,
        castesList: action.payload,
        loading: false,
        castesListLoading: false,
      };
    }

    case `${FETCH_EXAM_CENTERS}_PENDING`: {
      return {
        ...state,
        loading: true,
        examCentersLoading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${FETCH_EXAM_CENTERS}_FULFILLED`: {
      return {
        ...state,
        examCenters: action.payload,
        loading: false,
        examCentersLoading: false,
      };
    }

    case RESET_CONFIG: {
      return {
        ...state,
        oldDistrictsList: [],
        districtsList: [],
        mandalsList: [],
        schoolsList: [],
        castesList: [],
        examCenters: [],
      };
    }

    case `${GET_SYS_CONFIG}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
        systemConfig: [],
      };
    }

    case `${GET_SYS_CONFIG}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        serverDown: false,
        systemConfig: action.payload,
      };
    }

    case `${GET_SYS_CONFIG}_REJECTED`: {
      return {
        ...state,
        loading: false,
        serverDown: true,
        errorMessage: action.payload.data,
      };
    }

    case `${SAVE_CONFIG}_PENDING`:
    case `${DEL_CONFIG}_PENDING`:
    case `${SAVE_SYS_CONFIG}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${SAVE_SYS_CONFIG}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        systemConfig: action.payload,
        successMessage: "Saved Successfully...",
      };
    }

    case `${SAVE_CONFIG}_REJECTED`:
    case `${DEL_CONFIG}_REJECTED`:
    case `${SAVE_SYS_CONFIG}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${SAVE_CONFIG}_FULFILLED`:
    case `${DEL_CONFIG}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    }

    case `${ADMIN_UPLOAD}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${ADMIN_UPLOAD}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    }

    case `${ADMIN_UPLOAD}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${FETCH_CONFIG}_PENDING`: {
      return {
        ...state,
        loading: true,
        currentConfig: {},
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${FETCH_CONFIG}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        currentConfig: action.payload,
      };
    }

    case `${FETCH_CONFIG}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${MARKS_CONFIG}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        marksConfig: action.payload,
      };
    }

    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    }

    default:
      return state;
  }
};

export default AppConfigReducer;

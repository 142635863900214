import {
  Button,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Form,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  deleteConfig,
  fetchDistricts,
  fetchMandals,
  saveConfig,
} from "../../base/actions/AppConfigActions";
import { DIST, MNDL } from "../utils/const";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import { DEL_CONFIG, FETCH_CONFIG, SAVE_CONFIG } from "../../base/utils/const";
import { formItemLayout } from "../../sampoorna-siksha/utils/consts";
import { exportFile } from "../../common/utils/export";

const MNDL_LIST_COLS = [
  {
    title: "Mandal Name",
    dataIndex: "configVal",
    key: "configVal",
    render: (text, record) => record.configVal,
    sorter: {
      compare: (a, b) => sortNames(a.configVal, b.configVal),
    },
  },
  {
    title: "District Name",
    dataIndex: "parentTypeCd",
    key: "parentTypeCd",
    render: (text, record) => record.parent?.configVal,
    sorter: {
      compare: (a, b) => sortNames(a.parent?.configVal, b.parent?.configVal),
    },
  },
];

const MandalForm = (props) => {
  const [formData, setFormData] = useState(props.appConfig.currentConfig);

  useEffect(() => {
    props.fetchDistricts();
  }, []);

  const saveConfig = (formValues) => {
    console.log(formValues);
    formValues.configCd = MNDL;
    formValues.parentTypeCd = DIST;
    props.saveConfig(formValues);
  };
  return (
    <React.Fragment>
      <Modal
        title={formData.id ? "Edit Mandal" : "Add Mandal"}
        visible={props.masterTable.showModal === MNDL}
        footer={null}
        onCancel={() => props.toogleAddModal("")}
      >
        <Spin spinning={props.masterTable.loading} delay={500}>
          <Form
            name="subject"
            className="login-form login-wrapper"
            onFinish={saveConfig}
            initialValues={formData}
          >
            <Form.Item {...formItemLayout} name="id" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item {...formItemLayout} name="configCd" hidden={true}>
              <Input />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              name="configVal"
              label="Mandal Name"
              rules={[
                {
                  required: true,
                  pattern: /^\S[A-Za-z\s]+$/,
                  message: "Please enter valid Mandal Name",
                },
              ]}
            >
              <Input maxLength="50" />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              name="parentId"
              label="District"
              rules={[
                {
                  required: true,
                  message: "Please select District",
                },
              ]}
            >
              <Select className="full-width">
                {props.appConfig.districtsList.map((cat) => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.configVal}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div className="modal-form-button-div">
              <Button
                type="primary"
                htmlType="submit"
                className="modal-form-submit-button"
                disabled={props.appConfig.loading}
              >
                Save
              </Button>
            </div>
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  );
};

const Mandals = (props) => {
  const [record, setRecord] = useState({});

  useEffect(() => {
    props.fetchMandals();
  }, []);

  const handleAdd = () => {
    props.setCurrentConfig({});
    props.toogleAddModal(MNDL);
  };

  const editConfig = (record, e) => {
    e.preventDefault();
    props.setCurrentConfig(record);
    props.toogleAddModal(MNDL);
  };

  const deleteConfig = (record, e) => {
    e.preventDefault();
    Modal.confirm({
      title: "Do you want to delete this Mandal?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        props.deleteMandalConfig(record.id);
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  const exportData = () => {
    exportFile(MNDL_LIST_COLS, props.appConfig.mandalsList, "Mandals");
  };


  if (MNDL_LIST_COLS.length < 3) {
    MNDL_LIST_COLS.push({
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <a href="/" onClick={(e) => editConfig(record, e)}>
              <EditOutlined />
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <a href="/" onClick={(e) => deleteConfig(record, e)}>
              <DeleteOutlined />
            </a>
          </Tooltip>
        </Space>
      ),
    });
  }
  return (
    <React.Fragment>
      <h2>
        Mandals
        <Button
          className="add-cat-button"
          onClick={(e) => handleAdd(e)}
          type="primary"
          style={{ float: "right" }}
          disabled={props.appConfig.loading}
        >
          Add Mandal
        </Button>
        <Button
          className="add-cat-button"
          onClick={(e) => exportData(e)}
          style={{ float: "right", marginRight: "10px" }}
          disabled={props.appConfig.loading}
        >
          Export Excel
        </Button>
      </h2>
      <Spin
        spinning={props.masterTable.loading || props.appConfig.loading}
        delay={500}
      >
        <div>
          <Table
            columns={MNDL_LIST_COLS}
            dataSource={props.appConfig.mandalsList}
            pagination={{
              showSizeChanger: true,
              showTotal: () => {
                const total = props.appConfig.mandalsList.length;
                return `Total ${total}`;
              },
            }}
          />
        </div>
      </Spin>
      {props.masterTable.showModal === MNDL && (
        <MandalForm record={record} {...props} />
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // offlineSearch(searchText, profiles) {
    //   if (searchText && searchText.length > 1) {
    //     const result = profiles.filter((profile) => {
    //       return studProfPredicate(profile, searchText);
    //     });
    //     dispatch({
    //       type: SET_FILTERED_PROFILES,
    //       payload: result,
    //     });
    //   } else if (!searchText) {
    //     dispatch({
    //       type: SET_FILTERED_PROFILES,
    //       payload: profiles,
    //     });
    //   }
    // },

    fetchMandals() {
      dispatch(fetchMandals(-1));
    },
    fetchDistricts() {
      dispatch(fetchDistricts());
    },
    saveConfig(form) {
      dispatch(saveConfig(form)).then((res) => {
        if (res.action.type === `${SAVE_CONFIG}_FULFILLED`) {
          this.fetchMandals();
          this.toogleAddModal("");
        }
      });
    },
    deleteMandalConfig(id) {
      dispatch(deleteConfig(id)).then((res) => {
        if (res.action.type === `${DEL_CONFIG}_FULFILLED`) {
          this.fetchMandals();
        }
      });
    },
    setCurrentConfig(config) {
      dispatch({
        type: `${FETCH_CONFIG}_FULFILLED`,
        payload: config,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, AdminReducer, MasterTablesReducer, AppConfigReducer } =
    state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    masterTable: MasterTablesReducer,
    appConfig: AppConfigReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Mandals)
);

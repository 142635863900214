import { axiosInstance } from '../../Interceptor';

export const getFile = (path) => {
    const url = `/upload/download?path=${encodeURIComponent(path)}`;
    // axiosInstance.get(url);
    axiosInstance.get(url, { responseType: "blob" })
      .then((response) => {
          // console.log(response);
        const url = window.URL.createObjectURL(response);
        // window.open(url, '_blank');
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = "Result";
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };
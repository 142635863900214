import React from 'react';
import { List, Spin } from 'antd';

class Comments extends React.Component {

    render() {
        const reasons = this.props.sampurnaSiksha.comments;
        return (
            <div>
                <Spin spinning={this.props.sampurnaSiksha.loading} delay={500}>
                <List
                    header={<div>Reasons</div>}
                    bordered
                    dataSource={reasons}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.extraInfo}
                                description={item.comment}
                            />
                        </List.Item>
                    )}
                />
                </Spin>
            </div>
        );
    }
}

export default Comments;
import { Col, Empty } from "antd";
import React from "react";
import { translate } from "../../../base/actions/translate";
import ViewSurveyCard from "./ViewSurveyCard";

class PersonalInfoView extends React.Component {
  generateFieldData = (personalInfo) => {
    const html = [];
    if (Array.isArray(personalInfo)) {
      for (const j of personalInfo) {
        for (const i in j) {
          html.push(
            <Col xs={24} sm={12}>
              <label>{translate(i)}</label>:
            </Col>,
            <Col xs={24} sm={12}>
              {" "}
              <span>{j[i]}</span>
            </Col>
          );
        }
      }
    } else {
      for (const i in personalInfo) {
        if (!Array.isArray(personalInfo[i])) {
          html.push(
            <Col xs={24} sm={12}>
              <label>{translate(i)}</label>:
            </Col>,
            <Col xs={24} sm={12}>
              {" "}
              <span>{personalInfo[i]}</span>
            </Col>
          );
        }
      }
    }

    return html;
  };
  render() {
    let info = this.props.data;
    if(!info) {
      return (
        <ViewSurveyCard title="Personal Information" {...this.props}>
          <Empty />
        </ViewSurveyCard>
      );
    }
    return (
      <div>
        <ViewSurveyCard title="Personal Information" {...this.props}>
          {this.generateFieldData(info)}
          {this.generateFieldData(info?.siblingsList)}
        </ViewSurveyCard>
      </div>
    );
  }
}

export default PersonalInfoView;

import { connect } from "react-redux";
import SampoornaSiksha from "../components/SampoornaSiksha";

const mapDispatchToProps = dispatch => {
    return {
      
    };
  };

  const mapStateToProps = state => {
    const { AuthReducer, AdminReducer } = state;
    return {
      isLoading: AuthReducer.isLoading,
      profile: AuthReducer.profile,
      lang: AuthReducer.lang,
      role: AuthReducer.role,
      admin: AdminReducer
    }
  }

  export default connect(mapStateToProps,mapDispatchToProps)(SampoornaSiksha);
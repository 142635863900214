import { Col, Form, Row, Input, Select, DatePicker, Button } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { formItemLayout } from "../../../sampoorna-siksha/utils/consts";
import { checkEligibility } from "../../utils/util";
import { APRV, DEN, PEND, COMP } from "../../utils/const";
import CommentsAndDocs from "./CommentsAndDocs";
import { MED_REQ } from "../../../mastertables/utils/const";

const dateFormat = "DD/MM/YYYY";

const MedicalRequestDetails = (props) => {
  const [medicalRequest, setMedicalRequest] = useState([]);
  const [form] = Form.useForm();
  const [formUpdated, setFormUpdated] = useState(false);
  const [status, setStatus] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [eligibleLevel, setEligibleLevel] = useState([]);

  useEffect(() => {
    props.getReqCats(MED_REQ);
  }, []);

  useEffect(() => {
    if(props.currentTask.eligibleLevel) {
      const e = props.currentTask?.eligibleLevel?.split(",");
      setEligibleLevel(e);
    }
  }, [props.currentTask]);

  useEffect(() => {
    if (
      props.tasks.currentTask.entity &&
      props.tasks.currentTask.entity.length > 0 &&
      !props.tasks.loading
    ) {
      const record = props.tasks.currentTask.entity[0];
      record.requestDate = moment(record.requestDate);
      if (record.followupDate) {
        record.followupDate = moment(record.followupDate);
      }
      setMedicalRequest(record);
    }
  }, [props.tasks.currentTask]);

  const onFinish = (formValues) => {
    console.log("-------", formValues);
    formValues.status = status;
    setEditMode(false);
    form.setFieldsValue({
      comment: "",
    });
    props.saveMedicalRequest(
      {
        request: formValues,
        studentProfileId: medicalRequest.studentProfileId,
        comment: formValues.comment,
        // uploadId: uploadedFiles[0].managementUploadId,
      },
      props.currentTask,
      props.taskFilter
    );
  };

  const onValueChange = () => {
    setFormUpdated(true);
  };

  const changeStatus = (status) => {
    setStatus(status);
    form.submit();
  };

  return (
    <div className="med-req-approval-container">
      {medicalRequest.studentProfile !== undefined && (
        <>
          <div style={{ marginBottom: "10px" }}>
            <div>
              <Form
                initialValues={medicalRequest}
                onFinish={onFinish}
                form={form}
                onValuesChange={(e) => onValueChange(e)}
              >
                <Form.Item
                  {...formItemLayout}
                  name="medicalRequestID"
                  hidden={true}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="studentProfileId"
                  hidden={true}
                >
                  <Input />
                </Form.Item>
                <Row gutter={[16, 8]}>
                  <Col sm={12} xs={24}>
                    <Form.Item
                      {...formItemLayout}
                      name="medicalType"
                      label="Medical Type"
                      rules={[
                        {
                          required: true,
                          message: "Please select Medical Type",
                        },
                      ]}
                    >
                      <Select className="full-width" disabled={!editMode}>
                        {props.masterTable.reqCat.map((typ) => (
                          <Select.Option
                            key={typ.requestCategoryId}
                            value={typ.requestCategoryId}
                          >
                            {typ.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={12} xs={24}>
                    <Form.Item
                      {...formItemLayout}
                      name="specialization"
                      label="Specialisation"
                      rules={[
                        {
                          required: true,
                          message: "Please enter doctor Specialisation",
                        },
                      ]}
                    >
                      <Input disabled={!editMode} />
                    </Form.Item>
                  </Col>
                  <Col sm={12} xs={24}>
                    <Form.Item
                      {...formItemLayout}
                      name="doctor"
                      label="Doctor Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Doctor Name",
                        },
                      ]}
                    >
                      <Input disabled={!editMode} />
                    </Form.Item>
                  </Col>
                  <Col sm={12} xs={24}>
                    <Form.Item
                      {...formItemLayout}
                      name="medicalFacility"
                      label="Medical Facility"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Medical Facility name",
                        },
                      ]}
                    >
                      <Input disabled={!editMode} />
                    </Form.Item>
                  </Col>
                  <Col sm={12} xs={24}>
                    <Form.Item
                      {...formItemLayout}
                      name="requestDate"
                      label="Date"
                      rules={[
                        {
                          required: true,
                          message: "Please select Join Date",
                        },
                      ]}
                    >
                      <DatePicker
                        format={dateFormat}
                        className="full-width"
                        showToday={false}
                        disabled={!editMode}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={12} xs={24}>
                    <Form.Item
                      {...formItemLayout}
                      name="details"
                      label="Details"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Details",
                        },
                      ]}
                    >
                      <Input.TextArea disabled={!editMode} />
                    </Form.Item>
                  </Col>
                  <Col sm={12} xs={24}>
                    <Form.Item
                      {...formItemLayout}
                      name="amount"
                      label="Amount"
                      rules={[
                        {
                          pattern: /^[1-9][0-9]*$/,
                          message: "Please enter a valid Amount",
                        },
                      ]}
                    >
                      <Input disabled={!editMode} />
                    </Form.Item>
                  </Col>
                  <Col sm={12} xs={24}>
                    <Form.Item
                      {...formItemLayout}
                      name="followupDate"
                      label="Follow up Date"
                    >
                      <DatePicker
                        format={dateFormat}
                        className="full-width"
                        showToday={false}
                        disabled={!editMode}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={12} xs={24}>
                    <Form.Item
                      {...formItemLayout}
                      name="followupDetails"
                      label="Follow up Details"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (
                              formUpdated &&
                              !value &&
                              getFieldValue("followupDate")
                            ) {
                              return Promise.reject(
                                "Please enter details of follow up."
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.TextArea disabled={!editMode} />
                    </Form.Item>
                  </Col>
                </Row>
                {checkEligibility(
                  eligibleLevel,
                  props.role,
                  props.currentTask.status
                ) && (
                  <Form.Item
                    label="Reason"
                    name="comment"
                    rules={[
                      { required: true, message: "Please enter the reason" },
                    ]}
                  >
                    <Input.TextArea rows={2} />
                  </Form.Item>
                )}
              </Form>
            </div>
            <CommentsAndDocs
              currentTask={props.currentTask}
              eligibleLevel={eligibleLevel}
              showDocs={true}
              {...props}
            />
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              {checkEligibility(
                eligibleLevel,
                props.role,
                props.currentTask.status
              ) &&
                props.currentTask.status === PEND && (
                  <Button
                    type="primary"
                    onClick={(e) => changeStatus(APRV)}
                    style={{ marginRight: 8 }}
                  >
                    Approve
                  </Button>
                )}
              {checkEligibility(
                eligibleLevel,
                props.role,
                props.currentTask.status
              ) &&
                props.currentTask.status === APRV && (
                  <Button
                    type="primary"
                    onClick={(e) => changeStatus(APRV)}
                    style={{ marginRight: 8 }}
                  >
                    Completed
                  </Button>
                )}
              {checkEligibility(
                eligibleLevel,
                props.role,
                props.currentTask.status
              ) &&
                props.currentTask.status === COMP && (
                  <Button
                    type="primary"
                    onClick={(e) => changeStatus(COMP)}
                    style={{ marginRight: 8 }}
                  >
                    Close Request
                  </Button>
                )}
              {checkEligibility(
                eligibleLevel,
                props.role,
                props.currentTask.status
              ) &&
                props.currentTask.status !== COMP && (
                  <Button
                    onClick={(e) => changeStatus(DEN)}
                    style={{ marginRight: 8 }}
                    danger
                  >
                    Deny
                  </Button>
                )}

              {checkEligibility(
                eligibleLevel,
                props.role,
                props.record.status
              ) &&
                props.showEdit &&
                !editMode && (
                  <Button
                    type="primary"
                    onClick={(e) => setEditMode(true)}
                    style={{ marginRight: 8 }}
                  >
                    Edit
                  </Button>
                )}

              {checkEligibility(
                eligibleLevel,
                props.role,
                props.record.status
              ) &&
                props.showEdit &&
                editMode && (
                  <Button
                    onClick={(e) => {
                      setEditMode(false);
                      form.setFieldsValue(medicalRequest);
                    }}
                    style={{ marginRight: 8 }}
                  >
                    Cancel
                  </Button>
                )}

              <Button
                onClick={(e) => props.onClose(e)}
                style={{ marginRight: 8 }}
              >
                Close
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MedicalRequestDetails;

import { Card, Col, Row, Select, Space } from "antd";
import React from "react";
import {
  AFFL,
  CAT,
  CRED_GRADE,
  CRED_PERCENT,
  CUR,
  GRADE,
  SUB,
} from "../utils/const";
import { Button, Form, Input, Modal, Spin } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const subFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const marksFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
};

class AddCuricullum extends React.Component {
  state = {
    courses: [],
    affiliate: {},
    onLoad: true,
    semTotal: [],
  };
  formRef = React.createRef();
  componentDidMount() {
    // this.props.fetchEducationCategories();
    this.props.fetchMasterTableData(CAT);
    this.props.fetchMasterTableData(AFFL);
    this.props.fetchMasterTableData(SUB);
    this.props.fetchMasterTableData(GRADE);
    const record = this.props.record;
    if (record?.course?.categoryId) {
      this.props.fetchCoursesByCatId(record?.course?.categoryId);
    }
    if (record?.affiliation) {
      this.setState({ affiliate: record?.affiliation });
    }
    this.populateSemScores();
  }

  populateSemScores = () => {
    const record = this.props.masterTable.currentRecord;
    const initValues = {
      curriculumId: record.curriculumId,
      curiculumCode: record.code,
      curriculumName: record.name,
      affiliateId: record.affiliateId,
      categoryId: record.course?.categoryId,
      courseId: record.courseId,
      effectiveYear: record.effectiveYear,
      semisterViews: this.generateSemisterView(record),
    };
    this.onValChange(null, initValues);
  };

  populateCourses = (val) => {
    // let courses = [];
    let selCat = {};
    for (const cat of this.props.masterTable.categories) {
      if (cat.categoryId === val) {
        // courses = cat.courses;
        selCat = cat;
      }
    }
    this.props.fetchCoursesByCatId(val);
    // this.setState({ courses, onLoad: false });
    if (this.formRef.current) {
      const sems = [];
      for (const s of selCat.semisters) {
        sems.push({
          semId: s.semId,
          semisterName: s.semName,
          semisterSubjects: [],
        });
      }
      this.formRef.current.setFieldsValue({
        semisterViews: sems,
        courseId: "",
      });
    }
  };

  updateAffiliation = (val) => {
    let selAffl = {};
    for (const cat of this.props.masterTable.affiliations) {
      if (cat.affiliateId === val) {
        selAffl = cat;
      }
    }
    this.setState({ affiliate: selAffl });
  };

  saveCuriculum = (form) => {
    this.props.saveMasterTableData(CUR, form);
  };

  generateSemisterView = (record) => {
    const semSubj = [];
    if (!record.course) {
      return semSubj;
    }
    const sem = record.course?.educationCategory.semisters;

    for (const s of sem) {
      const semView = {};
      semView.semId = s.semId;
      semView.semisterName = s.semName;
      semView.semisterSubjects = [];
      for (const sub of s.curriculumSubjects) {
        if (record.curriculumId === sub.curriculumId) {
          const sSub = {
            curSubId: sub.curSubId,
            subjectId: sub.subjectId,
            maxMarks: sub.maxMarks,
            credits: sub.credits,
          };
          semView.semisterSubjects.push(sSub);
        }
      }
      semSubj.push(semView);
    }
    return semSubj;
  };

  onValChange = (changed, all) => {
    console.log(changed, all);
    const semTotal = [];
    let marksSys = CRED_PERCENT;
    const record = this.props.masterTable.currentRecord;
    if (record && record.affiliation) {
      marksSys = record.affiliation.credSystem;
    }
    if (all.semisterViews && all.semisterViews.length > 0) {
      for (const sem of all.semisterViews) {
        let total = 0;
        for (const m of sem.semisterSubjects) {
          if (m && marksSys === CRED_GRADE && !isNaN(m.credits)) {
            total += Number(m.credits);
          } else if (m && marksSys === CRED_PERCENT && !isNaN(m.maxMarks)) {
            total += Number(m.maxMarks);
          } else {
            total += 0;
          }
        }
        semTotal.push(total);
      }
    }
    console.log(semTotal);
    this.setState({ semTotal });
  };

  render() {
    const record = this.props.masterTable.currentRecord;
    const fieldData = [
      {
        name: ["curriculumId"],
        value: record.curriculumId,
      },
      {
        name: ["curiculumCode"],
        value: record.code,
      },
      {
        name: ["curriculumName"],
        value: record.name,
      },
      {
        name: ["affiliateId"],
        value: record.affiliateId,
      },
      {
        name: ["categoryId"],
        value: record.course?.categoryId,
      },
      {
        name: ["courseId"],
        value: record.courseId,
      },
      {
        name: ["effectiveYear"],
        value: record.effectiveYear,
      },
      {
        name: ["semisterViews"],
        value: this.generateSemisterView(record),
      },
    ];
    const initValues = {
      curriculumId: record.curriculumId,
      curiculumCode: record.code,
      curriculumName: record.name,
      affiliateId: record.affiliateId,
      categoryId: record.course?.categoryId,
      courseId: record.courseId,
      effectiveYear: record.effectiveYear,
      semisterViews: this.generateSemisterView(record),
      gradeSystemId: record.gradeSystemId,
    };
    console.log(fieldData);
    // let courses = this.state.courses;
    // if (
    //   this.state.onLoad &&
    //   this.props.masterTable.categories.length > 0 &&
    //   this.state.courses.length === 0 &&
    //   record.courseId
    // ) {
    //   // this.populateCourses(record.courseId);
    //   const cat = this.props.masterTable.categories.find(c => c.categoryId === record.course.categoryId);
    //   courses = cat?.courses;
    // }
    const text = record.curriculumId ? "Edit Curriculum" : "Add Curriculum";
    return (
      <div id="add-cur-modal">
        <Modal
          title={text}
          visible={this.props.masterTable.showModal === CUR}
          footer={null}
          onCancel={() => this.props.toogleAddModal("")}
          width="90%"
        >
          <Spin spinning={this.props.masterTable.loading} delay={500}>
            <div className="form-container">
            {(this.props.masterTable.currentRecord.curriculumId || !this.props.record.curriculumId) ?
            <Form
              name="curiculum"
              className="login-form login-wrapper"
              onFinish={this.saveCuriculum}
              // fields={fieldData}
              initialValues={initValues}
              ref={this.formRef}
              onValuesChange={this.onValChange}
            >
              <Form.Item {...formItemLayout} name="curriculumId" hidden={true}>
                <Input />
              </Form.Item>
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="curiculumCode"
                    label="Curriculum Code"
                    rules={[
                      {
                        pattern: /^\S/,
                        message: "Please enter a valid Curriculum Code",
                      },
                    ]}
                  >
                    <Input maxLength="6" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="curriculumName"
                    label="Curriculum Name"
                    rules={[
                      {
                        required: true,
                        pattern: /^\S/,
                        message: "Please enter Curriculum Name",
                      },
                    ]}
                  >
                    <Input maxLength="200" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="categoryId"
                    label="Category Type"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Category Type",
                      },
                    ]}
                  >
                    <Select
                      className="full-width"
                      onChange={(val) => this.populateCourses(val)}
                    >
                      {this.props.masterTable.categories.map((cat) => (
                        <Select.Option
                          key={cat.categoryId}
                          value={cat.categoryId}
                        >
                          {cat.categoryName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="courseId"
                    label="Course"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Course",
                      },
                    ]}
                  >
                    <Select className="full-width">
                      {this.props.masterTable.courses.map((cat) => (
                        <Select.Option key={cat.courseId} value={cat.courseId}>
                          {cat.courseName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="affiliateId"
                    label="Affiliation"
                    rules={[
                      {
                        required: true,
                        message: "Please select an Affiliation",
                      },
                    ]}
                  >
                    <Select
                      className="full-width"
                      onChange={(val) => this.updateAffiliation(val)}
                    >
                      {this.props.masterTable.affiliations.map((cat) => (
                        <Select.Option
                          key={cat.affiliateId}
                          value={cat.affiliateId}
                        >
                          {cat.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="effectiveYear"
                    label="Effective Year"
                    rules={[
                      {
                        required: true,
                        pattern: /^[1-9][0-9]{3}$/,
                        message: "Please enter Effective Year",
                      },
                    ]}
                  >
                    <Input maxLength="4" />
                  </Form.Item>
                </Col>
                {this.state.affiliate.credSystem === 2 ? (
                  <Col xs={24} sm={12}>
                    <Form.Item
                      {...formItemLayout}
                      name="gradeSystemId"
                      label="Grading System"
                      rules={[
                        {
                          required: true,
                          message: "Please select a Grading System",
                        },
                      ]}
                    >
                      <Select className="full-width">
                        {this.props.masterTable.grades.map((g) => (
                          <Select.Option
                            key={g.gradeSystemId}
                            value={g.gradeSystemId}
                          >
                            {g.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}
              </Row>

              {/* Configuring semisters in the category */}
              <Form.List name="semisterViews">
                {(fields) => (
                  <Row gutter={[16, 8]}>
                    {fields.map((field, idx) => (
                      <Col xs={24} sm={12}>
                        <Card
                          title={
                            <Space>
                              <span>{"Semester-" + (idx + 1)}</span>
                              {this.state.semTotal[idx] ? (
                                <span>
                                  (Total - {this.state.semTotal[idx]})
                                </span>
                              ) : (
                                ""
                              )}
                            </Space>
                          }
                        >
                          <Form.Item
                            {...formItemLayout}
                            name={[field.name, "semId"]}
                            hidden={true}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            name={[field.name, "semisterName"]}
                            fieldKey={[field.fieldKey, "semisterName"]}
                            label="Semester Name"
                            rules={[
                              {
                                required: true,
                                pattern: /^\S/,
                                message: "Please enter Semester Name",
                              },
                            ]}
                          >
                            <Input maxLength="200" />
                          </Form.Item>
                          {/* Configuring subjects in each semister */}
                          <Form.List name={[field.name, "semisterSubjects"]}>
                            {(subFields, { add, remove }, { errors }) => {
                              return (
                                <div>
                                  {subFields.map((subField, idx) => (
                                    <Row key={idx} gutter={[16, 8]}>
                                      <Form.Item
                                        {...formItemLayout}
                                        name={[subField.name, "curSubId"]}
                                        hidden={true}
                                      >
                                        <Input />
                                      </Form.Item>
                                      <Col xs={24} sm={15}>
                                        <Form.Item
                                          {...subFormItemLayout}
                                          name={[
                                            // field.name,
                                            subField.name,
                                            "subjectId",
                                          ]}
                                          fieldKey={[
                                            field.fieldKey,
                                            subField.fieldKey,
                                            "subjectId",
                                          ]}
                                          label="Subject"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please select a Subject",
                                            },
                                          ]}
                                        >
                                          <Select
                                            className="full-width"
                                            showSearch
                                            filterOption={(input, option) =>
                                              option.children
                                                .toUpperCase()
                                                .includes(input.toUpperCase())
                                            }
                                          >
                                            {this.props.masterTable.subjects.map(
                                              (cat) => (
                                                <Select.Option
                                                  key={cat.subjectId}
                                                  value={cat.subjectId}
                                                >
                                                  {cat.name}
                                                </Select.Option>
                                              )
                                            )}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      {this.state.affiliate?.credSystem ===
                                        1 && (
                                        <Col xs={24} sm={8}>
                                          <Form.Item
                                            {...marksFormItemLayout}
                                            name={[
                                              //   field.name,
                                              subField.name,
                                              "maxMarks",
                                            ]}
                                            fieldKey={[
                                              field.fieldKey,
                                              subField.fieldKey,
                                              "maxMarks",
                                            ]}
                                            label="Maximum Score"
                                            rules={[
                                              {
                                                required: true,
                                                pattern: /^([0-9]*)$/,
                                                message:
                                                  "Please enter Maximum Score",
                                              },
                                            ]}
                                          >
                                            <Input maxLength="3" />
                                          </Form.Item>
                                        </Col>
                                      )}
                                      {this.state.affiliate?.credSystem ===
                                        2 && (
                                        <Col xs={24} sm={8}>
                                          <Form.Item
                                            {...marksFormItemLayout}
                                            name={[
                                              //   field.name,
                                              subField.name,
                                              "credits",
                                            ]}
                                            fieldKey={[
                                              field.fieldKey,
                                              subField.fieldKey,
                                              "credits",
                                            ]}
                                            label="Credits Assigned"
                                            rules={[
                                              ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                  if (value && !isNaN(value)) {
                                                    const splitCount = value
                                                      ? value
                                                          .toString()
                                                          .split(".")
                                                      : "";
                                                    let valid = true;
                                                    if (
                                                      splitCount.length >= 2 &&
                                                      (splitCount[0].length >
                                                        2 ||
                                                        splitCount[1].length >
                                                          2)
                                                    ) {
                                                      valid = false;
                                                    }
                                                    if (valid) {
                                                      return Promise.resolve();
                                                    }
                                                  }
                                                  return Promise.reject(
                                                    "Please enter Credits Assigned"
                                                  );
                                                },
                                              }),
                                            ]}
                                          >
                                            <Input />
                                          </Form.Item>
                                        </Col>
                                      )}

                                      <Col sm={1}>
                                        <MinusCircleOutlined
                                          onClick={() => {
                                            remove(subField.name);
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  ))}

                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => {
                                        add();
                                      }}
                                      block
                                    >
                                      <PlusOutlined /> Add Subject
                                    </Button>
                                    {/* <Form.ErrorList errors={errors} /> */}
                                  </Form.Item>
                                </div>
                              );
                            }}
                          </Form.List>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                )}
              </Form.List>
              <div className="modal-form-button-div">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="modal-form-submit-button"
                  disabled={this.props.masterTable.loading}
                >
                  Save
                </Button>
              </div>
            </Form>
            :''}
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default AddCuricullum;

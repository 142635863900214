import { Button, Card, Col, Row } from "antd";
import React from "react";
import {
  AGR_OWN,
  BUS_OWN,
  CAT_OWN,
  CONS_ITEMS,
  CONT_INFO,
  HOUSE_OWN,
  INC_SUM,
  NGHB_DTLS,
  OCC_DTLS,
  PER_INFO,
  PLOT_OWN,
  RESP_TO_MSS,
  STUD_INFO,
  UPLD_SUR,
  VEHC_INFO,
  VOL_CMNTS,
} from "../../utils/consts";
import ImageViewer from "../reviewSteps/ImageViewer";
import AgricultureView from "./AgricultureView";
import BusinessView from "./BusinessView";
import CattleView from "./CattleView";
import ConsumerItemsView from "./ConsumerItemsView";
import ContactInformationView from "./ContactInformationView";
import HousesView from "./HousesView";
import IncomeSummaryView from "./IncomeSummaryView";
import NeighbourRefView from "./NeighbourRefView";
import OccupationDetailsView from "./OccupationDetailsView";
import PersonalInfoView from "./PersonalInfoView";
import PlotsView from "./PlotsView";
import ResponseToMSSView from "./ResponseToMSSView";
import StudentInfoView from "./StudentInfoView";
import VehicleInfoView from "./VehicleInfoView";
import VolunteerCommentsView from "./VolunteerCommentsView";

class PreviewSurvey extends React.Component {
  render() {
    const { surveyImages } = this.props.review;
    const canEdit = this.props.review.formSteps.includes(UPLD_SUR);
    return (
      <div className="preview-survey-container">
        <Row gutter={[16, 8]}>
          <Col xs={24} sm={12}>
            <PersonalInfoView
               className="selection-pers-info"
              data={this.props.review.reviewFormData[PER_INFO]}
              stepKey={PER_INFO}
              {...this.props}
            ></PersonalInfoView>
          </Col>
          <Col xs={24} sm={12}>
            <ContactInformationView
              data={this.props.review.reviewFormData[CONT_INFO]}
              stepKey={CONT_INFO}
              {...this.props}
            ></ContactInformationView>
          </Col>
          <Col xs={24} sm={12}>
            <ConsumerItemsView
              data={this.props.review.reviewFormData[CONS_ITEMS]}
              stepKey={CONS_ITEMS}
              {...this.props}
            ></ConsumerItemsView>
          </Col>
          <Col xs={24} sm={12}>
            <VehicleInfoView
              data={this.props.review.reviewFormData[VEHC_INFO]}
              stepKey={VEHC_INFO}
              {...this.props}
            ></VehicleInfoView>
          </Col>
          <Col xs={24} sm={12}>
            <StudentInfoView
              data={this.props.review.reviewFormData[STUD_INFO]}
              stepKey={STUD_INFO}
              {...this.props}
            ></StudentInfoView>
          </Col>
          <Col xs={24} sm={12}>
            <ResponseToMSSView
              data={this.props.review.reviewFormData[RESP_TO_MSS]}
              stepKey={RESP_TO_MSS}
              {...this.props}
            ></ResponseToMSSView>
          </Col>
          <Col xs={24} sm={12}>
            <VolunteerCommentsView
              data={this.props.review.reviewFormData[VOL_CMNTS]}
              stepKey={VOL_CMNTS}
              {...this.props}
            ></VolunteerCommentsView>
          </Col>
          <Col xs={24} sm={12}>
            <OccupationDetailsView
              data={this.props.review.surveyData[OCC_DTLS]}
              stepKey={OCC_DTLS}
              {...this.props}
            ></OccupationDetailsView>
          </Col>
          <Col xs={24} sm={12}>
            <AgricultureView
              data={this.props.review.surveyData[AGR_OWN]}
              stepKey={AGR_OWN}
              {...this.props}
            ></AgricultureView>
          </Col>
          <Col xs={24} sm={12}>
            <CattleView
              data={this.props.review.surveyData[CAT_OWN]}
              stepKey={CAT_OWN}
              {...this.props}
            ></CattleView>
          </Col>
          <Col xs={24} sm={12}>
            <PlotsView
              data={this.props.review.surveyData[PLOT_OWN]}
              stepKey={PLOT_OWN}
              {...this.props}
            ></PlotsView>
          </Col>
          <Col xs={24} sm={12}>
            <HousesView
              data={this.props.review.surveyData[HOUSE_OWN]}
              stepKey={HOUSE_OWN}
              {...this.props}
            ></HousesView>
          </Col>
          <Col xs={24} sm={12}>
            <BusinessView
              data={this.props.review.surveyData[BUS_OWN]}
              stepKey={BUS_OWN}
              {...this.props}
            ></BusinessView>
          </Col>
          <Col xs={24} sm={12}>
            <NeighbourRefView
              data={this.props.review.surveyData[NGHB_DTLS]}
              stepKey={NGHB_DTLS}
              {...this.props}
            ></NeighbourRefView>
          </Col>
          <Col xs={24} sm={12}>
            <IncomeSummaryView
              data={this.props.review.surveyData[INC_SUM]}
              stepKey={INC_SUM}
              {...this.props}
            ></IncomeSummaryView>
          </Col>
        </Row>

        <Card
          title="Upload Photos"
          extra={
            <span>
              {canEdit && (
                <a href="#" onClick={(e) => this.props.setStepByKey(UPLD_SUR)}>
                  Edit
                </a>
              )}
            </span>
          }
        >
          <ImageViewer imageList={surveyImages} year={this.props.currentRecord?.year} {...this.props}></ImageViewer>
        </Card>
        {!this.props.isSelComt && (
          <div className="steps-action">
            <Button onClick={(e) => this.props.prev()}>Previous</Button>
            <Button type="primary" onClick={(e) => this.props.submitSurvey()}>Submit</Button>
          </div>
        )}
      </div>
    );
  }
}

export default PreviewSurvey;

import { Card, Col, Empty, Row, Spin } from "antd";
import React from "react";
import {
  AGR_OWN,
  BUS_OWN,
  CAT_OWN,
  CONS_ITEMS,
  CONT_INFO,
  HOUSE_OWN,
  INC_SUM,
  NGHB_DTLS,
  OCC_DTLS,
  PER_INFO,
  PLOT_OWN,
  RESP_TO_MSS,
  STUD_INFO,
  UPLD_SUR,
  VEHC_INFO,
  VOL_CMNTS,
} from "../../utils/consts";
import { checkDataAvailable, reOrderSurveyImages } from "../../utils/util";
import ImageViewer from "../reviewSteps/ImageViewer";
import AgricultureView from "./AgricultureView";
import BusinessView from "./BusinessView";
import CattleView from "./CattleView";
import ConsumerItemsView from "./ConsumerItemsView";
import ContactInformationView from "./ContactInformationView";
import HousesView from "./HousesView";
import IncomeSummaryView from "./IncomeSummaryView";
import NeighbourRefView from "./NeighbourRefView";
import OccupationDetailsView from "./OccupationDetailsView";
import PersonalInfoView from "./PersonalInfoView";
import PlotsView from "./PlotsView";
import ResponseToMSSView from "./ResponseToMSSView";
import StudentInfoView from "./StudentInfoView";
import VehicleInfoView from "./VehicleInfoView";
import VolunteerCommentsView from "./VolunteerCommentsView";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@material-ui/core";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

class SelectionCommitteePreview extends React.Component {
  state = { expanded: "" };

  handleAChange = (panel) => (event, newExpanded) => {
    this.setState({ expanded: newExpanded ? panel : false });
  };

  render() {
    const { surveyImages } = this.props.review;
    const canEdit = this.props.review.formSteps.includes(UPLD_SUR);
    if (Object.keys(this.props.review.surveyData).length <= 1) {
      return (
        <Spin spinning={this.props.review.loading}>
          <Empty />
          <p>No Survey data.</p>
        </Spin>
      );
    }
    return (
      <div className="preview-survey-container">
        <Spin spinning={this.props.review.loading}>
          <Accordion
            key="personalDetails"
            expanded={this.state.expanded === "personalDetails"}
            onChange={this.handleAChange("personalDetails")}
          >
            <AccordionSummary
              aria-controls={"personalDetails-content"}
              id={"personalDetails-header"}
              style={{ paddingLeft: "10px" }}
            >
              <Typography>Personal Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Row gutter={[16, 8]}>
                {checkDataAvailable(
                  this.props.review.reviewFormData[PER_INFO]
                ) ? (
                  <Col xs={24} sm={8}>
                    <PersonalInfoView
                      data={this.props.review.reviewFormData[PER_INFO]}
                      stepKey={PER_INFO}
                      {...this.props}
                    ></PersonalInfoView>
                  </Col>
                ) : (
                  ""
                )}
                {checkDataAvailable(
                  this.props.review.reviewFormData[CONT_INFO]
                ) ? (
                  <Col xs={24} sm={8} className="draw meet">
                    <ContactInformationView
                      data={this.props.review.reviewFormData[CONT_INFO]}
                      stepKey={CONT_INFO}
                      {...this.props}
                    ></ContactInformationView>
                  </Col>
                ) : (
                  ""
                )}
                {checkDataAvailable(
                  this.props.review.reviewFormData[CONS_ITEMS]
                ) &&
                checkDataAvailable(
                  this.props.review.reviewFormData[VEHC_INFO]
                ) ? (
                  <Col xs={24} sm={8}>
                    {checkDataAvailable(
                      this.props.review.reviewFormData[CONS_ITEMS]
                    ) &&
                    this.props.review.reviewFormData[CONS_ITEMS]
                      ?.consumerItemsList.length > 0 ? (
                      <ConsumerItemsView
                        data={this.props.review.reviewFormData[CONS_ITEMS]}
                        stepKey={CONS_ITEMS}
                        {...this.props}
                      ></ConsumerItemsView>
                    ) : (
                      ""
                    )}
                    {checkDataAvailable(
                      this.props.review.reviewFormData[VEHC_INFO]
                    ) ? (
                      <VehicleInfoView
                        data={this.props.review.reviewFormData[VEHC_INFO]}
                        stepKey={VEHC_INFO}
                        {...this.props}
                      ></VehicleInfoView>
                    ) : (
                      ""
                    )}
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </AccordionDetails>
          </Accordion>

          <Accordion
            key="incomeDetails"
            expanded={this.state.expanded === "incomeDetails"}
            onChange={this.handleAChange("incomeDetails")}
          >
            <AccordionSummary
              aria-controls={"incomeDetails-content"}
              id={"incomeDetails-header"}
              style={{ paddingLeft: "10px" }}
            >
              <Typography>Income Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Row gutter={[16, 8]}>
                {checkDataAvailable(
                  this.props.review.reviewFormData[OCC_DTLS]
                ) ? (
                  <Col xs={24} sm={8}>
                    <OccupationDetailsView
                      data={this.props.review.surveyData[OCC_DTLS]}
                      stepKey={OCC_DTLS}
                      {...this.props}
                    ></OccupationDetailsView>
                  </Col>
                ) : (
                  ""
                )}
                {checkDataAvailable(
                  this.props.review.reviewFormData[AGR_OWN]
                ) &&
                this.props.review.reviewFormData[AGR_OWN]?.agricultureList
                  .length > 0 &&
                this.props.review.reviewFormData[AGR_OWN].agricultureList
                  .length > 0 ? (
                  <Col xs={24} sm={8}>
                    <AgricultureView
                      data={this.props.review.surveyData[AGR_OWN]}
                      stepKey={AGR_OWN}
                      {...this.props}
                    ></AgricultureView>
                  </Col>
                ) : (
                  ""
                )}
                {checkDataAvailable(
                  this.props.review.reviewFormData[CAT_OWN]
                ) &&
                this.props.review.reviewFormData[CAT_OWN]?.animalList.length >
                  0 ? (
                  <Col xs={24} sm={8}>
                    <CattleView
                      data={this.props.review.surveyData[CAT_OWN]}
                      stepKey={CAT_OWN}
                      {...this.props}
                    ></CattleView>
                  </Col>
                ) : (
                  ""
                )}
                {checkDataAvailable(
                  this.props.review.reviewFormData[PLOT_OWN]
                ) &&
                this.props.review.reviewFormData[PLOT_OWN]?.plotsList.length >
                  0 ? (
                  <Col xs={24} sm={8}>
                    <PlotsView
                      data={this.props.review.surveyData[PLOT_OWN]}
                      stepKey={PLOT_OWN}
                      {...this.props}
                    ></PlotsView>
                  </Col>
                ) : (
                  ""
                )}
                {checkDataAvailable(
                  this.props.review.reviewFormData[HOUSE_OWN]
                ) &&
                this.props.review.reviewFormData[HOUSE_OWN]?.housesList.length >
                  0 ? (
                  <Col xs={24} sm={8}>
                    <HousesView
                      data={this.props.review.surveyData[HOUSE_OWN]}
                      stepKey={HOUSE_OWN}
                      {...this.props}
                    ></HousesView>
                  </Col>
                ) : (
                  ""
                )}
                {checkDataAvailable(
                  this.props.review.reviewFormData[BUS_OWN]
                ) &&
                this.props.review.reviewFormData[BUS_OWN]?.businessesList
                  .length > 0 ? (
                  <Col xs={24} sm={8}>
                    <BusinessView
                      data={this.props.review.surveyData[BUS_OWN]}
                      stepKey={BUS_OWN}
                      {...this.props}
                    ></BusinessView>
                  </Col>
                ) : (
                  ""
                )}
                {checkDataAvailable(
                  this.props.review.reviewFormData[NGHB_DTLS]
                ) &&
                this.props.review.reviewFormData[NGHB_DTLS]?.neighboursList
                  .length > 0 ? (
                  <Col xs={24} sm={8}>
                    <NeighbourRefView
                      data={this.props.review.surveyData[NGHB_DTLS]}
                      stepKey={NGHB_DTLS}
                      {...this.props}
                    ></NeighbourRefView>
                  </Col>
                ) : (
                  ""
                )}
                {checkDataAvailable(
                  this.props.review.reviewFormData[INC_SUM]
                ) ? (
                  <Col xs={24} sm={8}>
                    <IncomeSummaryView
                      data={this.props.review.surveyData[INC_SUM]}
                      stepKey={INC_SUM}
                      {...this.props}
                    ></IncomeSummaryView>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </AccordionDetails>
          </Accordion>

          <Accordion
            key="otherDetails"
            expanded={this.state.expanded === "otherDetails"}
            onChange={this.handleAChange("otherDetails")}
          >
            <AccordionSummary
              aria-controls={"otherDetails-content"}
              id={"otherDetails-header"}
              style={{ paddingLeft: "10px" }}
            >
              <Typography>Other Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Row gutter={[16, 8]}>
                {checkDataAvailable(
                  this.props.review.reviewFormData[STUD_INFO]
                ) ? (
                  <Col xs={24} sm={8}>
                    <StudentInfoView
                      data={this.props.review.reviewFormData[STUD_INFO]}
                      stepKey={STUD_INFO}
                      {...this.props}
                    ></StudentInfoView>
                  </Col>
                ) : (
                  ""
                )}
                {checkDataAvailable(
                  this.props.review.reviewFormData[RESP_TO_MSS]
                ) ? (
                  <Col xs={24} sm={8}>
                    <ResponseToMSSView
                      data={this.props.review.reviewFormData[RESP_TO_MSS]}
                      stepKey={RESP_TO_MSS}
                      {...this.props}
                    ></ResponseToMSSView>
                  </Col>
                ) : (
                  ""
                )}
                {checkDataAvailable(
                  this.props.review.reviewFormData[VOL_CMNTS]
                ) ? (
                  <Col xs={24} sm={8}>
                    <VolunteerCommentsView
                      data={this.props.review.reviewFormData[VOL_CMNTS]}
                      stepKey={VOL_CMNTS}
                      {...this.props}
                    ></VolunteerCommentsView>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </AccordionDetails>
          </Accordion>

          <Card
            title="Images"
            extra={
              <span>
                {canEdit && (
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.setStepByKey(UPLD_SUR);
                    }}
                  >
                    Edit
                  </a>
                )}
              </span>
            }
          >
            <ImageViewer
              imageList={reOrderSurveyImages(surveyImages)}
              year={this.props.currentRecord?.year}
              {...this.props}
            ></ImageViewer>
          </Card>
        </Spin>
      </div>
    );
  }
}

export default SelectionCommitteePreview;

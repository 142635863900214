import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getUsersList, getUsersListByRole } from "../../admin/actions/AdminActions";
import { fetchCasteCategory } from "../../base/actions/AppConfigActions";
import { RESET_MESSAGES } from "../../base/utils/const";
import { fetchMasterTableData } from "../../mastertables/actions/masterTables";
import { TOGGLE_ADD_MODAL } from "../../mastertables/utils/const";
import { getMarksConfig } from "../../sampoorna-siksha/actions/SSActions";
import {
  approveDrafts,
  deleteDrafts,
  deleteFiles,
  updateImageAngle,
  draftImg,
  getUploadsByProfIdAndType,
} from "../actions/managementUploads";
import {
  deleteStudentAcademics,
  deleteStudentMarks,
  deleteStudHostelComments,
  deleteStudHostelDetails,
  fetchAllAvailableBatchList,
  fetchStudentProfileById,
  getIncharge,
  getStudHostelComments,
  saveStudentAcademics,
  saveStudentMarks,
  saveStudentProfile,
  saveStudHostelComments,
  saveStudHostelDetails,
  searchStudentProfiles,
  setIncharge,
  updateStudentStatus,
} from "../actions/studentList";
import StudentList from "../components/StudentList";
import {
  ADD_TO_DEL_MNG_FILES,
  SAVE_STUD_PROFILE,
  SET_EDIT_FORM,
  SET_FILTERED_PROFILES,
  SET_SEARCH_FORM,
} from "../utils/const";
import { studProfPredicate } from "../utils/utils";

const mapDispatchToProps = (dispatch) => {
  return {
    seatSearchForm(form) {
      dispatch({
        type: SET_SEARCH_FORM,
        payload: form,
      });
    },

    searchStudentProfiles(form) {
      dispatch(searchStudentProfiles(form));
    },

    offlineSearch(searchText, profiles) {
      if (searchText && searchText.length > 1) {
        const result = profiles.filter((profile) => {
          return studProfPredicate(profile, searchText);
        });
        dispatch({
          type: SET_FILTERED_PROFILES,
          payload: result,
        });
      } else if (!searchText) {
        dispatch({
          type: SET_FILTERED_PROFILES,
          payload: profiles,
        });
      }
    },

    resetStudentsList() {
      dispatch({
        type: SET_FILTERED_PROFILES,
        payload: [],
      });
    },

    fetchAllAvailableBatchList() {
      dispatch(fetchAllAvailableBatchList());
    },

    fetchMasterTableData(dataType) {
      dispatch(fetchMasterTableData(dataType));
    },

    fetchStudentProfileById(id) {
      dispatch(fetchStudentProfileById(id));
    },

    getCastes() {
      dispatch(fetchCasteCategory());
    },

    getMarksConfig() {
      dispatch(getMarksConfig());
    },

    updateImageAngle(angle, uploadId) {
      dispatch(updateImageAngle(uploadId, angle));
    },

    draftUpload(studentProfileId, file, type, batch) {
      dispatch(draftImg(studentProfileId, file, type, batch));
    },

    deleteDraft(studentProfileId) {
      dispatch(deleteDrafts(studentProfileId));
    },

    deleteFiles(filesList) {
      dispatch(deleteFiles(filesList));
    },

    addToDeleteFiles(filesList) {
      dispatch({
        type: ADD_TO_DEL_MNG_FILES,
        payload: filesList,
      });
    },

    approveDrafts(studentProfileId, filesList) {
      dispatch(approveDrafts(studentProfileId, filesList));
    },

    getUploadsByProfIdAndType(profId, type) {
      dispatch(getUploadsByProfIdAndType(profId, type));
    },

    saveStudentProfile(form) {
      dispatch(saveStudentProfile(form)).then((res) => {
        if (res.action.type === `${SAVE_STUD_PROFILE}_FULFILLED`) {
          this.fetchStudentProfileById(form.studentProfileId);
        }
      });
    },

    saveStudentStatus(statusInfo, form) {
      dispatch(updateStudentStatus(statusInfo)).then((res) => {
        this.fetchStudentProfileById(statusInfo.studentProfileId);
        this.searchStudentProfiles(form);
      });
    },

    saveStudentAcademics(form) {
      dispatch(saveStudentAcademics(form)).then((res) => {
        this.fetchStudentProfileById(form.studentProfileId);
      });
    },

    deleteStudentAcademics(record) {
      dispatch(deleteStudentAcademics(record.studentAcadId)).then((res) => {
        this.fetchStudentProfileById(record.studentProfileId);
      });
    },

    saveStudentMarks(marksList, profileId) {
      dispatch(saveStudentMarks(marksList)).then((res) => {
        this.fetchStudentProfileById(profileId);
      });
    },

    deleteStudentMarks(semId, attempt, profileId) {
      dispatch(deleteStudentMarks(semId, attempt)).then((res) => {
        this.fetchStudentProfileById(profileId);
      });
    },

    saveStudHostelDetails(form) {
      dispatch(saveStudHostelDetails(form)).then((res) => {
        this.fetchStudentProfileById(form.studentProfileId);
      });
    },

    deleteStudHostelDetails(studHostelId, profileId) {
      dispatch(deleteStudHostelDetails(studHostelId)).then((res) => {
        this.fetchStudentProfileById(profileId);
      });
    },

    getStudHostelComments(studHostelId) {
      dispatch(getStudHostelComments(studHostelId));
    },

    saveStudHostelComments(form, studentProfileId) {
      dispatch(saveStudHostelComments(form)).then((res) => {
        this.fetchStudentProfileById(studentProfileId);
      });
    },

    deleteStudHostelComments(studHostelCmntId, studentProfileId) {
      dispatch(deleteStudHostelComments(studHostelCmntId)).then((res) => {
        this.fetchStudentProfileById(studentProfileId);
      });
    },

    getUsersList() {
      dispatch(getUsersList());
    },

    getUsersListByRole(role) {
      // dispatch(getUsersList());
      dispatch(getUsersListByRole(role));
    },

    setEditForm(formName) {
      dispatch({
        type: SET_EDIT_FORM,
        payload: formName,
      });
    },

    getIncharge(profileId) {
      dispatch(getIncharge(profileId));
    },

    setIncharge(profileId, inchargeId) {
      dispatch(setIncharge(profileId,inchargeId)).then(
        res => {
          this.getIncharge(profileId);
        }
      );
    },

    toogleAddModal(modal) {
      dispatch({
        type: TOGGLE_ADD_MODAL,
        payload: modal,
      });
    },

    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    ManagementReducer,
    MasterTablesReducer,
    AppConfigReducer,
    ManagementUploadReducer,
  } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    manage: ManagementReducer,
    masterTable: MasterTablesReducer,
    appConfig: AppConfigReducer,
    mgUploads: ManagementUploadReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StudentList)
);

import React from 'react';
import { Collapse, Col, Card } from 'antd';
import '../styles/FaqComponent.css';
import { translate } from '../../base/actions/translate';

const { Panel } = Collapse;

class FaqsComponent extends React.Component {
    render() {
        return (
            <Col xs={24} sm={20} className="pd-l">
                <div className="FaqContainer">
                    <Card bordered={false} className="faq-main-heading-box">
                        <div className="Faq-main-heading">{translate('FaqMainHeading')}</div>
                    </Card>
                    <div className="Faq-heading-1">{translate('FAQHeading1')}</div>
                    <Collapse accordion>
                        <Panel header={translate('Faq1')} key="1">
                            <p>{translate('Faqans1')}</p>
                        </Panel>
                        <Panel header={translate('Faq2')} key="2">
                            <p>{translate('Faqans2')}</p>
                        </Panel>
                        <Panel header={translate('Faq3')} key="3">
                            <p>{translate('Faqans3')}</p>
                        </Panel>
                        <Panel header={translate('Faq4')} key="4">
                            <p>{translate('Faqans4')}</p>
                        </Panel>
                        <Panel header={translate('Faq5')} key="5">
                            <p>{translate('Faqans5')}</p>
                        </Panel>
                        <Panel header={translate('Faq6')} key="6">
                            <p>{translate('Faqans6')}</p>
                        </Panel>
                        <Panel header={translate('Faq7')} key="7">
                            <p>{translate('Faqans7')}</p>
                        </Panel>
                        <Panel header={translate('Faq8')} key="8">
                            <p>{translate('Faqans8')}</p>
                        </Panel>
                        <Panel header={translate('Faq9')} key="9">
                            <p>{translate('Faqans9')}</p>
                        </Panel>
                        <Panel header={translate('Faq10')} key="10">
                            <p>{translate('Faqans10')}</p>
                        </Panel>
                        <Panel header={translate('Faq11')} key="11">
                            <p>{translate('Faqans11')}</p>
                        </Panel>
                        <Panel header={translate('Faq12')} key="12">
                            <p>{translate('Faqans12')}</p>
                        </Panel>
                        <Panel header={translate('Faq13')} key="13">
                            <p>{translate('Faqans13')}</p>
                        </Panel>
                        <Panel header={translate('Faq14')} key="14">
                            <p>{translate('Faqans14')}</p>
                        </Panel>
                        <Panel header={translate('Faq15')} key="15">
                            <p>{translate('Faqans15')}</p>
                        </Panel>
                        <Panel header={translate('Faq16')} key="16">
                            <p>{translate('Faqans16')}</p>
                        </Panel>
                        <Panel header={translate('Faq17')} key="17">
                            <p>{translate('Faqans17')}</p>
                        </Panel>
                        <Panel header={translate('Faq18')} key="18">
                            <p>{translate('Faqans18')}</p>
                        </Panel>
                        <Panel header={translate('Faq19')} key="19">
                            <p>{translate('Faqans19')}</p>
                        </Panel>
                        <Panel header={translate('Faq20')} key="20">
                            <p>{translate('Faqans20')}</p>
                        </Panel>
                    </Collapse>
                    <div className="Faq-heading-1">{translate('FAQHeading2')}</div>
                    <Collapse accordion>
                        <Panel header={translate('Faq21')} key="21">
                            <p>{translate('Faqans21')}</p>
                        </Panel>
                        <Panel header={translate('Faq22')} key="22">
                            <p>{translate('Faqans22')}</p>
                        </Panel>
                        <Panel header={translate('Faq23')} key="23">
                            <p>{translate('Faqans23')}</p>
                        </Panel>
                        <Panel header={translate('Faq24')} key="24">
                            <p>{translate('Faqans24')}</p>
                        </Panel>
                        <Panel header={translate('Faq25')} key="25">
                            <p>{translate('Faqans25')}</p>
                        </Panel>
                        <Panel header={translate('Faq26')} key="26">
                            <p>{translate('Faqans26')}</p>
                        </Panel>
                        <Panel header={translate('Faq27')} key="27">
                            <p>{translate('Faqans27')}</p>
                        </Panel>
                        <Panel header={translate('Faq28')} key="28">
                            <p>{translate('Faqans28')}</p>
                        </Panel>
                        <Panel header={translate('Faq29')} key="29">
                            <p>{translate('Faqans29')}</p>
                        </Panel>
                    </Collapse>
                </div>
            </Col>
        );
    }
}

export default FaqsComponent;
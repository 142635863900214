import { connect } from "react-redux";
import { getApplication } from "../actions/StudentActions";
import HallTicket from "../components/HallTicket";
import { changeStatus } from "../../sampoorna-siksha/actions/SSActions";
import { fetchConfigByCode, getSystemConfigs } from "../../base/actions/AppConfigActions";
import { getFile } from "../../base/actions/FIleActions";

const mapDispatchToProps = dispatch => {
  return {
    getApplication(username, year) {
      dispatch(getApplication(year, username));
    },

    changeStatus(reasonView, username, year) {
      dispatch(changeStatus(reasonView)).then(
        res => {
          this.getApplication(username, year);
        }
      );
    },

    getSystemConfig() {
      dispatch(getSystemConfigs());
    },

    fetchConfigByCode(code) {
      dispatch(fetchConfigByCode(code));
    },

    getFile(path) {
      getFile(path);
    }
  };
};

const mapStateToProps = state => {
  const { AuthReducer, StudentReducer, AppConfigReducer, SSReducer } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    studentData: StudentReducer,
    appConfig: AppConfigReducer,
    ssLoding: SSReducer.loading
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HallTicket);
import { RESET_MESSAGES } from "../../base/utils/const";
import {
  AFFL,
  CAT,
  COURSE,
  CUR,
  DELETE_CATEGORY,
  DELETE_COURSE,
  DELETE_MASTER_TABLE,
  EDUCATION_TYPE,
  ED_TYP,
  FETCH_CATEGORIES,
  FETCH_COURSES,
  FETCH_MASTER_OLD_DISTRICTS,
  FETCH_MASTER_TABLE,
  FIND_MASTER_TABLE,
  GRADE,
  HOST,
  INST,
  REQ_CAT,
  REQ_SUB_CAT,
  SAVE_CATEGORY,
  SAVE_COURSE,
  SAVE_MASTER_TABLE,
  SEARCH_MASTER_TABLE,
  SET_CURRENT_REC,
  SUB,
  TOGGLE_ADD_CATEGORY,
  TOGGLE_ADD_COURSE,
  TOGGLE_ADD_MODAL,
} from "../utils/const";
import { getEntityNameByMeta } from "../utils/util";

const initialState = {
  loading: false,
  categories: [],
  courses: [],
  educationTypes: [],
  showAddCategory: false,
  showAddCourse: false,
  errorMessage: "",
  successMessage: "",
  subjects: [],
  searchSubj: [],
  affiliations: [],
  curicullums: [],
  institutes: [],
  instLoading: false,
  hostels: [],
  hostLoading: false,
  showModal: "",
  reqCat: [],
  reqSubCat: [],
  grades: [],
  oldDistrictsList: [],
  currentRecord: {}
};

const MasterTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${EDUCATION_TYPE}_PENDING`: {
      return {
        ...state,
        loading: true,
        educationTypes: [],
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${EDUCATION_TYPE}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        educationTypes: action.payload,
      };
    }

    case `${EDUCATION_TYPE}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${FETCH_CATEGORIES}_PENDING`: {
      return {
        ...state,
        loading: true,
        categories: [],
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${FETCH_CATEGORIES}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    }

    case `${FETCH_CATEGORIES}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${SAVE_CATEGORY}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${SAVE_CATEGORY}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        showAddCategory: false,
        successMessage: action.payload,
      };
    }

    case `${SAVE_CATEGORY}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${DELETE_CATEGORY}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${DELETE_CATEGORY}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    }

    case `${DELETE_CATEGORY}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: "Unable to delete this Category. Please the check whether it is mapped to any course or semisters.",
      };
    }

    case TOGGLE_ADD_CATEGORY: {
      return {
        ...state,
        showAddCategory: action.payload,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${FETCH_COURSES}_PENDING`: {
      return {
        ...state,
        loading: true,
        courses: [],
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${FETCH_COURSES}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        courses: action.payload,
      };
    }

    case `${FETCH_COURSES}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${SAVE_COURSE}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${SAVE_COURSE}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        showAddCourse: false,
        successMessage: action.payload,
      };
    }

    case `${SAVE_COURSE}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${DELETE_COURSE}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${DELETE_COURSE}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    }

    case `${DELETE_COURSE}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: "Can not delete this Course. It is already mapped to Students Profile",
      };
    }

    case TOGGLE_ADD_COURSE: {
      return {
        ...state,
        showAddCourse: action.payload,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${FETCH_MASTER_TABLE}_PENDING`: {
      return {
        ...state,
        loading: true,
        instLoading: true,
        courLoading: true,
        hostLoading: true,
        courses: [],
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${FETCH_MASTER_TABLE}_FULFILLED`: {
      let {
        affiliations,
        categories,
        courses,
        curicullums,
        educationTypes,
        subjects,
        searchSubj,
        institutes,
        instLoading,
        hostels,
        hostLoading,
        reqCat,
        reqSubCat,
        grades
      } = state;

      if (action.meta === SUB) {
        subjects = action.payload;
        searchSubj = action.payload;
      } else if (action.meta === AFFL) {
        affiliations = action.payload;
      } else if (action.meta === CAT) {
        categories = action.payload;
      } else if (action.meta === COURSE) {
        courses = action.payload;
      } else if (action.meta === CUR) {
        curicullums = action.payload;
      } else if (action.meta === ED_TYP) {
        educationTypes = action.payload;
      } else if (action.meta === INST) {
        instLoading = false;
        institutes = action.payload;
      } else if (action.meta === HOST) {
        hostels = action.payload;
        hostLoading = false;
      } else if (action.meta === REQ_CAT) {
        reqCat = action.payload;
      } else if (action.meta === REQ_SUB_CAT) {
        reqSubCat = action.payload;
      } else if (action.meta === GRADE) {
        grades = action.payload;
      }
      return {
        ...state,
        loading: false,
        affiliations,
        categories,
        courses,
        curicullums,
        educationTypes,
        subjects,
        searchSubj,
        institutes,
        instLoading,
        hostels,
        hostLoading,
        reqCat,
        reqSubCat,
        grades
      };
    }

    case `${FIND_MASTER_TABLE}_REJECTED`:
    case `${FETCH_MASTER_TABLE}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${FIND_MASTER_TABLE}_PENDING`: {
      return {
        ...state,
        loading: true,
        currentRecord: {}
      }
    }

    case `${FIND_MASTER_TABLE}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        currentRecord: action.payload,
      }
    }

    case `${SAVE_MASTER_TABLE}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${SAVE_MASTER_TABLE}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        showModal: "",
        successMessage: action.payload,
      };
    }

    case `${SAVE_MASTER_TABLE}_REJECTED`: {
      
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${DELETE_MASTER_TABLE}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${DELETE_MASTER_TABLE}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    }

    case `${DELETE_MASTER_TABLE}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: `Can not delete this ${getEntityNameByMeta(action.meta)} as it is mapped to Student profiles.`,
      };
    }

    case TOGGLE_ADD_MODAL: {
      return {
        ...state,
        showModal: action.payload,
      };
    }

    case SEARCH_MASTER_TABLE: {
      let { searchSubj } = state;
      if (action.meta === SUB) {
        searchSubj = action.payload;
      }
      return {
        ...state,
        searchSubj,
      };
    }

    case `${FETCH_MASTER_OLD_DISTRICTS}_PENDING`: {
      return {
        ...state,
        loading: true,
      };
    }

    case `${FETCH_MASTER_OLD_DISTRICTS}_FULFILLED`: {
      return {
        ...state,
        oldDistrictsList: action.payload,
        loading: false,
      };
    }

    case SET_CURRENT_REC: {
      return {
        ...state,
        currentRecord: action.payload
      };
    }

    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    }
    default:
      return state;
  }
};

export default MasterTablesReducer;

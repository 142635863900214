import { Col, Row, Form, Select, Input, Button } from "antd";
import React, { useEffect, useState } from "react";
import { checkEligibility } from "../../utils/util";
import { formItemLayout } from "../../../sampoorna-siksha/utils/consts";
import { APRV, COMP, DEN, PEND } from "../../utils/const";
import CommentsAndDocs from "./CommentsAndDocs";
import { WEL_REQ } from "../../../mastertables/utils/const";

const WelfareRequestDetails = (props) => {
  const [welfareRequest, setWelfareRequest] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [form] = Form.useForm();
  const [status, setStatus] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [eligibleLevel, setEligibleLevel] = useState([]);

  useEffect(() => {
    props.getReqCats(WEL_REQ);
  }, []);

  useEffect(() => {
    if(props.currentTask.eligibleLevel) {
      const e = props.currentTask?.eligibleLevel?.split(",");
      setEligibleLevel(e);
    }
  }, [props.currentTask]);

  useEffect(() => {
    if (
      props.tasks.currentTask.entity &&
      props.tasks.currentTask.entity.length > 0 &&
      !props.tasks.loading
    ) {
      setWelfareRequest(props.tasks.currentTask.entity[0]);
      onCatChanged(props.tasks.currentTask.entity[0].category, props.tasks.currentTask.entity[0].item);
    }
  }, [props.tasks.currentTask, props.masterTable.reqCat]);

  const onFinish = (formValues) => {
    console.log("-------", formValues);
    formValues.status = status;
    setEditMode(false);
    form.setFieldsValue({
      comment: "",
    });
    props.saveWelfareRequest(
      {
        request: formValues,
        studentProfileId: welfareRequest.studentProfileId,
        comment: formValues.comment,
      },
      props.currentTask,
      props.taskFilter
    );
  };

  const onCatChanged = (cat, item) => {
    const selCat = props.masterTable.reqCat?.find(
      (c) => c.requestCategoryId === cat
    );
    if (selCat) {
      setItemsList(selCat.requestSubCategories);
    }

    form.setFieldsValue({
      item: item,
    });
  };

  const changeStatus = (status) => {
    setStatus(status);
    form.submit();
  };

  return (
    <React.Fragment>
      <div className="med-req-approval-container">
        {welfareRequest.studentProfile !== undefined && (
          <>
            <div style={{ marginBottom: "10px" }}>
              <div>
                <Form
                  initialValues={welfareRequest}
                  onFinish={onFinish}
                  form={form}
                >
                  <Form.Item
                    {...formItemLayout}
                    name="welfareRequestID"
                    hidden={true}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    name="studentProfileId"
                    hidden={true}
                  >
                    <Input />
                  </Form.Item>
                  <Row gutter={[16, 8]}>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="category"
                        label="Category"
                        rules={[
                          {
                            required: true,
                            message: "Please select Category",
                          },
                        ]}
                      >
                        <Select
                          disabled={!editMode}
                          className="full-width"
                          onSelect={(e) => onCatChanged(e, "")}
                        >
                          {props.masterTable.reqCat.map((typ) => (
                            <Select.Option
                              key={typ.requestCategoryId}
                              value={typ.requestCategoryId}
                            >
                              {typ.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="item"
                        label="Item"
                        rules={[
                          {
                            required: true,
                            message: "Please select an Item",
                          },
                        ]}
                      >
                        <Select className="full-width" disabled={!editMode}>
                          {itemsList.map((typ) => (
                            <Select.Option
                              key={typ.requestSubCategoryId}
                              value={typ.requestSubCategoryId}
                            >
                              {typ.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="quantity"
                        label="Quantity"
                        rules={[
                          {
                            required: true,
                            pattern: /^[1-9][0-9]*$/,
                            message: "Please enter a valid Quantity",
                          },
                        ]}
                      >
                        <Input disabled={!editMode} />
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="details"
                        label="Details"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Details",
                          },
                        ]}
                      >
                        <Input.TextArea maxLength="1000" disabled={!editMode} />
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="amount"
                        label="Amount"
                        rules={[
                          {
                            pattern: /^[1-9][0-9]*$/,
                            message: "Please enter a valid Amount",
                          },
                        ]}
                      >
                        <Input disabled={!editMode} />
                      </Form.Item>
                    </Col>
                  </Row>
                  {checkEligibility(eligibleLevel, props.role, props.record.status) && (
                    <Form.Item
                      label="Reason"
                      name="comment"
                      rules={[
                        { required: true, message: "Please enter the reason" },
                      ]}
                    >
                      <Input.TextArea rows={2} />
                    </Form.Item>
                  )}
                </Form>
              </div>
              <CommentsAndDocs
                currentTask={props.currentTask}
                eligibleLevel={eligibleLevel}
                {...props}
              />
              <div style={{ textAlign: "right", marginTop: "10px" }}>
              {checkEligibility(
                eligibleLevel,
                props.role,
                props.currentTask.status
              ) &&
                props.currentTask.status === PEND && (
                  <Button
                    type="primary"
                    onClick={(e) => changeStatus(APRV)}
                    style={{ marginRight: 8 }}
                  >
                    Approve
                  </Button>
                )}
              {checkEligibility(
                eligibleLevel,
                props.role,
                props.currentTask.status
              ) &&
                props.currentTask.status === APRV && (
                  <Button
                    type="primary"
                    onClick={(e) => changeStatus(APRV)}
                    style={{ marginRight: 8 }}
                  >
                    Completed
                  </Button>
                )}
              {checkEligibility(
                eligibleLevel,
                props.role,
                props.currentTask.status
              ) &&
                props.currentTask.status === COMP && (
                  <Button
                    type="primary"
                    onClick={(e) => changeStatus(COMP)}
                    style={{ marginRight: 8 }}
                  >
                    Close Request
                  </Button>
                )}
              {checkEligibility(
                eligibleLevel,
                props.role,
                props.currentTask.status
              ) &&
                props.currentTask.status !== COMP && (
                  <Button
                    onClick={(e) => changeStatus(DEN)}
                    style={{ marginRight: 8 }}
                    danger
                  >
                    Deny
                  </Button>
                )}
                
                {checkEligibility(eligibleLevel, props.role, props.record.status) &&
                  props.showEdit &&
                  !editMode && (
                    <Button
                      type="primary"
                      onClick={(e) => {
                        setEditMode(true);
                        form.setFieldsValue({ comment: "" });
                      }}
                      style={{ marginRight: 8 }}
                    >
                      Edit
                    </Button>
                  )}

                {checkEligibility(eligibleLevel, props.role, props.record.status) &&
                  props.showEdit &&
                  editMode && (
                    <Button
                      onClick={(e) => {
                        setEditMode(false);
                        form.setFieldsValue(welfareRequest);
                      }}
                      style={{ marginRight: 8 }}
                    >
                      Cancel
                    </Button>
                  )}

                <Button
                  onClick={(e) => props.onClose(e)}
                  style={{ marginRight: 8 }}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default WelfareRequestDetails;

export const SET_MSS_APPLY_FORM = 'SET_MSS_APPLY_FORM';
export const SET_MSS_APPLY_FORM_VALUES = 'SET_MSS_APPLY_FORM_VALUES';
export const UPLOAD_STUDENT_IMG = 'UPLOAD_STUDENT_IMG';
export const UPLOAD_STUDENT_PROOF = 'UPLOAD_STUDENT_PROOF';
export const UPLOAD_STUDENT_NINTH_FRONT = 'UPLOAD_STUDENT_NINTH_FRONT';
export const UPLOAD_STUDENT_NINTH_BACK = 'UPLOAD_STUDENT_NINTH_BACK';
export const UPLOAD_FORM_ERRORS_UPDATE = 'UPLOAD_FORM_ERRORS_UPDATE';

export const STUD_IMG = 'STUD_IMG';
export const STUD_ID = 'STUD_ID';
export const MEMO_NINTH_FRONT = 'MEMO_NINTH_FRONT';
export const MEMO_NINTH_BACK = 'MEMO_NINTH_BACK';

export const SAVE_APPLICATION = 'SAVE_APPLICATION';
export const SUBMIT_APPLICATION = 'SUBMIT_APPLICATION';
export const GET_APPLICATION = 'GET_APPLICATION';
export const ADD_SCHOOL_REQUEST = 'ADD_SCHOOL_REQUEST';
export const GET_ADD_SCHOOL_REQUEST = 'GET_ADD_SCHOOL_REQUEST';

export const UPDATE_APP_TAB = 'UPDATE_APP_TAB';

export const UPLOAD_ACTN_TYPE = {
    STUD_IMG: UPLOAD_STUDENT_IMG,
    STUD_ID: UPLOAD_STUDENT_PROOF,
    MEMO_NINTH_FRONT: UPLOAD_STUDENT_NINTH_FRONT,
    MEMO_NINTH_BACK: UPLOAD_STUDENT_NINTH_BACK
}

export const FETCH_STUDENT_IMG = 'FETCH_STUDENT_IMG';
export const FETCH_STUDENT_PROOF = 'FETCH_STUDENT_PROOF';
export const FETCH_STUDENT_NINTH_FRONT = 'FETCH_STUDENT_NINTH_FRONT';
export const FETCH_STUDENT_NINTH_BACK = 'FETCH_STUDENT_NINTH_BACK';

export const ROTATE_IMG = 'ROTATE_IMG';

export const FETCH_ACTN_TYPE = {
    STUD_IMG: FETCH_STUDENT_IMG,
    STUD_ID: FETCH_STUDENT_PROOF,
    MEMO_NINTH_FRONT: FETCH_STUDENT_NINTH_FRONT,
    MEMO_NINTH_BACK: FETCH_STUDENT_NINTH_BACK
}

export const TYPE_PDF = 'application/pdf';

export const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/tiff', 'image/gif'];

export const FILE_TYPES = ['image/jpeg', 'image/png', 'image/tiff', 'image/gif', 'application/pdf'];

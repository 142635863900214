import { Card, notification, Tabs } from "antd";
import React from "react";
import {
  AFFL,
  CAT,
  COURSE,
  CUR,
  DIST,
  EXMCNT,
  GRADE,
  HOST,
  INST,
  MNDL,
  REQ_CAT,
  SCHL,
  SUB,
} from "../utils/const";
import Affiliations from "./Affiliations";
import Courses from "./Courses";
import Curicullum from "./Curicullum";
import EducationCategory from "./EducationCategory";
import Subject from "./Subjects";
import "../styles/masterTables.css";
import Institutes from "./Institutes";
import Hostels from "./Hostels";
import RequestCategories from "./RequestCategories";
import Districts from "./Districts";
import Mandals from "./Mandals";
import Schools from "./Schools";
import ExamCenters from "./ExamCenters";
import GradeSystem from "./GradeSystem";

const { TabPane } = Tabs;

const APP_CONFIG_TABS = [DIST, MNDL, SCHL, EXMCNT];

class MasterTables extends React.Component {
  callback = (key) => {
    console.log(key);
    this.props.history.push(`/mastertable/${key}`);
    if (APP_CONFIG_TABS.indexOf(key) === -1) {
      this.props.fetchMasterTableData(key);
    }
  };

  showNotifications = () => {
    let message = "";

    if (this.props.masterTable.successMessage) {
      message += this.props.masterTable.successMessage;
    } else if (this.props.appConfigSuccess) {
      message = this.props.appConfigSuccess;
    }

    if (message) {
      notification.success({
        message: "Success",
        description: message,
      });
      const that = this;
      setTimeout(function () {
        that.props.resetMessage();
      }, 3000);
    }
  };

  showErrorNotifications = () => {
    let message = "";

    if (this.props.masterTable.errorMessage) {
      message += this.props.masterTable.errorMessage;
    } else if (this.props.appConfigError) {
      message = this.props.appConfigError.message;
    }

    if (message) {
      notification.error({
        message: "Error",
        description: message,
      });

      const that = this;
      setTimeout(function () {
        that.props.resetMessage();
      }, 3000);
    }
  };

  render() {
    let activeKey = this.props.match.params.key;
    if (!activeKey) {
      activeKey = CAT;
    }
    return (
      <div id="master-tables-container">
        <Card>
          <Tabs onTabClick={(e) => this.callback(e)} activeKey={activeKey}>
            <TabPane tab="Education Categories" key={CAT}>
            {activeKey === CAT && <EducationCategory {...this.props}></EducationCategory>}
            </TabPane>
            <TabPane tab="Courses" key={COURSE}>
            {activeKey === COURSE && <Courses {...this.props}></Courses>}
            </TabPane>
            <TabPane tab="Subjects" key={SUB}>
              {activeKey === SUB && <Subject {...this.props}></Subject>}
            </TabPane>
            <TabPane tab="Affiliation" key={AFFL}>
            {activeKey === AFFL && <Affiliations {...this.props}></Affiliations>}
            </TabPane>
            <TabPane tab="Grading System" key={GRADE}>
            {activeKey === GRADE && <GradeSystem {...this.props}></GradeSystem>}
            </TabPane>
            <TabPane tab="Curriculum" key={CUR}>
            {activeKey === CUR && <Curicullum {...this.props}></Curicullum>}
            </TabPane>
            <TabPane tab="Institutes" key={INST}>
            {activeKey === INST && <Institutes {...this.props}></Institutes>}
            </TabPane>
            <TabPane tab="Hostels" key={HOST}>
            {activeKey === HOST && <Hostels {...this.props}></Hostels>}
            </TabPane>
            <TabPane tab="Request Categories" key={REQ_CAT}>
            {activeKey === REQ_CAT && <RequestCategories {...this.props}></RequestCategories>}
            </TabPane>
            <TabPane tab="Districts" key={DIST}>
            {activeKey === DIST && <Districts {...this.props}></Districts>}
            </TabPane>
            <TabPane tab="Mandals" key={MNDL}>
            {activeKey === MNDL && <Mandals {...this.props}></Mandals>}
            </TabPane>
            <TabPane tab="Schools" key={SCHL}>
            {activeKey === SCHL && <Schools {...this.props}></Schools>}
            </TabPane>
            <TabPane tab="Exam Centers" key={EXMCNT}>
            {activeKey === EXMCNT && <ExamCenters {...this.props}></ExamCenters>}
            </TabPane>
          </Tabs>
        </Card>
        {(this.props.masterTable.successMessage ||
          this.props.appConfigSuccess) &&
          this.showNotifications()}
        {(this.props.masterTable.errorMessage || this.props.appConfigError) &&
          this.showErrorNotifications()}
      </div>
    );
  }
}

export default MasterTables;

import { Button, Col, Comment, Input, List, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { PaperClipOutlined, SendOutlined } from "@ant-design/icons";
import { MSSSTUD } from "../../base/utils/roles";
import { isValidDoc, isValidOfcFile } from "../../base/utils/utils";
import ImageViewer from "../../sampoorna-siksha/components/reviewSteps/ImageViewer";
import moment from "moment";
import "../styles/studentNotes.css";
import { LOAD_MORE_NOTES, NOTES_ADD_TO_DEL_FILES } from "../utils/const";
import { connect } from "react-redux";
import { RESET_MESSAGES } from "../../base/utils/const";
import {
  getNotes,
  loadMoreNotes,
  saveNotes,
  deleteDrafts,
  approveDrafts,
  draftImg,
} from "../actions/studentNotes";

const StudentNotes = (props) => {
  const [messages, setMessages] = useState("");

  useEffect(() => {
    if (props.manage.currentProfile?.studentProfileId) {
      const profileId = props.manage.currentProfile?.studentProfileId;
      getNotes(profileId);
    }
  }, [props.manage.currentProfile]);

  useEffect(() => {
    if (!props.notes.loading) {
      setTimeout(function () {
        if (document.getElementById("messages-container")) {
          document.getElementById("messages-container").scrollTop =
            document.getElementById("messages-container").scrollHeight;
        }
      }, 300);
    }
  }, [props.notes]);

  const getNotes = (profileId) => {
    props.getNotes(profileId, 0);
  };

  const setFiles = (e) => {
    const file = document.getElementById("myIntrFileB").files[0];
    if (!file) {
      return;
    }

    if (!isValidOfcFile(file)) {
      message.error("Please upload valid file less than 500 KB.");
      document.getElementById("myIntrFileB").value = "";
      return;
    }

    const currentProfile = props.manage.currentProfile;

    // Save the new image uploaded as a draft.
    props.draftIntrUpload(
      currentProfile.studentProfileId,
      file,
      "NOTES",
      currentProfile.batch
    );
    document.getElementById("myIntrFileB").value = "";
  };

  const handleUpload = () => {
    document.getElementById("myIntrFileB").click();
  };

  const sendMessage = () => {
    if (
      messages.trim().length === 0 &&
      props.notes.currentUploads?.length === 0
    ) {
      message.error("Please enter a message.");
      return;
    }
    const profileId = props.manage.currentProfile?.studentProfileId;
    const notes = {
      studentId: profileId,
      message: messages,
    };
    const notesFileIds = [];
    for (const i of props.notes.currentUploads) {
      notesFileIds.push(i.studentNotesFileId);
    }
    props.saveNotes({ notes, notesFileIds });
    setMessages("");
  };

  const onClose = (e) => {
    props.onClose(e);
    props.setEditForm("");
  };

  const loadMore = () => {
    const profileId = props.manage.currentProfile?.studentProfileId;
    const lastMsgId = props.notes.messages[0]?.studentNotesId;
    props.loadMoreNotes(profileId, lastMsgId ? lastMsgId : 0);
  };

  return (
    <Row>
      <Col xs={2} sm={2}></Col>
      <Col xs={20} sm={20}>
        <div id="interaction-container">
          <div id="messages-container" className="chat">
            {props.notes.hasMore && (
              <div style={{ textAlign: "center" }}>
                <Button onClick={(e) => loadMore()}>Load more</Button>
              </div>
            )}
            <List
              className="comment-list"
              itemLayout="horizontal"
              dataSource={props.notes.messages}
              renderItem={(msg) => (
                <List.Item>
                  <Comment
                    actions={false}
                    author={<b>{msg.createdByName}</b>}
                    avatar={false}
                    className=""
                    content={
                      <div>
                        <p>{msg.message}</p>
                        {msg.attachments && (
                          <div>
                            <ImageViewer
                              imageList={msg.attachments}
                              showActions={false}
                              loading={props.manage.loading}
                              year={props.manage.currentProfile?.batch}
                              hideMeta={true}
                              {...props}
                            />
                          </div>
                        )}
                      </div>
                    }
                    datetime={
                      <strong>
                        {moment(msg.createdDate).format("DD-MM-YYYY hh:mm A")}
                      </strong>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
          <div id="input-container">
            <Row className="input-cont-row">
              <Col xs={24} sm={24}>
                <Input.TextArea
                  placeholder="Please enter your notes here. Maximum 250 characters."
                  value={messages}
                  onChange={(e) => setMessages(e.target.value)}
                  defaultChecked
                  maxLength={250}
                />
              </Col>
              <Col xs={24} sm={24}>
                <Button
                  className="paper-clip-button"
                  icon={<PaperClipOutlined />}
                  onClick={(e) => handleUpload()}
                ></Button>
                <Button
                  className="send-message-button"
                  type="primary"
                  onClick={(e) => sendMessage()}
                >
                  Save
                </Button>
                {props.role !== MSSSTUD && (
                  <Button
                    onClick={(e) => onClose(e)}
                    className="chat-close-btn"
                  >
                    Close
                  </Button>
                )}
                {props.role === MSSSTUD && (
                  <Button
                    onClick={(e) => props.history.push("/")}
                    className="chat-close-btn"
                  >
                    Close
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <input
            type="file"
            id="myIntrFileB"
            style={{ display: "none" }}
            onChange={setFiles}
          ></input>
          <ImageViewer
            imageList={props.notes.currentUploads}
            showActions={true}
            loading={props.manage.loading}
            year={props.manage.currentProfile?.batch}
            {...props}
            addToDeleteFiles={props.addToDeleteFiles}
          />
        </div>
      </Col>
      <Col xs={2} sm={2}></Col>
    </Row>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveNotes(studentNotes) {
      dispatch(saveNotes(studentNotes)).then((res) => {
        this.getNotes(studentNotes.notes.studentId, 0);
      });
    },

    getNotes(profileId, studentNotesId) {
      dispatch(getNotes(profileId, studentNotesId));
    },

    loadMoreNotes(profileId, studentNotesId) {
      dispatch(loadMoreNotes(profileId, studentNotesId));
    },

    draftIntrUpload(studentProfileId, file, type, batch) {
      dispatch(draftImg(studentProfileId, file, type, batch));
    },

    addToDeleteFiles(filesList) {
      dispatch({
        type: NOTES_ADD_TO_DEL_FILES,
        payload: filesList,
      });
    },

    deleteIntrDraft(studentProfileId) {
      dispatch(deleteDrafts(studentProfileId));
    },

    approveIntrDrafts(studentProfileId, filesList) {
      dispatch(approveDrafts(studentProfileId, filesList));
    },

    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, StudentNotesReducer } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    notes: StudentNotesReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentNotes);

import { Button, Modal, Space, Spin, Table, Tooltip } from "antd";
import React from "react";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { HOST } from "../utils/const";
import AddHostel from "./AddHostel";
import { exportFile } from "../../common/utils/export";

class Hostels extends React.Component {
  state = {
    hostel: {},
  };

  componentDidMount() {
    this.props.fetchMasterTableData(HOST);
  }

  handleAdd = () => {
    this.setState({ hostel: {} });
    this.props.toogleAddModal(HOST);
  };

  edit = (record, e) => {
    e.preventDefault();
    this.setState({ hostel: record });
    this.props.toogleAddModal(HOST);
  };

  delete = (record, e) => {
    e.preventDefault();
    const that = this;
    Modal.confirm({
      title: (<div>Do you want to delete this Hostel?
        <p>Note: It can only be deleted if there are no students mapped to this Hostel.</p>
      </div>),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        that.props.deleteMasterTableData(HOST, record.hostelId);
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  exportData = (cols) => {
    exportFile(cols, this.props.masterTable.hostels, "Hostels");
  };

  render() {
    const HOST_LIST_COLS = [
      {
        title: "Hostel Name",
        dataIndex: "hostelName",
        key: "hostelName",
        render: (text, record) => record.hostelName,
        sorter: {
          compare: (a, b) => sortNames(a.hostelName, b.hostelName),
        },
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        render: (text, record) => record.location,
        sorter: {
          compare: (a, b) =>
            sortNames(
              a.location,
              b.location
            ),
        },
      },
      {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
        render: (text, record) => record.gender === "F" ? 'Female' : "Male" ,
        sorter: {
          compare: (a, b) => sortNames(a.gender, b.gender),
        },
      },
      {
        title: "Owner",
        dataIndex: "owner",
        key: "owner",
        render: (text, record) => record.owner,
        sorter: {
          compare: (a, b) => sortNames(a.owner, b.owner),
        },
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <Tooltip title="Edit">
              <a onClick={(e) => this.edit(record, e)} href="/">
                <EditOutlined />
              </a>
            </Tooltip>
            <Tooltip title="Delete">
              <a onClick={(e) => this.delete(record, e)} href="/">
                <DeleteOutlined />
              </a>
            </Tooltip>
          </Space>
        ),
      },
    ];
    return (
      <div id="hostel-master-container">
        <h2>
          Hostels
          <Button
            className="add-host-button"
            onClick={this.handleAdd}
            type="primary"
            style={{ float: "right" }}
            disabled={this.props.masterTable.loading}
          >
            Add Hostel
          </Button>
          <Button
            className="add-cat-button"
            onClick={(e) => this.exportData(HOST_LIST_COLS)}
            style={{ float: "right", marginRight: "10px" }}
            disabled={this.props.masterTable.loading}
          >
            Export Excel
          </Button>
        </h2>
        <Spin spinning={this.props.masterTable.loading} delay={500}>
          <div>
            <Table
              columns={HOST_LIST_COLS}
              dataSource={this.props.masterTable.hostels}
              pagination={{
                showSizeChanger: true,
                showTotal: () => {
                  const total = this.props.masterTable.hostels.length;
                  return `Total ${total}`;
                },
              }}
            />
          </div>
        </Spin>
        {this.props.masterTable.showModal === HOST && (
          <AddHostel record={this.state.hostel} {...this.props} />
        )}
      </div>
    );
  }
}

export default Hostels;

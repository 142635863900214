import React from 'react';
import { Card, Row, Col } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { VictoryPie, VictoryChart, VictoryLabel } from 'victory';



class MedhaNaipunyaNirmana extends React.Component {
    state = {
        data:  [
            { y: 0 },
            { y: 0 },
            {  y: 0 },
            {  y: 0 },
            {  y: 0 },
            {  y: 0 },
            {  y: 0 },
            {  y: 0 },
            {  y: 100 }
        ]
    };
    componentDidMount() {
        const grapthData = [
            
            { x: "Plastic Processing (97)", y: 97 },
            { x: "Offset Printing (5)", y: 5 },
            { x: "Diploma PMT (79)", y: 79 }, 
            { x: "Tailoring/Zardozi/Ornamental Work (53)", y: 53 },
            { x: "Fitter (9)", y: 9 },
            { x: "Electrician (30)", y: 30 },
            { x: "Welding (1)", y: 4 },
            { x: "CNC Operator (73)", y: 73 },
            { x: "Solar Electrician (35)", y: 35 },   
        ];
            this.setState({data: grapthData});
        
    }
    render() {
        return (

            <Card bordered={false} className="card-93 mg-l mnn-main-container">
                <title>Medha Naipunya Nirmana</title>
                <meta
                    name="description"
                    content="Various job oriented technical training programmes are sponsored to the youth in rural India and jobs are offered to pull them out of poverty."
                />
                <span className="mss-disc-heading"> Medha Naipunya Nirmana  </span>

                <div className="mnn-pie-chart">
                    <VictoryPie
                        colorScale={["tomato", "orange", "gold", "cyan", "navy", "green"]}
                        animate={{
                            easing: 'exp',
                            onLoad: { duration: 3000 }

                        }}
                        
                        data={this.state.data}
                        innerRadius={50}
                        style={{
                            labels: {width: 100, }
                        }}
                    />
                    <h3>Number of students sponsored</h3>
                </div>
                <div className="dotted-lines-mnn">
                    <div>
                    <h1 className="colored-heading colored-heading-mnn">Situation</h1>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">A</span>  well-known fact is that there is a huge skill gap in the Indian manufacturing industry. Young adults in rural areas are unable to find jobs and manufacturing industry is suffering from lack of quality workforce.
                        </p>
                        <h1 className="colored-heading colored-heading-mnn">Our Aim</h1>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">T</span>o reduce the skill gap by providing technical training to youth in rural areas while helping them in placement services to better utilise their talent for betterment of the country.
                        </p>
                        <h1 className="colored-heading colored-heading-mnn">Our Solution</h1>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">S</span>tudents from government schools were enrolled in job oriented diploma programmes. Since 2017 we sponsored Diploma in Plastics Mould Technology at CIPET for 80 students. Students have been encouraged to learn the requirements of the industry in addition to class room and lab training. With mentorship support and placement services to all students, graduating students have joined various manufacturing industries in and around Hyderabad while few have proceeded for higher education.
                        </p>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">A</span>fter discussing with various members of central government and state government technical training institutes to understand the industry requirements and growth prospects in various areas, we selected few best technical institutes and clubbed their short term programmes to redesign 6 month skill development programmes. These programmes were offered to candidates who are jobless and reside in rural areas. These certificate courses have helped the candidates in learning the subject and gaining practical exposure. Placement support is also provided to all candidates in addition to being groomed to be a part of qualified skilled workforce of Indian manufacturing industry. The candidates who completed the training have progressed well in the industries where they were placed.
                         </p>
                    </div>
                    <h1 className=" colored-heading colored-heading-mnn highlights-heading"> Highlights</h1>
                    <p className="mss-highlights">
                        <span className="highlight-start"> Years:</span> 2017 – present<br></br>
                        <span className="highlight-start">Students Benefitted:</span> Over 400 students<br></br>
                        <span className="highlight-start"> Courses:</span> Diploma Programmes and  Certificate Courses<br></br>
                        <span className="highlight-start"> Institutes Targeted:</span> Central Institute of Plastic Design<br></br>
                                                                                 Swamy Ramananda Tirtha Rural Institute<br></br>
                                                                                 Boys Town ITI  <br></br>
                                                                                 National Small Industries Corporation – Technical Services Centre<br></br>

                    </p>

                    <h1 className=" colored-heading colored-heading-mnn  highlights-heading"> Programmes Offered</h1>
                    <Row>
                        <Col xs={24} sm={12}>
                            <p>
                                <span className="mss-grad-head"> Diploma Programmes</span> <br></br>
                                <p className="prog-disc">
                                    <ArrowRightOutlined />  Diploma in Plastics Mould Technology<br></br>
                                    <ArrowRightOutlined />  Diploma in Tool, Die and Mould Making <br></br>
                                    <ArrowRightOutlined />  Diploma in Production Engineering <br></br>
                                    
                                </p>
                            </p>
                        </Col>
                        <Col xs={24} sm={12}>
                            <span className="mss-grad-head">  Certificate Courses</span> <br></br>
                            <p className="prog-disc">
                                <ArrowRightOutlined />  Machine Operator – Plastic Processing<br></br>
                                <ArrowRightOutlined />  Machine Operator – CNC Lathe and Milling<br></br>
                                <ArrowRightOutlined />  Electrician and Solar Electric System Installer and Service Provider<br></br>
                                <ArrowRightOutlined />  Self Employed Tailor with Zardozi and Ornamental Work<br></br>
                                <ArrowRightOutlined />  Electrician<br></br>
                                <ArrowRightOutlined />  Fitter<br></br>
                                <ArrowRightOutlined />  Welding<br></br>
                                <ArrowRightOutlined />  Offset Printing Operator<br></br>
                                <ArrowRightOutlined />  Master Certificate Course in Electronics<br></br>
                                <ArrowRightOutlined />  Master Certificate Course in CNC &amp; CAD/CAM<br></br>
                                <ArrowRightOutlined />  Field Engineer – Refrigeration, Air Conditioning and Washing Machine<br></br>
                            </p>
                        </Col>

                    </Row>
                </div>
            </Card>
        );
    }
}

export default MedhaNaipunyaNirmana;
import React, { useEffect } from "react";
import { getLable, sortNames } from "../../sampoorna-siksha/utils/util";
import { Tag, Space, Tooltip, Modal, Table, Button, Spin } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { REQ_CAT, REQ_CAT_TYP } from "../utils/const";
import AddRequestCategory from "./AddRequestCategory";
import { exportFile } from "../../common/utils/export";

const REQ_CAT_LIST_COLS = [
  {
    title: "Category",
    dataIndex: "name",
    key: "name",
    render: (text, record) => record.name,
    sorter: {
      compare: (a, b) => sortNames(a.name, b.name),
    },
  },
  {
    title: "Category Type",
    dataIndex: "type",
    key: "type",
    render: (text, record) => getLable(REQ_CAT_TYP, record.type),
    sorter: {
      compare: (a, b) =>
        sortNames(getLable(REQ_CAT_TYP, a.type), getLable(REQ_CAT_TYP, b.type)),
    },
  },
  {
    title: "Sub Categories",
    dataIndex: "requestSubCategories",
    key: "requestSubCategories",
    render: (text, record) => {
      const html = [];
      for (const sc of record.requestSubCategories) {
        html.push(<Tag> {sc.name} </Tag>);
      }
      return html;
    },
  },
];

const REQ_CAT_LIST_XL_COLS = [
  {
    title: "Category",
    dataIndex: "name",
    key: "name",
    render: (text, record) => record.name,
    sorter: {
      compare: (a, b) => sortNames(a.name, b.name),
    },
  },
  {
    title: "Category Type",
    dataIndex: "type",
    key: "type",
    render: (text, record) => getLable(REQ_CAT_TYP, record.type),
  },
  {
    title: "Sub Categories",
    dataIndex: "requestSubCategories",
    key: "requestSubCategories",
    render: (text, record) => {
      const str = [];
      for (const sc of record.requestSubCategories) {
        str.push(sc.name);
      }
      return str.join(', ');
    },
  },
];

const RequestCategories = (props) => {
  const [category, setCategory] = useState(null);

  useEffect(() => {
    props.fetchMasterTableData(REQ_CAT);
  }, []);

  const handleAdd = () => {
    props.setCurrentRecord({});
    props.toogleAddModal(REQ_CAT);
  };

  const editCat = (record, e) => {
    e.preventDefault();
    props.setCurrentRecord(record);
    props.toogleAddModal(REQ_CAT);
  };

  const deleteCat = (record, e) => {
    e.preventDefault();
    Modal.confirm({
      title: "Do you want to delete this Request Category?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        props.deleteMasterTableData(REQ_CAT, record.requestCategoryId);
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  if (REQ_CAT_LIST_COLS.length < 4) {
    REQ_CAT_LIST_COLS.push({
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <a href="/" onClick={(e) => editCat(record, e)}>
              <EditOutlined />
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <a href="/" onClick={(e) => deleteCat(record, e)}>
              <DeleteOutlined />
            </a>
          </Tooltip>
        </Space>
      ),
    });
  }

  const exportData = () => {
    exportFile(REQ_CAT_LIST_XL_COLS, props.masterTable.reqCat, "Request Categories");
  };

  return (
    <React.Fragment>
      <div id="education-category-container">
        <h2>
          Request Categories
          <Button
            className="add-cat-button"
            onClick={handleAdd}
            type="primary"
            style={{ float: "right" }}
            disabled={props.masterTable.loading}
          >
            Add Category
          </Button>
          <Button
            className="add-cat-button"
            onClick={(e) => exportData(e)}
            style={{ float: "right", marginRight: "10px" }}
            disabled={props.masterTable.loading}
          >
            Export Excel
          </Button>
        </h2>
        <Spin spinning={props.masterTable.loading} delay={500}>
          <div>
            <Table
              columns={REQ_CAT_LIST_COLS}
              dataSource={props.masterTable.reqCat}
              pagination={{
                showSizeChanger: true,
                showTotal: () => {
                  const total = props.masterTable.reqCat.length;
                  return `Total ${total}`;
                },
              }}
            />
          </div>
        </Spin>
        {props.masterTable.showModal === REQ_CAT && (
          <AddRequestCategory record={category} {...props} />
        )}
      </div>
    </React.Fragment>
  );
};

export default RequestCategories;

import { Col, Divider, Row, Spin, Steps } from "antd";
import React from "react";
import { CONT_INFO, PER_INFO } from "../utils/consts";
import AgricultureForm from "./reviewSteps/AgricultureForm";
import BusinessForm from "./reviewSteps/BusinessForm";
import CattleForm from "./reviewSteps/CattleForm";
import ConsumerItems from "./reviewSteps/ConsumerItems";
import ContactInfo from "./reviewSteps/ContactInfo";
import HouseForm from "./reviewSteps/HouseForm";
import NeighbourReference from "./reviewSteps/NeighbourReference";
import OccupationDetails from "./reviewSteps/OccupationDetails";
import PersonalInfo from "./reviewSteps/PersonalInfo";
import PlotForm from "./reviewSteps/PlotForm";
import PropertiesOwned from "./reviewSteps/PropertiesOwned";
import ResponseToMSS from "./reviewSteps/ResponseToMSS";
import StudentInfo from "./reviewSteps/StudentInfo";
import UploadPhotos from "./reviewSteps/UploadPhotos";
import VehiclesInfo from "./reviewSteps/VehiclesInfo";
import VolunteerComments from "./reviewSteps/VolunteerComments";
import PreviewSurvey from "./viewSurvey/PreviewSurvey";

const { Step } = Steps;

class ReviewerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      disableNext: true,
      reviewForm: {},
      surveyData: [],
    };

    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.saveDetails = this.saveDetails.bind(this);
    this.submitSurvey = this.submitSurvey.bind(this);
  }

  next() {
    const current = this.props.review.step + 1;
    this.setState({ current });
    this.props.setStep(current);
  }

  prev() {
    const current = this.props.review.step - 1;
    this.setState({ current });
    this.props.setStep(current);
  }

  onChange(current) {
    if (this.props.review.showPreviewBtn) {
      this.setState({ current });
      this.props.setStep(current);
    }
  }

  gotoStep = (step) => {
    if (step <= this.props.review.completedSteps) {
      this.setState({ step });
      this.props.setStep(step);
    }
  };

  saveDetails(key, data) {
    // console.log("----------", key, data);
    const { reviewForm } = this.state;
    const application = this.props.review.reviewDetails.application;
    let surveyData = this.props.review.reviewDetails.surveyList;
    if (!surveyData) {
      surveyData = [];
    }
    let obj = null;
    for (const i of surveyData) {
      if (!!i.application) {
        i.application = null;
      }
      if (i.surveyKey === key) {
        obj = i;
      }
    }

    if (!obj) {
      obj = {};
      surveyData.push(obj);
    }

    if (!obj.surveyKey) {
      obj.surveyKey = key;
      obj.applicationId = application.applicationId;
    }

    if (key === PER_INFO) {
      obj.fatherName = data.fatherName;
      obj.motherName = data.motherName;
    } else if (key === CONT_INFO) {
      obj.address = data.address;
      obj.alternatePhone = data.alternatePhone;
    }
    obj.surveyDetails = JSON.stringify(data);

    reviewForm[key] = data;
    this.setState({ reviewForm, surveyData });
    this.props.saveApplication(surveyData, this.props.review.step + 1);
  }

  submitSurvey() {
    const application = this.props.review.reviewDetails.application;
    this.props.submitSurveyForm(application.applicationId);
  }

  render() {
    const current = this.props.review.step;
    return (
      <div id="reviewer-form">
        <Spin spinning={this.props.review.loading}>
          <Row>
            <Col sm={4}>
              <Steps
                current={current}
                progressDot
                direction="vertical"
                size="small"
                onChange={(e) => this.gotoStep(e)}
              >
                <Step
                  key="personalInfo"
                  title="Personal Information"
                  onChange={this.onChange}
                />
                <Step
                  key="contactInfo"
                  title="Contact Information"
                  onChange={this.onChange}
                />
                <Step
                  key="consumerItems"
                  title="Consumer Items"
                  onChange={this.onChange}
                />
                <Step
                  key="vehiclesInfo"
                  title="Vehicles Information"
                  onChange={this.onChange}
                />
                <Step
                  key="studentInfo"
                  title="Student Information"
                  onChange={this.onChange}
                />
                <Step
                  key="responseToMSS"
                  title="Response To MSS"
                  onChange={this.onChange}
                />
                <Step
                  key="volunteersComments"
                  title="Volunteer's Comments"
                  onChange={this.onChange}
                />
                <Step
                  key="occupationDetails"
                  title="Occupation Details"
                  onChange={this.onChange}
                />
                <Step
                  key="agriculture"
                  title="Agriculture Lands"
                  onChange={this.onChange}
                />
                <Step
                  key="cattle"
                  title="Animals Owned"
                  onChange={this.onChange}
                />
                <Step
                  key="plots"
                  title="Plots Owned"
                  onChange={this.onChange}
                />
                <Step
                  key="houses"
                  title="House Owned"
                  onChange={this.onChange}
                />
                <Step
                  key="businesses"
                  title="Business Owned"
                  onChange={this.onChange}
                />
                <Step
                  key="neighboursReference"
                  title="Neighbour's Reference"
                  onChange={this.onChange}
                />
                <Step
                  key="otherDetails"
                  title="Income Summary"
                  onChange={this.onChange}
                />
                <Step
                  key="uploadPhotos"
                  title="Upload Photos"
                  onChange={this.onChange}
                />
                <Step key="prevew" title="Preview" />
              </Steps>
            </Col>
            <Col sm={1}>
              <Divider type="vertical" style={{ height: "100%" }}></Divider>
            </Col>
            <Col sm={18}>
              {current === 0 && (
                <div className="steps-content">
                  <PersonalInfo
                    showNext={true}
                    showPrev={false}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></PersonalInfo>
                </div>
              )}
              {current === 1 && (
                <div className="steps-content">
                  <ContactInfo
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></ContactInfo>
                </div>
              )}
              {current === 2 && (
                <div className="steps-content">
                  <ConsumerItems
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></ConsumerItems>
                </div>
              )}
              {current === 3 && (
                <div className="steps-content">
                  <VehiclesInfo
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></VehiclesInfo>
                </div>
              )}
              {current === 4 && (
                <div className="steps-content">
                  <StudentInfo
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></StudentInfo>
                </div>
              )}
              {current === 5 && (
                <div className="steps-content">
                  <ResponseToMSS
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></ResponseToMSS>
                </div>
              )}
              {current === 6 && (
                <div className="steps-content">
                  <VolunteerComments
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></VolunteerComments>
                </div>
              )}

              {current === 7 && (
                <div className="steps-content">
                  <OccupationDetails
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></OccupationDetails>
                </div>
              )}

              {current === 8 && (
                <div className="steps-content">
                  <AgricultureForm
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></AgricultureForm>
                </div>
              )}

              {current === 9 && (
                <div className="steps-content">
                  <CattleForm
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></CattleForm>
                </div>
              )}

              {current === 10 && (
                <div className="steps-content">
                  <PlotForm
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></PlotForm>
                </div>
              )}

              {current === 11 && (
                <div className="steps-content">
                  <HouseForm
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></HouseForm>
                </div>
              )}

              {current === 12 && (
                <div className="steps-content">
                  <BusinessForm
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></BusinessForm>
                </div>
              )}

              {current === 13 && (
                <div className="steps-content">
                  <NeighbourReference
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></NeighbourReference>
                </div>
              )}

              {current === 14 && (
                <div className="steps-content">
                  <PropertiesOwned
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></PropertiesOwned>
                </div>
              )}

              {current === 15 && (
                <div className="steps-content">
                  <UploadPhotos
                    showNext={true}
                    showPrev={true}
                    next={this.next}
                    prev={this.prev}
                    saveDetails={this.saveDetails}
                    {...this.props}
                  ></UploadPhotos>
                </div>
              )}
              {current === 16 && (
                <div className="steps-content">
                  <PreviewSurvey
                    next={this.next}
                    prev={this.prev}
                    submitSurvey={this.submitSurvey}
                    disableEdit={true}
                    edit={(e) => this.props.setStepByKey(e)}
                    {...this.props}
                  ></PreviewSurvey>
                </div>
              )}
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

export default ReviewerForm;

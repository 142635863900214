import React from 'react';
import { Slide } from 'react-slideshow-image';
import { Row } from 'antd';
import { getBoytBlack } from '../utils/images';

class Carousel extends React.Component {
    constructor(props) {
        super(props);

        const slideImages = [
            {
                img: getBoytBlack(),
                message: ''
            },
            {
                img: getBoytBlack(),
                message: ''
            },
            {
                img: getBoytBlack(),
                message: ''
            }
        ];

        this.state = {
            slideImages: slideImages
        }

    }

    render() {
        const properties = {
            duration: 5000,
            transitionDuration: 500,
            infinite: true,
            indicators: true,
            arrows: true,
            pauseOnHover: true,
            onChange: (oldIndex, newIndex) => {
                // console.log(`slide transition from ${oldIndex} to ${newIndex}`);
            }
        }

        const imageSlides = [];

        for (const slide of this.state.slideImages) {
            imageSlides.push(
                <div className="each-slide">
                    <div style={{ 'backgroundImage': `url(${slide.img})` }}>
                        <span>{slide.message}</span>
                    </div>
                </div>
            );
        }
        return(
            <div>
                <Row>
                    <div className="slide-container">
                        <Slide {...properties}>
                            {imageSlides}
                        </Slide>
                    </div>
                </Row>
            </div>
        );
    }
}

export default Carousel;
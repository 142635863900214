import { Col, Empty } from "antd";
import React from "react";
import { translate } from "../../../base/actions/translate";
import ViewSurveyCard from "./ViewSurveyCard";

class ResponseToMSSView extends React.Component {
  
  render() {
    let info = this.props.data;
    if(!info) {
      return (
        <ViewSurveyCard title="Response To MSS" {...this.props}>
          <Empty />
        </ViewSurveyCard>
      );
    }
    return (
      <div>
        <ViewSurveyCard title="Response To MSS" {...this.props}>
          <Col sm={20}>
              <label>Are parents interested in sending student to us?</label>:
            </Col>
            <Col sm={4}>
              <span>{translate(info.parentsIntrest)}</span>
            </Col>
            <Col sm={20}>
              <label>Did student receive any private college offers?</label>:
            </Col>
            <Col sm={4}>
              <span>{translate(info.collegeOffer)}</span>
            </Col>
            <Col sm={20}>
              <label>Did student apply for IIIT?</label>:
            </Col>
            <Col sm={4}>
              <span>{translate(info.applyForIIIT)}</span>
            </Col>
            <Col sm={20}>
              <label>If yes to IIIT, does student prefer IIIT over MCT scholarship?</label>:
            </Col>
            <Col sm={4}>
              <span>{translate(info.preferMCT)}</span>
            </Col>
            <Col sm={20}>
              <label>If student doesn't get MCT admission, can parents support financially?</label>:
            </Col>
            <Col sm={4}>
              <span>{translate(info.canSupportFin)}</span>
            </Col>
        </ViewSurveyCard>
      </div>
    );
  }
}

export default ResponseToMSSView;

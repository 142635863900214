import { connect } from "react-redux";
import { getSystemConfigs, saveSystemConfigs } from "../../base/actions/AppConfigActions";
import { RESET_MESSAGES } from "../../admin/utils/consts";
import DocumentsUpload from "../components/DocumnetsUpload";
import { uploadMCTMarksSheet, uploadSSCMarksSheet, getMarksConfig } from "../actions/SSActions";
import { uploadAdminDocs } from "../../admin/actions/AdminActions";

const mapDispatchToProps = (dispatch, props) => {
  return {
    getSystemConfig() {
        dispatch(getSystemConfigs());
    },
    saveSystemConfig(form) {
      dispatch(saveSystemConfigs(form));
    },
    getMarksConfig() {
      dispatch(getMarksConfig());
    },
    uploadSSCMarksSheet(formData) {
      dispatch(uploadSSCMarksSheet(formData)).then(res => {
        this.getMarksConfig();
      });
    },
    uploadMCTMarksSheet(formData) {
      dispatch(uploadMCTMarksSheet(formData)).then(res => {
        this.getMarksConfig();
      });
    },
    uploadAdminDocs(formData) {
      dispatch(uploadAdminDocs(formData));
    },
    resetMessage() {
      dispatch({
          type: RESET_MESSAGES
      });
  },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, AdminReducer, SSReducer, AppConfigReducer } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    sampurnaSiksha: SSReducer,
    appConfig: AppConfigReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsUpload);

import { generateAppNum } from "../../student/util/util";
import { translate } from "../../base/actions/translate";
import {
  ALL_STATUS_LIST,
  ALL_SURVEY_UPLOAD_CAT,
  DYN_FIELDS,
  INC_SUM,
  NON_REQ_IMAGES,
  SURVEY_FILE_CAT,
  SURVEY_PHOTO_CAT,
  TASK_STATUS,
} from "./consts";
import {
  MED_TYP,
  MON_TYP,
  WEL_TYP,
} from "../../management/utils/const";

export const isApplicationContainsText = (record, searchText) => {
  searchText = searchText.toUpperCase();
  let hallTcktNo = '';
  if(record.hallTicket && record.hallTicket.hallTicketNo) {
    hallTcktNo = record.hallTicket.hallTicketNo;
  }
  let medium = '';
  if(record.medium) {
    medium = record.medium;
  }
  return (
    generateAppNum(record).indexOf(searchText) > -1 ||
    record.studentName.toUpperCase().indexOf(searchText) > -1 ||
    record.studentSurName.toUpperCase().indexOf(searchText) > -1 ||
    record.school?.parent?.parent?.configVal.toUpperCase().indexOf(searchText) >
      -1 ||
    record.school?.parent?.configVal.toUpperCase().indexOf(searchText) > -1 ||
    record.school.configVal.toUpperCase().indexOf(searchText) > -1 ||
    record.ninthGrade.toUpperCase().indexOf(searchText) > -1 ||
    record.caste.configVal.toUpperCase().indexOf(searchText) > -1 ||
    translate(getStatusCode(record.status).key)
      .toUpperCase()
      .indexOf(searchText) > -1 ||
      hallTcktNo.toString().toUpperCase().indexOf(searchText) > -1 ||
      translate(medium).toUpperCase().indexOf(searchText) > -1
  );
};

export const isSurveyListContains = (record, searchText) => {
  searchText = searchText.toUpperCase();
  return (
    generateAppNum(record).indexOf(searchText) > -1 ||
    record.studentName.toUpperCase().indexOf(searchText) > -1 ||
    record.studentSurName.toUpperCase().indexOf(searchText) > -1
  );
};

export const sortNames = (a, b) => {
  const alphabet = "*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyz";
  if (!a) {
    a = [];
  }
  if (!b) {
    b = [];
  }
  var index_a = alphabet.indexOf(a[0]),
    index_b = alphabet.indexOf(b[0]);

  if (index_a === index_b) {
    // same first character, sort regular
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }
    return 0;
  } else {
    return index_a - index_b;
  }
};

export const toCapital = (e) => {
  if (e.target.value) {
    return e.target.value.toUpperCase();
  }
  return e.target.value;
};

export const taskListPredicate = (task, searchText) => {
  searchText = searchText.toUpperCase();
  let config = task.entity;
  let text = "";
  while (config.parentId && config.configCd !== "ODIST") {
    text += config.configVal + " ";
    config = config.parent;
  }
  const info = JSON.parse(task.info);
  for (const i in info) {
    text += info[i] + " ";
  }

  text += task.requester.username;
  return text.toUpperCase().indexOf(searchText) > -1;
};

export const examCenterPredicate = (center, searchText) => {
  searchText = searchText.toUpperCase();
  let text = "";
  text += center.configVal + " ";
  text += center.parent.configVal + " ";
  text += center.info + " ";
  text += center.studentCount + " ";

  return text.toUpperCase().indexOf(searchText) > -1;
};

export const getConfigVal = (config, code) => {
  let c = config;
  if (c) {
    while (c.configCd !== code) {
      c = c.parent;
    }
  }

  return c.configVal;
};

export const createSiblingData = (siblings, name) => {
  const sib = JSON.parse(siblings);

  const sibList = [];

  for (const i of sib) {
    sibList.push({
      siblingName: i.siblingName,
      siblingEducation: i.siblingEducation,
    });
  }

  return {
    name: [name + "List"],
    value: sibList,
  };
};

export const createConsumerItemsData = (consumerItems) => {
  const items = JSON.parse(consumerItems);
  return {
    name: ["consumerItemsList"],
    value: items,
  };
};

export const prepareFieldData = (reviewDetails) => {
  const fieldData = [];
  // const reviewDetails = this.props.review.reviewDetails;
  const application = reviewDetails.application;
  const basicInformation = reviewDetails.basicInformation;

  fieldData.push(
    {
      name: ["fatherName"],
      value: application.fatherName,
    },
    {
      name: ["motherName"],
      value: application.motherName,
    },
    {
      name: ["address"],
      value: application.address,
    },
    {
      name: ["alternatePhone"],
      value: application.alternatePhone,
    }
  );

  if (basicInformation) {
    for (let i in basicInformation) {
      if (i === "siblings") {
        fieldData.push(createSiblingData(basicInformation[i], i));
      } else if (i === "consumerItems") {
        fieldData.push(createConsumerItemsData(basicInformation[i]));
      } else {
        fieldData.push({
          name: [i],
          value: basicInformation[i],
        });
      }
    }
  }

  return fieldData;
};

export const createDynamicData = (data, name) => {
  const sibList = [];

  for (const i of data) {
    sibList.push({
      siblingName: i.siblingName,
      siblingEducation: i.siblingEducation,
    });
  }
  return {
    name: [name + "List"],
    value: sibList,
  };
};

export const prepareReviewersFieldData = (jsonObject) => {
  const fieldData = [];
  if (jsonObject) {
    for (let i in jsonObject) {
      if (DYN_FIELDS.indexOf(i) > -1) {
        fieldData.push(createDynamicData(jsonObject[i]));
      } else if (i === "consumerItems") {
        fieldData.push(createConsumerItemsData(jsonObject[i]));
      } else {
        fieldData.push({
          name: [i],
          value: jsonObject[i],
        });
      }
    }
  }
  return fieldData;
};

export const getNumberValue = (object, key) => {
  return object[key] && !isNaN(object[key]) ? Number(object[key]) : 0;
};

export const getNumberValueWithAlt = (object, key, altKey) => {
  // let val = object[key] && Number(object[key]) != NaN ? Number(object[key]) : 0;
  return object[key] && !isNaN(object[key]) 
    ? Number(object[key])
    : getNumberValue(object, altKey);
};

export const calculateTotalIncome = (survey) => {
  let totalIncome = 0;

  if (survey.OCC_DTLS) {
    totalIncome += getNumberValue(survey.OCC_DTLS, "fAnnualIncome");
    totalIncome += getNumberValue(survey.OCC_DTLS, "mAnnualIncome");
    if (survey.OCC_DTLS.othersList) {
      for (let i = 0; i < survey.OCC_DTLS.othersList.length; i++) {
        totalIncome += getNumberValue(
          survey.OCC_DTLS.othersList[i],
          "oAnnualIncome"
        );
      }
    }
  }

  if (survey.AGR_OWN && survey.AGR_OWN.agricultureList) {
    for (let i = 0; i < survey.AGR_OWN.agricultureList.length; i++) {
      totalIncome += getNumberValue(
        survey.AGR_OWN.agricultureList[i],
        "totalYield"
      );
    }
  }

  if (survey.HOUSE_OWN && survey.HOUSE_OWN.housesList) {
    for (let i = 0; i < survey.HOUSE_OWN.housesList.length; i++) {
      if (survey.HOUSE_OWN.housesList[i]?.ownership === "Let Out") {
        const rent = getNumberValue(
          survey.HOUSE_OWN.housesList[i],
          "monthlyRent"
        );
        totalIncome += rent * 12;
      }
    }
  }

  if (survey.CAT_OWN && survey.CAT_OWN.animalList) {
    for (let i = 0; i < survey.CAT_OWN.animalList.length; i++) {
      totalIncome += getNumberValue(
        survey.CAT_OWN.animalList[i],
        "annualIncome"
      );
    }
  }

  if (survey.BUS_OWN && survey.BUS_OWN.businessesList) {
    for (let i = 0; i < survey.BUS_OWN.businessesList.length; i++) {
      totalIncome += getNumberValue(
        survey.BUS_OWN.businessesList[i],
        "annualIncome"
      );
    }
  }

  return totalIncome;
};

export const calculateNewTotalIncome = (survey) => {
  let newTotalIncome = 0;
  if (survey.OCC_DTLS) {
    newTotalIncome += getNumberValueWithAlt(
      survey.OCC_DTLS,
      "fNewAnnualIncome",
      "fAnnualIncome"
    );
    newTotalIncome += getNumberValueWithAlt(
      survey.OCC_DTLS,
      "mNewAnnualIncome",
      "mAnnualIncome"
    );
    if (survey.OCC_DTLS.othersList) {
      for (let i = 0; i < survey.OCC_DTLS.othersList.length; i++) {
        newTotalIncome += getNumberValueWithAlt(
          survey.OCC_DTLS.othersList[i],
          "oNewAnnualIncome",
          "oAnnualIncome"
        );
      }
    }
  }

  if (survey.AGR_OWN && survey.AGR_OWN.agricultureList) {
    for (let i = 0; i < survey.AGR_OWN.agricultureList.length; i++) {
      newTotalIncome += getNumberValueWithAlt(
        survey.AGR_OWN.agricultureList[i],
        "newTotalYield",
        "totalYield"
      );
    }
  }

  if (survey.HOUSE_OWN && survey.HOUSE_OWN.housesList) {
    for (let i = 0; i < survey.HOUSE_OWN.housesList.length; i++) {
      if (survey.HOUSE_OWN.housesList[i]?.ownership === "Let Out") {
        const newRent = getNumberValueWithAlt(
          survey.HOUSE_OWN.housesList[i],
          "newMonthlyRent",
          "monthlyRent"
        );
        newTotalIncome += newRent * 12;
      }
    }
  }

  if (survey.CAT_OWN && survey.CAT_OWN.animalList) {
    for (let i = 0; i < survey.CAT_OWN.animalList.length; i++) {
      newTotalIncome += getNumberValueWithAlt(
        survey.CAT_OWN.animalList[i],
        "newAnnualIncome",
        "annualIncome"
      );
    }
  }

  if (survey.BUS_OWN && survey.BUS_OWN.businessesList) {
    for (let i = 0; i < survey.BUS_OWN.businessesList.length; i++) {
      newTotalIncome += getNumberValueWithAlt(
        survey.BUS_OWN.businessesList[i],
        "newAnnualIncome",
        "annualIncome"
      );
    }
  }

  return newTotalIncome;
};

export const calculateTotalPropertyValue = (survey) => {
  let totalPropertyValue = 0;

  if (survey.AGR_OWN && survey.AGR_OWN.agricultureList) {
    for (let i = 0; i < survey.AGR_OWN.agricultureList.length; i++) {
      totalPropertyValue += getNumberValue(
        survey.AGR_OWN.agricultureList[i],
        "propertyValue"
      );
    }
  }

  if (survey.PLOT_OWN && survey.PLOT_OWN.plotsList) {
    for (let i = 0; i < survey.PLOT_OWN.plotsList.length; i++) {
      totalPropertyValue += getNumberValue(
        survey.PLOT_OWN.plotsList[i],
        "propertyValue"
      );
    }
  }

  if (survey.HOUSE_OWN && survey.HOUSE_OWN.housesList) {
    for (let i = 0; i < survey.HOUSE_OWN.housesList.length; i++) {
      const ownership = survey.HOUSE_OWN.housesList[i]?.ownership;
      if (ownership !== "Rented") {
        totalPropertyValue += getNumberValue(
          survey.HOUSE_OWN.housesList[i],
          "propertyValue"
        );
      }
    }
  }

  if (survey.CAT_OWN && survey.CAT_OWN.animalList) {
    for (let i = 0; i < survey.CAT_OWN.animalList.length; i++) {
      totalPropertyValue += getNumberValue(
        survey.CAT_OWN.animalList[i],
        "propertyValue"
      );
    }
  }

  if (survey.BUS_OWN && survey.BUS_OWN.businessesList) {
    for (let i = 0; i < survey.BUS_OWN.businessesList.length; i++) {
      totalPropertyValue += getNumberValue(
        survey.BUS_OWN.businessesList[i],
        "propertyValue"
      );
    }
  }

  return totalPropertyValue;
};

export const calculateNewTotalPropertyValue = (survey) => {
  let totalPropertyValue = 0;

  if (survey.AGR_OWN && survey.AGR_OWN.agricultureList) {
    for (let i = 0; i < survey.AGR_OWN.agricultureList.length; i++) {
      totalPropertyValue += getNumberValueWithAlt(
        survey.AGR_OWN.agricultureList[i],
        "newPropertyValue",
        "propertyValue"
      );
    }
  }

  if (survey.PLOT_OWN && survey.PLOT_OWN.plotsList) {
    for (let i = 0; i < survey.PLOT_OWN.plotsList.length; i++) {
      totalPropertyValue += getNumberValueWithAlt(
        survey.PLOT_OWN.plotsList[i],
        "newPropertyValue",
        "propertyValue"
      );
    }
  }

  if (survey.HOUSE_OWN && survey.HOUSE_OWN.housesList) {
    for (let i = 0; i < survey.HOUSE_OWN.housesList.length; i++) {
      const ownership = survey.HOUSE_OWN.housesList[i]?.ownership;
      if (ownership !== "Rented") {
        totalPropertyValue += getNumberValueWithAlt(
          survey.HOUSE_OWN.housesList[i],
          "newPropertyValue",
          "propertyValue"
        );
      }
    }
  }

  if (survey.CAT_OWN && survey.CAT_OWN.animalList) {
    for (let i = 0; i < survey.CAT_OWN.animalList.length; i++) {
      totalPropertyValue += getNumberValueWithAlt(
        survey.CAT_OWN.animalList[i],
        "newPropertyValue",
        "propertyValue"
      );
    }
  }

  if (survey.BUS_OWN && survey.BUS_OWN.businessesList) {
    for (let i = 0; i < survey.BUS_OWN.businessesList.length; i++) {
      totalPropertyValue += getNumberValueWithAlt(
        survey.BUS_OWN.businessesList[i],
        "newPropertyValue",
        "propertyValue"
      );
    }
  }

  return totalPropertyValue;
};

export const createSurveyFormData = (surveyData) => {
  const surveyForm = {};
  for (const i of surveyData) {
    surveyForm[i.surveyKey] = JSON.parse(i.surveyDetails);
  }

  if (!surveyForm[INC_SUM]) {
    surveyForm[INC_SUM] = {};
  }
  surveyForm[INC_SUM].totalAnnualIncome = calculateTotalIncome(surveyForm);
  surveyForm[INC_SUM].totalPropertyValue =
    calculateTotalPropertyValue(surveyForm);

  surveyForm[INC_SUM].newTotalAnnualIncome =
    calculateNewTotalIncome(surveyForm);
  surveyForm[INC_SUM].newTotalPropertyValue =
    calculateNewTotalPropertyValue(surveyForm);

  return surveyForm;
};

export const getLandingStep = (reviewFormData, surveyCatImages) => {
  let isAllFilesUploaded = true;
  for (const i in surveyCatImages) {
    if (surveyCatImages[i].length === 0 && !NON_REQ_IMAGES.includes(i)) {
      isAllFilesUploaded = false;
    }
  }
  const keys = Object.keys(reviewFormData);
  if (keys.length > 0) {
    if (keys.length === 1 && keys.indexOf(INC_SUM) > -1) {
      return 0;
    } else if (keys.length < 15 && keys.indexOf(INC_SUM) > -1) {
      return keys.length - 1;
    } else if (keys.length === 15 && isAllFilesUploaded) {
      return keys.length + 1;
    }
    return keys.length;
  }
  return 0;
};

export const createEmptyReviewFilesList = () => {
  const fileList = {};
  for (const i of ALL_SURVEY_UPLOAD_CAT) {
    fileList[i] = [];
  }
  return fileList;
};

export const updateIncomeDetails = (surveyData) => {
  if (!surveyData.INC_SUM) {
    surveyData.INC_SUM = {};
  }
  surveyData.INC_SUM.totalPropertyValue =
    calculateTotalPropertyValue(surveyData);
  surveyData.INC_SUM.newTotalPropertyValue =
    calculateNewTotalPropertyValue(surveyData);
  surveyData.INC_SUM.totalAnnualIncome = calculateTotalIncome(surveyData);
  surveyData.INC_SUM.newTotalAnnualIncome = calculateNewTotalIncome(surveyData);
  return surveyData;
};

export const generateSurveyData = (surveyList) => {
  const surveyData = {};
  for (const s of surveyList) {
    surveyData[s.surveyKey] = JSON.parse(s.surveyDetails);
  }
  return updateIncomeDetails(surveyData);
};

export const getSurveyByKey = (sureylist, key) => {
  if (!sureylist) {
    return {};
  }

  for (const i of sureylist) {
    if (i.surveyKey === key) {
      return JSON.parse(i.surveyDetails);
    }
  }
  return {};
};

export const getStatusCode = (statusId) => {
  return ALL_STATUS_LIST.find((st) => st.id === statusId);
};

export const getTaskStatusCode = (statusId) => {
  return TASK_STATUS.find((st) => st.id === statusId);
};

export const getMedType = (typeId) => {
  return MED_TYP.find((st) => st.key === typeId);
};

export const getWelType = (typeId) => {
  return WEL_TYP.find((st) => st.key === typeId);
};

export const getWelSubType = (typeId, subTypeId) => {
  return WEL_TYP.find((st) => st.key === typeId)?.subTypes?.find(
    (st) => st.key === subTypeId
  );
};

export const getMonType = (typeId) => {
  return MON_TYP.find((st) => st.key === typeId);
};

export const getMonSubType = (typeId, subTypeId) => {
  return MON_TYP.find((st) => st.key === typeId)?.subTypes?.find(
    (st) => st.key === subTypeId
  );
};

export const getLable = (list, key) => {
  if (!list || !key) {
    return "";
  }
  return list.find((l) => l.key === key)?.label;
};

export const reOrderSurveyImages = (imageList) => {
  const orderedImgs = [];
  for (const cat of ALL_SURVEY_UPLOAD_CAT) {
    console.log("order surevey img -------------", cat);
    for (const img of imageList) {
      if (cat === img.type) {
        orderedImgs.push(img);
      }
    }
  }
  return orderedImgs;
};

export const getMaxUploadCount = (cat) => {
  return SURVEY_PHOTO_CAT.indexOf(cat) > -1
    ? 10
    : SURVEY_FILE_CAT.indexOf(cat) > -1
    ? 2
    : 100;
};

export const checkDataAvailable = (data) => {
  return (
    data &&
    ((Array.isArray(data) && data.length > 0) || Object.keys(data).length > 0)
  );
};

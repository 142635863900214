import { connect } from "react-redux";
import FaqsComponent from "../components/FaqsComponent";

const mapDispatchToProps = dispatch => {
    return {
     
    };
  };

  const mapStateToProps = state => {
    const { AuthReducer } = state;
    return {
      isLoading: AuthReducer.isLoading,
      profile: AuthReducer.profile,
      lang: AuthReducer.lang
    }
  };

  export default connect(mapStateToProps,mapDispatchToProps)(FaqsComponent);
import { connect } from "react-redux";
import AddSchoolSuccess from "../components/AddSchoolSuccess";

const mapDispatchToProps = dispatch => {
    return {
     
    };
  };

  const mapStateToProps = state => {
    const { AuthReducer, StudentReducer, AppConfigReducer } = state;
    return {
      isLoading: AuthReducer.isLoading,
      profile: AuthReducer.profile,
      lang: AuthReducer.lang,
      districtsList: AppConfigReducer.districtsList,
      mandalsList: AppConfigReducer.mandalsList,
      studentData: StudentReducer
    }
  }

  export default connect(mapStateToProps,mapDispatchToProps)(AddSchoolSuccess);
import { connect } from "react-redux";
import { fetchOldDistricts } from "../../base/actions/AppConfigActions";
import { deleteDrafts, downloadExamDocs } from "../actions/SSActions";
import { RESET_MESSAGES } from "../../base/utils/const";
import ExamDocument from "../components/ExamDocuments";

const mapDispatchToProps = (dispatch, props) => {
    return {
        getOldDistricts() {
            dispatch(fetchOldDistricts());
        },
        downloadExamDocs(oldDistId, distName) {
            dispatch(downloadExamDocs(oldDistId, distName));
        },
        resetMessage() {
            dispatch({
                type: RESET_MESSAGES
            });
        },
        deleteDraft(applicationId) {
            dispatch(deleteDrafts(applicationId));
        }
    };
};

const mapStateToProps = state => {
    const { AuthReducer, AdminReducer, SSReducer, AppConfigReducer, StudentReducer } = state;
    return {
        isLoading: AuthReducer.isLoading,
        profile: AuthReducer.profile,
        lang: AuthReducer.lang,
        role: AuthReducer.role,
        admin: AdminReducer,
        sampurnaSiksha: SSReducer,
        appConfig: AppConfigReducer,
        oldDistrictsList: AppConfigReducer.oldDistrictsList,
        districtsList: AppConfigReducer.districtsList,
        mandalsList: AppConfigReducer.mandalsList,
        schoolsList: AppConfigReducer.schoolsList,
        castesList: AppConfigReducer.castesList,
        examCenters: AppConfigReducer.examCenters,
        student: StudentReducer
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamDocument);
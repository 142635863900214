import React from "react";
import { BUS_OWN, formItemLayout } from "../../utils/consts";
import ReviewStep from "./ReviewStep";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import { prepareReviewersFieldData } from "../../utils/util";

class BusinessForm extends React.Component {
  onFinish = (form) => {
    // console.log("Received values of form:", form);
    this.props.saveDetails(BUS_OWN, form);
  };
  render() {
    let reviewData = this.props.review.reviewFormData[BUS_OWN];
    if (!reviewData) {
      reviewData = {};
    }
    const fieldData = prepareReviewersFieldData(reviewData);
    const isSelComt = this.props.isSelCommit;
    return (
      <div className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Business Owned"
          onFinish={(e) => this.onFinish(e)}
          {...this.props}
        >
          <Form.List name="businessesList">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, idx) => (
                    <Row key={idx} gutter={[8, 16]}>
                      <Col sm={1}>
                        <span>{idx + 1}</span>
                      </Col>
                      <Col sm={22}>
                        <Row key={idx} gutter={[8, 8]}>
                          <Col sm={8}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "businessName"]}
                              fieldKey={[field.fieldKey, "businessName"]}
                              label="Name of Business"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^\S[A-Za-z0-9\s]+$/,
                                  message: "Please enter name of Business",
                                },
                              ]}
                            >
                              <Input maxLength="50" disabled={isSelComt} />
                            </Form.Item>
                          </Col>

                          <Col sm={8}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "propertyValue"]}
                              fieldKey={[field.fieldKey, "propertyValue"]}
                              label="Asset Value"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^[0-9][0-9]*$/,//#627
                                  message: "Please enter asset value",
                                },
                              ]}
                            >
                              <Input maxLength="10" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          {isSelComt && (
                            <Col sm={6}>
                              <Form.Item
                                {...formItemLayout}
                                name={[field.name, "newPropertyValue"]}
                                fieldKey={[field.fieldKey, "newPropertyValue"]}
                                label="New Asset Value"
                              >
                                <Input maxLength="8" />
                              </Form.Item>
                            </Col>
                          )}
                          <Col sm={8}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "annualIncome"]}
                              fieldKey={[field.fieldKey, "annualIncome"]}
                              label="Annual Income"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^[0-9][0-9]*$/,//#627
                                  message:
                                    "Please enter annual income",
                                },
                              ]}
                            >
                              <Input maxLength="8" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          {isSelComt && (
                            <Col sm={6}>
                              <Form.Item
                                {...formItemLayout}
                                name={[field.name, "newAnnualIncome"]}
                                fieldKey={[field.fieldKey, "newAnnualIncome"]}
                                label="New Annual Income"
                              >
                                <Input maxLength="8" />
                              </Form.Item>
                            </Col>
                          )}
                          <Col sm={5}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "remarks"]}
                              fieldKey={[field.fieldKey, "remarks"]}
                              label="Remarks"
                            >
                              <Input maxLength="100" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={1} style={{ textAlign: "center" }}>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add Businesses
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </ReviewStep>
      </div>
    );
  }
}

export default BusinessForm;

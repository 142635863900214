import React from 'react';
import Carousel from '../../base/components/Carousel ';
import { Card } from 'antd';
import { translate } from '../../base/actions/translate';
import '../styles/student.css';
import { Redirect } from 'react-router';

class AddSchoolSuccess extends React.Component {

    render() {
        if (!this.props.studentData.workflow) {
            return (<Redirect to="/home" />);
        }
        return (
            <div>
                <Carousel />
                <Card bordered={false} className="card-95 mg-l">
                    <h3>{translate('addSchoolSuccess')}</h3>
                </Card>

            </div>
        );
    }
}

export default AddSchoolSuccess;
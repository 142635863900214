import { RESET_CURR_UPLD, RESET_MESSAGES } from "../../base/utils/const";
import {
  ADD_TO_DEL_MNG_FILES,
  APPROVED_MNG_DRAFTS,
  DEL_MNG_DRAFT,
  DRAFT_MNG_IMG,
  DRAFT_REQ_IMG,
  GET_MNG_IMG,
  ROTATE_MNG_IMG,
  ROTATE_REQ_IMG,
  SET_CUR_REQ,
  STUD_PROFILE,
} from "../utils/const";

const initialState = {
  loading: false,
  errorMessage: "",
  successMessage: "",
  currentUploads: [],
  attachments: [],
  deletedImages: [],
  newAttach: [],
};

const ManagementUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${STUD_PROFILE}_PENDING`: {
      return {
        ...state,
        loading: true,
        currentUploads: [],
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${STUD_PROFILE}_FULFILLED`: {
      const currentUploads = [];
      const deletedImages = [];
      if (action.payload?.uploads) {
        for (const i of action.payload?.uploads) {
          if (i.status === "A") {
            currentUploads.push(i);
          } else {
            deletedImages.push(i);
          }
        }
      }

      return {
        ...state,
        loading: false,
        currentUploads: currentUploads,
        deletedImages: deletedImages,
      };
    }

    case `${STUD_PROFILE}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${DRAFT_MNG_IMG}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${DRAFT_MNG_IMG}_FULFILLED`: {
      const image = action.payload;
      const spImages = [];
      const { currentUploads } = state;
      const deletedImages = state.deletedImages;
      const dupImg = currentUploads.filter((cu) => cu.type === image.type);
      for (const i of currentUploads) {
        if (i.type === image.type) {
          deletedImages.push(i);
          spImages.push(image);
        } else {
          spImages.push(i);
        }
      }
      if (dupImg.length === 0) {
        spImages.push(image);
      }

      return {
        ...state,
        loading: false,
        currentUploads: spImages,
        deletedImages: deletedImages,
      };
    }

    case `${DRAFT_MNG_IMG}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case `${DEL_MNG_DRAFT}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
        deletedImages: [],
      };
    }

    case `${DEL_MNG_DRAFT}_FULFILLED`: {
      return {
        ...state,
        loading: false,
      };
    }

    case `${DEL_MNG_DRAFT}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case `${GET_MNG_IMG}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
        attachments: []
      };
    }

    case `${GET_MNG_IMG}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        attachments: action.payload
      };
    }

    case `${GET_MNG_IMG}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }


    case `${APPROVED_MNG_DRAFTS}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${APPROVED_MNG_DRAFTS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
      };
    }

    case `${APPROVED_MNG_DRAFTS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case `${ROTATE_MNG_IMG}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${ROTATE_MNG_IMG}_REJECTED`: {
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    }

    case `${ROTATE_MNG_IMG}_FULFILLED`: {
      const image = action.payload;
      const { currentUploads } = state;
      for (const i of currentUploads) {
        if (
          i.type === image.type &&
          i.managementUploadId === image.managementUploadId
        ) {
          i.angle = image.angle;
        }
      }

      return {
        ...state,
        loading: false,
        currentUploads: currentUploads,
        // successMessage: 'Image updated successfully'
      };
    }

    case ADD_TO_DEL_MNG_FILES: {
      const deletedImages = state.deletedImages.concat(action.payload);
      const { currentUploads } = state;
      for (const image of action.payload) {
        for (let i = 0; i < currentUploads.length; i++) {
          if (
            currentUploads[i].type === image.type &&
            currentUploads[i].managementUploadId === image.managementUploadId
          ) {
            currentUploads.splice(i, 1);
          }
        }
      }

      return {
        ...state,
        deletedImages: deletedImages,
        currentUploads: currentUploads,
      };
    }

    case RESET_CURR_UPLD: {
      return {
        ...state,
        currentUploads: []
      };
    }

    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    }

    case SET_CUR_REQ: {
      return {
        ...state,
        newAttach: action.payload.attach ? action.payload.attach : []
      }
    }

    case `${DRAFT_REQ_IMG}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${DRAFT_REQ_IMG}_FULFILLED`: {
      const image = action.payload;
      const spImages = [];
      const { newAttach } = state;
      const deletedImages = state.deletedImages;
      const dupImg = newAttach.filter((cu) => cu.type === image.type);
      for (const i of newAttach) {
        if (i.type === image.type) {
          deletedImages.push(i);
          spImages.push(image);
        } else {
          spImages.push(i);
        }
      }
      if (dupImg.length === 0) {
        spImages.push(image);
      }

      return {
        ...state,
        loading: false,
        newAttach: spImages,
        deletedImages: deletedImages,
      };
    }

    case `${DRAFT_REQ_IMG}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case `${ROTATE_REQ_IMG}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${ROTATE_REQ_IMG}_REJECTED`: {
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    }

    case `${ROTATE_REQ_IMG}_FULFILLED`: {
      const image = action.payload;
      const { attachments } = state;
      for (const i of attachments) {
        if (
          i.type === image.type &&
          i.managementUploadId === image.managementUploadId
        ) {
          i.angle = image.angle;
        }
      }

      const { newAttach } = state;
      for (const i of newAttach) {
        if (
          i.type === image.type &&
          i.managementUploadId === image.managementUploadId
        ) {
          i.angle = image.angle;
        }
      }
      return {
        ...state,
        loading: false,
        attachments: attachments,
        newAttach: newAttach,
        // successMessage: 'Image updated successfully'
      };
    }

    default:
      return state;
  }
};

export default ManagementUploadReducer;

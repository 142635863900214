import React from "react";
import { Row, Col, Card, Button, Spin } from "antd";
import "../styles/hallTicket.css";
import { getLoggedInUser } from "../../service/SessionStorageService";
import moment from "moment";
import { generateAppNum } from "../util/util";
import { PrinterOutlined } from "@ant-design/icons";
import { APP_URI } from "../../config/env";
import { APPRV, DOWN } from "../../sampoorna-siksha/utils/consts";

class HallTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: getLoggedInUser(),
      imgLoaded: false,
    };
  }

  componentWillMount = () => {
    this.props.fetchConfigByCode("SAMPLE");
    if (this.props.studentData.applicationForm.applicationId) {
      this.changeStatus();
    } else {
      this.props.getApplication(
        this.state.loggedInUser.username,
        moment().year()
      );
    }
  };

  getApplicationDetails = () => {
    if (
      !this.props.studentData.applicationForm.applicationId &&
      !this.props.studentData.loading &&
      this.props.profile
    ) {
      this.props.getApplication(this.props.profile.username, moment().year());
    }
  };

  changeStatus = () => {
    if (
      this.props.studentData.applicationForm.status === APPRV &&
      !this.props.ssLoding
    ) {
      const reasonView = {
        status: DOWN,
        applicationId: this.props.studentData.applicationForm.applicationId,
      };

      this.props.changeStatus(
        reasonView,
        this.props.profile.username,
        moment().year()
      );
    }
  };

  getRequiredDocs = () => {
    let date = "";
    if (this.props.appConfig.systemConfig.length) {
      for (const config of this.props.appConfig.systemConfig) {
        if (config.configCd === "REQ_DOCS") {
          date = config.info;
        }
      }
    }
    return date;
  };

  getExamDate = () => {
    let date = "";
    if (this.props.appConfig.systemConfig.length) {
      for (const config of this.props.appConfig.systemConfig) {
        if (config.configCd === "EXAM_DT") {
          date = moment
            .parseZone(config.configVal)
            .zone("+05:30")
            .format("DD-MM-YYYY");
        }
      }
    }
    return date;
  };

  getExamTime = () => {
    let date = "";
    if (this.props.appConfig.systemConfig.length) {
      for (const config of this.props.appConfig.systemConfig) {
        if (config.configCd === "EXAM_DT") {
          date = moment
            .parseZone(config.configVal)
            .zone("+05:30")
            .format("hh:mm A");
        }
      }
    }
    return date;
  };

  print = (e) => {
    window.print();
  };

  downloadSamplePaper = (e) => {
    e.preventDefault();
    const path = this.props.appConfig.currentConfig[0]?.configVal;
    this.props.getFile(path);
  };

  render = () => {
    // this.getApplicationDetails();
    const application = this.props.studentData.applicationForm;
    const studImg = this.props.studentData.studentImage;
    const currentConfig = this.props.appConfig.currentConfig;
    if (application.applicationId) {
      document.title =
        "Medha Charitable Trust Exam Hall Ticket " +
        generateAppNum(application);
    }
    if (application.status === APPRV && !this.props.ssLoding) {
      const that = this;
      setTimeout(() => {
        that.changeStatus();
      }, 1000);
    }
    return (
      <div>
        {application.hallTicket && application.hallTicket.hallTicketId && (
          <Spin spinning={!this.state.imgLoaded}>
            <Card bordered={true} id="hall-tckt-container">
              <div style={{ display: "inline-block", width: "100%" }}>
                <span
                  className="no-print"
                  style={{ float: "right", marginTop: "20px" }}
                >
                  <Button
                    type="primary"
                    shape="round"
                    icon={<PrinterOutlined />}
                    size="small"
                    onClick={(e) => this.print(e)}
                  >
                    Print
                  </Button>
                </span>
                <img
                  src="/MCT_logo.png"
                  alt="Medha Charitable Trust"
                  id="HallticketlogoImg"
                ></img>
                <span className="heading">
                  Medha Charitable Trust Exam Hall Ticket
                </span>
              </div>
              <Row className="hall-ticket-container">
                <Col lg={16} xs={16} className="print-left-container">
                  <div>
                    <div>
                      <label>Hall Ticket Number : </label>
                      {application.hallTicket.hallTicketNo}
                    </div>
                  </div>
                  <div>
                    <div>
                      <label>Student Name : </label>
                      {application.studentSurName} {application.studentName}
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <div>
                      <div>
                        Application Number: {generateAppNum(application)}
                      </div>
                      <div>
                        {" "}
                        <div>School Name: {application.school.configVal}</div>
                      </div>
                      <div>School Code: {application.school.info}</div>
                    </div>
                  </div>
                </Col>
                <Col lg={8} xs={8}>
                  <div className="hall-ticket-image">
                    <img
                      alt="Student"
                      width="175"
                      id="stud_img"
                      onLoad={(e) => {
                        this.setState({ imgLoaded: true });
                      }}
                      onError={(e) => {
                        this.setState({ imgLoaded: true });
                      }}
                      src={studImg.url}
                      style={{
                        transform:
                          "rotate(" +
                          (studImg.angle ? studImg.angle : 0) +
                          "deg)",
                      }}
                    ></img>
                  </div>
                </Col>
              </Row>
              <div className="exam-center-container">
                <strong>
                  Appear for Exam on {this.getExamDate()} at{" "}
                  {this.getExamTime()} at exam center:{" "}
                </strong>
                <div>{application.examCenter.configVal}</div>
                <div>{application.examCenter.address}</div>
              </div>
              <div
                style={{ marginTop: "20px" }}
                dangerouslySetInnerHTML={{ __html: this.getRequiredDocs() }}
              ></div>
              {currentConfig[0]?.configCd === "SAMPLE" && (
                <p className="no-print">
                  <a
                    href={
                      APP_URI +
                      "/upload/download?path=" +
                      encodeURIComponent(currentConfig[0]?.configVal)
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <b>Download Sample Exam Paper and OMR Sheet</b>
                  </a>
                </p>
              )}
            </Card>
          </Spin>
        )}
      </div>
    );
  };
}

export default HallTicket;

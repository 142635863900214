import XLSX from "xlsx";
import { message } from "antd";

const exportToFile = (data, fileName) => {
  /* convert state to workbook */
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
  /* generate XLSX file and send to client */
  if (!fileName) {
    fileName = "Sample";
  }
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export const exportFile = (columns, dataArray, fileName) => {
  const data = [];
  const heads = [];
  for (const col of columns) {
    if (col.key !== "action" && col.render) {
      heads.push(col.title);
    }
  }
  data.push(heads);
  for (const record of dataArray) {
    const row = [];
    for (const col of columns) {
      if (col.key !== "action" && col.render) {
        row.push(col.render("", record));
      }
    }
    data.push(row);
  }
  if(data.length>1)
  {
    exportToFile(data, fileName);
  }
  else{
    message.error("Please try after sometime.");
  }
};

import { connect } from "react-redux";
import { fetchOldDistricts } from "../../base/actions/AppConfigActions";
import {
  exportShortListedApplications,
  deleteDrafts,
  getMarksConfig,
  searchApplications,
} from "../actions/SSActions";
import {
  SS_SEARCH_FILTERS,
  SHOW_DETAILS,
  ADD_TO_DEL_FILES,
  SET_APPL,
  SEARCH_APPLICATIONS,
  ENROL,
  SMSS,
} from "../utils/consts";
import { updateImageAngle } from "../../student/actions/StudentActions";
import { RESET_MESSAGES } from "../../base/utils/const";
import {
  approveDrafts,
  deleteFiles,
  draftSurveyImg,
  getApplicationReviewDetails,
  updateApplicationStatus,
} from "../actions/ReviewActions";
import Enrollment from "../components/Enrollment";

const mapDispatchToProps = (dispatch, props) => {
  return {
    getOldDistricts() {
      dispatch(fetchOldDistricts());
    },

    searchApplications(form) {
      form.status = [SMSS, ENROL];
      dispatch(searchApplications(form));
    },

    setSearchFilters(form) {
      dispatch({
        type: SS_SEARCH_FILTERS,
        payload: form,
      });
    },

    offlineSearchApplication(form, allApplications) {
      this.setSearchFilters(form);
      this.setSearchApplication(allApplications);
    },

    setSearchApplication(applications) {
      dispatch({
        type: SEARCH_APPLICATIONS,
        payload: applications,
      });
    },

    exportToExcel(filters) {
      dispatch(exportShortListedApplications(filters));
    },

    getApplicationReviewDetails(applicationId) {
      dispatch(getApplicationReviewDetails(applicationId)).then((res) => {
        // this.setStep(7);
      });
    },

    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },

    updateApplicationStatus(applIds, status) {
      dispatch(updateApplicationStatus(applIds, status));
    },

    toggleDetails(flag) {
      dispatch({
        type: SHOW_DETAILS,
        payload: flag,
      });
    },

    // setFormSteps(stepsList) {
    //   dispatch({
    //     type: SET_FORM_STEPS,
    //     payload: stepsList,
    //   });
    // },

    // setCommitteeData(data, key) {
    //   dispatch({
    //     type: SET_COMMITTEE_DATA,
    //     payload: { key, data },
    //   });
    // },

    // updateSurveyData(surveyData, surveyList) {
    //   for (const s of surveyList) {
    //     s.surveyDetails = JSON.stringify(surveyData[s.surveyKey]);
    //   }
    //   this.saveApplicationReview(surveyList);
    // },

    // showPreviewAfterSave(flag) {
    //   dispatch({
    //     type: SHOW_PREVIEW_AFTER_SAVE,
    //     payload: flag,
    //   });
    // },

    updateImageAngle(angle, uploadId) {
      dispatch(updateImageAngle(uploadId, angle));
    },

    draftUpload(applicationId, file, type) {
      dispatch(draftSurveyImg(applicationId, file, type));
    },

    deleteDraft(applicationId) {
      dispatch(deleteDrafts(applicationId));
    },

    deleteFiles(filesList) {
      dispatch(deleteFiles(filesList));
    },

    addToDeleteFiles(filesList) {
      dispatch({
        type: ADD_TO_DEL_FILES,
        payload: filesList,
      });
    },

    approveDrafts(applicationId, filesList) {
      dispatch(approveDrafts(applicationId, filesList));
    },

    // complateSurvey(applicationId) {
    //   dispatch(complateSurvey(applicationId));
    // },

    getMarksConfig() {
      dispatch(getMarksConfig()).then((res) => {
        this.searchApplications({});
      });
    },

    setApplicationsList(surveyedList, searchedApplications) {
      dispatch({
        type: SET_APPL,
        payload: { surveyedList, searchedApplications },
      });
    },

  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    SSReducer,
    AppConfigReducer,
    StudentReducer,
    ReviewReducer,
  } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    sampurnaSiksha: SSReducer,
    appConfig: AppConfigReducer,
    oldDistrictsList: AppConfigReducer.oldDistrictsList,
    student: StudentReducer,
    review: ReviewReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Enrollment);

import {
  Modal,
  Space,
  Spin,
  Table,
  Tooltip,
  Button,
  Input,
  Select,
  Form,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  deleteConfig,
  fetchDistricts,
  saveConfig,
} from "../../base/actions/AppConfigActions";
import { DIST } from "../utils/const";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import { DEL_CONFIG, FETCH_CONFIG, SAVE_CONFIG } from "../../base/utils/const";
import { formItemLayout } from "../../sampoorna-siksha/utils/consts";
import { fetchOldDistricts } from "../actions/masterTables";
import { exportFile } from "../../common/utils/export";

const DIST_LIST_COLS = [
  {
    title: "District Name",
    dataIndex: "configVal",
    key: "configVal",
    render: (text, record) => record.configVal,
    sorter: {
      compare: (a, b) => sortNames(a.configVal, b.configVal),
    },
  },
  {
    title: "Old District",
    dataIndex: "parentTypeCd",
    key: "parentTypeCd",
    render: (text, record) => record.parent.configVal,
    sorter: {
      compare: (a, b) => sortNames(a.parent.configVal, b.parent.configVal),
    },
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    render: (text, record) => record.parent.info,
    sorter: {
      compare: (a, b) => sortNames(a.parent.info, b.parent.info),
    },
  },
];

const DistrictForm = (props) => {
  const [formData, setFormData] = useState(props.appConfig.currentConfig);
  const [form] = Form.useForm();
  useEffect(() => {
    props.fetchOldDistricts();
    setFormData(props.appConfig.currentConfig);
  }, []);

  const saveConfig = (formValues) => {
    console.log(formValues);
    formValues.configCd = "DIST";
    formValues.parentTypeCd = "ODIST";
    props.saveConfig(formValues);
  };
  return (
    <React.Fragment>
      <Modal
        title={formData.id ? "Edit District" : "Add District"}
        visible={props.masterTable.showModal === DIST}
        footer={null}
        onCancel={() => props.toogleAddModal("")}
        // width="90%"
      >
        <Spin spinning={props.masterTable.loading} delay={500}>
          <Form
            name="subject"
            className="login-form login-wrapper"
            onFinish={saveConfig}
            initialValues={formData}
            form={form}
          >
            <Form.Item {...formItemLayout} name="id" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item {...formItemLayout} name="configCd" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item {...formItemLayout} name="parentTypeCd" hidden={true}>
              <Input />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              name="configVal"
              label="District Name"
              rules={[
                {
                  pattern: /^\S[A-Za-z\s]+$/,
                  required: true,
                  message: "Please enter valid District Name",
                },
              ]}
            >
              <Input maxLength="50" />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              name="parentId"
              label="Old District"
              rules={[
                {
                  required: true,
                  message: "Please select Old District",
                },
              ]}
            >
              <Select className="full-width">
                {props.masterTable.oldDistrictsList.map((cat) => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.configVal}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div className="modal-form-button-div">
              <Button
                type="primary"
                htmlType="submit"
                className="modal-form-submit-button"
                disabled={props.masterTable.loading || props.appConfig.loading}
              >
                Save
              </Button>
            </div>
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  );
};

const Districts = (props) => {
  const [record, setRecord] = useState({});

  useEffect(() => {
    props.fetchDistricts();
  }, []);

  const handleAdd = () => {
    props.setCurrentConfig({});
    props.toogleAddModal(DIST);
  };

  const editConfig = (record, e) => {
    e.preventDefault();
    props.setCurrentConfig(record);
    props.toogleAddModal(DIST);
  };

  const deleteConfig = (record, e) => {
    e.preventDefault();
    Modal.confirm({
      title: "Do you want to delete this District?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
          props.deleteConfig(record.id);
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  const exportData = () => {
    exportFile(DIST_LIST_COLS, props.appConfig.districtsList, "Districts");
  };

  if (DIST_LIST_COLS.length < 4) {
    DIST_LIST_COLS.push({
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <a href="/" onClick={(e) => editConfig(record, e)}>
              <EditOutlined />
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <a href="/" onClick={(e) => deleteConfig(record, e)}>
              <DeleteOutlined />
            </a>
          </Tooltip>
        </Space>
      ),
    });
  }
  return (
    <React.Fragment>
      <h2>
        Districts
        <Button
          className="add-cat-button"
          onClick={(e) => handleAdd(e)}
          type="primary"
          style={{ float: "right" }}
          disabled={props.masterTable.loading}
        >
          Add District
        </Button>
        <Button
          className="add-cat-button"
          onClick={(e) => exportData(e)}
          style={{ float: "right", marginRight: "10px" }}
          disabled={props.masterTable.loading}
        >
          Export Excel
        </Button>
      </h2>
      <Spin
        spinning={props.masterTable.loading || props.appConfig.loading}
        delay={500}
      >
        <div>
          <Table
            columns={DIST_LIST_COLS}
            dataSource={props.appConfig.districtsList}
            pagination={{
              showSizeChanger: true,
              showTotal: () => {
                const total = props.appConfig.districtsList.length;
                return `Total ${total}`;
              },
            }}
          />
        </div>
      </Spin>
      {props.masterTable.showModal === DIST && (
        <DistrictForm record={record} {...props} />
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // offlineSearch(searchText, profiles) {
    //   if (searchText && searchText.length > 1) {
    //     const result = profiles.filter((profile) => {
    //       return studProfPredicate(profile, searchText);
    //     });
    //     dispatch({
    //       type: SET_FILTERED_PROFILES,
    //       payload: result,
    //     });
    //   } else if (!searchText) {
    //     dispatch({
    //       type: SET_FILTERED_PROFILES,
    //       payload: profiles,
    //     });
    //   }
    // },

    fetchDistricts() {
      dispatch(fetchDistricts());
    },
    fetchOldDistricts() {
      dispatch(fetchOldDistricts());
    },
    saveConfig(form) {
      dispatch(saveConfig(form)).then((res) => {
        if (res.action.type === `${SAVE_CONFIG}_FULFILLED`) {
          this.fetchDistricts();
          this.toogleAddModal("");
        }
      });
    },
    deleteConfig(id) {
      dispatch(deleteConfig(id)).then((res) => {
        if (res.action.type === `${DEL_CONFIG}_FULFILLED`) {
          this.fetchDistricts();
          this.toogleAddModal("");
        }
      });
    },
    setCurrentConfig(config) {
      dispatch({
        type: `${FETCH_CONFIG}_FULFILLED`,
        payload: config,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, AdminReducer, MasterTablesReducer, AppConfigReducer } =
    state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    masterTable: MasterTablesReducer,
    appConfig: AppConfigReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Districts)
);

import { connect } from "react-redux";
import UsersList from "../components/UsersList";
import {
  getUsersList,
  addUser,
  editUser,
  deleteUser,
} from "../actions/AdminActions";
import {
  TOGGLE_ADD_USER_MODAL,
  EDIT_USER,
  RESET_MESSAGES,
  EMPTY_USER_DETAILS,
} from "../utils/consts";
import { fetchOldDistricts } from "../../base/actions/AppConfigActions";

const mapDispatchToProps = (dispatch, props) => {
  return {
    getUsersList() {
      dispatch(getUsersList());
    },

    getOldDistricts() {
      dispatch(fetchOldDistricts());
    },

    resetMessages() {
      dispatch({
        type: RESET_MESSAGES,
        payload: {},
      });
    },

    toggleAddUserModal(showAddUserModal) {
      dispatch({
        type: EDIT_USER,
        payload: EMPTY_USER_DETAILS,
      });
      dispatch({
        type: TOGGLE_ADD_USER_MODAL,
        payload: showAddUserModal,
      });
    },

    createUser(userData) {
      dispatch(addUser(userData)).then((response) => {
        this.getUsersList();
      });
    },

    editUser(userData, curProf) {
      if (curProf.userId) {
        userData.username = curProf.user.username;
        userData.userId = curProf.userId;
      }
      dispatch(editUser(userData)).then((response) => {
        this.getUsersList();
      });
    },

    deleteUser(userData) {
      dispatch(deleteUser(userData)).then((response) => {
        this.getUsersList();
      });
    },

    setEditData(userData) {
      dispatch({
        type: EDIT_USER,
        payload: userData,
      });
    },

    showEditModal(userData, showEditUserModal) {
      dispatch({
        type: EDIT_USER,
        payload: userData,
      });
      dispatch({
        type: TOGGLE_ADD_USER_MODAL,
        payload: showEditUserModal,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, AdminReducer, AppConfigReducer } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    appConfig: AppConfigReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);

import { Col, Row, Spin, Steps } from "antd";
import React from "react";
import SelectionCommitteePreview from "./viewSurvey/SelectionCommitteePreview";

const { Step } = Steps;

class SelectionCommitteeForm extends React.Component {
  state = {
    current: 0,
    disableNext: true,
    reviewForm: {},
    surveyData: [],
  };

  next() {
    const current = this.props.review.step + 1;
    this.setState({ current });
    this.props.setStep(current);
  }

  prev() {
    const current = this.props.review.step - 1;
    this.setState({ current });
    this.props.setStep(current);
  }

  edit = (step) => {
    this.props.setStep(step);
  };

  saveDetails(key, data) {
    // console.log("----------", key, data);
    const { reviewForm } = this.state;
    let surveyData = this.props.review.reviewDetails.surveyList;
    if (!surveyData) {
      surveyData = [];
    }
    let obj = null;
    for (const i of surveyData) {
      if (i.surveyKey === key) {
        obj = i;
      }
    }
    obj.surveyDetails = JSON.stringify(data);
    reviewForm[key] = data;
    this.setState({ reviewForm, surveyData });
    this.props.saveApplication(surveyData, this.props.review.step + 1);
  }

  render() {
    return (
      <div id="selection-committee-form">
        <Spin spinning={this.props.review.loading} tip="Loading">
          <Row>
            <Col sm={24}>
              <div className="steps-content">
                <SelectionCommitteePreview
                  next={(e) => this.next()}
                  prev={(e) => this.prev()}
                  submitSurvey={this.submitSurvey}
                  edit={(e) => this.props.setStepByKey(e)}
                  isSelComt={true}
                  {...this.props}
                ></SelectionCommitteePreview>
              </div>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

export default SelectionCommitteeForm;

import React from "react";
import { Spin, Table, Space, Button, Modal } from "antd";
import AddUserModal from "./AddUserModal";
import "../styles/admin.css";
import { Roles } from "../../base/utils/roles";
import { ExclamationCircleOutlined } from "@ant-design/icons";

class UsersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
    };
  }

  componentWillMount() {
    this.props.getUsersList();
  }

  sortNames = (a, b) => {
    const alphabet = "*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyz";
    var index_a = alphabet.indexOf(a[0]),
      index_b = alphabet.indexOf(b[0]);

    if (index_a === index_b) {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return index_a - index_b;
    }
  };

  onChange = (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  };

  handleAdd = () => {
    this.props.toggleAddUserModal(true);
  };

  showDeleteConfirm = (user) => {
    const that = this;
    Modal.confirm({
      title: "Do you want to delete this user?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        that.props.deleteUser(user);
      },
      onCancel() {},
    });
  };

  editUser = (user, event) => {
    event.preventDefault();
    this.props.showEditModal(user, true);
  };

  deleteUser = (user, event) => {
    // this.props.deleteUser(user);
    event.preventDefault();
    this.showDeleteConfirm(user);
  };

  render() {
    const USERS_LIST_COLS = [
      {
        title: "Username",
        dataIndex: "username",
        key: "username",
        render: (text, record) => record.user.username,
        sorter: {
          compare: (a, b) => this.sortNames(a.user.username, b.user.username),
        },
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        render: (text, record) => Roles[record.user.role],
        sorter: {
          compare: (a, b) =>  this.sortNames(Roles[a.user.role], Roles[b.user.role]),
        },
      },
      {
        title: "Mobile Number",
        dataIndex: "mobileNumber",
        key: "mobileNumber",
        sorter: {
          compare: (a, b) => a.mobileNumber > b.mobileNumber,
        },
      },
      {
        title: "E-mail",
        dataIndex: "email",
        key: "email",
        sorter: {
          compare: (a, b) => this.sortNames(a.email, b.email),
        },
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <a href="/" onClick={(e) => this.editUser(record, e)}>
              Edit
            </a>
            |
            <a href="/" onClick={(e) => this.deleteUser(record, e)}>
              Delete
            </a>
          </Space>
        ),
      },
    ];
    return (
      <div>
        {/* <Col xs={24} sm={24}> */}
        {/* <Card >
                        <Row>
                            <Col xs={24} sm={20}>
                                <p className="add-user-heading">Associate's List</p>
                            </Col>
                            <Col xs={24} sm={4}>
                                <Button className="add-user-button" onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
                                    Add User
                            </Button>
                            </Col>
                        </Row>
                    </Card> */}
        {/* <Card> */}
        <Spin spinning={this.props.admin.loading} delay={500}>
          <Button
            className="add-user-button"
            onClick={this.handleAdd}
            type="primary"
            style={{ marginBottom: 16 }}
          >
            Add User
          </Button>
          <Table
            columns={USERS_LIST_COLS}
            dataSource={this.props.admin.usersList}
            pagination={{ showSizeChanger: true }}
          />
        </Spin>
        {/* </Card> */}
        {this.props.admin.showAddUser && <AddUserModal {...this.props} />}
        {/* </Col> */}
      </div>
    );
  }
}
export default UsersList;

import React from "react";
import { translate } from "../../base/actions/translate";
import { STATUS_LIST } from "../utils/consts";
import { Row, Col, Card, Spin } from "antd";

class SummaryCount extends React.Component {
  statusClicked = (status) => {
    this.props.statusClicked(status);
  };

  renderStatusChips = () => {
    const that = this;
    const statusList = [];
    for (const status of STATUS_LIST) {
      statusList.push(
        <a onClick={(e) => that.statusClicked(status.id)}>
          <Col
            className={["status-chip status-hover-chip", status.key.toLowerCase()]}
            key={status.key}
          >
            <div className="status-count">
            {this.getStatusCount(status.id) === undefined && <Spin spinning={true}></Spin>}
            {this.getStatusCount(status.id) !== undefined && this.getStatusCount(status.id)}
            </div>
            <div className="status-cd">{translate(status.key)}</div>
          </Col>
        </a>
      );
    }

    return statusList;
  };

  renderNoOperationalStatusChips = () => {
    const that = this;
    const statusList = [];
    for (const status of STATUS_LIST) {
      statusList.push(
        <Col className={["status-chip", status.key.toLowerCase()]} key={status.id}>
          <div className="status-count">
            {this.getStatusCount(status.id) === undefined && <Spin spinning={true}></Spin>}
            {this.getStatusCount(status.id) !== undefined && this.getStatusCount(status.id)}
          </div>
          <div className="status-cd">{translate(status.key)}</div>
        </Col>
      );
    }

    return statusList;
  };

  getStatusCount(statusCd) {
    let count = null;
    for (const summ of this.props.summaryList) {
      if (summ.status === statusCd) {
        count = summ.count;
      }
    }
    return count;
  }

  render() {
    return (
      <div className="status-card-container">
        <Spin tip="Loading..." spinning={this.props.loading}>
          <Card className="status-card">
            <Row>
              {this.props.isActionItem && (
                <a onClick={(e) => this.statusClicked("")}>
                  <Col
                    className="status-chip status-hover-chip total"
                    key="total"
                  >
                    <div className="status-count">
                      {this.getStatusCount(0) === undefined && (
                        <Spin spinning={true}></Spin>
                      )}
                      {this.getStatusCount(0) &&
                        this.getStatusCount(0)}
                    </div>
                    <div className="status-cd">{translate("TOTAL")}</div>
                  </Col>
                </a>
              )}
              {this.props.isActionItem && this.renderStatusChips()}

              {!this.props.isActionItem && (
                <Col className="status-chip total" key="total">
                  <div className="status-count">
                    {!this.getStatusCount(0) && (
                      <Spin spinning={true}></Spin>
                    )}
                    {this.getStatusCount(0) &&
                      this.getStatusCount(0)}
                  </div>
                  <div className="status-cd">{translate("TOTAL")}</div>
                </Col>
              )}

              {!this.props.isActionItem &&
                this.renderNoOperationalStatusChips()}
            </Row>
          </Card>
        </Spin>
      </div>
    );
  }
}

export default SummaryCount;

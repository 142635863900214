import React, { useEffect, useState } from "react";
import { generateTaskFilesList } from "../../utils/util";
import DocViewer from "../docViewer/DocViewer";
import TaskComments from "./TaskComments";

const CommentsAndDocs = (props) => {
  const [currentTask, setCurrentTask] = useState(props.currentTask);
  
  useEffect(() => {
    if (props.currentTask.taskId) {
      setCurrentTask(props.currentTask);
    }
  }, [props.currentTask]);

  return (
    <React.Fragment>
      {props.showDocs && (
        <div className="docView-container division">
          <DocViewer
            width={150}
            docs={generateTaskFilesList(
              props.tasks?.currentTask.uploads,
              currentTask.taskId
            )}
          />
        </div>
      )}

      <div className="comment-container division">
        <TaskComments comments={props.tasks?.currentTask.comments} />
      </div>      
    </React.Fragment>
  );
};
export default CommentsAndDocs;

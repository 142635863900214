import { Form, Radio } from "antd";
import React from "react";
import { translate } from "../../../base/actions/translate";
import { radioItemLayout, RESP_TO_MSS } from "../../utils/consts";
import { prepareReviewersFieldData } from "../../utils/util";
import ReviewStep from "./ReviewStep";

class ResponseToMSS extends React.Component {
  onFinish = (form) => {
    // console.log("Received values of form:", form);
    this.props.saveDetails(RESP_TO_MSS, form);
  };

  render() {
    let reviewData = this.props.review.reviewFormData[RESP_TO_MSS];
    if(!reviewData) {
      reviewData = {};
    } 
    const fieldData = prepareReviewersFieldData(reviewData);
    return (
      <div className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Response towards MSS"
          onFinish={(e) => this.onFinish(e)}
          {...this.props}
        >
          <Form.Item
            name="parentsIntrest"
            label={translate("parentsIntrest")}
            {...radioItemLayout}
            rules={[
              {
                required: true,
                message: "Please select ‘Yes’ or ‘No’",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="Y">Yes</Radio.Button>
              <Radio.Button value="N">No</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="collegeOffer"
            label={translate("collegeOffer")}
            {...radioItemLayout}
            rules={[
              {
                required: true,
                message: "Please select ‘Yes’ or ‘No’",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="Y">Yes</Radio.Button>
              <Radio.Button value="N">No</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="applyForIIIT"
            label={translate("applyForIIIT")}
            {...radioItemLayout}
            rules={[
              {
                required: true,
                message: "Please select ‘Yes’ or ‘No’",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="Y">Yes</Radio.Button>
              <Radio.Button value="N">No</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="preferMCT"
            label={translate("preferMCT")}
            {...radioItemLayout}
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const applyForIIIT = getFieldValue("applyForIIIT");
                  if (!value && applyForIIIT === "Y") {
                    return Promise.reject("Please select ‘Yes’ or ‘No’");
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Radio.Group>
              <Radio.Button value="Y">Yes</Radio.Button>
              <Radio.Button value="N">No</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="canSupportFin"
            label={translate("canSupportFin")}
            {...radioItemLayout}
            rules={[
              {
                required: true,
                message: "Please select ‘Yes’ or ‘No’",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="Y">Yes</Radio.Button>
              <Radio.Button value="N">No</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </ReviewStep>
      </div>
    );
  }
}

export default ResponseToMSS;

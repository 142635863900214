import { Button, Card, Col, Form, Row, Select } from "antd";
import React from "react";
import { formItemLayout, VEHC_INFO } from "../../utils/consts";
import { prepareReviewersFieldData } from "../../utils/util";
import ReviewStep from "./ReviewStep";

const count = [0, 1, 2, 3, 4, 5];

class VehiclesInfo extends React.Component {
  onFinish = (form) => {
    // console.log("Received values of form:", form);
    this.props.saveDetails(VEHC_INFO, form);
  };
  render() {
    let vehiclesInfo = this.props.review.reviewFormData[VEHC_INFO];
    if (!vehiclesInfo) {
      vehiclesInfo = {};
    }
    const fieldData = prepareReviewersFieldData(vehiclesInfo);
    return (
      <div className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Vehicles Information"
          onFinish={(e) => this.onFinish(e)}
          {...this.props}
        >
          <Row gutter={[16, 8]}>
            <Col sm={12}>
              <Form.Item
                {...formItemLayout}
                name="bicycles"
                label="Number of Bicycles"
                rules={[
                  {
                    required: true,
                    message: "Please select number of Bicycles",
                  },
                ]}
              >
                <Select placeholder="Select">
                  {count.map((i) => (
                    <Select.Option key={i} value={i}>
                      {i}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                {...formItemLayout}
                name="twoWheelers"
                label="Number of 2 Wheelers"
                rules={[
                  {
                    required: true,
                    message: "Please select number of 2 wheelers",
                  },
                ]}
              >
                <Select placeholder="Select">
                  {count.map((i) => (
                    <Select.Option key={i} value={i}>
                      {i}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                {...formItemLayout}
                name="threeWheelers"
                label="Number of 3 Wheelers"
                rules={[
                  {
                    required: true,
                    message: "Please select number of 3 Wheelers",
                  },
                ]}
              >
                <Select placeholder="Select">
                  {count.map((i) => (
                    <Select.Option key={i} value={i}>
                      {i}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                {...formItemLayout}
                name="fourWheelers"
                label="Number of 4 Wheelers"
                rules={[
                  {
                    required: true,
                    message: "Please select number of 4 Wheelers",
                  },
                ]}
              >
                <Select placeholder="Select">
                  {count.map((i) => (
                    <Select.Option key={i} value={i}>
                      {i}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </ReviewStep>
      </div>
    );
  }
}

export default VehiclesInfo;

import {
  Button,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Form,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  deleteExamCenter,
  fetchExamCenters,
  fetchOldDistricts,
  saveConfig,
} from "../../base/actions/AppConfigActions";
import { EXMCNT } from "../utils/const";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import { DEL_CONFIG, FETCH_CONFIG, SAVE_CONFIG } from "../../base/utils/const";
import { formItemLayout } from "../../sampoorna-siksha/utils/consts";
import { exportFile } from "../../common/utils/export";

const EXMCNT_LIST_COLS = [
  {
    title: "Exam Center Name",
    dataIndex: "configVal",
    key: "configVal",
    render: (text, record) => record.configVal,
    sorter: {
      compare: (a, b) => sortNames(a.configVal, b.configVal),
    },
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    render: (text, record) => record.info,
    sorter: {
      compare: (a, b) => sortNames(a.info, b.info),
    },
  },
  {
    title: "District Name",
    dataIndex: "parentTypeCd",
    key: "parentTypeCd",
    render: (text, record) => record.parent?.configVal,
    sorter: {
      compare: (a, b) => sortNames(a.parent?.configVal, b.parent?.configVal),
    },
  },
];

const EXL_EXMCNT_LIST_COLS = [
 {
    title: "Code",
    dataIndex: "code",
    key: "code",
    render: (text, record) => record.info,
  },
  {
    title: "District Name",
    dataIndex: "parentTypeCd",
    key: "parentTypeCd",
    render: (text, record) => record.parent?.configVal,
    sorter: {
      compare: (a, b) => sortNames(a.parent?.configVal, b.parent?.configVal),
    },
  },
  {
    title: "Exam Center Name",
    dataIndex: "configVal",
    key: "configVal",
    render: (text, record) => record.configVal,
  },
  {
    title: "Exam Center Address",
    dataIndex: "address",
    key: "address",
    render: (text, record) => record.address,
  },
];

const DelExamCenterForm = (props) => {
  const deleteExamCenter = (form) => {
    props.deleteExamCenter(props.appConfig.currentConfig.id, form.id);
  };
  return (
    <React.Fragment>
      <Modal
        title="Delete Exam Center"
        visible={props.masterTable.showModal === EXMCNT + "_DEL"}
        footer={null}
        onCancel={() => props.toogleAddModal("")}
      >
        <Form onFinish={deleteExamCenter}>
          <p>Please select a new exam center to move students</p>
          <Form.Item
            name="id"
            label="Exam Center"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (
                    value &&
                    Number.isInteger(value) &&
                    value !== props.appConfig.currentConfig.id
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Please select a valid Exam Center");
                },
              }),
            ]}
          >
            <Select className="full-width wrapped_select">
              {props.appConfig.examCenters.map((center) => (
                <Select.Option key={center.id} value={center.id}>
                  {center.configVal}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div style={{ textAlign: "center" }}>
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginRight: "5px" }}
              disabled={props.appConfig.loading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

const ExamCenterForm = (props) => {
  const [formData, setFormData] = useState(props.appConfig.currentConfig);

  useEffect(() => {
    props.fetchOldDistricts();
  }, []);

  const saveConfig = (formValues) => {
    console.log(formValues);
    formValues.configCd = EXMCNT;
    formValues.parentTypeCd = "ODIST";
    props.saveConfig(formValues);
  };

  return (
    <React.Fragment>
      <Modal
        title={formData.id ? "Edit Exam Center" : "Add Exam Center"}
        visible={props.masterTable.showModal === EXMCNT}
        footer={null}
        onCancel={() => props.toogleAddModal("")}
      >
        <Spin spinning={props.masterTable.loading} delay={500}>
          <Form
            name="appConfig"
            className="login-form login-wrapper"
            onFinish={saveConfig}
            initialValues={formData}
          >
            <Form.Item {...formItemLayout} name="id" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item {...formItemLayout} name="configCd" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item {...formItemLayout} name="parentTypeCd" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item {...formItemLayout} name="info" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="configVal"
              label="Exam Center Name"
              rules={[
                {
                  required: true,
                  pattern: /^\S[A-Za-z0-9\\(\\)_,-\\+\s]+$/,
                  message: "Please enter valid Exam Center Name",
                },
              ]}
            >
              <Input maxLength="100" />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              name="parentId"
              label="Old District"
              rules={[
                {
                  required: true,
                  message: "Please select Old District",
                },
              ]}
            >
              <Select className="full-width">
                {props.appConfig.oldDistrictsList.map((cat) => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.configVal}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              name="address"
              label="Exam Center Address"
              rules={[
                {
                  required: true,
                  min: 10,
                  message: "Please enter a valid Address",
                },
              ]}
            >
              <Input.TextArea maxLength="200" />
            </Form.Item>

            <div className="modal-form-button-div">
              <Button
                type="primary"
                htmlType="submit"
                className="modal-form-submit-button"
                disabled={props.appConfig.loading}
              >
                Save
              </Button>
            </div>
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  );
};

const ExamCenters = (props) => {
  const [record, setRecord] = useState({});

  useEffect(() => {
    props.fetchExamCenters();
  }, []);

  const handleAdd = () => {
    props.setCurrentConfig({});
    props.toogleAddModal(EXMCNT);
  };

  const editConfig = (record, e) => {
    e.preventDefault();
    props.setCurrentConfig(record);
    props.toogleAddModal(EXMCNT);
  };

  const deleteExamCenter = (record, e) => {
    e.preventDefault();
    props.setCurrentConfig(record);
    props.toogleAddModal(EXMCNT + "_DEL");
  };

  const exportExct = () => {
    exportFile(EXL_EXMCNT_LIST_COLS, props.appConfig.examCenters, "Exam_Centers");
  };

  if (EXMCNT_LIST_COLS.length < 4) {
    EXMCNT_LIST_COLS.push({
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <a href="/" onClick={(e) => editConfig(record, e)}>
              <EditOutlined />
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <a href="/" onClick={(e) => deleteExamCenter(record, e)}>
              <DeleteOutlined />
            </a>
          </Tooltip>
        </Space>
      ),
    });
  }
  return (
    <React.Fragment>
      <h2>
        Exam Centers
        <Space size="middle" style={{ float: "right" }}>
          <Button
            className="add-cat-button"
            onClick={handleAdd}
            type="primary"
            disabled={props.appConfig.loading}
          >
            Add Exam Center
          </Button>
          <Button
            className="add-cat-button"
            onClick={(e) => exportExct(e)}
            type="primary"
          >
            Export Exam Center
          </Button>
        </Space>
      </h2>
      <Spin
        spinning={props.masterTable.loading || props.appConfig.loading}
        delay={500}
      >
        <div>
          <Table
            columns={EXMCNT_LIST_COLS}
            dataSource={props.appConfig.examCenters}
            pagination={{
              showSizeChanger: true,
              showTotal: () => {
                const total = props.appConfig.examCenters.length;
                return `Total ${total}`;
              },
            }}
          />
        </div>
      </Spin>
      {props.masterTable.showModal === EXMCNT && (
        <ExamCenterForm record={record} {...props} />
      )}
      {props.masterTable.showModal === EXMCNT + "_DEL" && (
        <DelExamCenterForm record={record} {...props} />
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // offlineSearch(searchText, profiles) {
    //   if (searchText && searchText.length > 1) {
    //     const result = profiles.filter((profile) => {
    //       return studProfPredicate(profile, searchText);
    //     });
    //     dispatch({
    //       type: SET_FILTERED_PROFILES,
    //       payload: result,
    //     });
    //   } else if (!searchText) {
    //     dispatch({
    //       type: SET_FILTERED_PROFILES,
    //       payload: profiles,
    //     });
    //   }
    // },

    fetchExamCenters() {
      dispatch(fetchExamCenters(-1));
    },
    fetchOldDistricts() {
      dispatch(fetchOldDistricts(-1));
    },
    saveConfig(form) {
      dispatch(saveConfig(form)).then((res) => {
        if (res.action.type === `${SAVE_CONFIG}_FULFILLED`) {
          this.fetchExamCenters();
          this.toogleAddModal("");
        }
      });
    },
    deleteExamCenter(sourceId, destId) {
      dispatch(deleteExamCenter(sourceId, destId)).then((res) => {
        if (res.action.type === `${DEL_CONFIG}_FULFILLED`) {
          this.resetMessage();
          this.toogleAddModal("");
          this.fetchExamCenters();
        }
      });
    },
    setCurrentConfig(config) {
      dispatch({
        type: `${FETCH_CONFIG}_FULFILLED`,
        payload: config,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, AdminReducer, MasterTablesReducer, AppConfigReducer } =
    state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    masterTable: MasterTablesReducer,
    appConfig: AppConfigReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExamCenters)
);

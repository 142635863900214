import { connect } from "react-redux";
import {
  LANG_CHANGE,
  LOGOUT,
  LOGIN,
  TOGGLE_LOGIN_MODAL,
  REGISTER,
  TOGGLE_FORGOT_PWD,
  RESET_MESSAGES,
} from "../utils/const";
import Header from "../components/Header";
import { withRouter } from "react-router";
import { login, register, sendPassword } from "../actions/AuthActions";
import moment from "moment";
import { getCurrentUserDetails, fetchProfile } from "../actions/HomeActions";
import { fetchConfigByCode, getSystemConfigs } from "../actions/AppConfigActions";
import { saveUserProfile } from "../../service/SessionStorageService";

const mapDispatchToProps = (dispatch, props) => {
  return {
    setTranslate(lang) {
      dispatch({
        type: LANG_CHANGE,
        payload: lang,
      });
    },

    checkUserLoggedIn() {
      const isLoggedIn = sessionStorage.getItem("x-auth-token") ? true : false;
      dispatch({
        type: LOGIN,
        payload: isLoggedIn,
      });
      if (isLoggedIn) {
        this.getCurrentUserDetails();
      }
    },

    getCurrentUserDetails() {
      dispatch(getCurrentUserDetails()).then((resp) => {
        this.fetchProfile();
      });
    },

    logout() {
      sessionStorage.clear();
      dispatch({
        type: LOGOUT,
        payload: "You are logged out successfully.",
      });

      // console.log("..........loggedout.........");
      window.location = "/login";
    },

    toggleLoginModal(showLogin) {
      dispatch({
        type: TOGGLE_LOGIN_MODAL,
        payload: { showLogin },
      });
    },

    login(loginForm) {
      dispatch(login(loginForm)).then((resp) => {
        props.history.push('/home');
        this.resetMessages();
        dispatch(fetchConfigByCode('SAMPLE'));
      });
    },
    doRegister(key) {
      dispatch({
        type: REGISTER,
        payload: key ? key : "register",
      });
    },
    register(registerForm) {
      const form = {
        username: registerForm.username,
        dateOfBirth: moment(registerForm.dateOfBirth).utc(),
      };
      dispatch(register(form));
      // console.log("-----------reg---------", form);
    },
    toggleFgtPwdModal(showFgtPwd) {
      dispatch({
        type: TOGGLE_FORGOT_PWD,
        payload: { showFgtPwd },
      });
    },
    sendPassword(username) {
      dispatch(sendPassword(username));
    },

    fetchProfile() {
      dispatch(fetchProfile()).then((response) => {
        saveUserProfile(response.value);
      });
    },

    getSystemConfig() {
      dispatch(getSystemConfigs());
    },

    resetMessages() {
      dispatch({
        type: RESET_MESSAGES,
        payload: {}
       });
     },
  };
};

const mapStateToProps = (state) => {
  const { HeadReducer, AuthReducer, AppConfigReducer, AdminReducer } = state;
  return {
    lang: HeadReducer.lang,
    isLoggedIn: AuthReducer.isLoggedIn,
    isLoading: AuthReducer.isLoading,
    isSuccess: AuthReducer.isSuccess,
    errorMessage: AuthReducer.errorMessage,
    successMessage: AuthReducer.successMessage,
    userContext: AuthReducer.userContext,
    activeTab: AuthReducer.activeTab,
    showLogin: AuthReducer.showLogin,
    showForgot: AuthReducer.showForgot,
    role: AuthReducer.role,
    profile: AuthReducer.profile,
    appConfig: AppConfigReducer,
    currentProfile: AdminReducer.currentProfile
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

import { connect } from "react-redux";
import StatusSummary from "../components/StatusSummary";
import { getStatusSummary, searchApplications } from "../actions/SSActions";
import { SS_SEARCH_FILTERS } from "../utils/consts";

const mapDispatchToProps = (dispatch, props) => {
  return {
    getStatusSummary(year) {
      dispatch(getStatusSummary(year));
    },

    searchApplications(form) {
      dispatch(searchApplications(form));
    },

    setSearchFilters(form) {
      dispatch({
        type: SS_SEARCH_FILTERS,
        payload: form
      });
    }
  };
};

const mapStateToProps = state => {
  const { AuthReducer, AdminReducer, SSReducer } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    sampurnaSiksha: SSReducer
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusSummary);
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  message,
  Steps,
  Button,
  Card,
  Spin,
  Table,
  Row,
  Col,
  Form,
  Select,
  Input,
  DatePicker,
  Result,
  Space,
} from "antd";
import { useState } from "react";
import {
  UserOutlined,
  SolutionOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import { generateStudentId, getStatusString } from "../utils/utils";
import {
  COURSE,
  HOST,
  INST,
  MED_REQ,
  MON_REQ,
  WEL_REQ,
  WEL_REQ_TYP,
} from "../../mastertables/utils/const";
import {
  fetchAllAvailableBatchList,
  fetchStudentProfileById,
  searchStudentProfiles,
} from "../actions/studentList";
import { fetchMasterTableData } from "../../mastertables/actions/masterTables";
import { fetchCasteCategory } from "../../base/actions/AppConfigActions";
import { getMarksConfig } from "../../sampoorna-siksha/actions/SSActions";
import { PAY_MODE, PAY_TO, REQ_TYPES } from "../utils/const";
import { formItemLayout } from "../../sampoorna-siksha/utils/consts";
import { RESET_DATA, RESET_MESSAGES } from "../../base/utils/const";
import { Link } from "react-router-dom";
import { createBulkRequests } from "../actions/bulkRequests";

const { Step } = Steps;

const STUD_LIST_COLS = [
  {
    title: "Student ID",
    dataIndex: "studentId",
    key: "studentId",
    render: (text, record) => generateStudentId(record),
    sorter: {
      compare: (a, b) => sortNames(generateStudentId(a), generateStudentId(b)),
    },
  },
  {
    title: "Student Name",
    dataIndex: "studentName",
    key: "studentName",
    render: (text, record) => record.firstName + " " + record.lastName,
    sorter: {
      compare: (a, b) =>
        sortNames(
          a.firstName + " " + a.lastName,
          b.firstName + " " + b.lastName
        ),
    },
  },
  {
    title: "Batch",
    dataIndex: "batch",
    key: "batch",
    render: (text, record) => record.batch,
    sorter: {
      compare: (a, b) => a.batch - b.batch,
    },
  },
  {
    title: "Course Name",
    dataIndex: "courseName",
    key: "courseName",
    render: (text, record) => record.courses.join(", "),
    sorter: {
      compare: (a, b) =>
        sortNames(a.courses.join(","), b.courses.join(",")),
    },
  },
  {
    title: "Academic Year",
    dataIndex: "year",
    key: "year",
    render: (text, record) => record.batch + "-" + (record.batch + 1),
    sorter: {
      compare: (a, b) => sortNames(a.batch, b.batch),
    },
  },
  {
    title: "College Name",
    dataIndex: "instituteName",
    key: "instituteName",
    render: (text, record) => record.instituteNames.join(", "),
    sorter: {
      compare: (a, b) =>
        sortNames(a.instituteNames.join(","), b.instituteNames.join(",")),
    },
    width: "200px",
  },
  {
    title: "Hostel Name",
    dataIndex: "hostelName",
    key: "hostelName",
    render: (text, record) => record.hostelNames.join(", "),
    sorter: {
      compare: (a, b) =>
        sortNames(a.hostelNames.join(","), b.hostelNames.join(",")),
    },
    width: "200px",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text, record) => getStatusString(record.status),
    sorter: {
      compare: (a, b) => a.status - b.status,
    },
  },
];

export const selItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
};

const SearchCriteria = (props) => {
  const [courses, setCourses] = useState([]);
  const [form] = Form.useForm();

  const searchStudentsList = (form) => {
    props.searchStudentProfiles(form);
  };

  const populateCourses = (val) => {
    let courses = [];
    if(!val || val.length === 0 ) {
      setCourses(courses);
      return;
    }
    for (const cat of props.masterTable.institutes) {
      if (val.includes(cat.instituteId)) {
        for (const ic of cat.instituteCourses) {
          courses.push(ic.course);
        }
      }
    }
    setCourses(courses);
    form.setFieldsValue({
      courseId: "",
    });
  };

  return (
    <Spin spinning={props.masterTable.loading}>
      <Form onFinish={searchStudentsList} form={form}>
        <Row gutter={[16, 8]}>
          <Col xs={24} sm={3}>
            <Form.Item name="batches" label="Batch">
              <Select mode="multiple">
                {props.manage.batches
                .sort((a, b) => sortNames(b, a)) //#637
                .map((batch) => (
                  <Select.Option key={batch} value={batch}>
                    {batch}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item {...selItemLayout} name="instituteIds" label="College">
              <Select
                showSearch
                mode="multiple"
                filterOption={(input, option) =>
                  option.children.toUpperCase().includes(input.toUpperCase())
                }
                className="wrapped_select"
                onChange={(val) => populateCourses(val)}
              >
                {props.masterTable.institutes
                  .sort((a, b) => sortNames(a.name, b.name))
                  .map((cat) => (
                    <Select.Option
                      key={cat.instituteId}
                      value={cat.instituteId}
                    >
                      {cat.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item {...selItemLayout} name="courseIds" label="Course">
              <Select mode="multiple">
                {courses
                  .sort((a, b) => sortNames(a.courseName, b.courseName))
                  .map((cat) => (
                    <Select.Option key={cat.courseId} value={cat.courseId}>
                      {cat.courseName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item {...selItemLayout} name="hostelIds" label="Hostel">
              <Select
                showSearch
                mode="multiple"
                filterOption={(input, option) =>
                  option.children.toUpperCase().includes(input.toUpperCase())
                }
              >
                {props.masterTable.hostels
                  .sort((a, b) => sortNames(a.hostelName, b.hostelName))
                  .map((cat) => (
                    <Select.Option key={cat.hostelId} value={cat.hostelId}>
                      {cat.hostelName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={3}>
            <Form.Item name="statuses" label="Status">
              <Select mode="multiple">
                <Select.Option key="13" value="13">
                  Active
                </Select.Option>
                <Select.Option key="16" value="16">
                  Rejoined
                </Select.Option>
                <Select.Option key="15" value="15">
                  Dropped Out
                </Select.Option>
                <Select.Option key="14" value="14">
                  Completed
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            className="modal-form-submit-button"
          >
            Search
          </Button>
          <Button
            className="modal-form-submit-button"
            onClick={(e) => form.resetFields()}
          >
            Reset
          </Button>
        </div>
      </Form>
      <div>
        <StudentSelection
          selStudents={props.selStudents}
          onSelectChange={props.onSelectChange}
          data={props.manage.filteredProfiles}
          {...props}
        />
      </div>
      <div
        style={{
          textAlign: "right",
          paddingTop: "5px",
        }}
      >
        <Button
          type="primary"
          onClick={() => props.next()}
          disabled={props.selStudents.length === 0}
        >
          Next
        </Button>
      </div>
    </Spin>
  );
};

const StudentSelection = (props) => {
  const [formData, setFormData] = useState();

  const onSelectChange = (selectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", selectedRowKeys);
    props.onSelectChange(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: props.selStudents,
    onChange: onSelectChange,
  };

  return (
    <Spin spinning={props.manage.loading}>
      <div style={{ overflowY: "auto" }}>
        <Table
          className="row-selection"
          columns={STUD_LIST_COLS}
          rowSelection={rowSelection}
          rowKey={(record) => record.studentProfileId}
          dataSource={props.data}
          pagination={{ showSizeChanger: true }}
        />
      </div>
    </Spin>
  );
};
const dateFormat = "DD/MM/YYYY";
const RequestForm = (props) => {
  const [eventTypes, setEventTypes] = useState([]);
  const [requestType, setRequestType] = useState("");
  const [reqCat, setReqCat] = useState([]);
  const [subCatList, setSubCatList] = useState([]);
  //   const [formUpdated, setFormUpdated] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    setEventTypes(REQ_TYPES[props.role]);
  }, []);

  useEffect(() => {
    if (props.reqData.requestType) {
      onChange(props.reqData.requestType);
    }
  }, [props.reqData]);

  const onChange = (reqType) => {
    setRequestType(reqType);
    if (props.masterTable.reqCat.length > 0) {
      const cat = props.masterTable.reqCat.filter((c) => c.type === reqType);
      setReqCat(cat);
    }
  };

  const onCatChanged = (cat) => {
    setSubCatList(
      props.masterTable.reqCat?.find((c) => c.requestCategoryId === cat)
        .requestSubCategories
    );
    if (requestType === WEL_REQ) {
      form.setFieldsValue({
        welfare: {
          item: "",
        },
      });
    } else if (requestType === MON_REQ) {
      form.setFieldsValue({
        monetary: {
          subCategory: "",
        },
      });
    }
  };

  const sumitRequest = (formVal) => {
    console.log("Form vals", formVal);
    props.next(formVal);
  };

  return (
    <React.Fragment>
      <Form form={form} onFinish={sumitRequest} initialValues={props.reqData}>
        <div>
          <Row gutter={[16, 8]}>
            <Col sm={8} xs={24}>
              <Form.Item
                {...formItemLayout}
                label="Request Type"
                name="requestType"
              >
                <Select onChange={(e) => onChange(e)}>
                  {eventTypes.map((type) => (
                    <Select.Option key={type.key} value={type.key}>
                      {type.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {requestType === MED_REQ ? (
            <Row gutter={[16, 8]}>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["medical", "medicalType"]}
                  label="Medical Type"
                  rules={[
                    {
                      required: true,
                      message: "Please select Medical Type",
                    },
                  ]}
                >
                  <Select className="full-width">
                    {reqCat.map((typ) => (
                      <Select.Option
                        key={typ.requestCategoryId}
                        value={typ.requestCategoryId}
                      >
                        {typ.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["medical", "specialization"]}
                  label="Specialisation"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Doctor Specialisation",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["medical", "doctor"]}
                  label="Doctor Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Doctor Name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["medical", "medicalFacility"]}
                  label="Medical Facility"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Medical Facility name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["medical", "requestDate"]}
                  label="Date"
                  rules={[
                    {
                      required: true,
                      message: "Please select Join Date",
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormat}
                    className="full-width"
                    showToday={false}
                  />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["medical", "details"]}
                  label="Details"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Details",
                    },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["medical", "amount"]}
                  label="Amount"
                  rules={[
                    {
                      pattern: /^[1-9][0-9]*$/,
                      message: "Please enter a valid Amount",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["medical", "followupDate"]}
                  label="Follow up Date"
                >
                  <DatePicker
                    format={dateFormat}
                    className="full-width"
                    showToday={false}
                  />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["medical", "followupDetails"]}
                  label="Follow up Details"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value && getFieldValue("medical","followupDate")) {
                          return Promise.reject(
                            "Please enter details of follow up."
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
          ) : requestType === WEL_REQ ? (
            <Row gutter={[16, 8]}>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["welfare", "category"]}
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: "Please select Category",
                    },
                  ]}
                >
                  <Select
                    className="full-width"
                    onSelect={(e) => onCatChanged(e)}
                  >
                    {reqCat.map((typ) => (
                      <Select.Option
                        key={typ.requestCategoryId}
                        value={typ.requestCategoryId}
                      >
                        {typ.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["welfare", "item"]}
                  label="Item"
                  rules={[
                    {
                      required: true,
                      message: "Please select Item",
                    },
                  ]}
                >
                  <Select className="full-width">
                    {subCatList.map((typ) => (
                      <Select.Option
                        key={typ.requestSubCategoryId}
                        value={typ.requestSubCategoryId}
                      >
                        {typ.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["welfare", "quantity"]}
                  label="Quantity"
                  rules={[
                    {
                      required: true,
                      pattern: /^[1-9][0-9]*$/,
                      message: "Please enter a valid Quantity",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["welfare", "details"]}
                  label="Details"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Details",
                    },
                  ]}
                >
                  <Input.TextArea maxLength="1000" />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["welfare", "amount"]}
                  label="Amount"
                  rules={[
                    {
                      pattern: /^[1-9][0-9]*$/,
                      message: "Please enter a valid Amount",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          ) : requestType === MON_REQ ? (
            <Row gutter={[16, 8]}>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["monetary", "category"]}
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: "Please select Category",
                    },
                  ]}
                >
                  <Select
                    className="full-width"
                    onSelect={(e) => onCatChanged(e)}
                  >
                    {reqCat.map((typ) => (
                      <Select.Option
                        key={typ.requestCategoryId}
                        value={typ.requestCategoryId}
                      >
                        {typ.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["monetary", "subCategory"]}
                  label="Sub-Category"
                  rules={[
                    {
                      required: true,
                      message: "Please select item",
                    },
                  ]}
                >
                  <Select className="full-width">
                    {subCatList.map((typ, i) => (
                      <Select.Option
                        key={typ.requestSubCategoryId}
                        value={typ.requestSubCategoryId}
                      >
                        {typ.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["monetary", "modeOfPayment"]}
                  label="Mode of Payment"
                  rules={[
                    {
                      required: true,
                      message: "Please select Mode of Payment",
                    },
                  ]}
                >
                  <Select className="full-width">
                    {PAY_MODE.map((typ, i) => (
                      <Select.Option key={"item-" + i} value={typ.key}>
                        {typ.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["monetary", "transferTo"]}
                  label="Transfer to"
                  rules={[
                    {
                      required: true,
                      message: "Please select Transfer to",
                    },
                  ]}
                >
                  <Select className="full-width">
                    {PAY_TO.map((typ, i) => (
                      <Select.Option key={"item-" + i} value={typ.key}>
                        {typ.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["monetary", "details"]}
                  label="Details"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Details",
                    },
                  ]}
                >
                  <Input.TextArea maxLength="1000" />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={["monetary", "amount"]}
                  label="Amount"
                  rules={[
                    {
                      required: true,
                      pattern: /^[1-9][0-9]*$/,
                      message: "Please enter valid Amount",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            textAlign: "right",
            paddingTop: "5px",
          }}
        >
          <Space>
            <Button onClick={() => props.prev(form.getFieldsValue())}>
              Previous
            </Button>
            <Button type="primary" htmlType="submit" disabled={props.bulk.loading}>
              Submit
            </Button>
          </Space>
        </div>
      </Form>
    </React.Fragment>
  );
};

const BulkRequestCreation = (props) => {
  const [stepStatus, setStepStatus] = useState(["process", "wait", "wait"]);
  const [current, setCurrent] = useState(0);
  const [selStudents, setSelStudents] = useState([]);
  const [reqData, setReqData] = useState({});

  useEffect(() => {
    props.resetData();
    props.fetchMasterTableData(INST);
    props.fetchMasterTableData(HOST);
    props.fetchMasterTableData(COURSE);
    props.fetchAllAvailableBatchList();
    props.getReqCats();
  }, []);

  useEffect(() => {
    if (current === stepStatus.length - 2 && props.bulk.requestCreated) {
      setCurrent(stepStatus.length - 1);
      stepStatus[current] = "finish";
      stepStatus[current + 1] = "finish";
    }
  }, [props.bulk.requestCreated]);

  const createAnother = () => {
    setCurrent(0);
    setStepStatus(["process", "wait", "wait"]);
    setSelStudents([]);
    setReqData({});
    props.resetData();
  };

  const next = () => {
    if (current > stepStatus.length - 1) {
      return;
    }
    stepStatus[current] = "finish";
    stepStatus[current + 1] = "process";
    setCurrent(current + 1);
    setStepStatus(stepStatus);
  };

  const prev = (reqFormData) => {
    setReqData(reqFormData);
    if (current <= 0) {
      return;
    }
    stepStatus[current - 1] = "process";
    setCurrent(current - 1);
    setStepStatus(stepStatus);
  };

  const onSelectChange = (selectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelStudents(selectedRowKeys);
  };

  const submitRequest = (reqForm) => {
    setReqData(reqData);
    reqForm.studentIds = selStudents;
    console.log("Bulk req Form - ", reqForm);
    props.createBulkRequests(reqForm);
  };

  return (
    <React.Fragment>
      <Card title="Bulk Request">
        <Steps>
          <Step
            status={stepStatus[0]}
            title="Student Selection"
            icon={<UserOutlined />}
          />
          <Step
            status={stepStatus[1]}
            title="Request Form"
            icon={<SolutionOutlined />}
          />
          <Step status={stepStatus[2]} title="Done" icon={<SmileOutlined />} />
        </Steps>
        <Card className="steps-content">
          {current === 0 ? (
            <SearchCriteria
              selStudents={selStudents}
              onSelectChange={onSelectChange}
              next={next}
              {...props}
            />
          ) : current === 1 ? (
            <RequestForm
              prev={prev}
              next={submitRequest}
              reqData={reqData}
              {...props}
            />
          ) : (
            <div>
              <Result
                status={props.bulk.requestCreated === "s" ? "success" : "error"}
                title={
                  props.bulk.requestCreated === "s"
                    ? props.bulk.successMessage
                    : props.bulk.errorMessage
                }
                extra={[
                  <Button
                    type="primary"
                    key="aother"
                    onClick={(e) => createAnother(e)}
                  >
                    Create Another
                  </Button>,
                  <Link to="/home" key="homeLink">
                    <Button key="home">Back to Home</Button>
                  </Link>,
                ]}
              />
            </div>
          )}
        </Card>
      </Card>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllAvailableBatchList() {
      dispatch(fetchAllAvailableBatchList());
    },

    fetchMasterTableData(dataType) {
      dispatch(fetchMasterTableData(dataType));
    },

    fetchStudentProfileById(id) {
      dispatch(fetchStudentProfileById(id));
    },

    getCastes() {
      dispatch(fetchCasteCategory());
    },

    getMarksConfig() {
      dispatch(getMarksConfig());
    },

    searchStudentProfiles(form) {
      dispatch(searchStudentProfiles(form));
    },

    getReqCats() {
      dispatch(fetchMasterTableData("reqCat"));
    },

    createBulkRequests(form) {
      dispatch(createBulkRequests(form));
    },

    resetData() {
      dispatch({ type: RESET_DATA });
    },
    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    ManagementReducer,
    MasterTablesReducer,
    AppConfigReducer,
    ManagementUploadReducer,
    BulkRequestsReducer,
  } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    manage: ManagementReducer,
    masterTable: MasterTablesReducer,
    appConfig: AppConfigReducer,
    mgUploads: ManagementUploadReducer,
    bulk: BulkRequestsReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BulkRequestCreation)
);

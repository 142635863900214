import { te } from "../../config/te";
import { en } from "../../config/en";

export const translate = (label) => {
  if(!label) {
    return "";
  }
  const lang = sessionStorage.getItem("lang");
  switch (lang) {
    case "te": {
        let text = te[label];
      return text ? text : en[label];
    }
    case "en":
      return en[label];
    default:
      return en[label];
  }
};

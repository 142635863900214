import {
  Button,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Form,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  deleteSchool,
  fetchMandals,
  fetchSchools,
  saveConfig,
} from "../../base/actions/AppConfigActions";
import { INACTV, MNDL, SCHL } from "../utils/const";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { sortNames, toCapital } from "../../sampoorna-siksha/utils/util";
import { DEL_CONFIG, FETCH_CONFIG, SAVE_CONFIG } from "../../base/utils/const";
import { exportFile } from "../../common/utils/export";
import { translate } from "../../base/actions/translate";

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  //   wrapperCol: {
  //     xs: { span: 24 },
  //     sm: { span: 12 },
  //   },
};

const SCHL_LIST_COLS = [
  {
    title: "School Name",
    dataIndex: "configVal",
    key: "configVal",
    render: (text, record) => record.configVal,
    sorter: {
      compare: (a, b) => sortNames(a.configVal, b.configVal),
    },
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    render: (text, record) => record.info,
    sorter: {
      compare: (a, b) => sortNames(a.info, b.info),
    },
  },
  {
    title: "Old District Name",
    dataIndex: "grandParentTypeCd",
    key: "grandParentTypeCd",
    render: (text, record) => record.parent?.parent?.parent?.configVal,
    sorter: {
      compare: (a, b) =>
        sortNames(
          a.parent?.parent?.parent?.configVal,
          b.parent?.parent?.parent?.configVal
        ),
    },
  },
  {
    title: "District Name",
    dataIndex: "parentTypeCd",
    key: "parentTypeCd",
    render: (text, record) => record.parent?.parent?.configVal,
    sorter: {
      compare: (a, b) =>
        sortNames(a.parent?.parent?.configVal, b.parent?.parent?.configVal),
    },
  },
  {
    title: "Mandal Name",
    dataIndex: "parentTypeCd",
    key: "parentTypeCd",
    render: (text, record) => record.parent?.configVal,
    sorter: {
      compare: (a, b) => sortNames(a.parent?.configVal, b.parent?.configVal),
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text, record) => record.status,
    sorter: {
      compare: (a, b) => sortNames(a.status, b.status),
    },
  },
];

const DelSchoolForm = (props) => {
  const [skip, setSkip] = useState(false);
  const deleteSchool = (form) => {
    props.deleteSchool(props.appConfig.currentConfig.id, form.id ?? -1);
  };
  return (
    <React.Fragment>
      <Modal
        title="Delete School"
        visible={props.masterTable.showModal === SCHL + "_DEL"}
        footer={null}
        onCancel={() => props.toogleAddModal("")}
        width="70%"
      >
        <Form onFinish={deleteSchool}>
          <p>Please select a new School to move students</p>
           <p>
            *If you wish not to move students data to another school then use{" "}
            <b>Skip&save</b>
          </p>
          <Form.Item
            name="id"
            label="School"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (skip) {
                    return Promise.resolve();
                  }
                  if (
                    value &&
                    Number.isInteger(value) &&
                    value !== props.appConfig.currentConfig.id
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Please select a valid School");
                },
              }),
            ]}
          >
            <Select className="full-width wrapped_select">
              {props.appConfig.schoolsList.map((center) => (
                <Select.Option key={center.id} value={center.id}>
                  {center.configVal}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div style={{ textAlign: "center" }}>
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginRight: "5px" }}
              disabled={props.appConfig.loading}
            >
              Save
            </Button>
            <Button
              htmlType="submit"
              type="secondary"
              style={{ marginRight: "5px" }}
              disabled={props.appConfig.loading}
              onClick={() => setSkip(true)}
            >
              Skip&Save
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

const SchoolForm = (props) => {
  const [formData, setFormData] = useState(props.appConfig.currentConfig);

  useEffect(() => {
    props.fetchMandals();
  }, []);

  const saveConfig = (formValues) => {
    console.log(formValues);
    formValues.configCd = SCHL;
    formValues.parentTypeCd = MNDL;
    props.saveConfig(formValues);
  };
  return (
    <React.Fragment>
      <Modal
        title={formData.id ? "Edit School" : "Add School"}
        visible={props.masterTable.showModal === SCHL}
        footer={null}
        onCancel={() => props.toogleAddModal("")}
        width="70%"
      >
        <Spin spinning={props.masterTable.loading} delay={500}>
          <Form
            name="subject"
            className="login-form login-wrapper"
            onFinish={saveConfig}
            initialValues={formData}
          >
            <Form.Item {...formItemLayout} name="id" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item {...formItemLayout} name="configCd" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item {...formItemLayout} name="parentTypeCd" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item {...formItemLayout} name="info" hidden={true}>
              <Input />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              name="configVal"
              label="School Name"
              getValueFromEvent={toCapital}
              rules={[
                {
                  required: true,
                  message: translate("schoolNameError"),
                },
              ]}
            >
              <Input maxLength="100" />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              name="parentId"
              label="Mandal"
              rules={[
                {
                  required: true,
                  message: "Please select Mandal",
                },
              ]}
            >
              <Select
                className="full-width"
                showSearch
                filterOption={(input, option) =>
                  option.children.toUpperCase().includes(input.toUpperCase())
                }
              >
                {props.appConfig.mandalsList.map((cat) => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.configVal}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div className="modal-form-button-div">
              <Button
                type="primary"
                htmlType="submit"
                className="modal-form-submit-button"
                disabled={props.appConfig.loading}
              >
                Save
              </Button>
            </div>
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  );
};

const Schools = (props) => {
  const [record, setRecord] = useState({});

  useEffect(() => {
    props.fetchSchools();
  }, []);

  const handleAdd = () => {
    props.setCurrentConfig({});
    props.toogleAddModal(SCHL);
  };

  const editConfig = (record, e) => {
    e.preventDefault();
    props.setCurrentConfig(record);
    props.toogleAddModal(SCHL);
  };

  const deleteSchool = (record, e) => {
    e.preventDefault();
    props.setCurrentConfig(record);
    props.toogleAddModal(SCHL + "_DEL");
  };

  const exportSchools = () => {
    exportFile(SCHL_LIST_COLS, props.appConfig.schoolsList, "Schools");
  };

  if (SCHL_LIST_COLS.length < 7) {
    SCHL_LIST_COLS.push({
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <a
              href="/"
              onClick={(e) => {
                if (record.status === INACTV) {
                  e.preventDefault();
                } else {
                  editConfig(record, e);
                }
              }}
              disabled={record.status === INACTV ? true : false}
            >
              <EditOutlined />
            </a>
          </Tooltip>

          <Tooltip title="Delete">
            <a
              href="/"
              onClick={(e) => {
                if (record.status === INACTV) {
                  e.preventDefault();
                } else {
                  deleteSchool(record, e);
                }
              }}
              disabled={record.status === INACTV ? true : false}
            >
              <DeleteOutlined />
            </a>
          </Tooltip>
        </Space>
      ),
    });
  }
  return (
    <React.Fragment>
      <h2>
        Schools
        <Space size="middle" style={{ float: "right" }}>
          <Button
            className="add-cat-button"
            onClick={(e) => handleAdd(e)}
            type="primary"
            disabled={props.appConfig.loading}
          >
            Add School
          </Button>
          <Button
            className="add-cat-button"
            onClick={(e) => exportSchools(e)}
            type="primary"
          >
            Export Schools
          </Button>
        </Space>
      </h2>
      <Spin
        spinning={props.masterTable.loading || props.appConfig.loading}
        delay={500}
      >
        <div>
          <Table
            columns={SCHL_LIST_COLS}
            dataSource={props.appConfig.schoolsList}
            pagination={{
              showSizeChanger: true,
              showTotal: () => {
                const total = props.appConfig.schoolsList.length;
                return `Total ${total}`;
              },
            }}
          />
        </div>
      </Spin>
      {props.masterTable.showModal === SCHL && (
        <SchoolForm record={record} {...props} />
      )}
      {props.masterTable.showModal === SCHL + "_DEL" && (
        <DelSchoolForm record={record} {...props} />
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // offlineSearch(searchText, profiles) {
    //   if (searchText && searchText.length > 1) {
    //     const result = profiles.filter((profile) => {
    //       return studProfPredicate(profile, searchText);
    //     });
    //     dispatch({
    //       type: SET_FILTERED_PROFILES,
    //       payload: result,
    //     });
    //   } else if (!searchText) {
    //     dispatch({
    //       type: SET_FILTERED_PROFILES,
    //       payload: profiles,
    //     });
    //   }
    // },

    fetchSchools() {
      dispatch(fetchSchools(-1));
    },
    fetchMandals() {
      dispatch(fetchMandals(-1));
    },
    saveConfig(form) {
      dispatch(saveConfig(form)).then((res) => {
        if (res.action.type === `${SAVE_CONFIG}_FULFILLED`) {
          this.fetchSchools();
          this.toogleAddModal("");
        }
      });
    },
    deleteSchool(sourceId, destId) {
      dispatch(deleteSchool(sourceId, destId)).then((res) => {
        if (res.action.type === `${DEL_CONFIG}_FULFILLED`) {
          this.resetMessage();
          this.toogleAddModal("");
          this.fetchSchools();
        }
      });
    },
    setCurrentConfig(config) {
      dispatch({
        type: `${FETCH_CONFIG}_FULFILLED`,
        payload: config,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, AdminReducer, MasterTablesReducer, AppConfigReducer } =
    state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    masterTable: MasterTablesReducer,
    appConfig: AppConfigReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Schools)
);

import React from 'react';
import { Row, Card, Button, Spin, notification, Result } from 'antd';
import '../styles/applicationForm.css';
import { Link } from 'react-router-dom';
import { DownloadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { translate } from '../../base/actions/translate';
import { Steps } from 'antd';
import { UserOutlined, SolutionOutlined, SmileOutlined, QuestionCircleOutlined, HomeOutlined } from '@ant-design/icons';
import BasicForm from './BasicForm';
import UploadForm from './UploadForm';
import Form from 'antd/lib/form/Form';
import moment from 'moment';
import { Redirect } from 'react-router';
import Verification from './Verification';
import { getLoggedInUser } from '../../service/SessionStorageService';
import { STUDE } from '../../base/utils/roles';
import { INCOMP, NEW } from '../../sampoorna-siksha/utils/consts';
import Student_Identification from "../../assets/forms/Student_Identification.pdf";

const { Step } = Steps;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

class ApplicationFrom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            maxSteps: 3,
            status: [
                'finish', 'wait', 'wait', 'wait'
            ],
            loggedInUser: getLoggedInUser()

        };

        this.downloadRef = React.createRef();
    }

    componentWillMount() {
        this.props.updateAppTab(0);
    }

    componentDidMount() {
        this.props.setMssApplicationForm(React.createRef());
        // this.props.getFile(4, STUD_IMG);
        if (this.props.profile) {
            this.props.getApplication(this.props.profile.username, moment().year());
        }

        this.props.getCastes();
        this.props.getDistricts();
        this.props.getExamCenters();
        // if (this.props.studentData.applicationForm.districtId) {
        //     this.props.getMandalsList(this.props.studentData.applicationForm.districtId);
        // }
        // if (this.props.studentData.applicationForm.mandalId) {
        //     this.props.getSchoolsList(this.props.studentData.applicationForm.mandalId);
        // }

    }

    submitBasicForm = (form) => {
        // console.log('Form submission --------------', form);
        this.props.handleApplicationFormFields(form);
        this.props.saveMssApplicationForm(form, this.props.studentData.applicationForm, this.props.profile.username);
        // this.next();
    }

    submitUploadForm = (values) => {
        const msg = [];
        // console.log('Upload form values -------------', values);
        const { studentImage, studentIdProof, ninthMemoBack, ninthMemoFront } = this.props.studentData;
        if (!studentImage.url || !studentIdProof.url || !ninthMemoBack.url || !ninthMemoFront.url) {
            if (!studentImage.url) {
                msg.push('Please upload all required files');
            } else if (!studentIdProof.url) {
                msg.push('Please upload all required files');
            } else if (!ninthMemoFront.url) {
                msg.push('Please upload all required files');
            } else if (!ninthMemoBack.url) {
                msg.push('Please upload all required files');
            }
            notification['error']({
                message: 'Error Notification',
                description: msg,
            });
        } else {
            this.next();
        }

    }

    submitApplication = () => {
        this.props.submitApplication(this.props.studentData.applicationForm);
        this.next();
    }

    openNotificationWithIcon = form => {
        const msg = [];
        for (let errorField of form.errorFields) {
            msg.push(errorField.errors);
        }
        notification['error']({
            message: 'Error Notification',
            description: 'Please check errors.',
        });
    };

    next = () => {
        if (this.props.studentData.applicationTab === this.state.maxSteps) {
            return;
        }
        const current = this.props.studentData.applicationTab + 1;
        const status = this.state.status;
        status[current] = 'finish';
        this.setState({ status });
        this.props.updateAppTab(current);
        // window.scrollTo(0, 0);
    }

    prev = () => {
        if (this.props.studentData.applicationTab <= 0) {
            return;
        }
        const current = this.props.studentData.applicationTab - 1;
        const status = this.state.status;
        status[current + 1] = 'wait';
        this.setState({ status });
        this.props.updateAppTab(current);
        if(current === 0) {
            this.props.getApplication(this.props.profile.username, moment().year());
        }
    }

    edit = () => {
        if (this.props.studentData.applicationTab <= 0) {
            return;
        }
        const current = 0;
        const status = this.state.status;
        this.setState({ current, status });
        this.props.updateAppTab(current);
    }

    render() {
        if (this.props.role !== STUDE) {
            return <Redirect to="/home" />
        }

        if(this.props.studentData.applicationForm.status && (this.props.studentData.applicationForm.status !== INCOMP && this.props.studentData.applicationForm.status !== NEW)) {
            return <Redirect to="/home" />
        }
        const { status } = this.state;
        const current = this.props.studentData.applicationTab;
        const applicationForm = this.props.studentData.fieldData;
        if (applicationForm.length > 0) {
            for (let field of applicationForm) {
                if (field.name[0] === 'dateOfBirth') {
                    field.value = moment(field.value);
                }
            }
        }
        const uploads = this.props.studentData.uploadFieldData;

        return (
            <div>
                <span id="scrollhelp"></span>
                {current === 0 &&
                    <Row>
                        <Card bordered={false} className="card-95 mg-l" >
                            <div className="inline-block" style={{ marginRight: '10px' }} id="downloadId">
                                {translate('applocationFormMessage')}
                            </div>
                            <Button type="primary" shape="round" icon={<DownloadOutlined />}  >
                                <a href={Student_Identification} target="_blank" style={{ color: '#fff' }}>Download</a>
                            </Button>
                        </Card>
                    </Row>
                }

                <Card className="card-95 mg-l">
                    <Row>
                        <Steps id="appStepper" current={current}>
                            <Step  title="Application" icon={<UserOutlined />} />
                            <Step  title="Upload Documents" icon={<CloudUploadOutlined />} />
                            <Step  title="Verification" icon={<SolutionOutlined />} />
                            <Step  title="Complete" icon={<SmileOutlined />} />
                        </Steps>
                    </Row>
                    {current === 0 &&
                        <Spin spinning={this.props.formLoading || this.props.studentData.loading} delay={50}>
                            <Form  {...layout} name="student-application"
                                // onFieldsChange={this.props.handleApplicationFormFiels}
                                // onValuesChange={this.props.handleApplicationFormFiels}
                                onFinish={this.submitBasicForm}
                                onFinishFailed={this.openNotificationWithIcon}
                                fields={applicationForm}
                                ref={this.props.studentData.mssApplicationForm}>
                                <BasicForm {...this.props}></BasicForm>
                                <div className="steps-action">
                                    <Button type="primary" htmlType="submit">
                                        Next
                                    </Button>
                                </div>
                            </Form>
                        </Spin>
                    }
                    {current === 1 &&
                        <Spin spinning={this.props.studentData.loading} delay={500}>
                            <Form name="validate_other" onFinish={this.submitUploadForm}
                                fields={uploads}
                                onFinishFailed={this.openNotificationWithIcon}>
                                <UploadForm {...this.props}></UploadForm>
                                <div className="steps-action">
                                    <Button style={{ margin: 8 }} onClick={() => this.prev()}>
                                        Previous
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Next
                                    </Button>
                                </div>
                            </Form>
                        </Spin>
                    }
                    {current === 2 &&
                        <div>
                            <h3 className="verifyTopText">{translate('applicationPreviewHeading')} </h3>
                            <Verification {...this.props} />
                            <div className="steps-action">
                                <Button style={{ margin: 8 }} onClick={() => this.edit()}>
                                    Edit
                                    </Button>
                                <Button type="primary" onClick={() => this.submitApplication()}>
                                    Submit
                                    </Button>
                            </div>
                        </div>

                    }
                    {current === 3 &&
                        <div className="thankNote">
                            <Result
                                status="success"
                                title={translate('applicationCompleteHead')}
                                subTitle={translate('applicationCompleteMessage')}
                                extra={[
                                    <Link to="/home">
                                    <Button type="primary" key="home" icon={<HomeOutlined />}>
                                        Home Page
                                    </Button>
                                    </Link>,
                                    <Link to="/faqs">
                                    <Button key="faqs" icon={<QuestionCircleOutlined />}>Frequently Asked Questions</Button>
                                    </Link>,
                                    // <div className="thankNoteBottom"> Please <Link to="/faqs">click here</Link> for some freequently asked questions.</div>,
                                    // <div className="thankNoteBottom">Please <Link to="/home">click here</Link> to go to home page.</div>,
                                ]}
                            />



                        </div>
                    }

                </Card>
            </div>
        );
    }
}

export default ApplicationFrom;
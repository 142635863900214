import {
  ADMIN,
  MGR,
  MSSPC,
  MSSCC,
  MWCO,
  ACCEX,
  DSTIN,
  SELCOMT,
} from "../../base/utils/roles";

export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const TOGGLE_ADD_USER_MODAL = "TOGGLE_ADD_USER_MODAL";
export const RESET_MESSAGES = "RESET_MESSAGES";
export const GET_HT_DETAILS = "GET_HT_DETAILS";
export const ADMIN_SHORT_LIST = "ADMIN_SHORT_LIST";
export const SEND_ACCT_INFO = "SEND_ACCT_INFO";
export const SET_FILTERED_USERS = "SET_FILTERED_USERS";

export const GET_ALL_SMS_TEMPLATE = "GET_ALL_SMS_TEMPLATE";
export const GET_SMS_TEMPLATE = "GET_SMS_TEMPLATE";
export const SAVE_SMS_TEMPLATE = "SAVE_SMS_TEMPLATE";
export const SEND_SMS = "SEND_SMS";
export const SEND_BULK_SMS = "SEND_BULK_SMS";
export const GET_SMS_LOGS = "GET_SMS_LOGS";
export const GET_SMS_LOGS_CAMPAIGNS = "GET_SMS_LOGS_CAMPAIGNS";

export const TOGGLE_EDIT_SMS = "TOGGLE_EDIT_SMS";
export const TOGGLE_SEND_SMS = "TOGGLE_SEND_SMS";
export const TOGGLE_SMS_LOGS = "TOGGLE_SMS_LOGS";
export const TOGGLE_ADHOC_SMS = "TOGGLE_ADHOC_SMS";

export const ADMIN_UPLOAD = "ADMIN_UPLOAD";

export const CLEAN_UP = "CLEAN_UP";

export const EMPTY_USER_DETAILS = {
  firstName: "",
  lastName: "",
  mobileNumber: "",
  user: { role: "" },
  email: "",
  address: "",
};

export const ACCESS_LEVELS = [
  // { code: STUDE, value: "Student"},
  // { code: ADMIN, value: "GM" },
  { code: MGR, value: "Manager" },
  { code: MSSPC, value: "Programme Coordinator" },
  { code: MSSCC, value: "MSS Curriculum Coordinator" },
  { code: MWCO, value: "Medical and Welfare Coordinator" },
  { code: ACCEX, value: "Accounts Executive" },
  { code: DSTIN, value: "District Incharge" },
  { code: SELCOMT, value: "Selection Committee" },
];

export const QUOTES = [
  {
    author: " - Jim Morrison",
    quote: "A friend is someone who gives you total freedom to be yourself.",
  },
  {
    author: " - Bhagawad Gita",
    quote:
      "A gift is pure when it is given from the heart to the right person at the right time and at the right place, and when we expect nothing in return.",
  },
  {
    author: " - A.P.J. Abdul Kalam",
    quote:
      "All of us do not have equal talent. But, all of us have an equal opportunity to develop our talents.",
  },
  {
    author: " - Helen Keller",
    quote: "Alone we can do so little together we can do so much.",
  },
  {
    author: " - Oscar Wilde",
    quote: "Always forgive your enemies, nothing annoys them so much.",
  },
  {
    author: " - Benjamin Franklin",
    quote: "An investment in knowledge pays the best interest.",
  },
  {
    author: " - Audrey Hepburn",
    quote:
      "As you grow older, you will discover that you have two hands, one for helping yourself, the other for helping others.",
  },
  {
    author: " - Mahatma Gandhi",
    quote: "Be the change you wish to see in the world.",
  },
  {
    author: " - Bhagawad Gita",
    quote:
      "Blessed is the human birth; even the dwellers in heaven desire this birth; for true knowledge and pure love may be attained only by a human being.",
  },
  {
    author: " - Bhagawad Gita",
    quote:
      "Calmness, gentleness, silence, self-restraint, and purity: these are the disciplines of the mind.",
  },
  {
    author: " - Joshua J. Marine",
    quote:
      "Challenges are what make life interesting and overcoming them is what makes life meaningful.",
  },
  {
    author: " - Leo Buscaglia",
    quote: "Change is the end result of all true learning.",
  },
  {
    author: " - Ernest Dimnet",
    quote:
      "Children have to be educated, but they have also to be left to educate themselves.",
  },
  {
    author: " - A.P.J. Abdul Kalam",
    quote:
      "Climbing to the top demands strength, whether it is to the top of Mount Everest or to the top of your career.",
  },
  {
    author: " - Steve Jobs",
    quote:
      "Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn’t really do it, the just saw something. It seemed obvious to them after a while.",
  },
  {
    author: " - Oprah Winfrey",
    quote:
      "Doing the best at this moment puts you in the best place for the next moment.",
  },
  {
    author: "",
    quote:
      "Don’t compare yourself with others, if you do, you are just insulting yourself.",
  },
  {
    author: " - A.P.J. Abdul Kalam",
    quote:
      "Don’t read success stories, you will only get message. Read failure stories, you will  get some ideas to get success.",
  },
  {
    author: " - A.P.J. Abdul Kalam",
    quote:
      "Don’t take rest after your first victory because if you fail in second, more lips are waiting to say that your first victory was just luck.",
  },
  {
    author: " - Aristotle",
    quote:
      "Educating the mind without educating the heart is no education at all.",
  },
  {
    author: "",
    quote:
      "Education breeds confidence, confidence breeds hope, hope breeds peace.",
  },
  {
    author: " - George Weah",
    quote:
      "Education is a continual process, it’s like a bicycle… If you don’t pedal you don’t go forward.",
  },
  {
    author: " - Marian Wright Edelman",
    quote:
      "Education is for improving the lives of others and for leaving your community and world better than you found it.",
  },
  {
    author: " - Robert Frost",
    quote:
      "Education is the ability to listen to almost anything without losing your temper or your self-confidence.",
  },
  {
    author: "",
    quote:
      "Education is the passport to the future, for tomorrow belongs to those who prepare for it today.",
  },
  {
    author: " - Albert Einstein",
    quote:
      "Education is what remains after one has forgotten what one has learned in school.",
  },
  {
    author: " - W. E. B. Du Bois",
    quote: "Education must not simply teach work – it must teach Life.",
  },
  {
    author: " - C.S. Lewis",
    quote:
      "Education without values, as useful as it is, seems rather to make man a more clever devil.",
  },
  {
    author: " - Ralph W. Emerson",
    quote: "Every artist was at first an amateur.",
  },
  {
    author: " - Pablo Picasso",
    quote:
      "Every child is an artist, the problem is staying an artist when you grow up.",
  },
  {
    author: " - Mary Kay Ash",
    quote:
      "Everyone has an invisible sign hanging from their neck saying, MAKE ME FEEL IMPORTANT. Never forget this message when working with people.",
  },
  {
    author: " - Swami Vivekananda",
    quote:
      "Experience is the only teacher we have . We may talk and reason all our lives, but we shall not understand a word of truth until we experience it ourselves.",
  },
  {
    author: " - Eleanor Roosevelt",
    quote:
      "Great minds discuss ideas, average minds discuss events, small minds discuss people.",
  },
  {
    author: " - Anne Frank",
    quote:
      "How wonderful it is that nobody need wait a single moment to improve the world.",
  },
  {
    author: " - Mark Twain",
    quote: "I have never let my schooling interfere with my education.",
  },
  {
    author: " - Rabindranath Tagore",
    quote:
      "I slept and dreamt that life was joy. I awoke and saw that life was service. I acted and behold, service was joy.",
  },
  {
    author: " - Mother Teresa",
    quote: "If you cannot feed a hundred people, then feed just one.",
  },
  {
    author: " - Martin Luther King Jr.",
    quote: "Intelligence plus character-that is the goal of true education.",
  },
  {
    author: " - Agnes Repplierg",
    quote:
      "It is as impossible to withhold education from the receptive mind as it is impossible to force it upon the unreasoning.",
  },
  {
    author: " - Bhagawad Gita",
    quote:
      "It is better to live your own destiny imperfectly than to live an imitation of somebody else’s life with perfection.",
  },
  {
    author: " - Dalai Lama",
    quote: "It is not enough to be compassionate. You must act.",
  },
  {
    author: " - Anna Taylor",
    quote:
      "It is not your job to save everyone. Some people are not even ready to be helped. Focus on being of service to those who are, and be wise and humble enough to know when the best service you can offer is to guide them toward help in another direction.",
  },
  {
    author: " - J. K Rowling",
    quote:
      "It is our choices, that show what we truly are, far more than our abilities.",
  },
  {
    author: " - Claire Fagin",
    quote: "Knowledge will bring you the opportunity to make a difference.",
  },
  {
    author: "",
    quote:
      "Learn everything that is good from others, but bring it in, and in your own way absorb it do not become others.",
  },
  {
    author: " - John Holt",
    quote:
      "Learning is not the product of teaching. Learning is the product of the activity of learners.",
  },
  {
    author: " - John Hersey",
    quote:
      "Learning starts with failure the first failure is the beginning of education.",
  },
  {
    author: " - Albert Schweitzer",
    quote:
      "Life becomes harder for us when we live for others, but it also becomes richer and happier.",
  },
  {
    author: "",
    quote:
      "Life is not a problem to be solved, but a reality to be experienced.",
  },
  {
    author: " - Ray Bradbury",
    quote: "Life is trying things to see if they work.",
  },
  {
    author: " - George Bernard Shaw",
    quote:
      "Life isn’t about finding yourself. Life is about creating yourself.",
  },
  {
    author: " - Martin Luther King, Jr.",
    quote: "Most urgent question in life is: What are you doing for others?",
  },
  {
    author: " - Mahatma Gandhi",
    quote:
      "Live as if you were to die tomorrow. Learn as if you were to live forever.",
  },
  {
    author: " - Thomas A. Edison",
    quote:
      "Many of life’s failures are people who did not realize how close they were to success when they gave up.",
  },
  {
    author: "",
    quote: "Never look down on someone unless you are helping them up.",
  },
  {
    author: "",
    quote: "Never stop learning, because life never stops teaching.",
  },
  {
    author: " - Mother Teresa",
    quote:
      "Never worry about numbers. Help one person at a time and always start with the person nearest you.",
  },
  {
    author: " - Anatole France",
    quote: "Nine-tenths of education is encouragement.",
  },
  {
    author: " - A.P.J. Abdul Kalam",
    quote:
      "No matter what is the environment around you, it is always possible to maintain brand of integrity.",
  },
  {
    author: " - Mother Teresa",
    quote:
      "Not all of us can do great things, but we can do small things with great love.",
  },
  {
    author: " - A.P.J. Abdul Kalam",
    quote:
      "One best book is equal to hundred good friends, but one good friend is equal to a library.",
  },
  { author: "", quote: "Only a life lived for others is a life worthwhile." },
  {
    author: " - Helen Keller",
    quote:
      "Optimism is the faith that leads to achievement nothing can be done without hope and confidence. ",
  },
  {
    author: "",
    quote: "Social Work is the Art of Listening and the Science of Hope.",
  },
  { author: "", quote: "Speak up for those who cannot speak for themselves." },
  {
    author: " - Albert Einstein",
    quote: "Strive not to be a success, but rather to be of value.",
  },
  {
    author: "",
    quote:
      "Strong people stand up for themselves. But the strongest people stand up for others.",
  },
  {
    author: " - George S. Patton",
    quote: "Success is how high you bounce when you hit bottom.",
  },
  {
    author: " - Swami Vivekananda",
    quote:
      "Talk to yourself once in a day.. otherwise you may miss meeting an excellent person in this world.",
  },
  {
    author: "",
    quote:
      "The beautiful thing about learning is that no one can take it away from you.",
  },
  {
    author: " - Mahatma Gandhi",
    quote:
      "The best way to find yourself is to lose yourself in the service of others.",
  },
  {
    author: " - Abraham Lincoln",
    quote: "The Best way to predict your future is to create it. ",
  },
  {
    author: "",
    quote:
      "The goal of education is the advancement of knowledge and dissemination of truth.",
  },
  {
    author: " - Gordon B. Hinckley",
    quote:
      "The happiest people I know are those who lose themselves in the service of others.",
  },
  {
    author: " - Bhagawad Gita",
    quote:
      "The ignorant work for their own profit, the wise work for the welfare of the world, without thought for themselves.",
  },
  {
    author: "",
    quote: "The journey of a thousand miles begins with one step.",
  },
  {
    author: " - A.P.J. Abdul Kalam",
    quote:
      "The purpose of education is to make good human beings with skill and expertise. Enlightened human being can be created by teachers.",
  },
  {
    author: "",
    quote:
      "The purpose of education is to replace empty mind with an open one.",
  },
  {
    author: " - Ayn Rand",
    quote:
      "The question isn’t who is going to let me it’s who is going to stop me.",
  },
  {
    author: "",
    quote: "The secret in education lies in respecting the student.",
  },
  {
    author: " - Bruce Lee",
    quote: "The successful warrior is the average man, with laser-like focus.",
  },
  {
    author: " - Mary Rose Mcgeady",
    quote:
      "There is no greater joy nor greater reward than to make a fundamental difference in someones life.",
  },
  {
    author: " - Malala Yousafzai",
    quote:
      "They cannot stop me. I will get my education, if it is in the home, school, or anyplace.",
  },
  {
    author: " - Mark Twain",
    quote:
      "Twenty years from now you will be more disappointed by the things that you didn’t do than by the ones you did do.",
  },
  {
    author: " - Dr. Seuss",
    quote:
      "Unless someone like you cares a whole awful lot, nothing is going to get better. It is not!",
  },
  {
    author: " - Ben Sweetland",
    quote:
      "We cannot hold a torch to light others path without brightening our own.",
  },
  {
    author: " - Helen Keller",
    quote:
      "When one door of happiness closes, another opens but often we look so long at the closed door that we do not see the one which has been opened for us.",
  },
  {
    author: " - Anton Chekhov",
    quote: "Wisdom…. comes not from age, but from education and learning.",
  },
  {
    author: " - Bil Keane",
    quote:
      "Yesterday is history, tomorrow is a mystery, today is a gift of God, which is why we call it the present.",
  },
  { author: "", quote: "You are the creator of your own destiny." },
  {
    author: "",
    quote: "You don’t have to be great to start, you must start to be great.",
  },
  {
    author: " - A.P.J. Abdul Kalam",
    quote: "You have to dream before your dreams can come true.",
  },
  {
    author: " - Ray LeBlond",
    quote: "You learn something every day if you pay attention.",
  },
  {
    author: " - Mahatma Gandhi",
    quote: "You must be the change you wish to see in the world.",
  },
  {
    author: " - Rosa Parks",
    quote:
      "You must never be fearful about what you are doing when it is right.",
  },
  {
    author: " - Mahatma Gandhi",
    quote:
      "You must not lose faith in humanity. Humanity is an ocean, if a few drops of the ocean are dirty, the ocean does not become dirty.",
  },
  {
    author: "",
    quote: "You only live once, but if you do it right, once is enough",
  },
  {
    author: " - Maya Angelou",
    quote:
      "You will face many defeats in life, but never let yourself be defeated.",
  },
  {
    author: " - Jason Mraz",
    quote:
      "You’re not obligated to win. You’re obligated to keep trying. To the best you can do everyday.",
  },
  {
    author: " - A.P.J. Abdul Kalam",
    quote: "Your best teacher is your last mistake.",
  },
];

export const SMS_TEMPLATE_TYPES = [
  "STUD_REG",
  "STUD_FGT",
  "STAFF_REG",
  "STAFF_FGT",
  "APPL_3_DAYS",
  "APPL_1_DAYS",
  "DOWN_HALL",
  "REJC_PPL",
  "EXAM_3_DAYS_APPRV",
  "EXAM_1_DAYS_APPRV",
  "EXAM_1_DAYS_APPRV_HEAD",
  "EXAM_3_DAYS",
  "EXAM_1_DAYS",
  "EXAM_1_DAYS_HEAD",
  "CENTER_CHANGE",
  "CENTER_CHANGE_HEAD",
  "SHORT_APPL",
  "SHORT_HEAD",
  "NOT_SHORT_APPL",
  "ADD_SCHL",
  "ADD_SCHL_APPRV",
  "ADD_SCHL_REJC",
  "STUD_ACCT",
];

export const STATUS_LIST_ADMIN = [
  "INCOMP",
  "NEW",
  "VERIF",
  "ACCPT",
  "WAIT",
  "REJECD",
  "REJECC",
  "APPRV",
  "DOWN",
  "ATTMPT",
  "SMSS",
  "ENROL",
];

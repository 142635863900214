import React from "react";
import { Button, Col, Form, Input, Modal, Row, Select, Spin } from "antd";
import { HOST, INST } from "../utils/const";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class AddHostel extends React.Component {
 
  saveInstitute = (form) => {
    this.props.saveMasterTableData(HOST, form);
  };
  render() {
    const record = this.props.record;
    const fieldData = [];
    const initValues = this.props.record;
    const text = record.hostelId ? "Edit Hostel" : "Add Hostel";
    return (
      <div id="add-inst-modal">
        <Modal
          title={text}
          visible={this.props.masterTable.showModal === HOST}
          footer={null}
          onCancel={() => this.props.toogleAddModal("")}
          width="80%"
        >
          <Spin spinning={this.props.masterTable.loading} delay={500}>
            <Form
              name="institute"
              className="login-form login-wrapper"
              onFinish={this.saveInstitute}
              // fields={fieldData}
              initialValues={initValues}
            >
              <Form.Item {...formItemLayout} name="hostelId" hidden={true}>
                <Input />
              </Form.Item>

              <Row gutter={[16, 8]}>
              <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="hostelCode"
                    label="Hostel Code"
                    rules={[
                      {
                        pattern: /^\S/,
                        message: "Please enter a valid Hostel Code",
                      },
                    ]}
                  >
                    <Input maxLength="6" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="hostelName"
                    label="Hostel Name"
                    rules={[
                      {
                        required: true,
                        pattern: /^\S/,
                        message: "Please enter Hostel Name",
                      },
                    ]}
                  >
                    <Input maxLength="500" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="gender"
                    label="Students Gender"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Students Gender",
                      },
                    ]}
                  >
                    <Select className="full-width wrapped_select">
                      <Select.Option key="M" value="M">
                        Male
                      </Select.Option>
                      <Select.Option key="F" value="F">
                        Female
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name= "location"
                    label="Location"
                    rules={[
                        {
                          required: true,
                          pattern: /^\S/,
                          message: "Please enter Hostel Location",
                        },
                      ]}
                  >
                    <Input maxLength="200" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="owner"
                    label="Owner Name"
                    rules={[
                        {
                          required: true,
                          pattern: /^\S[A-Za-z\s]+$/,
                          message: "Please enter Owner Name",
                        },
                      ]}
                  >
                    <Input maxLength="50" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="phone1"
                    label="Phone 1"
                    rules={[
                      {
                        required: true,
                        pattern: /^\d{10}$/,
                        message: "Please enter a valid Phone Number",
                      },
                    ]}
                  >
                    <Input maxLength="10" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="phone2"
                    label="Phone 2"
                    rules={[
                      {
                        pattern: /^\d{10}$/,
                        message: "Please enter a valid Phone Number",
                      },
                    ]}
                  >
                    <Input maxLength="10" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="address"
                    label="Address"
                    rules={[
                        {
                          required: true,
                          min: 10,
                          message: "Please enter a valid Address",
                        },
                      ]}
                  >
                    <Input.TextArea maxLength="1000" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="panNumber"
                    label="PAN Number"
                    rules={[
                        {
                          // required: true,
                          min:10,
                          message: "Please enter a valid PAN Number",
                        },
                      ]}
                  >
                    <Input maxLength="10" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="bankName"
                    label="Bank Name"
                    rules={[
                        {
                          required: true,
                          pattern: /^\S/,
                          message: "Please enter a valid Bank Name",
                        },
                      ]}
                  >
                    <Input maxLength="200" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="bankBranch"
                    label="Bank Branch"
                    rules={[
                        {
                          required: true,
                          pattern: /^\S/,
                          message: "Please enter a valid Bank Branch",
                        },
                      ]}
                  >
                    <Input maxLength="200" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="bankAccountNumber"
                    label="Bank Account Number"
                    rules={[
                        {
                          required: true,
                          pattern: /^\d*$/,
                          message: "Please enter a valid Bank Account Number",
                        },
                      ]}
                  >
                    <Input maxLength="18" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="ifscCode"
                    label="Bank IFSC Code"
                    rules={[
                        {
                          required: true,
                          len: 11,
                          message: "Please enter a valid IFSC Code",
                        },
                      ]}
                  >
                    <Input maxLength="11" />
                  </Form.Item>
                </Col>
              </Row>

              <div className="modal-form-button-div">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="modal-form-submit-button"
                  disabled={this.props.masterTable.loading}
                >
                  Save
                </Button>
              </div>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default AddHostel;

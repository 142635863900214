import React from "react";
import { Button, Modal, Space, Spin, Table, Tooltip } from "antd";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { SUB } from "../utils/const";
import AddSubject from "./AddSubject";
import Search from "antd/lib/input/Search";
import { exportFile } from "../../common/utils/export";

class Subject extends React.Component {
  state = {
    subject: {},
    searchText: ''
  };
  componentDidMount() {
    this.props.fetchMasterTableData(SUB);
  }

  handleAdd = () => {
    this.setState({ subject: {} });
    this.props.toogleAddModal(SUB);
  };

  edit = (record, e) => {
    e.preventDefault();
    this.setState({ subject: record });
    this.props.toogleAddModal(SUB);
  };

  delete = (record, e) => {
    e.preventDefault();
    const that = this;
    Modal.confirm({
      title: (<div>Do you want to delete this Subject?
        <p>Note: It can only be deleted if there are no students mapped to this Subject.</p>
      </div>),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        that.props.deleteMasterTableData(SUB, record.subjectId);
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  offlineSearch = (val) => {
    const cols = ['name', 'notes'];
    this.props.offlineSearch(val, this.props.masterTable.subjects, cols, SUB);
    this.setState({ searchText: val });
  }

  exportData = (cols) => {
    exportFile(cols, this.props.masterTable.searchSubj, "Subjects");
  };
  
  render() {
    const SUB_LIST_COLS = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => record.name,
        sorter: {
          compare: (a, b) => sortNames(a.name, b.name),
        },
      },
      {
        title: "Description",
        dataIndex: "notes",
        key: "notes",
        render: (text, record) => record.notes,
        sorter: {
          compare: (a, b) => sortNames(a.notes, b.notes),
        },
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <Tooltip title="Edit">
              <a onClick={(e) => this.edit(record, e)}>
                <EditOutlined />
              </a>
            </Tooltip>
            <Tooltip title="Delete">
              <a onClick={(e) => this.delete(record, e)}>
                <DeleteOutlined />
              </a>
            </Tooltip>
          </Space>
        ),
      },
    ];
    return (
      <div id="subjects-container">
        <h2>
          Subjects
          <Button
            className="add-cat-button"
            onClick={this.handleAdd}
            type="primary"
            style={{ float: "right" }}
            disabled={this.props.masterTable.loading}
          >
            Add Subject
          </Button>
          <Button
            className="add-cat-button"
            onClick={(e) => this.exportData(SUB_LIST_COLS)}
            style={{ float: "right", marginRight: "10px" }}
            disabled={this.props.masterTable.loading}
          >
            Export Excel
          </Button>
        </h2>
        <Spin spinning={this.props.masterTable.loading} delay={500}>
          <div>
            <Search
              placeholder="Search"
              value={this.state.searchText}
              onChange={(e) => this.offlineSearch(e.target.value)}
              onSearch={(value) => this.offlineSearch(value)}
              enterButton
              style={{ marginBottom: "10px" }}
            />
            <Table
              columns={SUB_LIST_COLS}
              dataSource={this.props.masterTable.searchSubj}
              pagination={{
                showSizeChanger: true,
                showTotal: () => {
                  const total = this.props.masterTable.searchSubj.length;
                  return `Total ${total}`;
                },
              }}
            />
          </div>
        </Spin>
        {this.props.masterTable.showModal === SUB && (
          <AddSubject record={this.state.subject} {...this.props} />
        )}
      </div>
    );
  }
}

export default Subject;

import {
  Button,
  Card,
  DatePicker,
  notification,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import React from "react";
import { HOST, INST } from "../../mastertables/utils/const";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import { JOIN_STATUS } from "../utils/const";
import moment from "moment";

const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
class Joining extends React.Component {
  state = { selectedRowKeys: [], year: new Date().getFullYear() };

  constructor(props) {
    super(props);
    props.fetchMasterTableData(INST);
    props.fetchMasterTableData(HOST);
  }

  componentDidMount() {
    this.searchList();
  }

  searchList = () => {
    if(!this.state.year) {
      this.props.fetchAllEnrollmentDetails(new Date().getFullYear());
    } else {
      this.props.fetchAllEnrollmentDetails(this.state.year);
    }
  };

  updateData = (val, record, colName) => {
    const enrollDetails = this.props.manage.enrollDetails;
    const { institutes } = this.props.masterTable;
    for (const i of enrollDetails) {
      if (i.applicationId === record.applicationId) {
        i[colName] = val;
        if (colName === "instituteId") {
          let inst = null;
          for (const j of institutes) {
            if (j.instituteId === val) {
              inst = j;
            }
          }
          i.instituteCourses = inst.instituteCourses;
          i.courseId = null;
        }

        if (colName === "enrollStatus") {
          i.instituteId = null;
          i.courseId = null;
          i.hostelId = null;
          i.joiningDate = null;
        }
      }
    }

    this.props.setEnrollData(enrollDetails);
  };

  onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  updateSelection = () => {
    const enrollDetails = this.props.manage.enrollDetails;
    const selKeys = this.state.selectedRowKeys;
    const data = enrollDetails.filter((appl) =>
      selKeys.includes(appl.applicationId)
    );
    console.log("-------selection committee--------", data);
    if (data.length > 0) {
      this.validateRecords(data);
    } else {
      notification.error({
        message: "Error",
        description: "Select atleast one record to save.",
      });
    }
  };

  validateRecords = (data) => {
    const invalidData = [];
    for (const i of data) {
      if (
        i.enrollStatus !== 12 &&
        (!i.instituteId ||
          !i.hostelId ||
          !i.courseId ||
          !i.enrollStatus ||
          !i.joiningDate)
      ) {
        invalidData.push(i.studentName + " " + i.studentSurName);
      }
    }
    if (invalidData.length > 0) {
      notification.error({
        message: "Select all the required fields of below students",
        description: invalidData.join(", "),
      });
    } else {
      console.log("----------- final data :", data);
      this.props.saveEnrollmentDetails(data, this.state.year);
      this.setState({ selectedRowKeys: [] });
    }
  };

  showErrorNotifications = () => {
    let message = "";

    if (this.props.manage.errorMessage) {
      message += this.props.manage.errorMessage;
    }

    if (message) {
      notification.error({
        message: "Error",
        description: message,
      });
      this.props.resetMessage();
    }
  };

  showNotifications = () => {
    let message = "";

    if (this.props.manage.successMessage) {
      message += this.props.manage.successMessage;
    }

    if (message) {
      notification.success({
        message: "Success",
        description: message,
      });
      this.props.resetMessage();
    }
  };

  getYear = () => {
    const year = new Date().getFullYear();
    const opts = [];
    for (let i = 2010; i <= year; i++) {
      opts.push(<Option value={i}>{i}</Option>);
    }

    return opts;
  };

  render() {
    const { institutes, hostels } = this.props.masterTable;
    const ENROLL_LIST_COLS = [
      {
        title: "Student Name",
        dataIndex: "studentName",
        key: "studentName",
        render: (text, record) =>
          record.studentName + " " + record.studentSurName,
        sorter: {
          compare: (a, b) =>
            sortNames(
              a.studentName + " " + a.studentSurName,
              b.studentName + " " + b.studentSurName
            ),
        },
        width: "250px",
        defaultSortOrder: "ascend",
      },
      {
        title: "District",
        dataIndex: "districtName",
        key: "districtName",
        render: (text, record) => record.districtName,
        sorter: {
          compare: (a, b) => sortNames(a.districtName, b.districtName),
        },
      },
      {
        title: "Status",
        dataIndex: "enrollStatus",
        key: "enrollStatus",
        render: (text, record) => (
          <Select
            className="full-width"
            onChange={(e) => this.updateData(e, record, "enrollStatus")}
            value={
              record.enrollStatus ? record.enrollStatus : record.applStatus
            }
          >
            {JOIN_STATUS.map((status) => (
              <Option key={status.key} value={status.key}>
                {status.label}
              </Option>
            ))}
          </Select>
        ),
        sorter: {
          compare: (a, b) => sortNames(a.enrollStatus, b.enrollStatus),
        },
        width: "150px",
      },
      {
        title: "College",
        dataIndex: "instituteName",
        key: "instituteName",
        render: (text, record) => (
          <Select
            className="full-width wrapped_select"
            showSearch
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            onChange={(e) => this.updateData(e, record, "instituteId")}
            value={record.instituteId}
            disabled={!record.enrollStatus || record.enrollStatus === 12}
          >
            {institutes
              .sort((a, b) => sortNames(a.name, b.name))
              .map((inst) => (
                <Option key={inst.instituteId} value={inst.instituteId}>
                  {inst.name}
                </Option>
              ))}
          </Select>
        ),
        // sorter: {
        //   compare: (a, b) => sortNames(a.name, b.name),
        // },
        width: "250px",
      },
      {
        title: "Course",
        dataIndex: "courseName",
        key: "courseName",
        render: (text, record) => {
          let courses = record.instituteCourses;
          if (
            record.courseId &&
            (!courses || courses.length === 0) &&
            institutes.length > 0
          ) {
            let inst = null;
            for (const i of institutes) {
              if (i.instituteId === record.instituteId) {
                inst = i;
              }
            }
            courses = inst.instituteCourses;
          }
          return (
            <Select
              className="full-width wrapped_select"
              showSearch
              filterOption={(input, option) =>
                option.children.toUpperCase().includes(input.toUpperCase())
              }
              onChange={(e) => this.updateData(e, record, "courseId")}
              value={record.courseId}
              disabled={!record.enrollStatus || record.enrollStatus === 12}
            >
              {courses
                ?.sort((a, b) => sortNames(a.course.courseName, b.course.courseName))
                .map((icourse) => (
                  <Option
                    key={icourse.course.courseId}
                    value={icourse.course.courseId}
                  >
                    {icourse.course.courseName}
                  </Option>
                ))}
            </Select>
          );
        },
        // sorter: {
        //   compare: (a, b) => sortNames(a.courseName, b.courseName),
        // },
        width: "250px",
      },
      {
        title: "Hostel",
        dataIndex: "hostelName",
        key: "hostelName",
        render: (text, record) => (
          <Select
            className="full-width wrapped_select"
            showSearch
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            onChange={(e) => this.updateData(e, record, "hostelId")}
            value={record.hostelId}
            disabled={!record.enrollStatus || record.enrollStatus === 12}
          >
            {hostels
              .sort((a, b) => sortNames(a.hostelName, b.hostelName))
              .map((hostel) => (
                <Option key={hostel.hostelId} value={hostel.hostelId}>
                  {hostel.hostelName}
                </Option>
              ))}
          </Select>
        ),
        // sorter: {
        //   compare: (a, b) => sortNames(a.hostelName, b.hostelName),
        // },
        width: "250px",
      },
      {
        title: "Joining Date",
        dataIndex: "joiningDate",
        key: "joiningDate",
        render: (text, record) => (
          <DatePicker
            format={dateFormat}
            className="full-width"
            showToday={false}
            value={record.joiningDate ? moment(record.joiningDate) : ""}
            disabled={!record.enrollStatus || record.enrollStatus === 12}
            onChange={(e) => this.updateData(e, record, "joiningDate")}
          />
        ),
        // sorter: {
        //   compare: (a, b) => moment(a.joiningDate).unix() - moment(b.joiningDate).unix,
        // },
        width: "200px",
      },
    ];
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div id="enrollment-container">
        <Card>
          <h2>
            Joining
            <div style={{ float: "right" }}>
              <Space>
              <label for="year" style={{fontSize: "15px"}}>Year : </label>
              <Select id="year" style={{width: "100px"}}
                onChange={(e) => this.setState({ year: e })}
                value={this.state.year}
              >
                {this.getYear()}
              </Select>
              <Button
                onClick={e => this.searchList(e)}
                disabled={this.props.manage.loading}
              >
                Search
              </Button>
              <Button
                onClick={this.updateSelection}
                type="primary"
                disabled={this.props.manage.loading}
              >
                Save
              </Button>
              </Space>
            </div>
          </h2>
          <Spin
            spinning={
              this.props.manage.loading ||
              this.props.masterTable.loading ||
              this.props.masterTable.instLoading ||
              this.props.masterTable.hostLoading
            }
            delay={500}
          >
            <div>
              <Table
                columns={ENROLL_LIST_COLS}
                dataSource={this.props.manage.enrollDetails}
                rowSelection={rowSelection}
                rowKey={(record) => record.applicationId}
                pagination={{ showSizeChanger: true }}
              />
            </div>
          </Spin>
        </Card>
        {this.props.manage.successMessage && this.showNotifications()}
        {this.props.manage.errorMessage && this.showErrorNotifications()}
      </div>
    );
  }
}

export default Joining;

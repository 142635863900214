import { connect } from "react-redux";
import MasterTables from "../components/MasterTables";
import {
  deleteCourses,
  deleteEducationCategory,
  deleteMasterTableData,
  fetchCourses,
  fetchCoursesByCatId,
  fetchEducationCategories,
  fetchEducationTypes,
  fetchMasterTableData,
  fetchMasterTableRecord,
  saveCourse,
  saveEducationCategory,
  saveMasterTableData,
} from "../actions/masterTables";
import {
  SAVE_MASTER_TABLE,
  SEARCH_MASTER_TABLE,
  SET_CURRENT_REC,
  TOGGLE_ADD_CATEGORY,
  TOGGLE_ADD_COURSE,
  TOGGLE_ADD_MODAL,
} from "../utils/const";
import { withRouter } from "react-router";
import { RESET_MESSAGES } from "../../admin/utils/consts";

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchEducationTypes() {
      dispatch(fetchEducationTypes());
    },
    fetchEducationCategories() {
      dispatch(fetchEducationCategories());
    },
    saveEducationCategory(categoryForm) {
      dispatch(saveEducationCategory(categoryForm)).then((res) => {
        this.fetchEducationCategories();
      });
    },
    deleteEducationCategory(id) {
      dispatch(deleteEducationCategory(id)).then((res) => {
        if(res.action.type.indexOf(`_FULFILLED`) > -1) {
          this.fetchEducationCategories();
        }
      });
    },
    toggleAddCatModal(flag) {
      dispatch({
        type: TOGGLE_ADD_CATEGORY,
        payload: flag,
      });
    },
    toggleAddCourseModal(flag) {
      dispatch({
        type: TOGGLE_ADD_COURSE,
        payload: flag,
      });
    },
    fetchCourses() {
      dispatch(fetchCourses());
    },
    fetchCoursesByCatId(id) {
      dispatch(fetchCoursesByCatId(id));
    },
    saveCourse(courseForm) {
      dispatch(saveCourse(courseForm)).then((res) => {
        this.fetchCourses();
      });
    },
    deleteCourses(id) {
      dispatch(deleteCourses(id)).then((res) => {
        this.fetchCourses();
      });
    },
    fetchMasterTableData(dataType) {
      dispatch(fetchMasterTableData(dataType));
    },
    fetchMasterTableRecord(dataType, id) {
      dispatch(fetchMasterTableRecord(dataType, id));
    },
    saveMasterTableData(dataType, form) {
      dispatch(saveMasterTableData(dataType, form)).then((res) => {
        if(res.action.type === `${SAVE_MASTER_TABLE}_FULFILLED`) {
          this.fetchMasterTableData(dataType);
        }
      });
    },
    deleteMasterTableData(dataType, id) {
      dispatch(deleteMasterTableData(dataType, id)).then((res) => {
        if(res.action.type.indexOf(`_FULFILLED`) > -1) {
          this.fetchMasterTableData(dataType);
        }
      });
    },
    offlineSearch(searchText, data, columns, dataType) {
      if (searchText && searchText.length > 1) {
        const result = data.filter((row) => {
          searchText = searchText.toUpperCase();
          let text = "";
          for(const i of columns) {
            text += row[i] + ' ';
          }
          return text.toUpperCase().indexOf(searchText) > -1;
          // return examCenterPredicate(examCenter, searchText);
        });
        dispatch({
          type: SEARCH_MASTER_TABLE,
          payload: result,
          meta: dataType,
        });
      } else if (!searchText) {
        dispatch({
          type: SEARCH_MASTER_TABLE,
          payload: data,
          meta: dataType,
        });
      }
    },
    toogleAddModal(modal) {
      dispatch({
        type: TOGGLE_ADD_MODAL,
        payload: modal,
      });
    },
    setCurrentRecord(rec) {
      dispatch({
        type: SET_CURRENT_REC,
        payload: rec,
      });
    },
    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, AdminReducer, MasterTablesReducer, AppConfigReducer } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    masterTable: MasterTablesReducer,
    appConfigSuccess: AppConfigReducer.successMessage,
    appConfigError: AppConfigReducer.errorMessage
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MasterTables)
);

import { Card, Col, List, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";

const TaskComments = (props) => {
    const [comments, setComments] = useState([]);

    useEffect(() => {
        if(props.comments) {
            setComments(props.comments);
        }
    }, [props.comments]);
  return (
    <React.Fragment>
      <Card title="Comments" style={{ minHeight: "300px" }}>
        <List
          bordered
          dataSource={comments}
          renderItem={(item) => (
            <List.Item key={item.taskCommentId}>
              <List.Item.Meta
                title={
                  <Space>
                    <span>{item.userDetails?.firstName}</span>
                    <span>{item.userDetails?.lastName}</span>
                  </Space>
                }
                description={item.comment}
              />
              {item.createdAt?.epochSecond ? (
                <div>
                  {moment
                    .unix(
                      item.createdAt.epochSecond
                        ? item.createdAt.epochSecond
                        : item.createdAt
                    )
                    .format("DD-MM-YYYY hh:mm A")}
                </div>
              ) : (
                <div>{moment(item.createdAt).format("DD-MM-YYYY hh:mm A")}</div>
              )}

              {/* <Row>
                <Col sm={20} xs={24}>
                  <Typography.Text mark style={{ marginRight: "10px" }}>
                    {item.userDetails?.firstName}
                    <Space />
                    {item.userDetails?.lastName}
                  </Typography.Text>
                  {item.comment}
                </Col>
                <Col sm={4} xs={24}>
                  {moment(item.createdAt).format("DD-MM-YYYY h:m A")}
                </Col>
              </Row> */}
            </List.Item>
          )}
          style={{ height: "300px", overflow: "auto" }}
        />
      </Card>
    </React.Fragment>
  );
};

export default TaskComments;

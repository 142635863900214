import { LANG_CHANGE } from "../utils/const";

const initialState = {
    lang: sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'en'
};
const HeadReducer = (state = initialState, action) => {
    switch(action.type) {
        case LANG_CHANGE: {
            sessionStorage.setItem('lang', action.payload);
            return {
                ...state,
                lang: action.payload
            };
        }
            
        default: 
            return state;
    }
};

export default HeadReducer;
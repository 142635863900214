import React from "react";
import {
  Card,
  Spin,
  Table,
  Input,
  Modal,
  Button,
  Typography,
  List,
  Form,
  InputNumber,
  Row,
  Col,
  notification,
} from "antd";
import {
  EditFilled,
  DeleteFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  DownloadOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { sortNames } from "../utils/util";
import "../styles/ss.css";
import Text from "antd/lib/typography/Text";
import { EXCEL_ERROR } from "../utils/consts";
import AddExamCenter from "./AddExamCenter";

const { Search } = Input;

class ExamCenterRoom extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDataSource: [],
      searchText: "",
      examCenter: {},
      seatCount: "",
      action: "",
    };
    this.form = React.createRef();
  }

  componentWillMount() {
    this.props.getExamCenterDetails();
  }

  componentDidMount() {
    this.props.getOldDistricts();
  }

  showErrorNotification = (message) => {
    if (EXCEL_ERROR !== message) {
      return;
    }
    const that = this;
    setTimeout(function () {
      that.props.resetMessage();
    }, 3000);
    notification.error({
      message: "Seating process is not performed.",
      description: "Please click on Start Processing button before export.",
    });
  };

  showNotification = (msgType, message) => {
    const that = this;
    setTimeout(function () {
      that.props.resetMessage();
    }, 3000);
    notification[msgType]({
      message: message,
      // description: message,
    });
    // return <Alert message={message} type={msgType} />;
  };

  offlineSearch = (value) => {
    this.props.offlineSearch(value, this.props.sampurnaSiksha.examCentersList);
    this.setState({ searchText: value });
  };

  saveExamCenterDetails = () => {
    const { examCenter } = this.state;
    let isValid = true;
    for(const room of examCenter.rooms) {
      if(!this.isValidCapacity(room.tempCapacity)) {
        isValid = false;
      }
    }
    if(!isValid) {
      return;
    }
    const rooms = examCenter.rooms.map((room) => room.capacity);
    // console.log("-------------", this.state.examCenter);
    const examRoom = {
      examCenterId: examCenter.id,
      roomDetails: JSON.stringify(rooms),
    };
    this.props.saveExamRooms(examRoom);
    // console.log("Room details - ", examRoom);
  };

  updateExamCenterRooms = (center) => {
    center.rooms = [];
    if (center.roomDetails) {
      const rooms = JSON.parse(center.roomDetails);
      for (let i of rooms) {
        center.rooms.push({
          editMode: false,
          capacity: i,
          tempCapacity: i,
        });
      }
    }

    this.setState({ examCenter: center });
    this.props.toggleExamCenterModal(true);
  };

  closeExamCenterModal = () => {
    this.props.toggleExamCenterModal(false);
    this.form.current.resetFields();
  };

  editRoom = (index, room, e) => {
    const { examCenter } = this.state;
    examCenter.rooms[index].editMode = true;
    this.setState({ examCenter });
  };

  isValidCapacity = (tempCapacity) => {
    return !(!tempCapacity ||
    tempCapacity === 0 ||
    !Number.isInteger(tempCapacity) ||
    parseInt(tempCapacity) > 1000);
  }

  saveRoom = (index, room, e) => {
    if (!this.isValidCapacity(room.tempCapacity)) {
      return;
    }
    const { examCenter } = this.state;
    examCenter.rooms[index].capacity = examCenter.rooms[index].tempCapacity;
    examCenter.rooms[index].editMode = false;
    this.setState({ examCenter });
  };

  cancelRoomEdit = (index, room, e) => {
    const { examCenter } = this.state;
    examCenter.rooms[index].editMode = false;
    examCenter.rooms[index].tempCapacity = examCenter.rooms[index].capacity;
    this.setState({ examCenter });
  };

  updateTempCap = (index, room, e) => {
    const { examCenter } = this.state;
    examCenter.rooms[index].tempCapacity = e > 0 ? e : "";
    this.setState({ examCenter });
  };

  deleteRoom = (index) => {
    const { examCenter } = this.state;
    const that = this;
    Modal.confirm({
      title: "Do you want to delete this room?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        examCenter.rooms.splice(index, 1);
        that.setState({ examCenter });
      },
      onCancel() {},
    });
  };

  generate = () => {
    if (
      !this.state.examCenter.rooms ||
      this.state.examCenter.rooms.length === 0
    ) {
      return <Typography>No Rooms added</Typography>;
    }
    return this.state.examCenter.rooms.map((room, index) => {
      const html = [];
      if (!room.editMode) {
        html.push(
          <Button
            // hidden={room.editMode}
            type="primary"
            shape="round"
            icon={<EditFilled />}
            size="small"
            onClick={(e) => this.editRoom(index, room, e)}
            style={{ margin: "10px" }}
          />,
          <Button
            // hidden={room.editMode}
            type="primary"
            shape="round"
            icon={<DeleteFilled />}
            size="small"
            onClick={(e) => this.deleteRoom(index, e)}
            style={{ margin: "10px" }}
          />
        );
      } else {
        html.push(
          <Button
            // hidden={!room.editMode}
            type="primary"
            shape="round"
            icon={<CheckCircleFilled />}
            size="small"
            onClick={(e) => this.saveRoom(index, room, e)}
            style={{ margin: "10px" }}
          />,
          <Button
            // hidden={!room.editMode}
            type="primary"
            shape="round"
            icon={<CloseCircleFilled />}
            size="small"
            onClick={(e) => this.cancelRoomEdit(index, room, e)}
            style={{ margin: "10px" }}
          />
        );
      }
      return (
        <List.Item key={index} actions={html}>
          <div>
            Room {index + 1} -{" "}
            {room.editMode && (
              <span>
                <InputNumber
                  value={room.tempCapacity}
                  onChange={(e) => this.updateTempCap(index, room, e)}
                  style={{ width: "50%" }}
                />
                {(!this.isValidCapacity(room.tempCapacity)) && (
                  <Text type="danger" style={{ display: "block" }}>
                    Please enter a valid room capacity.
                  </Text>
                )}
              </span>
            )}
            {!room.editMode && <span>{room.capacity}</span>}
          </div>
        </List.Item>
      );
    });
  };

  addSeatCount = (form) => {
    const { examCenter } = this.state;
    examCenter.rooms.push({
      editMode: false,
      capacity: form.capacity,
      tempCapacity: form.capacity,
    });
    this.setState({ examCenter });
    this.form.current.resetFields();
  };

  getRoomsCount = (examCenter) => {
    if (examCenter.roomDetails) {
      return JSON.parse(examCenter.roomDetails).length;
    }
    return 0;
  };

  getSeatsCount = (examCenter) => {
    let seatCount = 0;
    if (examCenter.roomDetails) {
      const rooms = JSON.parse(examCenter.roomDetails);
      for (let i of rooms) {
        seatCount += i;
      }
      return seatCount;
    }
    return seatCount;
  };

  getSeatsCountFromForm = (examCenter) => {
    let seatCount = 0;
    if (examCenter.rooms) {
      // const rooms = JSON.parse(examCenter.roomDetails);
      for (let i of examCenter.rooms) {
        seatCount += i.capacity;
      }
      return seatCount;
    }
    return seatCount;
  };

  startAllocatingSeats = () => {
    this.props.processSeatingManagement();
  };

  exportHallTickets = () => {
    this.props.exportHallTickets();
  };

  addExamCenter() {
    this.setState({
      action: "ADD",
      examCenter: {},
    });
    this.props.toggleAddModal(true);
  }

  editExamCenter(center) {
    this.setState({
      action: "EDIT",
      examCenter: center,
    });
    this.props.toggleAddModal(true);
  }

  deleteExamCenter(center) {
    this.setState({
      action: "DEL",
      examCenter: center,
    });
    // if(center.studentCount  > 0) {
      this.props.toggleAddModal(true);
    // } else {
    //   this.props.deleteExamCenter(center.id, -1);
    // }
  }

  showTotal = () => {
    const total = this.props.sampurnaSiksha.filteredExamCentersList.length;
    return `Total ${total}`;
  };

  render() {
    const EXAM_CENTERS_LIST_COLS = [
      {
        title: "Exam Center Name",
        dataIndex: "configVal",
        key: "configVal",
        render: (text, record) => record.configVal,
        sorter: {
          compare: (a, b) => sortNames(a.configVal, b.configVal),
        },
        width: "300px",
      },
      {
        title: "Exam Center Code",
        dataIndex: "info",
        key: "info",
        render: (text, record) => record.info,
        sorter: {
          compare: (a, b) => sortNames(a.info, b.info),
        },
      },
      {
        title: "District",
        dataIndex: "district",
        key: "district",
        render: (text, record) => record.parent.configVal,
        sorter: {
          compare: (a, b) => sortNames(a.parent.configVal, b.parent.configVal),
        },
        defaultSortOrder: "ascend",
      },
      {
        title: "Student Count",
        dataIndex: "studentCount",
        key: "studentCount",
        render: (text, record) => record.studentCount,
        sorter: {
          compare: (a, b) => Number(a.studentCount) - Number(b.studentCount),
        },
      },
      {
        title: "Available Seats",
        dataIndex: "seats",
        key: "seats",
        render: (text, record) => this.getSeatsCount(record),
        sorter: {
          compare: (a, b) => this.getSeatsCount(a) - this.getSeatsCount(b),
        },
      },
      {
        title: "Available Rooms",
        dataIndex: "rooms",
        key: "rooms",
        render: (text, record) => this.getRoomsCount(record),
        sorter: {
          compare: (a, b) => this.getRoomsCount(a) - this.getRoomsCount(b),
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (text, record) => (
          <div>
            <Button
              type="primary"
              shape="round"
              size="small"
              onClick={(e) => this.updateExamCenterRooms(record, e)}
              style={{ margin: "5px" }}
            >
              Rooms
            </Button>
            <Button
              type="primary"
              shape="round"
              size="small"
              onClick={(e) => this.editExamCenter(record, e)}
              style={{ margin: "5px" }}
            >
              Edit
            </Button>
            <Button
              type="primary"
              shape="round"
              size="small"
              onClick={(e) => this.deleteExamCenter(record, e)}
              style={{ margin: "5px" }}
            >
              Delete
            </Button>
          </div>
        ),
      },
    ];

    return (
      <div className="exam-center-container">
        <Card>
          {" "}
          <h1>Exam Centers Management</h1>
        </Card>
        <Card>
          <Row>
            <Col style={{ paddingRight: "10px" }} sm={14}>
              <Search
                placeholder="Search"
                value={this.state.searchText}
                onChange={(e) => this.offlineSearch(e.target.value)}
                onSearch={(value) => this.offlineSearch(value)}
                enterButton
                style={{ marginBottom: "10px" }}
              />
            </Col>
            <Col style={{ paddingRight: "10px" }}>
              <Button
                onClick={(e) => this.startAllocatingSeats()}
                loading={this.props.sampurnaSiksha.isExporting}
              >
                Start Processing
              </Button>
            </Col>
            <Col style={{ paddingRight: "10px" }}>
              <Button
                icon={<DownloadOutlined />}
                onClick={(e) => this.exportHallTickets()}
                loading={this.props.sampurnaSiksha.isExporting}
              >
                Export Full Seating Order
              </Button>
            </Col>
            <Col>
              <Button onClick={(e) => this.addExamCenter()}>Add New</Button>
            </Col>
          </Row>
        </Card>
        <Card>
          <Spin spinning={this.props.sampurnaSiksha.loading} delay={500}>
            <Table
              className="exam_centers"
              columns={EXAM_CENTERS_LIST_COLS}
              dataSource={this.props.sampurnaSiksha.filteredExamCentersList}
              pagination={{ showSizeChanger: true, showTotal: this.showTotal }}
            />
          </Spin>
        </Card>

        <Modal
          title="Add New Room Capacity"
          visible={this.props.sampurnaSiksha.showExamCenterModal}
          onOk={this.saveExamCenterDetails}
          onCancel={this.closeExamCenterModal}
          maskClosable={false}
          footer={[
            <Button key="back" onClick={this.closeExamCenterModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.saveExamCenterDetails}
            >
              Save
            </Button>,
          ]}
        >
          <div>
            <Spin spinning={this.props.sampurnaSiksha.loading} delay={500}>
              <div style={{ margin: "8px" }}>
                <label>Exam Center Name : </label>
                <span>{this.state.examCenter.configVal}</span>
              </div>
              <div style={{ margin: "8px" }}>
                <label>Student Count : </label>
                <span>{this.state.examCenter.studentCount}</span>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <List className="rooms-list">{this.generate()}</List>
              </div>
              <Form
                layout="inline"
                onFinish={this.addSeatCount}
                ref={this.form}
              >
                <Form.Item
                  name="capacity"
                  label="Add new room capacity"
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Please enter the room capacity",
                    // },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value > 0 && Number.isInteger(value) && parseInt(value) <= 1000) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Please enter the room capacity");
                      },
                    }),
                  ]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Add
                  </Button>
                </Form.Item>
              </Form>
              {this.state.examCenter.rooms?.length > 0 &&
                this.state.examCenter.studentCount >
                  this.getSeatsCountFromForm(this.state.examCenter) && (
                  <Text type="warning">
                    Need{" "}
                    {this.state.examCenter.studentCount -
                      this.getSeatsCountFromForm(this.state.examCenter)}{" "}
                    more seats.
                  </Text>
                )}
            </Spin>
          </div>
        </Modal>
        {this.props.sampurnaSiksha.showAddExamCenterModal && (
          <AddExamCenter
            record={this.state.examCenter}
            action={this.state.action}
            {...this.props}
          ></AddExamCenter>
        )}
        {this.props.sampurnaSiksha.successMessage &&
          this.showNotification(
            "success",
            this.props.sampurnaSiksha.successMessage
          )}
        {this.props.sampurnaSiksha.errorMessage &&
          this.showErrorNotification(this.props.sampurnaSiksha.errorMessage)}
      </div>
    );
  }
}

export default ExamCenterRoom;

import { axiosInstance } from "../../Interceptor";
import { GET_NOTIF, READ_NOTIF } from "../utils/const";

export const setReadNotifications = () => {
  return {
    type: READ_NOTIF,
    payload: axiosInstance.get("/notifications/read"),
  };
};

export const getNotifications = () => {
  return {
    type: GET_NOTIF,
    payload: axiosInstance.get("/notifications"),
  };
};

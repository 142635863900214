import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Button, Collapse, Modal } from "antd";
import React from "react";
import { useState } from "react";
import moment from "moment";
import { CRED_PERCENT } from "../../../mastertables/utils/const";
import MarksForm from "./MaksForm";

import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  calculateTotalMarksByAttempt,
  getMarksByAttemptAndSub,
  calculateTotalFinalMarks,
  calculateTotalMaxMarks,
  getStatusClassByAttemptAndSub,
  getResultClassByAttemptAndSub,
  getGradeByAttemptAndSub,
  getFinalGradeBySub,
  calculateTotalFinalGradePoints,
  calculateTotalGradePointsByAttempt,
  getCountOfUploadsByEntityIdAndType,
  filterUploadsByEntityIdAndType,
  getFinalApprovedMarksBySub,
} from "../../utils/utils";
import { ADMIN } from "../../../base/utils/roles";
import { MARKS_MEMO } from "../../utils/const";
import ImageViewer from "../../../sampoorna-siksha/components/reviewSteps/ImageViewer";

const MarksDetails = (props) => {
  const [title, setTitle] = useState("");
  const [currCourseSub, setCurrCourseSub] = useState([]);
  const [curAttempt, setCurAttempt] = useState(1);
  const [curSem, setCurSem] = useState(null);
  const [curCurriculum, setCurCurriculum] = useState({});

  const addAttempt = (courseSubj, maxAttempts, sem, courseCur) => {
    props.toogleAddModal(`EDIT_ATTEMPT_${props.academics.studentAcadId}`);
    props.setCurrentRequest({attach: []});
    setTitle("Add Marks");
    setCurrCourseSub(courseSubj);
    setCurAttempt(maxAttempts ? maxAttempts + 1 : 1);
    setCurSem(sem);
    if (courseCur) {
      setCurCurriculum(courseCur);
    }
  };

  const editAttempt = (courseSubj, maxAttempts, sem, courseCur, uploads) => {
    props.toogleAddModal(`EDIT_ATTEMPT_${props.academics.studentAcadId}`);
    props.setCurrentRequest({attach: uploads});
    setTitle("Edit Marks");
    setCurrCourseSub(courseSubj);
    setCurAttempt(maxAttempts);
    setCurSem(sem);
    if (courseCur) {
      setCurCurriculum(courseCur);
    }
  };

  const delAttempt = (sem, attempt) => {
    // const that = this;
    Modal.confirm({
      title: "Do you want to delete marks?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        props.deleteStudentMarks(
          sem.studentSemId,
          attempt,
          sem.studentProfileId
        );
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  const onCancel = () => {
    props.toogleAddModal("");
    // props.resetUploads();
  };

  const onFinish = (marksList) => {
    props.saveStudentMarks(marksList, props.academics.studentProfileId);
  };

  const generateMarksTable = () => {
    const creditSystem = props.academics.institute?.affiliation?.credSystem;
    // let gradeSystem = props.academics.institute?.affiliation.gradingSystem;
    // if (gradeSystem) {
    //   gradeSystem = JSON.parse(gradeSystem);
    // }
    if(!creditSystem) {
      return '';
    }
    const affId = props.academics.institute?.affiliation.affiliateId;
    const html = [];
    if (!props.academics.semisters) {
      return html;
    }

    for (const sems of props.academics.semisters) {
      const year = moment(props.academics.startYear).get("year");

      const courseCur = props.academics.course.curriculum
        ?.filter(
          (cur) => cur.effectiveYear <= year && cur.affiliateId === affId
        )
        .sort((a, b) => b.effectiveYear - a.effectiveYear);

      const courseSubj = courseCur[0]?.curriculumSubjects?.filter(
        (cs) => cs.semId === sems.courseSemId
      );

      let gradeSystem = [];

      if (
        courseCur[0] &&
        courseCur[0].gradeSystem &&
        courseCur[0].gradeSystem.gradePoints
      ) {
        gradeSystem = courseCur[0].gradeSystem.gradePoints;
      }

      const maxAttempts = sems.studentMarks.sort(
        (a, b) => b.attempt - a.attempt
      )[0]?.attempt;

      const header = [
        <TableCell>Year / Semester</TableCell>,
        <TableCell>Attempt</TableCell>,
      ];
      const allRows = [];
      const rows = [
        <TableCell></TableCell>,
        <TableCell>
          {creditSystem === CRED_PERCENT ? "Max Marks" : "Max Credits"}
        </TableCell>,
      ];
      if (courseSubj && courseSubj.length > 0) {
        for (const cs of courseSubj) {
          header.push(<TableCell>{cs.subject.name}</TableCell>);
          rows.push(
            <TableCell>
              {creditSystem === CRED_PERCENT && (cs.maxMarks || 0)}
              {creditSystem !== CRED_PERCENT && (cs.credits || 0)}
            </TableCell>
          );
        }
        if (creditSystem === CRED_PERCENT) {
          header.push(<TableCell>Total</TableCell>);
        } else {
          header.push(<TableCell>SGPA</TableCell>);
        }
        header.push(<TableCell>Action</TableCell>);
        rows.push(
          <TableCell>
            {creditSystem === CRED_PERCENT &&
              calculateTotalMaxMarks(courseSubj)}
            
          </TableCell>,
          <TableCell>
            <Button
              type="primary"
              onClick={(e) =>
                addAttempt(courseSubj, maxAttempts, sems, courseCur[0])
              }
            >
              Add Attempt
            </Button>
          </TableCell>
        );
        allRows.push(<TableRow>{rows}</TableRow>);

        for (let i = 1; i <= (maxAttempts || 1); i++) {
          const markRows = [
            <TableCell>{sems.semDetails.semName}</TableCell>,
            <TableCell>Attempt {i}</TableCell>,
          ];
          for (const cs of courseSubj) {
            markRows.push(
              <TableCell
                className={
                  getStatusClassByAttemptAndSub(
                    sems.studentMarks,
                    i,
                    courseSubj
                  ) +
                  " " +
                  getResultClassByAttemptAndSub(
                    sems.studentMarks,
                    i,
                    cs.curSubId
                  )
                }
              >
                {creditSystem !== CRED_PERCENT && (
                  <span>
                    {getGradeByAttemptAndSub(
                      sems.studentMarks,
                      i,
                      cs.curSubId
                    ) || "-"}
                  </span>
                )}
                {creditSystem === CRED_PERCENT && (
                  <span>
                    {getMarksByAttemptAndSub(
                      sems.studentMarks,
                      i,
                      cs.curSubId
                    ) || "-"}
                  </span>
                )}
              </TableCell>
            );
          }
          markRows.push(
            <TableCell
              className={getStatusClassByAttemptAndSub(
                sems.studentMarks,
                i,
                courseSubj
              )}
            >
              {creditSystem !== CRED_PERCENT && (
                <span>
                  {calculateTotalGradePointsByAttempt(
                    sems.studentMarks,
                    courseSubj,
                    i,
                    gradeSystem
                  )}
                </span>
              )}
              {creditSystem === CRED_PERCENT && (
                <span>
                  {calculateTotalMarksByAttempt(
                    sems.studentMarks,
                    courseSubj,
                    i
                  )}
                </span>
              )}
            </TableCell>,
            <TableCell className="actions">
              {sems.studentMarks.length > 0 &&
                getCountOfUploadsByEntityIdAndType(
                  props.mgUploads.attachments,
                  sems.studentMarks
                    .filter((sm) => sm.attempt === i)
                    .map((sm) => sm.studentMarksId),
                  MARKS_MEMO
                ) && (
                  <ImageViewer
                    imageList={filterUploadsByEntityIdAndType(
                      props.mgUploads.attachments,
                      sems.studentMarks
                        .filter((sm) => sm.attempt === i)
                        .map((sm) => sm.studentMarksId),
                      MARKS_MEMO
                    )}
                    showActions={false}
                    showPin={true}
                    year={props.manage.currentProfile.batch}
                    {...props}
                  />
                )}
              {getStatusClassByAttemptAndSub(
                sems.studentMarks,
                i,
                courseSubj
              ) === "pending" && (
                <Button
                  type="link"
                  onClick={(e) =>
                    editAttempt(
                      courseSubj,
                      i,
                      sems,
                      courseCur[0],
                      filterUploadsByEntityIdAndType(
                        props.mgUploads.attachments,
                        sems.studentMarks
                          .filter((sm) => sm.attempt === i)
                          .map((sm) => sm.studentMarksId),
                        MARKS_MEMO
                      )
                    )
                  }
                  title="Edit"
                >
                  <EditOutlined />
                </Button>
              )}
              {(props.role === ADMIN ||
                getStatusClassByAttemptAndSub(
                  sems.studentMarks,
                  i,
                  courseSubj
                ) === "pending") && (
                <Button
                  type="link"
                  onClick={(e) => delAttempt(sems, i)}
                  title="Delete"
                >
                  <DeleteOutlined />
                </Button>
              )}
            </TableCell>
          );
          allRows.push(<TableRow>{markRows}</TableRow>);
        }

        const finalMarksRow = [
          <TableCell></TableCell>,
          <TableCell>
            {creditSystem === CRED_PERCENT ? "Final Marks" : "Final Grade"}
          </TableCell>,
        ];

        for (const cs of courseSubj) {
          finalMarksRow.push(
            <TableCell>
              {creditSystem !== CRED_PERCENT && (
                <span>
                  {getFinalGradeBySub(
                    sems.studentMarks,
                    cs.curSubId,
                    gradeSystem
                  ) || "-"}
                </span>
              )}
              {creditSystem === CRED_PERCENT && (
                <span>
                  {getFinalApprovedMarksBySub(sems.studentMarks, cs.curSubId) || "-"}
                </span>
              )}
            </TableCell>
          );
        }
        finalMarksRow.push(
          <TableCell>
            {creditSystem !== CRED_PERCENT && (
              <span>
                {calculateTotalFinalGradePoints(
                  sems.studentMarks,
                  courseSubj,
                  gradeSystem
                )}
              </span>
            )}
            {creditSystem === CRED_PERCENT && (
              <span>
                {calculateTotalFinalMarks(sems.studentMarks, courseSubj)}
              </span>
            )}
          </TableCell>,
          <TableCell></TableCell>
        );
        allRows.push(<TableRow>{finalMarksRow}</TableRow>);
      }

      html.push(
        <TableContainer
          component={Paper}
          style={{ marginBottom: "10px" }}
          className="marks-details-table"
        >
          <Table className="marks-table">
            <TableHead>
              <TableRow>{header}</TableRow>
            </TableHead>
            <TableBody>{allRows}</TableBody>
          </Table>
        </TableContainer>
      );
    }

    return html;
  };

  return (
    <>
      <Collapse bordered={false}>
        <Collapse.Panel
          header={<b>{props.academics.course?.courseName}</b>}
          key="1"
        >
          {/* <Row gutter={[16, 8]}>{generateMarkDetails()}</Row> */}
          {generateMarksTable()}
        </Collapse.Panel>
      </Collapse>
      {props.manage.showModal ===
        `EDIT_ATTEMPT_${props.academics.studentAcadId}` && (
        <MarksForm
          onCancel={onCancel}
          onFinish={onFinish}
          title={title}
          currCourseSub={currCourseSub}
          attempt={curAttempt}
          sem={curSem}
          curCurriculum={curCurriculum}
          {...props}
        />
      )}
    </>
  );
};

export default MarksDetails;

import React from "react";
import { Button, Form, Input, Modal, Select, Spin } from "antd";
import { AFFL, CRED_SYS } from "../utils/const";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class AddAffiliate extends React.Component {
  saveAffiliation = (form) => {
    this.props.saveMasterTableData(AFFL, form);
  };

  render() {
    const record = this.props.record;
    const fieldData = [
      {
        name: ["affiliateId"],
        value: record.affiliateId,
      },
      {
        name: ["credSystem"],
        value: record.credSystem,
      },
      {
        name: ["description"],
        value: record.description,
      },
      {
        name: ["name"],
        value: record.name,
      },
    ];

    const text = record.affiliateId ? "Edit Affiliation" : "Add Affiliation";
    return (
      <div id="add-affl-modal">
        <Modal
          title={text}
          visible={this.props.masterTable.showModal === AFFL}
          footer={null}
          onCancel={() => this.props.toogleAddModal("")}
          width="50%"
        >
          <Spin spinning={this.props.masterTable.loading} delay={500}>
            <Form
              name="affiliation"
              className="login-form login-wrapper"
              onFinish={this.saveAffiliation}
              fields={fieldData}
            >
              <Form.Item {...formItemLayout} name="affiliateId" hidden={true}>
                <Input />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="name"
                label="Affiliate Name"
                rules={[
                  {
                    required: true,
                    pattern: /^\S/,
                    message: "Please enter Affiliation Name",
                  },
                ]}
              >
                <Input maxLength="200"/>
              </Form.Item>
              <Form.Item {...formItemLayout} name="description" label="Description">
                <Input.TextArea maxLength="200" />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="credSystem"
                label="Credit System"
                rules={[
                  {
                    required: true,
                    message: "Please select Credit System",
                  },
                ]}
              >
                <Select className="full-width">
                  {CRED_SYS.map((type) => (
                    <Select.Option key={type.value} value={type.value}>
                      {type.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <div className="modal-form-button-div">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="modal-form-submit-button"
                  disabled={this.props.masterTable.loading}
                >
                  Save
                </Button>
              </div>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default AddAffiliate;

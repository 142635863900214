import { connect } from "react-redux";
import ApplicationFrom from "../components/ApplicationForm";
import {
  fetchDistricts,
  fetchMandals,
  fetchCasteCategory,
  fetchSchools,
  fetchExamCenters,
} from "../../base/actions/AppConfigActions";
import {
  SET_MSS_APPLY_FORM,
  SET_MSS_APPLY_FORM_VALUES,
  UPLOAD_FORM_ERRORS_UPDATE,
  UPDATE_APP_TAB,
  ADD_SCHOOL_REQUEST,
} from "../util/const";
import {
  uploadStudentDoc,
  getFile,
  saveApplication,
  getApplication,
  submitApplication,
} from "../actions/StudentActions";
import moment from "moment";
import { withRouter } from "react-router";

const mapDispatchToProps = (dispatch) => {
  return {
    uploadStudentDoc(applicationId, file, type) {
      // console.log("upload event ---------- ", file.file);
      dispatch(uploadStudentDoc(applicationId, file.file, type));
    },

    getFile(fileId, type) {
      dispatch(getFile(fileId, type));
    },

    getDistricts() {
      dispatch(fetchDistricts());
    },

    getMandalsList(districtId) {
      dispatch(fetchMandals(districtId));
    },

    getSchoolsList(mandalId) {
      dispatch(fetchSchools(mandalId));
    },

    getCastes() {
      dispatch(fetchCasteCategory());
    },

    getExamCenters() {
      dispatch(fetchExamCenters());
    },

    handleStudenNameChange(event) {
      // console.log("--------------", event.target.value);
    },

    handleApplicationFormFields(formValues) {
      dispatch({
        type: SET_MSS_APPLY_FORM_VALUES,
        payload: formValues,
      });
      // console.log("Form changed -------------", formValues);
    },

    setMssApplicationForm(form) {
      dispatch({
        type: SET_MSS_APPLY_FORM,
        payload: form,
      });
    },

    saveMssApplicationForm(form, application, username) {
      const newApplication = {
        ...form,
        year: moment().year(),
        type: 1,
        username: username,
      };

      if (application.applicationId) {
        newApplication.applicationId = application.applicationId;
        newApplication.year = application.year;
        newApplication.type = application.type;
        newApplication.username = application.username;
      }

      dispatch(saveApplication(newApplication));
    },

    getApplication(username, year) {
      dispatch(getApplication(year, username)).then((response) => {
        if (
          response.value &&
          response.value.applicationForm &&
          response.value.applicationForm.applicationId
        ) {
          const application = response.value.applicationForm;
          this.getMandalsList(application.school?.parent?.parent?.id);
          this.getSchoolsList(application.school?.parent?.id);
        }
      });
    },

    submitApplication(applicationForm) {
      dispatch(submitApplication(applicationForm));
    },
    updateUploadErrors(errors) {
      dispatch({
        type: UPLOAD_FORM_ERRORS_UPDATE,
        payload: errors,
      });
    },
    resetUploadField(filedName, uploads) {
      // const uploads = this.props.studentData.uploadFieldData;
      for (let field of uploads) {
        if (field.name[0] === filedName) {
          field.value = "";
        }
      }
      dispatch({
        type: UPLOAD_FORM_ERRORS_UPDATE,
        payload: uploads,
      });
    },

    updateAppTab(tabNum) {
      dispatch({
        type: UPDATE_APP_TAB,
        payload: tabNum,
      });
    },

    resetWorkflowData() {
      dispatch({
        type: `${ADD_SCHOOL_REQUEST}_FULFILLED`,
        payload: null,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, AppConfigReducer, StudentReducer } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    role: AuthReducer.role,
    lang: AuthReducer.lang,
    districtsList: AppConfigReducer.districtsList,
    mandalsList: AppConfigReducer.mandalsList,
    schoolsList: AppConfigReducer.schoolsList,
    castesList: AppConfigReducer.castesList,
    examCenters: AppConfigReducer.examCenters,
    formLoading:
      AppConfigReducer.districtsListLoading ||
      AppConfigReducer.mandalsListLoading ||
      AppConfigReducer.schoolsListLoading ||
      AppConfigReducer.castesListLoading ||
      AppConfigReducer.examCentersLoading,
    studentData: StudentReducer,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationFrom));

import {
  SET_MSS_APPLY_FORM,
  SET_MSS_APPLY_FORM_VALUES,
  UPLOAD_STUDENT_IMG,
  UPLOAD_STUDENT_PROOF,
  FETCH_STUDENT_IMG,
  UPLOAD_STUDENT_NINTH_FRONT,
  UPLOAD_STUDENT_NINTH_BACK,
  ADD_SCHOOL_REQUEST,
  GET_APPLICATION,
  SAVE_APPLICATION,
  UPLOAD_FORM_ERRORS_UPDATE,
  UPDATE_APP_TAB,
  SUBMIT_APPLICATION,
  ROTATE_IMG,
  GET_ADD_SCHOOL_REQUEST,
} from "../util/const";
import React from "react";
import {
  extractApplicationData,
  getFileData,
  updateUploadFormData,
  updateRotatedImageData,
} from "../util/util";
import { RESET_MESSAGES } from "../../base/utils/const";

const initialState = {
  status: "new",
  mssApplicationFormFields: {},
  mssApplicationForm: React.createRef(),
  studentImage: {},
  studentIdProof: {},
  ninthMemoBack: {},
  ninthMemoFront: {},
  studImg: {},
  loading: false,
  loadApplication: false,
  workflow: "",
  errorMessage: "",
  applicationForm: {},
  fieldData: [],
  uploadFieldData: [],
  uploadErrors: {},
  applicationTab: 0,
  successMessage: "",
  addSchoolRequests: [],
};
const StudentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MSS_APPLY_FORM:
      return {
        ...state,
        mssApplicationForm: action.payload,
      };

    case SET_MSS_APPLY_FORM_VALUES:
      return {
        ...state,
        mssApplicationFormFields: action.payload,
      };

    case `${UPLOAD_STUDENT_IMG}_PENDING`: {
      const studentImage = {
        status: "uploading",
      };
      return {
        ...state,
        studentImage: studentImage,
        successMessage: "",
      };
    }

    case `${UPLOAD_STUDENT_IMG}_REJECTED`: {
      const studentImage = {
        status: "error",
      };
      return {
        ...state,
        studentImage: studentImage,
      };
    }

    case `${UPLOAD_STUDENT_IMG}_FULFILLED`: {
      const uploadFieldData = updateUploadFormData(
        state.uploadFieldData,
        action.payload,
        "studentImage",
        state.applicationForm.year
      );
      return {
        ...state,
        studentImage: getFileData(action.payload, state.applicationForm.year),
        loading: false,
        successMessage: "Student Image uploaded successfully",
        uploadFieldData: uploadFieldData,
      };
    }

    case `${UPLOAD_STUDENT_PROOF}_PENDING`: {
      const studentIdProof = {
        status: "uploading",
      };
      return {
        ...state,
        studentIdProof: studentIdProof,
        loading: true,
        successMessage: "",
      };
    }

    case `${UPLOAD_STUDENT_PROOF}_REJECTED`: {
      const studentIdProof = {
        status: "error",
      };
      return {
        ...state,
        studentIdProof: studentIdProof,
        loading: false,
      };
    }

    case `${UPLOAD_STUDENT_PROOF}_FULFILLED`: {
      const uploadFieldData = updateUploadFormData(
        state.uploadFieldData,
        action.payload,
        "studentIdProof",
        state.applicationForm.year
      );
      return {
        ...state,
        studentIdProof: getFileData(action.payload, state.applicationForm.year),
        loading: false,
        successMessage: "Student ID Form uploaded successfully",
      };
    }

    case `${UPLOAD_STUDENT_NINTH_FRONT}_PENDING`: {
      const ninthMemoFront = {
        status: "uploading",
      };
      return {
        ...state,
        ninthMemoFront: ninthMemoFront,
        successMessage: "",
      };
    }

    case `${UPLOAD_STUDENT_NINTH_FRONT}_REJECTED`: {
      const ninthMemoFront = {
        status: "error",
      };
      return {
        ...state,
        ninthMemoFront: ninthMemoFront,
      };
    }

    case `${UPLOAD_STUDENT_NINTH_FRONT}_FULFILLED`: {
      const uploadFieldData = updateUploadFormData(
        state.uploadFieldData,
        action.payload,
        "ninthMemoFront",
        state.applicationForm.year
      );
      return {
        ...state,
        ninthMemoFront: getFileData(action.payload, state.applicationForm.year),
        loading: false,
        successMessage:
          "9th Class Marks Memo – Front Side uploaded successfully",
      };
    }

    case `${UPLOAD_STUDENT_NINTH_BACK}_PENDING`: {
      const ninthMemoBack = {
        status: "uploading",
      };
      return {
        ...state,
        ninthMemoBack: ninthMemoBack,
        loading: true,
        successMessage: "",
      };
    }

    case `${UPLOAD_STUDENT_NINTH_BACK}_REJECTED`: {
      const ninthMemoBack = {
        status: "error",
      };
      return {
        ...state,
        ninthMemoBack: ninthMemoBack,
        loading: false,
      };
    }

    case `${UPLOAD_STUDENT_NINTH_BACK}_FULFILLED`: {
      const uploadFieldData = updateUploadFormData(
        state.uploadFieldData,
        action.payload,
        "ninthMemoBack",
        state.applicationForm.year
      );
      return {
        ...state,
        ninthMemoBack: getFileData(action.payload, state.applicationForm.year),
        loading: false,
        successMessage:
          "9th Class Marks Memo – Back Side uploaded successfully",
      };
    }

    case `${FETCH_STUDENT_IMG}_FULFILLED`: {
      return {
        ...state,
        studImg: action.payload,
      };
    }

    case `${ADD_SCHOOL_REQUEST}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        workflow: "",
        successMessage: "",
      };
    }

    case `${ADD_SCHOOL_REQUEST}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        workflow: "",
      };
    }

    case `${ADD_SCHOOL_REQUEST}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        workflow: action.payload,
        errorMessage: "",
      };
    }

    case `${GET_ADD_SCHOOL_REQUEST}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        addSchoolRequests: [],
        successMessage: "",
      };
    }

    case `${GET_ADD_SCHOOL_REQUEST}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        addSchoolRequests: [],
      };
    }

    case `${GET_ADD_SCHOOL_REQUEST}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        addSchoolRequests: action.payload,
        errorMessage: "",
      };
    }

    case `${SAVE_APPLICATION}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
      };
    }

    case `${SAVE_APPLICATION}_REJECTED`: {
      return {
        ...state,
        loading: false,
      };
    }

    case `${SAVE_APPLICATION}_FULFILLED`: {
      // const school = state.applicationForm.school;
      // action.payload.applicationForm.school = school;
      const view = extractApplicationData(action.payload);
      return {
        ...state,
        loading: false,
        applicationForm: view.application,
        mssApplicationFormFields: view.application,
        fieldData: view.fieldData,
        studentImage: view.studentImage,
        studentIdProof: view.studentIdProof,
        ninthMemoFront: view.ninthMemoFront,
        ninthMemoBack: view.ninthMemoBack,
        uploadFieldData: view.uploadFieldData,
        applicationTab: 1,
        successMessage: "Application saved successfully",
      };
    }

    case `${SUBMIT_APPLICATION}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
      };
    }

    case `${SUBMIT_APPLICATION}_REJECTED`: {
      return {
        ...state,
        loading: false,
      };
    }

    case `${SUBMIT_APPLICATION}_FULFILLED`: {
      const view = extractApplicationData(action.payload);
      return {
        ...state,
        loading: false,
        applicationForm: view.application,
        mssApplicationFormFields: view.application,
        fieldData: view.fieldData,
        studentImage: view.studentImage,
        studentIdProof: view.studentIdProof,
        ninthMemoFront: view.ninthMemoFront,
        ninthMemoBack: view.ninthMemoBack,
        uploadFieldData: view.uploadFieldData,
        applicationTab: 3,
        successMessage: "Application saved successfully",
      };
    }

    case `${GET_APPLICATION}_PENDING`: {
      return {
        ...state,
        loading: true,
        loadApplication: true,
        errorMessage: "",
        successMessage: "",
        applicationForm: {},
      };
    }

    case `${GET_APPLICATION}_REJECTED`: {
      return {
        ...state,
        loading: false,
        loadApplication: false,
        errorMessage: action.payload,
      };
    }

    case `${GET_APPLICATION}_FULFILLED`: {
      const view = extractApplicationData(action.payload);
      return {
        ...state,
        loading: false,
        loadApplication: false,
        applicationForm: view.application,
        mssApplicationFormFields: view.application,
        fieldData: view.fieldData,
        studentImage: view.studentImage,
        studentIdProof: view.studentIdProof,
        ninthMemoFront: view.ninthMemoFront,
        ninthMemoBack: view.ninthMemoBack,
        uploadFieldData: view.uploadFieldData,
      };
    }

    case `${ROTATE_IMG}_FULFILLED`: {
      const view = updateRotatedImageData(state, action.payload);
      return {
        ...state,
        studentImage: view.studentImage,
        studentIdProof: view.studentIdProof,
        ninthMemoFront: view.ninthMemoFront,
        ninthMemoBack: view.ninthMemoBack,
        uploadFieldData: view.uploadFieldData,
        loading: false,
        successMessage: "Image updated successfully.",
      };
    }

    case UPLOAD_FORM_ERRORS_UPDATE: {
      return {
        ...state,
        uploadFieldData: action.payload,
      };
    }

    case UPDATE_APP_TAB: {
      return {
        ...state,
        applicationTab: action.payload,
      };
    }

    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default StudentReducer;

import React from "react";
import { translate } from "../../base/actions/translate";
import {
  ACCEX,
  ADMIN,
  MGR,
  MSSCC,
  MSSPC,
  MSSSTUD,
  MWCO,
  Roles,
  STUDE,
} from "../../base/utils/roles";
import { ALL_TASK_CAT, APRV, CLOS, COMP, DEN, PEND } from "./const";
import TASK_FLOW_CONFIG from "./taskFlowConfig.json";

export const getEventType = (eventType) => {
  let type = ALL_TASK_CAT.filter((t) => t.value === eventType);
  if (type.length > 0) {
    return translate(type[0].label);
  }
  return eventType;
};

export const getStatusString = (status) => {
  switch (status) {
    case APRV:
      return "Approved";
    case PEND:
      return "Pending";
    case DEN:
      return "Denied";
    case COMP:
      return "Completed";
    case CLOS:
      return "Closed";
    default:
      return "";
  }
};

export const getAccessLevel = (role) => {
  switch (role) {
    case ADMIN:
      return 1;
    case MGR:
      return 2;
    case MSSPC:
      return 3;
    case MSSCC:
      return 4;
    case MWCO:
      return 5;
    case ACCEX:
      return 6;
    case MSSSTUD:
    default:
      return 7;
  }
};

export const checkEligibility = (givenLevels, role, status) => {
  if (!givenLevels) {
    return false;
  }

  const userLevel = getAccessLevel(role);

  if (userLevel === getAccessLevel(ACCEX) && status === COMP) {
    return true;
  } else if (status === COMP || status === CLOS || status === DEN) {
    return false;
  }

  for (const l of givenLevels) {
    if ((6 !== Number(l) && userLevel < Number(l)) || userLevel === Number(l)) {
      return true;
    }
  }
  return false;
};

export const generateTaskFilesList = (filesList, taskId) => {
  const docs = [];
  if (filesList) {
    for (const file of filesList) {
      docs.push({
        id: file.managementUploadId,
        src: `manage/${file.studentProfile.batch}/${file.studentProfile.studentProfileId}/${file.type}/${file.entityId}/${file.filename}`,
        angle: file.angle,
        extension: file.extension,
        status: file.status,
        filename: file.filename,
      });
    }
  }
  return docs;
};

export const getStatusContent = (apl, currentLevel, status, taskType) => {
  const html = [];

  if (!currentLevel) {
    return html;
  }

  let totalLevels = 0;
  for (const al of apl) {
    if (al.taskType === taskType) {
      totalLevels = al.levels;
    }
  }
  for (let i = 1; i <= totalLevels; i++) {
    if (i < currentLevel || (i < currentLevel && status === APRV)) {
      html.push(<span className="dot aprv"></span>);
    } else if (i === currentLevel && status === COMP) {
      html.push(<span className="dot aprv"></span>);
    } else if (i === currentLevel && status === CLOS) {
      html.push(<span className="dot aprv"></span>);
    } else if (i === currentLevel && status === DEN) {
      html.push(<span className="dot den"></span>);
    } else {
      html.push(<span className="dot"></span>);
    }
  }
  return <div>{html}</div>;
};

const converter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
});

export const toCurrency = (num) => {
  if (!num) {
    num = 0;
  }
  let result = converter.format(Math.round(num)).split('.')[0];
  return  result;
};


export const canEditRequest = (currentLevel, role, taskType) => {

  let flowConfig = TASK_FLOW_CONFIG.filter(c => 
    c.current_level >= currentLevel && c.access_level === getAccessLevel(role) && c.task_type === taskType
  );

  if(role === ACCEX) {
    flowConfig = TASK_FLOW_CONFIG.filter(c => 
      c.current_level === currentLevel && c.access_level === getAccessLevel(role) && c.task_type === taskType
    );
  }

  if(flowConfig && flowConfig.length > 0) {
    return true;
  }
  return false;
};
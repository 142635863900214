import React from "react";
import { Link } from "react-router-dom";

export const en = {
  username: "Mobile Number / Username",
  password: "Password",
  loginError: "The Username or Password your entered is incorrect.",
  welcome: "Welcome",
  applocationFormMessage:
    "Before filling application form, please download Student Identification Authorisation Form, fill it and take Headmaster’s signature and school stamp on the form.",
  scholorshipSelectionMessage:
    "Please select from the scholarship programmes below to apply",
  vision:
    "We make a difference in students’ lives by giving them hope of a better future, with support that goes beyond the material requirements of education and guidance that exceeds the expectation of every student under our umbrella.",
  sampoornaSikshaInfo:
    "Every year over 100 students are given complete scholarship for Intermediate and Graduate education and up to 30 students are given scholarship for diploma programmes. This scholarship is available only in Telangana for 10th class students studying in Zilla Parishad High School, Government High School, Telangana Model School, Telangana Minority Residential School (Gurukulam) and Mahatma Jyotiba Phule Schools of Telangana state. Interested students can apply for scholarship exam conducted by Medha Charitable Trust here.",
  nipunyaNirman:
    "Every year 500 students are given technical skills training for 6 months along with placement services. Certificate courses are conducted in Central Institute of Plastics and Engineering Technology (CIPET), Swami Ramananda Tirtha Rural Institute (SRTRI), Boys Town ITI, Central Institute of Tool Design (CITD) and National Small Industries Corporation – Technical Services Centre (NSIC-TSC). This scholarship is available only for candidates who attempted 10th class, passed 10th class, passed Intermediate, completed ITI or completed Diploma anywhere in India. Interested candidates can apply for certificate courses here.",
  applicationPreviewHeading:"Please verify below details before submitting the form. Inorder to change the details entered, please click Edit button in the bottom of the page.",
  applicationPreviewNote:'Note',
  applicationPreviewNoteCont:'This is NOT Medha Charitable Trust Exam Hall Ticket.',
  studentName: "Student Name",
  studentSurName: "Student Sur Name",
  fatherName: "Father’s Name",
  mothersName: "Mother’s Name",
  motherName: "Mother’s Name",
  dateOfBirth: "Date of Birth",
  alternatePhone: "Alternate Phone Number",
  address: "Student's Home Address",
  emailAddress: "Email Address",
  casteId: "Caste Category",
  gender: "Gender",
  ninthGrade: "9th Class Grade",
  medium: "Medium of Education",
  headmasterName: "Headmaster’s Name",
  headmasterPhone: "Headmaster’s Phone Number",
  district: "School District",
  oldDistrict: "School Old District",
  mandal: "School Mandal",
  school: "School Name",
  examCenter: "Exam Centre",
  uploadHeading: "Upload the following documents.",
  studentPassportPhoto: "Student Passport Size Photo",
  StudentIdentificationForm: "Student Identification Authorisation Form",
  ninthMarksMemoFront: "9th Class Marks Memo - Front Side",
  ninthMarksMemoBack: "9th Class Marks Memo - Back Side ",
  appNo: "Your application number is :",
  appNoView: "Application Number :",

  addSchoolTextpart1: "If your school is not listed,",
  addSchoolTextpart2: "please click",
  addSchoolTextpart3: "here to add your school.",

  studentNameError: "Please enter Student Name. Only alphabets are allowed.",
  studentSurNameError:
    "Please enter Student Sur Name. Only alphabets are allowed.",
  fatherNameError: "Please enter Father’s Name. Only alphabets are allowed.",
  mothersNameError: "Please enter Mother’s Name. Only alphabets are allowed.",
  dobError: "Please select your Date of Birth.",
  alternativePhoneNumberError: "Please enter a valid Alternate Phone Number.",
  altNumStudentNum:
    "Student Alternate Phone Number should be different from Registered Mobile Number.",
  altNumHeadMNum:
    "Headmaster's  Number should be different from Registered Mobile Number.",
  studentHomeAddressError: "Please enter a valid Student Home Address.",
  castCategoryError: "Please select your Caste Category.",
  genderError: "Please select your Gender.",
  ninthGradeError: "Please select a your 9th Class Grade.",
  mediumError: "Please select your Medium of Education",
  headmasterNameError:
    "Please enter Headmaster’s name. Only alphabets are allowed.",
  headmasterPhoneNoError: "Please enter a valid Headmaster’s Phone Number.",
  schoolDistrictError: "Please select your School District.",
  schoolMandalError: "Please select your School Mandal.",
  schoolNameError: "Please select your School Name.",
  examCenterError: "Please select Exam Centre.",
  examCenterDisc:
    "Students can select exam centre in any district. Exam centre once selected cannot be changed.",
  studentPassportPhotoError:
    "Please upload Student Passport Size Photo. File should be in jpeg, jpg or png formats only, with maximum size of 500 KB.",
  StudentIdentificationFormError:
    "Please upload Student Identification Authorisation Form. File should be in jpeg, jpg, png or pdf formats only, with maximum size of 500 KB.",
  ninthMarksMemoFrontError:
    "Please upload 9th Class Marks Memo – Front Side. File should be in jpeg, jpg, png or pdf formats only, with maximum size of 500 KB. Mark sheet should have Headmaster signature and school stamp.",
  ninthMarksMemoBackError:
    "Please upload 9th Class Marks Memo – Back Side. File should be in jpeg, jpg, png or pdf formats only, with maximum size of 500 KB.",
  applicationCompleteHead: "Your application was successfully submitted.",
  applicationCompleteMessage:
    "We will inform your application status to your registered mobile number after processing your application.",
  altRegisteredPhoneNumberError:
    "Alternate Phone number can not be same as registererd phone number.",
  headMasterPhoneNumberError:
    "Headmaster's Phone number can not be same as registererd phone number.",
  status: "Status",
  EN: "English",
  TE: "Telugu",
  FaqMainHeading:
    "Below are frequently asked questions for Medha Charitable Trust Scholarship Exam application process, hall ticket, exam preparation and admission process.",
  FAQHeading1: "Questions on application process and hall ticket",

  Faq1: "Who can apply for Medha Charitable Trust scholarship exam?",
  Faqans1:
    "Students from Telangana government schools currently in 10th class can apply for Intermediate and graduate college scholarship. Medha scholarships are available only for students of Zilla Parishad High School, Government High School, Telangana Model School, Telangana Minority Residential School (Gurukulam) and Mahatma Jyotiba Phule Schools of Telangana state. Private school students cannot apply and will notbe given Medha Charitable Trust scholarships.",
  Faq2: "I forgot my password. How should I login?",
  Faqans2:
    "Click on Forgot Password and fill in the details to receive your password via SMS on your registered mobile number.",
  Faq3: "Can I apply for multiple students with one mobile number?",
  Faqans3:
    "Only 1 application can be submitted with 1 mobile number. This registered mobile number will be used to send messages regarding application status.",
  Faq4: "We used my mobile number to apply last year, can I apply with same number again this year?",
  Faqans4:
    "Yes, you can apply with same mobile number that was used last year.",
  Faq5: "My school is not listed. What should I do?",
  Faqans5:
    "Medha Sampurna Siksha scholarship is available only for students of Zilla Parishad High School, Government High School, Telangana Model School, Telangana Minority Residential School (Gurukulam) and Mahatma Jyotiba Phule Schools of Telangana state. If your school is one of the above,  then in application form, click on Add New School to fill your school details. We will verify and add your school.",
  Faq6: "How many students can apply from my school?",
  Faqans6:
    "Any number of students can apply but Medha Charitable Trust will issue hall tickets to maximum 10 students in English medium and 10 students in Telugu medium.",
  Faq7: "Our school strength is high, can more students apply and receive hall tickets?",
  Faqans7:
    "Medha Charitable Trust will issue hall tickets to maximum of 10 students in English medium and 10 students in Telugu medium irrespective of school strength and number of applications from a school.",
  Faq8: "Can schools in our mandal work together to give more applications to higher strength schools and less applications to lower strength schools?",
  Faqans8:
    "Medha Charitable Trust will issue maximum of 10 hall tickets for English medium students and 10 hall tickets for Telugu medium students per schools and doesn’t allow schools to combine student strengths to give more hall tickets to one school and less to others.",
  Faq9: "Which exam center should  I select for my district?",
  Faqans9:
    "Any students can write exam from any exam center. There are no restrictions of exam center selection.",
  Faq10: "What documents should be uploaded?",
  Faqans10:
    "Student passport size photo, Student identification authorisation form, 9th class marks memo front side and back side.",
  Faq11: "We are not able to upload documents. What should we do?",
  Faqans11:
    "Student passport size photo should be in jpg, tiff or png formats only. Student identification authorisation form, 9th class marks memo - front side and 9th class marks - back side should be in in jpg, tiff, png or pdf formats only. All files should be maximum of 500 KB size.",
  Faq12: "We don’t have scanner in school, can I take picture and apply?",
  Faqans12: "If picture quality is good, we will accept it.",
  Faq13: "Where is Student Identification Authorisation Form?",
  Faqans13:
    "Download the file from downloads on left menu. Headmaster signature and school stamp are required on this form before uploading.",
  Faq14: "I don’t have 9th class marks, what should I do?",
  Faqans14:
    "Headmaster of school can write student’s 9th class marks on white paper. We will accept it if  Headmaster of school signs and stamps it.",
  Faq15: "I entered wrong details and uploaded wrong files. What should I do?",
  Faqans15:
    "You can login and correct your details and upload correct files till application submission last date. You can correct your application by clicking on Application and then on Edit Form on left menu bar. If application last date is over, contact Medha Charitable Trust office.",
  Faq16: "Will application last date be extended?",
  Faqans16: "If last date is extended, we will notify it on our website.",
  Faq17:
    "What should I do if there are technical problems in application form?",
  Faqans17: "Please call Medha Charitable Trust office.",
  Faq18: "Why is my application is rejected for scholarship?",
  Faqans18:
    "Only top 10 applications per school are selected for scholarship exam based on 9th class marks or headmaster recommendation, rest of the applications rejected. Applications with wrong details are rejected. Applications received from schools that are not eligible are also rejected.",
  Faq19: "Where is my exam hall ticket?",
  Faqans19:
    "If your application is accepted, you will receive message on your registered mobile number. Login to Medha Charitable Trust website and download your hall ticket from the Downloads button on the left menu bar.",

  FAQHeading2: "Questions on exam preparation and admission process",

  Faq20: "What is the exam pattern?",
  Faqans20:
    "Medha Charitable Trust scholarship exam has 150 objective type questions. Each question will have 4 answer choices and student has to mark the correct answer in answer sheet. Exam will be on 6 subjects of SSC level and general knowledge. 40 questions in Mathematics, 40 questions in Physical Sciences, 40 questions in Biology, 10 questions in Social Sciences, 10 questions in English and 10 questions in Aptitude. Each question carries 3 marks and wrong answer will have -1 (negative) marks and not answering a question or marking multiple answers will be 0.",
  Faq21:
    "Can we get model question papers or previous year question papers to practice for exam?",
  Faqans21:
    "Model question paper with 10 questions will be available to download along with hall ticket.",
  Faq22: "How should we practice for exam?",
  Faqans22:
    "Exam questions are based on Telangana schools SSC level. Studying 10th class SSC text books is sufficient.",
  Faq23: "What is the duration of the exam?",
  Faqans23:
    "Exam is for 2 hours and 30 minutes to answer 150 objective type questions.",
  Faq24: "What is the exam date?",
  Faqans24:
    "Exam date will be announced each year in January and will be conducted after Telangana SSC exams.",
  Faq25: "At what time does the exam start?",
  Faqans25: "Exam starts at 9am.",
  Faq26: "What can I carry with me to the exam center?",
  Faqans26:
    "Bring SSC exam hall ticket and Medha Charitable Trust scholarship exam hall ticket. Stationery like pens, pencils, erasers, pencil sharpener and exam pad are allowed. Electronic devices like calculators and mobile phone and all other personal items like school bags, handbags, purse, wallets or plastic covers will not be allowed inside the exam center. We will not provide storage facility inside the exam center.",
  Faq27: "I wrote exam but didn’t get selected. What are my marks?",
  Faqans27:
    "We select only toppers of Telangana state for providing scholarships. We don’t disclose marks of any students.",
  Faq28: "I got selected in exam, what should I do now?",
  Faqans28: "We will contact you for further admission steps.",
  Faq29: "What education is supported by Medha Charitable Trust?",
  Faqans29:
    "In Medha Sampurna Siksha, students can opt for MPC, BiPC and MEC for Intermediate. They can opt for over 20 courses for graduate programme based on where they secure admission. We sponsor a wide range of graduate programmes like MBBS, BVMS, BDS, BSc Nursing, BSc Agriculture, BE, BTech, Integrated courses (BTech and PG) BSc , CA etc.",

  AddSchoolHeading:
    "Medha Sampurna Siksha scholarship is available for students from Zilla Parishad High Schools, Government High Schools, Telangana Model Schools and Telangana Minority Residential Schools (Gurukulam) and Mahatma Jyotiba Phule Schools of Telangana state. If your school is among this list and is not listed in application form, please enter your school details. We will notify you after verifying your school details.",
  addSchoolSuccess:
    "Thank you for submitting your school details. We will inform you once your school details are verified.",

  photoInstructions:
    "Upload jpeg, jpg, png or pdf formats only, with maximum size of 500 KB.",
  photoHeadSign:
    "Mark sheet should have Headmaster’s signature and school stamp.",
  passportPhotoInst:
    "Upload jpeg, jpg or png formats only, with maximum size of 500 KB.",
  ninthMemoExtraInfo:
    "Mark sheet should have Headmaster’s signature and school stamp. Upload jpeg, jpg, png or pdf formats only, with maximum size of 500 KB.",

  homePageAppComplete:
    "Your application was successfully submitted. We will inform your application status to your registered mobile number after processing your application.",
  homePageEditMsg:
    "You can edit your application to correct any mistakes by clicking on Sampurna Siksha on the left menu bar and clicking on Edit Form.",
  homePageViewForm:
    "You can view your application by clicking on Sampurna Siksha on the left menu bar and clicking on View Form.",

  appViewHeading: "Medha Charitable Trust scholarship exam application details",

  TOTAL: "Total",
  INCOMP: "Incomplete",
  NEW: "New",
  VERIF: "Verified",
  ACCPT: "Accepted",
  WAIT: "Waitlisted",
  REJECD: "Rejected D",
  REJECC: "Rejected C",
  APPRV: "Approved",
  DOWN: "Downloaded",
  UNDEC: "Undecided",
  REJECT: "Rejected",
  SMSS: "Shortlist MSS",
  ATTMPT: "Attempted Exam",
  ENROL: "Enrolled",
  JOIN: "Joined",
  studentImage: "Passport Photo",
  studentIdProof: "Student ID Form",
  ninthMemoFront: "9th Memo – Front",
  ninthMemoBack: "9th Memo – Back",

  STUD_IMG: "Passport Photo",
  STUD_ID: "Student ID Form",
  MEMO_NINTH_FRONT: "9th Memo – Front",
  MEMO_NINTH_BACK: "9th Memo – Back",
  caste: "Caste",
  ADMIN: "GM",
  STUDE: "Student",
  MGR: "Manager",
  MSSPC: "MSS Programme Coordinators",
  MSSCC: "MSS Curriculum coordinators",
  MWCO: "Medical and Welfare Coordinators",
  ACCEX: "Accounts Executive",
  DSTIN: "District Incharge",
  SELCOMT: "Selection Committee",

  ADD_SCHL: "Add School Request",
  DIST: "District",
  ODST: "Old District",
  MNDL: "Mandal",
  SCHL: "School",

  APRV: "Approved",
  DENY: "Denied",
  SDENY: "Staff Denied",
  SAPRV: "Staff Approved",
  PNDG: "Pending",

  incompleteForm: (
    <p>
      Please <Link to="/sampurnaSiksha/apply"> click here </Link> to complete
      the application for Medha Sampurna Siksha.
    </p>
  ),
  applicationnotOnTime:
    "Your application was not submitted before due date, hence your application was rejected. We wish you the best for your future endeavours.",
  applicationNumber: "Application Number",
  applicationRejectMsg: (
    <p>
      Your application to Medha Charitable Trust Exam has been{" "}
      <strong>rejected</strong>. We wish you the best for your future
      endeavours.
    </p>
  ),
  studentAttemtedExam:
    "Thank you for attempting Medha Charitable Trust scholarship exam. We will contact you if you are selected.",
  studentSelectedToMss:
    "You are selected for awarding Medha Charitable Trust scholarship. We will contact you with further details.",
  hallTicketNumber: "Hall Ticket Number",
  examDateAndTime: "Exam Date and Time",
  applicationEndDate: "Application Last Date",
  applicationApproveMsg: (
    <p>
      Your application to Medha Charitable Trust Exam has been{" "}
      <strong>approved</strong>. Please download your hall ticket from Downloads
      menu on the left side.
    </p>
  ),

  parentsIntrest: "Are parents interested in sending student to us?",
  collegeOffer: "Did student receive any private college offers?",
  applyForIIIT: "Did student apply for IIIT?",
  preferMCT: "If yes to IIIT, does student prefer IIIT over MCT scholarship?",
  canSupportFin:
    "If student doesn't get MCT admission, can parents support financially?",

  whyRequireMCT: "Please describe why student requires MCT scholarship",
  isStudentActive:
    "Is the student active and can the student adjust to lifestyle away from home",
  isParentsTruthful:
    "Were the student's family members truthful to you in all their responses",
  finalObservation:
    "Please list any final observation and comments about the student family.",

  RATION: "Ration card",
  PAN: "PAN card",
  ADHAAR: "Aadhaar card",
  CASTE_CERT: "Caste certificate",
  INC_PROF: "Income certificate",
  SOLO: "Solo picture of student",
  FAMILY: "Full family pictures",
  STREET: "Street of the student's house",
  HOUSE_OUT: "Pictures of house from outside gate",
  HOUSE_IN: "Pictures of house from inside gate and surrounding areas",
  ROOMS: "Pictures from inside house covering all rooms",
  KITCHEN: "Pictures of kitchen",
  ELE_ITEMS: "Pictures of electronic items",
  INC_GEN_OPT: "Pictures of any income generating operations",
  AWARDS: "Pictures of student’s awards, medals, certificate etc",
  OTHER_INFO: "Pictures of any other information worth noting",
  WITNESSES:
    "Picture of all people present during survey and any other extra pictures",
  OTHERS: "Others",
  others: "Others",
  fPrimary: "Father's Primary Occupation",
  mPrimary: "Mother's Primary Occupation",
  oPrimary: "Other's Primary Occupation",
  fSecondary: "Father's Secondary Occupation",
  mSecondary: "Mother's Secondary Occupation",
  oSecondary: "Other's Secondary Occupation",
  fAnnualIncome: "Father's Annual Income",
  fRemarks: "Father's Remarks",
  mAnnualIncome: "Mother's Annual Income",
  mRemarks: "Mother's Remarks",
  oAnnualIncome: "Other's Annual Income",
  oRemarks: "Other's Remarks",

  fatherEducation: "Education",
  motherEducation: "Education",
  siblingName: "Sibling's Name",
  siblingEducation: "Education",

  numberOfPeople: "Total Family Members at home",
  numberOfDependents: "Number of dependents in the family",
  totalAnnualIncome: "Total Family Annual Income",
  totalPropertyValue: "Total Assets Value",
  newTotalAnnualIncome: "New Total Family Annual Income",
  newTotalPropertyValue: "New Total Assets Value",

  TV: "TV",
  SET_TOP_BOX: "Set-top Box",
  MUSIC_SYSTEM: "Music System",
  HOME_THEATRE: "Home Theatre",
  VCR: "VCR / VCD Player",
  DVD: "DVD Player",
  COMPUTER: "Computer",
  REFRIGERATOR: "Refrigerator",
  WASH_MACH: "Washing Machine",
  AIR_COOLER: "Air Cooler",
  AC: "AC",

  Y: "Yes",
  N: "No",

  PER_INFO: "Personal Information",
  CONT_INFO: "Contact Information",
  CONS_ITEMS: "Consumer Items",
  VEHC_INFO: "Vehicles Information",
  STUD_INFO: "Student Information",
  RESP_TO_MSS: "Response To MSS",
  VOL_CMNTS: "Volunteer Comments",
  OCC_DTLS: "Occupation Details",
  AGR_OWN: "Agriculture Lands",
  CAT_OWN: "Animal Husbandary",
  PLOT_OWN: "Plots Owned",
  HOUSE_OWN: "Houses Owned",
  BUS_OWN: "Businesses",
  NGHB_DTLS: "Neighbour Reference",
  INC_SUM: "Income Summary",
  UPLD_SUR: "Images",
  PRVW_SUR: "Preview",
  //Task list related constants
  MARKS_APPRV: "Marks Approval",
  INTERACTION: "Interactions",
  MED_REQ_APPRV: "Medical Request",
  WEL_REQ_APPRV: "Welfare Request",
  MON_REQ_APPRV: "Monetary Request",
  PEND: "Pending",
  DEN: "Denied",
  COMP: "Completed",
  CLOS: "Closed",
  M: "Male",
  F: "Female",
  dropOutReason: "Drop Out Reason",
  returnReason: "Reason for Return",
  date: "Date",
  futureStatus: "Future Status",
  orgName: "Organisation Name",
  designation: "Designation",
  salary: "Monthly Salary",
  instituteName: "Institution Name",
  study: "Programme of Study",
  mobileNumber: "Mobile Number",
  email: "Email Address",
  notes: "Notes",
  schoolId: "School Name",
  examCenterId: "Exam Center"
};

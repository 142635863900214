import React from 'react';
import { Redirect } from 'react-router';
import Welcome from './Welcome';
import '../styles/auth.css';

class Auth extends React.Component {

    render() {
        if (this.props.isLoggedIn && window.location !== '/home') {
            return <Redirect to="/home" />
        }
        return (
            <div id="authContainer">
                <Welcome {...this.props} />
            </div>
        );
    }
}

export default Auth;
import { Card, Col, Empty, Row } from "antd";
import React from "react";
import { CONT_INFO, PER_INFO, STUD_INFO, VEHC_INFO } from "../../utils/consts";
import ViewSurveyCard from "./ViewSurveyCard";

class StudentInfoView extends React.Component {
  generateFieldData = (data) => {
    const html = [];
    if (Array.isArray(data)) {
      for (const j of data) {
        for (const i in j) {
          html.push(
            <Col sm={6}>
              <label>{i}</label>:
            </Col>,
            <Col sm={6}>
              {" "}
              <span>{j[i]}</span>
            </Col>
          );
        }
      }
    } else {
      for (const i in data) {
        if (!Array.isArray(data[i])) {
          html.push(
            <Col sm={12}>
              <label>{i}</label>:
            </Col>,
            <Col sm={12}>
              {" "}
              <span>{data[i]}</span>
            </Col>
          );
        }
      }
    }

    return html;
  };
  render() {
    let info = this.props.data;
    if(!info) {
      return (
        <ViewSurveyCard title="Student Information" {...this.props}>
          <Empty />
        </ViewSurveyCard>
      );
    }
    return (
      <div>
        <ViewSurveyCard title="Student Information" {...this.props}>
          <Col sm={24}>
              <label>Student's Goal</label>:
            </Col>
            <Col sm={24}>
              <span>{info.studentsGoal}</span>
            </Col>
            <Col sm={24}>
              <label>Student's Health Problem</label>:
            </Col>
            <Col sm={24}>
              <span>{info.studentsHealthProblem}</span>
            </Col>
        </ViewSurveyCard>
      </div>
    );
  }
}

export default StudentInfoView;

import { Card, Col, Empty, Input, Row } from "antd";
import React from "react";
import { toCurrency } from "../../../common/utils/util";
import { PLOT_OWN } from "../../utils/consts";
import ViewSurveyCard from "./ViewSurveyCard";

class PlotsView extends React.Component {
  setInfo(info) {
    this.props.setCommitteeData(info, PLOT_OWN);
  }

  updateValues = (value, i, key) => {
    // console.log(i, "--------------------", value);
    let info = this.props.data;
    info.plotsList[i][key] = value;
    this.setInfo(info);
  };

  generateFieldData = (info) => {
    const html = [];
    const isSelComt = this.props.isSelComt;
    if (Array.isArray(info)) {
      info.forEach((j, i) => {
        html.push(
          <Col sm={12}>
            <label>Area</label>:
          </Col>,
          <Col sm={12}>
            <span>{j.area}</span>
          </Col>,
          <Col sm={12}>
            <label>UOM</label>:
          </Col>,
          <Col sm={12}>
            <span>{j.uom}</span>
          </Col>,
          <Col sm={12}>
            <label>Asset Value</label>:
          </Col>,
          <Col sm={12}>
            <span>{toCurrency(j.propertyValue)}</span>
            {isSelComt && (
              <Input
                maxLength="10"
                className="inline-input"
                value={j.newPropertyValue}
                disabled={this.props.disableSelComt}
                onChange={(e) => {
                  let value = e.target.value;
                  if(isNaN(value)) {
                    value='';
                  } else if(value) {
                    value = Number(value);
                  }
                  if (value > 9999999999) {
                    value = value.toString().slice(0, 10);
                  }
                  this.updateValues(value, i, "newPropertyValue");
                }}
              />
            )}
          </Col>,
          <Col sm={12}>
            <label>Remarks</label>:
          </Col>,
          <Col sm={12}>
            <span>{j.remarks}</span>
          </Col>
        );
      });
    }
    return html;
  };
  render() {
    let info = this.props.data;
    if(!info) {
      return (
        <ViewSurveyCard title="Plots Owned" {...this.props}>
          <Empty />
        </ViewSurveyCard>
      );
    }
    return (
      <div>
        <ViewSurveyCard title="Plots Owned" {...this.props}>
          {this.generateFieldData(info.plotsList)}
        </ViewSurveyCard>
      </div>
    );
  }
}

export default PlotsView;

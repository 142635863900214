import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select } from "antd";
import ReviewStep from "./ReviewStep";
import { formItemLayout, PLOT_OWN } from "../../utils/consts";
import { prepareReviewersFieldData } from "../../utils/util";
import { SELCOMT } from "../../../base/utils/roles";

const { Option } = Select;

class PlotForm extends React.Component {
  onFinish = (form) => {
    // console.log("Received values of form:", form);
    this.props.saveDetails(PLOT_OWN, form);
  };
  render() {
    let reviewData = this.props.review.reviewFormData[PLOT_OWN];
    if (!reviewData) {
      reviewData = {};
    }
    const isSelComt = this.props.isSelCommit;
    const fieldData = prepareReviewersFieldData(reviewData);
    return (
      <div id="plot-form" className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Plots Owned"
          onFinish={(e) => this.onFinish(e)}
          {...this.props}
        >
          <Form.List name="plotsList">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, idx) => (
                    <Row key={idx} gutter={[8, 16]}>
                      <Col sm={1}>
                        <span>{idx + 1}</span>
                      </Col>
                      <Col sm={22}>
                        <Row key={idx} gutter={[16, 8]}>
                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "area"]}
                              fieldKey={[field.fieldKey, "area"]}
                              label="Area"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^[1-9][0-9]*$/,
                                  message: "Please enter plot area",
                                },
                              ]}
                            >
                              <Input maxLength="5" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "uom"]}
                              fieldKey={[field.fieldKey, "uom"]}
                              label="UOM"
                              rules={[
                                {
                                  required: true,
                                  message: "Select Units of Measurement",
                                },
                              ]}
                            >
                              <Select placeholder="Select" disabled={isSelComt}>
                                <Option key="Acres" value="Acres">
                                  Acres
                                </Option>
                                <Option key="SqYd." value="SqYd.">
                                  SqYd.
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "propertyValue"]}
                              fieldKey={[field.fieldKey, "propertyValue"]}
                              label="Asset Value"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^[1-9][0-9]*$/,
                                  message: "Please enter asset value",
                                },
                              ]}
                            >
                              <Input maxLength="10" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          {isSelComt && (
                            <Col sm={6}>
                              <Form.Item
                                {...formItemLayout}
                                name={[field.name, "newPropertyValue"]}
                                fieldKey={[field.fieldKey, "newPropertyValue"]}
                                label="New Asset Value"
                              >
                                <Input maxLength="8" />
                              </Form.Item>
                            </Col>
                          )}
                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "remarks"]}
                              fieldKey={[field.fieldKey, "remarks"]}
                              label="Remarks"
                            >
                              <Input maxLength="100" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={1} style={{ textAlign: "center" }}>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add Plots
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </ReviewStep>
      </div>
    );
  }
}

export default PlotForm;

import { connect } from "react-redux";
import { fetchSchools, fetchOldDistricts, fetchMandalsByOldDistricts, fetchDistricts, fetchMandals, fetchCasteCategory, fetchExamCenters } from "../../base/actions/AppConfigActions";
import { searchApplications, exportApplications, changeStatus, getStatusSummary, getComments, getStatusSummaryBySchool, draftStudentDoc, saveDraftFiles, getAppHistory, applicationBulkApprove, deleteDrafts, getApplicationById } from "../../sampoorna-siksha/actions/SSActions";
import { isApplicationContainsText } from "../../sampoorna-siksha/utils/util";
import { uploadStudentDoc, saveApplication, updateImageAngle } from "../../student/actions/StudentActions";
import { RESET_MESSAGES, RESET_CONFIG } from "../../base/utils/const";
import AdminShortList from "../components/AdminShortList";
import {SS_SEARCH_FILTERS, SHOW_DETAILS, SHOW_COMMENTS, SHOW_HISTORY, SHOW_REASON_MODAL, SEARCH_APPLICATIONS, ATTMPT, SMSS } from "../../sampoorna-siksha/utils/consts";
import { shortListApplications } from "../actions/AdminActions";

const mapDispatchToProps = (dispatch, props) => {
    return {
        getOldDistricts() {
            dispatch(fetchOldDistricts());
        },
        getMandalsList(districtId) {
            dispatch(fetchMandalsByOldDistricts(districtId));
        },

        getSchoolsList(mandalId) {
            dispatch(fetchSchools(mandalId));
        },

        searchApplications(form) {
            form.status = [ATTMPT, SMSS];
            dispatch(searchApplications(form));
        },

        setSearchFilters(form) {
            dispatch({
                type: SS_SEARCH_FILTERS,
                payload: form
            });
        },

        offlineSearchApplication(form, allApplications) {
            this.setSearchFilters(form);
            if (form.searchText && form.searchText.length > 1) {
                const result = allApplications.filter(appl => {
                    return isApplicationContainsText(appl, form.searchText);
                });
                this.setSearchApplication(result);
            } else if (!form.searchText) {
                this.setSearchApplication(allApplications);
            }
        },

        setSearchApplication(applications) {
            dispatch({
                type: SEARCH_APPLICATIONS,
                payload: applications
            });
        },

        exportToExcel(filters) {
            dispatch(exportApplications(filters));
        },

        getApplicationDetails(application) {
            dispatch(getApplicationById(application.applicationId));
            dispatch({
                type: RESET_CONFIG
            })
        },

        uploadStudentDoc(applicationId, file, type) {
            // console.log('upload event ---------- ', file.file);
            dispatch(uploadStudentDoc(applicationId, file.file, type));
        },

        getDistricts() {
            dispatch(fetchDistricts());
        },

        getMandalsList(districtId) {
            dispatch(fetchMandals(districtId));
        },

        getSchoolsList(mandalId) {
            dispatch(fetchSchools(mandalId));
        },

        getCastes() {
            dispatch(fetchCasteCategory());
        },

        getExamCenters() {
            dispatch(fetchExamCenters());
        },

        resetMessage() {
            dispatch({
                type: RESET_MESSAGES
            });
        },

        changeStatus(reasonView, filters, application) {
            dispatch(changeStatus(reasonView)).then(res => {
                this.searchApplications(filters);
            });
        },

        shortListApplications(hallTickets, filters) {
            dispatch(shortListApplications(hallTickets)).then(res => {
                this.searchApplications(filters);
            });
        },

        getStatusSummary(year) {
            dispatch(getStatusSummary(year));
        },

        saveMssApplicationForm(form, application, username, filters) {
            const newApplication = {
                ...form,
                type: 'MSS',
                username: username
            }

            if (application.applicationId) {
                newApplication.applicationId = application.applicationId;
                newApplication.year = application.year;
                newApplication.type = application.type;
                newApplication.username = application.username;
            }

            dispatch(saveApplication(newApplication)).then(res => {
                this.searchApplications(filters);
                this.getAppHistory(application.applicationId);
            });
            
        },

        toggleDetails(flag) {
            dispatch({
                type: SHOW_DETAILS,
                payload: flag
            });
        },

        toggleComments(flag) {
            dispatch({
                type: SHOW_COMMENTS,
                payload: flag
            });
        },

        toggleHistory(flag) {
            dispatch({
                type: SHOW_HISTORY,
                payload: flag
            });
        },

        toggleReasonModal(flag) {
            dispatch({
                type: SHOW_REASON_MODAL,
                payload: flag
            });
        },

        getComments(entityId, entityType) {
            dispatch(getComments(entityId, entityType));
        },

        getSchoolStatusSummary(schoolId, year) {
            dispatch(getStatusSummaryBySchool(schoolId, year));
        },

        draftUpload(applicationId, file, type) {
            dispatch(draftStudentDoc(applicationId, file, type));
        },

        saveDraftFiles(form, application, username, filters, uploadCatList) {
            dispatch(saveDraftFiles(application.applicationId, uploadCatList)).then(res => {
                this.saveMssApplicationForm(form, application, username, filters);
            });
        },

        getAppHistory(applicationId) {
            dispatch(getAppHistory(applicationId));
        },

        updateImageAngle(angle, uploadId) {
            dispatch(updateImageAngle(uploadId, angle));
        },

        deleteDraft(applicationId) {
            dispatch(deleteDrafts(applicationId));
        }
    };
};

const mapStateToProps = state => {
    const { AuthReducer, AdminReducer, SSReducer, AppConfigReducer, StudentReducer } = state;
    return {
        isLoading: AuthReducer.isLoading,
        profile: AuthReducer.profile,
        lang: AuthReducer.lang,
        role: AuthReducer.role,
        admin: AdminReducer,
        sampurnaSiksha: SSReducer,
        appConfig: AppConfigReducer,
        oldDistrictsList: AppConfigReducer.oldDistrictsList,
        districtsList: AppConfigReducer.districtsList,
        mandalsList: AppConfigReducer.mandalsList,
        schoolsList: AppConfigReducer.schoolsList,
        castesList: AppConfigReducer.castesList,
        examCenters: AppConfigReducer.examCenters,
        student: StudentReducer
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminShortList);
import { AFFL, CAT, COURSE, CUR, ED_TYP, GRADE, HOST, INST, REQ_CAT, REQ_SUB_CAT, SUB } from "./const";

export const getEntityNameByMeta = (meta) => {
      if (meta === SUB) {
        return 'Subject';
      } else if (meta === AFFL) {
        return 'Affiliation';
      } else if (meta === CAT) {
        return 'Category';
      } else if (meta === COURSE) {
        return 'Course';
      } else if (meta === CUR) {
        return 'Curicullum';
      } else if (meta === ED_TYP) {
        return 'Education Type';
      } else if (meta === INST) {
        return 'Institute';
      } else if (meta === HOST) {
        return 'Hostel';
      } else if (meta === REQ_CAT) {
        return 'Request Category';
      } else if (meta === REQ_SUB_CAT) {
        return 'Request Sub-Category';
      }  else if (meta === GRADE) {
        return 'Grading System';
      }
}
import { Button, Input, Select, Space, Modal, Form } from "antd";
import React from "react";
import { formItemLayout } from "../utils/consts";

class AddExamCenter extends React.Component {
  constructor() {
    super();
    this.closeModal = this.closeModal.bind(this);
    this.saveExamCenter = this.saveExamCenter.bind(this);
    this.deleteExamCenter = this.deleteExamCenter.bind(this);
  }
  saveExamCenter(form) {
    this.props.saveExamCenter(form);
  }
  deleteExamCenter(form) {
    this.props.deleteExamCenter(this.props.record.id, form.id);
  }
  closeModal() {
    this.props.toggleAddModal(false);
  }
  render() {
    const initValues = this.props.record;
    let title = "Add New Exam Center";
    if (this.props.action === "EDIT") {
      title = "Edit Exam Center Name";
    } else if (this.props.action === "DEL") {
      title = "Delete Exam Center";
    }
    return (
      <Modal
        title={title}
        visible={this.props.sampurnaSiksha.showAddExamCenterModal}
        onOk={this.saveExamCenter}
        onCancel={this.closeModal}
        maskClosable={false}
        footer={null}
      >
        <div>
          {this.props.action !== "DEL" && (
            <Form initialValues={initValues} onFinish={this.saveExamCenter}>
              <Form.Item name="id" hidden={true}>
                {" "}
                <Input />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="configVal"
                label="Exam Center Name"
                rules={[
                  {
                    required: true,
                    pattern: /^\S[A-Za-z0-9\\(\\)_,-\\+\s]+$/,
                    message: "Please enter a valid Exam Center Name",
                  },
                ]}
              >
                <Input maxLength="100" />
              </Form.Item>
              <Form.Item {...formItemLayout} name="parentId" label="District">
                <Select
                  className="full-width"
                  disabled={this.props.action === "EDIT"}
                >
                  {this.props.appConfig.oldDistrictsList.map((district) => (
                    <Select.Option key={district.id} value={district.id}>
                      {district.configVal}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="address"
                label="Exam Center Address"
                rules={[
                  {
                    required: true,
                    min: 10,
                    message: "Please enter a valid Address",
                  },
                ]}
              >
                <Input.TextArea maxLength="200" />
              </Form.Item>
              <div style={{ textAlign: "center" }}>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ marginRight: "5px" }}
                >
                  Save
                </Button>
                <Space />
                <Button onClick={this.closeModal}>Cancel</Button>
              </div>
            </Form>
          )}
        </div>
        <div>
          {this.props.action === "DEL" && (
            <Form onFinish={this.deleteExamCenter}>
              <p>Please select a new exam center to move students</p>
              <Form.Item
                name="id"
                label="Exam Center"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (
                        value &&
                        Number.isInteger(value) &&
                        value !== initValues.id
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Please select a valid exam center"
                      );
                    },
                  }),
                ]}
              >
                <Select
                  className="full-width wrapped_select"
                  disabled={this.props.action === "EDIT"}
                >
                  {this.props.sampurnaSiksha.examCentersList.map((center) => (
                    <Select.Option key={center.id} value={center.id}>
                      {center.configVal}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div style={{ textAlign: "center" }}>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ marginRight: "5px" }}
                >
                  Save
                </Button>
                <Space />
                <Button onClick={this.closeModal}>Cancel</Button>
              </div>
            </Form>
          )}
        </div>
      </Modal>
    );
  }
}

export default AddExamCenter;

import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Spin,
  Table,
  Select,
  Form,
  Radio,
} from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { translate } from "../../base/actions/translate";
import { exportFile } from "../../common/utils/export";
import { fetchAllAvailableBatchList } from "../../management/actions/studentList";
import { STUD_STATUSES } from "../../management/utils/const";
import {
  generateStudentId,
  getStatusString,
} from "../../management/utils/utils";
import { fetchMasterTableData } from "../../mastertables/actions/masterTables";
import { COURSE, HOST, INST } from "../../mastertables/utils/const";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import { STUD_RPT } from "../utils/const";

const STD_RPT_COLS = [
  {
    title: "Student ID",
    dataIndex: "studentId",
    key: "studentId",
    render: (text, record) => generateStudentId(record),
    sorter: {
      compare: (a, b) => sortNames(generateStudentId(a), generateStudentId(b)),
    },
  },
  {
    title: "Student Name",
    dataIndex: "studentName",
    key: "studentName",
    render: (text, record) => record.firstName + " " + record.lastName,
    sorter: {
      compare: (a, b) =>
        sortNames(
          a.firstName + " " + a.lastName,
          b.firstName + " " + b.lastName
        ),
    },
  },
  {
    title: "Batch",
    dataIndex: "batch",
    key: "batch",
    render: (text, record) => record.batch,
    sorter: {
      compare: (a, b) => a.batch - b.batch,
    },
  },
  {
    title: "Course Name",
    dataIndex: "courseName",
    key: "courseName",
    render: (text, record) => record.courses.join(", "),
    sorter: {
      compare: (a, b) =>
        sortNames((a.courses ? a.courses.join(",") : a.courses), (b.courses ? b.courses.join(",") : b.courses)),
    },
  },
  {
    title: "College Name",
    dataIndex: "instituteName",
    key: "instituteName",
    render: (text, record) => record.instituteNames.join(", "),
    sorter: {
      compare: (a, b) =>
        sortNames(a.instituteNames.join(","), b.instituteNames.join(",")),
    },
    width: "200px",
  },
  {
    title: "Hostel Name",
    dataIndex: "hostelName",
    key: "hostelName",
    render: (text, record) => record.hostelNames.join(", "),
    sorter: {
      compare: (a, b) =>
        sortNames(a.hostelNames.join(","), b.hostelNames.join(",")),
    },
    width: "200px",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
    render: (text, record) => translate(record.gender),
    sorter: {
      compare: (a, b) => sortNames(a.gender, b.gender),
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text, record) => getStatusString(record.status),
    sorter: {
      compare: (a, b) => a.status - b.status,
    },
  },
];

const STD_COUNT_RPT_COLS = [
  {
    title: "Batch",
    dataIndex: "batch",
    key: "batch",
    render: (text, record) => record.batch,
    sorter: {
      compare: (a, b) => a.batch - b.batch,
    },
  },
  {
    title: "Male",
    dataIndex: "male",
    key: "male",
    render: (text, record) => record.male,
    sorter: {
      compare: (a, b) => a.male - b.male,
    },
  },
  {
    title: "Female",
    dataIndex: "female",
    key: "emale",
    render: (text, record) => record.female,
    sorter: {
      compare: (a, b) => a.female - b.female,
    },
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    render: (text, record) => record.total,
    sorter: {
      compare: (a, b) => a.total - b.total,
    },
  },
];

const options = [
  { label: "Current", value: "true" },
  { label: "Previous", value: "false" },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const radioFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const multiFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const StudentsReports = (props) => {
  const [courses, setCourses] = React.useState([]);
  const [institutes, setInstitutes] = React.useState([]);
  const [hostels, setHostels] = React.useState([]);
  const [studentCount, setStudentCount] = React.useState([]);
  const [filterForm, setFilterForm] = React.useState({});
  const [showCount, setShowCount] = React.useState("false");

  const [form] = Form.useForm();

  useEffect(() => {
    props.fetchReports(STUD_RPT, {});
    props.fetchMasterTableData(INST);
    props.fetchMasterTableData(HOST);
    props.fetchMasterTableData(COURSE);
    props.fetchAllAvailableBatchList();
  }, []);

  useEffect(() => {
    setInstitutes(props.masterTable.institutes);
  }, [props.masterTable.institutes]);

  useEffect(() => {
    setCourses(props.masterTable.courses);
  }, [props.masterTable.courses]);

  useEffect(() => {
    setHostels(props.masterTable.hostels);
  }, [props.masterTable.hostels]);

  useEffect(() => {
    const studCount = [];
    if (props.reports.studentReport) {
      const batches = filterForm.batches
        ? filterForm.batches
        : props.manage.batches;
      for (const b of batches) {
        studCount.push({
          batch: b,
          male: props.reports.studentReport.filter(
            (s) => s.batch === b && s.gender === "M"
          ).length,
          female: props.reports.studentReport.filter(
            (s) => s.batch === b && s.gender === "F"
          ).length,
          total: props.reports.studentReport.filter((s) => s.batch === b)
            .length,
        });
      }
      console.log(batches);
    }
    setStudentCount(studCount);
  }, [props.reports.studentReport, props.manage.batches]);

  const exportData = () => {

    if(showCount === 'true') {
      exportFile(STD_COUNT_RPT_COLS, studentCount, "Students Report");
    } else {
      exportFile(STD_RPT_COLS, props.reports.studentReport, "Students Report");
    }
    
  };

  const searchStudentsList = (form) => {
    setFilterForm(form);
    props.fetchReports(STUD_RPT, form);
  };

  const populateCourses = (val) => {
    let courses = [];
    for (const cat of props.masterTable.institutes) {
      if (val.includes(cat.instituteId)) {
        for (const ic of cat.instituteCourses) {
          courses.push(ic.course);
        }
      }
    }
    if (!val) {
      courses = props.masterTable.courses;
    }
    setCourses(courses);
    if (form) {
      form.setFieldsValue({
        courseId: "",
      });
    }
  };

  const onReset = () => {
    form.resetFields();
    form.submit();
  };

  return (
    <React.Fragment>
      <div>
        <Card>
          <Form
            onFinish={searchStudentsList}
            initialValues={{
              currentHstlFlag: "true",
              currentInstFlag: "true",
              currentCourseFlag: "true",
            }}
            name="filter"
            form={form} 
          >
            <Row gutter={[16, 8]}>
              <Col xs={24} sm={8}>
                <Form.Item
                  name="instituteIds"
                  label="College"
                  {...multiFormItemLayout}
                >
                  <Select
                    showSearch
                    mode="multiple"
                    filterOption={(input, option) =>
                      option.children
                        .toUpperCase()
                        .includes(input.toUpperCase())
                    }
                    className="wrapped_select"
                    onChange={(val) => populateCourses(val)}
                  >
                    {institutes.map((cat) => (
                      <Select.Option
                        key={cat.instituteId}
                        value={cat.instituteId}
                      >
                        {cat.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item
                  name="currentInstFlag"
                  label=""
                  {...radioFormItemLayout}
                >
                  <Radio.Group options={options} optionType="button" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  name="courseIds"
                  label="Course"
                  {...multiFormItemLayout}
                >
                  <Select mode="multiple">
                    {courses.map((cat) => (
                      <Select.Option key={cat.courseId} value={cat.courseId}>
                        {cat.courseName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item
                  name="currentCourseFlag"
                  label=""
                  {...radioFormItemLayout}
                >
                  <Radio.Group options={options} optionType="button" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  name="hostelIds"
                  label="Hostel"
                  {...multiFormItemLayout}
                >
                  <Select
                    showSearch
                    mode="multiple"
                    filterOption={(input, option) =>
                      option.children
                        .toUpperCase()
                        .includes(input.toUpperCase())
                    }
                  >
                    {hostels.map((cat) => (
                      <Select.Option key={cat.hostelId} value={cat.hostelId}>
                        {cat.hostelName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item
                  name="currentHstlFlag"
                  label=""
                  {...radioFormItemLayout}
                >
                  <Radio.Group options={options} optionType="button" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item name="batches" label="Batch" {...formItemLayout}>
                  <Select mode="multiple">
                    {props.manage.batches
                    .sort((a, b) => sortNames(b, a)) //#637
                    .map((batch) => (
                      <Select.Option key={batch} value={batch}>
                        {batch}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item name="statuses" label="Status" {...formItemLayout}>
                  <Select mode="multiple">
                    {STUD_STATUSES.map((status) => (
                      <Select.Option key={status.key} value={status.key}>
                        {status.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Space>
                <Radio.Group
                  onChange={(e) => setShowCount(e.target.value)}
                  optionType="button"
                  value={showCount}
                >
                  <Radio.Button value="false">List</Radio.Button>
                  <Radio.Button value="true">Count</Radio.Button>
                </Radio.Group>
                <Button
                  type="primary"
                  onClick={(e) =>
                    exportData()
                  }
                >
                  Export
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="modal-form-submit-button"
                >
                  Search
                </Button>
                <Button htmlType="button" onClick={e => onReset(e)}>
                  Reset
                </Button>
              </Space>
            </div>
          </Form>
        </Card>
        <Card>
          <Spin spinning={props.reports.loading} delay={500}>
            <div>
              {showCount === "true" ? (
                <Table
                  className="row-selection"
                  columns={STD_COUNT_RPT_COLS}
                  dataSource={studentCount}
                />
              ) : (
                <Table
                  className="row-selection"
                  columns={STD_RPT_COLS}
                  dataSource={props.reports.studentReport}
                  pagination={{
                    showSizeChanger: true,
                    showTotal: () => {
                      const total = props.reports.studentReport.length;
                      return `Total ${total}`;
                    },
                  }}
                />
              )}
            </div>
          </Spin>
        </Card>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMasterTableData(dataType) {
      dispatch(fetchMasterTableData(dataType));
    },

    fetchAllAvailableBatchList() {
      dispatch(fetchAllAvailableBatchList());
    },
  };
};

const mapStateToProps = (state) => {
  const { MasterTablesReducer, ManagementReducer, ReportsReducer } = state;
  return {
    masterTable: MasterTablesReducer,
    manage: ManagementReducer,
    reports: ReportsReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentsReports);

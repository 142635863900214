import React from 'react';
import { Card, Row, Col } from 'antd';
import '../styles/mss.css';
import { VictoryBar, VictoryChart, VictoryAxis } from 'victory';
import { ArrowRightOutlined } from '@ant-design/icons';

class MedhaSampurnaSiksha extends React.Component {
    render() {
        return (
            <Card bordered={false} className="card-93 mg-l mss-main-container">
                <title>Medha Sampurna Siksha</title>
                <meta
                    name="description"
                    content="Up to 100 merit based scholarships are awarded each year to needy students to complete their Intermediate and Graduate education."
                />
                <div>
                    <span className="mss-disc-heading"> Medha Sampurna Siksha </span>
                    <div className="mhh-bar-chart">
                    <VictoryChart
                        domainPadding={{ x: 20 }}
                        style={{ width: 550 }}
                        animate={{
                            duration: 2000,
                            onLoad: { duration: 1000 }

                        }}
                    >
                        <VictoryBar
                            data={[
                                { experiment: "2012", actual: 29 },
                                { experiment: "2013", actual: 72 },
                                { experiment: "2014", actual: 120 },
                                { experiment: "2015", actual: 176 },
                                { experiment: "2016", actual: 238 },
                                { experiment: "2017", actual: 319 },
                                { experiment: "2018", actual: 404 },
                                { experiment: "2019", actual: 502 },
                                { experiment: "2021", actual: 704 }
                               
                            ]}
                            x="experiment"
                            y="actual"
                            style={{
                                data: { fill: "#8fe638" }
                            }}
                        />
                        <VictoryAxis
                            style={{
                                axisLabel: { padding: 30 }
                            }}
                        />
                        <VictoryAxis dependentAxis
                            style={{
                                axisLabel: { padding: 80 }
                            }}
                        />
                    </VictoryChart>
                    <h3>Number of students sponsored</h3>
                </div>
                    <div className="dotted-lines-mss">
                    <div className="">
                        <h1 className="colored-heading colored-heading-mss">Situation</h1>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">T</span>he most talented people in India who reside in rural areas have limited resources and exposure to grow, which hinders them from not reaching their true potential and we as a nation are facing a grave loss of unutilised talent.
                        </p>
                        <h1 className="colored-heading colored-heading-mss">Our Aim</h1>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">T</span>o provide required resources to merit students from government schools and provide guidance to increase student’s awareness and personally monitor their performance.
                        </p>
                        <h1 className="colored-heading colored-heading-mss">Our Solution</h1>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">A</span>fter extensive discussions with prominent members of government colleges, private colleges and government schools, a new scholarship programme was framed to help merit students complete their education till graduation. A scholarship exam to test the capability of each student was made an integral part of scholarship selection process. The exam conducted throughout Telangana every year receives applications from 20% of government schools each year. Economic evaluation of the toppers is personally conducted by our volunteers and staff members, by visiting every qualifying student’s house and gaining good understanding of each student’s desires, goals and their lifestyle. Finally a committee of mature individuals selects 100 students each year to grant scholarships. Over 100 employees of Medha group of companies, participate in this effort each year, to conduct exam, to evaluate the economic condition of students and to select the best candidates for granting scholarship.
                        </p>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">W</span>e join the new students in intermediate colleges and give them the best quality education, while making sure that they are well prepared for graduate entrance exams. Most of the students join the best central government or state government universities for graduate education, while the rest are enrolled in the highest ranking private graduate colleges in Telangana. We provide complete material support to the students along with taking care of their residential, medical and travel requirements. We also support them in learning the latest training tools used in their course of study.
                        </p>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">B</span>y studying each individual student’s learning capabilities and desires, we developed several roadmaps for students to follow. We created a mentorship programme through which all students have a mentor who provides them with the necessary exposure, carves the way for them to participate in various learning clubs, inculcates the idea of practical learning, teaches the importance of being disciplined and provides awareness regarding health and wellness. We shape the future of each individual based on their goals, in addition to providing the academic knowledge required to lead a self-sustainable life. As a result of our holistic approach and extremely dedicated mentors, many students who otherwise would’ve discontinued their education after intermediate by taking up low income jobs, are now working in some of the best organisations or have opted for post-graduate education in the best universities of India.
                        </p>
                    </div>
                    <h1 className="highlights-heading colored-heading colored-heading-mss"> Highlights</h1>
                    <p className="mss-highlights">
                        <span className="highlight-start"> Years:</span> 2012 – present<br></br>
                        <span className="highlight-start">Students Benefitted:</span> Over 700 students<br></br>
                        <span className="highlight-start"> Schools Targeted:</span> Students from Zilla Parishad High Schools,<br></br> Government High Schools,<br></br> Telangana Model Schools and <br></br>Telangana Minority Residential Schools (Gurukulam)<br></br> Mahatma Jyotiba Phule Schools (MJPTBCWREIS)<br></br>
                    </p>

                    <h1 className="highlights-heading colored-heading colored-heading-mss"> Sponsored Programmes</h1>
                    <Row className="">
                        <Col xs={24} sm={24}>
                            <p className="mss-interm">Intermediate Education: MPC, BiPC and MEC <br></br>Along with various graduate programmes</p> <br></br>

                        </Col>
                       
                        <Col xs={24} sm={8}>
                            <p>

                                <span className="mss-grad-head"> Graduate Programmes for MPC students:</span> <br></br>
                                <p className="prog-disc">
                                    <ArrowRightOutlined />  Bachelor of Engineering<br></br>
                                    <ArrowRightOutlined />  Bachelor of Technology<br></br>
                                    <ArrowRightOutlined />  Integrated Programme in Master of Science<br></br>
                                    <ArrowRightOutlined />  Integrated Programme in Master in Technology<br></br>
                                    <ArrowRightOutlined />  Bachelor of Science (various specialisations)<br></br>
                                </p>
                            </p>
                        </Col>
                        <Col xs={24} sm={8}>
                            <span className="mss-grad-head"> Graduate Programmes for BiPC students:</span> <br></br>
                            <p className="prog-disc">
                                <ArrowRightOutlined />  Bachelor of Medicine and Bachelor of Science<br></br>
                                <ArrowRightOutlined />  Bachelor of Homeopathic Medicine and Surgery<br></br>
                                <ArrowRightOutlined />  Bachelor of Dental Surgery<br></br>
                                <ArrowRightOutlined />  Bachelor of Veterinary Science and Animal Husbandry<br></br>
                                <ArrowRightOutlined />  Bachelor of Pharmacy<br></br>
                                <ArrowRightOutlined />  BSc. Nursing<br></br>
                                <ArrowRightOutlined />  BSc. Agriculture<br></br>
                                <ArrowRightOutlined />  BSc. Fisheries<br></br>
                                <ArrowRightOutlined />  BSc. Forestry<br></br>
                                <ArrowRightOutlined />  BSc. Life Sciences<br></br>
                                <ArrowRightOutlined />  BSc. Dairy Science<br></br>
                            </p>
                        </Col>
                        <Col xs={24} sm={8}>
                            <span className="mss-grad-head"> Graduate Programmes for MEC students:</span><br></br>
                            <p className="prog-disc">
                                <ArrowRightOutlined />  Chartered Accountancy<br></br>
                                <ArrowRightOutlined />  Bachelor of Commerce<br></br>
                            </p>
                        </Col>

                    </Row>
                    </div>

                </div>
            </Card>
        );
    }
}

export default MedhaSampurnaSiksha;
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { RESET_MESSAGES } from "../../base/utils/const";
import { fetchMasterTableData } from "../../mastertables/actions/masterTables";
import {
  fetchAllEnrollmentDetails,
  saveEnrollmentDetails,
} from "../actions/joining";
import Joining from "../components/Joining";
import { FETCH_ENROLL_DETAILS } from "../utils/const";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllEnrollmentDetails(year) {
      dispatch(fetchAllEnrollmentDetails(year));
    },

    saveEnrollmentDetails(enrolls, year) {
      dispatch(saveEnrollmentDetails(enrolls)).then((res) => {
        this.fetchAllEnrollmentDetails(year);
      });
    },

    fetchMasterTableData(dataType) {
      dispatch(fetchMasterTableData(dataType));
    },

    setEnrollData(enrolls) {
      dispatch({
        type: `${FETCH_ENROLL_DETAILS}_FULFILLED`,
        payload: enrolls,
      });
    },

    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    ManagementReducer,
    MasterTablesReducer,
  } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    manage: ManagementReducer,
    masterTable: MasterTablesReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Joining)
);

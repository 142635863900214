import { Button, Col, DatePicker, Input, Row, Select, Form, Modal, Spin } from "antd";
import React from "react";
import { formItemLayout } from "../../sampoorna-siksha/utils/consts";
import {
  COMPL,
  DROP,
  HIGH_EDU,
  JOB_OFR,
  JOB_SEARCH,
  JOIN,
  RJOIN,
  STATUS_MODAL,
} from "../utils/const";
import { getFutureStatusString, getStatusString } from "../utils/utils";
import moment from "moment";

const dateFormat = "DD/MM/YYYY";

class StatusUpdateModal extends React.Component {
  state = {
    status: null,
    futureStatus: null,
  };
  updateStatus = (v) => {
    this.setState({ status: v, futureStatus: null });
  };
  updateFutureStatus = (v) => {
    this.setState({ futureStatus: v });
  };
  getFutureStatuses = () => {
    const html = [];
    if (this.state.status === COMPL) {
      html.push(
        <Select.Option key={JOB_OFR} value={JOB_OFR}>
          {getFutureStatusString(JOB_OFR)}
        </Select.Option>,
        <Select.Option key={HIGH_EDU} value={HIGH_EDU}>
          {getFutureStatusString(HIGH_EDU)}
        </Select.Option>,
        <Select.Option key={JOB_SEARCH} value={JOB_SEARCH}>
          {getFutureStatusString(JOB_SEARCH)}
        </Select.Option>
      );
    }
    return html;
  };

  saveStatus = (form) => {
    console.log(form);
    const statusInfo = {
        studentProfileId: this.props.manage.currentProfile.studentProfileId,
        futureStatus: form.futureStatus,
        status: form.status,
        mobileNumber:form.mobileNumber,
        homeAddress: form.address,
        emailAddress: form.email,
        infoMap: form
    };

    this.props.saveStudentStatus(statusInfo, this.props.manage.searchForm);
  };

  render() {
    const currentStatus = this.props.manage.currentProfile.status;
    return (
      <div id="status_update_modal">
        <Modal
          title="Update Student Status"
          visible={this.props.manage.showModal === STATUS_MODAL}
          footer={null}
          onCancel={() => this.props.toogleAddModal("")}
          width={700}
          style={{ top: 20 }}
        >
          <Form onFinish={(form) => this.saveStatus(form)}>
            <Spin spinning={this.props.manage.loading}>
            <Row gutter={[16, 8]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  {...formItemLayout}
                  name="date"
                  label="Date"
                  rules={[{ required: true, message: "Please select date" }]}
                >
                  <DatePicker
                    format={dateFormat}
                    className="full-width"
                    showToday={false}
                    disabledDate={(current) => current > moment()}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  {...formItemLayout}
                  name="status"
                  label="Status"
                  rules={[{ required: true, message: "Please Select Status" }]}
                >
                  <Select onSelect={(e) => this.updateStatus(e)}>
                    {(currentStatus === JOIN || currentStatus === RJOIN) && (
                      <Select.Option key={COMPL} value={COMPL}>
                        {getStatusString(COMPL)}
                      </Select.Option>
                    )}
                    {(currentStatus === JOIN || currentStatus === RJOIN) && (
                      <Select.Option key={DROP} value={DROP}>
                        {getStatusString(DROP)}
                      </Select.Option>
                    )}
                    {currentStatus === DROP && (
                      <Select.Option key={RJOIN} value={RJOIN}>
                        {getStatusString(RJOIN)}
                      </Select.Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              {this.state.status === COMPL && (
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="futureStatus"
                    label="Future Status"
                    {...formItemLayout}
                    rules={[
                      {
                        required: true,
                        message: "Please select future status",
                      },
                    ]}
                  >
                    <Select
                      onSelect={(e) => this.updateFutureStatus(e)}
                      disabled={this.state.status === DROP}
                    >
                      {this.getFutureStatuses()}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={[16, 8]}>
              {this.state.futureStatus === JOB_OFR && (
                <>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      {...formItemLayout}
                      name="orgName"
                      label="Organisation Name"
                      rules={[
                        {
                          required: true,
                          // max: 30,
                          message: "Please enter organisation name",
                        },
                      ]}
                    >
                      <Input  maxLength="30"/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      {...formItemLayout}
                      name="designation"
                      label="Designation"
                      rules={[
                        {
                          required: true,
                          // max: 30,
                          message: "Please enter designation",
                        },
                      ]}
                    >
                      <Input maxLength="30" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      {...formItemLayout}
                      name="salary"
                      label="Monthly Salary"
                      rules={[
                        {
                          required: true,
                          pattern:/^[1-9][0-9]*$/,
                          // max: 30,
                          message: "Please enter monthly salary",
                        },
                      ]}
                    >
                      <Input maxLength="30" />
                    </Form.Item>
                  </Col>
                </>
              )}

              {this.state.futureStatus === HIGH_EDU && (
                <>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      {...formItemLayout}
                      name="instituteName"
                      label="Institution Name"
                      rules={[
                        {
                          required: true,
                          // max: 30,
                          message: "Please enter institution name",
                        },
                      ]}
                    >
                      <Input maxLength="30" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      {...formItemLayout}
                      name="study"
                      label="Programme of Study"
                      rules={[
                        {
                          required: true,
                          // max: 30,
                          message: "Please enter programme of study",
                        },
                      ]}
                    >
                      <Input  maxLength="30"/>
                    </Form.Item>
                  </Col>
                </>
              )}

              {this.state.status === DROP && (
                <>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      {...formItemLayout}
                      name="dropOutReason"
                      label="Drop Out Reason"
                      rules={[
                        {
                          required: true,
                          // max: 60,
                          message: "Please enter reason for drop out",
                        },
                      ]}
                    >
                      <Input.TextArea maxLength="60" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item {...formItemLayout} name="others" label="Others">
                      <Input />
                    </Form.Item>
                  </Col>
                </>
              )}

              {(this.state.status === JOIN || this.state.status === RJOIN) && (
                <>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      {...formItemLayout}
                      name="returnReason"
                      label="Reason for Return"
                      rules={[
                        {
                          required: true,
                          // max: 60,
                          message: "Please enter reason for return",
                        },
                      ]}
                    >
                      <Input.TextArea maxLength="60" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item {...formItemLayout} name="others" label="Others">
                      <Input />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <Row gutter={[16, 8]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  {...formItemLayout}
                  name="address"
                  label="Current Address"
                  rules={[
                    {
                      required: true,
                      max: 60,
                      min: 10,
                      message: "Please enter valid address",
                    },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  {...formItemLayout}
                  name="mobileNumber"
                  label="Mobile Number"
                  rules={[
                    {
                      required: true,
                      pattern: /^\d{10}$/,
                      message: "Please enter valid mobile number",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  {...formItemLayout}
                  name="email"
                  label="Email Address"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      max: 30,
                      message: "Please enter valid email address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ textAlign: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: 8 }}
                disabled={this.props.manage.loading}
              >
                Save
              </Button>
            </div>
            </Spin>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default StatusUpdateModal;

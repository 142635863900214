import { Button, Col, Form, Modal, Row, Select } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { MSSCC } from "../../base/utils/roles";
import { formItemLayout } from "../../sampoorna-siksha/utils/consts";
import { INCH_MODAL } from "../utils/const";

const InchargeUpdateModal = (props) => {
  const [inchargeId, setInchargeId] = useState(props.inchargeId);

  useEffect(() => {
    props.getUsersListByRole(MSSCC);
    //props.admin.usersList
  }, []);

  const updateIncharge = (val) => {
      setInchargeId(val);
  }

  const saveIncharge = (form) => {
      props.setIncharge(props.record.studentProfileId, form.inchargeId);
  }

  return (
    <div id="status_update_modal">
      <Modal
        title="Update Incharge"
        visible={props.manage.showModal === INCH_MODAL}
        footer={null}
        onCancel={() => props.toogleAddModal("")}
        width={700}
        style={{ top: 20 }}
      >
        <Form onFinish={(form) => saveIncharge(form)} initialValues={{inchargeId}}>
          <Row gutter={[16, 8]}>
            <Col sm={24}>
              <Form.Item
                {...formItemLayout}
                name="inchargeId"
                label="Incharge"
                rules={[{ required: true, message: "Please Select Incharge" }]}
              >
                <Select onSelect={(e) => updateIncharge(e)}>
                  {props.admin.usersList.map((user) => {
                    return (
                      <Select.Option key={user.userId} value={user.userId}>
                        {user.firstName + ' ' + user.lastName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default InchargeUpdateModal;
import React from 'react';
import { Row, Col } from 'antd';
import '../styles/verification.css';
import { translate } from '../../base/actions/translate';
import moment from 'moment';
import { getLoggedInUser } from '../../service/SessionStorageService';
import { TYPE_PDF } from '../util/const';
import { generateAppNum } from '../util/util';
import PDF_ICON from "../../assets/images/pdfIcon.png";

class Verification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedInUser: getLoggedInUser()
        };
    }

    componentWillMount() {
        if (this.props.profile) {
            this.props.getApplication(this.props.profile.username, moment().year());
        }
    }

    componentDidMount() {
        setTimeout(function () {
            window.scrollTo(0,0);
        }, 500);
    }

    getImageSrc(file) {
        if(file.type === TYPE_PDF) {
            return PDF_ICON;
        } else {
            return file.url;
        }
    }

    renderFields() {
        const { studentImage, studentIdProof, ninthMemoBack, ninthMemoFront } = this.props.studentData;
        const ele = [];
        if (studentImage.url) {

            ele.push(
                <div>
                    {/* <h3 className="verifyImageTopText">Please verify the documents uploaded.</h3> */}
                    <Row className="verifyImagefirst">
                        <Col xs={0} sm={2}>
                        </Col>
                        <Col xs={24} sm={8} className="text img-view-disc"><span className="formDisc">{translate('studentPassportPhoto')}</span></Col>
                        <Col xs={24} sm={10} className="verifyImagetext"><img src={studentImage.url} alt="Student Passport Photo" className="verifyImage" /></Col>
                        <Col xs={0} sm={4}>
                        </Col>
                    </Row>
                </div>
            );
        }

        if (studentIdProof.url) {

            ele.push(
                <Row>
                    <Col xs={0} sm={2}>
                    </Col>
                    <Col xs={24} sm={8} className="text img-view-disc"><span className="formDisc">{translate('StudentIdentificationForm')}</span></Col>
                    <Col xs={24} sm={10} className="verifyImagetext"><a href={studentIdProof.url} rel="noopener noreferrer" target="_blank"><img src={this.getImageSrc(studentIdProof)} alt="Student Id proof" className="verifyImage" /></a></Col>
                    <Col xs={0} sm={4}>
                    </Col>
                </Row>
            );
        }
        if (ninthMemoFront.url) {

            ele.push(
                <Row>
                    <Col xs={0} sm={2}>
                    </Col>
                    <Col xs={24} sm={8} className="text img-view-disc"><span className="formDisc">{translate('ninthMarksMemoFront')}</span></Col>
                    <Col xs={24} sm={10} className="verifyImagetext"><a href={ninthMemoFront.url} rel="noopener noreferrer" target="_blank"><img src={this.getImageSrc(ninthMemoFront)} alt="Ninth Memo Front" className="verifyImage" /></a></Col>
                    <Col xs={0} sm={4}>
                    </Col>
                </Row>
            );
        }
        if (ninthMemoBack.url) {

            ele.push(
                <Row>
                    <Col xs={0} sm={2}>
                    </Col>
                    <Col xs={24} sm={8} className="text img-view-disc" ><span className="formDisc">{translate('ninthMarksMemoBack')}</span></Col>
                    <Col xs={24} sm={10} className="verifyImagetext"><a href={ninthMemoBack.url} rel="noopener noreferrer" target="_blank"><img src={this.getImageSrc(ninthMemoBack)} alt="Ninth Memo Back" className="verifyImage" /></a></Col>
                    <Col xs={0} sm={4}>
                    </Col>
                </Row>
            );
        }

        return ele;
    }

    renderCaste(application) {
        return <span>{application.caste.configVal}</span>
    }

    renderDistrict(application) {
        return <span>{application.school.parent.parent.configVal}</span>
    }

    rendermandal(application) {
        return <span>{application.school.parent.configVal}</span>
    }

    renderSchool(application) {
        return <span>{application.school.configVal}</span>
    }

    renderExamCenters(application) {
        return <span>{application.examCenter.configVal}</span>
    }

    renderDate(date) {
        const dob = moment(date).format('DD/MM/YYYY');
        return <span>{dob}</span>
    }

    render() {
        const { applicationForm } = this.props.studentData;
        return (

            <div>
                {applicationForm.caste &&
                    <div>
                            <Row>
                            <Col xs={24} sm={20} className="pd-l text">
                                <div className="app-no-view">{translate('appNoView')} {generateAppNum(applicationForm)}
                                </div>
                              </Col>
                            </Row>
                        <Row>
                            <Col xs={0} sm={2}>
                            </Col>
                            <Col xs={24} sm={20}>
                                <Row justify="space-between">
                                    
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('studentName')} : </span> {applicationForm.studentName}
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('studentSurName')} :</span> {applicationForm.studentSurName}</div>
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('fatherName')} : </span> {applicationForm.fatherName}</div>
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('mothersName')} : </span> {applicationForm.motherName}</div>
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('dateOfBirth')} : </span> {this.renderDate(applicationForm.dateOfBirth)}</div>
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('alternatePhone')} : </span> {applicationForm.alternatePhone}</div>
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('casteId')} : </span> {this.renderCaste(applicationForm)}</div>
                                        {/* <div>{applicationForm.casteId}</div> */}
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('address')} : </span> {applicationForm.address}</div>
                                    </Col>

                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('gender')} : </span> {applicationForm.gender}</div>
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('ninthGrade')} : </span> {applicationForm.ninthGrade}</div>
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('medium')} : </span> {translate(applicationForm.medium)}</div>
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('headmasterName')} : </span> {applicationForm.headmasterName}</div>
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('headmasterPhone')} : </span> {applicationForm.headmasterPhone}</div>
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('district')} : </span>{this.renderDistrict(applicationForm)}</div>
                                        {/* <div>{applicationForm.district}</div> */}
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('mandal')} : </span> {this.rendermandal(applicationForm)}</div>
                                        {/* <div>{applicationForm.mandal}</div> */}
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('school')} : </span> {this.renderSchool(applicationForm)}</div>
                                        {/* <div>{applicationForm.school}</div> */}
                                    </Col>
                                    <Col xs={24} sm={12} className="pd-l text">
                                        <div><span className="formDisc">{translate('examCenter')} : </span> {this.renderExamCenters(applicationForm)}</div>
                                        {/* <div>{applicationForm.examCenter}</div> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={0} sm={2}>
                            </Col>
                        </Row>
                        {this.renderFields()}
                    </div>
                }
            </div>

        );
    }
}

export default Verification;
import { Col, Empty, Input } from "antd";
import React from "react";
import { toCurrency } from "../../../common/utils/util";
import { INC_SUM } from "../../utils/consts";
import ViewSurveyCard from "./ViewSurveyCard";

class IncomeSummaryView extends React.Component {
  updateValue = (value, key) => {
    // console.log(key, "--------------------", value);
    let info = this.props.data;
    info[key] = value;
    this.setInfo(info);
  };

  setInfo(info) {
    this.props.setCommitteeData(info, INC_SUM);
  }

  render() {
    let info = this.props.data;
    if(!info) {
      return (
        <ViewSurveyCard title="Income Summary" {...this.props}>
          <Empty />
        </ViewSurveyCard>
      );
    }
    const isSelComt = this.props.isSelComt;
    return (
      <div>
        <ViewSurveyCard title="Income Summary" {...this.props}>
          <Col sm={18}>
            <label>Total number of people at home</label>:
        </Col>
          <Col sm={6}>
            <span>{info.numberOfPeople}</span>
          </Col>
          <Col sm={18}>
            <label>Number of dependents in the family</label>:
        </Col>
          <Col sm={6}>
            <span>{info.numberOfDependents}</span>
          </Col>
          <Col sm={14}>
            <label>Total assets value</label>:
        </Col>
          <Col sm={10}>
            <span>{toCurrency(info.totalPropertyValue)}</span>
            {isSelComt && (
              <Input
                // maxLength="10"
                className="inline-input"
                value={toCurrency(info.newTotalPropertyValue)}
                onChange={(e) =>
                  this.updateValues(e.target.value, "newTotalPropertyValue")
                }
                disabled={true}
                style={{width:'120px'}}
              />
            )}
          </Col>
          <Col sm={14}>
            <label>Total family annual income</label>:
        </Col>
          <Col sm={10}>
            <span>{toCurrency(info.totalAnnualIncome)}</span>
            {isSelComt && (
              <Input
                // maxLength="10"
                className="inline-input"
                value={toCurrency(info.newTotalAnnualIncome)}
                onChange={(e) =>
                  this.updateValues(e.target.value, "newTotalAnnualIncome")
                }
                disabled={true}
                style={{width:'120px'}}
              />
            )}
          </Col>
          {/* {this.generateFieldData(info)} */}
        </ViewSurveyCard>
      </div>
    );
  }
}

export default IncomeSummaryView;

import { Button, Col, Form, Input, Modal, Row, Select, Spin } from "antd";
import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class AddCategoryModal extends React.Component {
  componentDidMount() {
    this.props.fetchEducationTypes();
  }
  saveCategory = (form) => {
    if (this.props.masterTable.currentRecord.noOfSems > form.noOfSemisters) {
      const that = this;
      Modal.confirm({
        title: `Reducing the semester count will remove all the data related the last ${form.noOfSemisters} semester(s). Do you want to proceed?`,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          that.props.saveEducationCategory(form);
        },
        onCancel() {},
        okText: "Yes",
        cancelText: "No",
      });
    } else {
      this.props.saveEducationCategory(form);
    }
  };
  render() {
    const record = this.props.masterTable.currentRecord;
    const fieldData = [
      {
        name: ["categoryId"],
        value: record.categoryId,
      },
      {
        name: ["educationId"],
        value: record.educationId,
      },
      {
        name: ["categoryName"],
        value: record.categoryName,
      },
      {
        name: ["year"],
        value: record.months ? parseInt(record.months / 12) : "",
      },
      {
        name: ["months"],
        value: record.months ? record.months % 12 : "",
      },
      {
        name: ["noOfSemisters"],
        value: record.noOfSems,
      },
      {
        name: ["notes"],
        value: record.notes,
      },
    ];
    const text = record.categoryId
      ? "Edit Education Category"
      : "Add Education Category";
    return (
      <div id="add-cat-modal">
        <Modal
          title={text}
          visible={this.props.masterTable.showAddCategory}
          footer={null}
          onCancel={() => this.props.toggleAddCatModal(false)}
        >
          <Spin spinning={this.props.masterTable.loading} delay={500}>
          <div className="form-container">
            {(this.props.masterTable.currentRecord.categoryId || !this.props.record.categoryId) ?
            <Form
              name="normal_login"
              className="login-form login-wrapper"
              onFinish={this.saveCategory}
              fields={fieldData}
            >
              <Form.Item {...formItemLayout} name="categoryId" hidden={true}>
                <Input />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="educationId"
                label="Education Category"
                rules={[
                  {
                    required: true,
                    message: "Please select an Education Category",
                  },
                ]}
              >
                <Select className="full-width wrapped_select">
                  {this.props.masterTable.educationTypes.map((type) => (
                    <Select.Option
                      key={type.educationId}
                      value={type.educationId}
                    >
                      {type.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="categoryName"
                label="Category Type"
                rules={[
                  {
                    required: true,
                    message: "Please enter an Education Category Type",
                    pattern: /^\S/,
                  },
                ]}
              >
                <Input maxLength="200" />
              </Form.Item>
              <Row>
                <Col sm={8} className="ant-form-item-label">
                  <label>Duration</label>
                </Col>
                <Col sm={8} style={{ paddingRight: "10px" }}>
                  <Form.Item
                    name="year"
                    label="Years"
                    rules={[
                      {
                        required: true,
                        pattern: /^([0-6])$/,
                        message:
                          "Please enter number of years. '0' if not applicable",
                      },
                    ]}
                  >
                    <Input maxLength="4" />
                  </Form.Item>
                </Col>
                <Col sm={8}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.year !== currentValues.year
                    }
                  >
                    {({ getFieldValue }) => {
                      const years = getFieldValue("year")
                        ? parseInt(getFieldValue("year"))
                        : 0;

                      return (
                        <Form.Item
                          name="months"
                          label="Months"
                          rules={[
                            {
                              required: true,
                              pattern: /^([0-9]|10|11)$/,
                              message:
                                "Please enter number of months. '0' if not applicable",
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  !value ||
                                  (value &&
                                    ((years === 0 && parseInt(value) > 0) ||
                                      years > 0))
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  "Both Number of years and Months can not be 0."
                                );
                              },
                            }),
                          ]}
                        >
                          <Input maxLength="2" />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                {...formItemLayout}
                name="noOfSemisters"
                label="Total Semesters"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid number of semesters.",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if ( value && (value <= 0 || value > 99)) {
                        return Promise.reject(
                          "Please enter valid number of semesters."
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item {...formItemLayout} name="notes" label="Description">
                <Input.TextArea maxLength="100" />
              </Form.Item>
              <div className="modal-form-button-div">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="modal-form-submit-button"
                  disabled={this.props.masterTable.loading}
                >
                  Save
                </Button>
              </div>
            </Form>
            :''}
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default AddCategoryModal;

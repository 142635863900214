export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const LOGOUT = 'LOGOUT';
export const LANG_CHANGE = 'LANG_CHANGE';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';

export const FETCH_OLD_DISTRICTS = 'FETCH_OLD_DISTRICTS';
export const FETCH_DISTRICTS = 'FETCH_DISTRICTS';
export const FETCH_MANDALS = 'FETCH_MANDALS';
export const FETCH_SCHOOLS = 'FETCH_SCHOOLS';
export const FETCH_CASTE = 'FETCH_CASTE';
export const FETCH_EXAM_CENTERS = 'FETCH_EXAM_CENTERS';
export const RESET_CONFIG = 'RESET_CONFIG';

export const TOGGLE_LOGIN_MODAL = 'TOGGLE_LOGIN_MODAL';
export const TOGGLE_FORGOT_PWD = 'TOGGLE_FORGOT_PWD';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';

export const RESET_MESSAGES = 'RESET_MESSAGES';
export const RESET_DATA = 'RESET_DATA';
export const RESET_CURR_UPLD = 'RESET_CURR_UPLD';

export const GET_SYS_CONFIG = 'GET_SYS_CONFIG';
export const SAVE_SYS_CONFIG = 'SAVE_SYS_CONFIG';

export const FETCH_CONFIG = 'FETCH_CONFIG';
export const SAVE_CONFIG = 'SAVE_CONFIG';
export const DEL_CONFIG = 'DEL_CONFIG';

export const TYPE_PDF = 'application/pdf';

// Task List const
export const CREATE_TASK = 'CREATE_TASK';
import { RESET_DATA, RESET_MESSAGES } from "../../base/utils/const";
import {
  GET_TASK_COMMENTS,
  MED_EVENT,
  MON_EVENT,
  WEL_EVENT,
} from "../../common/utils/const";
import { TOGGLE_ADD_MODAL } from "../../mastertables/utils/const";
import { ALL_SURVEY_UPLOAD_CAT } from "../../sampoorna-siksha/utils/consts";
import {
  AVAILABLE_BATCHES,
  DEL_MED_REQ,
  DEL_MON_REQ,
  DEL_STUD_HSTL,
  DEL_STUD_HSTL_CMNTS,
  DEL_WEL_REQ,
  FETCH_ENROLL_DETAILS,
  GET_EXP,
  GET_INCHARGE,
  GET_MED_REQ,
  GET_MON_REQ,
  GET_STUD_HSTL_CMNTS,
  GET_WEL_REQ,
  MON_TYP,
  SAVE_ENROLL_DETAILS,
  SAVE_MED_REQ,
  SAVE_MON_REQ,
  SAVE_STUD_ACAD,
  SAVE_STUD_HSTL,
  SAVE_STUD_HSTL_CMNTS,
  SAVE_STUD_PROFILE,
  SAVE_WEL_REQ,
  SEARCH_STUDENT_PROF,
  SET_CUR_REQ,
  SET_EDIT_FORM,
  SET_FILTERED_PROFILES,
  SET_INCHARGE,
  SET_SEARCH_FORM,
  STUD_ACAD,
  STUD_HSTL,
  STUD_PROFILE,
  STUD_STATUS,
  WEL_TYP,
} from "../utils/const";

const initialState = {
  loading: false,
  errorMessage: "",
  successMessage: "",
  enrollDetails: [],
  studentProfiles: [],
  filteredProfiles: [],
  batches: [],
  currentProfile: {},
  showModal: "",
  searchForm: {},
  editForm: "",
  hostelComments: [],
  incharge: null,
  currentRequest: {},
};

const ManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ENROLL_DETAILS}_PENDING`: {
      return {
        ...state,
        loading: true,
        enrollDetails: [],
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${FETCH_ENROLL_DETAILS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        enrollDetails: action.payload,
      };
    }

    case `${FETCH_ENROLL_DETAILS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${SAVE_ENROLL_DETAILS}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${SAVE_ENROLL_DETAILS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    }

    case `${SAVE_ENROLL_DETAILS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data.message,
      };
    }

    case `${SEARCH_STUDENT_PROF}_PENDING`: {
      return {
        ...state,
        loading: true,
        studentProfiles: [],
        filteredProfiles: [],
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${SEARCH_STUDENT_PROF}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        studentProfiles: action.payload,
        filteredProfiles: action.payload,
      };
    }

    case `${SEARCH_STUDENT_PROF}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case SET_FILTERED_PROFILES: {
      return {
        ...state,
        loading: false,
        filteredProfiles: action.payload,
      };
    }

    case `${AVAILABLE_BATCHES}_PENDING`: {
      return {
        ...state,
        loading: true,
        batches: [],
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${AVAILABLE_BATCHES}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        batches: action.payload,
      };
    }

    case `${AVAILABLE_BATCHES}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${STUD_PROFILE}_PENDING`: {
      return {
        ...state,
        loading: true,
        currentProfile: {},
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${STUD_PROFILE}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        currentProfile: action.payload,
      };
    }

    case `${STUD_HSTL}_REJECTED`:
    case `${STUD_ACAD}_REJECTED`:
    case `${STUD_PROFILE}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${STUD_ACAD}_PENDING`:
    case `${STUD_HSTL}_PENDING`: {
      return {
        ...state,
        loading: true,
        // currentProfile: {},
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${STUD_HSTL}_FULFILLED`: {
      const { currentProfile } = state;
      if (action.payload.studentHostelDetails) {
        currentProfile.studentHostelDetails =
          action.payload.studentHostelDetails;
      }
      return {
        ...state,
        loading: false,
        currentProfile,
      };
    }

    case `${STUD_ACAD}_FULFILLED`: {
      const { currentProfile } = state;
      if (action.payload.studentAcademics) {
        currentProfile.studentAcademics =
          action.payload.studentAcademics;
      }
      return {
        ...state,
        loading: false,
        currentProfile,
      };
    }

    case `${STUD_STATUS}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${STUD_STATUS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        showModal: "",
        currentProfile: action.payload,
        successMessage: "Student status updated successfully.",
      };
    }

    case `${STUD_STATUS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${SAVE_STUD_PROFILE}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${SAVE_STUD_PROFILE}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        showModal: "",
        editForm: "",
        successMessage: action.payload,
      };
    }

    case `${SAVE_STUD_PROFILE}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data?.message
          ? action.payload.data.message
          : action.payload.data,
      };
    }

    case `${SAVE_MED_REQ}_PENDING`:
    case `${SAVE_WEL_REQ}_PENDING`:
    case `${SAVE_MON_REQ}_PENDING`:
    case `${SET_INCHARGE}_PENDING`:
    case `${SAVE_STUD_HSTL}_PENDING`:
    case `${DEL_STUD_HSTL}_PENDING`:
    case `${SAVE_STUD_ACAD}_PENDING`:
    case `${SAVE_STUD_HSTL_CMNTS}_PENDING`:
    case `${DEL_STUD_HSTL_CMNTS}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${SAVE_MED_REQ}_FULFILLED`:
    case `${SAVE_WEL_REQ}_FULFILLED`:
    case `${SAVE_MON_REQ}_FULFILLED`:
    case `${DEL_MED_REQ}_FULFILLED`:
    case `${DEL_WEL_REQ}_FULFILLED`:
    case `${DEL_MON_REQ}_FULFILLED`:
    case `${SET_INCHARGE}_FULFILLED`:
    case `${SAVE_STUD_HSTL}_FULFILLED`:
    case `${DEL_STUD_HSTL}_FULFILLED`:
    case `${SAVE_STUD_ACAD}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        showModal: "",
        editForm: "",
        successMessage: action.payload,
      };
    }

    case `${SAVE_MED_REQ}_REJECTED`:
    case `${SAVE_WEL_REQ}_REJECTED`:
    case `${SAVE_MON_REQ}_REJECTED`:
    case `${SET_INCHARGE}_REJECTED`:
    case `${SAVE_STUD_HSTL}_REJECTED`:
    case `${DEL_STUD_HSTL}_REJECTED`:
    case `${SAVE_STUD_ACAD}_REJECTED`:
    case `${SAVE_STUD_HSTL_CMNTS}_REJECTED`:
    case `${DEL_STUD_HSTL_CMNTS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data?.message
          ? action.payload.data.message
          : action.payload.data,
      };
    }

    case `${SAVE_STUD_HSTL_CMNTS}_FULFILLED`:
    case `${DEL_STUD_HSTL_CMNTS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        editForm: "",
        successMessage: action.payload,
      };
    }

    case `${GET_STUD_HSTL_CMNTS}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
        hostelComments: [],
      };
    }

    case `${GET_STUD_HSTL_CMNTS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        hostelComments: action.payload,
      };
    }

    case `${GET_STUD_HSTL_CMNTS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data?.message
          ? action.payload.data.message
          : action.payload.data,
      };
    }

    case `${GET_MED_REQ}_PENDING`:
    case `${GET_WEL_REQ}_PENDING`:
    case `${GET_MON_REQ}_PENDING`:
    case `${GET_EXP}_PENDING`:
    case `${GET_INCHARGE}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
        incharge: null,
      };
    }

    case `${GET_INCHARGE}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        incharge: action.payload,
      };
    }

    case `${GET_MED_REQ}_REJECTED`:
    case `${GET_WEL_REQ}_REJECTED`:
    case `${GET_MON_REQ}_REJECTED`:
    case `${GET_EXP}_REJECTED`:
    case `${GET_INCHARGE}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data?.message
          ? action.payload.data.message
          : action.payload.data,
      };
    }

    case `${GET_MED_REQ}_FULFILLED`: {
      const profile = state.currentProfile;
      profile.medicalRequests = action.payload;
      return {
        ...state,
        loading: false,
        currentProfile: profile,
      };
    }

    case `${GET_WEL_REQ}_FULFILLED`: {
      const profile = state.currentProfile;
      profile.welfareRequests = action.payload;
      return {
        ...state,
        loading: false,
        currentProfile: profile,
      };
    }

    case `${GET_MON_REQ}_FULFILLED`: {
      const profile = state.currentProfile;
      profile.monitoryRequests = action.payload;
      return {
        ...state,
        loading: false,
        currentProfile: profile,
      };
    }

    case `${GET_EXP}_FULFILLED`: {
      const profile = state.currentProfile;
      profile.expenses = action.payload;
      return {
        ...state,
        loading: false,
        currentProfile: profile,
      };
    }

    case `${GET_TASK_COMMENTS}_FULFILLED`: {
      const entityMap = action.payload;
      const profile = state.currentProfile;
      if (entityMap && entityMap.task) {
        if (entityMap.entityType === MED_EVENT) {
          for (const med of profile.medicalRequests) {
            if (med.medicalRequestID === entityMap.entityId) {
              med.comments = entityMap.task.comments;
            }
          }
        } else if (entityMap.entityType === WEL_EVENT) {
          for (const med of profile.welfareRequests) {
            if (med.welfareRequestID === entityMap.entityId) {
              med.comments = entityMap.task.comments;
            }
          }
        } else if (entityMap.entityType === MON_EVENT) {
          for (const med of profile.monitoryRequests) {
            if (med.monitoryRequestID === entityMap.entityId) {
              med.comments = entityMap.task.comments;
            }
          }
        }
      }

      return {
        ...state,
        loading: false,
        currentProfile: profile,
      };
    }

    case SET_SEARCH_FORM: {
      return {
        ...state,
        searchForm: action.payload,
      };
    }

    case TOGGLE_ADD_MODAL: {
      return {
        ...state,
        showModal: action.payload,
        editForm: "",
      };
    }

    case SET_CUR_REQ: {
      return {
        ...state,
        currentRequest: action.payload
      }
    }

    case SET_EDIT_FORM: {
      return {
        ...state,
        editForm: action.payload,
      };
    }

    case RESET_DATA: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
        studentProfiles: [],
        filteredProfiles: [],
      };
    }

    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    }

    default:
      return state;
  }
};

export default ManagementReducer;

import { connect } from "react-redux";
import { fetchOldDistricts } from "../../base/actions/AppConfigActions";
import {
  getShortListedApplications,
  exportShortListedApplications,
  draftStudentDoc,
  deleteDrafts,
  searchApplications,
} from "../actions/SSActions";
import {
  SS_SEARCH_FILTERS,
  SHOW_DETAILS,
  SEARCH_APPLICATIONS,
  SET_STEP, ADD_TO_DEL_FILES, SHOW_PREVIEW_AFTER_SAVE, SET_STEP_BY_KEY, SET_FORM_STEPS, SMSS
} from "../utils/consts";
import { isApplicationContainsText } from "../utils/util";
import { updateImageAngle } from "../../student/actions/StudentActions";
import { RESET_MESSAGES } from "../../base/utils/const";
import StudentSelection from "../components/StudentSelection";
import {
  approveDrafts,
  complateSurvey,
  deleteFiles,
  draftSurveyImg,
  getApplicationReviewDetails,
  saveApplicationReview,
  uploadSurveyImg,
} from "../actions/ReviewActions";

const mapDispatchToProps = (dispatch, props) => {
  return {
    getOldDistricts() {
      dispatch(fetchOldDistricts());
    },

    searchApplications(form) {
      form.status = [SMSS];
      dispatch(searchApplications(form));
    },

    setSearchFilters(form) {
      dispatch({
        type: SS_SEARCH_FILTERS,
        payload: form,
      });
    },

    offlineSearchApplication(form, allApplications) {
      this.setSearchFilters(form);
      if (form.searchText && form.searchText.length > 1) {
        const result = allApplications.filter((appl) => {
          return isApplicationContainsText(appl, form.searchText);
        });
        this.setSearchApplication(result);
      } else if (!form.searchText) {
        this.setSearchApplication(allApplications);
      }
    },

    setSearchApplication(applications) {
      dispatch({
        type: SEARCH_APPLICATIONS,
        payload: applications,
      });
    },

    exportToExcel(filters) {
      dispatch(exportShortListedApplications(filters));
    },

    getApplicationReviewDetails(applicationId) {
      dispatch(getApplicationReviewDetails(applicationId));
    },

    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },

    saveApplicationReview(reviewForm) {
      dispatch(saveApplicationReview(reviewForm));
    },

    toggleDetails(flag) {
      dispatch({
        type: SHOW_DETAILS,
        payload: flag,
      });
    },

    setStep(step) {
      dispatch({
        type: SET_STEP,
        payload: step,
      });
    },

    setStepByKey(key) {
      dispatch({
        type: SET_STEP_BY_KEY,
        payload: key,
      });
    },

    setFormSteps(stepsList) {
      dispatch({
        type: SET_FORM_STEPS,
        payload: stepsList,
      });
    },

    showPreviewAfterSave(flag) {
      dispatch({
        type: SHOW_PREVIEW_AFTER_SAVE,
        payload: flag,
      });
    },

    updateImageAngle(angle, uploadId) {
      dispatch(updateImageAngle(uploadId, angle));
    },

    uploadSurveyImg(applicationId, files, type) {
      dispatch(uploadSurveyImg(applicationId, files, type));
    },

    draftUpload(applicationId, file, type) {
      dispatch(draftSurveyImg(applicationId, file, type));
    },

    deleteDraft(applicationId) {
      dispatch(deleteDrafts(applicationId));
    },

    deleteFiles(filesList) {
      dispatch(deleteFiles(filesList));
    },

    addToDeleteFiles(filesList) {
        dispatch({
            type: ADD_TO_DEL_FILES,
            payload: filesList
        })
    },

    approveDrafts(applicationId, filesList) {
      dispatch(approveDrafts(applicationId, filesList));
    },

    complateSurvey(applicationId, searchForm) {
      dispatch(complateSurvey(applicationId)).then(res => {
        this.searchApplications(searchForm);
    });;
    }
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    SSReducer,
    AppConfigReducer,
    StudentReducer,
    ReviewReducer,
  } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    sampurnaSiksha: SSReducer,
    appConfig: AppConfigReducer,
    oldDistrictsList: AppConfigReducer.oldDistrictsList,
    student: StudentReducer,
    review: ReviewReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentSelection);

import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Spin,
  Table,
  Select,
  Form,
  Radio,
} from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { translate } from "../../base/actions/translate";
import { ADMIN } from "../../base/utils/roles";
import {
  TASK_STATUSES,
  APRV,
  PEND,
  DEN,
  COMP,
  CLOS,
  MED_REQ_APPRV,
  WEL_REQ_APPRV,
  MON_REQ_APPRV,
  MARKS_APPRV,
} from "../../common/utils/const";
import { exportFile } from "../../common/utils/export";
import { searchBulkRequests } from "../../management/actions/bulkRequests";
import { REQ_TYPES } from "../../management/utils/const";
import { fetchMasterTableData } from "../../mastertables/actions/masterTables";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import { REQ_RPT } from "../utils/const";
import { getApprovalLevels } from "../../common/actions/TaskActions";

const REQ_LIST_COLS = [
  {
    title: "Student Name",
    dataIndex: "studentName",
    key: "studentName",
    render: (text, record) => record.firstName + " " + record.lastName,
    sorter: {
      compare: (a, b) =>
        sortNames(
          a.firstName + " " + a.lastName,
          b.firstName + " " + b.lastName
        ),
    },
  },
  {
    title: "Course",
    dataIndex: "course",
    key: "course",
    render: (text, record) => record.courses,
    sorter: {
      compare: (a, b) => sortNames(a.courses, b.courses),
    },
  },
  {
    title: "College",
    dataIndex: "college",
    key: "college",
    render: (text, record) => record.colleges,
    sorter: {
      compare: (a, b) => sortNames(a.colleges, b.colleges),
    },
  },
  {
    title: "Hostel",
    dataIndex: "hostel",
    key: "hostel",
    render: (text, record) => record.hostels,
    sorter: {
      compare: (a, b) => sortNames(a.hostels, b.hostels),
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (text, record) => record.amount,
    sorter: {
      compare: (a, b) => {
        if (a.amount && b.amount) {
          return Number(a.amount) - Number(b.amount);
        } else if (!a.amount) {
          return -1;
        } else if (!b.amount) {
          return 1;
        } else {
          return 0;
        }
      },
    },
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    render: (text, record) => record.category,
    sorter: {
      compare: (a, b) => sortNames(a.category, b.category),
    },
  },
  {
    title: "Sub Category",
    dataIndex: "subCategory",
    key: "subCategory",
    render: (text, record) => (record.subCategory ? record.subCategory : "N/A"),
    sorter: {
      compare: (a, b) => sortNames(a.subCategory, b.subCategory),
    },
  },
];

const REQ_COUNT_RPT_COLS = [
  {
    title: "Request Type",
    dataIndex: "requestType",
    key: "requestType",
    render: (text, record) => record.requestType,
    sorter: {
      compare: (a, b) => a.requestType - b.requestType,
    },
  },
  {
    title: "New",
    dataIndex: "new",
    key: "new",
    render: (text, record) => record.new,
    sorter: {
      compare: (a, b) => a.new - b.new,
    },
  },
  {
    title: "Denied",
    dataIndex: "denied",
    key: "denied",
    render: (text, record) => record.denied,
    sorter: {
      compare: (a, b) => a.denied - b.denied,
    },
  },
  {
    title: "Pending",
    dataIndex: "pending",
    key: "pending",
    render: (text, record) => record.pending,
    sorter: {
      compare: (a, b) => a.pending - b.pending,
    },
  },
  {
    title: "Approved",
    dataIndex: "approved",
    key: "approved",
    render: (text, record) => record.approved,
    sorter: {
      compare: (a, b) => a.approved - b.approved,
    },
  },
  {
    title: "Completed",
    dataIndex: "completed",
    key: "completed",
    render: (text, record) => record.completed,
    sorter: {
      compare: (a, b) => a.completed - b.completed,
    },
  },
  {
    title: "Closed",
    dataIndex: "closed",
    key: "closed",
    render: (text, record) => record.closed,
    sorter: {
      compare: (a, b) => a.closed - b.closed,
    },
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    render: (text, record) => record.total,
    sorter: {
      compare: (a, b) => a.total - b.total,
    },
  },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const RequestsReports = (props) => {
  const [requestCount, setRequestCount] = React.useState([]);
  const [filterForm, setFilterForm] = React.useState({});
  const [showCount, setShowCount] = React.useState("false");
  const [statuses, setStatuses] = React.useState(TASK_STATUSES);
  const [reqCat, setReqCat] = React.useState([]);
  const [subCatList, setSubCatList] = React.useState([]);
  const [approvalLevels, setApprovalLevels] = React.useState(
    props.tasks.approvalLevels
  );

  const [form] = Form.useForm();

  useEffect(() => {
    props.getReqCats();
    props.getApprovalLevels();
    props.fetchReports(REQ_RPT, {});
  }, []);

  useEffect(() => {
    if (props.tasks.approvalLevels.length > 0) {
      setApprovalLevels(props.tasks.approvalLevels);
    }
  }, [props.tasks.approvalLevels]);

  useEffect(() => {
    const reqCount = [];
    if (props.reports.requestReport) {
      for (const b of REQ_TYPES[ADMIN]) {
        reqCount.push({
          requestType: b.label,
          new: props.reports.requestReport.filter(
            (s) => s.entityType === b.key && s.assignedLevel === 1
          ).length,
          pending: props.reports.requestReport.filter(
            (s) =>
              s.entityType === b.key &&
              s.taskStatus === PEND &&
              s.assignedLevel !== 1
          ).length,
          denied: props.reports.requestReport.filter(
            (s) => s.entityType === b.key && s.taskStatus === DEN
          ).length,
          approved: props.reports.requestReport.filter(
            (s) => s.entityType === b.key && s.taskStatus === APRV
          ).length,
          completed: props.reports.requestReport.filter(
            (s) => s.entityType === b.key && s.taskStatus === COMP
          ).length,
          closed: props.reports.requestReport.filter(
            (s) => s.entityType === b.key && s.taskStatus === CLOS
          ).length,
          total: props.reports.requestReport.filter(
            (s) => s.entityType === b.key
          ).length,
        });
      }
    }
    setRequestCount(reqCount);
  }, [props.reports.requestReport]);

  const exportData = () => {
    if (showCount === "true") {
      exportFile(REQ_COUNT_RPT_COLS, requestCount, "Requests Report");
    } else {
      exportFile(REQ_LIST_COLS, props.reports.requestReport, "Requests Report");
    }
  };

  const searchRequestsList = (form) => {
    setFilterForm(form);
    props.fetchReports(REQ_RPT, form);
  };

  const onReset = () => {
    form.resetFields();
    form.submit();
  };

  const onChange = (reqType) => {
    // setRequestType(reqType);
    if (props.masterTable.reqCat.length > 0) {
      const cat = props.masterTable.reqCat.filter((c) =>
        reqType.includes(c.type)
      );
      setReqCat(cat);
      form.setFieldsValue({
        category: [],
        subCategory: [],
      });
    }
  };

  const onCatChanged = (cat) => {
    const fCats = props.masterTable.reqCat?.filter(
      (c) =>
        cat.includes(c.requestCategoryId) && c.requestSubCategories.length > 0
    );
    let sCats = [];
    for (const s of fCats) {
      if (s.requestSubCategories && s.requestSubCategories.length > 0) {
        sCats = sCats.concat(s.requestSubCategories);
      }
    }
    setSubCatList(sCats);
    form.setFieldsValue({
      subCategory: [],
    });
  };

  const getStatusContent = (apl, currentLevel, status, taskType) => {
    const html = [];
    let totalLevels = 0;
    for (const al of apl) {
      if (al.taskType === taskType) {
        totalLevels = al.levels;
      }
    }
    for (let i = 1; i <= totalLevels; i++) {
      if (i < currentLevel || (i < currentLevel && status === APRV)) {
        html.push(<span className="dot aprv"></span>);
      } else if (
        i === currentLevel &&
        status === COMP &&
        ![
          MED_REQ_APPRV.value,
          WEL_REQ_APPRV.value,
          MON_REQ_APPRV.value,
        ].includes(taskType)
      ) {
        html.push(<span className="dot aprv"></span>);
      } else if (taskType === MARKS_APPRV.value && status === APRV) {
        html.push(<span className="dot aprv"></span>);
      } else if (i === currentLevel && status === CLOS) {
        html.push(<span className="dot aprv"></span>);
      } else if (i === currentLevel && status === DEN) {
        html.push(<span className="dot den"></span>);
      } else {
        html.push(<span className="dot"></span>);
      }
    }
    return <div>{html}</div>;
  };

  if (REQ_LIST_COLS.length < 8 && approvalLevels.length > 0) {
    REQ_LIST_COLS.push({
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) =>
        getStatusContent(
          approvalLevels,
          record.assignedLevel,
          record.taskStatus,
          record.entityType + 1
        ),
      sorter: {
        compare: (a, b) => {
          if (a.assignedLevel !== b.assignedLevel) {
            return a.assignedLevel - b.assignedLevel;
          } else if (
            a.assignedLevel === b.assignedLevel &&
            a.status !== b.status
          ) {
            if (a.status === APRV || a.status === DEN) {
              return 1;
            } else if (b.status === APRV || b.status === DEN) {
              return -1;
            }
          } else if (
            a.assignedLevel === b.assignedLevel &&
            a.status === b.status
          ) {
            return 0;
          } else {
            return 0;
          }
        },
      },
    });
  }

  return (
    <React.Fragment>
      <div>
        <Card>
          <Form onFinish={searchRequestsList} name="filter" form={form}>
            <Row gutter={[16, 8]}>
              <Col xs={24} sm={6}>
                <Form.Item
                  {...formItemLayout}
                  name="entityTypes"
                  label="Task Category"
                >
                  <Select onChange={(e) => onChange(e)} mode="multiple">
                    {REQ_TYPES[ADMIN].map((type) => (
                      <Select.Option key={type.key} value={type.key}>
                        {type.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={6} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name="categories"
                  label="Category"
                >
                  <Select
                    className="full-width"
                    mode="multiple"
                    onChange={(e) => onCatChanged(e)}
                  >
                    {reqCat.map((typ) => (
                      <Select.Option
                        key={typ.requestCategoryId}
                        value={typ.requestCategoryId}
                      >
                        {typ.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col sm={6} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name="subCategories"
                  label="Sub-Category"
                >
                  <Select className="full-width" mode="multiple">
                    {subCatList.map((typ, i) => (
                      <Select.Option
                        key={typ.requestSubCategoryId}
                        value={typ.requestSubCategoryId}
                      >
                        {typ.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  {...formItemLayout}
                  name="taskStatuses"
                  label="Task Status"
                >
                  <Select mode="multiple">
                    {statuses.map((type) => (
                      <Select.Option key={type.value} value={type.value}>
                        {translate(type.label)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Space>
                <Radio.Group
                  onChange={(e) => setShowCount(e.target.value)}
                  optionType="button"
                  value={showCount}
                >
                  <Radio.Button value="false">List</Radio.Button>
                  <Radio.Button value="true">Count</Radio.Button>
                </Radio.Group>
                <Button type="primary" onClick={(e) => exportData()}>
                  Export
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="modal-form-submit-button"
                >
                  Search
                </Button>
                <Button htmlType="button" onClick={(e) => {onReset(e); setReqCat([]);setSubCatList([]);}}>
                  Reset
                </Button>
              </Space>
            </div>
          </Form>
        </Card>
        <Card>
          <Spin spinning={props.reports.loading} delay={500}>
            <div>
              {showCount === "true" ? (
                <Table
                  className="row-selection"
                  columns={REQ_COUNT_RPT_COLS}
                  dataSource={requestCount}
                />
              ) : props.tasks.approvalLevels.length > 0 ? (
                <Table
                  className="row-selection"
                  columns={REQ_LIST_COLS}
                  rowKey={(record) => record.taskId}
                  dataSource={props.reports.requestReport}
                  pagination={{
                    showSizeChanger: true,
                    showTotal: () => {
                      const total = props.reports.requestReport.length;
                      return `Total ${total}`;
                    },
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </Spin>
        </Card>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReqCats() {
      dispatch(fetchMasterTableData("reqCat"));
    },
    searchBulkRequests(form) {
      dispatch(searchBulkRequests(form));
    },
    getApprovalLevels() {
      dispatch(getApprovalLevels());
    },
  };
};

const mapStateToProps = (state) => {
  const {
    MasterTablesReducer,
    ManagementReducer,
    ReportsReducer,
    TaskReducer,
  } = state;
  return {
    masterTable: MasterTablesReducer,
    manage: ManagementReducer,
    reports: ReportsReducer,
    tasks: TaskReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsReports);

import { RESET_MESSAGES } from "../../base/utils/const";
import { FETCH_REPORTS, FIN_RPT, REQ_RPT, STUD_RPT } from "../utils/const";

const initialState = {
  loading: false,
  errorMessage: "",
  successMessage: "",
  studentReport: [],
  requestReport: [],
  financialReport: []
};

const ReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_REPORTS}_PENDING`: {
      return {
        ...state,
        loading: true,
        courses: [],
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${FETCH_REPORTS}_FULFILLED`: {
      let { studentReport, requestReport, financialReport } = state;

      if (action.meta === STUD_RPT) {
        studentReport = action.payload;
      } else if (action.meta === REQ_RPT) {
        requestReport = action.payload;
      } else if (action.meta === FIN_RPT) {
        financialReport = action.payload;
      }
      return {
        ...state,
        loading: false,
        studentReport,
        requestReport,
        financialReport
      };
    }

    case `${FETCH_REPORTS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }
    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    }
    default:
      return state;
  }
};

export default ReportsReducer;
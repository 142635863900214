import { Card, Row } from "antd";
import React from "react";

class ViewSurveyCard extends React.Component {
  render() {
    const {title, cardLess, stepKey, } = this.props;
    const canEdit = this.props.review.formSteps.includes(stepKey);
    return (
      <div className="view-survey-card">
        {!cardLess && (
          <Card
            title={title}
            extra={
              <span>
                {canEdit && (
                  <a href="#" onClick={(e) => {
                    e.preventDefault();
                    this.props.edit(stepKey);
                    }}>
                    Edit
                  </a>
                )}
              </span>
            }
          >
            <Row>{this.props.children}</Row>
          </Card>
        )}

        {cardLess && <Row>{this.props.children}</Row>}
      </div>
    );
  }
}

export default ViewSurveyCard;

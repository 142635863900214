import React, { useEffect } from "react";
import { connect } from "react-redux";
import StudentDetailsContainer from "../../../management/containers/StudentDetailsContainer";
import { APRV } from "../../utils/const";

const InteractionTemplate = (props) => {
 
  const intClose = () => {
    props.changeStatus(props.currentTask.taskId, APRV, props.currentTask);
    props.onClose();
  }

  return (
    <div>
      {props.tasks.currentTask.studentProfileId && (
        <StudentDetailsContainer
          record={props.tasks.currentTask}
          showDetails={props.showDetails}
          onClose={intClose}
          activeTab="6"
          //   {...props}
        />
      )}
    </div>
  );
};

export default InteractionTemplate;
// const mapDispatchToProps = (dispatch) => {
//     return {

//     };
//   };

//   const mapStateToProps = (state) => {
//     const {
//       AuthReducer,
//       AdminReducer,
//       MasterTablesReducer,
//       AppConfigReducer,
//       ManagementUploadReducer,
//       TaskReducer,
//     } = state;
//     return {
//       profile: AuthReducer.profile,
//       lang: AuthReducer.lang,
//       role: AuthReducer.role,
//       admin: AdminReducer,
//       masterTable: MasterTablesReducer,
//       appConfig: AppConfigReducer,
//       mgUploads: ManagementUploadReducer,
//       tasks: TaskReducer,
//     };
//   };

//   export default connect(mapStateToProps, mapDispatchToProps)(InteractionTemplate);

import React from "react";
import "../styles/base.css";
import { Button, Dropdown, Menu, Radio } from "antd";
import {
  UserOutlined,
  MenuOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import LoginModal from "./LoginModal";
import { STUDE } from "../utils/roles";
import NavbarContainer from "../containers/NavbarContainer";
import Text from "antd/lib/typography/Text";
import YouTubePlayer from "./videoPlayer";

class Header extends React.Component {
  state = {
    expandMenu: false,
    isDesktop: true,
    showVideo: true,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.openLogin = this.openLogin.bind(this);
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.props.checkUserLoggedIn();
    // window.addEventListener('scroll', this.handleScroll, true);
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillMount = () => {
    this.props.getSystemConfig();
  };

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener("resize", this.updatePredicate);
    this.props.getSystemConfig();
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 700 });
  }

  // handleScroll() {
  //     if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
  //         // document.getElementById("topNavbar").style.padding = "30px 10px";
  //         document.getElementById("logo").style.fontSize = "25px";
  //         document.getElementById("logoImg").style.height = "50px";
  //     } else {
  //         // document.getElementById("topNavbar").style.padding = "80px 10px";
  //         document.getElementById("logo").style.fontSize = "35px";
  //         document.getElementById("logoImg").style.height = "60px";
  //     }

  //     if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
  //         // document.getElementById("topNavbar").style.padding = "30px 10px";
  //         document.getElementById("burger-menu").style.height = "85px";
  //     } else {
  //         // document.getElementById("topNavbar").style.padding = "80px 10px";
  //         document.getElementById("burger-menu").style.height = "90px";
  //     }
  // }

  navigate = (item) => {
    if (item.key === "logout") {
      sessionStorage.clear();
      console.log("..........loggedout.........");
      window.location = "/login";
    } else {
      this.props.history.push(`/${item.key}`);
    }
  };
  getMenu = () => {
    const menu = (
      <Menu>
        <Menu.Item
          key="editProfile"
          icon={<UserOutlined />}
          onClick={this.navigate}
        >
          Edit Profile
        </Menu.Item>
        <Menu.Item
          key="logout"
          icon={<PoweroffOutlined />}
          onClick={this.navigate}
        >
          Logout
        </Menu.Item>
      </Menu>
    );
    return menu;
  };

  handleChange(event) {
    const value = event.target.value;
    sessionStorage.getItem("lang", value);
    this.props.setTranslate(value);
  }

  openLogin(event) {
    event.preventDefault();
    this.props.toggleLoginModal(true);
  }

  isCurrentRoute(route) {
    return route === window.location.pathname;
  }

  toggleMainMenu = () => {
    this.setState({ expandMenu: !this.state.expandMenu });
  };

  getVideoStatus = () => {
    const sysConfig = this.props.appConfig.systemConfig;
    for (const sc of sysConfig) {
      if (sc.configCd === "VIDEO_ST") {
        return sc.configVal === "Y"
          ? !this.props.isLoggedIn
            ? true
            : false
          : false;
      }
    }
    return false;
  };

  getVideoId = () => {
    const sysConfig = this.props.appConfig.systemConfig;
    for (const sc of sysConfig) {
      if (sc.configCd === "VIDEO_ID") {
        return sc.info;
      }
    }
    return "";
  };

  render() {
    return (
      <div>
        <div id="topNavbar">
          {this.props.appConfig.serverDown ? (
            <div id="serverDown">
              Server is under maintenance. Please try again after sometime.
              Thanks - Medha Charitable Trust
            </div>
          ) : this.getVideoStatus() ? (
            <div id="applyHint">
              Medha Sampurna Siksha scholarship exam application is now open
              <button
                className="videobtn"
                onClick={() => this.setState({ showVideo: true })}
              >
                click here
              </button>
              to know how to apply
            </div>
          ) : (
            ""
          )}
          {this.state.showVideo && (
            <YouTubePlayer
              open={this.getVideoStatus()}
              onClose={() => this.setState({ showVideo: false })}
              videoId={this.getVideoId()} //"zowv2M9AX9k"
            />
          )}
          <NavbarContainer
            expandMenu={this.state.expandMenu}
            toggleMainMenu={this.toggleMainMenu}
          ></NavbarContainer>
          <div className="topNavbar-main-logo" style={{ display: "inline" }}>
            <Button
              id="burger-menu"
              type="link"
              onClick={() => this.toggleMainMenu()}
            >
              <MenuOutlined />
            </Button>
            <Link to="/" id="logo">
              <img
                src="/MCT_logo.png"
                alt="Medha Chariable Trust"
                id="logoImg"
              ></img>
              <h1 className="inline-block app-name">Medha Charitable Trust</h1>
            </Link>
          </div>
          <div id="topNavbar-right">
            <div>
              <div className="navbar-tablet-right">
                {this.props.isLoggedIn && (
                  <Text
                    className="user-id-mobile "
                    style={{ color: "#fff", marginLeft: "10px" }}
                  >
                    Welcome{" "}
                    {this.props.currentProfile?.firstName
                      ? this.props.currentProfile?.firstName
                      : this.props.profile?.username}
                  </Text>
                )}
                {this.props.isLoggedIn && this.props.role === STUDE && (
                  <Radio.Group
                    className="lang-opt"
                    value={this.props.lang}
                    buttonStyle="solid"
                    onChange={this.handleChange}
                  >
                    <Radio.Button value="en">English</Radio.Button>
                    <Radio.Button value="te">తెలుగు</Radio.Button>
                  </Radio.Group>
                )}
              </div>
              <div className="tablet-login-button">
                {!this.props.isLoggedIn && (
                  <a href="/#" onClick={this.openLogin}>
                    <span className="round-button mobile-login-button">
                      <UserOutlined /> Login / Register
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
          {this.props.isLoggedIn && !this.state.isDesktop && (
            <div className="profile_drop_container">
              <Dropdown className="profile_drop" overlay={this.getMenu()}>
                <Button>
                  <UserOutlined />
                </Button>
              </Dropdown>
            </div>
          )}
        </div>
        <LoginModal {...this.props} />
      </div>
    );
  }
}

export default Header;

// YouTubePlayer.js
import React from "react";
import Modal from "antd/lib/modal/Modal";
import "antd/lib/modal/style/css"; // Import the styles for the Modal component
import YouTube from "react-youtube";

const YouTubePlayer = ({ open, onClose, videoId }) => {
  const opts = {
    width: "100%", // Adjusted width to fit the modal
    height: "250", // Adjusted height to fit the modal
  };

  return (
    <Modal
      title="MCT Exam Application Process"
      visible={open} // Use `visible` instead of `open`
      onCancel={onClose}
      footer={null}
      width={600} // Adjusted width of the modal (you can change it as needed)
    >
      <YouTube videoId={videoId} opts={opts} />
    </Modal>
  );
};

export default YouTubePlayer;

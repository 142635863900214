import { CREATE_TASK, RESET_MESSAGES } from "../../base/utils/const";
import { CHANGE_TASK_STATUS, GET_TASK_APRV_LVLS, GET_TASK_COMMENTS, SEARCH_TASK, TASK_DETAILS } from "../utils/const";
import { SAVE_MED_REQ, SAVE_WEL_REQ, SAVE_MON_REQ } from "../../management/utils/const";
const initialState = {
  taskList: [],
  currentTask: {},
  loading: false,
  successMessage: "",
  errorMessage: "",
  taskComments: [],
  approvalLevels: []
};

const TaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_TASK}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${CREATE_TASK}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload.data,
      };
    }

    case `${CREATE_TASK}_REJECTED`: {
      return {
        ...state,
        loading: true,
        errorMessage: action.payload.data,
      };
    }

    case `${SEARCH_TASK}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${SEARCH_TASK}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        taskList: action.payload,
      };
    }

    case `${SEARCH_TASK}_REJECTED`: {
      return {
        ...state,
        loading: true,
        errorMessage: action.payload.data,
      };
    }

    case `${TASK_DETAILS}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
        currentTask: {},
      };
    }

    case `${TASK_DETAILS}_FULFILLED`: {
      const currentTask = action.payload;
      if (currentTask.uploads) {
        for (const i of currentTask.uploads) {
          if(!i.studentProfileId)
          i.studentProfileId = currentTask.entity.studentProfileId;
        }
      }

      return {
        ...state,
        loading: false,
        currentTask: currentTask,
      };
    }

    case `${TASK_DETAILS}_REJECTED`: {
      return {
        ...state,
        loading: true,
        errorMessage: action.payload.data,
      };
    }

    case `${GET_TASK_COMMENTS}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
        taskComments: []
      };
    }

    case `${GET_TASK_COMMENTS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        taskComments: action.payload?.task.comments,
      };
    }

    case `${GET_TASK_COMMENTS}_REJECTED`: {
      return {
        ...state,
        loading: true,
        errorMessage: action.payload.data,
      };
    }

    case `${GET_TASK_APRV_LVLS}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
        approvalLevels: []
      };
    }

    case `${GET_TASK_APRV_LVLS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        approvalLevels: action.payload,
      };
    }

    case `${GET_TASK_APRV_LVLS}_REJECTED`: {
      return {
        ...state,
        loading: true,
        errorMessage: action.payload.data,
      };
    }

    case `${CHANGE_TASK_STATUS}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${CHANGE_TASK_STATUS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    }

    case `${CHANGE_TASK_STATUS}_REJECTED`: {
      return {
        ...state,
        loading: true,
        errorMessage: action.payload.data,
      };
    }

    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${SAVE_MED_REQ}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    }
    case `${SAVE_WEL_REQ}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    }
    case `${SAVE_MON_REQ}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    }
    default:
      return state;
  }
};

export default TaskReducer;

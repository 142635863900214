import { Col, Empty } from "antd";
import React from "react";
import { toCurrency } from "../../../common/utils/util";
import ViewSurveyCard from "./ViewSurveyCard";

class NeighbourRefView extends React.Component {
  generateFieldData = (personalInfo) => {
    const html = [];
    if (Array.isArray(personalInfo)) {
      for (const j of personalInfo) {
        html.push(
          <Col sm={6}>
            <label>Income Details</label>:
          </Col>,
          <Col sm={6}>
            {" "}
            <span>{toCurrency(j.income)}</span>
          </Col>,
          <Col sm={6}>
            <label>Property Details</label>:
          </Col>,
          <Col sm={6}>
            {" "}
            <span>{toCurrency(j.propertyDetails)}</span>
          </Col>,
          <Col sm={6}>
            <label>House</label>:
          </Col>,
          <Col sm={6}>
            {" "}
            <span>{toCurrency(j.house)}</span>
          </Col>,
          <Col sm={6}>
            <label>Others</label>:
          </Col>,
          <Col sm={6}>
            {" "}
            {/* #629 */}
            <span>{toCurrency(j.others)}</span>
          </Col>
        );
      }
    }
    return html;
  };
  render() {
    let info = this.props.data;
    if(!info) {
      return (
        <ViewSurveyCard title="Neighbour's Reference" {...this.props}>
          <Empty />
        </ViewSurveyCard>
      );
    }
    return (
      <div>
        <ViewSurveyCard title="Neighbour's Reference" {...this.props}>
          {this.generateFieldData(info.neighboursList)}
        </ViewSurveyCard>
      </div>
    );
  }
}

export default NeighbourRefView;

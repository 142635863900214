export const CREATE_TASK = "CREATE_TASK";
export const SEARCH_TASK = "SEARCH_TASK";
export const TASK_DETAILS = "TASK_DETAILS";
export const CHANGE_TASK_STATUS = "CHANGE_TASK_STATUS";
export const GET_TASK_COMMENTS = "GET_TASK_COMMENTS";
export const GET_TASK_APRV_LVLS = "GET_TASK_APRV_LVLS";

export const GET_NOTIF = "GET_NOTIF";
export const READ_NOTIF = "READ_NOTIF";

export const SAVE_MSG = "SAVE_MSG";
export const GET_MSG = "GET_MSG";

export const MARKS_EVENT = 1;
export const MED_EVENT = 2;
export const WEL_EVENT = 3;
export const MON_EVENT = 4;


export const MARKS_APPRV = {
  label: "MARKS_APPRV",
  value: 1,
};

export const INTERACTION = {
  label: "INTERACTION",
  value: 2,
};

export const MED_REQ_APPRV = {
  label: "MED_REQ_APPRV",
  value: 3
}

export const WEL_REQ_APPRV = {
  label: "WEL_REQ_APPRV",
  value: 4
}

export const MON_REQ_APPRV = {
  label: "MON_REQ_APPRV",
  value: 5
}

export const ALL_TASK_CAT = [MARKS_APPRV, INTERACTION, MED_REQ_APPRV, WEL_REQ_APPRV, MON_REQ_APPRV];

export const TASK_TYPES = {
  ADMIN: [MARKS_APPRV, INTERACTION, MED_REQ_APPRV, WEL_REQ_APPRV, MON_REQ_APPRV],
  MSSPC: [MARKS_APPRV, INTERACTION, MED_REQ_APPRV, WEL_REQ_APPRV, MON_REQ_APPRV],
  MSSCC: [MARKS_APPRV, INTERACTION, WEL_REQ_APPRV, MON_REQ_APPRV],
  MWCO: [MED_REQ_APPRV, WEL_REQ_APPRV],
  ACCEX: [MED_REQ_APPRV, WEL_REQ_APPRV, MON_REQ_APPRV],
  MGR: [MARKS_APPRV, INTERACTION, MED_REQ_APPRV, WEL_REQ_APPRV, MON_REQ_APPRV]
};

// Task staus id constants
export const APRV = 1;
export const PEND = 3;
export const DEN = 4;
export const COMP = 2;
export const CLOS = 5;

export const TASK_STATUSES = [
  {
    label: "PEND",
    value: PEND,
  },
  {
    label: "DEN",
    value: DEN,
  },
  {
    label: "APRV",
    value: APRV,
  },
  {
    label: 'COMP',
    value: COMP
  },
  {
    label: 'CLOS',
    value: CLOS
  }
];


// Interaction Uploads
export const MSG_ADD_TO_DEL_FILES = "MSG_ADD_TO_DEL_FILES";
export const MSG_DEL_FILES = "MSG_DEL_FILES";
export const MSG_APPROVED_DRAFTS = "MSG_APPROVED_DRAFTS";
export const MSG_DRAFT_IMG = "MSG_DRAFT_IMG";
export const MSG_ROTATE_IMG = "MSG_ROTATE_IMG";
export const MSG_DEL_DRAFT = "MSG_DEL_DRAFT";
export const LOAD_MORE_MSGS = "LOAD_MORE_MSGS";

// Interactions load type
export const INIT_LOAD = "INIT_LOAD";
export const ADD_LOAD = "ADD_LOAD";
export const LOAD_MORE = "LOAD_MORE";
export const MSG_MAX_LIMIT = 50;

export const DOC_EXTNS = ['odt', 'doc', 'docx'];
export const XSL_EXTNS = ['ods', 'xls', 'xlsx'];
export const PPT_EXTNS = ['odp', 'ppt', 'pptx'];
export const OFC_EXTNS = DOC_EXTNS.concat(XSL_EXTNS, PPT_EXTNS);
export const STUDE = 'STUDE';
export const STAFF = 'STAFF';
export const ADMIN = 'ADMIN';
export const ALL = 'ALL';
export const SUP1 = 'SUP1';
export const SUP2 = 'SUP2';
export const MGR = 'MGR';
export const MSSPC = 'MSSPC';
export const MSSCC = 'MSSCC';
export const MWCO = 'MWCO';
export const ACCEX = 'ACCEX';
export const DSTIN = 'DSTIN';
export const SELCOMT = 'SELCOMT';
export const MSSSTUD = 'MSSSTUD';

export const Roles = {
    SUP1 : 'Program Coordinator',
    SUP2 : 'Program Manager',
    ADMIN: 'GM',
    STUDE: 'Student',
    MGR: 'Manager',
    MSSPC: 'MSS Programme Coordinators',
    MSSCC: 'MSS Curriculum coordinators',
    MWCO: 'Medical and Welfare Coordinators',
    ACCEX: 'Accounts Executive',
    DSTIN: 'District Incharge',
    SELCOMT: 'Selection Committee',
    MSSSTUD: 'MSS Student'
}

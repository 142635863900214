import { axiosInstance } from "../../Interceptor";
import { UPLOAD_ACTN_TYPE, FETCH_ACTN_TYPE, ADD_SCHOOL_REQUEST, SAVE_APPLICATION, GET_APPLICATION, SUBMIT_APPLICATION, ROTATE_IMG, GET_ADD_SCHOOL_REQUEST } from "../util/const";

export const uploadStudentDoc = (applicationId, file, type) => {
    const url = `/student/upload`;
    const formData = new FormData();
    formData.append('file',file)
    formData.append('applicationId', applicationId);
    formData.append('type', type);
    return {
        type: UPLOAD_ACTN_TYPE[type],
        payload: axiosInstance.post(url, formData)
    };
}

export const getFile = (fileId, type) => {
    const url = `/student/upload/${fileId}`;
    return {
        type: FETCH_ACTN_TYPE[type],
        payload: axiosInstance.get(url)
    };
}

export const saveApplication = (applicationForm) => {
    const url = `/student/application`;
    return {
        type: SAVE_APPLICATION,
        payload: axiosInstance.post(url, applicationForm)
    };
}

export const submitApplication = (applicationForm) => {
    const url = `/student/application/submit`;
    return {
        type: SUBMIT_APPLICATION,
        payload: axiosInstance.post(url, applicationForm)
    };
}

export const getApplication = (year, username) => {
    const url = `/student/application/${year}/${username}`;
    return {
        type: GET_APPLICATION,
        payload: axiosInstance.get(url)
    };
}

export const requestAddSchool = (formValues) => {
    const url = `/workflow/addschool`;
    return {
        type: ADD_SCHOOL_REQUEST,
        payload: axiosInstance.post(url, formValues)
    }
}

export const getAddSchoolRequests = (userId) => {
    const url = `/workflow/addschool/${userId}`;
    return {
        type: GET_ADD_SCHOOL_REQUEST,
        payload: axiosInstance.get(url)
    }
}

export const updateImageAngle = (uploadId, angle) => {
    const data = {
      uploadId: uploadId,
      angle: angle
    };
    return {
      type: ROTATE_IMG,
      payload: axiosInstance.post(`/upload/rotate`, data),
    };
  };
import { Form, Input } from "antd";
import React from "react";
import { translate } from "../../../base/actions/translate";
import { formItemLayout, VOL_CMNTS } from "../../utils/consts";
import { prepareReviewersFieldData } from "../../utils/util";
import ReviewStep from "./ReviewStep";

const { TextArea } = Input;

class VolunteerComments extends React.Component {
  onFinish = (form) => {
    // console.log("Received values of form:", form);
    this.props.saveDetails(VOL_CMNTS, form);
  };
  render() {
    let reviewData = this.props.review.reviewFormData[VOL_CMNTS];
    if(!reviewData) {
      reviewData = {};
    } 
    const fieldData = prepareReviewersFieldData(reviewData);
    return (
      <div className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Volunteer’s Comments"
          onFinish={(e) => this.onFinish(e)}
          {...this.props}
        >
          <Form.Item
            {...formItemLayout}
            name="whyRequireMCT"
            label={translate("whyRequireMCT")}
            // getValueFromEvent={this.toCapital}
            rules={[
              {
                required: true,
                message: "Please enter details",
              },
            ]}
          >
            <TextArea rows={3} maxLength="200" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="isStudentActive"
            label={translate("isStudentActive")}
            // getValueFromEvent={this.toCapital}
            rules={[
              {
                required: true,
                message: "Please enter details",
              },
            ]}
          >
            <TextArea rows={3} maxLength="200" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="isParentsTruthful"
            label={translate("isParentsTruthful")}
            // getValueFromEvent={this.toCapital}
            rules={[
              {
                required: true,
                message: "Please enter details",
              },
            ]}
          >
            <TextArea rows={3} maxLength="200" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="finalObservation"
            label={translate("finalObservation")}
            // getValueFromEvent={this.toCapital}
            rules={[
              {
                required: true,
                message: "Please enter details",
              },
            ]}
          >
            <TextArea rows={3} maxLength="200" />
          </Form.Item>
        </ReviewStep>
      </div>
    );
  }
}

export default VolunteerComments;

import React from "react";
import { Button, Col, Form, Input, Modal, Row, Select, Spin } from "antd";
import { AFFL, CAT, INST } from "../utils/const";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class AddInstitute extends React.Component {
  formRef = React.createRef();

  componentDidMount() {
    // this.props.fetchEducationCategories();
    this.props.fetchMasterTableData(CAT);
    this.props.fetchMasterTableData(AFFL);
    // this.props.fetchMasterTableData(SUB);

    if (this.props.record?.educationId) {
      this.props.fetchCoursesByCatId(this.props.record?.educationId);
    }
    if (this.props.record?.affiliation) {
      this.setState({ affiliate: this.props.record?.affiliation });
    }
  }

  populateCourses = (val) => {
    this.props.fetchCoursesByCatId(val);
    // this.setState({ courses, onLoad: false });
    if (this.formRef.current) {
      this.formRef.current.setFieldsValue({
        courseId: [],
      });
    }
  };

  updateAffiliation = (val) => {
    let selAffl = {};
    for (const cat of this.props.masterTable.affiliations) {
      if (cat.affiliateId === val) {
        selAffl = cat;
      }
    }
    this.setState({ affiliate: selAffl });
  };

  saveInstitute = (form) => {
    const instituteCourses = [];
    for (const i of form.courseId) {
      instituteCourses.push({
        courseId: i,
      });
    }
    form.instituteCourses = instituteCourses;
    form.isCollege = "Y";
    console.log("-----------", form);
    this.props.saveMasterTableData(INST, form);
  };
  render() {
    const record = this.props.record;
    const initValues = this.props.record;
    const text = record.instituteId ? "Edit Institute" : "Add Institute";
    return (
      <div id="add-inst-modal">
        <Modal
          title={text}
          visible={this.props.masterTable.showModal === INST}
          footer={null}
          onCancel={() => this.props.toogleAddModal("")}
          width="90%"
        >
          <Spin spinning={this.props.masterTable.loading} delay={500}>
            <Form
              name="institute"
              className="login-form login-wrapper"
              onFinish={this.saveInstitute}
            //   fields={fieldData}
            initialValues={initValues}
              ref={this.formRef}
            >
              <Form.Item {...formItemLayout} name="instituteId" hidden={true}>
                <Input />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name={["instituteDetails", "instituteDetId"]}
                hidden={true}
              >
                <Input />
              </Form.Item>
              <Form.Item {...formItemLayout} name="isCollege" hidden={true}>
                <Input />
              </Form.Item>
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="instituteCode"
                    label="Institute Code"
                    rules={[
                      {
                        pattern: /^\S/,
                        message: "Please enter a valid Institute Code",
                      },
                    ]}
                  >
                    <Input maxLength="6" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="name"
                    label="Institute Name"
                    rules={[
                      {
                        required: true,
                        pattern: /^\S/,
                        message: "Please enter Institute Name",
                      },
                    ]}
                  >
                    <Input maxLength="500" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="affiliateId"
                    label="Affiliation"
                    rules={[
                      {
                        required: true,
                        message: "Please select an Affiliation",
                      },
                    ]}
                  >
                    <Select
                      className="full-width"
                      onChange={(val) => this.updateAffiliation(val)}
                    >
                      {this.props.masterTable.affiliations.map((cat) => (
                        <Select.Option
                          key={cat.affiliateId}
                          value={cat.affiliateId}
                        >
                          {cat.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="educationId"
                    label="Category Type"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Category Type",
                      },
                    ]}
                  >
                    <Select
                      className="full-width wrapped_select"
                      onChange={(val) => this.populateCourses(val)}
                    >
                      {this.props.masterTable.categories.map((cat) => (
                        <Select.Option
                          key={cat.categoryId}
                          value={cat.categoryId}
                        >
                          {cat.categoryName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="courseId"
                    label="Course"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Course",
                      },
                    ]}
                  >
                    <Select className="full-width" mode="multiple">
                      {this.props.masterTable.courses.map((cat) => (
                        <Select.Option key={cat.courseId} value={cat.courseId}>
                          {cat.courseName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="gender"
                    label="Students Gender"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Students Gender",
                      },
                    ]}
                  >
                    <Select className="full-width wrapped_select">
                      <Select.Option key="M" value="M">
                        Male
                      </Select.Option>
                      <Select.Option key="F" value="F">
                        Female
                      </Select.Option>
                      <Select.Option key="B" value="B">
                        Both
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name={["instituteDetails", "location"]}
                    label="Location"
                    rules={[
                        {
                          required: true,
                          pattern: /^\S/,
                          message: "Please enter the institute location",
                        },
                      ]}
                  >
                    <Input maxLength="200" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name={["instituteDetails", "contactPerson"]}
                    label="Contact Person"
                    rules={[
                        {
                          required: true,
                          pattern: /^\S[A-Za-z\s]+$/,
                          message: "Please provide a contact person",
                        },
                      ]}
                  >
                    <Input maxLength="50" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name={["instituteDetails", "phone1"]}
                    label="Phone 1"
                    rules={[
                      {
                        required: true,
                        pattern: /^\d+$/,
                        message: "Please enter a valid phone number",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name={["instituteDetails", "phone2"]}
                    label="Phone 2"
                    rules={[
                      {
                        pattern: /^\d+$/,
                        message: "Please enter a valid phone number",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name={["instituteDetails", "email1"]}
                    label="Email 1"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please enter a valid E-mail address",
                      },
                    ]}
                  >
                    <Input maxLength="50" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name={["instituteDetails", "email2"]}
                    label="Email 2"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid E-mail address",
                      },
                    ]}
                  >
                    <Input maxLength="25" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name={["instituteDetails", "website"]}
                    label="Website"
                    rules={[
                        {
                          required: true,
                          type: "url",
                          message: "Please enter a valid website address",
                        },
                      ]}
                  >
                    <Input maxLength="40" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name={["instituteDetails", "address"]}
                    label="Address"
                    rules={[
                        {
                          required: true,
                          min: 10,
                          pattern: /^\S/,
                          message: "Please enter a valid address",
                        },
                      ]}
                  >
                    <Input.TextArea maxLength="1000" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name={["instituteDetails", "panNumber"]}
                    label="PAN Number"
                    rules={[
                        {
                          // required: true,
                          min:10,
                          pattern: /^\S[A-Za-z0-9]+$/,
                          message: "Please enter a valid PAN Number",
                        },
                      ]}
                  >
                    <Input maxLength="10" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name={["instituteDetails", "bankName"]}
                    label="Bank Name"
                    rules={[
                        {
                          pattern: /^\S/,
                          message: "Please enter a valid Bank Name",
                        },
                      ]}
                  >
                    <Input maxLength="200" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name={["instituteDetails", "bankBranch"]}
                    label="Bank Branch"
                    rules={[
                        {
                          pattern: /^\S/,
                          message: "Please enter a valid Bank Branch",
                        },
                      ]}
                  >
                    <Input maxLength="200" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name={["instituteDetails", "bankAccountNumber"]}
                    label="Bank Account Number"
                    rules={[
                        {
                          pattern: /^\d*$/,
                          message: "Please enter a valid Bank Account number",
                        },
                      ]}
                  >
                    <Input maxLength="18" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name={["instituteDetails", "ifscCode"]}
                    label="Bank IFSC Code"
                    rules={[
                        {
                          len: 11,
                          message: "Please enter a valid IFSC Code",
                        },
                      ]}
                  >
                    <Input maxLength="11" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="modal-form-button-div">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="modal-form-submit-button"
                  disabled={this.props.masterTable.loading}
                >
                  Save
                </Button>
              </div>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default AddInstitute;

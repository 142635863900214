import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { ANIMAL_TYPES, CAT_OWN, formItemLayout } from "../../utils/consts";
import ReviewStep from "./ReviewStep";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { prepareReviewersFieldData } from "../../utils/util";
import { SELCOMT } from "../../../base/utils/roles";

const { Option } = Select;
const MAX_QUANTITY = 500;

class CattleForm extends React.Component {
  getCountOptions = () => {
    const html = [];
    for (let i = 0; i < MAX_QUANTITY; i++) {
      html.push(
        <Option key={i} value={i}>
          {i}
        </Option>
      );
    }
    return html;
  };

  onFinish = (form) => {
    // console.log("Received values of form:", form);
    this.props.saveDetails(CAT_OWN, form);
  };
  render() {
    let reviewData = this.props.review.reviewFormData[CAT_OWN];
    if (!reviewData) {
      reviewData = {};
    }
    const isSelComt = this.props.isSelCommit;
    const fieldData = prepareReviewersFieldData(reviewData);
    return (
      <div className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Animals Owned"
          onFinish={(e) => this.onFinish(e)}
          {...this.props}
        >
          <Form.List name="animalList">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, idx) => (
                    <Row key={idx} gutter={[8, 16]}>
                      <Col sm={1}>
                        <span>{idx + 1}</span>
                      </Col>
                      <Col sm={22}>
                        <Row key={idx} gutter={[16, 8]}>
                          <Col sm={12}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "animalType"]}
                              fieldKey={[field.fieldKey, "animalType"]}
                              label="Type of Animal"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select type of animal",
                                },
                              ]}
                            >
                              <Select placeholder="Select" disabled={isSelComt}>
                                {ANIMAL_TYPES.map((i) => (
                                  <Option key={i} value={i}>
                                    {i}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col sm={12}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "quantity"]}
                              fieldKey={[field.fieldKey, "quantity"]}
                              label="Quantity"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Quantity",
                                },
                              ]}
                            >
                              <Select placeholder="Select" disabled={isSelComt}>
                                {this.getCountOptions()}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col sm={12}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "propertyValue"]}
                              fieldKey={[field.fieldKey, "propertyValue"]}
                              label="Asset Value"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^[0-9][0-9]*$/,//#627
                                  message: "Please enter asset value",
                                },
                              ]}
                            >
                              <Input maxLength="10" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          {isSelComt && (
                            <Col sm={12}>
                              <Form.Item
                                {...formItemLayout}
                                name={[field.name, "newPropertyValue"]}
                                fieldKey={[field.fieldKey, "newPropertyValue"]}
                                label="New Asset Value"
                              >
                                <Input maxLength="8" />
                              </Form.Item>
                            </Col>
                          )}
                          <Col sm={12}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "annualIncome"]}
                              fieldKey={[field.fieldKey, "annualIncome"]}
                              label="Annual Income"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^[0-9][0-9]*$/,//#627
                                  message: "Please enter annual income",
                                },
                              ]}
                            >
                              <Input maxLength="8" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          {isSelComt && (
                            <Col sm={12}>
                              <Form.Item
                                {...formItemLayout}
                                name={[field.name, "newAnnualIncome"]}
                                fieldKey={[field.fieldKey, "newAnnualIncome"]}
                                label="New Annual Income"
                              >
                                <Input maxLength="8" />
                              </Form.Item>
                            </Col>
                          )}
                          {/* <Col sm={5}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "remarks"]}
                              fieldKey={[field.fieldKey, "remarks"]}
                              label="Remarks"
                            >
                              <Input maxLength="100" />
                            </Form.Item>
                          </Col> */}
                        </Row>
                      </Col>
                      <Col sm={1} style={{ textAlign: "center" }}>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add Animals Owned
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </ReviewStep>
      </div>
    );
  }
}

export default CattleForm;

import { OFC_EXTNS } from "../../common/utils/const";
import { IMAGE_TYPES } from "../../student/util/const";
import { TYPE_PDF } from "./const";

export const isPDF = (file) => {
  return file.type === TYPE_PDF;
};

export const normFile = (e) => {
  // console.log("Upload event:", e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList[0];
};

export const isValidImg = (file) => {
  const isJpgOrPng = IMAGE_TYPES.includes(file.type);
  const isLt500KB = file.size / 1024 < 500;
  return isJpgOrPng && isLt500KB;
}

export const isValidDoc = (file) => {
  const isJpgOrPngOrPdf =
    IMAGE_TYPES.includes(file.type) || file.type === "application/pdf";
  const isLt500KB = file.size / 1024 < 500;
  return isJpgOrPngOrPdf && isLt500KB;
}

export const isValidOfcFile = (file) => {
  const isJpgOrPngOrPdf =
    IMAGE_TYPES.includes(file.type) || file.type === "application/pdf";
  const isLt500KB = file.size / 1024 < 500;
  let validFile = false;
  for(const e of OFC_EXTNS) {
    if(file.name.indexOf(e) > -1) {
      validFile = true;
    }
  }
  return (isJpgOrPngOrPdf || validFile) && isLt500KB;
}
import React from "react";
import {
  Card,
  Form,
  Col,
  Select,
  Row,
  Button,
  Spin,
  Table,
  Input,
  Drawer,
  notification,
} from "antd";
import { translate } from "../../base/actions/translate";
import { sortNames } from "../utils/util";
import { DSTIN } from "../../base/utils/roles";
import ReviewerForm from "./ReviewerForm";
import { SURVEY_STEPS } from "../utils/consts";
import "../styles/survey.css";
// import XLSX from 'xlsx';

const { Option } = Select;
const { Search } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class ShortList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldData: {
        oldDistId: "",
        mandalId: "",
        schoolId: "",
        currentPage: 1,
        filters: {},
        sorter: {},
      },
      activePanels: "",
      currentRecord: {},
      showDetails: false,
      disableFields: true,
      reasonsList: [],
      showReasonModal: false,
      reason: {
        reason: "",
        otherReason: "",
        status: "",
        errorMsg: "",
      },
      currentIndex: 0,
      selectedRowKeys: [],
    };

    this.formRef = React.createRef();
  }

  componentWillMount() {
    this.props.getOldDistricts();
    this.searchApplications({ oldDistId: null });
    this.props.setFormSteps(SURVEY_STEPS);
  }

  componentWillUnmount() {
    this.props.toggleDetails(false);
  }

  onSelectChange = (selectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  toCapital = (e) => {
    if (e.target.value) {
      return e.target.value.toUpperCase();
    }
    return e.target.value;
  };

  searchApplications = (form) => {
    // console.log("-------", form);
    form.currentPage = 1;
    // form.pageSize = 10;
    // form.sortOrder = 'asc';
    // form.sortField = 'applicationId';
    form.filters = {};
    form.sorter = {};
    form.searchText = "";
    this.setState({ fieldData: form, activePanels: "" });
    this.props.searchApplications(form);
    this.props.setSearchFilters(form);
  };

  showDetails = (record, index) => {
    // console.log("-------------", record);
    this.setState({
      currentRecord: record,
      showDetails: true,
      currentIndex: index,
    });
    this.props.toggleDetails(true);
    // this.props.setStep(0);
    this.props.getApplicationReviewDetails(record.applicationId);
  };

  showNext = () => {
    const { currentIndex } = this.state;
    const dataSource = this.props.sampurnaSiksha.searchedApplications;
    const currentRec = dataSource[currentIndex + 1];
    // console.log("------------", currentRec.status);
    this.setState({
      currentRecord: currentRec,
      currentIndex: currentIndex + 1,
    });
    this.props.getApplicationReviewDetails(currentRec.applicationId);
    this.props.setStep(0);
  };

  showPrevious = () => {
    const { currentIndex } = this.state;
    const dataSource = this.props.sampurnaSiksha.searchedApplications;
    const currentRecord = dataSource[currentIndex - 1];
    this.setState({
      currentRecord: currentRecord,
      currentIndex: currentIndex - 1,
    });
    this.props.getApplicationReviewDetails(currentRecord.applicationId);
    this.props.setStep(0);
  };

  exportToExcel = () => {
    this.props.exportToExcel(this.props.sampurnaSiksha.ssSearchFilters);
  };

  onClose = () => {
    this.setState({ showDetails: false, disableFields: true });
    this.props.toggleDetails(false);
    // console.log("form ref.... : ", this.formRef);
    this.props.getOldDistricts();
  };

  enableFields = () => {
    this.setState({ disableFields: false });
  };

  bulkApproveApplications = () => {
    this.props.applicationBulkApprove(
      this.state.selectedRowKeys,
      this.props.sampurnaSiksha.ssSearchFilters
    );
    this.setState({ selectedRowKeys: [] });
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.currentPage = 1;
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
  };

  generateActionButtons = () => {
    return (
      <div style={{ textAlign: "right" }}>
        {/* <Button
          onClick={(e) => {
            this.formRef.current.submit();
          }}
          type="primary"
          style={{ marginRight: 8 }}
        >
          {" "}
          Save{" "}
        </Button> */}
        <Button onClick={this.onClose} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
    );
  };

  saveApplication = (form, step) => {
    this.props.saveApplicationReview(form, step);
  };

  submitSurvey = (applicationId) => {
    this.props.complateSurvey(applicationId, this.state.fieldData);
  }

  showNotifications = () => {
    let message = "";

    if (this.props.review.successMessage) {
      message += this.props.review.successMessage;
    }

    if (message) {
      notification.success({
        message: "Success",
        description: message,
      });
      this.props.resetMessage();
    }
  };

  offlineSearchApplication = (value) => {
    const filters = this.props.sampurnaSiksha.ssSearchFilters;
    filters.searchText = value.toUpperCase();
    this.props.offlineSearchApplication(
      filters,
      this.props.sampurnaSiksha.applications
    );
    this.setState({ fieldData: filters });
  };

  onFiltersChange = (pagination, filters, sorter, extra) => {
    // console.log(
    //   "on filter change ------ : ",
    //   pagination,
    //   filters,
    //   sorter,
    //   extra
    // );
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.filters = filters;
    fieldData.sorter = sorter;
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
    this.props.setSearchApplication(extra.currentDataSource);
  };

  onPageChange = (page, pageSize) => {
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.currentPage = page;
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
  };

  showTotal = () => {
    const total = this.props.sampurnaSiksha.searchedApplications.length;
    return `Total ${total} Applicants`;
  };

  getStatus = (appl) => {
    let status = 1;
    if(appl.selectionStatus) {
      status = 3;
    } else if(appl.surveyDetails.length > 0) {
      status = 2;
    }
    return status;
  }

  displayStatus = (appl) => {
    const html = [];
    const status = this.getStatus(appl);
    const clsName = status === 3 ? 'done' : status === 2 ? 'in-prog' : 'pend';
    for(let i = 0; i < 3; i++) {
      if(i < status) {
        html.push(<span className={"dot " + clsName} key={`${i}-key-status-${appl.applicationId}`}></span>);
      } else {
        html.push(<span className={"dot "} key={`${i}-key-status-${appl.applicationId}`}></span>);
      }
    }
    return html;
  }

  render() {
    const searchFilters = this.props.sampurnaSiksha.ssSearchFilters;
    const fieldData = [];
    for (let i in this.props.sampurnaSiksha.ssSearchFilters) {
      fieldData.push({
        name: [i],
        value: this.props.sampurnaSiksha.ssSearchFilters[i],
      });
    }

    const dataSource = this.props.sampurnaSiksha.searchedApplications;

    const USERS_LIST_COLS = [
      // {
      //   title: "S.No",
      //   dataIndex: "applicationId",
      //   key: "applicationId",
      //   render: (text, record, index) => index + 1,
      //   sorter: {
      //     compare: (a, b) => a.applicationId > b.applicationId,
      //   },
      //   sortOrder: sortedInfo.columnKey === "applicationId" && sortedInfo.order,
      // },
      {
        title: "Old District",
        dataIndex: "districtId",
        key: "districtId",
        render: (text, record) => record.school.parent.parent.parent.configVal,
        sorter: {
          compare: (a, b) =>
            sortNames(
              a.school.parent.parent.parent.configVal,
              b.school.parent.parent.parent.configVal
            ),
        },
      },
      {
        title: "Student's Name",
        dataIndex: "studentName",
        key: "studentName",
        render: (text, record) =>
          record.studentName + " " + record.studentSurName,
        sorter: {
          compare: (a, b) =>
            sortNames(
              a.studentName + " " + a.studentSurName,
              b.studentName + " " + b.studentSurName
            ),
        },
      },
      {
        title: "Survey Status",
        dataIndex: "surveyStatus",
        key: "surveyStatus",
        render: (text, record) =>
          this.displayStatus(record),
        sorter: {
          compare: (a, b) =>
            this.getStatus(a) - this.getStatus(b),
        },
      },
    ];
    return (
      <div>
        {this.props.role !== DSTIN && (
          <Card title="Survey Candidates List">
            <Form
              {...layout}
              onFinish={this.searchApplications}
              className="app-search-form"
            >
              <Row>
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="oldDistId"
                    label={translate("district")}
                  >
                    <Select className="full-width">
                      <Option>All</Option>
                      {this.props.oldDistrictsList.map((district) => (
                        <Option key={district.id} value={district.id}>
                          {district.configVal}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: "10px" }}
                    disabled={this.props.sampurnaSiksha.loading}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        )}

        <Card>
          <Search
            placeholder="Search"
            value={this.props.sampurnaSiksha.ssSearchFilters.searchText}
            onChange={(e) => this.offlineSearchApplication(e.target.value)}
            onSearch={(value) => this.offlineSearchApplication(value)}
            enterButton
            style={{ marginBottom: "10px", width: "85%", marginRight: "10px" }}
          />
          <Button
            onClick={this.exportToExcel}
            className="mg-l-l"
            loading={this.props.sampurnaSiksha.isExporting}
          >
            Export Excel
          </Button>
          <Spin
            spinning={
              this.props.sampurnaSiksha.loading &&
              !this.props.sampurnaSiksha.showDetails
            }
            delay={500}
          >
            <Table
              columns={USERS_LIST_COLS}
              className="row-selection"
              rowKey={(record) => record.applicationId}
              onChange={this.onFiltersChange}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (e) => {
                    this.showDetails(record, rowIndex, e);
                  }, // click row
                };
              }}
              dataSource={this.props.sampurnaSiksha.searchedApplications}
              pagination={{
                current: searchFilters.currentPage,
                onChange: this.onPageChange,
                showSizeChanger: true,
                showTotal: this.showTotal,
              }}
            />
          </Spin>
        </Card>

        <Drawer
          id="reviewers-details"
          title={
            <div>
              {this.props.review.reviewDetails.application ? 
              <h4 style={{display:'inline-block'}}>
                <strong style={{ marginLeft: "10px" }}>
                  Name:{" "}
                  {this.props.review.reviewDetails.application?.studentName +
                    " " +
                    this.props.review.reviewDetails.application?.studentSurName}
                </strong>
                <strong style={{ marginLeft: "10px" }}>
                  Registered Mobile Number:{" "}
                  {this.props.review.reviewDetails.application?.username}
                </strong>
                </h4> : ''}
                <Button
                  onClick={this.onClose}
                  style={{ marginRight: 8, float: "right" }}
                >
                  Close
                </Button>
                {this.state.currentIndex < dataSource.length - 1 && (
                  <Button
                    onClick={(e) => this.showNext()}
                    style={{ marginRight: 8, float: "right" }}
                  >
                    Next Profile
                  </Button>
                )}
                {this.state.currentIndex > 0 && (
                  <Button
                    onClick={(e) => this.showPrevious()}
                    style={{ marginRight: 8, float: "right" }}
                  >
                    Previous Profile
                  </Button>
                )}
              
            </div>
          }
          // footer={this.generateActionButtons()}
          width="100%"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.props.sampurnaSiksha.showDetails}
        >
          {this.props.review.reviewDetails.application?.applicationId && (
            <ReviewerForm
              formRef={this.formRef}
              currentRecord={this.state.currentRecord}
              saveApplication={this.saveApplication}
              submitSurveyForm={this.submitSurvey}
              {...this.props}
            ></ReviewerForm>
          )}
        </Drawer>
        {(this.props.review.successMessage ||
          this.props.sampurnaSiksha.successMessage) &&
          this.showNotifications()}
      </div>
    );
  }
}

export default ShortList;

import { axiosInstance } from "../../Interceptor";
import {
  APPROVED_DRAFTS,
  DEL_FILES,
  DRAFT_SURVEY_IMG,
  GET_REVIEW_DETAILS,
  MULT_SURVEY_IMG,
  SAVE_REVIEW,
  SUBMIT_SURVEY,
  SURVEYED_LIST,
  UPDATE_COMMITTEE_FEEDBACK,
  UPDATE_ENROL_STATUS,
} from "../utils/consts";

export const getApplicationReviewDetails = (applicationId) => {
  return {
    type: GET_REVIEW_DETAILS,
    payload: axiosInstance.get(`/survey/${applicationId}`),
  };
};

export const saveApplicationReview = (reviewForm) => {
  return {
    type: SAVE_REVIEW,
    payload: axiosInstance.post(`/survey/all`, reviewForm),
  };
};

export const deleteFiles = (filesList) => {
  return {
    type: DEL_FILES,
    payload: axiosInstance.post(`/upload/delete`, filesList),
  };
};

export const approveDrafts = (applicationId, filesList) => {
  return {
    type: APPROVED_DRAFTS,
    payload: axiosInstance.post(`/upload/approve/${applicationId}`, filesList),
  };
};

export const draftSurveyImg = (applicationId, file, type) => {
  const url = `/upload/draft`;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("applicationId", applicationId);
  formData.append("type", type);
  return {
    type: DRAFT_SURVEY_IMG,
    payload: axiosInstance.post(url, formData),
  };
};

export const uploadSurveyImg = (applicationId, files, type) => {
  const url = `/upload/multiple`;
  const formData = new FormData();
  for (var x = 0; x < files.length; x++) {
    formData.append("files["+x+"]", files[x]);
  }

  formData.append("applicationId", applicationId);
  formData.append("type", type);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return {
    type: MULT_SURVEY_IMG,
    payload: axiosInstance.post(url, formData, config),
  };
};

export const complateSurvey = (applicationId) => {
  return {
    type: SUBMIT_SURVEY,
    payload: axiosInstance.get(`/survey/submit/${applicationId}`),
  };
};

export const getSurveyedList = (form) => {
  return {
    type: SURVEYED_LIST,
    payload: axiosInstance.post(`/application/selection`, form),
  };
};

export const updateCommitteeFeedback = (feedbackList) => {
  return {
    type: UPDATE_COMMITTEE_FEEDBACK,
    payload: axiosInstance.post(`/selection/selectioncommittee`, feedbackList),
  };
};

export const updateApplicationStatus = (applIds, status) => {
  return {
    type: UPDATE_ENROL_STATUS,
    payload: axiosInstance.post(
      `/application/changeStatus/id/${status}`,
      applIds
    ),
  };
};

import React from "react";
import UploadMarksSheet from "./UploadMarksSheet";
import { PageHeader, Divider, Spin, Form, Row, Col, Upload, Button, Card, message } from "antd";
import { isPDF, normFile } from "../../base/utils/utils";
import { UploadOutlined } from "@ant-design/icons";
import MarksExcel from "../../assets/samples/MarksExcel.xlsx";

class DocumentsUpload extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
    };
  }

  componentWillMount = () => {
    this.props.getMarksConfig();
  };

  uploadMCTMarks = (form) => {
    // console.log("MCT form data :", form);
    this.props.uploadMCTMarksSheet(form);
  };

  uploadSSCMarks = (form) => {
    // console.log("SSC form data :", form);
    this.props.uploadSSCMarksSheet(form);
  };

  uploadSample = () => {
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('file', file);
    });
    formData.append('type', 'SAMPLE');
    this.props.uploadAdminDocs(formData);
    this.setState({fileList: []});
    this.formRef.current.reset();
  }

  render() {
    const { marksConfig } = this.props.sampurnaSiksha;

    const sscFieldData = [];
    const mctFieldData = [];

    for (const config of marksConfig) {
      if (config.configCd === "SSC_TOTAL") {
        sscFieldData.push({
          name: ["totalMarks"],
          value: config.configVal,
        });
      }
      if (config.configCd === "MCT_TOTAL") {
        mctFieldData.push({
          name: ["totalMarks"],
          value: config.configVal,
        });
      }
      if (config.configCd === "SSC_PERCENT") {
        sscFieldData.push({
          name: ["percentage"],
          value: config.configVal,
        });
      }
      if (config.configCd === "MCT_PERCENT") {
        mctFieldData.push({
          name: ["percentage"],
          value: config.configVal,
        });
      }
    }
    const { uploading, fileList } = this.state;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        if (!isPDF(file)) {
            message.error(`${file.name} is not a PDF file`);
            return false;
        }
        this.setState((state) => ({
          fileList: [file],
        }));
        return false;
      },
      fileList,
    };

    return (
      <div id="doc-upload-container">
        <Card id="doc-upload">
          <Spin spinning={this.props.sampurnaSiksha.loading || this.props.appConfig.loading}>
            <PageHeader className="site-page-header" title="Documents Upload" />
            <div id="ssc-marks-container">
              <Divider orientation="left">
                <h4>SSC Marks Sheet Upload</h4>
              </Divider>

              <UploadMarksSheet
                uploadMarksSheet={this.uploadSSCMarks}
                fieldData={sscFieldData}
              ></UploadMarksSheet>
            </div>
            <div id="mct-marks-container">
              <Divider orientation="left">
                <h4>MCT Marks Sheet Upload</h4>
              </Divider>
              <UploadMarksSheet
                uploadMarksSheet={this.uploadMCTMarks}
                fieldData={mctFieldData}
              ></UploadMarksSheet>
            </div>
            <div className="mg-l">
              Please{" "}
              <a href={MarksExcel} target="_blank" rel="noopener noreferrer">
                Click Here
              </a>{" "}
              form sample document.
            </div>

            <div id="sample-papers-container">
              <Divider orientation="left">
                <h4>Sample Exam Papers Upload</h4>
              </Divider>
              <Form name="samplePapers" onFinish={this.uploadSample} ef={this.formRef}>
                <Row>
                  <Col>
                    <Form.Item
                      name="file"
                      valuePropName="file"
                      getValueFromEvent={normFile}
                      label="Sample Exam Papers"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please provide sample exam papers.",
                        },
                      ]}
                    >
                      <Upload {...props}>
                        <Button>
                          <UploadOutlined /> Select PDF File (.pdf) only
                  </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Upload
          </Button>
              </Form>
            </div>

          </Spin>
        </Card>
      </div>
    );
  }
}

export default DocumentsUpload;

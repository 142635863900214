import { Button, Col, Input, message, Row, Switch, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { PaperClipOutlined, SendOutlined } from "@ant-design/icons";
import { MSSSTUD } from "../../base/utils/roles";
import { isValidDoc, isValidOfcFile } from "../../base/utils/utils";
import ImageViewer from "../../sampoorna-siksha/components/reviewSteps/ImageViewer";
import moment from "moment";
import "../styles/interactions.css";
import { LOAD_MORE } from "../utils/const";
import { withRouter } from "react-router";

const Interactions = (props) => {
  const [messages, setMessages] = useState("");
  const [showToStud, setShowToStud] = useState(1);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [prevCount, setPreviousCount] = useState(0);

  useEffect(() => {
    if (props.manage.currentProfile?.studentProfileId) {
      const profileId = props.manage.currentProfile?.studentProfileId;
      getInteractions(profileId);
    }
  }, [props.manage.currentProfile]);

  useEffect(() => {
    const uploadedFiles = [];
    for (const up of props.msgFiles.currentUploads) {
      if (up.status === "P" && !up.interactionId) {
        uploadedFiles.push(up);
      }
    }
    setUploadedFiles(uploadedFiles);
  }, [props.msgFiles.currentUploads]);

  useEffect(() => {
    const intervel = setInterval(function () {
      const profileId = props.manage.currentProfile?.studentProfileId;
      if (!document.getElementById('draggable-dialog-title')) {
        getInteractions(profileId);
      }
   }, 30000);
    return () => {
      clearInterval(intervel);
  }
  }, [props.manage.currentProfile]);

  const getInteractions = (profileId) => {
    props.getInteractions(profileId, 0);
    // setTimeout(function () {
    //   const loc = window.location.href;
    //   if (loc.indexOf("studentlist") > -1 || loc.indexOf("interactions") > -1) {
    //     getInteractions(profileId);
    //   }
    // }, 30000);
  };

  const setFiles = (e) => {
    const file = document.getElementById("myIntrFileB").files[0];
    if (!file) {
      return;
    }

    if (!isValidOfcFile(file)) {
      message.error("Please upload valid file less than 500 KB.");
      document.getElementById("myIntrFileB").value = "";
      return;
    }

    const currentProfile = props.manage.currentProfile;

    // Save the new image uploaded as a draft.
    props.draftIntrUpload(
      currentProfile.studentProfileId,
      file,
      "MSG_INTR",
      currentProfile.batch
    );
    document.getElementById("myIntrFileB").value = "";
  };

  const handleUpload = () => {
    document.getElementById("myIntrFileB").click();
  };

  const sendMessage = () => {
    if (messages.trim().length === 0 && props.msgFiles.currentUploads?.length === 0) {
      message.error("Please enter a message.");
      return;
    }
    const profileId = props.manage.currentProfile?.studentProfileId;
    const interaction = {
      studentId: profileId,
      message: messages,
      showToStud: showToStud,
    };
    const interactionFileIds = [];
    for (const i of props.msgFiles.currentUploads) {
      interactionFileIds.push(i.interactionFileId);
    }
    props.saveInteractions({ interaction, interactionFileIds });
    setMessages("");
  };

  const onClose = (e) => {
    props.onClose(e);
    props.setEditForm("");
  };

  const generateChatMessages = (messages) => {
    const html = messages.map((msg) => {
      if (msg.createdById === props.profile?.id) {
        return (
          <div key={"myMsgDiv" + msg.interactionId} className="my-message">
            <div className="chat-message me">
              <p className="chat-text">{msg.message}</p>
              {msg.attachments && (
                <div>
                  <ImageViewer
                    imageList={msg.attachments}
                    showActions={false}
                    loading={props.manage.loading}
                    year={props.manage.currentProfile?.batch}
                    hideMeta={true}
                    {...props}
                  />
                </div>
              )}
              <p className="chat-text chat-tim-stamp">
                {moment(msg.createdDate).format("DD-MM-YYYY hh:mm A")}
              </p>
              <p>{msg.showToStud === 0 ? <Tag color="red"> Hidden </Tag> : ""}</p>
            </div>
          </div>
        );
      } else {
        return (
          <div key={"myMsgDiv" + msg.interactionId} className="my-message">
            <div className="sent chat-message ">
              <p className="chat-text chat-user-name">{msg.createdByName}</p>
              <p className="chat-text chat-text-message">{msg.message}</p>
              {msg.attachments && (
                <div>
                  <ImageViewer
                    imageList={msg.attachments}
                    showActions={true}
                    loading={props.manage.loading}
                    year={props.manage.currentProfile?.batch}
                    hideMeta={true}
                    {...props}
                  />
                </div>
              )}
              <p className="chat-text chat-tim-stamp">
                {moment(msg.createdDate).format("DD-MM-YYYY hh:mm A")}
              </p>
              <p>{msg.showToStud === 0 ? <Tag color="#108ee9"> Hidden </Tag> : ""}</p>
            </div>
          </div>
        );
      }
    });
    if (!props.msgs.loading && props.msgs.messages.length > 0) {
      const currCount = props.msgs.messages.length;
      if (props.msgs.loadType !== LOAD_MORE) {
        setTimeout(() => {
          if (document.getElementById("messages-container")) {
            document.getElementById("messages-container").scrollTop =
              document.getElementById("messages-container").scrollHeight;
          }
        }, 300);
        if (currCount !== prevCount) {
          setPreviousCount(currCount);
        }
      } else if (prevCount > 0) {
        setTimeout(() => {
          if (
            document.getElementById("messages-container") &&
            document.getElementsByClassName("my-message").length &&
            currCount > prevCount + 1
          ) {
            document
              .getElementsByClassName("my-message")
              [currCount - (prevCount + 1)].scrollIntoView();
          }
          if (currCount !== prevCount) {
            setPreviousCount(currCount);
          }
        }, 300);
      }
    }
    return html;
  };

  const loadMore = () => {
    const profileId = props.manage.currentProfile?.studentProfileId;
    const lastMsgId = props.msgs.messages[0]?.interactionId;
    props.loadMoreInteractions(profileId, lastMsgId ? lastMsgId : 0);
  };

  return (
    <Row>
      <Col xs={2} sm={2}></Col>
      <Col xs={20} sm={20}>
        <div id="interaction-container">
          <div id="messages-container" className="chat">
            {props.msgs.hasMore && (
              <div style={{ textAlign: "center" }}>
                <Button onClick={(e) => loadMore()}>Load more</Button>
              </div>
            )}
            {generateChatMessages(props.msgs.messages)}
          </div>
          <div id="input-container">
            <Row className="input-cont-row">
              <Col xs={24} sm={24}>
                <Input.TextArea
                  placeholder="Please enter your message here. Maximum 250 characters."
                  value={messages}
                  onChange={(e) => setMessages(e.target.value)}
                  defaultChecked
                  maxLength={250}
                />
              </Col>
              <Col xs={24} sm={24}>
                <Button
                  className="paper-clip-button"
                  icon={<PaperClipOutlined />}
                  onClick={(e) => handleUpload()}
                ></Button>
                <Button
                  className="send-message-button"
                  type="primary"
                  icon={<SendOutlined />}
                  onClick={(e) => sendMessage()}
                >
                  Send
                </Button>
                {props.role !== MSSSTUD && (
                  <Button
                    onClick={(e) => onClose(e)}
                    className="chat-close-btn"
                  >
                    Close
                  </Button>
                )}
                {props.role === MSSSTUD && (
                  <Button
                    onClick={(e) => props.history.push("/")}
                    className="chat-close-btn"
                  >
                    Close
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <input
            type="file"
            id="myIntrFileB"
            style={{ display: "none" }}
            onChange={setFiles}
          ></input>
          <ImageViewer
            imageList={props.msgFiles.currentUploads}
            showActions={true}
            loading={props.manage.loading}
            year={props.manage.currentProfile?.batch}
            {...props}
            addToDeleteFiles={props.addToDeleteFiles}
          />
        </div>
      </Col>
      <Col xs={2} sm={2}></Col>
    </Row>
  );
};

export default withRouter(Interactions);

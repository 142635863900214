import React from "react";
import { Route } from "react-router-dom";
import { getLoggedInUser } from "../service/SessionStorageService";
import Unauthorized from "../base/components/Unauthorized";
import { useSelector } from "react-redux";

function RouteWithSubRoutes(route) {
  let role = useSelector((state) => state.AuthReducer.role);
  const loggedInUser = getLoggedInUser();
  if (!role && loggedInUser) {
    role = loggedInUser.role;
  }
  // console.log("---------router with useSelector---------", role);
  if (route.role.indexOf("ALL") > -1) {
    // console.log(".......ALL......Added...route.....", route);
    return (
      <Route path={route.path}>
        <route.component routes={route.routes}></route.component>
      </Route>
    );
  } else if (route.role.indexOf(role) > -1) {
    // console.log("......COND.......Added...route.....", route);
    return (
      <Route path={route.path}>
        <route.component routes={route.routes}></route.component>
      </Route>
    );
  } else {
    // console.log(".......ELSE......Not Added...route.....", route);
    return (
      <Route path={route.path}>
        <Unauthorized></Unauthorized>
      </Route>
    );
  }
}

export default RouteWithSubRoutes;

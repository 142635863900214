import { Button, Card, Col, Collapse, Row, Spin } from "antd";
import React from "react";
import { MSSSTUD } from "../../../base/utils/roles";
import { CRED_PERCENT } from "../../../mastertables/utils/const";
import {
  calculateCGPA,
  calculateTotalApprovedMakrs,
  calculateTotalPercentage,
} from "../../utils/utils";
import MarksDetails from "./MarksDetails";
import StudentInstitutionDetails from "./StudentInstitutionDetails";
import moment from "moment";
import { connect } from "react-redux";
import { getMarksConfig } from "../../../sampoorna-siksha/actions/SSActions";
import {
  deleteStudentAcademics,
  deleteStudentMarks,
  fetchStudentAcadById,
  saveStudentAcademics,
  saveStudentMarks,
} from "../../actions/studentList";
import { RESET_CURR_UPLD } from "../../../base/utils/const";
import { draftReqImg, getUploadsByProfIdAndType, updateReqImageAngle } from "../../actions/managementUploads";
import { MARKS_MEMO, SET_CUR_REQ } from "../../utils/const";
import {APRV} from '../../../common/utils/const';

class StudentAcademics extends React.Component {
  state = {
    activePannels: ["1"],
  };

  componentDidMount() {
    if (this.props.manage.currentProfile.studentProfileId) {
      this.props.fetchStudentAcadById(
        this.props.manage.currentProfile.studentProfileId
      );
    }
  }

  onClose = (e) => {
    this.props.onClose(e);
    this.props.setEditForm("");
  };

  getRank = (academics) => {
    if (!academics) {
      return "";
    }

    let rank = 0;
    const year = moment(academics.startYear).get("year");
    for (const sems of academics.semisters) {
      const courseCur = academics.course.curriculum
        ?.filter((cur) => cur.effectiveYear <= year)
        .sort((a, b) => b.effectiveYear - a.effectiveYear);
      const courseSubj = courseCur[0]?.curriculumSubjects?.filter(
        (cs) => cs.semId === sems.courseSemId
      );

      if (
        courseSubj &&
        courseSubj.length > 0 &&
        sems.studentMarks  &&
        sems.studentMarks.length > 0
      ) {
        for(const ranksInfo of sems.studentMarks.sort((a, b) => a.attempt - b.attempt))
        {
          if (ranksInfo.status === APRV) {
            rank = ranksInfo.info;
          }
        }
      }
    }
    return rank;
  };

  generateEntranceExams = (profile) => {
    if (!profile.studentAcademics) {
      return "";
    }
    const html = [];

    for (const ac of profile.studentAcademics) {
      const edCatType =
        ac.course.educationCategory?.educationCategory.educationId;
      if (edCatType && edCatType === 5) {
        const courseName = ac.course.courseName;
        const edCat = ac.course.educationCategory.categoryName;
        const credSys = ac.institute.affiliation.credSystem;
        html.push(
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-18">
              <label className="ant-form-item-required" title={edCat}>
                {edCat} ({courseName}){" "}
                {credSys === CRED_PERCENT ? "Marks" : "CGPA"}
              </label>
            </div>
            <div
              className="ant-col ant-form-item-control"
              style={{ padding: "8px" }}
            >
              {credSys === CRED_PERCENT
                ? calculateTotalApprovedMakrs(ac)
                : calculateCGPA(ac)}
              <p>(Rank - {this.getRank(ac)})</p>
            </div>
          </div>
        );
      }
    }
    if (html.length === 0) {
      html.push(<p>Not applied to any entrance exam.</p>);
    }
    return html;
  };

  generateBoardExams = (profile) => {
    if (!profile.studentAcademics) {
      return "";
    }
    const html = [];

    for (const ac of profile.studentAcademics) {
      const edCatType =
        ac.course.educationCategory?.educationCategory.educationId;
      if (edCatType && edCatType !== 5) {
        // const courseName = ac.course.courseName;
        const edCat = ac.course.educationCategory.categoryName;
        const credSys = ac.institute.affiliation.credSystem;
        html.push(
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-16">
              <label className="ant-form-item-required" title={edCat}>
                {edCat} - {credSys === CRED_PERCENT ? "Percentage" : "CGPA"}
              </label>
            </div>
            <div
              className="ant-col ant-form-item-control"
              style={{ padding: "8px" }}
            >
              {credSys === CRED_PERCENT
                ? calculateTotalPercentage(ac)
                : calculateCGPA(ac)}
            </div>
          </div>
        );
      }
    }
    return html;
  };

  generateMarksDetails = () => {
    const html = [];
    if (!this.props.manage.currentProfile.studentAcademics) {
      return html;
    }
    for (const studAcad of this.props.manage.currentProfile.studentAcademics) {
      html.push(<MarksDetails academics={studAcad} {...this.props} />);
    }
    return html;
  };

  calculateTotalScore = (profile) => {
    const { marksConfig } = this.props.appConfig;
    const config = {};
    for (const mc of marksConfig) {
      config[mc.configCd] = mc.configVal;
    }
    const mct =
      (profile.mctMarks / (config["MCT_TOTAL"] ? config["MCT_TOTAL"] : 100)) *
      (config["MCT_PERCENT"] ? config["MCT_PERCENT"] : 100);
    const ssc =
      (profile.sscMarks / (config["SSC_TOTAL"] ? config["SSC_TOTAL"] : 100)) *
      (config["SSC_PERCENT"] ? config["SSC_PERCENT"] : 100);
    return (mct + ssc).toFixed(1) + "%";
    // return (profile.calSscMarks + profile.calMctMarks).toFixed(1) + '%';
  };

  calculateMCTPercent = (profile) => {
    const { marksConfig } = this.props.appConfig;
    const config = {};
    for (const mc of marksConfig) {
      config[mc.configCd] = mc.configVal;
    }
    const mct =
      (profile.mctMarks / (config["MCT_TOTAL"] ? config["MCT_TOTAL"] : 100)) *
      100;

    return mct.toFixed(2);
  };

  render() {
    const { currentProfile } = this.props.manage;
    return (
      <div id="academics_container">
        <Spin spinning={this.props.manage.loading} delay={500}>
        <Collapse bordered={false} defaultActiveKey={this.state.activePannels}>
          <Collapse.Panel header={<b>Academic Record Summary</b>} key="1">
            <Row gutter={[16, 8]}>
              <Col xs={24} sm={6}>
                <Card title="MCT Exam Score">
                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-12">
                      <label
                        for="percentage"
                        className="ant-form-item-required"
                        title="Percentage"
                      >
                        Percentage
                      </label>
                    </div>
                    <div
                      className="ant-col ant-form-item-control"
                      style={{ padding: "8px" }}
                    >
                      {this.calculateMCTPercent(currentProfile)}
                    </div>
                  </div>
                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-12">
                      <label
                        for="percentage"
                        className="ant-form-item-required"
                        title="Percentage"
                      >
                        MCT Marks
                      </label>
                    </div>
                    <div
                      className="ant-col ant-form-item-control"
                      style={{ padding: "8px" }}
                    >
                      {currentProfile.mctMarks}
                    </div>
                  </div>
                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-12">
                      <label
                        for="combinedScore"
                        className="ant-form-item-required"
                        title="Combined Score"
                      >
                        Combined Score
                      </label>
                    </div>
                    <div
                      className="ant-col ant-form-item-control"
                      style={{ padding: "8px" }}
                    >
                      {this.calculateTotalScore(currentProfile)}
                    </div>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={8}>
                <Card title="Board Exams">
                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-16">
                      <label
                        for="sscMarks"
                        className="ant-form-item-required"
                        title="SSC Marks"
                      >
                        SSC GPA
                      </label>
                    </div>
                    <div
                      className="ant-col ant-form-item-control"
                      style={{ padding: "8px" }}
                    >
                      {currentProfile.sscMarks}
                    </div>
                  </div>
                  {this.generateBoardExams(currentProfile)}
                </Card>
              </Col>
              <Col xs={24} sm={8}>
                <Card title="Entrance Exams">
                  {this.generateEntranceExams(currentProfile)}
                </Card>
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header={<b>Education Timeline Details</b>} key="2">
            <StudentInstitutionDetails
              currentProfile={currentProfile}
              {...this.props}
            />
          </Collapse.Panel>
        </Collapse>
        {this.generateMarksDetails()}
        {this.props.role !== MSSSTUD && (
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <Button onClick={(e) => this.onClose(e)} style={{ marginRight: 8 }}>
              Close
            </Button>
          </div>
        )}
        {this.props.role === MSSSTUD && (
          <Button
            onClick={(e) => this.props.history.push("/")}
            className="chat-close-btn"
          >
            Close
          </Button>
        )}
        </Spin>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMarksConfig() {
      dispatch(getMarksConfig());
    },

    fetchStudentAcadById(id) {
      dispatch(getUploadsByProfIdAndType(id, MARKS_MEMO)).then((res) => {
        dispatch(fetchStudentAcadById(id));
      });
    },

    saveStudentAcademics(form) {
      dispatch(saveStudentAcademics(form)).then((res) => {
        this.fetchStudentAcadById(form.studentProfileId);
      });
    },

    deleteStudentAcademics(record) {
      dispatch(deleteStudentAcademics(record.studentAcadId)).then((res) => {
        this.fetchStudentAcadById(record.studentProfileId);
      });
    },

    saveStudentMarks(marksList, profileId) {
      dispatch(saveStudentMarks(marksList)).then((res) => {
        this.fetchStudentAcadById(profileId);
      });
    },

    deleteStudentMarks(semId, attempt, profileId) {
      dispatch(deleteStudentMarks(semId, attempt)).then((res) => {
        this.fetchStudentAcadById(profileId);
      });
    },

    draftReqImg(studentProfileId, file, type, batch) {
      dispatch(draftReqImg(studentProfileId, file, type, batch));
    },

    setCurrentRequest(data) {
      dispatch({
        type: SET_CUR_REQ,
        payload: data,
      });
    },

    updateImageAngle(angle, uploadId) {
      dispatch(updateReqImageAngle(uploadId, angle));
    },

    resetUploads() {
      dispatch({
        type: RESET_CURR_UPLD,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { ManagementReducer } = state;
  return {
    loading: ManagementReducer.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentAcademics);
// export default StudentAcademics;

import { RESET_DATA, RESET_MESSAGES } from "../../base/utils/const";
import { CREATE_BULK_REQ, SEARCH_BULK_REQ } from "../utils/const";

const initialState = {
  loading: false,
  errorMessage: "",
  successMessage: "",
  requestCreated: "",
  taskEntities: []
};

const BulkRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SEARCH_BULK_REQ}_PENDING`:
    case `${CREATE_BULK_REQ}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${CREATE_BULK_REQ}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
        requestCreated: "s",
      };
    }

    case `${SEARCH_BULK_REQ}_REJECTED`:
    case `${CREATE_BULK_REQ}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data?.message,
        requestCreated: "e",
      };
    }

    case `${SEARCH_BULK_REQ}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        taskEntities: action.payload
      };
    }

    case RESET_DATA: {
      return {
        ...state,
        taskEntities: [],
        requestCreated: ''
      };
    }
    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
        requestCreated: "",
      };
    }
    default:
      return state;
  }
};

export default BulkRequestsReducer;

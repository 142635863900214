import { Button, Form, Input, Modal, Select, Spin } from "antd";
import React from "react";

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class AddCourseModal extends React.Component {
  componentDidMount() {
    this.props.fetchEducationCategories();
  }
  saveCourse = (form) => {
    this.props.saveCourse(form);
  };
  render() {
    const record = this.props.record;
    const fieldData = [
      {
        name: ["categoryId"],
        value: record.categoryId,
      },
      {
        name: ["courseId"],
        value: record.courseId,
      },
      {
        name: ["courseName"],
        value: record.courseName,
      },
      {
        name: ["courseCode"],
        value: record.courseCode,
      },
      {
        name: ["notes"],
        value: record.notes,
      },
    ];
    const text = record.courseId ? "Edit Courses" : "Add Courses";
    return (
      <div id="add-cat-modal">
        <Modal
          title={text}
          visible={this.props.masterTable.showAddCourse}
          footer={null}
          onCancel={() => this.props.toggleAddCourseModal(false)}
        >
          <Spin spinning={this.props.masterTable.loading} delay={500}>
            <Form
              name="normal_login"
              className="login-form login-wrapper"
              onFinish={this.saveCourse}
              fields={fieldData}
            >
              <Form.Item {...formItemLayout} name="courseId" hidden={true}>
                <Input />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="courseCode"
                label="Course Code"
                rules={[
                  {
                    pattern: /^\S/,
                    message: "Please enter a valid Course Code",
                  },
                ]}
              >
                <Input  maxLength="50"/>
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="courseName"
                label="Course Name"
                rules={[
                  {
                    required: true,
                    pattern: /^\S/,
                    message: "Please enter Course Name",
                  },
                ]}
              >
                <Input maxLength="200" />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="categoryId"
                label="Category Type"
                rules={[
                  {
                    required: true,
                    message: "Please select a Category Type",
                  },
                ]}
              >
                <Select className="full-width wrapped_select">
                  {this.props.masterTable.categories.map((cat) => (
                    <Select.Option key={cat.categoryId} value={cat.categoryId}>
                      {cat.categoryName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item {...formItemLayout} name="notes" label="Description">
                <Input.TextArea maxLength="100" />
              </Form.Item>
              <div className="modal-form-button-div">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="modal-form-submit-button"
                  disabled={this.props.masterTable.loading}
                >
                  Save
                </Button>
              </div>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default AddCourseModal;

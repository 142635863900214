import { APP_URI } from "../../config/env";
import moment from "moment";
import { STUD_IMG, STUD_ID, MEMO_NINTH_FRONT, MEMO_NINTH_BACK } from "./const";
import { TASK_UPLOAD_CATS } from "../../management/utils/const";

export const extractApplicationData = (applicationView) => {
  const application = applicationView.applicationForm
    ? applicationView.applicationForm
    : {};
  const fieldData = [];
  if (application) {
    for (let i in application) {
      if (i === "dateOfBirth") {
        fieldData.push({
          name: [i],
          value: moment(application[i]),
        });
      } else if (i === "school") {
        fieldData.push({
          name: ["mandalId"],
          value: application[i]?.parent?.id,
        });
        fieldData.push({
          name: ["districtId"],
          value: application[i]?.parent?.parent?.id,
        });
        fieldData.push({
          name: [i],
          value: application[i],
        });
      } else {
        fieldData.push({
          name: [i],
          value: application[i],
        });
      }
    }
  }
  const uploads = applicationView.uploads;
  const uploadFieldData = [];
  let studentImage = {};
  let studentIdProof = {};
  let ninthMemoBack = {};
  let ninthMemoFront = {};
  if (uploads) {
    for (let upload of uploads) {
      if (upload.type === "STUD_IMG") {
        studentImage = {
          status: "done",
          url:
            APP_URI +
            "/upload/download?path=" +
            getUploadPath(upload, application.year),
          type: getFileType(upload.extension),
          size: upload.size,
          name: "test",
          fileId: upload.uploadId,
          angle: upload.angle,
        };
        uploadFieldData.push({
          name: ["studentImage"],
          value: studentImage,
        });
      } else if (upload.type === "STUD_ID") {
        studentIdProof = {
          status: "done",
          url:
            APP_URI +
            "/upload/download?path=" +
            getUploadPath(upload, application.year),
          type: getFileType(upload.extension),
          size: upload.size,
          name: "test",
          fileId: upload.uploadId,
          angle: upload.angle,
        };
        uploadFieldData.push({
          name: ["studentIdProof"],
          value: studentIdProof,
        });
      } else if (upload.type === "MEMO_NINTH_FRONT") {
        ninthMemoFront = {
          status: "done",
          url:
            APP_URI +
            "/upload/download?path=" +
            getUploadPath(upload, application.year),
          type: getFileType(upload.extension),
          size: upload.size,
          name: "test",
          fileId: upload.uploadId,
          angle: upload.angle,
        };
        uploadFieldData.push({
          name: ["ninthMemoFront"],
          value: ninthMemoFront,
        });
      } else if (upload.type === "MEMO_NINTH_BACK") {
        ninthMemoBack = {
          status: "done",
          url:
            APP_URI +
            "/upload/download?path=" +
            getUploadPath(upload, application.year),
          type: getFileType(upload.extension),
          size: upload.size,
          fileId: upload.uploadId,
          angle: upload.angle,
        };
        uploadFieldData.push({
          name: ["ninthMemoBack"],
          value: ninthMemoBack,
        });
      }
    }
  }

  return {
    application,
    fieldData,
    studentImage,
    studentIdProof,
    ninthMemoFront,
    ninthMemoBack,
    uploadFieldData,
  };
};

export const getFileData = (upload, year) => {
  return {
    status: "done",
    url: APP_URI + "/upload/download?path=" + getUploadPath(upload, year),
    type: getFileType(upload.extension),
    size: upload.size,
    angle: upload.angle,
    fileId: upload.uploadId,
  };
};

export const getFileType = (ext) => {
  switch (ext) {
    case "png":
      return "image/png";
    case "jpeg":
      return "image/jpeg";
    case "jpg":
      return "image/jpeg";
    case "pdf":
      return "application/pdf";
    default:
      return "image/jpeg";
  }
};

export const generateAppNum = (application) => {
  let appId = application.applicationId.toString();
  if(application.applicationNo) {
    appId = application.applicationNo.toString();
  }
  while (appId.length < 5) {
    appId = "0" + appId;
  }

  return (application.type === "1" ? "MSS" : "MNP") + application.year + appId;
};

export const updateUploadFormData = (
  uploadFieldData,
  upload,
  fieldName,
  year
) => {
  if (uploadFieldData.length > 0) {
    for (let field of uploadFieldData) {
      if (field.name[0] === fieldName) {
        field.value = {
          status: "done",
          url: APP_URI + "/upload/download?path=" + getUploadPath(upload, year),
          type: getFileType(upload.extension),
          size: upload.size,
          name: "test",
          fileId: upload.uploadId,
        };
      }
    }
  }
  return uploadFieldData;
};

export const updateRotatedImageData = (state, upload) => {
  let studentImage = state.studentImage;
  let studentIdProof = state.studentIdProof;
  let ninthMemoBack = state.ninthMemoBack;
  let ninthMemoFront = state.ninthMemoFront;
  let uploadFieldData = state.uploadFieldData;
  const year = state.applicationForm.year;

  if (upload.type === STUD_IMG) {
    studentImage = getFileData(upload, year);
    uploadFieldData = updateUploadFormData(
      uploadFieldData,
      upload,
      "studentImage",
      year
    );
  } else if (upload.type === STUD_ID) {
    studentIdProof = getFileData(upload, year);
    uploadFieldData = updateUploadFormData(
      uploadFieldData,
      upload,
      "studentIdProof",
      year
    );
  } else if (upload.type === MEMO_NINTH_FRONT) {
    ninthMemoFront = getFileData(upload, year);
    uploadFieldData = updateUploadFormData(
      uploadFieldData,
      upload,
      "ninthMemoFront",
      year
    );
  } else if (upload.type === MEMO_NINTH_BACK) {
    ninthMemoBack = getFileData(upload, year);
    uploadFieldData = updateUploadFormData(
      uploadFieldData,
      upload,
      "ninthMemoBack",
      year
    );
  }

  return {
    studentImage,
    studentIdProof,
    ninthMemoBack,
    ninthMemoFront,
    uploadFieldData,
  };
};

export const getUploadPath = (uploads, year) => {
  if (uploads.entityId && !TASK_UPLOAD_CATS.includes(uploads.type)) {
    return encodeURIComponent(
      `${year}/${uploads.entityId}/${uploads.type}/${uploads.filename}`
    );
  } else if (uploads.interactionFileId) {
    return encodeURIComponent(
      `interaction/${year}/${uploads.studentProfileId}/MSG_INTR/${uploads.interactionFileId}/${uploads.filename}`
    );
  } else if (uploads.studentNotesId) {
    return encodeURIComponent(
      `studentnotes/${year}/${uploads.studentProfileId}/NOTES/${uploads.studentNotesFileId}/${uploads.filename}`
    );
  } else if (uploads.studentProfileId) {
    return encodeURIComponent(
      `manage/${year}/${uploads.studentProfileId}/${uploads.type}/${uploads.filename}`
    );
  }
};

import { Col, Empty } from "antd";
import React from "react";
import ViewSurveyCard from "./ViewSurveyCard";

class VehicleInfoView extends React.Component {
  generateFieldData = (data) => {
    const html = [];
    if (Array.isArray(data)) {
      for (const j of data) {
        for (const i in j) {
          html.push(
            <Col sm={6}>
              <label>{i}</label>:
            </Col>,
            <Col sm={6}>
              {" "}
              <span>{j[i]}</span>
            </Col>
          );
        }
      }
    } else {
      for (const i in data) {
        if (!Array.isArray(data[i])) {
          html.push(
            <Col sm={6}>
              <label>{i}</label>:
            </Col>,
            <Col sm={6}>
              {" "}
              <span>{data[i]}</span>
            </Col>
          );
        }
      }
    }

    return html;
  };
  render() {
    let data = this.props.data;
    if(!data) {
      return (
        <ViewSurveyCard title="Vehicles Information" {...this.props}>
          <Empty />
        </ViewSurveyCard>
      );
    }
    return (
      <div>
        <ViewSurveyCard title="Vehicles Information" {...this.props}>
          <Col sm={10}>
              <label>Number of Bicycles</label>:
            </Col>
            <Col sm={2}>
              <span>{data.bicycles}</span>
            </Col>
            <Col sm={10}>
              <label>Number of 2 Wheelers</label>:
            </Col>
            <Col sm={2}>
              <span>{data.twoWheelers}</span>
            </Col>
            <Col sm={10}>
              <label>Number of 3 Wheelers</label>:
            </Col>
            <Col sm={2}>
              <span>{data.threeWheelers}</span>
            </Col>
            <Col sm={10}>
              <label>Number of 4 Wheelers</label>:
            </Col>
            <Col sm={2}>
              <span>{data.fourWheelers}</span>
            </Col>
        </ViewSurveyCard>
      </div>
    );
  }
}

export default VehicleInfoView;

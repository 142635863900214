
import React from 'react';
import { Row, Col, Card, Button, Spin } from 'antd';
import { render } from 'react-dom';
import '../styles/achievements.css';
import { getBoytBlack } from '../utils/images';
import student1 from '../../assets/images/Success-Stories/story-1.jpg';
import student2 from '../../assets/images/Success-Stories/story-2.jpg';
import student3 from '../../assets/images/Success-Stories/story-3.jpg';
import student4 from '../../assets/images/Success-Stories/story-4.jpg';
import student5 from '../../assets/images/Success-Stories/story-5.jpg';
import student6 from '../../assets/images/Success-Stories/story-6.jpg';
import student7 from '../../assets/images/Success-Stories/story-7-1.JPG';
import student8 from '../../assets/images/Success-Stories/story-8.jpg';
import student9 from '../../assets/images/Success-Stories/story-9.jpg';
import student10 from '../../assets/images/Success-Stories/story-10.jpg';
import student11 from '../../assets/images/Success-Stories/story-11.jpg';
import student12 from '../../assets/images/Success-Stories/story-12.jpg';
import student13 from '../../assets/images/Success-Stories/story-12.jpg';
import student14 from '../../assets/images/Success-Stories/story-14.jpg';
import student15 from '../../assets/images/Success-Stories/story-15-1.jpg';
import student16 from '../../assets/images/Success-Stories/story-16.jpg';
import student17 from '../../assets/images/Success-Stories/story-17.jpg';
import student18 from '../../assets/images/Success-Stories/story-18.jpg';
import student19 from '../../assets/images/Success-Stories/story-19.jpg';
import student20 from '../../assets/images/Success-Stories/story-20.jpg';

class Achievements extends React.Component {
    render() {
        return (
            <div id="achievements">
                <title>Success Stories</title>
                <meta
                    name="description"
                    content="Students from varied backgrounds successfully completed their education and are currently working in various fields with the help of Medha Charitable Trust’s initiatives."
                />
                <Card bordered={false} className="card-95">
                    {/* Sampurna Siksha */}
                    <h1 className="heading heading-mnn">Success Stories</h1>
                    <Row>
                    <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    
                                    <div className="achievements-img"> <img src={student7} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc"> <h1 className="gideon">Balla Anil Kumar</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2014 scholarship awardee</p>
                                        <p className="about-achievements">Anil is from Nalgonda and belongs to a weavers family. Anil has been always eager to learn and explore new things. His financial position has never stopped his eagerness and enthusiasm. After receiving scholarship from Medha Charitable Trust, he gave his 100% in preparing for JEE exam. He secured good rank and got admission in Electrical and Electronics Engineering in NIT Warangal. He had utmost interest in learning new programming languages and improving his zeal for coding. In campus placements, he was placed in Cipher Cloud with the highest salary package beating all other Medha Sampurna Siksha scholarship awardees before him. Anil wishes to hone his technical aptitude and work on the most challenging and advanced technologies.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    <div className="achievements-img"> <img src={student8} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc"> <h1 className="gideon">Kommu Chittaranjan</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2014 scholarship awardee</p>
                                        <p className="about-achievements">Chittaranjan is from rural family in Yadadri Bhongir, where his family struggled hard with meagre income. Amidst wide range of issues financially, education was a sure burden for the family to bear. Chittaranjan performed well in Medha Sampurna Siksha scholarship exam and Medha Charitable Trust has provided all the required financial and motivational support. He got admitted into BTech in Civil Engineering in NIT Warangal. He received the best education from one of the best universities in India. He currently works at Shapoorji Pallonji Group. He wishes to help students of similar background to achieve their dreams.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    <div className="achievements-img"> <img src={student9} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc"> <h1 className="gideon">Bommidi Harish</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2014 scholarship awardee</p>
                                        <p className="about-achievements">Harish hails from a small agricultural family. Harish schooling was in Telugu medium in a local government school but Harish was always aiming high in life. Medha Sampurna Siksha scholarship made his dreams come true. With his hard work he got admission into Mechanical Engineering at NIT Warangal. At university, he did multiple projects which were well appreciated. A project on “Medical Incubator based on Thermal Mass”, which can help in saving new born babies in localities where there is no electrical facility is among the best projects he worked on. After graduation he was placed in Alliance Tire Company. He wishes to learn more about Mechanical Engineering and serve our nation by providing innovative engineering solutions.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    <div className="achievements-img"><img src={student15} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc">  <h1 className="gideon">Guduguntla Renuka</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2015 scholarship awardee</p>
                                        <p className="about-achievements">Renuka is from Nalgonda and has 3 sisters. Her father moved to Mumbai to make a living and has been struggling to make ends meet to support his family. Any kind of difficulty couldn't stop Renuka from aiming high. Her strong will and unflinching motivation has driven her towards her dreams. Renuka got admission into BTech in Civil Engineering in National Institute of Technology, Warangal. Her persuasion for betterment continued at university. She received job offer from TATA Projects through campus placements. Her aim is to become a well qualified Civil engineer and be part of building our nation.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    <div className="achievements-img"> <img src={student11} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc"> <h1 className="gideon">Shaik Sunera Banu</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2014 scholarship awardee</p>
                                        <p className="about-achievements">Sunera, a native of Medak district, has been a brilliant student from a young age. After she lost her father, her mother struggled hard for Sunera and her younger sister's education. Sunera was selected for Medha Sampurna Siksha scholarship where all her education expenses for 6 years was covered. She studied in Osmania University in Bachelors in Engineering in Computer Science Engineering. She proved that financial barrier can't stop her from achieving success. Sunera now works at NCR Technologies as a software developer, where she receives very high salary package. It is Sunera's dream to support her younger sister's education and see her sister become a doctor.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    <div className="achievements-img"> <img src={student5} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc"> <h1 className="gideon">Kurva Hanumanthu</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2013 scholarship awardee</p>
                                        <p className="about-achievements">Hanumanthu belongs to a small farming family in Jogulamba Gadwal. Hanumanthu has always been a brilliant student and was awarded Medha Sampurna Siksha scholarship in 2013. He completed his intermediate education from Sri Chaitanya Junior College and secured 1620 in TS EAMCET. He got admission into BE in Electronics and Communication Engineering in Chaitanya Bharati Institute of Technology (CBIT). He maintained consistent scores in all his semesters and got multiple offers in campus placements. He opted to work at Medha Servo Drives Pvt. Ltd. as an Engineer - Trainee. He wishes to learn the intricacies of Electronics and to provide solutions to complex engineering problems.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                     <div className="achievements-img"><img src={student17} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc">  <h1 className="gideon">Kati Kalyani</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2018 scholarship awardee</p>
                                        <p className="about-achievements">Kalyani is native of Mancherial where her parents earn their livelihood by running a breakfast cart. Her good performance in Medha Sampurna Siksha scholarship exam won her the scholarship and was joined in Sri Chaitanya Junior College for intermediate education.<b>  She secured Telangana State First Rank in Intermediate exam in 2020.</b> In addition to this, she performed exceptionally well in JEE exams and was able to secure admission in NIT Delhi in BTech Computer Science and Engineering. Her dream is to learn more about advanced technologies and to use her knowledge in solving problems in today's society.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    <div className="achievements-img"><img src={student20} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc">  <h1 className="gideon">Bhagavan Reddy Machannagari</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2018 scholarship awardee</p>
                                        <p className="about-achievements">Bhagavan is from Siddipet, where his father is a farmer. Bhagavan was exceptionally good in school and in intermediate education. <b> He secured 5th highest marks in Intermediate exams in Telangana State in 2020.</b> He is currently pursuing BCom (Hons.) in Shri Ram College of Commerce, New Delhi, one of the premier institutes in commerce studies in India. Bhagavan aspires to be a Chartered Accountant.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    <div className="achievements-img"> <img className="profile-img" alt="Gideon" src={student2}></img></div>
                                    <div className="achievements-disc"> <h1 className="gideon">Dongari Sandhya</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2013 scholarship awardee</p>
                                        <p className="about-achievements">Sandhya belongs to a small agricultural family from Suryapet. Sandhya has been an active and brilliant girl since school days. She strived hard for better quality education since childhood. Through Medha Sampurna Siksha scholarship she was enrolled Sri Chaitanya Junior College in Hyderabad for intermediate and later she received admission in Vignana Bharathi Institute of Technology in BTech Civil Engineering. She worked hard to understand the core concepts of Civil engineering and learned them with curiosity and passion. She worked on multiple projects and has been the best student in her department. She got offers from multiple software companies but she wished to work in the area of Civil engineering due to her passion. She is currently working in IJM Concrete Products Pvt Ltd. as Technical Officer. Today, after achieving her goal, Sandhya wishes to give back to society by helping the needy.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                     <div className="achievements-img"> <img src={student4} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc"> <h1 className="gideon">Paladi Mahesh</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2013 scholarship awardee</p>
                                        <p className="about-achievements">Mahesh hails from a lower income family in Mahabubnagar district. His family struggled hard to meet everyday needs and Mahesh getting good education was a distant dream for them. However, due to his academic performance in school and good performance in Medha Sampurna Siksha scholarship exam, fetched him Medha Sampurna Siksha scholarship, He secured good rank in entrance exams and got admission in Vidya Jyothi Institute of Technology in BTech ECE. Mahesh possess a peculiar zeal for passion and learning. His natural leadership qualities has made him a student captain and he supported other students from his batch who received the same scholarship. His enthusiasm in learning new things made him explore many horizons in various technologies. He now works in OPPO India Software Quality R & D Centre. He has been successful at work and has been given varied responsibilities to, work not just in India but across nations. Mahesh's story stands out from many Medha Sampurna Siksha scholarship awardees as he has earned his success with passion and commitment along with unflinching perseverance.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    <div className="achievements-img"><img src={student1} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc"><h1 className="gideon">Chinthala Anjali</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2012 scholarship awardee</p>
                                        <p className="about-achievements">Anjali is from an agricultural family in Siddipet. Anjali is a kind hearted and compassionate person who is soft spoken and a good human being. She has been a brilliant student since childhood and has been a consistent performer. Through Medha Sampurna Siksha scholarship, she received entire education support from intermediate to end of graduation. She got admission into BTech in Computer Science Engineering in Sreenidhi Institute of Science and Technology. She is currently working in Accenture and has been performing exceptionally well at work as well.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    <div className="achievements-img"> <img src={student3} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc"> <h1 className="gideon">Maddikuntla Jyothi</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2013 scholarship awardee</p>
                                        <p className="about-achievements">Jyothi is a soft spoken brilliant person from Nalgonda. Her father's income was meagre, in spite of which, Jyothi worked hard to do her best in school. Jyothi was awarded Medha Sampurna Siksha scholarship in 2013 and studied intermediate in Hyderabad and got admission in BTech in Computer Science Engineering in Mallareddy Engineering College. She studied hard and maintained good academic score. She struggled a lot with both English language and computer languages as both were new to her. However, she pushed herself at every step of the way and spent more time where ever she was lagging behind. In the end, she was offered a job through pool drives, being among the 12 candidates who were offered jobs in over 500 candidates. She is currently working in Apalya Technologies on Android development. Jyothi's new aim is to become the best software developer and she wishes to work with advanced technologies.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    <div className="achievements-img"><img src={student19} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc">  <h1 className="gideon">Jallela Kaveri</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2017 scholarship awardee</p>
                                        <p className="about-achievements">Kaveri is from Rangareddy where her father works on their own farmland. Being an intelligent student since childhood, she was selected for Medha Sampurna Siksha scholarship. She studied hard in intermediate college and is currently pursuing MBBS in Rajiv Gandhi Institute of Medical Sciences (RIMS) Adilabad. She desires to serve the society by providing good medical care to the poor.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    {/* <img src="https://i.pinimg.com/originals/fb/d2/68/fbd2684ccf55078ce370e5525081e2cb.jpg" alt="Card Background" className="card-img"></img> */}
                                    <div className="achievements-img"> <img src={student6} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc"> <h1 className="gideon">Palabindela Shirisha</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2013 scholarship awardee</p>
                                        <p className="about-achievements">Palabindela Shirisha lost her father at a very young age and her mother struggled hard to take care of the family in Nalgonda. Shirisha quite aware of her family situation had decided to study well and secure a respectable job so that the struggles of her mother are fructified. Through Medha Sampurna Siksha scholarship Shirisha got admission into BTech in Mechanical Engineering in Gokaraju Rangaraju Institute of Engineering and Technology. She performed exceptionally well in her academics with consistent 9+ pointer in all her semesters. She cleared CV Corp examination and got aid for attending placement drives. She is now working at Accenture in Bengaluru. She holds impressive leadership qualities and she served as a student captain in Medha Sampurna Siksha student body committee during her graduation. She wishes to serve the society, women in particular.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    {/* <img src="https://i.pinimg.com/originals/fb/d2/68/fbd2684ccf55078ce370e5525081e2cb.jpg" alt="Card Background" className="card-img"></img> */}
                                    <div className="achievements-img"><img src={student14} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc">  <h1 className="gideon">Narayana Aravind Reddy</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2015 scholarship awardee</p>
                                        <p className="about-achievements">Aravind is from a small agricultural family, native of Nagarkurnool. The way Aravind perceives his surrounding has always made him stand out from rest of the crowd. His passion to be innovative helps him in overcoming hurdles and made him learn his subjects with a unique approach. His quest for perfection has made him a constant innovator. He is pursuing his BTech in Mechanical Engineering from Mallareddy Engineering College. He has worked on multiple projects in providing solutions to existing problems. One of his such innovation, <b> “Anti-Drowsiness Device”, was selected for Intinta Innovator program by Telangana State Innovation Cell. A patent was published for his device in 2020.</b></p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    {/* <img src="https://i.pinimg.com/originals/fb/d2/68/fbd2684ccf55078ce370e5525081e2cb.jpg" alt="Card Background" className="card-img"></img> */}
                                    <div className="achievements-img"><img src={student18} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc">  <h1 className="gideon">Koppolu Bhoomika</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2014 scholarship awardee</p>
                                        <p className="about-achievements">Bhoomika is from Nalgonda District where she lost both of her parents at very young age. She had to face many challenges since childhood, but her will to study hard and achieve her goals in life always stood intact. She is studying BSc Agriculture in University College of Agriculture, PJTSAU – Ashwaraopeta. She aspires to be an Agricultural Scientist.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24}>
                            <div className="card-wrapper">
                                <div className="card-achievements card-mnn">
                                    {/* <img src="https://i.pinimg.com/originals/fb/d2/68/fbd2684ccf55078ce370e5525081e2cb.jpg" alt="Card Background" className="card-img"></img> */}
                                    <div className="achievements-img"> <img src={student10} alt="Gideon" className="profile-img"></img></div>
                                    <div className="achievements-disc"> <h1 className="gideon">Kuntla Sushma Reddy</h1>
                                        <p className="job-title">Medha Sampurna Siksha 2014 scholarship awardee</p>
                                        <p className="about-achievements">Sushma, a native of Nalgonda district, has lost her father in very young age, due to which she had to face many hurdles since childhood. Sushma has always been a good student in academics and extra curricular activities. She performed well in Medha Sampurna Siksha scholarship exam and was awarded scholarship in 2014. She completed her BTech in CSE from Nallamalla Reddy Engineering College and is now working at Tata Consultancy Services. In college, Sushma worked on developing<b> “Artificial Intelligence based Smart Attendance System”, </b> a project for which patent got published in 2020.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> 
                </Card>
            </div>

        );
    }
}

export default Achievements;
import { connect } from "react-redux";
import { RESET_MESSAGES } from "../../base/utils/const";
import { getInteractions, loadMoreInteractions, saveInteractions } from "../actions/Interactions";
import { approveDrafts, deleteDrafts, draftImg } from "../actions/InteractionUploads";
import Interactions from "../components/Interactions";
import { MSG_ADD_TO_DEL_FILES } from "../utils/const";

const mapDispatchToProps = (dispatch) => {
  return {
    saveInteractions(interaction) {
      dispatch(saveInteractions(interaction)).then(
        res => {
          this.getInteractions(interaction.interaction.studentId, 0);
        }
      );
    },

    getInteractions(profileId, interactionId) {
      dispatch(getInteractions(profileId, interactionId));
    },

    loadMoreInteractions(profileId, interactionId) {
      dispatch(loadMoreInteractions(profileId, interactionId));
    },

    draftIntrUpload(studentProfileId, file, type, batch) {
      dispatch(draftImg(studentProfileId, file, type, batch));
    },

    addToDeleteFiles(filesList) {
      dispatch({
          type: MSG_ADD_TO_DEL_FILES,
          payload: filesList
      })
  },

    deleteIntrDraft(studentProfileId) {
      dispatch(deleteDrafts(studentProfileId));
    },

    approveIntrDrafts(studentProfileId, filesList) {
      dispatch(approveDrafts(studentProfileId, filesList));
    },

    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, InteractionsReducer, InteractionUploadReducer } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    msgs: InteractionsReducer,
    msgFiles: InteractionUploadReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interactions);

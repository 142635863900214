import { Form, Input } from "antd";
import React from "react";
import { formItemLayout, STUD_INFO } from "../../utils/consts";
import { prepareReviewersFieldData } from "../../utils/util";
import ReviewStep from "./ReviewStep";

class StudentInfo extends React.Component {
  onFinish = (form) => {
    // console.log("Received values of form:", form);
    this.props.saveDetails(STUD_INFO, form);
  };

  render() {
    let reviewData = this.props.review.reviewFormData[STUD_INFO];
    if(!reviewData) {
      reviewData = {};
    } 
    const fieldData = prepareReviewersFieldData(reviewData);
    return (
      <div className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Student Information"
          onFinish={e => this.onFinish(e)}
          {...this.props}
        >
          <Form.Item
            {...formItemLayout}
            name="studentsGoal"
            label="Student's Goal"
            // getValueFromEvent={this.toCapital}
            rules={[
              {
                required: true,
                message: "Please enter student’s goal",
              },
            ]}
          >
            <Input.TextArea rows={4} maxLength="100" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="studentsHealthProblem"
            label="Student's Health Problem"
            // getValueFromEvent={this.toCapital}
          >
            <Input.TextArea rows={4} maxLength="100" />
          </Form.Item>
        </ReviewStep>
      </div>
    );
  }
}

export default StudentInfo;

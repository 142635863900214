import { Col, Empty } from "antd";
import React from "react";
import ViewSurveyCard from "./ViewSurveyCard";

class ContactInformationView extends React.Component {
  
  render() {
    let info = this.props.data;
    if(!info) {
      return (
        <ViewSurveyCard title="Contact Information" {...this.props}>
          <Empty />
        </ViewSurveyCard>
      );
    }
    return (
      <div>
        <ViewSurveyCard title="Contact Information" {...this.props}>
          <Col sm={12}>
              <label>Student’s Home Address</label>:
            </Col>
            <Col sm={12}>
              <span>{info.address}</span>
            </Col>
            <Col sm={12}>
              <label>New District</label>:
            </Col>
            <Col sm={12}>
              <span>{info.newDistrict}</span>
            </Col>
            <Col sm={12}>
              <label>Mandal</label>:
            </Col>
            <Col sm={12}>
              <span>{info.mandal}</span>
            </Col>
            <Col sm={12}>
              <label>School Name</label>:
            </Col>
            <Col sm={12}>
              <span>{info.school}</span>
            </Col>
            <Col sm={12}>
              <label>Alternate Phone Number</label>:
            </Col>
            <Col sm={12}>
              <span>{info.alternatePhone}</span>
            </Col>
            <Col sm={12}>
              <label>Email Address</label>:
            </Col>
            <Col sm={12}>
              <span>{info.emailAddress}</span>
            </Col>
        </ViewSurveyCard>
      </div>
    );
  }
}

export default ContactInformationView;

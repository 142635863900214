import React from "react";
import { Modal, Button, Spin, Form, Input, Row, Col, Select } from "antd";
import { MED_REQ, REQ_CAT, REQ_CAT_TYP } from "../utils/const";
import { useState } from "react";
import { formItemLayout } from "./AddCourseModal";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const AddRequestCategory = (props) => {
  const [category, setCategory] = useState(props.masterTable.currentRecord);
  const [form] = Form.useForm();
  const closeModal = () => {
    props.toogleAddModal("");
  };

  const submitReqCat = () => {
    if(!props.masterTable.loading) {
      form.submit();
    }
  };

  const saveReqCat = (form) => {
    props.saveMasterTableData(REQ_CAT, form);
  };

  return (
    <React.Fragment>
      <Modal
        title={category.requestCategoryId? "Edit Request Category" : "Add Request Category"}
        visible={props.masterTable.showModal === REQ_CAT}
        onOk={submitReqCat}
        okText="Save"
        onCancel={closeModal}
        maskClosable={false}
      >
        <Spin spinning={props.masterTable.loading} delay={500}>
          <Form
            name="curiculum"
            className="login-form login-wrapper"
            onFinish={saveReqCat}
            // fields={fieldData}
            initialValues={category}
            form={form}
          >
            <Form.Item
              {...formItemLayout}
              name="requestCategoryId"
              hidden={true}
            >
              <Input />
            </Form.Item>
            <Row gutter={[16, 8]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  {...formItemLayout}
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      pattern: /^\S/,
                      message: "Please enter a Name",
                    },
                  ]}
                >
                  <Input maxLength="200"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  {...formItemLayout}
                  name="type"
                  label="Request Type"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Request Type",
                    },
                  ]}
                >
                  <Select className="full-width">
                    {REQ_CAT_TYP.map((cat) => (
                      <Select.Option key={cat.key - 1} value={cat.key}>
                        {cat.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.type !== currentValues.type
              }
            >
              {({ getFieldValue }) => {
                const type = getFieldValue("type");
                return type !== MED_REQ ? (
                  <Form.List name="requestSubCategories">
                    {(fields, { add, remove }) => {
                      return (
                        <div>
                          {fields.map((field, idx) => (
                            <Row key={idx}>
                              <Col sm={1}>
                                <span>{idx + 1}</span>
                                <Form.Item
                                  {...formItemLayout}
                                  name={[field.name, "requestSubCategoryId"]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "requestSubCategoryId",
                                  ]}
                                  hidden={true}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col sm={20}>
                                <Form.Item
                                  {...formItemLayout}
                                  name={[field.name, "name"]}
                                  fieldKey={[field.fieldKey, "name"]}
                                  label="Sub-Category Name"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please enter valid Sub-Category Name",
                                    },
                                  ]}
                                >
                                  <Input maxLength="200"/>
                                </Form.Item>
                              </Col>
                              <Col sm={2} style={{ textAlign: "center" }}>
                                <MinusCircleOutlined
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                />
                              </Col>
                            </Row>
                          ))}

                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => {
                                add();
                              }}
                              block
                            >
                              <PlusOutlined /> Add
                            </Button>
                          </Form.Item>
                        </div>
                      );
                    }}
                  </Form.List>
                ) : null;
              }}
            </Form.Item>
          </Form>
          <p><b>Note:</b> Sub-Categories can not be removed if they are mapped to any student request.</p>
        </Spin>
      </Modal>
    </React.Fragment>
  );
};

export default AddRequestCategory;

import { Button, Modal, Space, Spin, Table, Tooltip } from "antd";
import React from "react";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { INST } from "../utils/const";
import AddInstitute from "./AddInstitute";
import { exportFile } from "../../common/utils/export";

class Institutes extends React.Component {
  state = {
    institute: {},
  };

  componentDidMount() {
    this.props.fetchMasterTableData(INST);
  }

  handleAdd = () => {
    this.setState({ institute: {} });
    this.props.toogleAddModal(INST);
  };

  edit = (record, e) => {
    e.preventDefault();
    const courseId = [];
    for(const i of record.instituteCourses) {
      courseId.push(i.courseId);
    }
    record.courseId = courseId;
    record.instituteDetails = record.details[0];
    this.setState({ institute: record });
    this.props.toogleAddModal(INST);
  };

  delete = (record, e) => {
    e.preventDefault();
    const that = this;
    Modal.confirm({
      title: (<div>Do you want to delete this Institute?
        <p>Note: It can only be deleted if there are no students mapped to this Institute.</p>
      </div>),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        that.props.deleteMasterTableData(INST, record.instituteId);
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  exportData = (cols) => {
    exportFile(cols, this.props.masterTable.institutes, "Institutes");
  };

  render() {
    const INST_LIST_COLS = [
      {
        title: "Institute Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => record.name,
        sorter: {
          compare: (a, b) => sortNames(a.name, b.name),
        },
      },
      {
        title: "Category",
        dataIndex: "categoryName",
        key: "categoryName",
        render: (text, record) => record.educationCategory?.categoryName,
        sorter: {
          compare: (a, b) =>
            sortNames(
              a.educationCategory?.categoryName,
              b.educationCategory?.categoryName
            ),
        },
      },
      {
        title: "Affiliation",
        dataIndex: "affiliation",
        key: "affiliation",
        render: (text, record) => record.affiliation?.name,
        sorter: {
          compare: (a, b) => sortNames(a.affiliation?.name, b.affiliation?.name),
        },
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        render: (text, record) => record.details[0]?.location,
        sorter: {
          compare: (a, b) => sortNames(a.details[0]?.location, b.details[0]?.location),
        },
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <Tooltip title="Edit">
              <a href="/" onClick={(e) => this.edit(record, e)}>
                <EditOutlined />
              </a>
            </Tooltip>
            <Tooltip title="Delete">
              <a href="/" onClick={(e) => this.delete(record, e)}>
                <DeleteOutlined />
              </a>
            </Tooltip>
          </Space>
        ),
      },
    ];
    return (
      <div id="institute-master-container">
        <h2>
          Institutes
          <Button
            className="add-inst-button"
            onClick={this.handleAdd}
            type="primary"
            style={{ float: "right" }}
            disabled={this.props.masterTable.loading}
          >
            Add Institute
          </Button>
          <Button
            className="add-cat-button"
            onClick={(e) => this.exportData(INST_LIST_COLS)}
            style={{ float: "right", marginRight: "10px" }}
            disabled={this.props.masterTable.loading}
          >
            Export Excel
          </Button>
        </h2>
        <Spin spinning={this.props.masterTable.loading} delay={500}>
          <div>
            <Table
              columns={INST_LIST_COLS}
              dataSource={this.props.masterTable.institutes}
              pagination={{
                showSizeChanger: true,
                showTotal: () => {
                  const total = this.props.masterTable.institutes.length;
                  return `Total ${total}`;
                },
              }}
            />
          </div>
        </Spin>
        {this.props.masterTable.showModal === INST && (
          <AddInstitute record={this.state.institute} {...this.props} />
        )}
      </div>
    );
  }
}

export default Institutes;

import { axiosInstance } from "../../Interceptor";
import {
  GET_ALL_SMS_TEMPLATE,
  GET_SMS_LOGS,
  GET_SMS_LOGS_CAMPAIGNS,
  SAVE_SMS_TEMPLATE,
  SEND_SMS,
  SEND_BULK_SMS,
} from "../utils/consts";

export const getAllSMSTemplates = () => {
  return {
    type: GET_ALL_SMS_TEMPLATE,
    payload: axiosInstance.get("/sms"),
  };
};

export const getSMSTemplateByCode = (code) => {
  return {
    type: GET_ALL_SMS_TEMPLATE,
    payload: axiosInstance.get(`/sms/code/${code}`),
  };
};

export const saveSMSTemplate = (smsTemplate) => {
  const url = `/sms`;
  return {
    type: SAVE_SMS_TEMPLATE,
    payload: axiosInstance.post(url, smsTemplate),
  };
};

export const sendSMS = (form) => {
  const url = `/sms/send`;
  return {
    type: SEND_SMS,
    payload: axiosInstance.post(url, form),
  };
};

export const getAllSMSLogs = (form) => {
  return {
    type: GET_SMS_LOGS,
    payload: axiosInstance.post("/sms/logs", form),
  };
};

export const getAllSMSLogCampaigns = () => {
  return {
    type: GET_SMS_LOGS_CAMPAIGNS,
    payload: axiosInstance.get("/sms/logs/campaigns"),
  };
};

export const sendBulkSMS = (form) => {
  return {
    type: SEND_BULK_SMS,
    payload: axiosInstance.post("/sms/bulk", form),
  };
};

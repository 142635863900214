import {
  ADD_TO_DEL_FILES,
  ALL_SURVEY_UPLOAD_CAT,
  APPROVED_DRAFTS,
  DEL_FILES,
  DRAFT_SURVEY_IMG,
  GET_REVIEW_DETAILS,
  MULT_SURVEY_IMG,
  SAVE_REVIEW,
  SEARCH_SURVEYED_LIST,
  SET_APPL,
  SET_COMMITTEE_DATA,
  SET_COMPARE_LIST,
  SET_FEEDBACK_IN_PREVIEW,
  SET_FORM_STEPS,
  SET_STATUS_IN_PREVIEW,
  SET_STEP,
  SET_STEP_BY_KEY,
  SHOW_PREVIEW_AFTER_SAVE,
  SUBMIT_SURVEY,
  SURVEYED_LIST,
  TOGGLE_COMPARE,
  TOGGLE_COMPARE_REVIEW,
  UPDATE_COMMITTEE_FEEDBACK,
} from "../utils/consts";
import { RESET_MESSAGES } from "../../base/utils/const";
import {
  createEmptyReviewFilesList,
  createSurveyFormData,
  getLandingStep,
  generateSurveyData, updateIncomeDetails, getMaxUploadCount, sortNames
} from "../utils/util";
import { ROTATE_IMG } from "../../student/util/const";

const initialState = {
  loading: false,
  reviewDetails: {},
  reviewFormData: [],
  errorMessage: "",
  successMessage: "",
  step: 0,
  completedSteps:0,
  surveyImages: [],
  surveyCatImages: createEmptyReviewFilesList(),
  deletedImages: [],
  showPreviewBtn: false,
  showPreview: false,
  surveyedList: [],
  searchedApplications:[],
  formSteps: [],
  surveyData: {},
  compareList: [],
  showCompare: false,
  showCompareReview: false,
};

const ReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${GET_REVIEW_DETAILS}_PENDING`: {
      return {
        ...state,
        loading: true,
        reviewDetails: {},
        reviewFormData: {},
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${GET_REVIEW_DETAILS}_FULFILLED`: {
      const reviewDetails = action.payload;
      const reviewFormData = createSurveyFormData(reviewDetails.surveyList);
      const surveyCatImages = createEmptyReviewFilesList();
      let deletedImages = state.deletedImages;
      let surveyImages = [];
      for (const image of reviewDetails.imageUploads) {
        if (ALL_SURVEY_UPLOAD_CAT.indexOf(image.type) > -1) {
          let max = getMaxUploadCount(image.type);
          for (const i in surveyCatImages) {
            if (i === image.type) {
              if (max === surveyCatImages[i].length) {
                deletedImages = deletedImages.concat(
                  surveyCatImages[i].splice(0, 1)
                );
              }
              surveyCatImages[i].push(image);
            }
          }
        }
      }

      for (const i in surveyCatImages) {
        surveyImages = surveyImages.concat(surveyCatImages[i]);
      }
      const step = getLandingStep(reviewFormData, surveyCatImages);
      const surveyData = generateSurveyData(action.payload.surveyList);
      const showPreviewBtn = reviewDetails.surveyList.length  >= 15;
      return {
        ...state,
        loading: false,
        reviewDetails: reviewDetails,
        reviewFormData: reviewFormData,
        surveyCatImages: surveyCatImages,
        surveyImages: surveyImages,
        deletedImages: deletedImages,
        step: step,
        completedSteps: step,
        showPreviewBtn: showPreviewBtn,
        surveyData: surveyData
      };
    }

    case `${GET_REVIEW_DETAILS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        // errorMessage: action.payload.data,
      };
    }

    case `${SAVE_REVIEW}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${SAVE_REVIEW}_FULFILLED`: {
      const reviewFormData = createSurveyFormData(action.payload);
      const reviewDetails = state.reviewDetails;
      reviewDetails.surveyList = action.payload;
      const surveyData = generateSurveyData(action.payload);
      let step = state.step;
      if(step < 16) {
        step = state.step + 1;
      }
      return {
        ...state,
        loading: false,
        reviewDetails: reviewDetails,
        reviewFormData: reviewFormData,
        // successMessage: action.payload,
        step: step,
        showPreview: false,
        surveyData: surveyData
      };
    }

    case `${SAVE_REVIEW}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        // errorMessage: action.payload.data,
      };
    }
    case `${MULT_SURVEY_IMG}_PENDING`:
    case `${DRAFT_SURVEY_IMG}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }
    case `${MULT_SURVEY_IMG}_FULFILLED`: {
      const images = action.payload;

      let surveyImages = [];
      const { surveyCatImages } = state;
      let deletedImages = state.deletedImages;
      for(const image of images) {
        let max = getMaxUploadCount(image.type);
      for (const i in surveyCatImages) {
        if (i === image.type) {
          if (max === surveyCatImages[i].length) {
            deletedImages = deletedImages.concat(
              surveyCatImages[i].splice(0, 1)
            );
          }
          surveyCatImages[i].push(image);
        }
      }
      }
      
      for (const i in surveyCatImages) {
        surveyImages = surveyImages.concat(surveyCatImages[i]);
      }

      return {
        ...state,
        loading: false,
        surveyCatImages: surveyCatImages,
        surveyImages: surveyImages,
        deletedImages: deletedImages,
        // successMessage: action.payload,
      };
    }
    case `${DRAFT_SURVEY_IMG}_FULFILLED`: {
      const image = action.payload;

      let surveyImages = [];
      let max = getMaxUploadCount(image.type);

      const { surveyCatImages } = state;
      let deletedImages = state.deletedImages;
      for (const i in surveyCatImages) {
        if (i === image.type) {
          if (max === surveyCatImages[i].length) {
            deletedImages = deletedImages.concat(
              surveyCatImages[i].splice(0, 1)
            );
          }
          surveyCatImages[i].push(image);
        }
      }
      for (const i in surveyCatImages) {
        surveyImages = surveyImages.concat(surveyCatImages[i]);
      }

      return {
        ...state,
        loading: false,
        surveyCatImages: surveyCatImages,
        surveyImages: surveyImages,
        deletedImages: deletedImages,
        // successMessage: action.payload,
      };
    }
    case `${MULT_SURVEY_IMG}_REJECTED`:
    case `${DRAFT_SURVEY_IMG}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        // errorMessage: action.payload.data,
      };
    }

    case `${DEL_FILES}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
        deletedImages: [],
      };
    }

    case `${DEL_FILES}_FULFILLED`: {
      return {
        ...state,
        loading: false,
      };
    }

    case `${DEL_FILES}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case `${APPROVED_DRAFTS}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${APPROVED_DRAFTS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        step: state.step + 1,
      };
    }

    case `${APPROVED_DRAFTS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case `${ROTATE_IMG}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${ROTATE_IMG}_REJECTED`: {
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    }

    case `${ROTATE_IMG}_FULFILLED`: {

      const image = action.payload;
      let surveyImages = [];
      const { surveyCatImages } = state;
      for (const i in surveyCatImages) {
        if (i === image.type) {
          for(const j of surveyCatImages[i]) {
            if(j.uploadId === image.uploadId) {
              j.angle = image.angle;
            }
          }
        }
      }
      for (const i in surveyCatImages) {
        surveyImages = surveyImages.concat(surveyCatImages[i]);
      }

      return {
        ...state,
        loading: false,
        surveyImages: surveyImages,
        surveyCatImages: surveyCatImages
        // successMessage: 'Image updated successfully'
      };
    }

    case ADD_TO_DEL_FILES: {
      const deletedImages = state.deletedImages.concat(action.payload);
      const {surveyCatImages} = state;
      let surveyImages = [];
      for (const image of action.payload) {
          for (const i in surveyCatImages) {
            if (i === image.type) {
              for(let j = 0; j < surveyCatImages[i].length; j++) {
                if(surveyCatImages[i][j].uploadId === image.uploadId) {
                  surveyCatImages[i].splice(j, 1)
                }
              }
            }
          }
      }

      for (const i in surveyCatImages) {
        surveyImages = surveyImages.concat(surveyCatImages[i]);
      }

      return {
        ...state,
        deletedImages: deletedImages,
        surveyImages: surveyImages,
        surveyCatImages: surveyCatImages
      };
    }

    case SHOW_PREVIEW_AFTER_SAVE: {
      return {
        ...state,
        showPreview: action.payload,
      };
    }

    case SET_STEP: {
      return {
        ...state,
        step: action.payload,
      };
    }

    case `${SUBMIT_SURVEY}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${SUBMIT_SURVEY}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload
      };
    }

    case `${SUBMIT_SURVEY}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case `${SURVEYED_LIST}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
        surveyedList: [],
        searchedApplications: []
      };
    }

    case `${SURVEYED_LIST}_FULFILLED`: {
      const data = action.payload;
      return {
        ...state,
        loading: false,
        surveyedList: action.payload,
        searchedApplications: data.sort((a, b) =>
        sortNames(
          a.studentName + " " + a.studentSurName,
          b.studentName + " " + b.studentSurName
        ))
      };
    }

    case `${SURVEYED_LIST}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case SEARCH_SURVEYED_LIST: {
      return {
        ...state,
        searchedApplications: action.payload
      }
    }

    case `${UPDATE_COMMITTEE_FEEDBACK}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${UPDATE_COMMITTEE_FEEDBACK}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    }

    case `${UPDATE_COMMITTEE_FEEDBACK}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case SET_APPL: {
      return {
        ...state,
        loading: false,
        surveyedList: action.payload.surveyedList,
        searchedApplications: action.payload.searchedApplications
      };
    }

    case SET_STEP_BY_KEY: {
      const step = state.formSteps.indexOf(action.payload) > -1 ? state.formSteps.indexOf(action.payload) : 0;
      return {
        ...state,
        step: step
      }
    }

    case SET_FORM_STEPS: {
      return {
        ...state,
        formSteps: action.payload
      }
    }

    case SET_COMMITTEE_DATA: {
      const info = action.payload;
      state.surveyData[info.key] = info.data;
      const surveyData = updateIncomeDetails(state.surveyData);
      return {
        ...state,
        surveyData: surveyData
      }
    }

    case SET_COMPARE_LIST: {
      return {
        ...state,
        compareList: action.payload,
        showCompare: true
      };
    }

    case TOGGLE_COMPARE: {
      return {
        ...state,
        showCompare: action.payload
      };
    }

    case TOGGLE_COMPARE_REVIEW: {
      return {
        ...state,
        showCompareReview: action.payload
      };
    }

    case SET_STATUS_IN_PREVIEW: {
      const reviewDetails = state.reviewDetails;
      reviewDetails.application.selectionStatus = action.payload;
      return {
        ...state,
        reviewDetails: reviewDetails
      }
    }

    case SET_FEEDBACK_IN_PREVIEW: {
      const reviewDetails = state.reviewDetails;
      // reviewDetails.application.selectionStatus = action.payload;
      let isExists = false;
        for (const c of reviewDetails.application.comments) {
          if (c.entityType === "APPL" && c.eventType === "STUD_SEL") {
            c.comment = action.payload;
            isExists = true;
          }
        }

        if (!isExists) {
          reviewDetails.application.comments.push({
            comment: action.payload,
            entityId: reviewDetails.application.applicationId,
            entityType: "APPL",
            eventType: "STUD_SEL",
          });
        }
      return {
        ...state,
        reviewDetails: reviewDetails
      }
    }

    default:
      return state;
  }
};

export default ReviewReducer;

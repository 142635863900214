import React from "react";
import { Card, Row, Col, Select, Button, notification } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { EXCEL_ERROR } from "../utils/consts";

class ExamDocument extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      district: "",
    };
    this.form = React.createRef();
  }

  componentWillMount() {
    this.props.getOldDistricts();
  }

  downloadExamDocs = () => {
    if (this.state.district) {
      let dist = "";
      for (const d of this.props.oldDistrictsList) {
        if (d.id === this.state.district) {
          dist = d;
        }
      }
      this.props.downloadExamDocs(this.state.district, dist.configVal);
      this.setState({ district: "" });
    } else {
      notification.error({
        message: "Error",
        description: "Please select district.",
      });
    }
  };

  showErrorNotification = (message) => {
    if (EXCEL_ERROR !== message) {
      return;
    }
    const that = this;
    setTimeout(function () {
      that.props.resetMessage();
    }, 3000);
    notification.error({
      message: "Seating process is not performed.",
      description:
        "Please complete the seating process before exporting exam documents.",
    });
  };

  setDistrict = (value) => {
    this.setState({ district: value });
  };

  render() {
    return (
      <div className="container">
        <Card title="Exam Documents">
          <Row>
            <Col xs={24} sm={8} style={{ paddingRight: "10px" }}>
              <div>
                <span>Select District Name :</span>
                <Select
                  style={{ width: "50%", paddingLeft: "10px" }}
                  lable="Select District Name :"
                  onChange={this.setDistrict}
                  value={this.state.district}
                >
                  {this.props.oldDistrictsList.map((district) => (
                    <Select.Option key={district.id} value={district.id}>
                      {district.configVal}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={(e) => this.downloadExamDocs()}
                loading={this.props.sampurnaSiksha.isExporting}
              >
                Download Exam Documents
              </Button>
            </Col>
          </Row>
          <p style={{ marginTop: "20px" }}>
            <strong>*Note : </strong> All the documents related to all the Exam
            Centers in the selected district will be downloaded in a zip file.
            Please extract the zip file for the documents.
          </p>
        </Card>
        {this.props.sampurnaSiksha.errorMessage &&
          this.showErrorNotification(this.props.sampurnaSiksha.errorMessage)}
      </div>
    );
  }
}

export default ExamDocument;

import { axiosInstance } from "../../Interceptor";
import { MSG_APPROVED_DRAFTS, MSG_DEL_DRAFT, MSG_DEL_FILES, MSG_DRAFT_IMG, MSG_ROTATE_IMG } from "../utils/const";

export const deleteFiles = (filesList) => {
    return {
      type: MSG_DEL_FILES,
      payload: axiosInstance.post(`/interactions/upload/delete`, filesList),
    };
  };
  
  export const approveDrafts = (studentProfileId, filesList) => {
    return {
      type: MSG_APPROVED_DRAFTS,
      payload: axiosInstance.post(`/interactions/upload/approve/${studentProfileId}`, filesList),
    };
  };
  
  export const draftImg = (studentProfileId, file, type, batch) => {
    const url = `/interactions/upload/draft`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("entityId", studentProfileId);
    formData.append("type", type);
    formData.append("year", batch);
    return {
      type: MSG_DRAFT_IMG,
      payload: axiosInstance.post(url, formData),
    };
  };

  export const updateImageAngle = (uploadId, angle) => {
    const data = {
      managementUploadId: uploadId,
      angle: angle
    };
    return {
      type: MSG_ROTATE_IMG,
      payload: axiosInstance.post(`/interactions/upload/rotate`, data),
    };
  };

  export const deleteDrafts = (studentProfileId) => {
    return {
      type: MSG_DEL_DRAFT,
      payload: axiosInstance.delete(`/interactions/upload/draft/${studentProfileId}`),
    };
  };

import { Button, Card, Form } from "antd";
import React from "react";
import { PRVW_SUR } from "../../utils/consts";

class ReviewStep extends React.Component {
  state = {
    showPreview: false,
  };
  formRef = React.createRef();
  saveAndPreview = () => {
    // console.log("--------------------", this.formRef.current);
    this.setState({ showPreview: true });
    this.formRef.current.submit();
  };
  onFormSubmit = (form) => {
    this.props.onFinish(form);
    if (this.state.showPreview) {
      const that = this;
      setTimeout(() => {
        that.props.setStepByKey(PRVW_SUR);
      }, 200);
    }
  };
  render() {
    return (
      <div>
        <Form
          name="reviewStep"
          onFinish={this.onFormSubmit}
          onFinishFailed={(e) => {this.setState({showPreview: false})}}
          autoComplete="off"
          ref={this.formRef}
          fields={this.props.fieldData}
          //   labelAlign="left"
        >
          <Card title={this.props.title}>{this.props.children}</Card>
          <div className="steps-action">
            {this.props.showPrev && (
              <Button onClick={(e) => this.props.prev()}>Previous</Button>
            )}

            {this.props.showNext && (
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            )}
            {this.props.review.showPreviewBtn && (
              <Button type="primary" onClick={(e) => this.saveAndPreview()}>
                Save and Preview
              </Button>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

export default ReviewStep;

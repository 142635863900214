import { axiosInstance } from "../../Interceptor";
import {
  FETCH_DISTRICTS,
  FETCH_MANDALS,
  FETCH_SCHOOLS,
  FETCH_CASTE,
  FETCH_EXAM_CENTERS,
  FETCH_OLD_DISTRICTS,
  SAVE_SYS_CONFIG,
  GET_SYS_CONFIG,
  FETCH_CONFIG,
  SAVE_CONFIG,
  DEL_CONFIG,
} from "../utils/const";

export const fetchOldDistricts = () => {
  return {
    type: FETCH_OLD_DISTRICTS,
    payload: axiosInstance.get("/appconfig/ODIST/-1"),
  };
};

export const fetchMandalsByOldDistricts = (oldDistId) => {
  return {
    type: FETCH_MANDALS,
    payload: axiosInstance.get(`/appconfig/subchild/${oldDistId}`),
  };
};

export const fetchDistricts = () => {
  return {
    type: FETCH_DISTRICTS,
    payload: axiosInstance.get("/appconfig/DIST/-1"),
  };
};

export const fetchMandals = (distId) => {
  return {
    type: FETCH_MANDALS,
    payload: axiosInstance.get(`/appconfig/MNDL/${distId}`),
  };
};

export const fetchSchools = (mandlId) => {
  return {
    type: FETCH_SCHOOLS,
    payload: axiosInstance.get(`/appconfig/SCHL/${mandlId}`),
  };
};

export const fetchCasteCategory = () => {
  return {
    type: FETCH_CASTE,
    payload: axiosInstance.get("/appconfig/CASTE/-1"),
  };
};

export const fetchExamCenters = () => {
  return {
    type: FETCH_EXAM_CENTERS,
    payload: axiosInstance.get("/appconfig/EXAM_CENTER/-1"),
  };
};

export const getSystemConfigs = () => {
  return {
    type: GET_SYS_CONFIG,
    payload: axiosInstance.get("/appconfig/system"),
  };
};

export const saveSystemConfigs = (form) => {
  return {
    type: SAVE_SYS_CONFIG,
    payload: axiosInstance.post("/appconfig/savesystem", form),
  };
};

export const fetchConfigByTypeAndParentId = (type, parentIds) => {
  let actionType = FETCH_OLD_DISTRICTS;
  if (type === "DIST") {
    actionType = FETCH_DISTRICTS;
  } else if (type === "MNDL") {
    actionType = FETCH_MANDALS;
  } else if (type === "SCHL") {
    actionType = FETCH_SCHOOLS;
  } else if (type === "CASTE") {
    actionType = FETCH_CASTE;
  } else if (type === "EXAM_CENTER") {
    actionType = FETCH_EXAM_CENTERS;
  }

  return {
    type: actionType,
    payload: axiosInstance.get(
      `/appconfig?type=${type}&parentIds=${parentIds}`
    ),
  };
};

export const fetchConfigByCode = (code) => {
  return {
    type: FETCH_CONFIG,
    payload: axiosInstance.get(`/appconfig/${code}/-1`),
  };
};

export const saveConfigByCode = (code, form) => {
  return {
    type: SAVE_CONFIG,
    payload: axiosInstance.post(`/appconfig/${code}`, form),
  };
};

export const saveConfig = (form) => {
  return {
    type: SAVE_CONFIG,
    payload: axiosInstance.post(`/appconfig`, form),
  };
};

export const deleteConfig = (id) => {
  return {
    type: DEL_CONFIG,
    payload: axiosInstance.delete(`/appconfig/${id}`),
  };
};

export const deleteSchool = (sourceId, destId) => {
  return {
    type: DEL_CONFIG,
    payload: axiosInstance.delete(`/appconfig/schools/${sourceId}/${destId}`),
  };
};

export const deleteExamCenter = (sourceId, destId) => {
  return {
    type: DEL_CONFIG,
    payload: axiosInstance.delete(
      `/appconfig/examCenters/${sourceId}/${destId}`
    ),
  };
};

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { TOGGLE_ADD_MODAL } from "../../mastertables/utils/const";
import { getStudentExpenses } from "../actions/studentList";
import { Card, Spin, Button, Modal, Empty } from "antd";
import {
  sortNames,
} from "../../sampoorna-siksha/utils/util";
import {
  MED_REQ_APPRV,
  MON_REQ_APPRV,
  WEL_REQ_APPRV,
} from "../../common/utils/const";
import moment from "moment";
import { toCurrency } from "../../common/utils/util";

const Expenses = (props) => {
  const [expenses, setExpenses] = useState([]);
  const [modalId, setModalId] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (props.studentProfileId) {
      props.getStudentExpenses(props.studentProfileId);
    }
  }, [props.studentProfileId]);

  const totalAmt = (exList) => {
    let amt = 0;
    if (!exList) {
      return amt;
    }
    for (const ex of exList) {
      if (ex.amount) {
        amt += Number(ex.amount);
      }
    }
    return amt;
  };

  const showInfoModal = (type, list, category, year) => {
    setExpenses(list);
    setModalId(type);
    setTitle(category + " charges for " + year);
  };
  const closeInfoModal = () => {
    setExpenses([]);
    setModalId("");
    setTitle("");
  };

  const renderMedicalInfo = () => {
    const html = [];
    for (const el of expenses) {
      html.push(
        <tr className="expenses-summ">
          <td>
            {el.createdAt.epochSecond
              ? moment
                  .unix(el.createdAt.epochSecond)
                  .format("DD-MM-YYYY hh:mm A")
              : ""}
          </td>
          <td>{el.subCategory}</td>
          <td>{el.details}</td>
          <td className="money">{toCurrency(el.amount)}</td>
        </tr>
      );
    }
    return html;
  };

  const renderMonearyInfo = () => {
    const html = [];
    for (const el of expenses) {
      html.push(
        <tr className="expenses-summ">
          <td>
            {el.createdAt.epochSecond
              ? moment
                  .unix(el.createdAt.epochSecond)
                  .format("DD-MM-YYYY hh:mm A")
              : ""}
          </td>
          <td>{el.subCategory}</td>
          <td>{el.details}</td>
          <td className="money">{toCurrency(el.amount)}</td>
        </tr>
      );
    }
    return html;
  };

  const renderWelfareInfo = () => {
    const html = [];
    for (const el of expenses) {
      html.push(
        <tr className="expenses-summ">
          <td>
            {el.createdAt.epochSecond
              ? moment
                  .unix(el.createdAt.epochSecond)
                  .format("DD-MM-YYYY hh:mm A")
              : ""}
          </td>
          <td>{el.subCategory}</td>
          <td>{el.details}</td>
          <td className="money">{toCurrency(el.amount)}</td>
        </tr>
      );
    }
    return html;
  };

  const generateExpSummary = () => {
    const exp = props.manage.currentProfile?.expenses;
    if (!exp) {
      return "";
    }

    if (Object.keys(exp).length === 0) {
      return <Empty />;
    }

    const years = [];
    const medCats = [];
    const medExps = [];
    const monCats = [];
    const monExps = [];
    const welCats = [];
    const welExps = [];
    // expensesCat = {year : expenses[]}
    for (const key of Object.keys(exp)) {
      years.push(key);
      for (const ex of exp[key]) {
        if (ex.type === "medical") {
          const label = ex.categoryName;
          if (medCats.indexOf(label) === -1) {
            medCats.push(label);
          }
          if (!medExps[label]) {
            medExps[label] = [];
          }
          if (!medExps[label][key]) {
            medExps[label][key] = [];
          }
          {{medExps[label][key]&&medExps[label][key].push(ex)}}
          
        } else if (ex.type === "monetary") {
          const label = ex.categoryName;
          if (monCats.indexOf(label) === -1) {
            monCats.push(label);
          }
          if (!monExps[label]) {
            monExps[label] = [];
          }
          if (!monExps[label][key]) {
            monExps[label][key] = [];
          }
          {{monExps[label][key]&&monExps[label][key].push(ex)}}
        } else if (ex.type === "welfare") {
          const label = ex.categoryName;
          if (welCats.indexOf(label) === -1) {
            welCats.push(label);
          }
          if (!welExps[label]) {
            welExps[label] = [];
          }
          if (!welExps[label][key]) {
            welExps[label][key] = [];
          }
          {{welExps[label][key]&&welExps[label][key].push(ex)}}
        }
      }
    }

    const sortedYears = years.sort((y1, y2) => sortNames(y1, y2));
    const sortedMedCats = medCats.sort((mc1, mc2) => sortNames(mc1, mc2));
    const sortedMonCats = monCats.sort((mc1, mc2) => sortNames(mc1, mc2));
    const sortedWelCats = welCats.sort((wc1, wc2) => sortNames(wc1, wc2));
    const headers = [<th>Year</th>];
    const rows = [];
    const totalRows = {};
    for (const y of sortedYears) {
      headers.push(<th>{y}</th>);
      totalRows[y] = 0;
    }
    for (const sm of sortedMedCats) {
      const row = [];
      row.push(<td>{sm}</td>);
      for (const y of sortedYears) {
        const total = totalAmt(medExps[sm][y]);
        row.push(
          <td
            className="money"
            onClick={(e) =>
              showInfoModal(MED_REQ_APPRV.value, medExps[sm][y], sm, y)
            }
          >
            {toCurrency(total)}
          </td>
        );
        totalRows[y] += total;
      }
      rows.push(<tr>{row}</tr>);
    }

    for (const sm of sortedMonCats) {
      const row = [];
      row.push(<td>{sm}</td>);
      for (const y of sortedYears) {
        const total = totalAmt(monExps[sm][y]);
        row.push(
          <td
            className="money"
            onClick={(e) =>
              showInfoModal(MON_REQ_APPRV.value, monExps[sm][y], sm, y)
            }
          >
            {toCurrency(total)}
          </td>
        );
        totalRows[y] += total;
      }
      rows.push(<tr>{row}</tr>);
    }

    for (const sm of sortedWelCats) {
      const row = [];
      row.push(<td>{sm}</td>);
      for (const y of sortedYears) {
        const total = totalAmt(welExps[sm][y]);
        row.push(
          <td
            className="money"
            onClick={(e) =>
              showInfoModal(WEL_REQ_APPRV.value, welExps[sm][y], sm, y)
            }
          >
            {toCurrency(total)}
          </td>
        );
        totalRows[y] += total;
      }
      rows.push(<tr>{row}</tr>);
    }
    const totalRowhtml = [
      <td>
        <b>Total</b>
      </td>,
    ];
    for (const y of sortedYears) {
      totalRowhtml.push(
        <td className="money" style={{ cursor: "default" }}>
          <b>{toCurrency(totalRows[y])}</b>
        </td>
      );
    }
    console.log(medExps);
    return (
      <table className="expenses-table">
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody>
          <tr>{totalRowhtml}</tr>
          {rows}
        </tbody>
      </table>
    );
  };

  return (
    <React.Fragment>
      <Card title="Expenses">
        <Spin spinning={!props.manage.currentProfile.expenses}>
          {generateExpSummary()}
        </Spin>
      </Card>
      {props.onClose && (
        <div style={{ textAlign: "right", marginTop: "10px" }}>
          <Button onClick={(e) => props.onClose(e)} style={{ marginRight: 8 }}>
            Close
          </Button>
        </div>
      )}
      {modalId === MED_REQ_APPRV.value && (
        <Modal
          title={title}
          visible={modalId === MED_REQ_APPRV.value}
          onCancel={closeInfoModal}
          footer={[
            <Button key="back" type="primary" onClick={closeInfoModal}>
              Close
            </Button>,
          ]}
        >
          <table className="expenses-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Specilazation</th>
                <th>Details</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>{expenses&&renderMedicalInfo()}</tbody>
          </table>
        </Modal>
      )}

      {modalId === MON_REQ_APPRV.value && (
        <Modal
          title={title}
          visible={modalId === MON_REQ_APPRV.value}
          onCancel={closeInfoModal}
          footer={[
            <Button key="back" type="primary" onClick={closeInfoModal}>
              Close
            </Button>,
          ]}
        >
          <table className="expenses-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Sub-Category</th>
                <th>Details</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>{expenses&&renderMonearyInfo()}</tbody>
          </table>
        </Modal>
      )}
      {modalId === WEL_REQ_APPRV.value && (
        <Modal
          title={title}
          visible={modalId === WEL_REQ_APPRV.value}
          onCancel={closeInfoModal}
          footer={[
            <Button key="back" type="primary" onClick={closeInfoModal}>
              Close
            </Button>,
          ]}
        >
          <table className="expenses-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Item</th>
                <th>Details</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>{expenses&&renderWelfareInfo()}</tbody>
          </table>
        </Modal>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // offlineSearch(searchText, profiles) {
    //   if (searchText && searchText.length > 1) {
    //     const result = profiles.filter((profile) => {
    //       return studProfPredicate(profile, searchText);
    //     });
    //     dispatch({
    //       type: SET_FILTERED_PROFILES,
    //       payload: result,
    //     });
    //   } else if (!searchText) {
    //     dispatch({
    //       type: SET_FILTERED_PROFILES,
    //       payload: profiles,
    //     });
    //   }
    // },

    getStudentExpenses(id) {
      dispatch(getStudentExpenses(id));
    },

    toogleAddModal(modal) {
      dispatch({
        type: TOGGLE_ADD_MODAL,
        payload: modal,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    ManagementReducer,
    MasterTablesReducer,
    AppConfigReducer,
    ManagementUploadReducer,
  } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    manage: ManagementReducer,
    masterTable: MasterTablesReducer,
    appConfig: AppConfigReducer,
    mgUploads: ManagementUploadReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Expenses)
);

import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import moment from "moment";
import React from "react";
import { sortNames } from "../../sampoorna-siksha/utils/util";

const dateTimeFormat = "DD/MM/YYYY";
const { Option } = Select;
class SendSMS extends React.Component {
  componentDidMount = () => {
    this.props.getAllSMSLogCampaigns();
  };

  showTotal = () => {
    const total = this.props.sms.smsLogs.length;
    return `Total ${total} Applicants`;
  };
  getResponseMessage = (response) => {
    if (response === "testing") {
      return response;
    }
    const res = JSON.parse(response);
    return `(${res.data[0].status}) ${res.message}`;
  };

  search = (form) => {
    // console.log(form);
    if(form.date) {
      form.date = moment(form.date).utc();
    }
    this.props.getSmsLogs(form);
  };

  render() {
    const SMS_LOGS_COLS = [
      {
        title: "Phone Number",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        render: (text, record) => record.phoneNumber? record.phoneNumber.split(',').join(', ') : '',
        sorter: {
          compare: (a, b) => sortNames(a.phoneNumber, b.phoneNumber),
        },
        width: "400px",
        textWrap: 'word-break',
      },
      {
        title: "Message",
        dataIndex: "message",
        key: "message",
        render: (text, record) => record.message,
        sorter: {
          compare: (a, b) => sortNames(a.message, b.message),
        },
      },
      {
        title: "Sent on",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text, record) => {
          return moment.parseZone(record.createdAt).local().format("DD-MM-YYYY hh:mm A");
        },
        sorter: {
          compare: (a, b) =>
            moment(a.createdAt).unix() - moment(b.createdAt).unix(),
        },
        width: "200px",
      },
      // {
      //   title: "Response",
      //   dataIndex: "response",
      //   key: "response",
      //   render: (text, record) => this.getResponseMessage(record.response),
      //   sorter: {
      //     compare: (a, b) =>
      //       sortNames(
      //         this.getResponseMessage(a.response),
      //         this.getResponseMessage(b.response)
      //       ),
      //   },
      // },
    ];
    return (
      <div>
        <Card>
          <Form name="smsLogsFilter" onFinish={this.search}>
            <Row gutter={[8, 0]}>
              <Col>
                <Form.Item name="campaignName" label="Campaign Name">
                  {/* <Input maxLength={100} /> */}
                  <Select
                    placeholder="Select"
                    mode="multiple"
                    style={{ minWidth: "200px" }}
                  >
                    {this.props.sms.smsLogCampaigns.map((i) => {
                      if (i) {
                        return (
                          <Option key={i} value={i}>
                            {i}
                          </Option>
                        );
                      }
                      return "";
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="fromDate" label="From Date">
                  <DatePicker format={dateTimeFormat} className="full-width" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="toDate" label="To Date">
                  <DatePicker format={dateTimeFormat} className="full-width" />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        <Spin spinning={this.props.sms.loading} delay={500}>
          <Table
            columns={SMS_LOGS_COLS}
            dataSource={this.props.sms.smsLogs}
            pagination={{
              showSizeChanger: true,
              showTotal: this.showTotal,
            }}
          />
        </Spin>
      </div>
    );
  }
}

export default SendSMS;

import React from "react";
import {
  Spin,
  Input,
  Button,
  Card,
  Form,
  Select,
  Alert,
  notification,
} from "antd";
import { ADMIN, MSSSTUD, SUP1, SUP2 } from "../../base/utils/roles";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
};

class EditProfileComponent extends React.Component {
  componentWillMount() {
    this.props.getCurrentUserDetails();
  }

  showErrorNotification = (msgType, message) => {
    const that = this;
    setTimeout(function () {
      that.props.resetMessages();
    }, 3000);
    notification[msgType]({
      message: msgType.toUpperCase(),
      description: message,
    });
  };

  onFinish = (formValues) => {
    // console.log("Form-------------------", formValues);
    this.props.editUser(formValues, this.props.admin.currentProfile);
  };

  toCapital = (e) => {
    if (e.target.value) {
      return e.target.value.toUpperCase();
    }
    return e.target.value;
  };

  gotToHome = () => {
    this.props.history.push("/home");
  };

  render() {
    const fieldData = [];
    if (this.props.admin.currentProfile.userId) {
      const currentProfile = this.props.admin.currentProfile;
      for (let i in currentProfile) {
        if (i === "username") {
          const names = currentProfile[i].split(".");
          fieldData.push({
            name: ["firstName"],
            value: names[0].toUpperCase(),
          });
          fieldData.push({
            name: ["lastName"],
            value: names[1] ? names[1].toUpperCase() : "",
          });
        } else if (i === "user") {
          fieldData.push({
            name: ["role"],
            value: currentProfile[i].role,
          });
        } else {
          fieldData.push({
            name: [i],
            value: currentProfile[i],
          });
        }
      }
    }
    return (
      <div>
        <Card>
          <Spin tip="Loading..." spinning={this.props.admin.loading}>
            <div>
              <Form
                initialValues={{
                  remember: true,
                }}
                fields={fieldData}
                onFinish={this.onFinish}
              >
                <Form.Item
                  {...formItemLayout}
                  name="firstName"
                  label="First Name :"
                  getValueFromEvent={this.toCapital}
                  rules={[
                    {
                      required: true,
                      min: 3,
                      max: 25,
                      message: "Please enter a valid First Name.",
                    },
                    {
                      pattern: /^\S[A-Za-z\s]+$/,
                      message:
                        " Only alphabets are allowed and should not have empty spaces in between.",
                    },
                  ]}
                >
                  <Input placeholder="First Name" max="25" disabled={this.props.role === MSSSTUD}/>
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="lastName"
                  label="Last Name :"
                  getValueFromEvent={this.toCapital}
                  rules={[
                    {
                      required: true,
                      min: 1,
                      max: 25,
                      message: "Please enter a valid Lirst Name.",
                    },
                    {
                      pattern: /^\S[A-Za-z\s]+$/,
                      message: "Only be alphabets are allowed.",
                    },
                  ]}
                >
                  <Input placeholder="Last Name" max="25" disabled={this.props.role === MSSSTUD}/>
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="currentPassword"
                  label="Current Password"
                  rules={[
                    {
                      required: true,
                      max: 25,
                      message: "Please input your current password!",
                    },
                  ]}
                >
                  <Input.Password max="25" />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="password"
                  label=" New Password"
                  rules={[
                    {
                      min: 7,
                      max: 25,
                      message:
                        "Password should be atleast 7 characters length and maximum 25 characters.",
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (value && value.indexOf(' ') === 0) {
                            return Promise.reject(
                                "Password can not start with spaces."
                              );
                          }
                          return Promise.resolve();
                        },
                      }),
                  ]}
                  hasFeedback
                >
                  <Input.Password max="25" />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="confirmPassword"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if ((!value && !getFieldValue("password")) || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="mobileNumber"
                  label="Mobile Number :"
                  getValueFromEvent={this.toCapital}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid Mobile Number.",
                    },
                    {
                      pattern: /^\d{10}$/,
                      message: "Only numbers are allowed.",
                    },
                  ]}
                >
                  <Input className="full-width " placeholder="Mobile Number" disabled={this.props.role === MSSSTUD}/>
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="email"
                  label="E-mail : "
                  rules={[
                    {
                      type: "email",
                      max: 25,
                      message: "Please enter a valid E-mail address",
                    },
                    { required: true, message: "Please enter an E-mail ID" },
                  ]}
                >
                  <Input placeholder="E-mail" max="25" />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="role"
                  label="Role"
                  rules={[{ required: true, message: "Please select Role" }]}
                >
                  <Select className="full-width" disabled={true}>
                    <Option key={ADMIN}>Admin</Option>
                    <Option key={SUP2}>Program Manager</Option>
                    <Option key={SUP1}>Program Coordinator</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="address"
                  label="Home Address"
                  getValueFromEvent={this.toCapital}
                  rules={[
                    {
                      max: 60,
                      min: 10,
                      message: "Please enter a valid address",
                    }
                  ]}
                >
                  <TextArea className="full-width" max="60" />
                </Form.Item>
                {this.props.admin.errorMessage &&
                  this.showErrorNotification(
                    "error",
                    this.props.admin.errorMessage
                  )}
                {this.props.admin.successMessage &&
                  this.showErrorNotification(
                    "success",
                    this.props.admin.successMessage
                  )}
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button mg-l"
                >
                  Save
                </Button>
                <Button
                  className="login-form-button mg-l"
                  onClick={() => this.gotToHome()}
                >
                  Cancel
                </Button>
              </Form>
            </div>
          </Spin>
        </Card>
      </div>
    );
  }
}

export default EditProfileComponent;

import React from 'react';
import { Tabs, Spin, Modal, Input, Button, Card, Form } from 'antd';
import Login from './Login';
import Register from './Register';
import { Redirect } from 'react-router';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';

const { TabPane } = Tabs;

class LoginModal extends React.Component {

    formRef = React.createRef();
    
    constructor(props) {
        super(props);
        this.doRegister = this.doRegister.bind(this);
    }

    doRegister(key) {
        this.props.doRegister(key);
    }

    showRegister = () => {
        let startDate = '';
        let endDate = '';
        const sysConfig = this.props.appConfig.systemConfig;
        for (const config of sysConfig) {
            let label = "";
            if (config.configCd === "APP_START_DT") {
                startDate =  moment(config.configVal);
            } else if (config.configCd === "APP_END_DT") {
                endDate =  moment(config.configVal);
            } 
        }

        return (startDate && endDate && moment().isSameOrAfter(startDate, 'day') && moment().isSameOrBefore(endDate, 'day'));
    }

    
    onFinish = (formValues) => {
        this.props.sendPassword(formValues.username);
        this.formRef.current.resetFields();
      };

    render() {
        if (this.props.isLoggedIn && (window.location === '/' || window.location === '/login')) {
            return <Redirect to="/home" />
        }
        return (
            <div>
                <Modal
                    visible={this.props.showLogin}
                    footer={null}
                    onCancel={() => this.props.toggleLoginModal(false)}
                    width="400px"
                >
                    <div className="card-container" >
                        <Spin tip="Loading..." spinning={this.props.isLoading}>
                            <Tabs type="card" activeKey={this.props.activeTab} onTabClick={this.doRegister}>
                                <TabPane tab="Login" key="login">
                                    <Login showRegister={this.showRegister()} {...this.props}></Login>
                                </TabPane>
                                {this.showRegister() && 
                                    <TabPane tab="Register" key="register">
                                    <Register {...this.props}></Register>
                                    </TabPane>
                                }
                            </Tabs>
                        </Spin>
                    </div>
                </Modal>
                <Modal
                    visible={this.props.showForgot}
                    footer={null}
                    onCancel={() => this.props.toggleFgtPwdModal(false)}
                >

                    <div className="card-container" >
                        <Spin tip="Loading..." spinning={this.props.isLoading}>
                            <Card>
                                <div>
                                    <p>Please enter your Registered Mobile Number.</p>
                                    <Form ref={this.formRef}
                                        name="normal_login"
                                        className="login-form login-wrapper"
                                        initialValues={{
                                            remember: true,
                                        }}
                                        onFinish={this.onFinish}
                                    >
                                        <Form.Item
                                            name="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your Registered Mobile Number.',
                                                },
                                            ]}
                                        >
                                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username/ Mobile Number" />
                                        </Form.Item>
                                        <Button type="primary" htmlType="submit" className="login-form-button mg-l">
                                            Send
                                        </Button>
                                        <Button className="login-form-button mg-l" onClick={() => this.props.toggleFgtPwdModal(false)}>
                                            Cancel
                                        </Button>
                                    </Form>
                                </div>
                            </Card>
                        </Spin>
                    </div>

                </Modal>
            </div>
        );
    }
}

export default LoginModal;
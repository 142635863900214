import { IconButton } from "@material-ui/core";
import { Button, Col, Drawer, Row, Select, Spin } from "antd";
import React from "react";
import { translate } from "../../base/actions/translate";
import {
  AGR_OWN,
  ALL_SURVEY_UPLOAD_CAT,
  BUS_OWN,
  CAT_OWN,
  COMPARE_CAT,
  CONS_ITEMS,
  CONT_INFO,
  HOUSE_OWN,
  INC_SUM,
  NGHB_DTLS,
  OCC_DTLS,
  PER_INFO,
  PLOT_OWN,
  RESP_TO_MSS,
  STUD_INFO,
  UPLD_SUR,
  VEHC_INFO,
  VOL_CMNTS,
} from "../utils/consts";
import { getSurveyByKey } from "../utils/util";
import ImageViewer from "./reviewSteps/ImageViewer";
import SelectionCommitteeForm from "./SelectionCommitteeForm";
import AgricultureView from "./viewSurvey/AgricultureView";
import BusinessView from "./viewSurvey/BusinessView";
import CattleView from "./viewSurvey/CattleView";
import ConsumerItemsView from "./viewSurvey/ConsumerItemsView";
import ContactInformationView from "./viewSurvey/ContactInformationView";
import HousesView from "./viewSurvey/HousesView";
import IncomeSummaryView from "./viewSurvey/IncomeSummaryView";
import NeighbourRefView from "./viewSurvey/NeighbourRefView";
import OccupationDetailsView from "./viewSurvey/OccupationDetailsView";
import PersonalInfoView from "./viewSurvey/PersonalInfoView";
import PlotsView from "./viewSurvey/PlotsView";
import ResponseToMSSView from "./viewSurvey/ResponseToMSSView";
import StudentInfoView from "./viewSurvey/StudentInfoView";
import VehicleInfoView from "./viewSurvey/VehicleInfoView";
import VolunteerCommentsView from "./viewSurvey/VolunteerCommentsView";
import { CloseOutlined } from "@ant-design/icons";

class SurveyComparision extends React.Component {
  state = {
    currentRecord: {},
    fieldsCat: [PER_INFO],
    imagesCat: [],
    showImagesSel: false,
  };

  componentWillUnmount() {
    this.closeCompareReview();
  }

  clearCatSelction = () => {
    this.setState({ fieldsCat: [PER_INFO], imagesCat: [] });
  };

  toggleCompareReview = (flag) => {
    this.props.toggleCompareReview(flag);
  };

  closeCompareReview = () => {
    this.toggleCompareReview(false);
  };

  showDetails = (e, appl) => {
    e.preventDefault();
    this.setState({ currentRecord: appl });
    this.props.getApplicationReviewDetails(appl.applicationId);
    this.toggleCompareReview(true);
  };

  handleCatSelection = (value) => {
    // console.log("-------------------", value);
    this.setState({ fieldsCat: value });
  };

  handleUploadCatSelection = (value) => {
    // console.log("-------------------", value);
    this.setState({ imagesCat: value });
  };

  generateHeadings = () => {
    const compareList = this.props.review.compareList;
    const html = [];
    html.push(<th>Student Name</th>);
    for (const appl of compareList) {
      html.push(
        <th>
          <a href="/" onClick={(e) => this.showDetails(e, appl)}>
            {appl.studentName + " " + appl.studentSurName}
          </a>
          {appl.selectionStatus !== "SUBMIT" && (
            <span> ({translate(appl.selectionStatus)}) </span>
          )}
        </th>
      );
    }

    return html;
  };

  getCard = (appl, key) => {
    if (key === UPLD_SUR) {
      return;
    }
    switch (key) {
      case PER_INFO:
        return (
          <PersonalInfoView
            data={getSurveyByKey(appl.surveyDetails, PER_INFO)}
            stepKey={PER_INFO}
            {...this.props}
          ></PersonalInfoView>
        );

      case CONT_INFO:
        return (
          <ContactInformationView
            data={getSurveyByKey(appl.surveyDetails, CONT_INFO)}
            stepKey={CONT_INFO}
            {...this.props}
          ></ContactInformationView>
        );
      case CONS_ITEMS:
        return (
          <ConsumerItemsView
            data={getSurveyByKey(appl.surveyDetails, CONS_ITEMS)}
            stepKey={CONS_ITEMS}
            {...this.props}
          ></ConsumerItemsView>
        );
      case VEHC_INFO:
        return (
          <VehicleInfoView
            data={getSurveyByKey(appl.surveyDetails, VEHC_INFO)}
            stepKey={VEHC_INFO}
            {...this.props}
          ></VehicleInfoView>
        );
      case STUD_INFO:
        return (
          <StudentInfoView
            data={getSurveyByKey(appl.surveyDetails, STUD_INFO)}
            stepKey={STUD_INFO}
            {...this.props}
          ></StudentInfoView>
        );
      case RESP_TO_MSS:
        return (
          <ResponseToMSSView
            data={getSurveyByKey(appl.surveyDetails, RESP_TO_MSS)}
            stepKey={RESP_TO_MSS}
            {...this.props}
          ></ResponseToMSSView>
        );
      case VOL_CMNTS:
        return (
          <VolunteerCommentsView
            data={getSurveyByKey(appl.surveyDetails, VOL_CMNTS)}
            stepKey={VOL_CMNTS}
            {...this.props}
          ></VolunteerCommentsView>
        );
      case OCC_DTLS:
        return (
          <OccupationDetailsView
            data={getSurveyByKey(appl.surveyDetails, OCC_DTLS)}
            stepKey={OCC_DTLS}
            isSelComt={true}
            disableSelComt={true}
            {...this.props}
          ></OccupationDetailsView>
        );
      case AGR_OWN:
        return (
          <AgricultureView
            data={getSurveyByKey(appl.surveyDetails, AGR_OWN)}
            stepKey={AGR_OWN}
            isSelComt={true}
            disableSelComt={true}
            {...this.props}
          ></AgricultureView>
        );
      case CAT_OWN:
        return (
          <CattleView
            data={getSurveyByKey(appl.surveyDetails, CAT_OWN)}
            stepKey={CAT_OWN}
            isSelComt={true}
            disableSelComt={true}
            {...this.props}
          ></CattleView>
        );
      case PLOT_OWN:
        return (
          <PlotsView
            data={getSurveyByKey(appl.surveyDetails, PLOT_OWN)}
            stepKey={PLOT_OWN}
            isSelComt={true}
            disableSelComt={true}
            {...this.props}
          ></PlotsView>
        );
      case HOUSE_OWN:
        return (
          <HousesView
            data={getSurveyByKey(appl.surveyDetails, HOUSE_OWN)}
            stepKey={HOUSE_OWN}
            isSelComt={true}
            disableSelComt={true}
            {...this.props}
          ></HousesView>
        );
      case BUS_OWN:
        return (
          <BusinessView
            data={getSurveyByKey(appl.surveyDetails, BUS_OWN)}
            stepKey={BUS_OWN}
            isSelComt={true}
            disableSelComt={true}
            {...this.props}
          ></BusinessView>
        );
      case NGHB_DTLS:
        return (
          <NeighbourRefView
            data={getSurveyByKey(appl.surveyDetails, NGHB_DTLS)}
            stepKey={NGHB_DTLS}
            {...this.props}
          ></NeighbourRefView>
        );
      case INC_SUM:
        return (
          <IncomeSummaryView
            data={getSurveyByKey(appl.surveyDetails, INC_SUM)}
            stepKey={INC_SUM}
            isSelComt={true}
            {...this.props}
          ></IncomeSummaryView>
        );

      default:
        return <div></div>;
    }
  };

  getImageCard = (appl, key) => {
    const images = [];
    for (const i of appl.uploads) {
      if (i.type === key) {
        images.push(i);
      }
    }

    return (
      <ImageViewer
        imageList={images}
        hideRotate={true}
        year={appl.year}
        {...this.props}
      ></ImageViewer>
    );
  };

  renderComparisionRows = () => {
    const compareList = this.props.review.compareList;
    const html = [];
    const { fieldsCat, imagesCat } = this.state;
    if (fieldsCat.length === 0) {
      return html;
    }
    for (const i of fieldsCat) {
      const row = [];
      row.push(
        <td>
          <b>{translate(i)}</b>
        </td>
      );
      for (const appl of compareList) {
        row.push(<td>{this.getCard(appl, i)}</td>);
      }
      html.push(<tr>{row}</tr>);
    }

    for (const j of imagesCat) {
      const row = [];
      row.push(
        <td>
          <b>{translate(j)}</b>
        </td>
      );
      for (const appl of compareList) {
        row.push(<td>{this.getImageCard(appl, j)}</td>);
      }
      html.push(<tr>{row}</tr>);
    }
    return html;
  };

  render() {
    return (
      <Drawer
        id="compare-survey"
        title={
          <div>
            {/* <h4 style={{display: 'inline-block'}}><span style={{marginRight: '10px'}}>Comparing</span>
              </h4> */}
            <Row gutter={16}>
              <Col xs={10}>
                <Select
                  placeholder="Select categories"
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  onChange={this.handleCatSelection}
                  value={this.state.fieldsCat}
                >
                  {COMPARE_CAT.map((i) => (
                    <Select.Option key={i} value={i}>
                      {translate(i)}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={10}>
                <Select
                  placeholder="Select File Categories"
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  onChange={this.handleUploadCatSelection}
                  value={this.state.imagesCat}
                >
                  {ALL_SURVEY_UPLOAD_CAT.map((i) => (
                    <Select.Option key={i} value={i}>
                      {translate(i)}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={4} style={{ textAlign: "right" }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.props.closeCompare}
                  aria-label="close"
                  style={{
                    fontSize: "20px",
                    padding: "0px",
                  }}
                >
                  <CloseOutlined />
                </IconButton>
              </Col>
            </Row>
          </div>
        }
        footer={<div style={{ textAlign: "right" }}></div>}
        width="100%"
        placement="right"
        closable={false}
        onClose={this.props.closeCompare}
        visible={this.props.review.showCompare}
      >
        <div className="survey-comparision-container">
          <table className="comparision-table">
            <thead>{this.generateHeadings()}</thead>
            <tbody>
              {/* <tr>
                <td>Categories</td>
                <td>
                  <Select
                    placeholder="Select categories"
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    onChange={this.handleCatSelection}
                    value={this.state.fieldsCat}
                  >
                    {COMPARE_CAT.map((i) => (
                      <Select.Option key={i} value={i}>
                        {translate(i)}
                      </Select.Option>
                    ))}
                  </Select>
                </td>
                <td>
                  <Select
                    placeholder="Select File Categories"
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    onChange={this.handleUploadCatSelection}
                    value={this.state.imagesCat}
                  >
                    {ALL_SURVEY_UPLOAD_CAT.map((i) => (
                      <Select.Option key={i} value={i}>
                        {translate(i)}
                      </Select.Option>
                    ))}
                  </Select>
                </td>
              </tr> */}
              {this.renderComparisionRows()}
            </tbody>
          </table>

          <Drawer
            id="reviewers-details"
            title={
              <div>
                {this.props.review.reviewDetails.application ? (
                  <h4>
                    <strong style={{ marginLeft: "10px" }}>
                      Name:{" "}
                      {(this.props.review.reviewDetails.application
                        ?.studentName ||
                        "") +
                          " " +
                          (this.props.review.reviewDetails.application
                            ?.studentSurName ||
                        "")}
                    </strong>
                    <strong style={{ marginLeft: "10px" }}>
                      Registered Mobile Number:{" "}
                      {this.props.review.reviewDetails.application?.username}
                    </strong>
                  </h4>
                ) : (
                  ""
                )}
              </div>
            }
            footer={
              <div style={{ textAlign: "right" }}>
                <Button
                  onClick={(e) => {
                    this.props.saveSurveyData();
                  }}
                  type="primary"
                  style={{ marginRight: 8 }}
                >
                  {" "}
                  Save{" "}
                </Button>
                <Button
                  onClick={this.closeCompareReview}
                  style={{ marginRight: 8 }}
                >
                  Close
                </Button>
              </div>
            }
            width="100%"
            placement="right"
            closable={false}
            onClose={this.closeCompareReview}
            visible={this.props.review.showCompareReview}
          >
            <Spin spinning={this.props.review.loading} tip="Loading">
              {this.props.review.reviewDetails.application?.applicationId && (
                <SelectionCommitteeForm
                  currentRecord={this.state.currentRecord}
                  {...this.props}
                ></SelectionCommitteeForm>
              )}
            </Spin>
          </Drawer>
        </div>
      </Drawer>
    );
  }
}

export default SurveyComparision;

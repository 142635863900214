import React from 'react';
import { render } from 'react-dom';
import { Row, Col, Card, Button, Spin } from 'antd';
import Gallery from 'react-grid-gallery';
import '../styles/gallery.css';
import { unmountComponentAtNode } from 'react-dom';
import gal1 from '../../assets/images/MCTwebsiteGallery/1.jpg';
import gal2 from '../../assets/images/MCTwebsiteGallery/2.jpg';
import gal3 from '../../assets/images/MCTwebsiteGallery/3.jpg';
import gal4 from '../../assets/images/MCTwebsiteGallery/4.jpg';
import gal5 from '../../assets/images/MCTwebsiteGallery/5.jpg';
import gal6 from '../../assets/images/MCTwebsiteGallery/6.jpg';
import gal7 from '../../assets/images/MCTwebsiteGallery/7.jpg';
import gal8 from '../../assets/images/MCTwebsiteGallery/8.jpg';
import gal9 from '../../assets/images/MCTwebsiteGallery/9.jpg';
import gal10 from '../../assets/images/MCTwebsiteGallery/10.jpg';
import gal11 from '../../assets/images/MCTwebsiteGallery/11.jpg';
import gal12 from '../../assets/images/MCTwebsiteGallery/12.jpg';
import gal13 from '../../assets/images/MCTwebsiteGallery/13.jpg';
import gal14 from '../../assets/images/MCTwebsiteGallery/14.jpg';
import gal15 from '../../assets/images/MCTwebsiteGallery/15.jpg';
import gal16 from '../../assets/images/MCTwebsiteGallery/16.jpg';
import gal17 from '../../assets/images/MCTwebsiteGallery/17.jpg';
import gal18 from '../../assets/images/MCTwebsiteGallery/18.jpg';
import gal19 from '../../assets/images/MCTwebsiteGallery/19.jpg';
import gal20 from '../../assets/images/MCTwebsiteGallery/20.jpg';
import gal21 from '../../assets/images/MCTwebsiteGallery/21.jpg';
import gal22 from '../../assets/images/MCTwebsiteGallery/22.jpg';
import gal23 from '../../assets/images/MCTwebsiteGallery/23.jpg';
import gal24 from '../../assets/images/MCTwebsiteGallery/24.jpg';
import gal25 from '../../assets/images/MCTwebsiteGallery/25.jpg';
import gal26 from '../../assets/images/MCTwebsiteGallery/26.jpg';
import gal27 from '../../assets/images/MCTwebsiteGallery/27.jpg';
import gal28 from '../../assets/images/MCTwebsiteGallery/28.jpg';
import gal29 from '../../assets/images/MCTwebsiteGallery/29.jpg';
import gal30 from '../../assets/images/MCTwebsiteGallery/30.jpg';




const IMAGES =
    [{
        src: gal1,
        thumbnail: gal1,
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        tags: [{ value: "Helping Hands", title: "Helping Hands" }],
        caption: "Medha Helping Hands – Jyothiprajwalana during felicitation of books authors"
    },
    {
        src: gal2,
        thumbnail: gal2,
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        tags: [{ value: "Helping Hands", title: "Helping Hands" }],
        caption: "Medha Helping Hands – Books distribution drive"
    },
    {
        src: gal3,
        thumbnail: gal3,
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        tags: [{ value: "Helping Hands", title: "Helping Hands" }],
        caption: "Medha Helping Hands – Books distribution drive"
    },
    {
        src: gal4,
        thumbnail: gal4,
        thumbnailWidth: 320,
        thumbnailHeight: 213,
        tags: [{ value: "Helping Hands", title: "Helping Hands" }],
        caption: "Medha Helping Hands – Felicitation of books authors"
    },
    {
        src: gal5,
        thumbnail: gal5,
        thumbnailWidth: 320,
        thumbnailHeight: 183,
        tags: [{ value: "Helping Hands", title: "Helping Hands" }],
        caption: "Medha Helping Hands – Felicitation of books authors"
    },
    {
        src: gal6,
        thumbnail: gal6,
        thumbnailWidth: 440,
        thumbnailHeight: 320,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Dignity drive, a health and wellness awareness programme"
    },
    {
        src: gal7,
        thumbnail: gal7,
        thumbnailWidth: 320,
        thumbnailHeight: 190,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Eye check-up done in coordination with LV Prasad Eye Institute"
    },
    {
        src: gal8,
        thumbnail: gal8,
        thumbnailWidth: 320,
        thumbnailHeight: 148,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Medical check-up for girls"
    },
    {
        src: gal9,
        thumbnail: gal9,
        thumbnailWidth: 320,
        thumbnailHeight: 213,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Rangoli competition for girls"
    },
    {
        src: gal10,
        thumbnail: gal10,
        thumbnailWidth: 348,
        thumbnailHeight: 320,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Yoga and pranayama practice for students in Intermediate college"
    },
    {
        src: gal24,
        thumbnail: gal24,
        thumbnailWidth: 220,
        thumbnailHeight: 113,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Sponsored students of Medha Charitable Trust"
    },
    {
        src: gal12,
        thumbnail: gal12,
        thumbnailWidth: 313,
        thumbnailHeight: 320,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Volleyball competition for boys"
    },
    {
        src: gal13,
        thumbnail: gal13,
        thumbnailWidth: 320,
        thumbnailHeight: 213,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Sports and games competitions"
    },
    {
        src: gal14,
        thumbnail: gal14,
        thumbnailWidth: 320,
        thumbnailHeight: 194,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Coco competition for girls"
    },
    {
        src: gal15,
        thumbnail: gal15,
        thumbnailWidth: 320,
        thumbnailHeight: 213,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Sports and games competitions"
    },
    {
        src: gal16,
        thumbnail: gal16,
        thumbnailWidth: 271,
        thumbnailHeight: 320,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Cricket competition"
    },
    {
        src: gal17,
        thumbnail: gal17,
        thumbnailWidth: 320,
        thumbnailHeight: 213,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Annual day celebrations"
    },
    {
        src: gal18,
        thumbnail: gal18,
        thumbnailWidth: 320,
        thumbnailHeight: 213,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Annual day celebrations"
    },
    {
        src: gal19,
        thumbnail: gal19,
        thumbnailWidth: 320,
        thumbnailHeight: 213,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Annual day celebrations"
    },
    {
        src: gal20,
        thumbnail: gal20,
        thumbnailWidth: 320,
        thumbnailHeight: 213,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Annual day celebrations"
    },
    {
        src: gal21,
        thumbnail: gal21,
        thumbnailWidth: 320,
        thumbnailHeight: 179,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Annual day celebrations"
    },
    {
        src: gal22,
        thumbnail: gal22,
        thumbnailWidth: 320,
        thumbnailHeight: 215,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Annual day celebrations"
    },
    {
        src: gal23,
        thumbnail: gal23,
        thumbnailWidth: 450,
        thumbnailHeight: 320,
        tags: [{ value: "Sampurna Siksha", title: "Sampurna Siksha" }],
        caption: "Medha Sampurna Siksha – Annual day celebrations"
    },
    {
        src: gal11,
        thumbnail: gal11,
        thumbnailWidth: 550,
        thumbnailHeight: 320,
        caption: "Medha Charitable Trust team members"
    },
    {
        src: gal25,
        thumbnail: gal25,
        thumbnailWidth: 326,
        thumbnailHeight: 320,
        tags: [{ value: "Naipunya Nirmana", title: "Naipunya Nirmana" }],
        caption: "Medha Naipunya Nirmana – Skill training for sponsored candidates at SRTRI for self-employed tailor with zardozi and ornamental work course"
    },
    {
        src: gal26,
        thumbnail: gal26,
        thumbnailWidth: 426,
        thumbnailHeight: 320,
        tags: [{ value: "Naipunya Nirmana", title: "Naipunya Nirmana" }],
        caption: "Medha Naipunya Nirmana – Skill training for sponsored candidates at CIPET for CNC lathe and milling machine operator course"
    },
    {
        src: gal27,
        thumbnail: gal27,
        thumbnailWidth: 326,
        thumbnailHeight: 320,
        tags: [{ value: "Naipunya Nirmana", title: "Naipunya Nirmana" }],
        caption: "Medha Naipunya Nirmana – Skill training for sponsored candidates at CIPET for plastic processing machine operator course"
    }, {
        src: gal28,
        thumbnail: gal28,
        thumbnailWidth: 426,
        thumbnailHeight: 320,
        tags: [{ value: "Naipunya Nirmana", title: "Naipunya Nirmana" }],
        caption: "Medha Naipunya Nirmana – Sponsored students at CIPET for Diploma in Plastics Mould Technology programme"
    },
    {
        src: gal29,
        thumbnail: gal29,
        thumbnailWidth: 226,
        thumbnailHeight: 320,
        tags: [{ value: "Vidyalaya Navikarana", title: "Vidyalaya Navikarana" }],
        caption: "Medha Vidyalaya Navikarana – Renovation of toilet block at Government Junior College in Nampally"
    },
    {
        src: gal30,
        thumbnail: gal30,
        thumbnailWidth: 226,
        thumbnailHeight: 320,
        tags: [{ value: "Vidyalaya Navikarana", title: "Vidyalaya Navikarana" }],
        caption: "Medha Vidyalaya Navikarana – Renovation of toilet block at Government Vocational Junior College for boys in Bazarghat"
    }
    ]
class About extends React.Component {
    render() {
        return (

            <Card bordered={false} className="card-92 mg-l about-main-container">
                <title>About page</title>
                <meta
                    name="description"
                    content="Our prime objective is to empower India through education. Our initiativeshave helped over 22 lakh students since inception."
                />
                {/* <div className="about-title">ABOUT</div> */}
                <Card bordered={false} className="card-95 mg-l">
                    <h2> Our Story</h2>
                    {/* <iframe src='https://www.youtube.com/embed/fzzI_cgoBUQ?controls=0;showinfo=0;rel=0;'
                        className='medha-player'
                        allow='autoplay; encrypted-media'
                        allowfullscreen='true'
                        title='video'
                        autoplay='true'
                        name='Medha Charitable Trust'
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        referrerpolicy='no-referrer'
                        showinfo='false'
                    /> */}
                    <div className="about-content"> <span className="about-years">O</span>ur journey began in 2006 when a few prominent members of Medha group of companies wanted to contribute to society by moving beyond regular business activities. With a prime objective to develop meaningful initiatives which help the most needy, Medha Charitable Trust was established.<br></br></div>
                    <div className="about-content"> <span className="about-years">A </span> humble effort to help students of one government college in Nalgonda district of Telangana state, has spiralled into a massive effort of providing study material and prescribed text books to over 4 lakh students studying in government colleges each year. An extension of which, to support few merit students had eventually become a continuous initiative to sponsor 100 underprivileged merit students each year to reach their potential. An aim to help the jobless youth to become qualified skilled workforce is now helping youth to be a part of growing Indian manufacturing industry. An effort to provide functional toilets to students of government colleges has turned into a welcoming change for all. <br></br></div>
                    <div className="about-content"> <span className="about-years">A</span>s we strive for further development and continuously evolve with the ever changing requirements of society, it is our goal to provide more opportunities for the youth of our nation, to make them lead a self-sustaining and respectable life which can be achieved only by gaining quality education.<br></br> </div>
                    <h2>Our Leadership</h2>
                    <p>
                        Shri Y. Yugandhar Reddy, a visionary with keen spiritual mind and a sincere desire to help the underserved, started Medha Charitable Trust in 2006, and had since then committed to improving quality of education in India. Our board comprises of Shri Y. Yugandhar Reddy, Promoter and Chairman of Medha Servo Drives Pvt. Ltd., Smt. Y. Lakshmi, serving as Managing Trustee of Medha Charitable Trust, Shri. P.S. Babu, Promoter and Director of Medha Servo Drives Pvt. Ltd. and Shri. Y. Mohana Rao, Chairman of Sirveen Control Systems Pvt. Ltd.
                    </p>

                    <Row>
                        <Col xs={24} sm={8}>
                            <div className="card">
                                <div className="card-content">
                                    <h1>Our Team</h1>
                                    <p>
                                        We are a spirited team of 12 members with a genuine interest in serving our society. With the blessing of right resources and direction, we strive to reach our aim of improving quality of education in India. The support structure of over 300 employees of Medha Servo Drives Pvt. Ltd., who volunteer in various activities, boasts our enthusiasm and magnifies the excitement in every target we achieve.
                                    </p>
                                </div>
                                <div className="card-reveal card-reveal-1 card-reveal-left">
                                    <p className="card-title"> <h2>Our Team</h2></p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={8}>
                            <div className="card">
                                <div className="card-content">
                                    <h1>Our Aim</h1>
                                    <p>
                                        Our aim is to improve the quality of education in India. We believe that if we help one person get educated, their family and future generations of their families will have the ability and desire to get educated.
                                    </p>
                                </div>
                                <div className="card-reveal card-reveal-2 card-reveal-up">
                                    <p className="card-title">  <h2>Our Aim</h2></p>
                                </div>
                            </div>
                            
                        </Col>
                        <Col xs={24} sm={8}>
                            <div className="card">
                                <div className="card-content">
                                    <h1>Our Core Values</h1>
                                    <p>
                                        We strongly believe in incorporating our value system of efficiency, effectiveness, accountability and integrity in every effort we make. Our policies are driven by these core values and all our projects are successful because our entire team respects and understands the need to make every decision based on our core values.
                                    </p>
                                </div>
                                <div className="card-reveal card-reveal-3 card-reveal-right">
                                    <p className="card-title"><h2>Our Core Values</h2></p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Card>
                <div className="gallery-container">
                    <h2>Gallery</h2>
                    <Gallery images={IMAGES}
                        showLightboxThumbnails={true} />,

            </div>


            </Card>

        );
    }
}

export default About;
export const isUserLoggedIn = () => {
    return sessionStorage.getItem('x-auth-token')? true : false;
}

export const getLoggedInUser = () => {
    if(isUserLoggedIn()) {
        return JSON.parse(sessionStorage.getItem('userPrincipal'));
    }
}

export const saveUserProfile = (userPrincipal) => {
    userPrincipal.role = userPrincipal.authorities[0].authority;
    const jsonString = JSON.stringify(userPrincipal);
    sessionStorage.setItem('userPrincipal', jsonString);
}

export const getCurrentUserObj = () => {
    const userProfString = sessionStorage.getItem('userPrincipal');
    if(!userProfString) {
       return {}; 
    }
    const userObj = JSON.parse(userProfString);
    return userObj;
}

export const getCurrentUserRole = () => {
    const userObj = getCurrentUserObj();
    if(!userObj || ! userObj.role) {
        return '';
    }
    return userObj.role;
}
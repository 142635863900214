import { RESET_MESSAGES } from "../../base/utils/const";
import { INIT_LOAD, MSG_MAX_LIMIT } from "../../common/utils/const";
import { GET_NOTES, LOAD_MORE_NOTES, NOTES_ADD_TO_DEL_FILES, NOTES_APPROVED_DRAFTS, NOTES_DEL_DRAFT, NOTES_DRAFT_IMG, NOTES_ROTATE_IMG, SAVE_NOTES } from "../utils/const";

const initialState = {
  messages: [],
  loading: false,
  successMessage: "",
  errorMessage: "",
  loadType: INIT_LOAD,
  hasMore: false,
  currentUploads: [],
  deletedImages: [],
};

const StudentNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOAD_MORE_NOTES}_PENDING`:
    case `${GET_NOTES}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${GET_NOTES}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        messages: action.payload.sort(
          (a, b) => a.studentNotesId - b.studentNotesId
        ),
        loadType: INIT_LOAD,
        hasMore: MSG_MAX_LIMIT === action.payload?.length,
      };
    }

    case `${LOAD_MORE_NOTES}_REJECTED`:
    case `${GET_NOTES}_REJECTED`: {
      return {
        ...state,
        loading: true,
        errorMessage: action.payload.data,
      };
    }

    case `${LOAD_MORE_NOTES}_FULFILLED`: {
      const newMsgs = action.payload.sort(
        (a, b) => a.studentNotesId - b.studentNotesId
      );
      const totalMsgs = newMsgs.concat(state.messages);
      return {
        ...state,
        loading: false,
        messages: totalMsgs,
        loadType: LOAD_MORE_NOTES,
        hasMore: MSG_MAX_LIMIT === action.payload?.length,
      };
    }

    case `${SAVE_NOTES}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${SAVE_NOTES}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        currentUploads: []
      };
    }

    case `${SAVE_NOTES}_REJECTED`: {
      return {
        ...state,
        loading: true,
        errorMessage: action.payload.data,
      };
    }

    case `${NOTES_DRAFT_IMG}_PENDING`: {
        return {
          ...state,
          loading: true,
          errorMessage: "",
          successMessage: "",
        };
      }
  
      case `${NOTES_DRAFT_IMG}_FULFILLED`: {
        const image = action.payload;
        const { currentUploads } = state;
        currentUploads.push(image);
        for (const i of currentUploads) {
          i.managementUploadId = i.studentNotesFileId;
          i.type='NOTES';
        }
  
        return {
          ...state,
          loading: false,
          currentUploads: currentUploads,
        };
      }
  
      case `${NOTES_DRAFT_IMG}_REJECTED`: {
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };
      }
  
      case `${NOTES_DEL_DRAFT}_PENDING`: {
        return {
          ...state,
          loading: true,
          errorMessage: "",
          successMessage: "",
          deletedImages: [],
        };
      }
  
      case `${NOTES_DEL_DRAFT}_FULFILLED`: {
        return {
          ...state,
          loading: false,
        };
      }
  
      case `${NOTES_DEL_DRAFT}_REJECTED`: {
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };
      }
  
      case `${NOTES_APPROVED_DRAFTS}_PENDING`: {
        return {
          ...state,
          loading: true,
          errorMessage: "",
          successMessage: "",
        };
      }
  
      case `${NOTES_APPROVED_DRAFTS}_FULFILLED`: {
        return {
          ...state,
          loading: false,
        };
      }
  
      case `${NOTES_APPROVED_DRAFTS}_REJECTED`: {
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };
      }
  
      case `${NOTES_ROTATE_IMG}_PENDING`: {
        return {
          ...state,
          loading: true,
          successMessage: "",
          errorMessage: "",
        };
      }
  
      case `${NOTES_ROTATE_IMG}_REJECTED`: {
        return {
          ...state,
          errorMessage: action.payload,
          loading: false,
        };
      }
  
      case `${NOTES_ROTATE_IMG}_FULFILLED`: {
        const image = action.payload;
        const { currentUploads } = state;
        for (const i of currentUploads) {
          if (
            i.type === image.type &&
            i.managementUploadId === image.managementUploadId
          ) {
            i.angle = image.angle;
          }
        }
  
        return {
          ...state,
          loading: false,
          currentUploads: currentUploads,
          // successMessage: 'Image updated successfully'
        };
      }

    case NOTES_ADD_TO_DEL_FILES: {
        const deletedImages = state.deletedImages.concat(action.payload);
        const { currentUploads } = state;
        for (const image of action.payload) {
          for (let i = 0; i < currentUploads.length; i++) {
            if (
              currentUploads[i].type === image.type &&
              currentUploads[i].managementUploadId === image.managementUploadId
            ) {
              currentUploads.splice(i, 1);
            }
          }
        }
  
        return {
          ...state,
          deletedImages: deletedImages,
          currentUploads: currentUploads,
        };
      }

    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    }

    default:
      return state;
  }
};

export default StudentNotesReducer;

import React from "react";
import {
  Spin,
  Modal,
  Input,
  Button,
  Card,
  Form,
  Select,
  Alert,
  notification,
} from "antd";
import { DSTIN, SELCOMT } from "../../base/utils/roles";
import TextArea from "antd/lib/input/TextArea";
import { ACCESS_LEVELS } from "../utils/consts";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class AddUserModal extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      showDistrict: false,
    };
  }

  componentWillMount() {
    this.props.getOldDistricts();
  }

  onFinish = (formValues) => {
    // console.log('Form-------------------', formValues);
    if (this.props.admin.currentProfile.userId) {
      this.props.editUser(formValues, this.props.admin.currentProfile);
    } else {
      this.props.createUser(formValues);
    }
    // this.formRef.current.resetFields();
  };

  onCancel = () => {
    this.formRef.current.resetFields();
    this.props.toggleAddUserModal(false);
  };

  toCapital = (e) => {
    if (e.target.value) {
      return e.target.value.toUpperCase();
    }
    return e.target.value;
  };

  showNotification = (msgType, message) => {
    const that = this;
    setTimeout(function () {
      that.props.resetMessages();
    }, 3000);
    notification[msgType]({
      message: message,
      description: message,
    });
    if (msgType === "success") {
      this.formRef.current.resetFields();
    }
    return <Alert message={message} type={msgType} />;
  };

  toggleDistrict = (value) => {
    const currentProfile = this.props.admin.currentProfile;
    if (DSTIN === value || SELCOMT === value) {
      this.setState({ showDistrict: true });
    } else {
      this.setState({ showDistrict: false });
    }
    currentProfile.user.role = value;
    this.props.setEditData(currentProfile);
    return value;
  };

  render() {
    const distList = this.props.appConfig.oldDistrictsList;
    const fieldData = [];
    let cardTitle = "Add User";
    let showDist = this.state.showDistrict;
    let disableNames = false;
    if (this.props.admin.currentProfile.userId) {
      cardTitle = "Edit User";
      disableNames = true;
      const currentProfile = this.props.admin.currentProfile;
      showDist =
        currentProfile.user.role === DSTIN ||
        currentProfile.user.role === SELCOMT;
      for (let i in currentProfile) {
        if (i === "username") {
          const names = currentProfile[i].split(".");
          fieldData.push({
            name: ["firstName"],
            value: names[0].toUpperCase(),
          });
          fieldData.push({
            name: ["lastName"],
            value: names[1].toUpperCase(),
          });
        } else if (i === "user") {
          fieldData.push({
            name: ["role"],
            value: currentProfile[i].role,
          });
        } else {
          fieldData.push({
            name: [i],
            value: currentProfile[i],
          });
        }
      }
    }
    return (
      <div>
        <Modal
          visible={this.props.admin.showAddUser}
          footer={null}
          onCancel={() => this.props.toggleAddUserModal(false)}
        >
          <div className="card-container">
            <Spin tip="Loading..." spinning={this.props.admin.loading}>
              <Card title={cardTitle}>
                <div>
                  <Form
                    initialValues={{
                      remember: true,
                    }}
                    fields={fieldData}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                  >
                    <Form.Item
                      {...formItemLayout}
                      name="firstName"
                      label="First Name :"
                      getValueFromEvent={this.toCapital}
                      rules={[
                        {
                          required: true,
                          min: 3,
                          max: 25,
                          pattern: /^\S[A-Za-z]+$/,
                          message:
                            "Please enter a valid First Name. Only alphabets are allowed and there should not be any empty spaces.",
                        },
                      ]}
                    >
                      <Input placeholder="First Name" disabled={disableNames} />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout}
                      name="lastName"
                      label="Last Name :"
                      getValueFromEvent={this.toCapital}
                      rules={[
                        {
                          required: true,
                          min: 3,
                          max: 25,
                          pattern: /^\S[A-Za-z\s]+$/,
                          message:
                            "Please enter a valid Last Name. Only alphabets are allowed.",
                        },
                      ]}
                    >
                      <Input placeholder="Last Name" disabled={disableNames} />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout}
                      name="mobileNumber"
                      label="Mobile Number :"
                      getValueFromEvent={this.toCapital}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a valid Mobile Number.",
                        },
                        {
                          pattern: /^\d{10}$/,
                          message: "Only numbers are allowed.",
                        },
                      ]}
                    >
                      <Input
                        className="full-width "
                        placeholder="Mobile Number"
                      />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout}
                      name="email"
                      label="E-mail : "
                      rules={[
                        {
                          type: "email",
                          max: 25,
                          required: true,
                          message: "Please enter a valid E-mail address",
                        },
                      ]}
                    >
                      <Input placeholder="E-mail" />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout}
                      name="role"
                      label="Role"
                      rules={[
                        { required: true, message: "Please select Role" },
                      ]}
                    >
                      <Select
                        className="full-width"
                        onChange={this.toggleDistrict}
                      >
                        {ACCESS_LEVELS.map((role) => (
                          <Option key={role.code} value={role.code}>
                            {role.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {showDist && (
                      <Form.Item
                        {...formItemLayout}
                        name="districtId"
                        label="District : "
                        rules={[
                          {
                            required: true,
                            message: "Please select a District",
                          },
                        ]}
                      >
                        <Select className="full-width">
                          {distList.map((dist) => (
                            <Option key={dist.id} value={dist.id}>
                              {dist.configVal}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                    <Form.Item
                      {...formItemLayout}
                      name="address"
                      label="Home Address"
                      getValueFromEvent={this.toCapital}
                      rules={[
                        {
                          max: 60,
                          min: 10,
                          message: "Please enter a valid address",
                        }
                      ]}
                    >
                      <TextArea className="full-width" />
                    </Form.Item>
                    {this.props.admin.errorMessage &&
                      this.showNotification(
                        "error",
                        this.props.admin.errorMessage
                      )}
                    {this.props.admin.successMessage &&
                      this.showNotification(
                        "success",
                        this.props.admin.successMessage
                      )}
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button mg-l"
                    >
                      Save
                    </Button>
                    <Button
                      className="login-form-button mg-l"
                      onClick={() => this.onCancel()}
                    >
                      Cancel
                    </Button>
                  </Form>
                </div>
              </Card>
            </Spin>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AddUserModal;

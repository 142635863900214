import React from "react";
import {
  Card,
  Input,
  Spin,
  Table,
  notification,
  Button,
  Modal,
  Select,
  message,
} from "antd";
import { translate } from "../../base/actions/translate";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { ADMIN } from "../../base/utils/roles";
import { sortNames } from "../utils/util";

const { Search, TextArea } = Input;
const { Option } = Select;

class AddSchoolRequestTasks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDataSource: [],
      searchText: "",
      showReasonModal: false,
      reason: {
        id: "",
        schoolName: "",
        reason: "",
        status: "",
      },
      workflow: {},
      denyTemplateId: "",
    };
  }

  componentWillMount = () => {
    this.props.getTaskList("ALL");
    // Add School Request Deny Reason SMS templates is 22
    this.props.getSMSTemplateByCode(21);
  };

  showNotifications = () => {
    let message = "";
    // if (this.props.student.successMessage) {
    //     message += this.props.student.successMessage;
    // }

    if (this.props.sampurnaSiksha.successMessage) {
      message += this.props.sampurnaSiksha.successMessage;
    }

    if (message) {
      notification.success({
        message: "Success",
        description: message,
      });
      this.props.resetMessage();
    }
  };

  closeReasonModal = () => {
    this.setState({ showReasonModal: false });
  };

  updateMandal = (value) => {
    const workflow = this.state.workflow;
    workflow.mandalId = value;
    this.setState({ workflow: workflow });
  };

  updateSchoolName = (e) => {
    const workflow = this.state.workflow;
    workflow.schoolName = e.target.value;
    this.setState({ workflow: workflow });
  };

  updateReason = (e) => {
    const workflow = this.state.workflow;
    workflow.reason = e.target.value;
    this.setState({ workflow: workflow });
  };

  updateDenyReason = (templateId) => {
    for (const temp of this.props.sms.smsTemplateList) {
      if (temp.smsTemplateId === templateId) {
        const workflow = this.state.workflow;
        workflow.reason = temp.smsTemplateValue;
        this.setState({ workflow: workflow, denyTemplateId: templateId });
      }
    }
  };

  saveWorkflow = () => {
    if (
      !this.state.workflow.reason ||
      this.state.workflow.reason.trim().length === 0
    ) {
      message.error("Please specify reason.");
      return;
    }
    this.props.changeTaskStatus(this.state.workflow, "ALL");
    this.setState({ showReasonModal: false });
  };

  changeStatus = (task, status) => {
    if (this.props.role === ADMIN) {
      const d = this.getConfig(task.entity, "DIST");
      this.props.getMandalsList(d.id);
    }
    const workflow = {};
    workflow.workflowId = task.workflowId;
    workflow.entityId = task.entityId;
    let config = task.entity;
    while (config.parentId && config.configCd !== "ODIST") {
      config = config.parent;
    }
    workflow.prefix = config.info;
    workflow.status = status;
    workflow.reason = "";
    workflow.schoolName = task.entity.configVal;

    workflow.mandalId = this.getConfig(task.entity, "MNDL")
      ? this.getConfig(task.entity, "MNDL").id
      : "";
    this.setState({ workflow: workflow, showReasonModal: true, denyTemplateId: "" });
    // this.props.changeTaskStatus(workflow);
  };

  approve = (task) => {
    if (this.props.role === ADMIN) {
      this.changeStatus(task, "APRV");
    } else {
      this.changeStatus(task, "SAPRV");
    }
  };

  showDenyReasonModal = () => {};

  deny = (task) => {
    if (this.props.role === ADMIN) {
      this.changeStatus(task, "DENY");
    } else {
      this.changeStatus(task, "SDENY");
    }
  };

  getInfo = (info, field) => {
    info = JSON.parse(info);
    return info[field];
  };

  getComments = (comments) => {
    const html = [];
    if (comments.length) {
      for (const comment of comments) {
        html.push(<p>{comment.comment}</p>);
      }
    }
    return html;
  };

  getConfigVal(config, code) {
    let c = config;
    if (c) {
      while (c.configCd !== code) {
        c = c.parent;
      }
    }

    return c.configVal;
  }

  getConfig = (config, code) => {
    let c = config;
    if (c) {
      while (c.configCd !== code) {
        c = c.parent;
      }
    }

    return c;
  };

  onFiltersChange = (pagination, filters, sorter, extra) => {
    // console.log(
    //   "on filter change ------ : ",
    //   pagination,
    //   filters,
    //   sorter,
    //   extra
    // );
    this.setState({ currentDataSource: extra.currentDataSource });
  };

  // showDetails = (record, index) => {
  //   console.log("-------------", record);
  // };

  offlineSearch = (value) => {
    this.props.offlineSearch(value, this.props.sampurnaSiksha.taskList);
    this.setState({ searchText: value });
  };

  render() {
    const USERS_LIST_COLS = [
      {
        title: "Registered Mobile Number",
        dataIndex: "username",
        key: "username",
        render: (text, record) => record.requester.username,
        sorter: {
          compare: (a, b) =>
            sortNames(a.requester.username, b.requester.username),
        },
      },
      {
        title: "School Name",
        dataIndex: "configVal",
        key: "configVal",
        render: (text, record) => record.entity.configVal,
        sorter: {
          compare: (a, b) => sortNames(a.entity.configVal, b.entity.configVal),
        },
      },
      {
        title: "District ",
        dataIndex: "districtId",
        key: "districtId",
        render: (text, record) => this.getConfigVal(record.entity, "DIST"),
        sorter: {
          compare: (a, b) =>
            sortNames(
              this.getConfigVal(a.entity, "DIST"),
              this.getConfigVal(b.entity, "DIST")
            ),
        },
      },
      {
        title: "Mandal",
        dataIndex: "mandal",
        key: "mandal",
        render: (text, record) => this.getConfigVal(record.entity, "MNDL"),
        sorter: {
          compare: (a, b) =>
            sortNames(
              this.getConfigVal(a.entity, "MNDL"),
              this.getConfigVal(b.entity, "MNDL")
            ),
        },
      },
      {
        title: "Headmaster's Name",
        dataIndex: "headmasterName",
        key: "headmasterName",
        render: (text, record) => this.getInfo(record.info, "headmasterName"),
        sorter: {
          compare: (a, b) =>
            sortNames(
              this.getInfo(a.info, "headmasterName"),
              this.getInfo(b.info, "headmasterName")
            ),
        },
      },
      {
        title: "Headmaster's Phone",
        dataIndex: "headmasterPhone",
        key: "headmasterPhone",
        render: (text, record) => this.getInfo(record.info, "headmasterPhone"),
        sorter: {
          compare: (a, b) =>
            sortNames(
              this.getInfo(a.info, "headmasterPhone"),
              this.getInfo(b.info, "headmasterPhone")
            ),
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => translate(record.status),
        sorter: {
          compare: (a, b) => sortNames(a.status, b.status),
        },
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "status",
        render: (text, record) => this.getComments(record.comments),
        sorting: false,
        width: "250px",
      },
      {
        title: "Approve/Deny",
        dataIndex: "action",
        key: "action",
        render: (text, record) => (
          <div>
            {(record.status !== "APRV" && record.status !== "DENY")  && (
              <div>
                <Button
                  type="primary"
                  shape="round"
                  icon={<CheckOutlined />}
                  size="small"
                  onClick={(e) => this.approve(record, e)}
                  style={{ marginRight: "10px" }}
                />
                <Button
                  type="primary"
                  shape="round"
                  icon={<CloseOutlined />}
                  size="small"
                  onClick={(e) => this.deny(record, e)}
                />
              </div>
            )}
          </div>
        ),
        width: "150px",
      },
    ];

    return (
      <div>
        <Card>
          <Search
            placeholder="Search"
            value={this.state.searchText}
            onChange={(e) => this.offlineSearch(e.target.value)}
            onSearch={(value) => this.offlineSearch(value)}
            enterButton
            style={{ marginBottom: "10px" }}
          />
        </Card>
        <div className="mg-l">
          <Spin spinning={this.props.sampurnaSiksha.loading} delay={500}>
            <Table
              columns={USERS_LIST_COLS}
              className="row-selection"
              dataSource={this.props.sampurnaSiksha.filteredTasks}
            />
          </Spin>
        </div>
        {this.props.sampurnaSiksha.successMessage && this.showNotifications()}

        <Modal
          title={
            ["DENY", "SDENY"].includes(this.state.workflow.status)
              ? "Reason for Deny request"
              : "Reason for Approve request"
          }
          visible={this.state.showReasonModal}
          onOk={this.saveWorkflow}
          onCancel={this.closeReasonModal}
          maskClosable={false}
          width="70%"
        >
          <div>
            <Spin
              spinning={
                this.props.sampurnaSiksha.loading ||
                this.props.appConfig.loading
              }
              delay={500}
            >
              {this.props.role === ADMIN && (
                <div>
                  <div style={{ margin: "8px" }}>
                    <label>Mandal</label>
                    <Select
                      className="full-width"
                      onChange={this.updateMandal}
                      value={this.state.workflow.mandalId}
                    >
                      {this.props.mandalsList.map((mandal) => (
                        <Option key={mandal.id} value={mandal.id}>
                          {mandal.configVal}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div style={{ margin: "8px" }}>
                    <label>School Name</label>
                    <Input
                      placeholder="Basic usage"
                      value={this.state.workflow.schoolName}
                      onChange={this.updateSchoolName}
                    />
                  </div>
                  {this.state.workflow.status === "DENY" ? (
                    <div style={{ margin: "8px" }}>
                      <label>Deny Reason</label>
                      <Select
                        className="full-width"
                        onChange={(e) => this.updateDenyReason(e)}
                        value={this.state.denyTemplateId}
                      >
                        {this.props.sms.smsTemplateList.map((temp) => (
                          <Option
                            key={temp.smsTemplateId}
                            value={temp.smsTemplateId}
                          >
                            {temp.smsTemplateValue}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div style={{ margin: "8px" }}>
                <label>Reason</label>
                <TextArea
                  maxLength="350"
                  value={this.state.workflow.reason}
                  onChange={this.updateReason}
                />
              </div>
            </Spin>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AddSchoolRequestTasks;

import React from 'react';
import { Spin, Row, Col } from 'antd';
import { translate } from '../../base/actions/translate';
import { STATUS_LIST } from '../utils/consts';
import SummaryCount from './SummaryCount';

class SchoolStatusSummary extends React.Component {

    componentWillMount() {
        this.loadSchoolSummary();
    }

    loadSchoolSummary = () => {
        const application = this.props.student.applicationForm;
        this.props.getSchoolStatusSummary(application.schoolId, application.year);
    }

    statusClicked = (status) => {
        console.log('status clicked ----- :', status);
    }

    render() {
        return (
            <div className="school-summary-container">
                <SummaryCount loading={this.props.sampurnaSiksha.loading} 
                summaryList={this.props.sampurnaSiksha.schoolStatusSummary}
                statusClicked={this.statusClicked} isActionItem={false}/>
            </div>
        );
    }
}

export default SchoolStatusSummary;
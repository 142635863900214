import React from "react";
import { Form, Input, Row, Col, DatePicker, Select, Radio, Modal } from "antd";
import { translate } from "../../base/actions/translate";
import { Link } from "react-router-dom";
import moment from "moment";
import { getLoggedInUser } from "../../service/SessionStorageService";

const dateFormat = "DD/MM/YYYY";

const { TextArea } = Input;
const { Option } = Select;

// const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 16 },
// };
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class BasicForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: getLoggedInUser(),
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 500);
  }

  populateMandals = (value) => {
    this.props.getMandalsList(value);
    this.props.studentData.mssApplicationForm.current.setFieldsValue({
      mandalId: "",
      schoolId: "",
    });
  };

  populateSchools = (value) => {
    this.props.getSchoolsList(value);
    this.props.studentData.mssApplicationForm.current.setFieldsValue({
      schoolId: "",
    });
  };

  checkSchool = (value) => {
    // console.log("----------------------school: ", value);
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return (
      current.year() < moment().subtract(18, "years").year() ||
      current.year() > moment().subtract(13, "years").year()
    );
  };

  getDefaultPickerValue = () => {
    if (this.props.studentData.applicationForm.dateOfBirth) {
      return moment(this.props.studentData.applicationForm.dateOfBirth);
    } else {
      return moment().subtract(18, "years");
    }
  };

  toCapital = (e) => {
    if (e.target.value) {
      return e.target.value.toUpperCase();
    }
    return e.target.value;
  };

  redirectToAddSchl = (e) => {
    e.preventDefault();
    this.props.resetWorkflowData();
    this.props.history.push('/addschool');
  }

  render() {
    let username = this.props.username;
    if (!username) {
      username = this.props.profile.username;
    }

    return (
      <div className="mg-l">
        {/* <Form  {...layout} name="student-application" 
                            // onFieldsChange={this.props.handleApplicationFormFiels}
                            onValuesChange={this.props.handleApplicationFormFiels}
                            // onFinish={this.submintBasicForm}
                            initialValues={this.props.mssApplicationFormFields}
                            ref={this.formRef}> */}
        <Row>
          {/* <Col xs={0} sm={2}>
                    </Col> */}
          <Col xs={24} sm={20}>
            <Row justify="space-between">
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="studentName"
                  label={translate("studentName")}
                  getValueFromEvent={this.toCapital}
                  rules={[
                    {
                      required: true,
                      min: 3,
                      pattern: /^\S[A-Za-z\s]+$/,
                      message: translate("studentNameError"),
                    },
                  ]}
                >
                  <Input maxLength="25" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="studentSurName"
                  label={translate("studentSurName")}
                  getValueFromEvent={this.toCapital}
                  rules={[
                    {
                      required: true,
                      min: 3,
                      pattern: /^\S[A-Za-z\s]+$/,
                      message: translate("studentSurNameError"),
                    },
                  ]}
                >
                  <Input maxLength="25" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="fatherName"
                  label={translate("fatherName")}
                  getValueFromEvent={this.toCapital}
                  rules={[
                    {
                      required: true,
                      min: 3,
                      pattern: /^\S[A-Za-z\s]+$/,
                      message: translate("fatherNameError"),
                    },
                  ]}
                >
                  <Input maxLength="25" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="motherName"
                  label={translate("mothersName")}
                  getValueFromEvent={this.toCapital}
                  rules={[
                    {
                      required: true,
                      min: 3,
                      pattern: /^\S[A-Za-z\s]+$/,
                      message: translate("mothersNameError"),
                    },
                  ]}
                >
                  <Input maxLength="25" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="dateOfBirth"
                  label={translate("dateOfBirth")}
                  rules={[{ required: true, message: translate("dobError") }]}
                >
                  <DatePicker
                    format={dateFormat}
                    className="full-width"
                    showToday={false}
                    disabledDate={this.disabledDate}
                    defaultPickerValue={this.getDefaultPickerValue()}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="alternatePhone"
                  label={translate("alternatePhone")}
                  rules={[
                    {
                      required: true,
                      message: translate("alternativePhoneNumberError"),
                    },
                    {
                      pattern: /^\d{10}$/,
                      message: translate("alternativePhoneNumberError"),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || username !== value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(translate("altNumStudentNum"));
                      },
                    }),
                  ]}
                >
                  <Input className="full-width" maxLength="10" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="casteId"
                  label={translate("casteId")}
                  rules={[
                    {
                      required: true,
                      message: translate("castCategoryError"),
                    },
                  ]}
                >
                  <Select className="full-width">
                    {this.props.castesList.map((caste) => (
                      <Option key={caste.id} value={caste.id}>
                        {caste.configVal}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="address"
                  label={translate("address")}
                  getValueFromEvent={this.toCapital}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || value.length < 10 || value.indexOf(" ") === 0) {
                          return Promise.reject(
                            translate("studentHomeAddressError")
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <TextArea className="full-width" maxLength="50" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="gender"
                  label={translate("gender")}
                  rules={[
                    { required: true, message: translate("genderError") },
                  ]}
                >
                  <Radio.Group onChange={this.onGenderChange}>
                    <Radio value="Male">Male</Radio>
                    <Radio value="Female">Female</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="ninthGrade"
                  label={translate("ninthGrade")}
                  rules={[
                    {
                      required: true,
                      message: translate("ninthGradeError"),
                    },
                  ]}
                >
                  <Select className="full-width">
                    <Option key="A1">A1</Option>
                    <Option key="A2">A2</Option>
                    <Option key="B1">B1</Option>
                    <Option key="B2">B2</Option>
                    <Option key="C1">C1</Option>
                    <Option key="C2">C2</Option>
                    <Option key="D1">D1</Option>
                    <Option key="D2">D2</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="medium"
                  label={translate("medium")}
                  rules={[
                    {
                      required: true,
                      message: translate("mediumError"),
                    },
                  ]}
                >
                  <Select className="full-width">
                    <Option key="EN" value="EN">English</Option>
                    <Option key="TE" value="TE">Telugu</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="headmasterName"
                  label={translate("headmasterName")}
                  getValueFromEvent={this.toCapital}
                  rules={[
                    {
                      required: true,
                      min: 3,
                      pattern: /^\S[A-Za-z\s]+$/,
                      message: translate("headmasterNameError"),
                    },
                  ]}
                >
                  <Input maxLength="25" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="headmasterPhone"
                  label={translate("headmasterPhone")}
                  rules={[
                    {
                      required: true,
                      message: translate("headmasterPhoneNoError"),
                    },
                    {
                      pattern: /^\d{10}$/,
                      message: translate("headmasterPhoneNoError"),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || username !== value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(translate("altNumHeadMNum"));
                      },
                    }),
                  ]}
                >
                  <Input className="full-width" maxLength="10" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="districtId"
                  label={translate("district")}
                  rules={[
                    {
                      required: true,
                      message: translate("schoolDistrictError"),
                    },
                  ]}
                >
                  <Select
                    className="full-width"
                    onChange={this.populateMandals}
                  >
                    {this.props.districtsList.map((district) => (
                      <Option key={district.id} value={district.id}>
                        {district.configVal}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="mandalId"
                  label={translate("mandal")}
                  rules={[
                    {
                      required: true,
                      message: translate("schoolMandalError"),
                    },
                  ]}
                >
                  <Select
                    className="full-width"
                    onChange={this.populateSchools}
                  >
                    {this.props.mandalsList.map((mandal) => (
                      <Option key={mandal.id} value={mandal.id}>
                        {mandal.configVal}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="schoolId"
                  label={translate("school")}
                  rules={[
                    {
                      required: true,
                      message: translate("schoolNameError"),
                    },
                  ]}
                  extra={
                    <span>
                      {translate("addSchoolTextpart1")}{" "}
                      <a href="/addschool" onClick={e => this.redirectToAddSchl(e)}>
                        {translate("addSchoolTextpart2")}
                      </a>{" "}
                      {translate("addSchoolTextpart3")}
                    </span>
                  }
                >
                  <Select onChange={this.checkSchool}>
                    {this.props.schoolsList.map((config) => (
                      <Option key={config.id} value={config.id}>
                        {config.configVal}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="examCenterId"
                  label={translate("examCenter")}
                  rules={[
                    {
                      required: true,
                      message: translate("examCenterError"),
                    },
                  ]}
                  extra={translate("examCenterDisc")}
                >
                  <Select className="exam_center_select">
                    {/* <Option key={""} value={""}>Please Select</Option> */}
                    {this.props.examCenters.map((center) => (
                      <Option key={center.id} value={center.id}>
                        {center.configVal}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          {/* <Col xs={0} sm={2}>
                    </Col> */}
        </Row>
      </div>
    );
  }
}

export default BasicForm;

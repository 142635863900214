import { axiosInstance } from "../../Interceptor";
import { CHANGE_TASK_STATUS, GET_TASK_APRV_LVLS, GET_TASK_COMMENTS, SEARCH_TASK, TASK_DETAILS } from "../utils/const";

export const searchTask = (taskFilter) => {
  return {
    type: SEARCH_TASK,
    payload: axiosInstance.post("/task/search", taskFilter),
  };
};

export const getTaskDetails = (taskView) => {
  return {
    type: TASK_DETAILS,
    payload: axiosInstance.post("/task/details", taskView),
  };
};

export const changeStatus = (taskId, status) => {
  return {
    type: CHANGE_TASK_STATUS,
    payload: axiosInstance.get(`/task/changestatus/${taskId}/${status}`),
  };
};

export const changeStatusWithComment = (taskStatusForm) => {
  return {
    type: CHANGE_TASK_STATUS,
    payload: axiosInstance.post(`/task/changestatus`, taskStatusForm),
  };
};

export const changeStatusInBulk = (taskStatusForm) => {
  return {
    type: CHANGE_TASK_STATUS,
    payload: axiosInstance.post(`/task/changestatus/bulk`, taskStatusForm),
  };
};

export const getTaskComments = (entityId, entityType) => {
  return {
    type: GET_TASK_COMMENTS,
    payload: axiosInstance.get(`/task/comments/${entityId}/${entityType}`),
  };
};

export const getApprovalLevels = () => {
  return {
    type: GET_TASK_APRV_LVLS,
    payload: axiosInstance.get(`/task/approval-levels`),
  };
};
import { axiosInstance } from "../../Interceptor";
import { CREATE_BULK_REQ, SEARCH_BULK_REQ } from "../utils/const";


export const createBulkRequests = (bulkReqForm) => {
    return {
      type: CREATE_BULK_REQ,
      payload: axiosInstance.post("/stud/manage/bulk", bulkReqForm),
    };
  };

  
export const searchBulkRequests = (bulkSearchForm) => {
    return {
      type: SEARCH_BULK_REQ,
      payload: axiosInstance.post("/stud/manage/bulk/search", bulkSearchForm),
    };
  };
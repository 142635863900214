import { Col, Empty, Input } from "antd";
import React from "react";
import { toCurrency } from "../../../common/utils/util";
import { HOUSE_OWN } from "../../utils/consts";
import ViewSurveyCard from "./ViewSurveyCard";

class HousesView extends React.Component {
  setInfo(info) {
    this.props.setCommitteeData(info, HOUSE_OWN);
  }

  updateValues = (value, i, key) => {
    // console.log(i, "--------------------", value);
    let info = this.props.data;
    info.housesList[i][key] = value;
    this.setInfo(info);
  };

  getAssetHtml = (data, i) => {
    const isSelComt = this.props.isSelComt;
    if (data.ownership !== "Rented") {
      return [
        <Col sm={12}>
          <label>Asset Value</label>:
        </Col>,
        <Col sm={12}>
          <span>{toCurrency(data.propertyValue)}</span>
          {isSelComt && (
            <Input
              maxLength="10"
              className="inline-input"
              value={data.newPropertyValue}
              disabled={this.props.disableSelComt}
              onChange={(e) => {
                let value = e.target.value;
                if(isNaN(value)) {
                  value='';
                } else if(value) {
                  value = Number(value);
                }
                if (value > 9999999999) {
                  value = value.toString().slice(0, 10);
                }
                this.updateValues(value, i, "newPropertyValue");
              }}
            />
          )}
        </Col>,
      ];
    }
    return "";
  };

  getRentHtml = (data, i) => {
    const isSelComt = this.props.isSelComt;
    if (data.ownership !== "Own") {
      return [
        <Col sm={12}>
          <label>Monthly Rent</label>:
        </Col>,
        <Col sm={12}>
          {/* #629 */}
          <span>{toCurrency(data.monthlyRent)}</span>
          {isSelComt && (
            <Input
              maxLength="6"
              className="inline-input"
              value={data.newMonthlyRent}
              disabled={this.props.disableSelComt}
              onChange={(e) => {
                let value = e.target.value;
                if(isNaN(value)) {
                  value='';
                } else if(value) {
                  value = Number(value);
                }
                if (value > 999999) {
                  value = value.toString().slice(0, 6);
                }
                this.updateValues(value, i, "newMonthlyRent");
              }}
            />
          )}
        </Col>,
      ];
    }
    return "";
  };

  getRoomsHtml = (data) => {
    if (data.ownership !== "Let Out") {
      return [
        <Col sm={12}>
          <label># of Rooms</label>:
        </Col>,
        <Col sm={12}>
          <span>{data.numberOfRooms}</span>
        </Col>,
        <Col sm={12}>
          <label># of Bed Rooms</label>:
        </Col>,
        <Col sm={12}>
          <span>{data.numberOfBedRooms}</span>
        </Col>,
      ];
    }
    return "";
  };

  getDescriptionHtml = (data) => {
    if (data.ownership !== "Let Out") {
      return [
        <Col sm={12}>
          <label>Describe House</label>:
        </Col>,
        <Col sm={12}>
          <span>{data.houseDescription}</span>
        </Col>,
        <Col sm={12}>
          <label>Describe Neighbourhood</label>:
        </Col>,
        <Col sm={12}>
          <span>{data.neighbourDescription}</span>
        </Col>,
      ];
    }
    return "";
  };

  generateFieldData = (info) => {
    const html = [];
    if (Array.isArray(info)) {
      info.forEach((j, i) => {
        html.push(
          <Col sm={12}>
            <label>Area</label>:
          </Col>,
          <Col sm={12}>
            <span>{j.area}</span>
          </Col>,
          <Col sm={12}>
            <label>UOM</label>:
          </Col>,
          <Col sm={12}>
            <span>{j.uom}</span>
          </Col>,
          <Col sm={12}>
            <label>Constructed Area</label>:
          </Col>,
          <Col sm={12}>
            <span>{j.constructedArea}</span>
          </Col>,
          <Col sm={12}>
            <label>Type of House</label>:
          </Col>,
          <Col sm={12}>
            <span>{j.houseType}</span>
          </Col>,
          <Col sm={12}>
            <label>Ownership</label>:
          </Col>,
          <Col sm={12}>
            <span>{j.ownership}</span>
          </Col>,
          this.getAssetHtml(j, i),
          this.getRoomsHtml(j),
          this.getRentHtml(j, i),
          this.getDescriptionHtml(j),
          <Col sm={12}>
            <label>Remarks</label>:
          </Col>,
          <Col sm={12}>
            <span>{j.remarks}</span>
          </Col>
        );
      });
    }
    return html;
  };
  render() {
    let info = this.props.data;
    if(!info) {
      return (
        <ViewSurveyCard title="Houses Owned" {...this.props}>
          <Empty />
        </ViewSurveyCard>
      );
    }
    return (
      <div>
        <ViewSurveyCard title="Houses Owned" {...this.props}>
          {this.generateFieldData(info.housesList)}
        </ViewSurveyCard>
      </div>
    );
  }
}

export default HousesView;

import { Button, notification, Modal, Spin } from "antd";
import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export const CleanUp = (props) => {
  const showNotifications = () => {
    let message = "";
    if (props.admin.successMessage) {
      message += props.admin.successMessage;
    }
    if (message) {
      notification.success({
        message: "Success",
        description: message,
      });
      props.resetMessage();
    }
  };

  const showErrorNotifications = () => {
    let message = "";
    if (props.admin.errorMessage) {
      message += props.admin.errorMessage;
    }
    if (message) {
      notification.error({
        message: "Error",
        description: message,
      });
      props.resetMessage();
    }
  };

  const confirmCleanUp = () => {
    Modal.confirm({
      title: "Do you want to clean up the old Application Forms data?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        props.cleanUp();
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  return (
    <React.Fragment>
      <Spin spinning={props.admin.loading}>
        <div>
          <Button
            type="primary"
            style={{ margin: "auto", display: "block" }}
            onClick={(e) => confirmCleanUp()}
            disabled={props.admin.loading}//#497 CLean Up
          >
            Clean Up 
          </Button>
        </div>
        <div style={{textAlign:'center', marginTop: '50px'}}>
            <p><b>Note:</b> &nbsp; Please note that this action will delete all application processing data of the previous year.</p>
        </div>
        {props.admin.successMessage && showNotifications()}
        {props.admin.errorMessage && showErrorNotifications()}
      </Spin>
    </React.Fragment>
  );
};

import { combineReducers } from "redux";
import AuthReducer from "./base/reducers/authReducer";
import HeadReducer from "./base/reducers/HeadReducer";
import AppConfigReducer from "./base/reducers/AppConfigReducer";
import StudentReducer from "./student/reducers/StudentReducer";
import AdminReducer from "./admin/reducers/AdminReducer";
import SMSReducer from "./admin/reducers/SMSReducer";
import SSReducer from "./sampoorna-siksha/reducers/SSReducer";
import ReviewReducer from "./sampoorna-siksha/reducers/ReviewReducer";
import MasterTablesReducer from "./mastertables/reducers/MasterTablesReducer";
import ManagementReducer from "./management/reducers/MangementReducer";
import ManagementUploadReducer from "./management/reducers/ManagementUploadReducer";
import TaskReducer from "./common/reducers/TaskReducer";
import NotificationReducer from "./common/reducers/NotificationReducer";
import InteractionsReducer from "./common/reducers/InteractionsReducer";
import InteractionUploadReducer from "./common/reducers/InteractionUploadReducer";
import BulkRequestsReducer from "./management/reducers/BulkRequestsReducer";
import ReportsReducer from "./reports/reducers/ReportsReducer";
import StudentNotesReducer from "./management/reducers/StudentNotesReducer";

const Reducer = combineReducers({
  AuthReducer,
  HeadReducer,
  StudentReducer,
  AppConfigReducer,
  AdminReducer,
  SSReducer,
  ReviewReducer,
  SMSReducer,
  MasterTablesReducer,
  ManagementReducer,
  ManagementUploadReducer,
  TaskReducer,
  NotificationReducer,
  InteractionsReducer,
  InteractionUploadReducer,
  BulkRequestsReducer,
  ReportsReducer,
  StudentNotesReducer
});

export default Reducer;

import { Button, Modal, Space, Spin, Table, Tooltip } from "antd";
import React from "react";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import AddCategoryModal from "./AddCategoryModal";
import { exportFile } from "../../common/utils/export";
import { CAT } from "../utils/const";

class EducationCategory extends React.Component {
  state = {
    category: {},
  };
  componentDidMount() {
    this.props.fetchEducationCategories();
    this.props.setCurrentRecord({});
  }

  handleAdd = () => {
    this.setState({ category: {} });
    this.props.setCurrentRecord({});
    this.props.toggleAddCatModal(true);
  };

  edit = (record, e) => {
    e.preventDefault();
    this.setState({ category: record });
    this.props.fetchMasterTableRecord(CAT, record.categoryId);
    this.props.toggleAddCatModal(true);
  };

  delete = (record, e) => {
    e.preventDefault();
    const that = this;
    Modal.confirm({
      title: (<div>Do you want to delete this Education Category?
        <p>Note: Before deleting please remove this category from courses and semisters.</p>
      </div>),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        that.props.deleteEducationCategory(record.categoryId);
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  exportData = (cols) => {
    exportFile(cols, this.props.masterTable.categories, "Education Categories");
  };

  render() {
    const CAT_LIST_COLS = [
      {
        title: "Category",
        dataIndex: "name",
        key: "name",
        render: (text, record) => record.educationCategory.name,
        sorter: {
          compare: (a, b) =>
            sortNames(a.educationCategory.name, b.educationCategory.name),
        },
      },
      {
        title: "Category Type",
        dataIndex: "categoryName",
        key: "categoryName",
        render: (text, record) => record.categoryName,
        sorter: {
          compare: (a, b) => sortNames(a.categoryName, b.categoryName),
        },
      },
      {
        title: "Duration",
        dataIndex: "months",
        key: "months",
        render: (text, record) => {
          return record.months
            ? parseInt(record.months / 12) +
                " Years, " +
                (record.months % 12) +
                " Months"
            : "";
        },
        sorter: {
          compare: (a, b) => a.months - b.months,
        },
        width: "150px",
      },
      {
        title: "# of Semesters",
        dataIndex: "noOfSems",
        key: "noOfSems",
        render: (text, record) => record.noOfSems,
        sorter: {
          compare: (a, b) => a.noOfSems - b.noOfSems,
        },
      },
      {
        title: "Description",
        dataIndex: "notes",
        key: "notes",
        render: (text, record) => record.notes,
        sorter: {
          compare: (a, b) => sortNames(a.notes, b.notes),
        },
        width: "40%",
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <Tooltip title="Edit">
              <a onClick={(e) => this.edit(record, e)}>
                <EditOutlined />
              </a>
            </Tooltip>
            <Tooltip title="Delete">
              <a onClick={(e) => this.delete(record, e)}>
                <DeleteOutlined />
              </a>
            </Tooltip>
          </Space>
        ),
      },
    ];
    return (
      <div id="education-category-container">
        <h2>
          Education Categories
          <Button
            className="add-cat-button"
            onClick={this.handleAdd}
            type="primary"
            style={{ float: "right" }}
            disabled={this.props.masterTable.loading}
          >
            Add Category
          </Button>
          <Button
            className="add-cat-button"
            onClick={(e) => this.exportData(CAT_LIST_COLS)}
            style={{ float: "right", marginRight: "10px" }}
            disabled={this.props.masterTable.loading}
          >
            Export Excel
          </Button>
        </h2>
        <Spin spinning={this.props.masterTable.loading} delay={500}>
          <div>
            <Table
              columns={CAT_LIST_COLS}
              dataSource={this.props.masterTable.categories}
              pagination={{
                showSizeChanger: true,
                showTotal: () => {
                  const total = this.props.masterTable.categories.length;
                  return `Total ${total}`;
                },
              }}
            />
          </div>
        </Spin>
        {this.props.masterTable.showAddCategory && (
          <AddCategoryModal record={this.state.category} {...this.props} />
        )}
      </div>
    );
  }
}

export default EducationCategory;

import React from "react";
import {
  Card,
  Form,
  Col,
  Select,
  Row,
  Button,
  Spin,
  Table,
  Input,
  Drawer,
  notification,
  Checkbox,
} from "antd";
import { translate } from "../../base/actions/translate";
import { createSurveyFormData, getStatusCode, sortNames } from "../utils/util";
import { DSTIN } from "../../base/utils/roles";
import {
  ENROL,
  INC_SUM,
  SMSS,
} from "../utils/consts";
import SelectionCommitteePreview from "./viewSurvey/SelectionCommitteePreview";
// import XLSX from 'xlsx';

const { Option } = Select;
const { Search } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class Enrollment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldData: {
        oldDistId: "",
        currentPage: 1,
        filters: {},
        sorter: {},
      },
      currentRecord: {},
      showDetails: false,
      reason: {
        reason: "",
        otherReason: "",
        status: "",
        errorMsg: "",
      },
      currentIndex: 0,
      selectedRowKeys: [],
    };

    this.formRef = React.createRef();
  }

  componentWillMount() {
    this.props.getOldDistricts();
    this.props.getMarksConfig();
    // this.props.setFormSteps(REVIEW_STEPS);
    this.searchApplications({oldDistId: null});
  }

  componentWillUnmount() {
    this.onClose();
  }


  searchApplications = (form) => {
    // form.pageSize = 10;
    // form.sortOrder = 'asc';
    // form.sortField = 'applicationId';
    form.searchText = "";
    form.currentPage = 1;
    this.setState({ fieldData: form });
    this.props.searchApplications(form);
    this.props.setSearchFilters(form);
  };

  showDetails = (record, index) => {
    this.setState({
      currentRecord: record,
      showDetails: true,
      currentIndex: index,
    });
    this.props.toggleDetails(true);
    // this.props.setStep(0);
    this.props.getApplicationReviewDetails(record.applicationId);
  };

  showNext = () => {
    const { currentIndex } = this.state;
    const dataSource = this.props.sampurnaSiksha.searchedApplications;
    const currentRec = dataSource[currentIndex + 1];
    this.setState({
      currentRecord: currentRec,
      currentIndex: currentIndex + 1,
    });
    this.props.getApplicationReviewDetails(currentRec.applicationId);
    // this.props.setStep(0);
  };

  showPrevious = () => {
    const { currentIndex } = this.state;
    const dataSource = this.props.sampurnaSiksha.searchedApplications;
    const currentRecord = dataSource[currentIndex - 1];
    this.setState({
      currentRecord: currentRecord,
      currentIndex: currentIndex - 1,
    });
    this.props.getApplicationReviewDetails(currentRecord.applicationId);
    // this.props.setStep(0);
  };

  exportToExcel = () => {
    this.props.exportToExcel(this.props.sampurnaSiksha.ssSearchFilters);
  };

  onClose = () => {
    this.setState({ showDetails: false });
    this.props.toggleDetails(false);
    this.props.getOldDistricts();
  };

  generateActionButtons = () => {
    return (
      <div style={{ textAlign: "right" }}>
        <Button onClick={this.onClose} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
    );
  };

  showNotifications = () => {
    let message = "";

    if (this.props.review.successMessage) {
      message += this.props.review.successMessage;
    }

    if (message) {
      notification.success({
        message: "Success",
        description: message,
      });
      this.props.resetMessage();
    }
  };

  isEnrollementListContains = (record, searchText) => {  
    searchText = searchText.toUpperCase();
    const rec =  record.studentName + " " + record.studentSurName + " " + record.caste?.configVal
     + " " + record.hallTicket?.sscMarks + " " + record.hallTicket?.mctMarks
     + " " + translate(getStatusCode(record.status).key) + " " + translate(record.selectionStatus)
     + " " + this.getFeedback(record) + " " + this.calculateTotalScore(record.hallTicket);
    return (
      rec.toUpperCase().indexOf(searchText) > -1
    );
  };

  offlineSearchApplication = (value) => {
    const filters = this.props.sampurnaSiksha.ssSearchFilters;
    filters.searchText = value.toUpperCase();
    let allApplications = this.props.sampurnaSiksha.applications;
    if (value && value.length > 1) {
      const result = allApplications.filter((appl) => {
        return this.isEnrollementListContains(appl, value);
      });
      this.props.setSearchApplication(result);
    } else if (!value) {
      this.props.setSearchApplication(allApplications);
    }
    this.props.setSearchFilters(filters);
    this.setState({ fieldData: filters });
  };

  onFiltersChange = (pagination, filters, sorter, extra) => {
    // console.log(
    //   "on filter change ------ : ",
    //   pagination,
    //   filters,
    //   sorter,
    //   extra
    // );
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.filters = filters;
    fieldData.sorter = sorter;
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
    this.props.setSearchApplication(extra.currentDataSource);
  };

  onPageChange = (page, pageSize) => {
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.currentPage = page;
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
  };

  showTotal = () => {
    const total = this.props.sampurnaSiksha.searchedApplications.length;
    return `Total ${total} Applicants`;
  };
  
  offlineExport = () => {
    const data = [];
    const appl = this.props.sampurnaSiksha.searchedApplications;
    for(const record of appl) {
        const arr = [];
        arr.push(record.studentName + " " + record.studentSurName);
        arr.push(this.generateSurveyDetails(
            record.surveyDetails,
            INC_SUM,
            "totalAnnualIncome"
          ));
          arr.push(this.generateSurveyDetails(
            record.surveyDetails,
            INC_SUM,
            "totalPropertyValue"
          ));
          arr.push(this.calculateTotalScore(record.hallTicket));
          arr.push(translate(record.selectionStatus));
          arr.push(this.getFeedback(record));
          data.push(arr);
    }
  }

  generateSurveyDetails = (surveyDetails, surveyKey, key) => {
    const data = createSurveyFormData(surveyDetails)[surveyKey];
    for (const i in data) {
      if (i === key) {
        return data[i];
      }
    }
    return "";
  };

  calculateTotalScore = (hallTicket) => {
    const { marksConfig } = this.props.sampurnaSiksha;
    const config = {};
    for (const mc of marksConfig) {
      config[mc.configCd] = mc.configVal;
    }
    const mct =
      (hallTicket.mctMarks /
        (config["MCT_TOTAL"] ? config["MCT_TOTAL"] : 100)) *
      (config["MCT_PERCENT"] ? config["MCT_PERCENT"] : 100);
    const ssc =
      (hallTicket.sscMarks /
        (config["SSC_TOTAL"] ? config["SSC_TOTAL"] : 100)) *
      (config["SSC_PERCENT"] ? config["SSC_PERCENT"] : 100);
    return (mct + ssc).toFixed(1);
  };

  updateSelection = (e, record) => {
    const status = e.target.checked? ENROL : SMSS ;
    this.props.updateApplicationStatus([record.applicationId], status);
  };

  getFeedback = (appl) => {
    const comments = appl.comments;
    let feedback = "";
    for (const c of comments) {
      if (c.entityType === "APPL" && c.eventType === "STUD_SEL") {
        feedback = c.comment;
      }
    }
    return feedback;
  };

  getYear = () => {
    const year = new Date().getFullYear();
    const opts = [];
    for(let i = 2010; i <= year; i++) {
      opts.push(<Option value = {i}>{i}</Option>);
    }

    return opts;
  }

  render() {
    const searchFilters = this.props.sampurnaSiksha.ssSearchFilters;
    const sortedInfo = searchFilters.sorter || {};
    const fieldData = [];
    for (let i in this.props.sampurnaSiksha.ssSearchFilters) {
      fieldData.push({
        name: [i],
        value: this.props.sampurnaSiksha.ssSearchFilters[i],
      });
    }

    const dataSource = this.props.sampurnaSiksha.searchedApplications;

    const APPL_LIST_COLS = [
      {
        title: "Enrol",
        dataIndex: "enroll",
        key: "enroll",
        render: (text, record) => {
          if(record.status === ENROL) {
            return <Checkbox onChange={(e) => this.updateSelection(e, record)} defaultChecked></Checkbox>
          } else {
            return <Checkbox onChange={(e) => this.updateSelection(e, record)}></Checkbox>
          }
         
        },
        sorter: {
          compare: (a, b) => a.hallTicket.mctMarks > b.hallTicket.mctMarks,
        },
        // sortOrder: sortedInfo.columnKey === "enroll" && sortedInfo.order,
      },
      {
        title: "Student Name",
        dataIndex: "studentName",
        key: "studentName",
        render: (text, record) =>
          record.studentName + " " + record.studentSurName,
        sorter: {
          compare: (a, b) =>
            sortNames(
              a.studentName + " " + a.studentSurName,
              b.studentName + " " + b.studentSurName
            ),
        },
        // sortOrder: sortedInfo.columnKey === "studentName" && sortedInfo.order,
        defaultSortOrder: "ascend",
        onCell: (record, rowIndex) => {
          return {
            onClick: (e) => {
              this.showDetails(record, rowIndex, e);
            }, // click row
          };
        },
      },
      {
        title: "Caste",
        dataIndex: "casteId",
        key: "casteId",
        render: (text, record) => record.caste.configVal,
        sorter: {
          compare: (a, b) =>
            sortNames(a.caste.configVal, b.caste.configVal),
        },
        // sortOrder: sortedInfo.columnKey  === 'casteId' && sortedInfo.order,
        onCell: (record, rowIndex) => {
          return {
            onClick: (e) => {
              this.showDetails(record, rowIndex, e);
            }, // click row
          };
        },
      },
      {
        title: "SSC GPA",
        dataIndex: "sscMarks",
        key: "sscMarks",
        render: (text, record) => record.hallTicket?.sscMarks,
        sorter: {
          compare: (a, b) => a.hallTicket.sscMarks - b.hallTicket.sscMarks,
        },
        // sortOrder: sortedInfo.columnKey === "sscMarks" && sortedInfo.order,
        onCell: (record, rowIndex) => {
          return {
            onClick: (e) => {
              this.showDetails(record, rowIndex, e);
            }, // click row
          };
        },
      },
      {
        title: "MSS Score",
        dataIndex: "mctExam",
        key: "mctMarks",
        render: (text, record) => record.hallTicket?.mctMarks,
        sorter: {
          compare: (a, b) => a.hallTicket.mctMarks - b.hallTicket.mctMarks,
        },
        // sortOrder: sortedInfo.columnKey === "mctMarks" && sortedInfo.order,
        onCell: (record, rowIndex) => {
          return {
            onClick: (e) => {
              this.showDetails(record, rowIndex, e);
            }, // click row
          };
        },
      },
      {
        title: "Combined Score",
        dataIndex: "totalScore",
        key: "totalScore",
        render: (text, record) => this.calculateTotalScore(record.hallTicket) + "%",
        sorter: {
          compare: (a, b) =>
            this.calculateTotalScore(a.hallTicket) - this.calculateTotalScore(b.hallTicket),
        },
        // sortOrder: sortedInfo.columnKey === "totalScore" && sortedInfo.order,
        onCell: (record, rowIndex) => {
          return {
            onClick: (e) => {
              this.showDetails(record, rowIndex, e);
            }, // click row
          };
        },
      },
      {
        title: "Application Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
           translate(getStatusCode(record.status).key)
        ),
        sorter: {
            compare: (a, b) =>
              sortNames(
                translate(getStatusCode(a.status).key),
                translate(getStatusCode(b.status).key)
              ),
          },
        // sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
        width: "140px",
        onCell: (record, rowIndex) => {
          return {
            onClick: (e) => {
              this.showDetails(record, rowIndex, e);
            }, // click row
          };
        },
      },
      {
        title: "Committee Status",
        dataIndex: "selectionStatus",
        key: "selectionStatus",
        render: (text, record) => (
           translate(record.selectionStatus)
        ),
        sorter: {
            compare: (a, b) =>
              sortNames(
                translate(a.selectionStatus),
                translate(b.selectionStatus)
              ),
          },
        // sortOrder: sortedInfo.columnKey === "selectionStatus" && sortedInfo.order,
        width: "140px",
        onCell: (record, rowIndex) => {
          return {
            onClick: (e) => {
              this.showDetails(record, rowIndex, e);
            }, // click row
          };
        },
      },
      {
        title: "Feedback",
        dataIndex: "feedback",
        key: "feedback",
        render: (text, record) => (
            this.getFeedback(record)
        ),
        sorting: false,
        width: "250px",
        onCell: (record, rowIndex) => {
          return {
            onClick: (e) => {
              this.showDetails(record, rowIndex, e);
            }, // click row
          };
        },
      },
    ];
    return (
      <div id="selection-committee-container">
        {this.props.role !== DSTIN && (
          <Card title="Enrollment">
            <Form
              {...layout}
              onFinish={this.searchApplications}
              className="app-search-form"
              fields={fieldData}
            >
              <Row>
              <Col xs={24} sm={6}>
                  <Form.Item
                    {...formItemLayout}
                    name="year"
                    label='Year'
                  >
                    <Select className="full-width">
                      {this.getYear()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="oldDistId"
                    label={translate("district")}
                  >
                    <Select className="full-width">
                      <Option>All</Option>
                      {this.props.oldDistrictsList.map((district) => (
                        <Option key={district.id} value={district.id}>
                          {district.configVal}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Button type="primary" htmlType="submit" className="enrolment-search">
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        )}

        <Card>
          <Search
            placeholder="Search"
            value={this.props.sampurnaSiksha.ssSearchFilters.searchText}
            onChange={(e) => this.offlineSearchApplication(e.target.value)}
            onSearch={(value) => this.offlineSearchApplication(value)}
            enterButton
            style={{ marginBottom: "10px", width: "75%", marginRight: "10px" }}
          />
          <Spin
            spinning={
              (this.props.review.loading || this.props.sampurnaSiksha.loading) &&
              !this.props.sampurnaSiksha.showDetails
            }
            delay={500}
          >
            <Table
              bordered
              columns={APPL_LIST_COLS}
              className="row-selection"
              // rowSelection={rowSelection}
              rowKey={(record) => record.applicationId}
              onChange={this.onFiltersChange}
              dataSource={this.props.sampurnaSiksha.searchedApplications}
              pagination={{
                current: searchFilters.currentPage,
                onChange: this.onPageChange,
                showSizeChanger: true,
                showTotal: this.showTotal,
              }}
            />
          </Spin>
        </Card>

        <Drawer
          id="reviewers-details"
          title={
            <div>
              {this.props.review.reviewDetails.application?.applicationId && (
              <h4>
                <strong style={{ marginLeft: "10px" }}>
                  Name:{" "}
                  {this.props.review.reviewDetails.application?.studentName ||
                    "" +
                      " " +
                      this.props.review.reviewDetails.application
                        ?.studentSurName ||
                    ""}
                </strong>
                <strong style={{ marginLeft: "10px" }}>
                  Registered Mobile Number:{" "}
                  {this.props.review.reviewDetails.application?.username}
                </strong>
                {this.state.currentIndex < dataSource.length - 1 && (
                  <Button
                    onClick={(e) => this.showNext()}
                    style={{ marginRight: 8, float: "right" }}
                  >
                    Next
                  </Button>
                )}
                {this.state.currentIndex > 0 && (
                  <Button
                    onClick={(e) => this.showPrevious()}
                    style={{ marginRight: 8, float: "right" }}
                  >
                    Previous
                  </Button>
                )}
              </h4>
              )}
            </div>
          }
          footer={this.generateActionButtons()}
          width="100%"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.props.sampurnaSiksha.showDetails}
        >
          <Spin spinning={this.props.review.loading} tip="Loading"></Spin>
          {this.props.review.reviewDetails.application?.applicationId && (
            <SelectionCommitteePreview
              formRef={this.formRef}
              currentRecord={this.state.currentRecord}
              saveApplication={this.saveApplication}
              disableSelComt={true}
              isSelComt={true}
              {...this.props}
            ></SelectionCommitteePreview>
          )}
        </Drawer>

        {(this.props.review.successMessage ||
          this.props.sampurnaSiksha.successMessage) &&
          this.showNotifications()}
      </div>
    );
  }
}

export default Enrollment;

import React from "react";
import { Form, Input, Button, Checkbox, Alert, Radio } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { translate } from "../actions/translate";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onFinish: false,
    };
    this.onFinish = this.onFinish.bind(this);
    this.doRegister = this.doRegister.bind(this);
    this.handleForgotPwd = this.handleForgotPwd.bind(this);
  }

  handleForgotPwd = (event) => {
    event.preventDefault();
    this.props.toggleFgtPwdModal(true);
  };

  onFinish = (formValues) => {
    this.props.login(formValues);
  };

  doRegister = (event) => {
    event.preventDefault();
    this.props.doRegister();
  };

  showForgotPassword() {
    this.props.toggleFgtPwdModal(true);
  }

  showErrorMessage() {
    const that = this;
    setTimeout(() => {
      that.props.resetMessages();
    }, 20000);
    return <Alert message={this.props.errorMessage} type="error" />;
  }

  showSuccessMessage() {
    const that = this;
    setTimeout(() => {
      that.props.resetMessages();
    }, 20000);
    return <Alert message={this.props.successMessage} type="info" />;
  }

  handleChange(event) {
    const value = event.target.value;
    sessionStorage.getItem("lang", value);
    this.props.setTranslate(value);
  }

    render() {
        return (
            <div className="card-container login-container" >
                <Form
                    name="normal_login"
                    className="login-form login-wrapper"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={this.onFinish}
                >
                    <div className="content-wrapper">
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your Mobile Number / Username!',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={translate('username')} />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your Password!',
                                },
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item>
                            <a className="login-form-forgot" href="/#" onClick={this.handleForgotPwd}>
                                Forgot password ?
                            </a>
                        </Form.Item>
                        {this.props.errorMessage &&
                            this.showErrorMessage()
                        }
                        {this.props.successMessage &&
                            this.showSuccessMessage()
                        }
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button mg-l">
                                Log in
                    </Button>
                    {this.props.showRegister && 
                        <span>or  <a onClick={this.doRegister} href="/#">Register now!</a></span>
                    }
                        </Form.Item>
                    </div>
                </Form>
            </div>
        );
    }
  render() {
    return (
      <div className="card-container login-container">
        <Form
          name="normal_login"
          className="login-form login-wrapper"
          initialValues={{
            remember: true,
          }}
          onFinish={this.onFinish}
        >
          <div className="content-wrapper">
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please enter your Mobile Number!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={translate("username")}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your Password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Radio.Group
              className="lang-opt"
              value={this.props.lang}
              buttonStyle="solid"
              onChange={ e => this.handleChange(e)}
            >
              <Radio.Button value="en">English</Radio.Button>
              <Radio.Button value="te">తెలుగు</Radio.Button>
            </Radio.Group>
            <Form.Item>
              <a
                className="login-form-forgot"
                href="/#"
                onClick={this.handleForgotPwd}
              >
                Forgot password ?
              </a>
            </Form.Item>
            {this.props.errorMessage && this.showErrorMessage()}
            {this.props.successMessage && this.showSuccessMessage()}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button mg-l"
              >
                Log in
              </Button>
              {this.props.showRegister && (
                <span>
                  or{" "}
                  <a onClick={this.doRegister} href="/#">
                    Register now!
                  </a>
                </span>
              )}
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}

export default Login;

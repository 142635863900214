import { Card, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RESET_MESSAGES } from "../../base/utils/const";
import MedicalRequests from "../../management/components/MedicalRequests";
import WelfareRequests from "../../management/components/WelfareRequests";
import { fetchMyStudentProfile } from "../actions/studAcctActions";
import MonetaryRequests from "../../management/components/MonetaryRequests";

const { TabPane } = Tabs;

const Requests = (props) => {
  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    if (props.profile.username) {
      props.fetchMyStudentProfile(props.profile.username);
    }
  }, [props.profile?.username]);

  return (
    <React.Fragment>
      {/* <h2>Requests</h2> */}
      <Card title="Requests">
        <Spin spinning={props.manage.loading}>
          <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
            <TabPane tab="Medical Requests" key="1">
              {activeKey === "1" && <MedicalRequests />}
            </TabPane>
            <TabPane tab="Welfare Requests" key="2">
              {activeKey === "2" && <WelfareRequests />}
            </TabPane>
            <TabPane tab="Monetary Requests" key="3">
              {activeKey === "3" && <MonetaryRequests />}
            </TabPane>
          </Tabs>
        </Spin>
      </Card>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMyStudentProfile(username) {
      dispatch(fetchMyStudentProfile(username));
    },

    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    ManagementReducer,
    MasterTablesReducer,
    AppConfigReducer,
    ManagementUploadReducer,
  } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    manage: ManagementReducer,
    masterTable: MasterTablesReducer,
    appConfig: AppConfigReducer,
    mgUploads: ManagementUploadReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Requests);

import {
  Button,
  Card,
  Form,
  Modal,
  Spin,
  Input,
  Row,
  Col,
  message,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { CRED_PERCENT } from "../../../mastertables/utils/const";
import {
  getGradeByAttemptAndSub,
  getMarksByAttemptAndSub,
  getMarksIdByAttemptAndSub,
  getRankByAttempt,
  getResultByAttemptAndSub,
} from "../../utils/utils";
import { UploadOutlined } from "@ant-design/icons";
import { isValidDoc } from "../../../base/utils/utils";
import ImageViewer from "../../../sampoorna-siksha/components/reviewSteps/ImageViewer";
import { MARKS_MEMO } from "../../utils/const";

const formItemLayout = {
  labelCol: {
    xs: { span: 18 },
    sm: { span: 18 },
  },
  wrapperCol: {
    xs: { span: 6 },
    sm: { span: 6 },
  },
};

const MarksForm = (props) => {
  const [form] = Form.useForm();
  const [initForm, setInitForm] = useState(() => {
    const initForm = {};
    const creditSystem = props.academics.institute?.affiliation.credSystem;
    for (const cs of props.currCourseSub) {
      initForm[cs.curSubId] = creditSystem === 1 ? getMarksByAttemptAndSub(
        props.sem.studentMarks,
        props.attempt,
        cs.curSubId
      ) : getGradeByAttemptAndSub(
        props.sem.studentMarks,
        props.attempt,
        cs.curSubId
      );
      initForm[cs.curSubId + "_result"] = getResultByAttemptAndSub(
        props.sem.studentMarks,
        props.attempt,
        cs.curSubId
      );
    }
    initForm.rank = getRankByAttempt(props.sem.studentMarks, props.attempt);
    return initForm;
  });
  const [edCatId, setEdCatId] = useState(() => {
    return props.academics?.course.educationCategory.educationId;
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    const uploadedFiles = [];
    const entityIds = props.sem.studentMarks
    .filter((sm) => sm.attempt === props.attempt)
    .map((sm) => sm.studentMarksId)
    for (const up of props.mgUploads.newAttach) {
      if (up.type === MARKS_MEMO && ((up.status === "P" && !up.entityId) || entityIds.includes(up.entityId))) {
        uploadedFiles.push(up);
      }
    }
    const uniqueUploads = Array.from(
      new Set(uploadedFiles.map((a) => a.managementUploadId))
    ).map((id) => {
      return uploadedFiles.find((a) => a.managementUploadId === id);
    });
    setUploadedFiles(uniqueUploads);
  }, [props.mgUploads.newAttach]);

  const setFiles = (e) => {
    const file = document.getElementById("myFileMemo").files[0];
    if (!file) {
      return;
    }

    if (!isValidDoc(file)) {
      message.error("Please upload valid file less than 500 KB.");
      document.getElementById("myFileMemo").value = "";
      return;
    }

    const currentProfile = props.manage.currentProfile;

    // Save the new image uploaded as a draft.
    props.draftReqImg(
      currentProfile.studentProfileId,
      file,
      MARKS_MEMO,
      currentProfile.batch
    );
    document.getElementById("myFileMemo").value = "";
  };

  const handleUpload = () => {
    document.getElementById("myFileMemo").click();
  };

  const onFinish = (form) => {
    const creditSystem = props.academics.institute?.affiliation.credSystem;
    const marksList = [];
    for (const id in form) {
      if (form[id] && id !== "upload" && id.indexOf("result") === -1) {
        const marks = {
          studentMarksId: getMarksIdByAttemptAndSub(
            props.sem.studentMarks,
            props.attempt,
            parseInt(id)
          ),
          studentId: props.sem.studentProfileId,
          studentSemId: props.sem.studentSemId,
          curSubId: parseInt(id),
          marks: parseInt(form[id]),
          result: parseInt(form[id + "_result"]),
          attempt: props.attempt || 1,
          info: form.rank,
        };
        if (creditSystem === CRED_PERCENT) {
          marks.marks = parseInt(form[id]);
          marks.grade = null;
        } else {
          marks.grade = form[id];
          marks.marks = null;
        }
        marksList.push(marks);
      }
    }
    console.log("--------------marks list form--------", form, marksList);
    const newFiles = uploadedFiles.find(up => up.status === "P" && !up.entityId);
    if (uploadedFiles.length === 0) {
      showError({
        title: "Marks Memo not uploaded.",
        content: "Please upload marks memo.",
      });
    } else if (marksList.length > 0) {
      props.onFinish({
        marksList,
        uploadId: newFiles?.managementUploadId,
        studentProfileId: props.academics.studentProfileId,
      });
    } else {
      showError({
        title: "No Marks entered.",
        content: "Enter atleast one subject marks for this attempt.",
      });
    }
  };

  const showError = (message) => {
    Modal.error(message);
  };

  const generateMarksFields = () => {
    const creditSystem = props.academics.institute?.affiliation.credSystem;
    let gradingSystems = [];

    if (
      props.curCurriculum.gradeSystem &&
      props.curCurriculum.gradeSystem.gradePoints
    ) {
      gradingSystems = props.curCurriculum.gradeSystem.gradePoints;
    }
    const fromFields = [];
    for (const cs of props.currCourseSub) {
      fromFields.push(
        <Col xs={24} sm={12} key={cs.curSubId}>
          <Row gutter={[16, 8]}>
            {creditSystem === CRED_PERCENT ? (
              <Col sm={18} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={cs.curSubId}
                  label={cs.subject.name}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (
                          (props.attempt > 1 && !value) ||
                          (value &&
                            ((creditSystem === CRED_PERCENT &&
                              value <= cs.maxMarks) ||
                              (creditSystem !== CRED_PERCENT &&
                                value <= cs.credits)))
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Please enter valid marks.");
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            ) : (
              <Col sm={18} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name={cs.curSubId}
                  label={cs.subject.name}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if ((props.attempt > 1 && !value) || value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Please select Grade  .");
                      },
                    }),
                  ]}
                >
                  <Select placeholder="Grade">
                    {gradingSystems.map((g) => {
                      return (
                        <Select.Option key={"grade_" + g.grade} value={g.grade}>
                          {g.grade}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col sm={6} xs={24}>
              <Form.Item
                name={cs.curSubId + "_result"}
                rules={[
                  {
                    required: true,
                    message: "Please select the Result",
                  },
                ]}
              >
                <Select placeholder="Result">
                  <Select.Option key="1" value={1}>
                    Pass
                  </Select.Option>
                  <Select.Option key="0" value={0}>
                    Fail
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      );
    }
    if (edCatId === 5) {
      fromFields.push(
        <Col xs={24} sm={12}>
          <Row gutter={[16, 8]}>
            <Col sm={18} xs={18}>
              <Form.Item
                {...formItemLayout}
                name="rank"
                label="Rank"
                rules={[
                  {
                    required: true,
                    pattern: /^[1-9][0-9]*$/,
                    message: "Please enter a valid Rank",
                  },
                ]}
              >
                <Input maxLength={8}/>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      );
    }
    return fromFields;
  };

  return (
    <>
      <Modal
        visible={props.manage.showModal.indexOf("EDIT_ATTEMPT") > -1}
        footer={null}
        onCancel={() => props.onCancel()}
        width="70%"
      >
        <div className="card-container">
          <Spin tip="Loading..." spinning={props.masterTable.loading}>
            <Card title={props.title}>
              <Form initialValues={initForm} onFinish={onFinish} form={form}>
                <Row gutter={[16, 8]}>{generateMarksFields()}</Row>
                <Form.Item name="upload" label="Memo" {...formItemLayout}>
                  <Button
                    icon={<UploadOutlined />}
                    onClick={(e) => handleUpload(e)}
                  >
                    Click to Upload Memo
                  </Button>
                </Form.Item>

                <input
                  type="file"
                  id="myFileMemo"
                  style={{ display: "none" }}
                  onChange={setFiles}
                ></input>
                <ImageViewer
                  imageList={uploadedFiles}
                  showActions={false}
                  loading={props.manage.loading}
                  year={props.manage.currentProfile?.batch}
                  {...props}
                />

                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button mg-l"
                  >
                    Save
                  </Button>
                  <Button
                    className="login-form-button mg-l"
                    onClick={() => props.onCancel()}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default MarksForm;

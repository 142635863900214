import React, { useEffect, useState } from "react";
import DocViewer, { PDFRenderer } from "react-doc-viewer";
import { APP_URI } from "../../config/env";
import { TYPE_PDF } from "../../student/util/const";
import { getUploadPath } from "../../student/util/util";
import {
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { translate } from "../../base/actions/translate";
import { Slide } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Spin } from "antd";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DEFAULT_ZOOM = 1;

const SlideShow = (props) => {
  const [imageList, setImageList] = useState([]);
  const [index, setIndex] = useState(0);
  const [current, setCurrent] = useState({});
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.imageList && props.imageList.length > 0) {
      setImageList(props.imageList);
    }
  }, [props.imageList]);

  useEffect(() => {
    if (props.index !== undefined && props.index !== null) {
      setCurrentImage(props.index);
    }
  }, [props.index]);

  const setCurrentImage = (index) => {
    if (props.imageList.length > index && index > -1) {
      setIndex(index);
      setCurrent(props.imageList[index]);
      setZoom(DEFAULT_ZOOM);
      setLoading(true);
    }
  };

  const onLoadHandler = (e) => {
    console.log("on img load", e);
    setLoading(false);
  };

  const setNextImage = () => {
    if (imageList.length > index + 1) {
      setCurrentImage(index + 1);
    }
  };

  const setPrevImage = () => {
    if (index - 1 < 0) {
      return;
    }
    setCurrentImage(index - 1);
  };

  const zoomIn = () => {
    setZoom(zoom + 0.2);
  };

  const zoomOut = () => {
    if (zoom > 0.2) {
      setZoom(zoom - 0.2);
    }
  };

  const rotateLeft = () => {
    if (!current.angle || current.angle === 0) {
      current.angle = 360;
    }
    current.angle = current.angle - 90;
    setCurrent(current);
    props.updateImageAngle(
      current.angle,
      current.uploadId ? current.uploadId : current.managementUploadId
    );
  };

  const rotateRight = () => {
    if (!current.angle || current.angle === 360) {
      current.angle = 0;
    }
    current.angle = current.angle + 90;
    setCurrent(current);
    props.updateImageAngle(
      current.angle,
      current.uploadId ? current.uploadId : current.managementUploadId
    );
  };

  const toggleSlideShow = () => {
    props.toggleSlideShow(0, null);
  };

  return (
    <React.Fragment>
      <div>
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          open={true}
          onClose={toggleSlideShow}
          aria-labelledby="draggable-dialog-title"
          hideBackdrop={true}
          maxWidth="xl"
        >
          <DialogTitle
            style={{ padding: "5px 24px" }}
            id="draggable-dialog-title"
          >
            {translate(current.type)}
            <div className="image-view-btns">
              {current.type !== TYPE_PDF && current.extension !== "pdf"
                ? [
                    <IconButton
                      key="zoom-in"
                      color="primary"
                      title="Zoom In"
                      edge="start"
                      aria-label="zoom in"
                      onClick={(e) => zoomIn()}
                    >
                      <ZoomInOutlined />
                    </IconButton>,
                    <IconButton
                      key="zoom-out"
                      color="primary"
                      title="Zoom Out"
                      edge="start"
                      aria-label="zoom out"
                      onClick={(e) => zoomOut()}
                    >
                      <ZoomOutOutlined />
                    </IconButton>,
                    <IconButton
                      key="rotate-l"
                      color="primary"
                      title="Rotate Left"
                      edge="start"
                      aria-label="rotate left"
                      onClick={(e) => rotateLeft()}
                    >
                      <RotateLeftOutlined />
                    </IconButton>,
                    <IconButton
                      key="rotate-r"
                      color="primary"
                      title="Rotate Right"
                      edge="start"
                      aria-label="rotate right"
                      onClick={(e) => rotateRight()}
                    >
                      <RotateRightOutlined />
                    </IconButton>,
                  ]
                : ""}
              <IconButton
                key="close-slide"
                edge="start"
                color="secondary"
                onClick={toggleSlideShow}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            {index > 0 ? (
              <div className="prev-btn">
                <a
                  href="/"
                  title="Previous"
                  onClick={(e) => {
                    e.preventDefault();
                    setPrevImage();
                  }}
                >
                  <LeftOutlined />
                </a>
              </div>
            ) : (
              ""
            )}
            <Spin spinning={loading} delay={500}>
              {current.type !== TYPE_PDF && current.extension !== "pdf" ? (
                <img
                  alt={translate(current.type)}
                  src={
                    APP_URI +
                    "/upload/download?path=" +
                    getUploadPath(current, props.year)
                  }
                  onLoad={(e) => onLoadHandler(e)}
                  onError={(e) => onLoadHandler(e)}
                  className={`rotate-${
                    current.angle != null ? current.angle : 0
                  }`}
                  style={{
                    transform:
                      "rotate(" +
                      (current.angle != null ? current.angle : 0) +
                      "deg)",
                    zoom: zoom,
                    MozTransform: `scale(${zoom})`,
                    WebkitTransform:
                      "rotate(" +
                      (current.angle != null ? current.angle : 0) +
                      "deg)",
                  }}
                ></img>
              ) : (
                ""
              )}
              {(current.type === TYPE_PDF || current.extension === "pdf") && (
                <div>
                  <DocViewer
                    pluginRenderers={[PDFRenderer]}
                    documents={[
                      {
                        uri:
                          APP_URI +
                          "/upload/download?path=" +
                          getUploadPath(current, props.year),
                      },
                    ]}
                    config={{
                      header: {
                        disableHeader: true,
                        disableFileName: true,
                        retainURLParams: false,
                      },
                    }}
                  />
                </div>
              )}
            </Spin>
            {props.imageList.length > index + 1 ? (
              <div className="next-btn">
                <a
                  href="/"
                  title="Next"
                  onClick={(e) => {
                    e.preventDefault();
                    setNextImage();
                  }}
                >
                  <RightOutlined />
                </a>
              </div>
            ) : (
              ""
            )}
          </DialogContent>
        </Dialog>
      </div>
    </React.Fragment>
  );
};

export default SlideShow;

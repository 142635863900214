import { FETCH_PROFILE, GET_USER_DETAILS } from "../utils/const";
import { axiosInstance } from "../../Interceptor";

export const fetchProfile = () => {
    return {
        type: FETCH_PROFILE,
        payload: axiosInstance.get('/user')
    };
}

export const getCurrentUserDetails = () => {
    return {
        type: GET_USER_DETAILS,
        payload: axiosInstance.get('/user/details')
    };
}

import { connect } from "react-redux";
import Home from "../components/Home";
import { fetchProfile } from "../actions/HomeActions";
import {login, register} from '../actions/AuthActions';
import moment from 'moment';
import { LANG_CHANGE, LOGIN, REGISTER, TOGGLE_LOGIN_MODAL } from "../utils/const";
import { saveUserProfile } from "../../service/SessionStorageService";

const mapDispatchToProps = dispatch => {
    return {
      fetchProfile() {
        dispatch(fetchProfile());
      },
      login(loginForm)  {
        dispatch(login(loginForm));
      },
      doRegister(key) {
        dispatch( {
          type: REGISTER,
          payload: key?key: 'register'
        });
      },
      register(registerForm) {
        const form = {
          username: registerForm.username,
          dateOfBirth: moment(registerForm.dateOfBirth).utc()
        };
        dispatch(register(form));
        // console.log('-----------reg---------', form);
      },

      setTranslate(lang) {
        dispatch({
          type: LANG_CHANGE,
          payload: lang
        });
      },

      checkUserLoggedIn() {
        const isLoggedIn = sessionStorage.getItem('x-auth-token')? true : false;
        dispatch({
          type: LOGIN,
          payload: isLoggedIn
        });
      },
      toggleLoginModal(showLogin) {
        dispatch({
          type: TOGGLE_LOGIN_MODAL,
          payload: {showLogin}
        });
      }
    };
  };

  const mapStateToProps = state => {
    const { AuthReducer } = state;
    return {
      isLoading: AuthReducer.isLoading,
      profile: AuthReducer.profile,
      role: AuthReducer.role,
      lang: AuthReducer.lang,
      isLoggedIn: AuthReducer.isLoggedIn,
      isSuccess: AuthReducer.isSuccess,
      errorMessage: AuthReducer.errorMessage,
      successMessage: AuthReducer.successMessage,
      userContext: AuthReducer.userContext,
      activeTab: AuthReducer.activeTab,
      showLogin: AuthReducer.showLogin
    }
  }

  export default connect(mapStateToProps,mapDispatchToProps)(Home);
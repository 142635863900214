import React from "react";
import AddStudAccount from "./AddStudAccount";
import { Button, Card, Col, Input, Modal, notification, Row, Space, Spin, Table } from "antd";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const { Search } = Input;

class StudAccounts extends React.Component {

  state = { selectedRowKeys: [], searchText: '' };

  componentWillMount() {
    this.props.getUsersList("MSSSTUD");
  }

  showErrorNotification = (message) => {
    const that = this;
    setTimeout(function () {
      that.props.resetMessages();
    }, 3000);
    notification.error({
      message: message,
    });
  };

  showNotification = (msgType, message) => {
    const that = this;
    setTimeout(function () {
      that.props.resetMessages();
    }, 3000);
    notification[msgType]({
      message: message,
      // description: message,
    });
    // return <Alert message={message} type={msgType} />;
  };

  onChange = (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  };

  editUser = (user, event) => {
    event.preventDefault();
    console.log("Edit User -------: ", user, event);
    this.props.showEditModal(user, true);
  };

  showDeleteConfirm = (user, e) => {
    e.preventDefault();
    const that = this;
    Modal.confirm({
      title: "Do you want to Remove User Access?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        that.props.deleteUser(user);
      },
      onCancel() {},
    });
  };

  showEnableConfirm = (user, e) => {
    e.preventDefault();
    const that = this;
    Modal.confirm({
      title: "Do you want to Activate this User Access?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        that.props.enableUser(user);
      },
      onCancel() {},
    });
  };

  onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  sentPasswordInfo = () => {
    const selKeys = this.state.selectedRowKeys;
    
    console.log("-------selected accts--------", selKeys);
    if (selKeys.length > 0) {
      this.props.sendAcctInfo(selKeys);
    } else {
      notification.error({
        message: "Error",
        description: "Select atleast one student account.",
      });
    }
  };

  offlineSearch = (value) => {
    this.props.offlineSearch(value, this.props.admin.usersList);
    this.setState({ searchText: value });
  };

  render() {
    const USERS_LIST_COLS = [
      {
        title: "Username",
        dataIndex: "username",
        key: "username",
        render: (text, record) => record.user.username,
        sorter: {
          compare: (a, b) => sortNames(a.username, b.username),
        },
      },
      {
        title: "Student Name",
        dataIndex: "studentName",
        key: "studentName",
        render: (text, record) => record.firstName + " " + record.lastName,
        sorter: {
          compare: (a, b) =>
            sortNames(
              a.firstName + " " + a.lastName,
              b.firstName + " " + b.lastName
            ),
        },
      },
      {
        title: "Mobile Number",
        dataIndex: "mobileNumber",
        key: "mobileNumber",
        sorter: {
          compare: (a, b) => a.mobileNumber > b.mobileNumber,
        },
      },
      {
        title: "E-mail",
        dataIndex: "email",
        key: "email",
        sorter: {
          compare: (a, b) => sortNames(a.email, b.email),
        },
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            {record?.user?.active === "Y" ?
            <a href="/" onClick={(e) => this.showDeleteConfirm(record, e)}>Remove User Access</a> : 
            <a href="/" onClick={(e) => this.showEnableConfirm(record, e)}>Activate User Access</a>
            }
            
          </Space>
        ),
      },
    ];

    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div>
        <Card title="Students Accounts">
        <Row>
            <Col style={{ paddingRight: "10px" }} sm={20}>
              <Search
                placeholder="Search"
                value={this.state.searchText}
                onChange={(e) => this.offlineSearch(e.target.value)}
                onSearch={(value) => this.offlineSearch(value)}
                enterButton
                style={{ marginBottom: "10px" }}
              />
            </Col>
            <Col style={{ paddingRight: "10px" }}>
              <Button
                onClick={(e) => this.sentPasswordInfo()}
                loading={this.props.admin.loading}
              >
                Send SMS
              </Button>
            </Col>
          </Row>
        <Spin spinning={this.props.admin.loading} delay={500}>
          <Table
            columns={USERS_LIST_COLS}
            rowSelection={rowSelection}
            rowKey={(record) => record.userDetailsId}
            dataSource={this.props.admin.filteredUsersList}
          />
        </Spin>
        {/* </Card> */}
        {this.props.admin.showAddUser && <AddStudAccount {...this.props} />}
        {/* </Col> */}
        </Card>
        {this.props.admin.successMessage &&
          this.showNotification(
            "success",
            this.props.admin.successMessage
          )}
        {this.props.admin.errorMessage &&
          this.showErrorNotification(this.props.sampurnaSiksha.errorMessage)}
      </div>
    );
  }
}

export default StudAccounts;

import React from "react";
import StudentContainer from "../../student/containers/StudentContainer";
import Auth from "./Auth";
import "../styles/base.css";
import { Spin } from "antd";
import { STUDE, ADMIN, MSSSTUD } from "../utils/roles";
import AdminContainer from "../../admin/containers/AdminContainer";
import StudAcctDashBoardContainer from "../../stud-acct/containers/StudAcctDashBoardContainer";

class Home extends React.Component {
  componentDidMount() {
    this.props.fetchProfile();
  }

  render() {
    return (
      <div>
        <Spin spinning={this.props.isLoading} delay={500}>
          {this.props.profile && this.props.role === STUDE && (
            <StudentContainer></StudentContainer>
          )}

          {this.props.profile && this.props.role === MSSSTUD && (
            <StudAcctDashBoardContainer></StudAcctDashBoardContainer>
          )}

          {!this.props.isLoggedIn && <Auth {...this.props} />}

          {this.props.profile &&
            this.props.role &&
            ![STUDE, MSSSTUD].includes(this.props.role) && <AdminContainer></AdminContainer>}
        </Spin>
      </div>
    );
  }
}

export default Home;

import { Card, Image, List } from "antd";
import React, { useEffect, useState } from "react";
import { APP_URI } from "../../../config/env";
import PDF_ICON from "../../../assets/images/pdfIcon.png";
import DocViewModal from "./DocViewModal";

const DocViewer = (props) => {
  const [docs, setDocs] = useState([]);
  const [currentDoc, setCurrentDoc] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (props.docs) {
      setDocs(props.docs);
      if (currentDoc) {
        const docList = props.docs.filter((t) => t.id === currentDoc.id);
        if (docList.length > 0) {
          setCurrentDoc(docList[0]);
        }
      }
    }
  }, [props.docs]);

  const showDocModal = (file) => {
    setCurrentDoc(file);
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <Card title="Files">
        <List
          grid={{ gutter: 16, column: 4 }}
          dataSource={docs}
          renderItem={(item) => (
            <List.Item>
              {item.extension !== "pdf" && (
                <Image
                  width={props.width ? props.width : 100}
                  src={APP_URI + "/upload/download?path=" + item.src}
                />
              )}
              {item.extension === "pdf" && (
                <Image
                  width={props.width ? props.width : 100}
                  src={PDF_ICON}
                  preview={false}
                  onClick={(e) => showDocModal(item)}
                />
              )}

              {/* <FileViewer fileType={item.extension}
        filePath={APP_URI + "/upload/download?path=" + item.src} /> */}
            </List.Item>
          )}
        />
        {showModal && (
          <DocViewModal
            file={currentDoc}
            closeModal={(e) => setShowModal(false)}
          />
        )}
      </Card>
    </React.Fragment>
  );
};

export default DocViewer;

import React from 'react';
import { Card, Row, Col,  Carousel } from 'antd';
import { VictoryBar, VictoryChart, VictoryAxis } from 'victory';
import { getBoytBlack} from '../utils/images';



class MedhaHelpingHands extends React.Component {
    render() {
        return (

            <Card bordered={false} className=" mhh-main-container card-93 mg-l">
                <title>Medha Helping Hands</title>
                <meta
                    name="description"
                    content="Over 22 lakh students were given books and study material in Telangana and Andhra Pradesh."
                />
                <span className="mss-disc-heading"> Medha Helping Hands </span>

                <div className="mhh-bar-chart">
                    <VictoryChart
                        domainPadding={{ x: 20 }}
                        style={{ width: 550, fontSize:10  }}
                        animate={{
                            duration: 2000,
                            onLoad: { duration: 1000 }

                        }}
                    >
                        <VictoryBar
                            data={[
                                { experiment: "2007", actual: 12600 },
                                { experiment: "2008", actual: 70000 },
                                { experiment: "2009", actual: 148700 },
                                { experiment: "2010", actual: 246000 },
                                { experiment: "2011", actual: 354700 },
                                { experiment: "2012", actual: 410000 },
                                { experiment: "2013", actual: 416000 },
                                { experiment: "2014", actual: 380000 },
                                { experiment: "2015", actual: 180000 }
                            ]}
                            x="experiment"
                            y="actual"
                            style={{
                                data: { fill: "#e29d88" }
                            }}
                        />
                        <VictoryAxis
                            style={{
                                axisLabel: { padding: 70 },
                                tickLabels: {fontSize:10, marginRight:30}
                            }}
                        />
                        <VictoryAxis dependentAxis
                            style={{
                                axisLabel: { padding: 80 }
                            }}
                        />
                    </VictoryChart>
                    <h3>Number of students benefitted</h3>
                </div>
                <div className="dotted-lines-mhh">
                    <div>
                    <h1 className=" colored-heading colored-heading-mhh">Situation</h1>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">M</span>ost of the illiterate people of our country reside in the rural areas, where government schools and colleges lack access to required books and educational resources, which hinders students in attaining quality education. In addition to this, talented students in rural areas are unaware of the opportunities available to them at their arm’s length.
                        </p>
                        <h1 className=" colored-heading colored-heading-mhh">Our Aim</h1>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">T</span>o support students studying in government colleges in rural areas, with educational resources, for the upliftment of the country.
                        </p>
                        <h1 className="colored-heading colored-heading-mhh">Our Solution</h1>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">M</span>any senior lecturers of government junior colleges of Telangana approached us to author books for students studying in Government Junior Colleges. We published these books and supplied it to all students of various government colleges along with prescribed text books. In addition to this, a complete set of books was sent every year to each college’s library as well. With careful logistics planning, books were sent directly to colleges, completely free of cost to students and principals every year. To cater to all student’s individual requirements, our books were published in English, Telugu and Urdu languages.
                        </p>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">M</span>edha Charitable Trust’s biggest strength is the support it receives from its volunteers. Over 300 employees of Medha group of companies voluntarily participated in this effort each year, truly to do community service. These volunteers spent time with key members of each college to discuss problems that colleges continued to face and to personally meet each student and give books to them. The results of our programme reaped good results in a very short span of time. Especially, for students from extremely backward districts like Adilabad, Mahbubnagar and Medak districts, the number of students who completed Intermediate education increased by 30% while there was 10% overall increase in pass percentage in the state of Telangana and Andhra Pradesh.
                        </p>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">S</span>enior lecturers who selflessly authored these books for many years were felicitated in the year 2012 and 2013. Over 120 lecturers of Government Junior Colleges were honoured with laptop computers for their selfless efforts in the past and to encourage them for the years to come.
                        </p>
                        <p className="mss-prog-disc">
                            <span className="mss-start-word">O</span>ver the years, books distribution drive was expanded to other government institutes and colleges in the Telugu states. When Government of Telangana and Government of Andhra Pradesh started a new initiative to provide text books to all students in 2016, we moved away from this initiative to focus on other problem areas in the education sector.
                        </p>
                    </div>
                    <h1 className=" colored-heading colored-heading-mhh highlights-heading"> Highlights</h1>
                    <p className="mss-highlights">
                        <span className="highlight-start"> Years:</span> 2007 – 2015<br></br>
                        <span className="highlight-start">Students Benefitted:</span>  Over 22 lakh students<br></br>
                        <span className="highlight-start"> Colleges:</span>  Over 1100 colleges  <br></br>
                        <span className="highlight-start"> Colleges Benefitted:</span>    Government Junior Colleges, Government Aided Colleges, <br></br>
                                                                                    Government Model High Schools, Tribal Welfare Colleges, <br></br>
                                                                                    Social Welfare Colleges, <br></br>
                                                                                    Industrial Training Institutes in Telangana and Andhra Pradesh <br></br>

                    </p>
                </div>
            </Card>
        );
    }
}
export default MedhaHelpingHands;
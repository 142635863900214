import { connect } from "react-redux";
import { getSystemConfigs, saveSystemConfigs } from "../../base/actions/AppConfigActions";
import { RESET_MESSAGES } from "../utils/consts";
import SendSMS from "../components/SendSMS";
import { getAllSMSLogCampaigns, getAllSMSLogs } from "../actions/SMSActions";

const mapDispatchToProps = (dispatch, props) => {
  return {
    getSmsLogs(form) {
      dispatch(getAllSMSLogs(form));
    },
    getAllSMSLogCampaigns() {
      dispatch(getAllSMSLogCampaigns());
    },
    resetMessage() {
      dispatch({
          type: RESET_MESSAGES
      });
  },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, AdminReducer, SMSReducer } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    sms: SMSReducer
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendSMS);

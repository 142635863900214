import { GET_ALL_SMS_TEMPLATE, GET_SMS_LOGS, GET_SMS_LOGS_CAMPAIGNS, RESET_MESSAGES, SAVE_SMS_TEMPLATE, SEND_BULK_SMS, SEND_SMS, TOGGLE_ADHOC_SMS, TOGGLE_EDIT_SMS, TOGGLE_SEND_SMS, TOGGLE_SMS_LOGS } from "../utils/consts";

const initialState = {
    loading: false,
    smsTemplateList: [],
    currentTemplate: {},
    smsLogs: [],
    smsLogCampaigns: [],
    errorMessage:'',
    successMesage: '',
    showEditModal: false,
    showSendModal: false,
    showLogsModal: false,
    showAdhocModal: false
};

const SMSReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${GET_ALL_SMS_TEMPLATE}_PENDING`: {
            return {
                ...state,
                loading: true,
                errorMessage: '',
                successMessage: '',
                smsTemplateList: []
            };
        }

        case `${GET_ALL_SMS_TEMPLATE}_FULFILLED`: {
            return {
                ...state,
                loading: false,
                smsTemplateList: action.payload,
                successMessage: ''
            };
        }

        case `${GET_ALL_SMS_TEMPLATE}_REJECTED`: {
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            };
        }

        case `${SAVE_SMS_TEMPLATE}_PENDING`: {
            return {
                ...state,
                loading: true,
                errorMessage: '',
                successMessage: '',
            };
        }

        case `${SAVE_SMS_TEMPLATE}_FULFILLED`: {
            const smsTemplateList = state.smsTemplateList;
            const template = action.payload;
            for(const temp of smsTemplateList) {
                if(temp.smsTemplateId === template.smsTemplateId) {
                    temp.smsTemplateValue = template.smsTemplateValue;
                }
            }
            return {
                ...state,
                loading: false,
                successMessage: "Template saved successfully.",
                showEditModal: false,
                smsTemplateList: smsTemplateList
            };
        }

        case `${SAVE_SMS_TEMPLATE}_REJECTED`: {
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            };
        }

        case `${SEND_SMS}_PENDING`: {
            return {
                ...state,
                loading: true,
                errorMessage: '',
                successMessage: '',
            };
        }

        case `${SEND_SMS}_FULFILLED`: {
            return {
                ...state,
                loading: false,
                successMessage: action.payload,
            };
        }

        case `${SEND_SMS}_REJECTED`: {
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            };
        }

        case `${SEND_BULK_SMS}_PENDING`: {
            return {
                ...state,
                loading: true,
                errorMessage: '',
                successMessage: '',
            };
        }

        case `${SEND_BULK_SMS}_FULFILLED`: {
            return {
                ...state,
                loading: false,
                successMessage: action.payload,
            };
        }

        case `${SEND_BULK_SMS}_REJECTED`: {
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            };
        }

        case `${GET_SMS_LOGS}_PENDING`: {
            return {
                ...state,
                loading: true,
                errorMessage: '',
                successMessage: '',
                smsLogs: []
            };
        }

        case `${GET_SMS_LOGS}_FULFILLED`: {
            return {
                ...state,
                loading: false,
                smsLogs: action.payload
            };
        }

        case `${GET_SMS_LOGS}_REJECTED`: {
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            };
        }

        case `${GET_SMS_LOGS_CAMPAIGNS}_PENDING`: {
            return {
                ...state,
                loading: true,
                errorMessage: '',
                successMessage: '',
                smsLogCampaigns: []
            };
        }

        case `${GET_SMS_LOGS_CAMPAIGNS}_FULFILLED`: {
            return {
                ...state,
                loading: false,
                smsLogCampaigns: action.payload
            };
        }

        case `${GET_SMS_LOGS_CAMPAIGNS}_REJECTED`: {
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            };
        }

        case TOGGLE_EDIT_SMS : {
            return {
                ...state,
                showEditModal: !state.showEditModal
            };
        }

        case TOGGLE_SEND_SMS : {
            return {
                ...state,
                showSendModal: !state.showSendModal
            };
        }

        case TOGGLE_SMS_LOGS : {
            return {
                ...state,
                showLogsModal: !state.showLogsModal
            };
        }

        case TOGGLE_ADHOC_SMS : {
            return {
                ...state,
                showAdhocModal: !state.showAdhocModal
            };
        }

        case RESET_MESSAGES: {
            return {
              ...state,
              successMessage: "",
              errorMessage: "",
            };
          }

        default:
            return state;
    }
};

export default SMSReducer;
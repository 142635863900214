import { MED_TYP, MON_TYP, WEL_TYP } from "../../management/utils/const";

export const EDUCATION_TYPE = "EDUCATION_TYPE";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const SAVE_CATEGORY = "SAVE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const TOGGLE_ADD_CATEGORY = "TOGGLE_ADD_CATEGORY";
export const FETCH_COURSES = "FETCH_COURSES";
export const SAVE_COURSE = "SAVE_COURSE";
export const DELETE_COURSE = "DELETE_COURSE";
export const TOGGLE_ADD_COURSE = "TOGGLE_ADD_COURSE";

export const FETCH_MASTER_TABLE = "FETCH_MASTER_TABLE";
export const FIND_MASTER_TABLE = "FIND_MASTER_TABLE";
export const SAVE_MASTER_TABLE = "SAVE_MASTER_TABLE";
export const DELETE_MASTER_TABLE = "DELETE_MASTER_TABLE";
export const SEARCH_MASTER_TABLE = "SEARCH_MASTER_TABLE";
export const FETCH_REQ_CAT = "FETCH_REQ_CAT";
export const FETCH_REQ_SUB_CAT = "FETCH_REQ_SUB_CAT";
export const FETCH_MASTER_OLD_DISTRICTS = "FETCH_MASTER_OLD_DISTRICTS";
export const SET_CURRENT_REC = "SET_CURRENT_REC";

export const TOGGLE_ADD_MODAL = "TOGGLE_ADD_MODAL";

export const SUB = "subject";
export const AFFL = "affiliation";
export const CUR = "curriculum";
export const ED_TYP = "educationTypes";
export const CAT = "categories";
export const COURSE = "courses";
export const SEM = "semister";
export const INST = "institute";
export const HOST = "hostel";
export const REQ_CAT = "reqCat";
export const REQ_SUB_CAT = "reqSubCat";
export const DIST = "DIST";
export const MNDL = "MNDL";
export const SCHL = "SCHL";
export const EXMCNT = "EXAM_CENTER";
export const GRADE = "grade";

export const CRED_PERCENT = 1;
export const CRED_GRADE = 2;

export const CRED_SYS = [
  {
    label: "Percentage",
    value: CRED_PERCENT,
  },
  {
    label: "Grading",
    value: CRED_GRADE,
  },
];

export const MED_REQ = 2;
export const WEL_REQ = 3;
export const MON_REQ = 4;

export const MED_REQ_TYP = {
  label: "Medical",
  key: MED_REQ,
};

export const WEL_REQ_TYP = {
  label: "Welfare",
  key: WEL_REQ,
};
export const MON_REQ_TYP = {
  label: "Monetary",
  key: MON_REQ,
};
export const REQ_CAT_TYP = [MED_REQ_TYP, WEL_REQ_TYP, MON_REQ_TYP];

export const INACTV = "INACTV";

import { Button, notification } from "antd";
import React, { useEffect, useState } from "react";
import RichTextEditor from "react-rte";

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'}
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'}
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'}
  ]
};

const HallTicketText = (props) => {
  const [hallTicketText, setHallTicketText] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [appConfig, setAppConfig] = useState({});
  useEffect(() => {
    const sysConfig = props.appConfig.systemConfig;
    for (const config of sysConfig) {
      if (config.configCd === "REQ_DOCS") {
        setHallTicketText(RichTextEditor.createValueFromString(config.info, 'html'));
        setAppConfig(config);
      }
    }
  }, [props.appConfig.systemConfig]);

  const onChange = (value) => {
    setHallTicketText(value);
  };

 const saveText = () => {
      const htmlText = hallTicketText.toString('html');
      if(htmlText === '<p><br></p>') {
        notification.error({
            message: "Error",
            description: "Please add some text.",
          });
      }
      else
      {
      console.log('------------', htmlText);
      appConfig.info = htmlText;
      appConfig.configVal = "";
      props.saveConfig(appConfig);
      }
  }

  return (
    <React.Fragment>
      <div style={{ marginBottom: "10px", display: "block" }}>
        <RichTextEditor toolbarConfig={toolbarConfig} value={hallTicketText} onChange={(e) => onChange(e)} />
      </div>
      <div>
        <Button type="primary" style={{ margin: "auto", display: "block" }} onClick={e => saveText()}>
          Save
        </Button>
      </div>
    </React.Fragment>
  );
};

export default HallTicketText;

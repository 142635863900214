import { RESET_MESSAGES } from "../../base/utils/const";
import {
  MSG_ADD_TO_DEL_FILES,
  MSG_APPROVED_DRAFTS,
  MSG_DRAFT_IMG,
  MSG_ROTATE_IMG,
  MSG_DEL_DRAFT,
  SAVE_MSG,
} from "../utils/const";

const initialState = {
  loading: false,
  errorMessage: "",
  successMessage: "",
  currentUploads: [],
  deletedImages: [],
};

const InteractionUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${MSG_DRAFT_IMG}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${MSG_DRAFT_IMG}_FULFILLED`: {
      const image = action.payload;
      const { currentUploads } = state;
      currentUploads.push(image);
      for (const i of currentUploads) {
        i.managementUploadId = i.interactionFileId;
        i.type='MSG_INTR';
      }

      return {
        ...state,
        loading: false,
        currentUploads: currentUploads,
      };
    }

    case `${MSG_DRAFT_IMG}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case `${MSG_DEL_DRAFT}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
        deletedImages: [],
      };
    }

    case `${MSG_DEL_DRAFT}_FULFILLED`: {
      return {
        ...state,
        loading: false,
      };
    }

    case `${MSG_DEL_DRAFT}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case `${MSG_APPROVED_DRAFTS}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${MSG_APPROVED_DRAFTS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
      };
    }

    case `${MSG_APPROVED_DRAFTS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case `${MSG_ROTATE_IMG}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${MSG_ROTATE_IMG}_REJECTED`: {
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    }

    case `${MSG_ROTATE_IMG}_FULFILLED`: {
      const image = action.payload;
      const { currentUploads } = state;
      for (const i of currentUploads) {
        if (
          i.type === image.type &&
          i.managementUploadId === image.managementUploadId
        ) {
          i.angle = image.angle;
        }
      }

      return {
        ...state,
        loading: false,
        currentUploads: currentUploads,
        // successMessage: 'Image updated successfully'
      };
    }

    case MSG_ADD_TO_DEL_FILES: {
      const deletedImages = state.deletedImages.concat(action.payload);
      const { currentUploads } = state;
      for (const image of action.payload) {
        for (let i = 0; i < currentUploads.length; i++) {
          if (
            currentUploads[i].type === image.type &&
            currentUploads[i].managementUploadId === image.managementUploadId
          ) {
            currentUploads.splice(i, 1);
          }
        }
      }

      return {
        ...state,
        deletedImages: deletedImages,
        currentUploads: currentUploads,
      };
    }

    case `${SAVE_MSG}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        currentUploads: []
      };
    }

    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    }

    default:
      return state;
  }
};

export default InteractionUploadReducer;

import React from 'react';
import { Form, Input, Button, DatePicker, Alert  } from 'antd';
import { MobileOutlined } from '@ant-design/icons';

const dateFormat = 'DD/MM/YYYY';

class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            onFinish: false,
        };
        this.onFinish = this.onFinish.bind(this);
    }

    onChange(date, dateString) {
        // console.log(date, dateString);
    }
    onFinish = formValues => {
        this.props.register(formValues);
      };

      showErrorMessage() {
        const that = this;
        setTimeout(()=> {
            that.props.resetMessages();
        },3000);
        return (<Alert message={this.props.errorMessage} type="error" showIcon />);
    }

    showSuccessMessage() {
        const that = this;
        setTimeout(()=> {
            that.props.resetMessages();
        },30000);
        return (<Alert message="Password has been sent to your registered mobile number" type="success" showIcon />);
    }

    render() {
        return (
            <div className="card-container register-container">
                <Form
                    name="normal_login"
                    className="login-form login-wrapper"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={this.onFinish}
                >
                    <div className="content-wrapper">
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    pattern: '^[0-9]*$',
                                    len: 10,
                                    message: 'Please enter valid mobile number',
                                },
                            ]}
                        >
                            <Input prefix={<MobileOutlined className="site-form-item-icon" />} placeholder="Mobile Number" />
                        </Form.Item>
                        <Form.Item
                            name="dateOfBirth"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your Date of Birth!',
                                },
                            ]}
                        >
                            <DatePicker onChange={this.onChange} style={{width: "100%"}} placeholder="Date of Birth" format={dateFormat}/>
                        </Form.Item>
                        
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Register
                    </Button>
                        </Form.Item>
                    </div>
                </Form>
                {this.props.successMessage && 
                    this.showSuccessMessage()
                }
                {this.props.errorMessage && 
                    this.showErrorMessage()
                }
                
                
            </div>
        );
    }
}

export default Register;
import { message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { translate } from "../../../base/actions/translate";
import { APP_URI } from "../../../config/env";
import { getUploadPath } from "../../../student/util/util";
import { getMaxUploadCount } from "../../utils/util";
import "../../styles/uploadStyles.css";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@material-ui/core";
import PreviewIcon from "@mui/icons-material/Preview";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Box } from "@mui/system";
import { IMAGE_TYPES } from "../../../student/util/const";
import Typography from "@mui/material/Typography";
import SlideShow from "../../../common/components/SlideShow";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Input = styled("input")({
  display: "none",
});

const UploadByCat = (props) => {
  const [catImages, setCatImages] = useState({});
  const [expanded, setExpanded] = useState("SOLO");
  const [showCat, setShowCat] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (
      props.imageList &&
      props.imageList.length > 0 &&
      props.categories &&
      props.categories.length > 0
    ) {
      const catImgs = {};
      for (const cat of props.categories) {
        catImgs[cat] = props.imageList.filter((img) => img.type === cat);
      }
      setCatImages(catImgs);
    }
  }, [props.imageList, props.categories]);

  const getImageUrl = (imageObj) => {
    return (
      APP_URI + "/upload/download?path=" + getUploadPath(imageObj, props.year)
    );
  };

  const getUploadedCount = (cat) => {
    return catImages[cat] ? catImages[cat].length : 0;
  };

  const handleAChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const toggleSlideShow = (item) => {
    if (item) {
      let index = 0;
      for (let i = 0; i < props.imageList.length; i++) {
        if (
          item &&
          item.uploadId &&
          item.uploadId === props.imageList[i].uploadId
        ) {
          index = i;
        }
        if (
          item &&
          item.managementUploadId &&
          item.managementUploadId === props.imageList[i].managementUploadId
        ) {
          index = i;
        }
      }
      setIndex(index);
      setShowCat(true);
    } else {
      setShowCat(false);
    }
  };

  const setFiles = (e, cat) => {
    // console.log("files", this.files);
    const files = document.getElementById("contained-button-file-" + cat).files;
    if (!files[0]) {
      return;
    }

    let valid = true;
    for (const f of files) {
      if (f.size / 1024 > 500) {
        message.error("Please upload image files less than 500 KB.");
        valid = false;
      } else if (!IMAGE_TYPES.includes(f.type)) {
        message.error("Only jpeg, png, tiff, gif images are allowed");
        valid = false;
      }
    }

    if (!valid) {
      document.getElementById("contained-button-file-" + cat).value = "";
      return;
    }

    const maxCount = getMaxUploadCount(cat);
    const curCount = getUploadedCount(cat);
    if (curCount + files.length <= maxCount) {
      uploadDraft(files, cat);
    } else {
      Modal.warning({
        title: "Limit Exceeded",
        content:
          "Limit for the number of images to be upload for this category is exceeded.",
      });
      document.getElementById("contained-button-file-" + cat).value = "";
    }
  };

  const uploadDraft = (file, cat) => {
    // Save the new image uploaded as a draft.
    props.uploadSurveyImg(props.application.applicationId, file, cat);
    document.getElementById("contained-button-file-" + cat).value = "";
  };

  const deleteImage = (file, cat) => {
    props.addToDeleteFiles([file]);
  };

  return (
    <React.Fragment>
      <div className="upload-by-cat">
        {props.categories
          ? props.categories.map((cat) => (
              <Accordion
                key={cat + "accord"}
                expanded={expanded === cat}
                onChange={handleAChange(cat)}
              >
                <AccordionSummary
                  aria-controls={cat + "-content"}
                  id={cat + "-header"}
                  style={{ paddingLeft: "10px" }}
                >
                  <Typography>
                    {translate(cat)} {getUploadedCount(cat)}/
                    {getMaxUploadCount(cat)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {catImages[cat] && (
                    <ImageList cols={5}>
                      {catImages[cat].map((item, i) => (
                        <ImageListItem
                          key={item.uploadId}
                          style={{ height: "100px" }}
                        >
                          <img
                            src={getImageUrl(item)}
                            srcSet={getImageUrl(item)}
                            className={`rotate-${
                              item.angle != null ? item.angle : 0
                            }`}
                            style={{
                              // height: "180px",
                              // width: "180px",
                              transform:
                                "rotate(" +
                                (item.angle ? item.angle : 0) +
                                "deg)",
                            }}
                            alt={item.title}
                            loading="lazy"
                          />
                          <ImageListItemBar
                            title={item.title}
                            subtitle={item.author}
                            actionIcon={[
                              <IconButton
                                key={`${cat}-${i}-preview`}
                                style={{ color: "rgba(255, 255, 255, 0.54)" }}
                                aria-label={`info about ${item.title}`}
                                onClick={(e) => toggleSlideShow(item)}
                              >
                                <PreviewIcon />
                              </IconButton>,
                              <IconButton
                                key={`${cat}-${i}-del`}
                                style={{ color: "rgba(255, 255, 255, 0.54)" }}
                                aria-label={`info about ${item.title}`}
                                onClick={(e) => deleteImage(item, cat)}
                              >
                                <DeleteOutlinedIcon />
                              </IconButton>,
                            ]}
                          />
                        </ImageListItem>
                      ))}
                      {catImages[cat].length < getMaxUploadCount(cat) ? (
                        <ImageListItem
                          key="uploadImg"
                          style={{ height: "100px" }}
                        >
                          <Box component="span" sx={{ p: 2 }}>
                            <label htmlFor={"contained-button-file-" + cat}>
                              <Input
                                accept="image/*"
                                id={"contained-button-file-" + cat}
                                multiple
                                type="file"
                                onChange={(e) => setFiles(e, cat)}
                              />
                              <IconButton
                                aria-label="Upload Image"
                                component="span"
                                color="primary"
                              >
                                <FileUploadOutlinedIcon />
                              </IconButton>
                            </label>
                          </Box>
                        </ImageListItem>
                      ) : (
                        ""
                      )}
                    </ImageList>
                  )}
                  {!catImages[cat] ? (
                    <ImageList cols={5}>
                      <ImageListItem
                        key="uploadImg"
                        style={{ height: "100px" }}
                      >
                        <Box component="span" sx={{ p: 2 }}>
                          <label htmlFor={"contained-button-file-" + cat}>
                            <Input
                              accept="image/*"
                              id={"contained-button-file-" + cat}
                              multiple
                              type="file"
                              onChange={(e) => setFiles(e, cat)}
                            />
                            <IconButton
                              aria-label="Upload Image"
                              component="span"
                              color="primary"
                            >
                              <FileUploadOutlinedIcon />
                            </IconButton>
                          </label>
                        </Box>
                      </ImageListItem>
                    </ImageList>
                  ) : (
                    ""
                  )}
                </AccordionDetails>
              </Accordion>
            ))
          : ""}
      </div>
      {showCat ? (
        <SlideShow
          year={props.year}
          imageList={props.imageList}
          index={index}
          toggleSlideShow={toggleSlideShow}
          updateImageAngle={props.updateImageAngle}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default UploadByCat;

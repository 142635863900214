import { Button, Card, Col, Form, Input, Modal, Row } from "antd";
import React from "react";
import { translate } from "../../../base/actions/translate";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { prepareReviewersFieldData } from "../../utils/util";
import { formItemLayout, PER_INFO } from "../../utils/consts";
import ReviewStep from "./ReviewStep";

const dFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 13 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 11 },
  },
};

class PersonalInfo extends React.Component {
  compare = (appl, form) => {
    const msg = [];
    if (form.fatherName !== appl.fatherName) {
      msg.push(
        <li>
          You have changed Father's Name from {appl.fatherName} to{" "}
          {form.fatherName}
        </li>
      );
    }

    if (form.motherName !== appl.motherName) {
      msg.push(
        <li>
          You have changed Mother's Name from {appl.motherName} to{" "}
          {form.motherName}
        </li>
      );
    }

    return msg;
  };

  onFinish = (form) => {
    // console.log("Received values of form:", form);
    const application = this.props.review.reviewDetails.application;
    const msg = this.compare(application, form);
    if (msg.length > 0) {
      this.showConfirm(form, msg);
    } else {
      this.props.saveDetails(PER_INFO, form);
      // console.log("-------------", form);
      // this.props.next();
    }
  };

  showConfirm = (form, msg) => {
    const that = this;
    Modal.confirm({
      title: "Do you want to replace this information?",
      // icon: <ExclamationCircleOutlined />,
      content: <ul>{msg}</ul>,
      onOk() {
        // console.log("OK");
        that.props.saveDetails(PER_INFO, form);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  render() {
    const application = this.props.review.reviewDetails.application;
    let personalInfo = this.props.review.reviewFormData[PER_INFO];
    if (!personalInfo) {
      personalInfo = {
        fatherName: application.fatherName,
        motherName: application.motherName,
      };
    }
    const fieldData = prepareReviewersFieldData(personalInfo);
    return (
      <div className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Personal Information"
          onFinish={(e) => this.onFinish(e)}
          {...this.props}
        >
          <Row gutter={[16, 8]}>
            <Col xs={24} sm={12}>
              <Form.Item
                {...formItemLayout}
                name="fatherName"
                label={translate("fatherName")}
                getValueFromEvent={this.toCapital}
                rules={[
                  {
                    required: true,
                    min: 3,
                    pattern: /^\S[A-Za-z\s]+$/,
                    message: translate("fatherNameError"),
                  },
                ]}
              >
                <Input maxLength="30" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                {...formItemLayout}
                name="fatherEducation"
                label="Father's Education"
                getValueFromEvent={this.toCapital}
                rules={[
                  {
                    required: true,
                    min: 3,
                    pattern: /^\S[a-zA-Z0-9\-_.\s]{0,20}$/,
                    message: "Father's Education is required",
                  },
                ]}
              >
                <Input maxLength="30" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                {...formItemLayout}
                name="motherName"
                label={translate("mothersName")}
                getValueFromEvent={this.toCapital}
                rules={[
                  {
                    required: true,
                    min: 3,
                    pattern: /^\S[A-Za-z\s]+$/,
                    message: translate("mothersNameError"),
                  },
                ]}
              >
                <Input maxLength="30" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                {...formItemLayout}
                name="motherEducation"
                label="Mother's Education"
                getValueFromEvent={this.toCapital}
                rules={[
                  {
                    required: true,
                    min: 3,
                    pattern: /^\S[a-zA-Z0-9\-_.\s]{0,20}$/,
                    message: "Mother's Education is required",
                  },
                ]}
              >
                <Input maxLength="30" />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.List name="siblingsList">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, idx) => (
                        <Row key={idx} gutter={[16, 8]}>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "siblingName"]}
                              fieldKey={[field.fieldKey, "siblingName"]}
                              label="Sibling's Name"
                              rules={[
                                {
                                pattern: /^[A-Za-z\s]+$/,
                                message: "Please enter proper sibling's name",
                                },
                              ]}
                            >
                              <Input maxLength="30" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={11}>
                            <Form.Item
                              {...dFormItemLayout}
                              name={[field.name, "siblingEducation"]}
                              fieldKey={[field.fieldKey, "siblingEducation"]}
                              label="Sibling's Education"
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    const siblings = getFieldValue(
                                      "siblingsList"
                                    );
                                    const patt = /^\S[a-zA-Z0-9\-_.\s]{0,20}$/;
                                    // const sName = siblings[idx].siblingName;
                                    if (
                                      !value &&
                                      siblings[idx] &&
                                      siblings[idx].siblingName
                                    ) {
                                      return Promise.reject(
                                        "Sibling's Education is required"
                                      );
                                    } else if(!patt.test(value)) {
                                      return Promise.reject(
                                        "Sibling's Education is required"
                                      );
                                    }

                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input maxLength="30" />
                            </Form.Item>
                          </Col>
                          <Col>
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </Col>
                        </Row>
                      ))}

                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined /> Add Sibling
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </Col>
          </Row>
        </ReviewStep>
      </div>
    );
  }
}

export default PersonalInfo;

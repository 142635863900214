import { connect } from "react-redux";
import { getTaskList, changeTaskStatus } from "../actions/SSActions";
import { taskListPredicate } from "../utils/util";
import { SEARCH_TASK } from "../utils/consts";
import { RESET_MESSAGES } from "../../base/utils/const";
import { fetchMandals } from "../../base/actions/AppConfigActions";
import AddSchoolRequestTasks from "../components/AddSchoolRequestTasks";
import { getSMSTemplateByCode } from "../../admin/actions/SMSActions";

const mapDispatchToProps = (dispatch, props) => {
  return {
    getTaskList(status) {
      dispatch(getTaskList(status));
    },

    getSMSTemplateByCode(code) {
      dispatch(getSMSTemplateByCode(code));
    },

    offlineSearch(searchText, taskList) {
      if (searchText && searchText.length > 1) {
        const result = taskList.filter((task) => {
          return taskListPredicate(task, searchText);
        });
        dispatch({
          type: SEARCH_TASK,
          payload: result,
        });
      } else if (!searchText) {
        dispatch({
          type: SEARCH_TASK,
          payload: taskList,
        });
      }
    },
    getMandalsList(districtId) {
      dispatch(fetchMandals(districtId));
    },
    changeTaskStatus(workflow, status) {
      dispatch(changeTaskStatus(workflow)).then((res) => {
        this.getTaskList(status);
      });
    },
    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, AdminReducer, SSReducer, AppConfigReducer, SMSReducer } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    sampurnaSiksha: SSReducer,
    mandalsList: AppConfigReducer.mandalsList,
    appConfig: AppConfigReducer,
    sms: SMSReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSchoolRequestTasks);

import {
  MED_REQ_TYP,
  MON_REQ_TYP,
  WEL_REQ_TYP,
} from "../../mastertables/utils/const";

export const FETCH_ENROLL_DETAILS = "FETCH_ENROLL_DETAILS";
export const SAVE_ENROLL_DETAILS = "SAVE_ENROLL_DETAILS";

export const SET_SEARCH_FORM = "SET_SEARCH_FORM";
export const SEARCH_STUDENT_PROF = "SEARCH_STUDENT_PROF";
export const AVAILABLE_BATCHES = "AVAILABLE_BATCHES";
export const STUD_PROFILE = "STUD_PROFILE";

export const STUD_HSTL = "STUD_HSTL";
export const STUD_ACAD = "STUD_ACAD";
export const STUD_MED = "STUD_MED";
export const STUD_WEL = "STUD_WEL";
export const STUD_MON = "STUD_MON";
export const STUD_INTR = "STUD_INTR";

export const SAVE_STUD_PROFILE = "SAVE_STUD_PROFILE";
export const STUD_STATUS = "STUD_STATUS";
export const SET_EDIT_FORM = "SET_EDIT_FORM";
export const SET_FILTERED_PROFILES = "SET_FILTERED_PROFILES";

export const ADD_TO_DEL_MNG_FILES = "ADD_TO_DEL_MNG_FILES";
export const DEL_MNG_FILES = "DEL_MNG_FILES";
export const APPROVED_MNG_DRAFTS = "APPROVED_MNG_DRAFTS";
export const DRAFT_MNG_IMG = "DRAFT_MNG_IMG";
export const ROTATE_MNG_IMG = "ROTATE_MNG_IMG";
export const DEL_MNG_DRAFT = "DEL_MNG_DRAFT";
export const GET_MNG_IMG = "GET_MNG_IMG";

export const DRAFT_REQ_IMG = "DRAFT_REQ_IMG";
export const ROTATE_REQ_IMG = "ROTATE_REQ_IMG";

export const GET_INCHARGE = "GET_INCHARGE";
export const SET_INCHARGE = "SET_INCHARGE";

// Student Academics
export const SAVE_STUD_ACAD = "SAVE_STUD_ACAD";

// Student Hostel details
export const SAVE_STUD_HSTL = "SAVE_STUD_HSTL";
export const DEL_STUD_HSTL = "DEL_STUD_HSTL";
export const GET_STUD_HSTL_CMNTS = "GET_STUD_HSTL_CMNTS";
export const DEL_STUD_HSTL_CMNTS = "DEL_STUD_HSTL_CMNTS";
export const SAVE_STUD_HSTL_CMNTS = "SAVE_STUD_HSTL_CMNTS";

// Medical requests
export const GET_MED_REQ = "GET_MED_REQ";
export const SAVE_MED_REQ = "SAVE_MED_REQ";
export const DEL_MED_REQ = "DEL_MED_REQ";

// Welfare requests
export const GET_WEL_REQ = "GET_WEL_REQ";
export const SAVE_WEL_REQ = "SAVE_WEL_REQ";
export const DEL_WEL_REQ = "DEL_WEL_REQ";

// Monitory requests
export const GET_MON_REQ = "GET_MON_REQ";
export const SAVE_MON_REQ = "SAVE_MON_REQ";
export const DEL_MON_REQ = "DEL_MON_REQ";
export const SET_CUR_REQ = "SET_CUR_REQ";

//Student Notes
export const GET_NOTES = "GET_NOTES";
export const LOAD_MORE_NOTES = "LOAD_MORE_NOTES";
export const SAVE_NOTES = "SAVE_NOTES";
export const NOTES_ADD_TO_DEL_FILES = "NOTES_ADD_TO_DEL_FILES";
export const NOTES_ROTATE_IMG = "NOTES_ROTATE_IMG";
export const NOTES_APPROVED_DRAFTS = "NOTES_APPROVED_DRAFTS";
export const NOTES_DEL_DRAFT = "NOTES_DEL_DRAFT";
export const NOTES_DRAFT_IMG = "NOTES_DRAFT_IMG";
export const NOTES_DEL_FILES = '';

export const GET_EXP = "GET_EXP";

export const CREATE_BULK_REQ = "CREATE_BULK_REQ";
export const SEARCH_BULK_REQ = "SEARCH_BULK_REQ";

export const STUD_IMG = "STUD_IMG";
export const PAN = "PAN";
export const ADHAAR = "ADHAAR";
export const CASTE_CERT = "CASTE_CERT";
export const INC_PROF = "INC_PROF";

export const STATUS_MODAL = "STATUS";
export const INCH_MODAL = "INCH_MODAL";

export const SP_FORM = "SP";
export const AC_FORM = "AC";

export const STUD_PROF_FILE_TYPES = [
  STUD_IMG,
  PAN,
  ADHAAR,
  CASTE_CERT,
  INC_PROF,
];

export const ENROLL = 12;
export const JOIN = 13;
export const DROP = 15;
export const COMPL = 14;
export const RJOIN = 16;

export const STUD_STATUSES = [
  {
    key: ENROLL,
    label: "Enrolled",
  },
  {
    key: JOIN,
    label: "Active",
  },
  {
    key: DROP,
    label: "Dropped Out",
  },
  {
    key: COMPL,
    label: "Completed",
  },
  {
    key: RJOIN,
    label: "Rejoined",
  },
];

export const JOB_OFR = 1;
export const HIGH_EDU = 2;
export const JOB_SEARCH = 3;

export const JOIN_STATUS = [
  {
    key: ENROLL,
    label: "Enrolled",
  },
  {
    key: JOIN,
    label: "Joined",
  },
];

export const BLOOD_GROUPS = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

export const ACTV = 1;
export const PEND = 3;
export const DENIED = 4;

export const PROFILE_IMGS = [STUD_IMG, PAN, ADHAAR, CASTE_CERT, INC_PROF];

export const MARKS_MEMO = "MARKS_MEMO";
export const MED_REC = "MED_REC";
export const MON_REC = "MON_REC";

export const TASK_UPLOAD_CATS = [MARKS_MEMO, MED_REC, MON_REC];

export const MED_TYP_CONS = 1;
export const MED_TYP_LAB = 2;
export const MED_TYP_PHARM = 3;
export const MED_TYP_INP = 4;
export const MED_TYP_CAMP = 5;

export const MED_TYP = [
  {
    key: MED_TYP_CONS,
    label: "Consultation",
  },
  {
    key: MED_TYP_LAB,
    label: "Lab",
  },
  {
    key: MED_TYP_PHARM,
    label: "Pharmacy",
  },
  {
    key: MED_TYP_INP,
    label: "In-Patient",
  },
  {
    key: MED_TYP_CAMP,
    label: "Medical Camp",
  },
];

export const WEL_TYP_OTHR = 0;
export const WEL_TYP_NBKS = 1;
export const WEL_TYP_TBKS = 2;
export const WEL_TYP_STNR = 3;
export const WEL_TYP_LAB = 4;
export const WEL_TYP_MKIT = 5;
export const WEL_TYP_EKIT = 6;

export const WEL_TYP = [
  {
    key: WEL_TYP_STNR,
    label: "Stationary",
    subTypes: [
      {
        key: 1,
        label: "Note Books",
      },
      {
        key: 2,
        label: "Text Books",
      },
      {
        key: 3,
        label: "Aprons / Lab Coat / Shoes",
      },
      {
        key: 4,
        label: "Medical Kit",
      },
      {
        key: 5,
        label: "Engineering Kit",
      },
    ],
  },
  {
    key: WEL_TYP_OTHR,
    label: "Others",
    subTypes: null,
  },
];

export const WEL_TYP1 = [
  {
    key: WEL_TYP_NBKS,
    label: "Note Books",
    subTypes: [
      {
        key: 1,
        label: "Note Books",
      },
    ],
  },
  {
    key: WEL_TYP_TBKS,
    label: "Text Books",
    subTypes: [
      {
        key: 1,
        label: "Science Text Book",
      },
    ],
  },
  {
    key: WEL_TYP_STNR,
    label: "Stationary",
    subTypes: [],
  },
  {
    key: WEL_TYP_LAB,
    label: "Aprons / Lab Coat / Shoes",
    subTypes: [],
  },
  {
    key: WEL_TYP_MKIT,
    label: "Medical Kit",
    subTypes: [],
  },
  {
    key: WEL_TYP_EKIT,
    label: "Engineering Kit",
    subTypes: [],
  },
  {
    key: WEL_TYP_OTHR,
    label: "Others",
    subTypes: null,
  },
];

export const PAY_TO = [
  {
    key: 1,
    label: "Student",
  },
  {
    key: 2,
    label: "Institute",
  },
];

export const PAY_MODE = [
  {
    key: 1,
    label: "Cash",
  },
  {
    key: 2,
    label: "Cheque",
  },
];

export const MON_TYP = [
  {
    key: 1,
    label: "Fees",
    subTypes: [
      {
        key: 1,
        label: "College Fees",
      },
      {
        key: 2,
        label: "Hostel Fees",
      },
    ],
  },
];

export const REQ_TYPES = {
  ADMIN: [MED_REQ_TYP, WEL_REQ_TYP, MON_REQ_TYP],
  MSSPC: [MED_REQ_TYP, WEL_REQ_TYP, MON_REQ_TYP],
  MSSCC: [WEL_REQ_TYP, MON_REQ_TYP],
  MWCO: [MED_REQ_TYP, WEL_REQ_TYP],
  ACCEX: [MED_REQ_TYP, WEL_REQ_TYP, MON_REQ_TYP],
  MGR: [MED_REQ_TYP, WEL_REQ_TYP],
};

import React from "react";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Spin,
} from "antd";
import { useState, useEffect } from "react";

const dateTimeFormat = "DD/MM/YYYY";

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
};

const StudentAcademicForm = (props) => {
  const [courses, setCourses] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.currentRec?.instituteId) {
      const courses = props.currentRec.institute.instituteCourses.map(
        (ic) => ic.course
      );
      setCourses(courses);
    }
  }, []);

  const populateCourses = (val) => {
    let courses = [];
    for (const cat of props.masterTable.institutes) {
      if (cat.instituteId === val) {
        for (const ic of cat.instituteCourses) {
          courses.push(ic.course);
        }
      }
    }
    setCourses(courses);
    form.setFieldsValue({
      courseId: "",
    });
  };

  return (
    <>
      <Modal
        visible={props.manage.showModal === "EDIT_ACAD"}
        footer={null}
        onCancel={() => props.onCancel()}
      >
        <div className="card-container">
          <Spin tip="Loading..." spinning={props.masterTable.loading}>
            <Card
              title={
                props.currentRec.studentAcadId ? "Edit Record" : "Add Record"
              }
            >
              <div>
                <Form
                  initialValues={props.currentRec}
                  onFinish={props.onFinish}
                  form={form}
                >
                  <Form.Item
                    {...formItemLayout}
                    name="studentAcadId"
                    hidden={true}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    name="studentProfileId"
                    hidden={true}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    name="instituteId"
                    label="Institution"
                    rules={[
                      {
                        required: true,
                        message: "Please select Institute",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toUpperCase()
                          .includes(input.toUpperCase())
                      }
                      onChange={(val) => populateCourses(val)}
                      className="full-width"
                    >
                      {props.masterTable.institutes.map((inst) => (
                        <Select.Option
                          key={inst.instituteId}
                          value={inst.instituteId}
                        >
                          {inst.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    name="courseId"
                    label="Course"
                    rules={[
                      {
                        required: true,
                        message: "Please select course",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toUpperCase()
                          .includes(input.toUpperCase())
                      }
                      className="full-width"
                    >
                      {courses.map((icourse) => (
                        <Select.Option
                          key={icourse.courseId}
                          value={icourse.courseId}
                        >
                          {icourse.courseName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    name="startYear"
                    label="Start Year"
                    rules={[
                      {
                        required: true,
                        message: "Please select Start Year",
                      },
                    ]}
                  >
                    <DatePicker
                      format={dateTimeFormat}
                      className="full-width"
                      showToday={false}
                    />
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      // prevValues.applicationEndDate !== currentValues.applicationEndDate ||
                      prevValues.startYear !== currentValues.startYear
                    }
                  >
                    {({ getFieldValue }) => {
                      const startYear = getFieldValue(["startYear"]);

                      return (
                        <Form.Item
                          {...formItemLayout}
                          name="endYear"
                          label="End Year"
                        >
                          <DatePicker
                            format={dateTimeFormat}
                            className="full-width"
                            showToday={false}
                            disabledDate={(current) => current < startYear}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button mg-l"
                    disabled={props.manage.loading}
                  >
                    Save
                  </Button>
                  <Button
                    className="login-form-button mg-l"
                    onClick={() => props.onCancel()}
                  >
                    Cancel
                  </Button>
                </Form>
              </div>
            </Card>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default StudentAcademicForm;

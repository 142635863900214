import React from "react";
import { Card, Carousel, Col, Row } from "antd";
import { QUOTES } from "../utils/consts";
import { Link } from "react-router-dom";
import {
  ACCEX,
  ADMIN,
  DSTIN,
  MGR,
  MSSCC,
  MSSPC,
  MWCO,
  SELCOMT,
} from "../../base/utils/roles";

class AdminDashboard extends React.Component {
  componentWillMount() {
    this.props.getCurrentUserDetails();
  }

  populateQuotes = () => {
    const html = [];
    for (const quote of QUOTES) {
      html.push(
        <div>
          <p className="quote-text">
            {/* <img className="quote-background" src={quoteBackground}></img> */}
            <span className="curly-quotes">{quote.quote}</span>
            <br></br>
            {quote.author && <span className="quote-by"> {quote.author} </span>}
          </p>
        </div>
      );
    }

    return html;
  };
  render() {
    return (
      <div>
        <div className="quote-mobile-display">
          <Card className="mg-l ">
            <Carousel
              autoplay
              dots={false}
              autoplaySpeed={6000}
              className="quote-background"
            >
              {this.populateQuotes()}
            </Carousel>
          </Card>
        </div>

        {/* <div className="admin-home-container">
          <Spin tip="Loading..." spinning={this.props.admin.loading}>
            Welcome... {this.props.admin.currentProfile.firstName}{" "}
            {this.props.admin.currentProfile.lastName}
          </Spin>
        </div> */}
        <div>
          <Card className="mg-l">
            <Row gutter={16}>
              {![DSTIN, SELCOMT].includes(this.props.role) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/add-school-requests">
                    <div className="shot-cuts"> Add School Request </div>
                  </Link>
                </Col>
              )}
              {![DSTIN, SELCOMT].includes(this.props.role) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/sampurnaSiksha/application-processing">
                    <div className="shot-cuts">
                      <span>Sampurna Siksha Application Processing</span>
                    </div>
                  </Link>
                </Col>
              )}
              {![DSTIN, SELCOMT].includes(this.props.role) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/examDocs">
                    <div className="shot-cuts">Exam Documents</div>
                  </Link>
                </Col>
              )}

              {[ADMIN].includes(this.props.role) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/config/sysConfig">
                    <div className="shot-cuts">Admin Configurations</div>
                  </Link>
                </Col>
              )}
              {[ADMIN, MGR].includes(this.props.role) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/examCenters">
                    <div className="shot-cuts">Exam Centers Management</div>
                  </Link>
                </Col>
              )}
              {[ADMIN].includes(this.props.role) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/admin/shortlist">
                    <div className="shot-cuts">Shortlist</div>
                  </Link>
                </Col>
              )}
              {![SELCOMT].includes(this.props.role) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/survey">
                    <div className="shot-cuts">Survey</div>
                  </Link>
                </Col>
              )}
              {[ADMIN, SELCOMT, MGR].includes(this.props.role) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/selection-committee">
                    <div className="shot-cuts">
                      <span>Selection Committee</span>
                    </div>
                  </Link>
                </Col>
              )}
              {[ADMIN, MGR].includes(this.props.role) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/enrollment">
                    <div className="shot-cuts">
                      <span>Enrollment</span>
                    </div>
                  </Link>
                </Col>
              )}
              {[ADMIN, MSSCC, MSSPC, MGR].includes(this.props.role) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/mastertable/categories">
                    <div className="shot-cuts">
                      <span>Master Tables</span>
                    </div>
                  </Link>
                </Col>
              )}
              {[MGR, ADMIN, MSSPC, MGR].includes(this.props.role) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/joining">
                    <div className="shot-cuts">
                      <span>Joining</span>
                    </div>
                  </Link>
                </Col>
              )}
              {[ADMIN, MSSCC, MSSPC, MWCO, MGR].includes(this.props.role) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/studentlist">
                    <div className="shot-cuts">
                      <span>Students Management</span>
                    </div>
                  </Link>
                </Col>
              )}
              {[MGR, ADMIN, MSSPC].includes(this.props.role) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/student-acct">
                    <div className="shot-cuts">
                      <span>Students Accounts List</span>
                    </div>
                  </Link>
                </Col>
              )}
              {[MGR, ADMIN, MSSPC, MWCO, ACCEX, MSSCC].includes(
                this.props.role
              ) && [
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/tasks">
                    <div className="shot-cuts">
                      <span>Tasks List</span>
                    </div>
                  </Link>
                </Col>]}
                {[MGR, ADMIN, MSSPC, MWCO, MSSCC].includes(
                this.props.role )&&[
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/bulk-request">
                    <div className="shot-cuts">
                      <span>Bulk Requests Creation</span>
                    </div>
                  </Link>
                </Col>,
              ]}
              {this.props.role === ADMIN && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/bulk-action">
                    <div className="shot-cuts">
                      <span> Requests Summary </span>
                    </div>
                  </Link>
                </Col>
              )}

              {[ADMIN].includes(
                this.props.role
              ) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/data-migration">
                    <div className="shot-cuts">
                      <span> Data Migration </span>
                    </div>
                  </Link>
                </Col>
              )}
              {[MGR, ADMIN, MSSPC, MWCO, ACCEX, MSSCC].includes(
                this.props.role
              ) && (
                <Col xs={12} sm={6} lg={4} className="short-cut-tile">
                  <Link to="/reports/students">
                    <div className="shot-cuts">
                      <span> Reports </span>
                    </div>
                  </Link>
                </Col>
              )}
            </Row>
          </Card>
        </div>
      </div>
    );
  }
}

export default AdminDashboard;

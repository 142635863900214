import React from "react";
import {
  Spin,
  notification,
  Divider,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Radio,
  Drawer,
  List,
  Card,
  Tooltip,
  Upload,
  Button,
} from "antd";
import { translate } from "../../base/actions/translate";
import moment from "moment";
import { IMAGE_TYPES, TYPE_PDF } from "../../student/util/const";
import Comments from "./Comments";
import SchoolStatusSummary from "./SchoolStatusSummary";
import History from "./History";
import {
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import IconButton from "@material-ui/core/IconButton";
import { CloseOutlined } from "@ant-design/icons";
import { ADMIN } from "../../base/utils/roles";
import { APPRV, DOWN } from "../utils/consts";
import PDF_ICON from "../../assets/images/pdfIcon.png";
import DocViewer, { PDFRenderer } from "react-doc-viewer";

const dateFormat = "DD/MM/YYYY";

const { TextArea } = Input;
const { Option } = Select;
// const [top, setTop] = useState(10);

// const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 16 },
// };

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const notesFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

class ApplicationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      disableFields: true,
      showComments: false,
      showHistory: false,
      shhowFile: false,
      fileUrl: "",
      fileType: "",
      fileCategory: "",
      uploadCat: "",
      uploadFile: "",
      uploadErrors: "",
      uploadCatList: [],
      rotateStyle: 0,
      zoom: 1,
      file: {},
      activeDrags: 0,
      formUpdated: false,
    };
    this.uploadRef = React.createRef();
  }

  componentDidMount() {
    this.props.getCastes();
    this.props.getDistricts();
    this.props.getExamCenters();
    if (this.props.student.applicationForm.school?.parent?.parent?.id) {
      this.props.getMandalsList(
        this.props.student.applicationForm.school?.parent?.parent?.id
      );
    }
    if (this.props.student.applicationForm.school?.parent?.id) {
      this.props.getSchoolsList(
        this.props.student.applicationForm.school?.parent?.id
      );
    }
  }

  populateMandals = (value) => {
    this.props.getMandalsList(value);
    this.props.formRef.current.setFieldsValue({
      mandalId: "",
      schoolId: "",
    });
  };

  populateSchools = (value) => {
    this.props.getSchoolsList(value);
    this.props.formRef.current.setFieldsValue({
      schoolId: "",
    });
  };

  // checkSchool = (value) => {
  //   console.log("----------------------school: ", value);
  // };

  disabledDate = (current) => {
    // Can not select days before today and today
    return (
      current.year() < moment().subtract(18, "years").year() ||
      current.year() > moment().subtract(13, "years").year()
    );
  };

  getDefaultPickerValue = () => {
    if (this.props.student.applicationForm.dateOfBirth) {
      return moment(this.props.student.applicationForm.dateOfBirth);
    } else {
      return moment().subtract(18, "years");
    }
  };

  toCapital = (e) => {
    if (e.target.value) {
      return e.target.value.toUpperCase();
    }
    return e.target.value;
  };

  submitBasicForm = (form) => {
    // console.log("Form submission --------------", form);
    const uploadCatList = this.state.uploadCatList
      .filter((cat) => cat.status === "U")
      .map((cat) => cat.type);
    if (this.state.formUpdated || uploadCatList.length > 0) {
      this.props.saveApplication(
        form,
        this.props.student.applicationForm,
        this.props.student.applicationForm.username,
        uploadCatList
      );
    }
    // if(this.state.uploadCatList.length > 0) {
    //     this.props.saveDraftFiles(this.props.student.applicationForm.applicationId,this.state.uploadCatList);
    // }
    this.setState({ uploadCatList: [], uploadCat: "", formUpdated: false });
    // this.next();
  };

  submitUploadForm = (values) => {
    const msg = [];
    // console.log("Upload form values -------------", values);
    const { studentImage, studentIdProof, ninthMemoBack, ninthMemoFront } =
      this.props.student;
    if (
      !studentImage.url ||
      !studentIdProof.url ||
      !ninthMemoBack.url ||
      !ninthMemoFront.url
    ) {
      if (!studentImage.url) {
        msg.push("Please upload all required files");
      } else if (!studentIdProof.url) {
        msg.push("Please upload all required files");
      } else if (!ninthMemoFront.url) {
        msg.push("Please upload all required files");
      } else if (!ninthMemoBack.url) {
        msg.push("Please upload all required files");
      }
      notification["error"]({
        message: "Error Notification",
        description: msg,
      });
    }
  };

  submitApplication = () => {
    this.props.submitApplication(this.props.student.applicationForm);
    this.next();
  };

  enableFields = () => {
    this.setState({ disableFields: false });
  };

  showHistory = () => {
    this.setState({ showHistory: true });
    this.props.toggleHistory(true);
  };

  showReasons = () => {
    this.setState({ showComments: true });
    this.props.toggleComments(true);
    this.props.getComments(
      this.props.student.applicationForm.applicationId,
      "APPL"
    );
  };

  closeHistory = () => {
    this.setState({ showHistory: false });
    this.props.toggleHistory(false);
  };

  closeComments = () => {
    this.setState({ showComments: false });
    this.props.toggleComments(false);
  };

  showFile = (file, fileCategory) => {
    this.setState({
      file: file,
      rotateStyle: file.angle,
      zoom: 1,
      showFile: true,
      fileCategory: fileCategory,
    });
  };

  hideFile = () => {
    this.setState({
      fileType: "",
      fileUrl: "",
      showFile: false,
      fileCategory: "",
    });
  };

  handleUpload = (value) => {
    // console.log("---------", value);
    if (value) {
      const uploadCatList = this.state.uploadCatList;
      uploadCatList.push({ type: value, status: "P" });
      this.setState({ uploadCat: value, uploadCatList });
      document.getElementById("myFileB").click();
    }
  };

  isValidDoc(file) {
    const isJpgOrPngOrPdf =
      IMAGE_TYPES.includes(file.type) || file.type === "application/pdf";
    const isLt500KB = file.size / 1024 < 500;
    return isJpgOrPngOrPdf && isLt500KB;
  }

  isValidImg(file) {
    const isJpgOrPng = IMAGE_TYPES.includes(file.type);
    const isLt500KB = file.size / 1024 < 500;
    return isJpgOrPng && isLt500KB;
  }

  validateFile(file) {
    const type = this.state.uploadCat;
    let uploadErrors = "";
    if (type !== "STUD_IMG" && !this.isValidDoc(file)) {
      uploadErrors = translate("photoInstructions");
    }
    if (type === "STUD_IMG" && !this.isValidImg(file)) {
      uploadErrors = translate("passportPhotoInst");
    }

    this.setState({ uploadErrors });
    return uploadErrors.length === 0;
  }

  handleFileEvent = (e) => {
    // console.log("---------", e);
    const applicationId = this.props.student.applicationForm.applicationId;
    if (this.validateFile(e.file)) {
      this.props.draftUpload(applicationId, e.file, this.state.uploadCat);
      const uploadCat = this.state.uploadCat;
      this.state.uploadCatList.forEach((cat) => {
        if (cat.type === uploadCat) {
          cat.status = "U";
        }
      });
      this.setState({ uploadCat: "" });
    } else {
      notification.error({
        message: "Error",
        description: this.state.uploadErrors,
      });
    }
  };

  getUploadData = () => {
    const { studentImage, studentIdProof, ninthMemoBack, ninthMemoFront } =
      this.props.student;
    return [
      { name: "studentImage", value: studentImage },
      { name: "studentIdProof", value: studentIdProof },
      { name: "ninthMemoFront", value: ninthMemoFront },
      { name: "ninthMemoBack", value: ninthMemoBack },
    ];
  };

  rotateLeft = (file) => {
    let angle = this.state.rotateStyle;
    if (!angle || angle === 0) {
      angle = 360;
    }
    angle = angle - 90;
    this.setState({ rotateStyle: angle });
    this.props.updateImageAngle(angle, file.fileId);
  };

  rotateRight = (file) => {
    let angle = this.state.rotateStyle;
    if (!angle || angle === 360) {
      angle = 0;
    }
    angle = angle + 90;
    this.setState({ rotateStyle: angle });
    this.props.updateImageAngle(angle, file.fileId);
  };

  zoomIn = () => {
    const zoom = this.state.zoom;
    this.setState({ zoom: zoom + 0.5 });
  };

  zoomOut = () => {
    const zoom = this.state.zoom;
    if (zoom > 0.5) {
      this.setState({ zoom: zoom - 0.5 });
    }
  };

  PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  onValueChange = () => {
    this.setState({ formUpdated: true });
  };

  isAfterExamDate = () => {
    let examDate = "";
    const sysConfig = this.props.appConfig.systemConfig;
    for (const config of sysConfig) {
      if (config.configCd === "EXAM_DT") {
        examDate = moment(config.configVal);
      }
    }

    return examDate && moment().isSameOrAfter(examDate);
  };

  disableField = () => {
    if (
      this.props.role !== ADMIN &&
      (this.props.student.applicationForm.status === APPRV ||
        this.props.student.applicationForm.status === DOWN ||
        this.isAfterExamDate())
    ) {
      return true;
    }

    return false;
  };

  render() {
    const applicationId = this.props.student.applicationForm.applicationId;
    const username = this.props.student.applicationForm.username;
    const appHistory = this.props.sampurnaSiksha.appHistory;
    let fieldData = [];

    let loading = true;
    const { districtsList, mandalsList, schoolsList, castesList, examCenters } =
      this.props;
    if (
      districtsList.length > 0 &&
      mandalsList.length > 0 &&
      schoolsList.length > 0 &&
      castesList.length > 0 &&
      examCenters.length > 0
    ) {
      loading = false;
    }

    if (!loading) {
      fieldData = this.props.student.fieldData.concat(
        this.props.student.uploadFieldData
      );
    }

    return (
      <div id="detailsContainer">
        <SchoolStatusSummary {...this.props} />

        {applicationId && !loading && (
          <Form
            name="student-application"
            className="app-staff-form"
            // onFieldsChange={this.props.handleApplicationFormFiels}
            // onValuesChange={this.props.handleApplicationFormFiels}
            onFinish={this.submitBasicForm}
            onFinishFailed={this.openNotificationWithIcon}
            fields={fieldData}
            ref={this.props.formRef}
            onValuesChange={this.onValueChange}
          >
            <Spin
              spinning={
                loading ||
                this.props.student.loading ||
                this.props.sampurnaSiksha.loading ||
                this.props.appConfig.loading
              }
              delay={200}
            >
              <Row>
                {/* <Col xs={0} sm={2}>
                    </Col> */}
                <Col xs={20} sm={18}>
                  <Row justify="space-between">
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="studentName"
                        label={translate("studentName")}
                        getValueFromEvent={this.toCapital}
                        rules={[
                          {
                            required: true,
                            min: 3,
                            pattern: /^\S[A-Za-z\s]+$/,
                            message: translate("studentNameError"),
                          },
                        ]}
                      >
                        <Input disabled={this.disableField()} maxLength="25" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="studentSurName"
                        label={translate("studentSurName")}
                        getValueFromEvent={this.toCapital}
                        rules={[
                          {
                            required: true,
                            min: 3,
                            pattern: /^\S[A-Za-z\s]+$/,
                            message: translate("studentSurNameError"),
                          },
                        ]}
                      >
                        <Input disabled={this.disableField()} maxLength="25" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="fatherName"
                        label={translate("fatherName")}
                        getValueFromEvent={this.toCapital}
                        rules={[
                          {
                            required: true,
                            min: 3,
                            pattern: /^\S[A-Za-z\s]+$/,
                            message: translate("fatherNameError"),
                          },
                        ]}
                      >
                        <Input disabled={this.disableField()} maxLength="25" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="motherName"
                        label={translate("mothersName")}
                        getValueFromEvent={this.toCapital}
                        rules={[
                          {
                            required: true,
                            min: 3,
                            pattern: /^\S[A-Za-z\s]+$/,
                            message: translate("mothersNameError"),
                          },
                        ]}
                      >
                        <Input disabled={this.disableField()} maxLength="25" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="dateOfBirth"
                        label={translate("dateOfBirth")}
                        rules={[
                          { required: true, message: translate("dobError") },
                        ]}
                      >
                        <DatePicker
                          format={dateFormat}
                          className="full-width"
                          showToday={false}
                          disabledDate={this.disabledDate}
                          defaultPickerValue={this.getDefaultPickerValue()}
                          disabled={this.disableField()}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="alternatePhone"
                        label={translate("alternatePhone")}
                        rules={[
                          {
                            required: true,
                            message: translate("alternativePhoneNumberError"),
                          },
                          {
                            pattern: /^\d{10}$/,
                            message: translate("alternativePhoneNumberError"),
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (!value || username !== value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                translate("altNumStudentNum")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input
                          className="full-width"
                          disabled={this.disableField()}
                          maxLength="10"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="casteId"
                        label={translate("casteId")}
                        rules={[
                          {
                            required: true,
                            message: translate("castCategoryError"),
                          },
                        ]}
                      >
                        <Select
                          className="full-width"
                          disabled={this.disableField()}
                        >
                          {this.props.castesList.map((caste) => (
                            <Option key={caste.id} value={caste.id}>
                              {caste.configVal}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="address"
                        label={translate("address")}
                        getValueFromEvent={this.toCapital}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (
                                !value ||
                                value.length < 10 ||
                                value.indexOf(" ") === 0
                              ) {
                                return Promise.reject(
                                  translate("studentHomeAddressError")
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <TextArea
                          className="full-width"
                          disabled={this.disableField()}
                          maxLength="50"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="gender"
                        label={translate("gender")}
                        rules={[
                          { required: true, message: translate("genderError") },
                        ]}
                      >
                        <Radio.Group
                          onChange={this.onGenderChange}
                          disabled={this.disableField()}
                        >
                          <Radio value="Male">Male</Radio>
                          <Radio value="Female">Female</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="ninthGrade"
                        label={translate("ninthGrade")}
                        rules={[
                          {
                            required: true,
                            message: translate("ninthGradeError"),
                          },
                        ]}
                      >
                        <Select
                          className="full-width"
                          disabled={this.disableField()}
                        >
                          <Option key="A1">A1</Option>
                          <Option key="A2">A2</Option>
                          <Option key="B1">B1</Option>
                          <Option key="B2">B2</Option>
                          <Option key="C1">C1</Option>
                          <Option key="C2">C2</Option>
                          <Option key="D1">D1</Option>
                          <Option key="D2">D2</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="medium"
                        label={translate("medium")}
                        rules={[
                          {
                            required: true,
                            message: translate("mediumError"),
                          },
                        ]}
                      >
                        <Select
                          className="full-width"
                          disabled={this.disableField()}
                        >
                          <Option key="EN" value="EN">
                            English
                          </Option>
                          <Option key="TE" value="TE">
                            Telugu
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="headmasterName"
                        label={translate("headmasterName")}
                        getValueFromEvent={this.toCapital}
                        rules={[
                          {
                            required: true,
                            min: 3,
                            pattern: /^\S[A-Za-z\s]+$/,
                            message: translate("headmasterNameError"),
                          },
                        ]}
                      >
                        <Input disabled={this.disableField()} maxLength="25" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="headmasterPhone"
                        label={translate("headmasterPhone")}
                        rules={[
                          {
                            required: true,
                            message: translate("headmasterPhoneNoError"),
                          },
                          {
                            pattern: /^\d{10}$/,
                            message: translate("headmasterPhoneNoError"),
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (!value || username !== value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                translate("altNumHeadMNum")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input
                          className="full-width"
                          disabled={this.disableField()}
                          maxLength="10"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="districtId"
                        label={translate("district")}
                        rules={[
                          {
                            required: true,
                            message: translate("schoolDistrictError"),
                          },
                        ]}
                      >
                        <Select
                          className="full-width"
                          onChange={this.populateMandals}
                          disabled={this.disableField()}
                        >
                          {this.props.districtsList.map((district) => (
                            <Option key={district.id} value={district.id}>
                              {district.configVal}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="mandalId"
                        label={translate("mandal")}
                        rules={[
                          {
                            required: true,
                            message: translate("schoolMandalError"),
                          },
                        ]}
                      >
                        <Select
                          className="full-width"
                          onChange={this.populateSchools}
                          disabled={this.disableField()}
                        >
                          {this.props.mandalsList.map((mandal) => (
                            <Option key={mandal.id} value={mandal.id}>
                              {mandal.configVal}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="schoolId"
                        label={translate("school")}
                        rules={[
                          {
                            required: true,
                            message: translate("schoolNameError"),
                          },
                        ]}
                      >
                        <Select
                          onChange={this.checkSchool}
                          disabled={this.disableField()}
                        >
                          {this.props.schoolsList.map((config) => (
                            <Option key={config.id} value={config.id}>
                              {config.configVal}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        {...formItemLayout}
                        name="examCenterId"
                        label={translate("examCenter")}
                        rules={[
                          {
                            required: true,
                            message: translate("examCenterError"),
                          },
                        ]}
                      >
                        <Select
                          className="exam_center_select"
                          disabled={this.disableField()}
                        >
                          {/* <Option key={""} value={""}>Please Select</Option> */}
                          {this.props.examCenters.map((center) => (
                            <Option key={center.id} value={center.id}>
                              {center.configVal}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                      <hr></hr>
                      <Form.Item
                        {...notesFormItemLayout}
                        name="notes"
                        label={translate("notes")}
                      >
                        <Input.TextArea showCount />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={4} sm={6} style={{ paddingLeft: "20px" }}>
                  <div>
                    <List
                      className="image-card"
                      grid={{
                        // gutter: 16,
                        // xs: 24,
                        sm: 2,
                        // md: 12,
                        // lg: 6,
                        // xl: 6,
                        // xxl: 3,
                      }}
                      dataSource={this.getUploadData()}
                      renderItem={(item) => (
                        <List.Item
                          onClick={(e) => this.showFile(item.value, item.name)}
                        >
                          <Tooltip title={translate(item.name)}>
                            <Card
                              className="expanded-view"
                              title={translate(item.name)}
                            >
                              {item.value.type !== TYPE_PDF && (
                                <img
                                  alt="PDF"
                                  src={item.value.url}
                                  style={{
                                    transform:
                                      "rotate(" +
                                      (item.value.angle
                                        ? item.value.angle
                                        : 0) +
                                      "deg)",
                                  }}
                                ></img>
                              )}
                              {item.value.type === TYPE_PDF && (
                                <img
                                  className="pdf-img"
                                  src={PDF_ICON}
                                  alt="avatar"
                                />
                              )}
                            </Card>
                          </Tooltip>
                        </List.Item>
                      )}
                    ></List>
                  </div>
                  {!this.disableField() && (
                    <div style={{ marginTop: "20px" }}>
                      <label style={{ fontWeight: "bold" }}>
                        Replace File :{" "}
                      </label>
                      <Select
                        value={this.state.uploadCat}
                        style={{ width: 200 }}
                        onSelect={(v) => this.handleUpload(v)}
                      >
                        <Option value="">Please select...</Option>
                        {this.props.role === ADMIN && (
                          <Option value="STUD_IMG">Passport Photo</Option>
                        )}
                        <Option value="STUD_ID">Student ID Form</Option>
                        <Option value="MEMO_NINTH_FRONT">
                          9th Memo - Front
                        </Option>
                        <Option value="MEMO_NINTH_BACK">9th Memo - Back</Option>
                      </Select>

                      <Upload
                        customRequest={(e) => this.handleFileEvent(e)}
                        style={{ display: "none" }}
                        showUploadList={false}
                      >
                        <Button id="myFileB" style={{ display: "none" }}>
                          click
                        </Button>
                      </Upload>
                    </div>
                  )}
                </Col>
              </Row>
            </Spin>
          </Form>
        )}

        {appHistory.length > 0 && (
          <Row>
            <Divider />
            <History history={appHistory} />
          </Row>
        )}

        <Drawer
          title="Reasons"
          width={320}
          closable={false}
          onClose={this.closeComments}
          visible={this.props.sampurnaSiksha.showComments}
        >
          <Comments {...this.props}></Comments>
        </Drawer>

        <Drawer
          title="History"
          width={320}
          closable={false}
          onClose={this.closeHistory}
          visible={this.props.sampurnaSiksha.showHistory}
        >
          History is under development...
        </Drawer>

        <Dialog
          open={this.state.showFile}
          onClose={this.hideFile}
          PaperComponent={this.PaperComponent}
          aria-labelledby="draggable-dialog-title"
          hideBackdrop={true}
          disableBackdropClick={true}
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {translate(this.state.fileCategory)}
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.hideFile}
              aria-label="close"
              style={{ float: "right", clear: "right" }}
            >
              <CloseOutlined />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className="image-dialog-card">
              <Spin spinning={this.props.sampurnaSiksha.loading} delay={500}>
                {this.state.file.type !== TYPE_PDF && (
                  <img
                    alt={this.state.file.filename}
                    src={this.state.file.url}
                    style={{
                      transform: "rotate(" + this.state.rotateStyle + "deg)",
                      zoom: this.state.zoom,
                      "-moz-transform": `scale(${this.state.zoom})`,
                    }}
                  ></img>
                )}
                {this.state.file.type === TYPE_PDF && (
                  <div>
                    <DocViewer
                      pluginRenderers={[PDFRenderer]}
                      documents={[{ uri: this.state.file.url }]}
                      config={{
                        header: {
                          disableHeader: true,
                          disableFileName: true,
                          retainURLParams: false,
                        },
                      }}
                    />
                  </div>
                )}
                {this.state.file.type !== TYPE_PDF && (
                  <Row>
                    <Col style={{ margin: "auto" }}></Col>
                  </Row>
                )}
              </Spin>
            </div>
          </DialogContent>
          {this.state.file.type !== TYPE_PDF && (
            <DialogActions>
              <Button
                type="primary"
                shape="round"
                icon={<ZoomInOutlined />}
                size="small"
                onClick={(e) => this.zoomIn()}
              />
              <Button
                type="primary"
                shape="round"
                icon={<ZoomOutOutlined />}
                size="small"
                onClick={(e) => this.zoomOut()}
              />
              <Button
                type="primary"
                shape="round"
                icon={<RotateLeftOutlined />}
                size="small"
                onClick={(e) => this.rotateLeft(this.state.file)}
              />
              <Button
                type="primary"
                shape="round"
                icon={<RotateRightOutlined />}
                size="small"
                onClick={(e) => this.rotateRight(this.state.file)}
              />
            </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}

export default ApplicationDetails;

import React from "react";
import "../styles/base.css";
import "../styles/ScrollBanner.css";
import { Row, Col, Carousel, Button } from "antd";
import { translate } from "../../base/actions/translate";
import { Link, withRouter } from "react-router-dom";
import {
  getBoytBlack,
  STUDENT_IMG_MSS,
  STUDENT_IMG_MNN,
  STUDENT_IMG_MHH,
} from "../utils/images";
import { connect } from "react-redux";
import mssBanner from "../../assets/images/MSSbanner.jpg";
import mnnBanner from "../../assets/images/MNNbanner.jpg";
import mhhBanner from "../../assets/images/MHHbanner.jpg";
import slider1 from "../../assets/images/MCT-Web-Slider_0_1020px.jpg";
import slider2 from "../../assets/images/MCT-Web-Slider_1_1020px.jpg";
import slider3 from "../../assets/images/MCT-Web-Slider_2_1020px.jpg";
import slider4 from "../../assets/images/MCT-Web-Slider_3_1020px.jpg";
import slider5 from "../../assets/images/MCT-Web-Slider_4_1020px.jpg";
import mobileSlider1 from "../../assets/images/carousel-mobile/MCT-Slider-mobile-1.jpg";
import mobileSlider2 from "../../assets/images/carousel-mobile/MCT-Slider-mobile-2.jpg";
import mobileSlider3 from "../../assets/images/carousel-mobile/MCT-Slider-mobile-3.jpg";
import mobileSlider4 from "../../assets/images/carousel-mobile/MCT-Slider-mobile-4.jpg";
import mobileSlider5 from "../../assets/images/carousel-mobile/MCT-Slider-mobile-5.jpg";

class Welcome extends React.Component {
  state = {
    isDesktop: true,
  };
  constructor() {
    super();
    this.updatePredicate = this.updatePredicate.bind(this);
  }
  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }
  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 700 });
  }
  getScrollingMsg = () => {
    let scroll = "";
    let scrollStatus = "N";
    const sysConfig = this.props.appConfig.systemConfig;
    for (const sc of sysConfig) {
      if (sc.configCd === "SCROLL") {
        scroll = sc.info;
      }
      if (sc.configCd === "SCROLL_ST") {
        scrollStatus = sc.configVal;
      }
    }

    if (scrollStatus === "Y") {
      return scroll;
    }
    return "";
  };
  render() {
    const scroll = this.getScrollingMsg();
    return (
      <div className="welcome-container">
        <Row justify="space-around">
          <Col className="inline-block" xs={24} sm={24}>
            {this.state.isDesktop && (
              <Carousel
                autoplay
                dots={false}
                pauseOnHover={false}
                autoplaySpeed={4500}
              >
                <div>
                  <div className="car-container">
                    <img height="690" width="100%" src={slider1}></img>
                    <div className="centered">
                      <p>
                        Empowering <br></br> India Through <br></br>Education
                      </p>
                      <Link to="/about">
                        <Button type="primary" className="home-carousal-button">
                          Know More
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="car-container">
                    <img height="690" width="100%" src={slider2}></img>
                    <div className="centered">
                      <p>
                        Sponsoring <br></br>Tomorrow's<br></br> Doctors
                      </p>
                      <Link to="/mss">
                        <Button type="primary" className="home-carousal-button">
                          Know More
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="car-container">
                    <img height="690" width="100%" src={slider4}></img>
                    <div className="centered">
                      <p>
                        Transforming <br></br> lifes through <br></br>career
                        advancement<br></br>opportunities
                      </p>
                      <Link to="/mnn">
                        <Button type="primary" className="home-carousal-button">
                          Know More
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="car-container">
                    <img height="690" width="100%" src={slider5}></img>
                    <div className="centered">
                      <p>
                        {" "}
                        Everyone Deserves <br></br>Quality Education
                      </p>
                      <Link to="/mhh">
                        <Button
                          type="primary"
                          className="home-carousal-button "
                        >
                          Know More
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="car-container">
                    <img height="690" width="100%" src={slider3}></img>
                    <div className="centered">
                      <p>
                        Functional Toilets <br></br> in Government <br></br>{" "}
                        Colleges
                      </p>
                      <Button
                        type="primary"
                        className="home-carousal-button navikarana"
                      >
                        Introducing a new initiative..<br></br> Medha Vidyalaya
                        Navikarana
                      </Button>
                    </div>
                  </div>
                </div>
              </Carousel>
            )}
            {!this.state.isDesktop && (
              <Carousel
                autoplay
                dots={false}
                pauseOnHover={false}
                autoplaySpeed={4500}
              >
                <div>
                  <div className="car-container">
                    <img height="600" width="100%" src={mobileSlider1}></img>
                    <div className="centered">
                      <p>
                        Empowering <br></br> India Through <br></br>Education
                      </p>
                      <Link to="/about">
                        <Button type="primary" className="home-carousal-button">
                          Know More
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                 <div>
                  <div className="car-container">
                    <img height="600" width="100%" src={mobileSlider5}></img>
                    <div className="centered">
                      <p>
                        Sponsoring <br></br>Tomorrow's<br></br> Doctors
                      </p>
                      <Link to="/mss">
                        <Button type="primary" className="home-carousal-button">
                          Know More
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="car-container">
                    <img height="600" width="100%" src={mobileSlider3}></img>
                    <div className="centered centered-slide3">
                      <p>
                        Transforming lifes through <br></br>career 
                        advancement<br></br>opportunities
                      </p>
                      <Link to="/mnn">
                        <Button type="primary" className="home-carousal-button">
                          Know More
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="car-container">
                    <img height="600" width="100%" src={mobileSlider4}></img>
                    <div className="centered centered-slide4">
                      <p>
                        {" "}
                        Everyone Deserves <br></br>Quality Education
                      </p>
                      <Link to="/mhh">
                        <Button
                          type="primary"
                          className="home-carousal-button "
                        >
                          Know More
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
               <div>
                  <div className="car-container">
                    <img height="600" width="100%" src={mobileSlider2}></img>
                    <div className="centered">
                      <p>
                        Functional Toilets <br></br> in Government <br></br>{" "}
                        Colleges
                      </p>
                      <Button
                        type="primary"
                        className="home-carousal-button navikarana"
                      >
                        Introducing a new initiative..<br></br> Medha Vidyalaya
                        Navikarana
                      </Button>
                    </div>
                  </div> 
                </div>
              </Carousel>
            )}
          </Col>
          {/* <div>
            <p className="temp-exam-center-list">
              Please <Link to="/exam2020">click here</Link> for Medha Sampurna Siksha Scholarship Exam 2020 details.
            </p>
          </div> */}
          <div className="vision-Heading">
            {/* {translate('vision')} */}
            We make a difference in students’ lives by giving them hope of a
            better future, with support that goes beyond the material
            requirements of education and guidance that exceeds the expectation
            of every student under our umbrella.{" "}
          </div>
          {scroll.length > 0 && (
            <div className="ticker-wrap ">
              <div className="ticker">
                <div className="ticker-item">{scroll}</div>
              </div>
            </div>
          )}
          <div className="vision-Heading">
            OUR PROGRAMMES BUILD SELF-SUSTAINABLE LIVES
          </div>

          <Col className="inline-block home-blocks" xs={24} sm={18}>
            <div className="vision-Heading Program-heading">
              Medha Sampurna Siksha
            </div>
            <div className="divider divider-heading">
              <span className="divider-line-title"></span>
              <span className="divider-arrow-title divider-arrow"></span>
              <span className="divider-line-title"></span>
            </div>
            <div className="circle-2 circle"></div>
            {STUDENT_IMG_MSS}
            <div className="mission-content">
              <div className="is-shown">
                <span className="invert-top invert-top-mss">
                  Encourage and support <br></br>
                  talented children to<br></br>
                </span>
                <div className="botton-heading botton-heading-2">
                  <span className="invert-bottom">
                    Educate <br></br>
                    <span className="invert-bottom-second">
                      {" "}
                      Evolve &amp; Prosper
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <img className="home-disc" src={mssBanner}></img>
            <div className="mission-content-description">
              <span className="intro-heading">WE BELIEVE</span>
              <div className="divider divider--mission">
                <span className="divider-line-left divider-line-left-2"></span>
                <span className="divider-arrow divider-arrow-2"></span>
                <span className="divider-line-right divider-line-right-2"></span>
              </div>
              <div className="body-region body-region-2">
                IF WE HELP ONE PERSON GET EDUCATED, THEIR FAMILY AND THE FUTURE
                GENERATIONS OF THEIR FAMILIES WILL HAVE THE ABILITY AND DESIRE
                TO BECOME EDUCATED
              </div>
              <span className="content-disc">
                Every year hundreds of students drop out of schools and
                colleges, due to lack of proper support system – financial and
                guidance. We know that merit students can succeed in any area
                that they have passion for. Over the years we’ve given hope of a
                better future to merit students among the most needy. With great
                opportunities to excel and a dedicated team to hold their hand,
                they have built their lives with their own efforts and are
                leading a prosperous life.
              </span>
              <div className="close-look-mobile">
                <Link to="/mss">
                  <div className="top-disc top-disc-2">Know more . . .</div>
                </Link>
                <div className="know-more-intro">
                  {" "}
                  Changed over 700 lives through education scholarships
                </div>
                <Link to="/mss">
                  {/* <div className="know-more-1"><a>Know more about Medha Sampurna Siksha</a></div> */}
                </Link>
              </div>
            </div>
            <div className="close-look">
              <Link to="/mss">
                <div className="top-disc top-disc-2">Know more . . .</div>
              </Link>
              <div className="know-more-intro">
                {" "}
                Changed over 700 lives through education scholarships
              </div>
              <Link to="/mss">
                {/* <div className="know-more-1"><a>Know more about Medha Sampurna Siksha</a></div> */}
              </Link>
            </div>
          </Col>
          {/* ------------third------------ */}
          <Col className="inline-block home-blocks prog-2-mob" xs={24} sm={18}>
            <div className="vision-Heading Program-heading">
              Medha Naipunya Nirmana
            </div>
            <div className="divider divider-heading">
              <span className="divider-line-title"></span>
              <span className="divider-arrow-title divider-arrow"></span>
              <span className="divider-line-title"></span>
            </div>
            <div className="circle-3 circle"></div>
            {STUDENT_IMG_MNN}
            <div className="mission-content">
              <div className="is-shown">
                <span className="invert-top invert-top-mnn">
                  Create opportunity for<br></br>
                  youth to <br></br>
                </span>
                <div className="botton-heading botton-heading-3">
                  <span className="invert-bottom">
                    Upskill<br></br>
                    <span className="invert-bottom-second">
                      {" "}
                      Employ &amp; Sustain{" "}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <img className="home-disc" src={mnnBanner}></img>
            <div className="mission-content-description">
              <span className="intro-heading">WE BELIEVE</span>
              <div className="divider divider--mission">
                <span className="divider-line-left divider-line-left-3"></span>
                <span className="divider-arrow divider-arrow-3 "></span>
                <span className="divider-line-right divider-line-right-3"></span>
              </div>
              <div className=" body-region body-region-3">
                NO YOUNG TALENT SHOULD GO WASTE DUE TO LACK OF RESOURCES
              </div>
              <span className="content-disc">
                Every year many youngsters struggle to pull themselves out of
                poverty due to lack of technical expertise and advancement
                opportunities. We know that creating the right space and scope
                for them to enhance their technical knowledge can transform
                their lives. Over the years we’ve collaborated with the best
                technical institutions that provide complete placement support.
                With an opportunity to advance their skills and manufacturing
                industry’s readiness to employ, these candidates took the first
                steps in skill development and built sustainable lives.
              </span>
              <div className="close-look-mobile">
                <Link to="/mnn">
                  <div className="top-disc-3 top-disc">Know more . . .</div>
                </Link>
                <div className="know-more-intro">
                  Changed over 400 lives through skill training scholarships and
                  job opportunities
                </div>
                <Link to="/mnn">
                  {/* <div className="know-more-1"><a>Know more about Medha Naypunya Nirmana</a></div> */}
                </Link>
              </div>
            </div>
            <div className="close-look">
              <Link to="/mnn">
                <div className="top-disc-3 top-disc">Know more . . .</div>
              </Link>
              <div className="know-more-intro">
                Changed over 400 lives through skill training scholarships and
                job opportunities
              </div>
              <Link to="/mnn">
                {/* <div className="know-more-1"><a>Know more about Medha Naypunya Nirmana</a></div> */}
              </Link>
            </div>
          </Col>
          {/* ---------------fourth-------------------- */}
          <Col className="inline-block home-blocks prog-3-mob" xs={24} sm={18}>
            <div className="vision-Heading Program-heading">
              Medha Helping Hands
            </div>
            <div className="divider divider-heading">
              <span className="divider-line-title"></span>
              <span className="divider-arrow-title divider-arrow"></span>
              <span className="divider-line-title"></span>
            </div>
            <div className="circle-4 circle"></div>
            {STUDENT_IMG_MHH}
            <div className="mission-content">
              <div className="is-shown">
                <div className="invert-top invert-top-mhh">
                  <span className="invert-top-3">
                    {" "}
                    Provide resources to students <br></br>
                    from government colleges to<br></br>
                  </span>
                </div>
                <div className="botton-heading botton-heading-4">
                  <span className="invert-bottom invert-bottom-3">
                    Outperform <br></br>
                    <span className="invert-bottom-second">
                      {" "}
                      Progress &amp; Succeed{" "}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <img className="home-disc" src={mhhBanner}></img>
            <div className="mission-content-description">
              <span className="intro-heading">WE BELIEVE</span>
              <div className="divider divider--mission">
                <span className="divider-line-left divider-line-left-4"></span>
                <span className="divider-arrow divider-arrow-4"></span>
                <span className="divider-line-right divider-line-right-4"></span>
              </div>
              <div className="body-region body-region-4">
                WE HAVE TO WORK AT THE GRASS ROOT LEVEL TO INCREASE THE LITERACY
                LEVEL IN INDIA
              </div>
              <span className="content-disc">
                Every year thousands of students studying in government colleges
                fail to perform well academically due to lack of availability of
                educational resources at their arm’s length. We know that
                providing the required resources to these students could lead
                them to gain quality education and grow confidence to achieve
                their goals. We’ve collaborated with government college
                lecturers to create study material. Over the years we provided
                text books and study material to students, which helped improve
                their performance drastically and were able to complete their
                education successfully.
              </span>
              <div className="close-look-mobile">
                <Link to="/mhh">
                  <div className="top-disc top-disc-4">Know more . . .</div>
                </Link>
                <div className="know-more-intro">
                  Changed over 22 lakh lives by donating books
                </div>
                <Link to="/mhh">
                  {/* <div className="know-more-1"><a>Know more about Medha Helping Hands</a></div> */}
                </Link>
              </div>
            </div>
            <div className="close-look">
              <Link to="/mhh">
                <div className="top-disc top-disc-4">Know more . . .</div>
              </Link>
              <div className="know-more-intro">
                Changed over 22 lakh lives by donating books
              </div>
              <Link to="/mhh">
                {/* <div className="know-more-1"><a>Know more about Medha Helping Hands</a></div> */}
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={0} sm={2} className="focus-empty"></Col>
          <Col xs={24} sm={20}>
            <div className="focus-container">
              <div className="welcome-focus">{/* Focus and Support */}</div>
              <div className="focus-content">
                Medha Charitable Trust was founded in 2006 with an aim to
                improve quality of education in India. We believe that an
                educated person can lead a self-sustainable and respectful life
                and that it is our responsibility to support and uplift the
                people of our nation. We are making an essential and long-term
                investment for the future of our country. All our initiatives
                are sponsored by Medha Servo Drives Pvt. Ltd.
              </div>
            </div>
          </Col>
          <Col xs={0} sm={2} className="focus-empty"></Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { AuthReducer, AppConfigReducer } = state;
  return {
    isLoggedIn: AuthReducer.isLoggedIn,
    isLoading: AuthReducer.isLoading,
    isSuccess: AuthReducer.isSuccess,
    errorMessage: AuthReducer.errorMessage,
    successMessage: AuthReducer.successMessage,
    userContext: AuthReducer.userContext,
    lang: AuthReducer.lang,
    activeTab: AuthReducer.activeTab,
    role: AuthReducer.role,
    showLogin: AuthReducer.showLogin,
    appConfig: AppConfigReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Welcome)
);

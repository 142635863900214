import { Button, Card, Col, Form, Input, Modal, Row } from "antd";
import React from "react";
import { translate } from "../../../base/actions/translate";
import { prepareReviewersFieldData } from "../../utils/util";
import { CONT_INFO, formItemLayout } from "../../utils/consts";
import ReviewStep from "./ReviewStep";

const { TextArea } = Input;

class ContactInfo extends React.Component {
  compare = (appl, form) => {
    const msg = [];
    if (form.alternatePhone !== appl.alternatePhone) {
      msg.push(
        <li>
          You have changed alternate phone number from {appl.alternatePhone} to{" "}
          {form.alternatePhone}
        </li>
      );
    }

    if (form.address !== appl.address) {
      msg.push(
        <li>
          You have changed Home Address from {appl.address} to {form.address}
        </li>
      );
    }

    return msg;
  };

  onFinish = (form) => {
    // console.log("Received values of form:", form);
    const application = this.props.review.reviewDetails.application;
    const msg = this.compare(application, form);
    if (msg.length > 0) {
      this.showConfirm(form, msg);
    } else {
      this.props.saveDetails(CONT_INFO, form);
    }
  };

  showConfirm = (form, msg) => {
    const that = this;
    Modal.confirm({
      title: "Do you want to replace this information?",
      // icon: <ExclamationCircleOutlined />,
      content: <ul>{msg}</ul>,
      onOk() {
        // console.log("OK");
        that.props.saveDetails(CONT_INFO, form);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  render() {
    const application = this.props.review.reviewDetails.application;
    const school = application.school;
    const username = this.props.student.applicationForm.username;
    let contactInfo = this.props.review.reviewFormData[CONT_INFO];
    if (!contactInfo) {
      contactInfo = {
        alternatePhone: application.alternatePhone,
        address: application.address
      };
    }
    contactInfo.newDistrict = school.parent.parent.configVal;
    contactInfo.mandal = school.parent.configVal;
    contactInfo.school = school.configVal;

    const fieldData = prepareReviewersFieldData(contactInfo);
    return (
      <div className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Contact Information"
          onFinish={(e) => this.onFinish(e)}
          {...this.props}
        >
          <Form.Item
            {...formItemLayout}
            name="address"
            label={translate("address")}
            getValueFromEvent={this.toCapital}
            rules={[
              {
                required: true,
                message: translate("studentHomeAddressError"),
              },
              {
                min: 10,
                message: translate("studentHomeAddressError"),
              },
            ]}
          >
            <TextArea rows={4} maxLength="200" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="newDistrict"
            label="New District"
            getValueFromEvent={this.toCapital}
          >
            <Input disabled/>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="mandal"
            label="Mandal"
            getValueFromEvent={this.toCapital}
          >
            <Input disabled/>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="school"
            label="School Name"
            getValueFromEvent={this.toCapital}
          >
            <Input disabled/>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="alternatePhone"
            label={translate("alternatePhone")}
            rules={[
              {
                required: true,
                message: translate("alternativePhoneNumberError"),
              },
              {
                pattern: /^\d{10}$/,
                message: translate("alternativePhoneNumberError"),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || username !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(translate("altNumStudentNum"));
                },
              }),
            ]}
          >
            <Input maxLength="10" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="emailAddress"
            label="Email Address"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </ReviewStep>
      </div>
    );
  }
}

export default ContactInfo;

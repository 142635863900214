import { Card, Col, List, Row, Typography } from "antd";
import React from "react";
import { BellOutlined } from "@ant-design/icons";
import moment from "moment";

const dateFormat = "DD/MM/YYYY";
class Notifications extends React.Component {
  componentDidMount() {
    if (this.props.notif.notifications.length === 0) {
      this.props.getNotifications();
    }
    const that = this;
    setTimeout(() => {
      that.props.setReadNotifications();
    }, 300);
  }

  render() {
    return (
      <div>
        <Card title="Notifications" style={{minHeight: '300px'}}>
        <List
          bordered
          dataSource={this.props.notif.notifications}
          renderItem={(item) => (
            <List.Item>
              <Row gutter={[16, 8]}>
                <Col sm={20} xs={24}>
                  <Typography.Text mark style={{ marginRight: "10px" }}>
                    <BellOutlined />
                  </Typography.Text>
                  {item.message}
                </Col>
                <Col sm={4} xs={24}>
                  {moment(item.createdDate).format("DD-MM-YYYY hh:mm A")}
                </Col>
              </Row>{" "}
            </List.Item>
          )}
        />
        </Card>
      </div>
    );
  }
}
export default Notifications;

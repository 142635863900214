import { STUDE, ALL, ADMIN } from "../../base/utils/roles";

export const GET_STATUS_SUMMARY = "GET_STATUS_SUMMARY";

export const GET_STATUS_SUMMARY_BY_SCHOOL = "GET_STATUS_SUMMARY_BY_SCHOOL";

export const GET_COMMENTS = "GET_COMMENTS";

export const SEARCH_APPLICATIONS = "SEARCH_APPLICATIONS";

export const SS_SEARCH_FILTERS = "SS_SEARCH_FILTERS";

export const CHANGE_STATUS = "CHANGE_STATUS";

export const APPLICATION_BULK_APPRV = "APPLICATION_BULK_APPRV";

export const SHOW_DETAILS = "SHOW_DETAILS";

export const SHOW_COMMENTS = "SHOW_COMMENTS";

export const SHOW_HISTORY = "SHOW_HISTORY";

export const SHOW_REASON_MODAL = "SHOW_REASON_MODAL";

export const SAVE_DRAFT_FILES = "SAVE_DRAFT_FILES";

export const APP_HISTORY = "APP_HISTORY";

export const GET_TASKS = "GET_TASKS";
export const SEARCH_TASK = "SEARCH_TASK";
export const CHANGE_TASK_STATUS = "CHANGE_TASK_STATUS";

export const EXAM_CENTER_DETAILS = "EXAM_CENTER_DETAILS";
export const SEARCH_EXAM_CENTERS = "SEARCH_EXAM_CENTERS";
export const TOGGLE_EXAM_ROOMS_MODAL = "TOGGLE_EXAM_ROOMS_MODAL";
export const SAVE_EXAM_ROOM = "SAVE_EXAM_ROOM";
export const DEL_DRAFT = "DEL_DRAFT";
export const TOGGLE_ADD_EXAM_CENTER_MODAL = "TOGGLE_ADD_EXAM_CENTER_MODAL";
export const SAVE_EXAM_CENTER = "SAVE_EXAM_CENTER";
export const DEL_EXAM_CENTER = "DEL_EXAM_CENTER";

export const EXCEL_EXPORT = "EXCEL_EXPORT";
export const START_SEATING = "START_SEATING";
export const EXAM_DOCS = "EXAM_DOCS";

export const MARKS_CONFIG = "MARKS_PERCENT";
export const UPLOAD_MARKS = "UPLOAD_MARKS";

/* Application review action types */
export const GET_REVIEW_DETAILS = "GET_REVIEW_DETAILS";
export const SAVE_REVIEW = "SAVE_REVIEW";
export const SET_STEP = "SET_STEP";
export const SET_STEP_BY_KEY = "SET_STEP_BY_KEY";
export const SET_FORM_STEPS = "SET_FORM_STEPS";

export const PER_INFO = "PER_INFO";
export const CONT_INFO = "CONT_INFO";
export const CONS_ITEMS = "CONS_ITEMS";
export const VEHC_INFO = "VEHC_INFO";
export const STUD_INFO = "STUD_INFO";
export const RESP_TO_MSS = "RESP_TO_MSS";
export const VOL_CMNTS = "VOL_CMNTS";

export const OCC_DTLS = "OCC_DTLS";
export const AGR_OWN = "AGR_OWN";
export const HOUSE_OWN = "HOUSE_OWN";
export const PLOT_OWN = "PLOT_OWN";
export const CAT_OWN = "CAT_OWN";
export const BUS_OWN = "BUS_OWN";
export const INC_SUM = "INC_SUM";
export const NGHB_DTLS = "NGHB_DTLS";
export const PRVW_SUR = "PRVW_SUR";
export const UPLD_SUR = "UPLD_SUR";

export const SURVEY_STEPS = [
  PER_INFO,
  CONT_INFO,
  CONS_ITEMS,
  VEHC_INFO,
  STUD_INFO,
  RESP_TO_MSS,
  VOL_CMNTS,
  OCC_DTLS,
  AGR_OWN,
  CAT_OWN,
  PLOT_OWN,
  HOUSE_OWN,
  BUS_OWN,
  NGHB_DTLS,
  INC_SUM,
  UPLD_SUR,
  PRVW_SUR,
];
export const REVIEW_STEPS = [];

export const HOUSE_TYPES = [
  "RCC Independent",
  "Portion / Apartment",
  "Hut (Thatched Roof)",
  "Hut (Asbestos Roof)",
];
export const OWNERSHIP_TYPE = ["Own", "Rented", "Let Out"];

export const ANIMAL_TYPES = [
  "Cows & Buffalos",
  "Sheep & Goat",
  "Hen",
  "Fish",
  "Others",
];

export const INCOMP = 1;
export const NEW = 2;
export const VERIF = 3;
export const ACCPT = 4;
export const WAIT = 5;
export const REJECD = 6;
export const REJECC = 7;
export const APPRV = 8;
export const DOWN = 9;
export const ENROL = 12;
export const ATTMPT = 10;
export const SMSS = 11;
export const JOIN = 13;

export const STATUS_LIST = [
  { id: INCOMP, key: "INCOMP" },
  { id: NEW, key: "NEW" },
  { id: VERIF, key: "VERIF" },
  { id: ACCPT, key: "ACCPT" },
  { id: WAIT, key: "WAIT" },
  { id: REJECD, key: "REJECD" },
  { id: REJECC, key: "REJECC" },
  { id: APPRV, key: "APPRV" },
  { id: DOWN, key: "DOWN" },
];

export const ALL_STATUS_LIST = [
  { id: INCOMP, key: "INCOMP" },
  { id: NEW, key: "NEW" },
  { id: VERIF, key: "VERIF" },
  { id: ACCPT, key: "ACCPT" },
  { id: WAIT, key: "WAIT" },
  { id: REJECD, key: "REJECD" },
  { id: REJECC, key: "REJECC" },
  { id: APPRV, key: "APPRV" },
  { id: DOWN, key: "DOWN" },
  { id: ATTMPT, key: "ATTMPT" },
  { id: SMSS, key: "SMSS" },
  { id: ENROL, key: "ENROL" },
  { id: JOIN, key: "JOIN" },
];

export const APPLICATION_EXCEL_COLUMNS = [
  { name: "APPLICATION NO", key: 0 },
  { name: "STUDENT NAME", key: 1 },
  { name: "DISTRICT", key: 2 },
  { name: "MANDAL", key: 3 },
  { name: "SCHOOL", key: 4 },
  { name: "9th CLASS GRADE", key: 5 },
  { name: "CASTE", key: 6 },
  { name: "STATUS", key: 7 },
  { name: "HALL TICKET", key: 8 },
];

export const WORKFLOW = {
  INCOMP: [
    {
      user: [ALL],
      step: NEW,
      requireReason: true,
      reasons: [
        "Application completed",
        "Completed with minor changes",
        "Incomplete application, contact student",
      ],
    },
    {
      user: [ADMIN],
      step: REJECD,
      requireReason: false,
      reasons: [],
    },
  ],
  NEW: [
    {
      user: [ALL],
      step: VERIF,
      requireReason: true,
      reasons: [
        "All entries are correct",
        "Minor mistakes, but acceptable",
        "Corrected mistakes, hence acceptable",
        "Others, please specify reason",
      ],
    },
    {
      user: [ALL],
      step: REJECD,
      requireReason: true,
      reasons: [
        "Authorisation form is not acceptable",
        "9th class grade proof mismatch",
        "Mark sheet proof mismatch",
        "Passport photo not acceptable",
        "Student failed in 9th class",
        "School mismatch",
        "Multiple applications",
        "Fake application",
        "Others, please specify reason",
      ],
    },
  ],
  VERIF: [
    {
      user: [ALL],
      step: ACCPT,
      requireReason: true,
      reasons: ["Student is top 10 in school", "Others, please specify reason"],
    },
    {
      user: [ALL],
      step: WAIT,
      requireReason: true,
      reasons: [
        "Student is not top 10 in school",
        "More than 10 applications, unable to decide who to select",
        "Verification is not correct",
        "Fake 9th class mark sheet, unable to decide",
        "Others, please specify reason",
      ],
    },
    {
      user: [ALL],
      step: REJECD,
      requireReason: true,
      reasons: ["Others, please specify reason"],
    },
  ],
  ACCPT: [
    {
      user: [ALL],
      step: WAIT,
      requireReason: true,
      reasons: [
        "More than 10 applications, unable to decide who to select",
        "Fake 9th class mark sheet, unable to decide",
        "Others, please specify reason",
      ],
    },
    {
      user: [ADMIN],
      step: APPRV,
      requireReason: false,
      reasons: [],
    },
  ],
  WAIT: [
    {
      user: [ALL],
      step: ACCPT,
      requireReason: true,
      reasons: ["Student is top 10 in school", "Others, please specify reason"],
    },
    {
      user: [ALL],
      step: REJECD,
      requireReason: true,
      reasons: [
        "More than 10 applications, student is not top 10 in school",
        "Others, please specify reason",
      ],
    },
  ],
  REJECD: [
    {
      user: [ADMIN],
      step: REJECC,
      requireReason: false,
      reasons: [],
    },
    {
      user: [ADMIN],
      step: VERIF,
      requireReason: true,
      reasons: [
        "Status change was human error",
        "Others, please specify reason",
      ],
    },
  ],
  REJECC: [],
  APPRV: [],
  DOWN: [],
};

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
  //   wrapperCol: {
  //     xs: { span: 24 },
  //     sm: { span: 12 },
  //   },
};

export const radioItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
  //   wrapperCol: {
  //     xs: { span: 24 },
  //     sm: { span: 12 },
  //   },
};

export const DYN_FIELDS = ["siblings", "others", "agriculture"];

export const SURVEY_FILE_CAT = [
  "RATION",
  "PAN",
  "ADHAAR",
  "CASTE_CERT",
  "INC_PROF",
];

export const SURVEY_PHOTO_CAT = [
  "SOLO",
  "FAMILY",
  "STREET",
  "HOUSE_OUT",
  "HOUSE_IN",
  "ROOMS",
  "KITCHEN",
  "ELE_ITEMS",
  "INC_GEN_OPT",
  "AWARDS",
  "OTHER_INFO",
  "WITNESSES",
];

export const NON_REQ_IMAGES = [
  "PAN",
  "INC_PROF",
  "AWARDS",
  "ELE_ITEMS",
  "INC_GEN_OPT",
];

export const COMPARE_CAT = [
  PER_INFO,
  CONT_INFO,
  CONS_ITEMS,
  VEHC_INFO,
  STUD_INFO,
  RESP_TO_MSS,
  VOL_CMNTS,
  OCC_DTLS,
  AGR_OWN,
  CAT_OWN,
  PLOT_OWN,
  HOUSE_OWN,
  BUS_OWN,
  NGHB_DTLS,
  INC_SUM,
  UPLD_SUR,
];

export const SURVEY_OTHER_CAT = ["OTHERS"];

export const ALL_SURVEY_UPLOAD_CAT = [].concat(
  SURVEY_PHOTO_CAT,
  SURVEY_FILE_CAT,
  SURVEY_OTHER_CAT
);

export const DRAFT_SURVEY_IMG = "DRAFT_SURVEY_IMG";
export const MULT_SURVEY_IMG = "MULT_SURVEY_IMG";
export const DEL_FILES = "DEL_FILES";
export const ADD_TO_DEL_FILES = "ADD_TO_DEL_FILES";
export const APPROVED_DRAFTS = "APPROVED_DRAFTS";

export const SHOW_PREVIEW_AFTER_SAVE = "SHOW_PREVIEW_AFTER_SAVE";
export const SUBMIT_SURVEY = "SUBMIT_SURVEY";

export const SURVEYED_LIST = "SURVEYED_LIST";
export const SET_APPL = "SET_APPL";

export const SET_COMMITTEE_DATA = "SET_COMMITTEE_DATA";
export const UPDATE_COMMITTEE_FEEDBACK = "UPDATE_COMMITTEE_FEEDBACK";
export const SET_COMPARE_LIST = "SET_COMPARE_LIST";
export const TOGGLE_COMPARE = "TOGGLE_COMPARE";
export const TOGGLE_COMPARE_REVIEW = "TOGGLE_COMPARE_REVIEW";
export const SET_STATUS_IN_PREVIEW = "SET_STATUS_IN_PREVIEW";
export const SET_FEEDBACK_IN_PREVIEW = "SET_FEEDBACK_IN_PREVIEW";
export const SEARCH_SURVEYED_LIST = "SEARCH_SURVEYED_LIST";
export const UPDATE_ENROL_STATUS = "UPDATE_ENROL_STATUS";

export const SEL_STATUS_LIST = ["WAIT", "ACCPT", "UNDEC", "REJECT"];

export const EXCEL_ERROR = "Exception in excel export";

export const TASK_PEND = 3;
export const TASK_APRV = 1;
export const TASK_DEN = 4;
export const TASK_COMP = 2;

export const TASK_STATUS = [
  { id: TASK_APRV, key: "APRV" },
  { id: TASK_COMP, key: "COMP" },
  { id: TASK_PEND, key: "PEND" },
  { id: TASK_DEN, key: "DEN" },
];

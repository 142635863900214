import React from 'react';
import { Timeline, List, Space } from 'antd';
import { translate } from '../../base/actions/translate';
import moment from 'moment';
import { getStatusCode } from '../utils/util';

class History extends React.Component {

    getDate = (dateTimeString) => {
        return moment(dateTimeString).format("DD-MM-YYYY");
    }

    generateAppHistory = (hst) => {
        // const hst = [];
        // for (const item of hst.history) {
        // hst.push(<p>{translate(item.fieldName)} from {item.oldValue} to {item.newValue}</p>);
        // }

        return (
            <Timeline.Item label={'Updated by ' + hst.username + ' on ' + moment.unix(parseInt(hst.dateTime)).format('DD/MM/YYYY hh:mm a') } key={hst.updatedAt}>
            <List
                itemLayout="horizontal"
                dataSource={hst.history}
                renderItem={item => (
                    <List.Item>
                        {translate(item.fieldName)} from <Space></Space>
                        {item.fieldName === 'status' &&
                        <span> {translate(getStatusCode(parseInt(item.oldValue))?.key)} to {translate(getStatusCode(parseInt(item.newValue))?.key)}</span>}
                        {item.fieldName !== 'status' && item.fieldName !== 'dateOfBirth' &&
                        <span>{item.oldValue} to {item.newValue}</span>}
                        {item.fieldName === 'dateOfBirth' &&
                            <span>{this.getDate(item.oldValue)} to {this.getDate(item.newValue)}</span>
                        }
                        {item.fieldName === 'status' && <p>{hst.comment}</p>}
                    </List.Item>
                )}
            /></Timeline.Item>
        );
    }

    generateUploadHistory = (hst) => {
        return (
            <Timeline.Item label={'Uploaded by ' + hst.username + ' on ' + moment.unix(parseInt(hst.dateTime)).format('DD/MM/YYYY hh:mm a')} key={hst.updatedAt}>
            <List
                itemLayout="horizontal"
                dataSource={hst.history}
                renderItem={item => (
                    <List.Item>
                        Upload {item.oldValue} as {translate(item.fieldName)}
                    </List.Item>
                )}
            /></Timeline.Item>
        );
    }

    generateHistory = () => {
        const html = [];
        if(this.props.history.length === 0) {
            return html;
        }
        for (const hst of this.props.history) {
            if (hst.entity === 'APP') {
                html.push(this.generateAppHistory(hst));
            } else if (hst.entity === 'UPL') {
                html.push(this.generateUploadHistory(hst));
            }
        }

        return html;
    }

    render() {
        const history = this.props.history;
        return (
            <div style={{width: '100%'}}>
                <h2>History</h2>
                <Timeline mode="left">
                    {this.generateHistory()}
                </Timeline>
            </div>

        );
    }
}

export default History;
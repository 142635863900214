import {
  GET_USERS_LIST,
  ADD_USER,
  EDIT_USER,
  TOGGLE_ADD_USER_MODAL,
  DELETE_USER,
  GET_USER_DETAILS,
  GET_HT_DETAILS,
  RESET_MESSAGES,
  ADMIN_SHORT_LIST,
  SEND_ACCT_INFO,
  SET_FILTERED_USERS,
  CLEAN_UP,
} from "../utils/consts";

const initialState = {
  loading: false,
  usersList: [],
  filteredUsersList: [],
  showAddUser: false,
  currentProfile: {},
  htDetails: [],
  errorMessage: "",
  successMessage: "",
};
const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_USERS_LIST}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${GET_USERS_LIST}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        usersList: action.payload,
        filteredUsersList: action.payload,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${GET_USERS_LIST}_REJECTED`: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_FILTERED_USERS: {
      return {
        ...state,
        filteredUsersList: action.payload,
      };
    }

    case `${ADD_USER}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${ADD_USER}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
        showAddUser: false,
      };
    }

    case `${ADD_USER}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message
          ? action.payload.message
          : action.payload.data,
      };
    }

    case `${EDIT_USER}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${EDIT_USER}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
        showAddUser: false,
      };
    }

    case `${EDIT_USER}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message
          ? action.payload.message
          : action.payload.data,
      };
    }

    case `${DELETE_USER}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${DELETE_USER}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    }

    case `${DELETE_USER}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${GET_USER_DETAILS}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${GET_USER_DETAILS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        currentProfile: action.payload,
      };
    }

    case `${GET_USER_DETAILS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${SEND_ACCT_INFO}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${SEND_ACCT_INFO}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        errorMessage: "",
        successMessage: action.payload,
      };
    }

    case `${SEND_ACCT_INFO}_REJECTED`: {
      return {
        ...state,
        loading: false,
      };
    }

    case `${GET_HT_DETAILS}_PENDING`: {
      return {
        ...state,
        loading: true,
        htDetails: [],
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${GET_HT_DETAILS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        htDetails: action.payload,
      };
    }

    case `${GET_HT_DETAILS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${ADMIN_SHORT_LIST}_PENDING`: {
      return {
        ...state,
        loading: true,
        htDetails: [],
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${ADMIN_SHORT_LIST}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    }

    case `${ADMIN_SHORT_LIST}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case TOGGLE_ADD_USER_MODAL: {
      return {
        ...state,
        showAddUser: action.payload,
        errorMessage: "",
        successMessage: "",
      };
    }

    case EDIT_USER: {
      return {
        ...state,
        currentProfile: action.payload,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${CLEAN_UP}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${CLEAN_UP}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
        showAddUser: false,
      };
    }

    case `${CLEAN_UP}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message
          ? action.payload.message
          : action.payload.data,
      };
    }

    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    }

    default:
      return state;
  }
};

export default AdminReducer;

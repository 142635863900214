import React from "react";
import { Row, Col, Button, Input, Form, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

class UploadMarksSheet extends React.Component {

    formRef = React.createRef();
    
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      uploading: false,
    };
  }

  normFile = (e) => {
    // console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    if(e.fileList[0] && !this.isExcel(e.fileList[0])) {
        return null;
    }
    return e && e.fileList[0];
  };

  isExcel = (file) => {
    const ext = file.name.split('.').pop();
        if (ext !== 'xls' && ext !== 'xlsx') {
            return false;
        }
    return true;
  }

  uploadMarksSheet = (form) => {
    // console.log('form values  : ', form)
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('marksSheet', file);
    });
    formData.append('totalMarks', form.totalMarks);
    formData.append('percentage', form.percentage);
    this.props.uploadMarksSheet(formData);
    this.setState({fileList: []});
    this.formRef.current.setFieldsValue(this.props.fieldData);
}

  render() {
    const { uploading, fileList } = this.state;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        if (!this.isExcel(file)) {
            message.error(`${file.name} is not a excel file`);
            return false;
        }
        this.setState((state) => ({
          fileList: [file],
        }));
        return false;
      },
      fileList,
    };

    return (
      <div id='upload-marks-sheet-container'>
        <Form name="marksSheetData" onFinish={this.uploadMarksSheet} fields={this.props.fieldData} ref={this.formRef}>
          <Row>
            <Col>
              <Form.Item
                name="marksSheet"
                valuePropName="file"
                getValueFromEvent={this.normFile}
                label="Marks Sheet"
                rules={[
                  {
                    required: true,
                    message:
                      "Please provide total marks for which exam is conducted.",
                  },
                ]}
              >
                <Upload {...props}>
                  <Button>
                    <UploadOutlined /> Select Excel File (.xlsx) only
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row className="mg-l">
            <Col sm={12}>
              <Form.Item
                name="totalMarks"
                label="Total Marks"
                rules={[
                  {
                    required: true,
                    message:
                      "Please provide total marks for which exam is conducted.",
                  },
                ]}
                extra="Please provide total marks for which exam is conducted."
              >
                <Input></Input>
              </Form.Item>
            </Col>
            <Col className="pd-l-l" sm={12}>
              <Form.Item
                name="percentage"
                label="% To Be Considered"
                rules={[
                  {
                    required: true,
                    message:
                      "Please provide percentage of marks to be considered in total.",
                  },
                ]}
                extra="Please provide percentage of marks to be considered in total."
              >
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
          <Button type="primary" htmlType="submit" style={{float:'right'}}>
            Upload
          </Button>
        </Form>
      </div>
    );
  }
}

export default UploadMarksSheet;
import { Col, Form, Row, Input, Select, Button } from "antd";
import React, { useEffect, useState } from "react";
import { PAY_MODE, PAY_TO } from "../../../management/utils/const";
import moment from "moment";
import { formItemLayout } from "../../../sampoorna-siksha/utils/consts";
import { checkEligibility } from "../../utils/util";
import CommentsAndDocs from "./CommentsAndDocs";
import { APRV, COMP, DEN, PEND } from "../../utils/const";
import { MON_REQ } from "../../../mastertables/utils/const";

const MonetaryRequestDetails = (props) => {
  const [monetaryRequest, setMonetaryRequest] = useState({});
  const [formUpdated, setFormUpdated] = useState(false);
  const [subCatList, setSubCatList] = useState([]);
  const [status, setStatus] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [form] = Form.useForm();
  const [eligibleLevel, setEligibleLevel] = useState([]);

  useEffect(() => {
    props.getReqCats(MON_REQ);
  }, []);

  useEffect(() => {
    if(props.currentTask.eligibleLevel) {
      const e = props.currentTask?.eligibleLevel?.split(",");
      setEligibleLevel(e);
    }
  }, [props.currentTask]);

  useEffect(() => {
    if (
      props.tasks.currentTask.entity &&
      props.tasks.currentTask.entity.length > 0 &&
      !props.tasks.loading
    ) {
      setMonetaryRequest(props.tasks.currentTask.entity[0]);
      onCatChanged(
        props.tasks.currentTask.entity[0].category,
        props.tasks.currentTask.entity[0].subCategory
      );
    }
  }, [props.tasks.currentTask, props.masterTable.reqCat]);

  const onFinish = (formValues) => {
    console.log("-------", formValues);
    if (monetaryRequest.monitoryRequestID) {
      formValues.createdAt = moment(monetaryRequest.createdAt);
    }
    formValues.status = status;
    setEditMode(false);
    form.setFieldsValue({
      comment: "",
    });
    props.saveMonitoryRequest(
      {
        monitoryRequest: formValues,
        studentProfileId: monetaryRequest.studentProfileId,
        comment: formValues.comment,
      },
      props.currentTask,
      props.taskFilter
    );
  };

  const onValueChange = () => {
    setFormUpdated(true);
  };

  const onCatChanged = (cat, subCat) => {
    const selCat = props.masterTable.reqCat?.find(
      (c) => c.requestCategoryId === cat
    );
    if (selCat) {
      setSubCatList(selCat.requestSubCategories);
    }

    form.setFieldsValue({
      subCategory: subCat,
    });
  };

  // const saveStatus = (form) => {
  //   if (!editMode) {
  //     props.changeStatusWithComment(
  //       { taskId: props.currentTask.taskId, status, comment: form.comment },
  //       props.currentTask
  //     );
  //   }
  // };

  const changeStatus = (status) => {
    setStatus(status);
    form.submit();
  };

  return (
    <React.Fragment>
      <div className="med-req-approval-container">
        {monetaryRequest.studentProfile !== undefined && (
          <>
            <div style={{ marginBottom: "10px" }}>
              <div>
                <Form
                  initialValues={monetaryRequest}
                  onFinish={onFinish}
                  form={form}
                  onValuesChange={(e) => onValueChange(e)}
                >
                  <Form.Item
                    {...formItemLayout}
                    name="monitoryRequestID"
                    hidden={true}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    name="studentProfileId"
                    hidden={true}
                  >
                    <Input />
                  </Form.Item>
                  <Row gutter={[16, 8]}>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="category"
                        label="Category"
                        rules={[
                          {
                            required: true,
                            message: "Please select Category",
                          },
                        ]}
                      >
                        <Select
                          className="full-width"
                          onSelect={(e) => onCatChanged(e, "")}
                          disabled={!editMode}
                        >
                          {props.masterTable.reqCat.map((typ) => (
                            <Select.Option
                              key={typ.requestCategoryId}
                              value={typ.requestCategoryId}
                            >
                              {typ.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="subCategory"
                        label="Sub-Category"
                        rules={[
                          {
                            required: true,
                            message: "Please select Sub-Category",
                          },
                        ]}
                      >
                        <Select className="full-width" disabled={!editMode}>
                          {subCatList.map((typ, i) => (
                            <Select.Option
                              key={typ.requestSubCategoryId}
                              value={typ.requestSubCategoryId}
                            >
                              {typ.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="modeOfPayment"
                        label="Mode of Payment"
                        rules={[
                          {
                            required: true,
                            message: "Please select Mode of Payment",
                          },
                        ]}
                      >
                        <Select className="full-width" disabled={!editMode}>
                          {PAY_MODE.map((typ, i) => (
                            <Select.Option key={"item-" + i} value={typ.key}>
                              {typ.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="transferTo"
                        label="Transfer to"
                        rules={[
                          {
                            required: true,
                            message: "Please select Transfer to",
                          },
                        ]}
                      >
                        <Select className="full-width" disabled={!editMode}>
                          {PAY_TO.map((typ, i) => (
                            <Select.Option key={"item-" + i} value={typ.key}>
                              {typ.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="details"
                        label="Details"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Details",
                          },
                        ]}
                      >
                        <Input.TextArea maxLength="1000" disabled={!editMode} />
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="amount"
                        label="Amount"
                        rules={[
                          {
                            pattern: /^[1-9][0-9]*$/,
                            message: "Please enter valid Amount",
                          },
                        ]}
                      >
                        <Input disabled={!editMode} />
                      </Form.Item>
                    </Col>
                  </Row>
                  {checkEligibility(
                    eligibleLevel,
                    props.role,
                    props.record.status
                  ) && (
                    <Form.Item
                      label="Reason"
                      name="comment"
                      rules={[
                        { required: true, message: "Please enter the reason" },
                      ]}
                    >
                      <Input.TextArea rows={2} />
                    </Form.Item>
                  )}
                </Form>
              </div>
              <CommentsAndDocs
                currentTask={props.currentTask}
                eligibleLevel={eligibleLevel}
                showDocs={true}
                {...props}
              />
              <div style={{ textAlign: "right", marginTop: "10px" }}>
              {checkEligibility(
                eligibleLevel,
                props.role,
                props.currentTask.status
              ) &&
                props.currentTask.status === PEND && (
                  <Button
                    type="primary"
                    onClick={(e) => changeStatus(APRV)}
                    style={{ marginRight: 8 }}
                  >
                    Approve
                  </Button>
                )}
              {checkEligibility(
                eligibleLevel,
                props.role,
                props.currentTask.status
              ) &&
                props.currentTask.status === APRV && (
                  <Button
                    type="primary"
                    onClick={(e) => changeStatus(APRV)}
                    style={{ marginRight: 8 }}
                  >
                    Completed
                  </Button>
                )}
              {checkEligibility(
                eligibleLevel,
                props.role,
                props.currentTask.status
              ) &&
                props.currentTask.status === COMP && (
                  <Button
                    type="primary"
                    onClick={(e) => changeStatus(COMP)}
                    style={{ marginRight: 8 }}
                  >
                    Close Request
                  </Button>
                )}
              {checkEligibility(
                eligibleLevel,
                props.role,
                props.currentTask.status
              ) &&
                props.currentTask.status !== COMP && (
                  <Button
                    onClick={(e) => changeStatus(DEN)}
                    style={{ marginRight: 8 }}
                    danger
                  >
                    Deny
                  </Button>
                )}
                {checkEligibility(
                  eligibleLevel,
                  props.role,
                  props.record.status
                ) &&
                  props.showEdit &&
                  !editMode && (
                    <Button
                      type="primary"
                      onClick={(e) => setEditMode(true)}
                      style={{ marginRight: 8 }}
                    >
                      Edit
                    </Button>
                  )}

                {checkEligibility(
                  eligibleLevel,
                  props.role,
                  props.record.status
                ) &&
                  props.showEdit &&
                  editMode && (
                    <Button
                      onClick={(e) => {
                        setEditMode(false);
                        form.setFieldsValue(monetaryRequest);
                      }}
                      style={{ marginRight: 8 }}
                    >
                      Cancel
                    </Button>
                  )}

                <Button
                  onClick={(e) => props.onClose(e)}
                  style={{ marginRight: 8 }}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default MonetaryRequestDetails;

import { connect } from "react-redux";
import { getApplication } from "../actions/StudentActions";
import { fetchDistricts, fetchMandals, fetchCasteCategory, fetchSchools, fetchExamCenters } from "../../base/actions/AppConfigActions";
import ViewApplication from "../components/ViewApplication";

const mapDispatchToProps = dispatch => {
  return {
    getApplication(username, year) {
      dispatch(getApplication(year, username));
    },

    getDistricts() {
      dispatch(fetchDistricts());
    },

    getMandalsList(districtId) {
      dispatch(fetchMandals(districtId));
    },

    getSchoolsList(mandalId) {
      dispatch(fetchSchools(mandalId));
    },

    getCastes() {
      dispatch(fetchCasteCategory());
    },

    getExamCenters() {
      dispatch(fetchExamCenters());
    },
  };
};

const mapStateToProps = state => {
  const { AuthReducer, StudentReducer, AppConfigReducer } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    studentData: StudentReducer,
    appConfig: AppConfigReducer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewApplication);
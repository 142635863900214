import React from "react";
//import LogoWithoutText from './assets/MCT-logo-without-text';

import { Drawer, Layout, Menu } from "antd";
import {
  SettingOutlined,
  HomeOutlined,
  UserOutlined,
  PoweroffOutlined,
  FormOutlined,
  CarryOutOutlined,
} from "@ant-design/icons";
import "../styles/base.css";

const { Sider } = Layout;
const { SubMenu } = Menu;

class AdminNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
    this.navigate = this.navigate.bind(this);
  }

  navigate(item) {
    this.props.toggleMainMenu();
    if (item.key === "userList") {
      this.props.history.push("/users");
    } else if (item.key === "config") {
      this.props.history.push("/config/sysConfig");
    } else if (item.key === "home") {
      this.props.history.push("/home");
    } else if (item.key === "editProfile") {
      this.props.history.push("/editProfile");
    } else if (item.key === "ssProcess") {
      this.props.history.push("/sampurnaSiksha/application-processing");
    } else if (item.key) {
      this.props.history.push(`/${item.key}`);
    }
  }

  render() {
    if (!this.props.isLoggedIn) {
      return "";
    }
    return (
      <div>
        {this.props.isLoggedIn && (
          <Drawer
            id="navbar"
            className="nav-bar"
            placement="left"
            closable={false}
            visible={this.props.expandMenu}
            onClose={this.props.toggleMainMenu}
          >
            <Menu
              theme="dark"
              defaultSelectedKeys={["1"]}
              mode="inline"
              onClick={this.props.performMenuActions}
            >
              {/* <Menu.Item key="1">
                  <img className="menu-item" src='/images/MSS_Web_logo.png' alt="mss"></img>
                    <span>Medha Sampoorna Siksha</span>
                  </Menu.Item> */}
              <Menu.Item key="home" onClick={this.navigate}><HomeOutlined /><span>Home</span></Menu.Item>
              {/* <SubMenu
                key="smm"
                title={
                  <span>
                    <FormOutlined />
                    <span>Sampurna Siksha</span>
                  </span>
                }
              > */}
                {/* <Menu.Item key="ssProcess" onClick={this.navigate}><span>Application Processing</span></Menu.Item> */}
                {/* <Menu.Item key="download-hall">Hall Ticket</Menu.Item> */}
              {/* </SubMenu>   */}
              {/* <Menu.Item key="smm" onClick={this.navigate}><FormOutlined /> <span>Sampurna Siksha</span></Menu.Item> */}
              {/* <Menu.Item key="config" onClick={this.navigate}>
                
                <SettingOutlined /><span>Configuration</span>
              </Menu.Item> */}

              {/* <Menu.Item key="enrollment" onClick={this.navigate}>
                <CarryOutOutlined /><span>Enrollment</span>
              </Menu.Item> */}

              <Menu.Item key="editProfile" onClick={this.navigate}>
                <UserOutlined />
                <span>Edit Profile</span>
              </Menu.Item>
              <Menu.Item key="logout" onClick={this.props.logout}>
                <PoweroffOutlined />
                <span>Logout</span>
              </Menu.Item>
            </Menu>
          </Drawer>
        )}
      </div>
    );
  }
}

export default AdminNav;

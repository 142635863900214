import {
  GET_STATUS_SUMMARY,
  SEARCH_APPLICATIONS,
  SS_SEARCH_FILTERS,
  CHANGE_STATUS,
  SHOW_COMMENTS,
  SHOW_HISTORY,
  SHOW_DETAILS,
  SHOW_REASON_MODAL,
  GET_COMMENTS,
  GET_STATUS_SUMMARY_BY_SCHOOL,
  DRAFT_UPLOAD,
  SAVE_DRAFT_FILES,
  APP_HISTORY,
  GET_TASKS,
  SEARCH_TASK,
  CHANGE_TASK_STATUS,
  APPLICATION_BULK_APPRV,
  DEL_DRAFT,
  EXAM_CENTER_DETAILS,
  SEARCH_EXAM_CENTERS,
  TOGGLE_EXAM_ROOMS_MODAL,
  SAVE_EXAM_ROOM,
  EXCEL_EXPORT,
  START_SEATING,
  EXAM_DOCS,
  MARKS_CONFIG,
  UPLOAD_MARKS,
  UPDATE_ENROL_STATUS,
  EXCEL_ERROR,
  TOGGLE_ADD_EXAM_CENTER_MODAL,
  SAVE_EXAM_CENTER,
} from "../utils/consts";
import { RESET_MESSAGES } from "../../base/utils/const";
import { SAVE_APPLICATION, ROTATE_IMG } from "../../student/util/const";

const initialState = {
  loading: false,
  errorMessage: "",
  successMessage: "",
  statusSummary: [],
  schoolStatusSummary: [],
  applications: [],
  searchedApplications: [],
  ssSearchFilters: {},
  showDetails: false,
  showComments: false,
  showHistory: false,
  showReasonModal: false,
  comments: [],
  uploadDrafts: [],
  appHistory: [],
  taskList: [],
  filteredTasks: [],
  examCentersList: [],
  filteredExamCentersList: [],
  showExamCenterModal: false,
  showAddExamCenterModal: false,
  marksConfig: [],
};
const SSReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_STATUS_SUMMARY}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${GET_STATUS_SUMMARY}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        statusSummary: action.payload,
      };
    }

    case `${GET_STATUS_SUMMARY}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${GET_STATUS_SUMMARY_BY_SCHOOL}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
        schoolStatusSummary: [],
      };
    }

    case `${GET_STATUS_SUMMARY_BY_SCHOOL}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        schoolStatusSummary: action.payload,
      };
    }

    case `${GET_STATUS_SUMMARY_BY_SCHOOL}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${SEARCH_APPLICATIONS}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
        applications: [],
        searchedApplications: [],
      };
    }

    case `${SEARCH_APPLICATIONS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        applications: action.payload,
        searchedApplications: action.payload,
      };
    }

    case `${SEARCH_APPLICATIONS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
        searchedApplications: [],
        applications: [],
      };
    }

    case `${CHANGE_STATUS}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${CHANGE_STATUS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
        showReasonModal: false,
      };
    }

    case `${CHANGE_STATUS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${APPLICATION_BULK_APPRV}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${APPLICATION_BULK_APPRV}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
        showReasonModal: false,
      };
    }

    case `${APPLICATION_BULK_APPRV}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${GET_COMMENTS}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${GET_COMMENTS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        comments: action.payload,
      };
    }

    case `${GET_COMMENTS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${GET_TASKS}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
        taskList: [],
        filteredTasks: [],
      };
    }

    case `${GET_TASKS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        taskList: action.payload,
        filteredTasks: action.payload,
      };
    }

    case `${GET_TASKS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case SEARCH_TASK: {
      return {
        ...state,
        filteredTasks: action.payload,
      };
    }

    case `${CHANGE_TASK_STATUS}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${CHANGE_TASK_STATUS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    }

    case `${CHANGE_TASK_STATUS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${SAVE_DRAFT_FILES}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${SAVE_DRAFT_FILES}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        // successMessage: 'Documents uploaded successfully',
      };
    }

    case `${SAVE_DRAFT_FILES}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${APP_HISTORY}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
        appHistory: [],
      };
    }

    case `${APP_HISTORY}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        appHistory: action.payload,
      };
    }

    case `${APP_HISTORY}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${SAVE_APPLICATION}_FULFILLED`: {
      return {
        ...state,
        successMessage: "Application saved successfully",
      };
    }

    case `${ROTATE_IMG}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${ROTATE_IMG}_REJECTED`: {
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    }

    case `${ROTATE_IMG}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        // successMessage: 'Image updated successfully'
      };
    }

    case `${EXAM_CENTER_DETAILS}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
        examCentersList: [],
        filteredExamCentersList: [],
      };
    }

    case `${EXAM_CENTER_DETAILS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        examCentersList: action.payload,
        filteredExamCentersList: action.payload,
      };
    }

    case `${EXAM_CENTER_DETAILS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case SEARCH_EXAM_CENTERS: {
      return {
        ...state,
        filteredExamCentersList: action.payload,
      };
    }

    case `${DEL_DRAFT}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${DEL_DRAFT}_REJECTED`: {
      return {
        ...state,
        // errorMessage: action.payload,
        loading: false,
      };
    }

    case `${DEL_DRAFT}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        // successMessage: 'Image updated successfully'
      };
    }

    case SEARCH_APPLICATIONS: {
      return {
        ...state,
        searchedApplications: action.payload,
      };
    }

    case SS_SEARCH_FILTERS: {
      return {
        ...state,
        ssSearchFilters: action.payload,
      };
    }

    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    }

    case SHOW_DETAILS: {
      return {
        ...state,
        showDetails: action.payload,
      };
    }

    case SHOW_COMMENTS: {
      return {
        ...state,
        showComments: action.payload,
      };
    }

    case SHOW_HISTORY: {
      return {
        ...state,
        showHistory: action.payload,
      };
    }

    case SHOW_REASON_MODAL: {
      return {
        ...state,
        showReasonModal: action.payload,
      };
    }

    case TOGGLE_EXAM_ROOMS_MODAL: {
      return {
        ...state,
        showExamCenterModal: action.payload,
      };
    }

    case TOGGLE_ADD_EXAM_CENTER_MODAL: {
      return {
        ...state,
        showAddExamCenterModal: action.payload,
      };
    }

    case `${SAVE_EXAM_ROOM}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${SAVE_EXAM_ROOM}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
        showExamCenterModal: false,
      };
    }

    case `${SAVE_EXAM_ROOM}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${SAVE_EXAM_CENTER}_PENDING`: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${SAVE_EXAM_CENTER}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
        showAddExamCenterModal: false,
      };
    }

    case `${SAVE_EXAM_CENTER}_REJECTED`: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      };
    }

    case `${EXCEL_EXPORT}_PENDING`: {
      return {
        ...state,
        loading: true,
        isExporting: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${EXCEL_EXPORT}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        isExporting: false,
      };
    }

    case `${EXCEL_EXPORT}_REJECTED`: {
      return {
        ...state,
        loading: false,
        isExporting: false,
        errorMessage: EXCEL_ERROR,
      };
    }

    case `${START_SEATING}_PENDING`: {
      return {
        ...state,
        loading: true,
        isExporting: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${START_SEATING}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        isExporting: false,
        successMessage: action.payload
      };
    }

    case `${START_SEATING}_REJECTED`: {
      return {
        ...state,
        loading: false,
        isExporting: false,
        // errorMessage: action.payload.data,
      };
    }

    case `${EXAM_DOCS}_PENDING`: {
      return {
        ...state,
        loading: true,
        isExporting: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${EXAM_DOCS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        isExporting: false,
      };
    }

    case `${EXAM_DOCS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        isExporting: false,
        errorMessage: EXCEL_ERROR,
      };
    }

    case `${MARKS_CONFIG}_PENDING`: {
      return {
        ...state,
        loading: true,
        marksConfig: [],
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${MARKS_CONFIG}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        marksConfig: action.payload,
      };
    }

    case `${MARKS_CONFIG}_REJECTED`: {
      return {
        ...state,
        loading: false,
        isExporting: false,
        // errorMessage: action.payload.data,
      };
    }

    case `${UPLOAD_MARKS}_PENDING`: {
      return {
        ...state,
        loading: true,
        isExporting: true,
        errorMessage: "",
        successMessage: "",
      };
    }

    case `${UPLOAD_MARKS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        isExporting: false,
      };
    }

    case `${UPLOAD_MARKS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        isExporting: false,
        // errorMessage: action.payload.data,
      };
    }

    case `${UPDATE_ENROL_STATUS}_FULFILLED`: {
      const result = action.payload;
      const { applications, searchedApplications } = state;
      for (const appl of result) {
        for (const i of searchedApplications) {
          if (i.applicationId === appl.applicationId) {
            i.status = appl.status;
          }
        }

        for (const i of applications) {
          if (i.applicationId === appl.applicationId) {
            i.status = appl.status;
          }
        }
      }
      return {
        ...state,
        applications: applications,
        searchedApplications: searchedApplications,
      };
    }

    case `${UPDATE_ENROL_STATUS}_REJECTED`: {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }

    default:
      return state;
  }
};

export default SSReducer;

import React from 'react';
import { Card, Spin, Button, Row, Col } from 'antd';
import Verification from './Verification';
import { Link } from 'react-router-dom';
import { translate } from '../../base/actions/translate';
import moment from 'moment';
import { getLoggedInUser } from '../../service/SessionStorageService';
import { INCOMP, NEW } from '../../sampoorna-siksha/utils/consts';

class ViewApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: getLoggedInUser(),
    };
  }

  componentDidMount() {
    if (this.props.profile) {
      this.props.getApplication(
        this.props.profile.username,
        moment().year()
      );
    }
  }

  showEdit = () => {
    let startDate = "";
    let endDate = "";
    const sysConfig = this.props.appConfig.systemConfig;
    for (const config of sysConfig) {
      if (config.configCd === "APP_START_DT") {
        startDate = moment(config.configVal);
      } else if (config.configCd === "APP_END_DT") {
        endDate = moment(config.configVal);
      }
    }

    return (
      startDate &&
      endDate &&
      moment().isAfter(startDate, 'day') &&
      moment().isBefore(endDate, 'day') &&
      (this.props.studentData.applicationForm.status === INCOMP ||
      this.props.studentData.applicationForm.status === NEW)
    );
  };

  render() {
    return (
      <Card className="card-95 mg-l">
        <Spin
          spinning={this.props.formLoading || this.props.studentData.loading}
          delay={500}
        >
          <div className="medha-h2">{translate("appViewHeading")}</div>

          <Verification {...this.props}></Verification>
          {this.showEdit() && (
            <Button type="primary" className="addSchoolSubmit">
              <Link to="/sampurnaSiksha/apply">Edit</Link>
            </Button>
          )}
          <Row>
            <Col xs={0} sm={2}></Col>
            <Col xs={22} sm={20}>
            <span><b>{translate("applicationPreviewNote")}</b> {translate("applicationPreviewNoteCont")} </span>
            </Col>
            <Col xs={0} sm={2}></Col>
          </Row>
          
        </Spin>
      </Card>
    );
  }
}

export default ViewApplication;
import { axiosInstance } from "../../Interceptor";
import { APPROVED_MNG_DRAFTS, DEL_MNG_DRAFT, DEL_MNG_FILES, DRAFT_MNG_IMG, DRAFT_REQ_IMG, GET_MNG_IMG, ROTATE_MNG_IMG, ROTATE_REQ_IMG } from "../utils/const";

export const deleteFiles = (filesList) => {
    return {
      type: DEL_MNG_FILES,
      payload: axiosInstance.post(`/upload/manage/delete`, filesList),
    };
  };
  
  export const approveDrafts = (studentProfileId, filesList) => {
    return {
      type: APPROVED_MNG_DRAFTS,
      payload: axiosInstance.post(`/upload/manage/approve/${studentProfileId}`, filesList),
    };
  };
  
  export const draftImg = (studentProfileId, file, type, batch) => {
    const url = `/upload/manage/draft`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("entityId", studentProfileId);
    formData.append("type", type);
    formData.append("year", batch);
    return {
      type: DRAFT_MNG_IMG,
      payload: axiosInstance.post(url, formData),
    };
  };

  export const updateImageAngle = (uploadId, angle) => {
    const data = {
      managementUploadId: uploadId,
      angle: angle
    };
    return {
      type: ROTATE_MNG_IMG,
      payload: axiosInstance.post(`/upload/manage/rotate`, data),
    };
  };

  export const deleteDrafts = (studentProfileId) => {
    return {
      type: DEL_MNG_DRAFT,
      payload: axiosInstance.delete(`/upload/manage/draft/${studentProfileId}`),
    };
  };

  export const getUploadsByProfIdAndType = (profId, type) => {
    return {
      type: GET_MNG_IMG,
      payload: axiosInstance.get(`/upload/manage/${type}/${profId}`),
    };
  }

  export const draftReqImg = (studentProfileId, file, type, batch) => {
    const url = `/upload/manage/draft`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("entityId", studentProfileId);
    formData.append("type", type);
    formData.append("year", batch);
    return {
      type: DRAFT_REQ_IMG,
      payload: axiosInstance.post(url, formData),
    };
  };

  export const updateReqImageAngle = (uploadId, angle) => {
    const data = {
      managementUploadId: uploadId,
      angle: angle
    };
    return {
      type: ROTATE_REQ_IMG,
      payload: axiosInstance.post(`/upload/manage/rotate`, data),
    };
  };
import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ReviewStep from "./ReviewStep";
import { AGR_OWN, formItemLayout } from "../../utils/consts";
import Text from "antd/lib/typography/Text";
import { prepareReviewersFieldData } from "../../utils/util";
import { SELCOMT } from "../../../base/utils/roles";

const { Option } = Select;

class AgricultureForm extends React.Component {
  onFinish = (form) => {
    // console.log("Received values of form:", form);
    this.props.saveDetails(AGR_OWN, form);
  };
  render() {
    let reviewData = this.props.review.reviewFormData[AGR_OWN];
    if (!reviewData) {
      reviewData = {};
    }
    const isSelComt = this.props.isSelCommit;
    const fieldData = prepareReviewersFieldData(reviewData);
    return (
      <div id="agriculture-form" className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Agriculture Lands Owned"
          onFinish={(e) => this.onFinish(e)}
          {...this.props}
        >
          <div className="mg-l">
            <Text type="danger">*</Text> Click Next if family has no Agriculture
            Land.
          </div>
          <Form.List name="agricultureList">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, idx) => (
                    <Row key={idx}>
                      <Col sm={1}>
                        <span>{idx + 1}</span>
                      </Col>
                      <Col sm={22}>
                        <Row key={idx + "_i"} gutter={[16, 8]}>
                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "area"]}
                              fieldKey={[field.fieldKey, "area"]}
                              label="Area"
                              rules={[
                                {
                                  required: true,
                                   pattern: /(^\d*\.?\d*[1-9]+\d*$)|(^[1-9]+\d*\.\d*$)/,
                                  message: "Please enter valid agriculture land area",
                                },
                              ]}
                            >
                              <Input maxLength="5" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "uom"]}
                              fieldKey={[field.fieldKey, "uom"]}
                              label="UOM"
                              rules={[
                                {
                                  required: true,
                                  message: "Select Units of Measurement",
                                },
                              ]}
                            >
                              <Select placeholder="Select" disabled={isSelComt}>
                                <Option key="Acres" value="Acres">
                                  Acres
                                </Option>
                                <Option key="SqYd." value="SqYd.">
                                  SqYd.
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "crop"]}
                              fieldKey={[field.fieldKey, "crop"]}
                              label="Crop"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter crop details",
                                },
                              ]}
                            >
                              <Input maxLength="50" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "cropsPerYear"]}
                              fieldKey={[field.fieldKey, "cropsPerYear"]}
                              label="# Crops per Year"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^([1-9]|10)$/,
                                  message:
                                    "Please enter number of crops per year",
                                },
                              ]}
                            >
                              <Input maxLength="2" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "totalYield"]}
                              fieldKey={[field.fieldKey, "totalYield"]}
                              label="Total Yield (Rs)"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^[0-9][0-9]*$/,//#627
                                  message: "Please enter total yield per year",
                                },
                              ]}
                            >
                              <Input maxLength="8" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          {isSelComt && (
                            <Col sm={6}>
                              <Form.Item
                                {...formItemLayout}
                                name={[field.name, "newTotalYield"]}
                                fieldKey={[field.fieldKey, "newTotalYield"]}
                                label="New Total Yield (Rs)"
                              >
                                <Input maxLength="8" />
                              </Form.Item>
                            </Col>
                          )}
                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "propertyValue"]}
                              fieldKey={[field.fieldKey, "propertyValue"]}
                              label="Asset Value"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^[0-9][0-9]*$/,//#627
                                  message: "Please enter asset value",
                                },
                              ]}
                            >
                              <Input maxLength="10" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          {isSelComt && (
                            <Col sm={6}>
                              <Form.Item
                                {...formItemLayout}
                                name={[field.name, "newPropertyValue"]}
                                fieldKey={[field.fieldKey, "newPropertyValue"]}
                                label="New Asset Value"
                              >
                                <Input maxLength="8" />
                              </Form.Item>
                            </Col>
                          )}
                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "remarks"]}
                              fieldKey={[field.fieldKey, "remarks"]}
                              label="Remarks"
                            >
                              <Input maxLength="100" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={1} style={{ textAlign: "center" }}>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add Agriculture Land
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </ReviewStep>
      </div>
    );
  }
}

export default AgricultureForm;

import React from "react";
import ReviewStep from "./ReviewStep";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import { formItemLayout, OCC_DTLS, VOL_CMNTS } from "../../utils/consts";
import { prepareReviewersFieldData } from "../../utils/util";

class OccupationDetails extends React.Component {
  onFinish = (form) => {
    // console.log("Received values of form:", form);
    this.props.saveDetails(OCC_DTLS, form);
  };
  render() {
    let reviewData = this.props.review.reviewFormData[OCC_DTLS];
    if (!reviewData) {
      reviewData = {};
    }
    const isSelComt = this.props.isSelCommit;
    const fieldData = prepareReviewersFieldData(reviewData);
    return (
      <div className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Occupation Details"
          onFinish={(e) => this.onFinish(e)}
          {...this.props}
        >
          <Row gutter={[16, 8]}>
            <Col sm={12}>
              <Form.Item
                {...formItemLayout}
                name="fPrimary"
                label="Father's Primary Occupation"
                rules={[
                  {
                    required: true,
                    pattern: /^\S[A-Za-z0-9\-_.\s]+$/,
                    message: "Please enter Father's primary occupation",
                  },
                ]}
              >
                <Input maxLength="50" disabled={isSelComt} />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                {...formItemLayout}
                name="fSecondary"
                label="Father's Secondary Occupation"
                rules={[
                  {
                    pattern: /^\S[A-Za-z0-9\s]+$/,
                    message: "Please enter Father's valid secondary occupation",
                  },
                ]}
              >
                <Input maxLength="50" disabled={isSelComt} />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                {...formItemLayout}
                name="fAnnualIncome"
                label="Father's Annual Income"
                rules={[
                  {
                    required: true,
                    min: 3,
                    pattern: /^[0-9][0-9]*$/, //#627
                    message: "Please enter Father's annual income",
                  },
                ]}
              >
                <Input maxLength="8" disabled={isSelComt} />
              </Form.Item>
            </Col>
            {isSelComt && (
              <Col sm={12}>
                <Form.Item
                  {...formItemLayout}
                  name="fNewAnnualIncome"
                  label="Father's New Annual Income"
                  rules={[
                    {
                      pattern: /^[0-9][0-9]*$/,//#627
                      message: "Please enter Father's valid new annual income",
                    },
                  ]}
                >
                  <Input maxLength="8" />
                </Form.Item>
              </Col>
            )}
            <Col sm={12}>
              <Form.Item {...formItemLayout} name="fRemarks" label="Remarks">
                <Input maxLength="100" disabled={isSelComt} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 8]}>
            <Col sm={12}>
              <Form.Item
                {...formItemLayout}
                name="mPrimary"
                label="Mother's Primary Occupation"
                rules={[
                  {
                    required: true,
                    pattern: /^\S[A-Za-z0-9\-_.\s]+$/,
                    message: "Please enter Mother's primary occupation",
                  },
                ]}
              >
                <Input maxLength="50" disabled={isSelComt} />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                {...formItemLayout}
                name="mSecondary"
                label="Mother's Secondary Occupation"
                rules={[
                  {
                    pattern: /^\S[A-Za-z0-9\s]+$/,
                    message: "Please enter Mother's valid secondary occupation",
                  },
                ]}
              >
                <Input maxLength="50" disabled={isSelComt} />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                {...formItemLayout}
                name="mAnnualIncome"
                label="Mother's Annual Income"
                rules={[
                  {
                    required: true,
                    min: 3,
                    pattern: /^[0-9][0-9]*$/,//#627
                    message: "Please enter Mother's annual income",
                  },
                ]}
              >
                <Input maxLength="8" disabled={isSelComt} />
              </Form.Item>
            </Col>
            {isSelComt && (
              <Col sm={12}>
                <Form.Item
                  {...formItemLayout}
                  name="mNewAnnualIncome"
                  label="Mother's New Annual Income"
                  rules={[
                    {
                      pattern: /^[0-9][0-9]*$/,//#627
                      message: "Please enter Mother's  valid new annual income",
                    },
                  ]}
                >
                  <Input maxLength="8" />
                </Form.Item>
              </Col>
            )}
            <Col sm={12}>
              <Form.Item {...formItemLayout} name="mRemarks" label="Remarks">
                <Input maxLength="100" disabled={isSelComt} />
              </Form.Item>
            </Col>
          </Row>

          <Form.List name="othersList">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, idx) => (
                    <Row>
                      <Col sm={23}>
                        <Row key={idx} gutter={[16, 8]}>
                          <Col sm={12}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "oPrimary"]}
                              fieldKey={[field.fieldKey, "oPrimary"]}
                              label="Other’s Primary Occupation"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^\S[A-Za-z0-9\s]+$/,
                                  message: "Please enter occupation",
                                },
                              ]}
                            >
                              <Input maxLength="50" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          <Col sm={12}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "oSecondary"]}
                              fieldKey={[field.fieldKey, "oSecondary"]}
                              label="Other’s Secondary Occupation"
                              rules={[
                                {
                                  pattern: /^\S[A-Za-z0-9\s]+$/,
                                  message: "Please enter a valid secondary occupation",
                                },
                              ]}
                            >
                              <Input maxLength="50" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          <Col sm={12}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "oAnnualIncome"]}
                              fieldKey={[field.fieldKey, "oAnnualIncome"]}
                              label="Other’s Annual Income"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^[0-9][0-9]*$/,//#627
                                  message: "Please enter annual income",
                                },
                              ]}
                            >
                              <Input maxLength="8" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          {isSelComt && (
                            <Col sm={12}>
                              <Form.Item
                                {...formItemLayout}
                                name={[field.name, "oNewAnnualIncome"]}
                                fieldKey={[field.fieldKey, "oNewAnnualIncome"]}
                                label="Others New Annual Income"
                                rules={[
                                  {
                                    pattern: /^[0-9][0-9]*$/,//#627
                                    message: "Please enter valid new annual income",
                                  },
                                ]}
                              >
                                <Input maxLength="8" />
                              </Form.Item>
                            </Col>
                          )}
                          <Col sm={12}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "oRemarks"]}
                              fieldKey={[field.fieldKey, "oRemarks"]}
                              label="Remarks"
                            >
                              <Input maxLength="100" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col sm={1} style={{ textAlign: "center" }}>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined />Add Other Family Members
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
          {/* <Row>
          <Col sm={8}>
              <Form.Item
                {...formItemLayout}
                name="familyCount"
                label="Total Number of People at Home"
                rules={[
                  {
                    required: true,
                    pattern: /^(1[0-5]|[1-9])$/,
                    message: "Please enter total number of people at home",
                  },
                ]}
              >
                <Input maxLength="2" />
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item
                {...formItemLayout}
                name="dependentsCount"
                label="Number of Dependents in the Family"
                rules={[
                  {
                    required: true,
                    pattern: /^(1[0-5]|[1-9])$/,
                    message: "Please enter number of dependents in the family",
                  },
                ]}
              >
                <Input maxLength="2" />
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item
                {...formItemLayout}
                name="familyIncome"
                label="Total Family Annual Income"
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9]+$/,
                    message: "Please enter father's primary occupation",
                  },
                ]}
              >
                <Input maxLength="50" />
              </Form.Item>
            </Col>
          </Row> */}
        </ReviewStep>
      </div>
    );
  }
}

export default OccupationDetails;

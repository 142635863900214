import { LinearProgress } from "@material-ui/core";
import { Spin } from "antd";
import React from "react";
import { connect } from "react-redux";
import InteractionsContainer from "../../common/containers/InteractionsContainer";
import { fetchMyStudentProfile } from "../actions/studAcctActions";

class StudentInteractions extends React.Component {
  componentDidMount() {
    if (this.props.profile.username) {
      this.props.fetchMyStudentProfile(this.props.profile.username);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile.username !== this.props.profile.username) {
      this.props.fetchMyStudentProfile(this.props.profile.username);
    }
  }

  render() {
    return (
      <div>
        {!this.props.manage.currentProfile?.studentProfileId && (
          <div style={{marginTop:'10px'}}> 
            <LinearProgress />
          </div>
        )}
        {this.props.manage.currentProfile?.studentProfileId && (
          <InteractionsContainer {...this.props} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMyStudentProfile(username) {
      dispatch(fetchMyStudentProfile(username));
    },
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    ManagementReducer,
    MasterTablesReducer,
    AppConfigReducer,
    ManagementUploadReducer,
  } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    manage: ManagementReducer,
    masterTable: MasterTablesReducer,
    appConfig: AppConfigReducer,
    mgUploads: ManagementUploadReducer,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentInteractions);

import { Badge, Card, Col, Row } from "antd";
import React from "react";
import {
  UserOutlined,
  BookOutlined,
  ClockCircleOutlined,
  BellOutlined,
  MessageOutlined,
  ContainerOutlined
} from "@ant-design/icons";
import "../styles/styles.css";
import { Link } from "react-router-dom";

class StudAcctDashBoard extends React.Component {
  componentDidMount() {
    this.props.getNotifications();
  }

  getNotificationsCount = () => {
    if (this.props.notif.loading) {
      return '';
    }

    const notifs = this.props.notif.notifications.filter((n) => n.unread === 0);

    return notifs.length;
  };

  render() {
    return (
      <div id="stud-acct-dashboard">
        <Card className="mg-l">
          <Row gutter={16}>
            <Col xs={12} sm={6} lg={4} className="short-cut-tile">
              <Link to="/editProfile">
                <div className="shot-cuts">
                  <span className="short-cut-logo">
                    <UserOutlined />
                  </span>
                  <span>Edit Profile</span>
                </div>
              </Link>
            </Col>
            <Col xs={12} sm={6} lg={4} className="short-cut-tile">
              <Link to="/myacademics">
                <div className="shot-cuts">
                  <span className="short-cut-logo">
                    <BookOutlined />
                  </span>
                  <span>My Academic Profile</span>
                </div>
              </Link>
            </Col>
            <Col xs={12} sm={6} lg={4} className="short-cut-tile">
              <Link to="/notifications">
                <div className="shot-cuts">
                  <span className="short-cut-logo">
                    <Badge count={this.getNotificationsCount()}>
                      <BellOutlined />
                    </Badge>
                  </span>
                  <span>Notifications</span>
                </div>
              </Link>
            </Col>
            <Col xs={12} sm={6} lg={4} className="short-cut-tile">
              <Link to="/interactions">
                <div className="shot-cuts">
                  <span className="short-cut-logo">
                    <MessageOutlined />
                  </span>
                  <span>Interactions</span>
                </div>
              </Link>
            </Col>
            <Col xs={12} sm={6} lg={4} className="short-cut-tile">
              <Link to="/requests">
                <div className="shot-cuts">
                  <span className="short-cut-logo">
                  <ContainerOutlined />
                  </span>
                  <span>Requests</span>
                </div>
              </Link>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

export default StudAcctDashBoard;

import {
  Button,
  Card,
  message,
  notification,
  Modal,
} from "antd";
import React from "react";
import { translate } from "../../../base/actions/translate";
import { IMAGE_TYPES } from "../../../student/util/const";
import {
  ALL_SURVEY_UPLOAD_CAT,
  NON_REQ_IMAGES,
} from "../../utils/consts";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getMaxUploadCount } from "../../utils/util";
import UploadByCat from "./UploadByCat";

class UploadPhotos extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.
  // }

  state = {
    cat: "",
    file: {},
    showValidation: false,
    pending: [],
  };

  onFinish = (form) => {
    if (!this.isValid()) {
      return;
    }
    const application = this.props.review.reviewDetails.application;
    const { deletedImages, surveyImages } = this.props.review;
    const draftIds = [];
    for (const img of surveyImages) {
      if (img.status === "P") {
        draftIds.push(img.uploadId);
      }
    }
    const delIds = [];
    for (const img of deletedImages) {
      delIds.push(img.uploadId);
    }
    if (draftIds.length > 0) {
      this.props.approveDrafts(application.applicationId, draftIds);
    } else {
      this.props.next();
    }

    if (delIds.length > 0) {
      this.props.deleteFiles(delIds);
    }
  };

  isImageFile = (file) => {
    if (IMAGE_TYPES.indexOf(file.type) > -1) {
      return true;
    }
    return false;
  };

  isValid = () => {
    const surveyCatImages = this.props.review.surveyCatImages;
    const pending = [];
    let isValid = true;
    for (const i in surveyCatImages) {
      if (surveyCatImages[i].length === 0 && !NON_REQ_IMAGES.includes(i)) {
        isValid = false;
        pending.push(translate(i));
      }
    }

    if (!isValid) {
      notification["error"]({
        message:
          "Please upload photos for the following category : " +
          pending.join(", "),
      });
      // this.setState({pending: pending, showValidation: !isValid});
      // const that = this;
      // setTimeout(() => {
      //   that.setState({pending: [], showValidation: false});
      // }, 2000);
    }

    return isValid;
  };

  setFiles = (e) => {
    // console.log("files", this.files);
    const file = document.getElementById("myFileB").files[0];
    if (!file) {
      this.setState({ cat: "" });
      return;
    }
    const isLt500KB = file.size / 1024 < 500;

    if (!this.isImageFile || !isLt500KB) {
      message.error("Please upload an image file less than 500 KB.");
      this.setState({ cat: "" });
      document.getElementById("myFileB").value = "";
      return;
    }

    const { cat } = this.state;

    const maxCount = getMaxUploadCount(cat);
    const curCount = this.props.review.surveyCatImages[cat];
    if (curCount < maxCount) {
      this.uploadDraft(file, cat);
    } else {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title:
          "Limit for the number of images to be upload for this category is exceeded. Press Ok to remove the first and upload this image.",
        onOk() {
          this.uploadDraft(file, cat);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }
  };

  uploadDraft = (file, cat) => {
    const application = this.props.review.reviewDetails.application;
    // Save the new image uploaded as a draft.
    this.props.draftUpload(application.applicationId, file, cat);
    this.setState((state) => ({
      cat: "",
    }));
    document.getElementById("myFileB").value = "";
  };

  handleUpload = (value) => {
    // console.log("---------", value);
    if (value) {
      this.setState({ cat: value });
      document.getElementById("myFileB").click();
    }
  };

  checkCount = (cat) => {
    let clsName = "primary";
    const surveyCatImages = this.props.review.surveyCatImages;
    for (const i in surveyCatImages) {
      if (surveyCatImages[i].length === 0 && !NON_REQ_IMAGES.includes(i)) {
        clsName = "secondary";
      }
    }

    return clsName;
  };

  getDisplayInfo = (cat) => {
    const html = [<span>{translate(cat)}</span>];

    const max = getMaxUploadCount(cat);
    let count = 0;
    if (this.props.review.surveyCatImages[cat]) {
      count = this.props.review.surveyCatImages[cat].length;
    }

    html.push(<span>({count + "/" + max})</span>);

    return html;
  };

  render() {
    const application = this.props.review.reviewDetails.application;
    const { surveyImages } = this.props.review;
    return (
      <div className="reviewer-form">
        <Card>
          <UploadByCat
            categories={ALL_SURVEY_UPLOAD_CAT}
            imageList={surveyImages}
            year={application.year}
            uploadSurveyImg={this.props.uploadSurveyImg}
            application={application}
            addToDeleteFiles={this.props.addToDeleteFiles}
            updateImageAngle={this.props.updateImageAngle}
          />
        </Card>

        <div className="steps-action">
          <Button
            style={{ margin: "0 8px" }}
            onClick={(e) => this.props.prev()}
          >
            Previous
          </Button>
          <Button type="primary" onClick={(e) => this.onFinish()}>
            Next
          </Button>
        </div>
        <input
          type="file"
          id="myFileB"
          style={{ display: "none" }}
          onChange={this.setFiles}
        ></input>
      </div>
    );
  }
}

export default UploadPhotos;

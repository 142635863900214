import React from "react";
import { Link } from "react-router-dom";

export const te = {
  username: "యూజర్ పేరు",
  password: "పాస్వర్డ్",
  loginError: "మీరు నమోదు చేసిన వినియోగదారు పేరు లేదా పాస్‌వర్డ్ తప్పు.",
  welcome: "స్వాగత",
  applocationFormMessage:
    "దరఖాస్తు ఫారమ్ నింపే ముందు, దయచేసి స్టూడెంట్ ఐడెంటిఫికేషన్ ఆథరైజేషన్ ఫారమ్‌ను డౌన్‌లోడ్ చేసి, దాన్ని పూరించండి మరియు ఫారమ్‌లో హెడ్‌మాస్టర్ సంతకం మరియు పాఠశాల స్టాంప్ తీసుకోండి.",
  scholorshipSelectionMessage:
    "దరఖాస్తు చేయడానికి దయచేసి క్రింది స్కాలర్‌షిప్ ప్రోగ్రామ్‌ల నుండి ఎంచుకోండి",
  sampoornaSikshaInfo:
    "ప్రతి సంవత్సరం 100 మందికి పైగా విద్యార్థులకు ఇంటర్మీడియట్ మరియు గ్రాడ్యుయేట్ విద్యకొరకు పూర్తి స్కాలర్‌షిప్ ఇవ్వబడుతుంది మరియు 30 మంది విద్యార్థులకు డిప్లొమా విద్యకొరకు స్కాలర్‌షిప్ ఇవ్వబడుతుంది. జిల్లా పరిషత్ హై స్కూల్, గవర్నమెంట్ హై స్కూల్, తెలంగాణ మోడల్ స్కూల్, తెలంగాణ మైనారిటీ రెసిడెన్షియల్ స్కూల్ (గురుకులం) మరియు మహాత్మా జ్యోతిబా ఫూలే పాఠశాలలు లో చదువుతున్న 10వ తరగతి విద్యార్థులకు మాత్రమే ఈ స్కాలర్‌షిప్ లభిస్తుంది. ఆసక్తి ఉన్న విద్యార్థులు మేధా ఛారిటబుల్ ట్రస్ట్ నిర్వహించిన స్కాలర్‌షిప్ పరీక్షకు ఇక్కడ దరఖాస్తు చేసుకోవచ్చు.",
  nipunyaNirman:
    "ప్రతి సంవత్సరం 500 మంది విద్యార్థులకు 6 నెలల పాటు సాంకేతిక నైపుణ్య శిక్షణతో పాటు ప్లేస్‌మెంట్ సేవలు ఉచితంగా ఇవ్వబడుతున్నాయి. సెంట్రల్ ఇన్స్టిట్యూట్ ఆఫ్ ప్లాస్టిక్స్ అండ్ ఇంజనీరింగ్ టెక్నాలజీ (CIPET), స్వామి రామానంద తీర్థ రూరల్ ఇన్స్టిట్యూట్ (SRTRI), బాయ్స్ టౌన్ ఐటిఐ, సెంట్రల్ ఇన్స్టిట్యూట్ ఆఫ్ టూల్ డిజైన్ (CITD) మరియు నేషనల్ స్మాల్ ఇండస్ట్రీస్ కార్పొరేషన్ - టెక్నికల్ సర్వీసెస్ సెంటర్ల (NSIC-TSC) లో సర్టిఫికేట్ కోర్సులు నిర్వహించబడుతున్నాయి. ఈ స్కాలర్‌షిప్ భారతదేశంలో ఎక్కడైనా 10వ తరగతి ప్రయత్నించిన, 10వ తరగతి ఉత్తీర్ణత, ఇంటర్మీడియట్ ఉత్తీర్ణత, ఐటిఐ పూర్తి చేసిన లేదా డిప్లొమా పూర్తి చేసిన అభ్యర్థులకు మాత్రమే అందుబాటులో ఉంటుంది. ఆసక్తి గల అభ్యర్థులు సర్టిఫికెట్ కోర్సు కోసం దరఖాస్తు చేసుకోవచ్చు.",
  applicationPreviewHeading:"దయచేసి దారకస్తు‌ను సమర్పించే ముందు దిగువ వివరాలను ధృవీకరించండి. నమోదు చేసిన ఏవైనా వివరాలను మార్చడానికి, పేజీ దిగువన ఉన్న ఎడిట్ (Edit) బటన్‌ను క్లిక్ చేయండి.",
  applicationPreviewNote:'గమనిక:',
  applicationPreviewNoteCont:'ఇది మేధా ఛారిటబుల్ ట్రస్ట్ హాల్ టికెట్ కాదు.',
  studentName: "విద్యార్థి పేరు",
  studentSurName: "ఇంటి పేరు",
  fatherName: "తండ్రి పేరు",
  mothersName: "తల్లి పేరు",
  dateOfBirth: "పుట్టిన తేది",
  alternatePhone: "ప్రత్యామ్నాయ ఫోన్ నంబర్",
  address: "విద్యార్థి ఇంటి చిరునామా",
  casteId: "కులం",
  gender: "లింగం",
  ninthGrade: "9వ తరగతి గ్రేడ్",
  headmasterName: "ప్రధానోపాధ్యాయుడి పేరు",
  headmasterPhone: "ప్రధానోపాధ్యాయుడి ఫోన్ నంబర్",
  district: "పాఠశాల జిల్లా",
  mandal: "పాఠశాల మండలం",
  school: "పాఠశాల పేరు",
  examCenter: "పరీక్షా కేంద్రం",
  examCenterDisc:
    "విద్యార్థులు ఏ జిల్లాలోనైనా పరీక్షా కేంద్రాన్ని ఎంచుకోవచ్చు. ఒకసారి ఎంపిక చేసిన పరీక్షా కేంద్రం మార్చబడదు.",
  uploadHeading: "కింది పత్రాలను అప్‌లోడ్ చేయండి.",
  studentPassportPhoto: "విద్యార్థి పాస్పోర్ట్ సైజు ఫోటో",
  StudentIdentificationForm: "స్టూడెంట్ ఐడెంటిఫికేషన్ ఆథరైజేషన్ ఫారం",
  ninthMarksMemoFront: "9వ తరగతి మార్కస్  మెమో - ముందు వైపు",
  ninthMarksMemoBack: "9వ తరగతి మార్కస్  మెమో - వెనుక వైపు",

  appNo: "మీ దరఖాస్తు సంఖ్య :",
  appNoView: "దరఖాస్తు సంఖ్య :",

  addSchoolTextpart1:
    "మీ పాఠశాల జాబితా చేయకపోతే, దయచేసి మీ పాఠశాలను జోడించడానికి ఇక్కడ క్లిక్ చేయండి.",
  addSchoolTextpart2: "ఇక్కడ క్లిక్ చేసి",
  addSchoolTextpart3: "మీ పాఠశాలను జోడించండి.",

  studentNameError:
    "దయచేసి విద్యార్థి పేరును నమోదు చేయండి. వర్ణమాలలు మాత్రమే అనుమతించబడతాయి.",
  studentSurNameError:
    "దయచేసి విద్యార్థి ఇంటి పేరును నమోదు చేయండి. వర్ణమాలలు మాత్రమే అనుమతించబడతాయి.",
  fatherNameError:
    "దయచేసి విద్యార్థి తండ్రి పేరును నమోదు చేయండి. వర్ణమాలలు మాత్రమే అనుమతించబడతాయి.",
  mothersNameError:
    "దయచేసి తల్లి పేరు నమోదు చేయండి. వర్ణమాలలు మాత్రమే అనుమతించబడతాయి",
  dobError: "దయచేసి మీ పుట్టిన తేదీని ఎంచుకోండి.",
  alternativePhoneNumberError:
    "దయచేసి ప్రత్యామ్నాయ ఫోన్ నంబర్‌ను నమోదు చేయండి.",
  headMasterPhoneNumberError:
    "దయచేసి ప్రధానోపాధ్యాయుడి ఫోన్ నంబర్‌ను నమోదు చేయండి.",
  altNumStudentNum:
    "విద్యార్థుల ప్రత్యామ్నాయ ఫోన్ నంబర్ రిజిస్టర్డ్ మొబైల్ నంబర్‌కు భిన్నంగా ఉండాలి.",
  altNumHeadMNum:
    "హెడ్‌మాస్ట్ నంబర్ రిజిస్టర్డ్ మొబైల్ నంబర్‌కు భిన్నంగా ఉండాలి.",
  studentHomeAddressError: "దయచేసి విద్యార్థి ఇంటి చిరునామాను నమోదు చేయండి.",
  castCategoryError: "దయచేసి మీ కులాన్ని ఎంచుకోండి.",
  genderError: "దయచేసి మీ లింగాన్ని ఎంచుకోండి",
  ninthGradeError: "దయచేసి మీ 9వ తరగతి గ్రేడ్‌ను ఎంచుకోండి.",
  mediumError: "దయచేసి మీ విద్యా మాధ్యమం ఎంచుకోండి.",
  medium: "విద్యా మాధ్యమం.",
  headmasterNameError:
    "దయచేసి ప్రధానోపాధ్యాయుడి పేరును నమోదు చేయండి. వర్ణమాలలు మాత్రమే అనుమతించబడతాయి.",
  headmasterPhoneNoError:
    "దయచేసి ప్రధానోపాధ్యాయుడి ఫోన్ నంబర్‌ను నమోదు చేయండి.",
  schoolDistrictError: "దయచేసి మీ పాఠశాల జిల్లాను ఎంచుకోండి.",
  schoolMandalError: "దయచేసి మీ పాఠశాల మండలాన్ని ఎంచుకోండి.",
  schoolNameError: "దయచేసి మీ పాఠశాల పేరును ఎంచుకోండి",
  examCenterError: "దయచేసి పరీక్షా కేంద్రాన్ని ఎంచుకోండి.",
  studentPassportPhotoError:
    "దయచేసి విద్యార్థి పాస్‌పోర్ట్ సైజు ఫోటోను అప్‌లోడ్ చేయండి. ఫైల్ jpeg, jpg లేదా png ఫార్మాట్లలో ఉండాలి మరియు గరిష్ట పరిమాణం 500 KB మాత్రమే ఉండాలి.",
  StudentIdentificationFormError:
    "దయచేసి స్టూడెంట్ ఐడెంటిఫికేషన్ ఆథరైజేషన్ ఫారమ్‌ను అప్‌లోడ్ చేయండి. ఫైల్ jpeg, jpg, png లేదా pdf ఫార్మాట్లలో ఉండాలి మరియు గరిష్ట పరిమాణం 500 KB మాత్రమే ఉండాలి.",
  ninthMarksMemoFrontError:
    "దయచేసి 9 వ తరగతి మార్క్స్ మెమో - ముందు వైపు అప్‌లోడ్ చేయండి. ఫైల్ jpeg, jpg, png లేదా pdf ఫార్మాట్లలో ఉండాలి మరియు గరిష్ట పరిమాణం 500 KB మాత్రమే ఉండాలి మార్క్స్ మెమోలో ప్రధానోపాధ్యాయుడి సంతకం మరియు పాఠశాల స్టాంప్ ఉండాలి.",
  ninthMarksMemoBackError:
    "దయచేసి 9 వ తరగతి మార్క్స్ మెమో - వెనుక వైపు అప్‌లోడ్ చేయండి. ఫైల్ jpeg, jpg, png లేదా pdf ఫార్మాట్లలో ఉండాలి మరియు గరిష్ట పరిమాణం 500 KB మాత్రమే ఉండాలి మార్క్స్ మెమోలో ప్రధానోపాధ్యాయుడి సంతకం మరియు పాఠశాల స్టాంప్ ఉండాలి.",
  applicationCompleteHead: "మీ అప్లికేషన్ విజయవంతంగా స్వీకరించబడింది.",
  applicationCompleteMessage:
    "మీ దరఖాస్తును ప్రాసెస్ చేసిన తర్వాత మీ రిజిస్టర్డ్ మొబైల్ నంబర్‌కు మీ అప్లికేషన్ స్థితిని తెలియజేస్తాము.",

  FaqMainHeading:
    "క్రింద తరచుగా అడిగే తరచుగా అడిగే అడిగే ప్రశ్నలు మేధా ఛారిటబుల్ ట్రస్ట్స్కాలర్షిప్ పరీక్ష ద తరచుగా అడిగే రఖాసు%ప్రక్రియ, హాల్ టికెట్, పరీక్షల తయారీ మరియు ప్రవేశ ప్రక్రియ కోసం సంబంధించినవి.",
  FAQHeading1: "దరఖాస్తు ప్రక్రియ మరియు హాల్ టికెట్‌పై ప్రశ్నలు",

  Faq1:
    "మేధా ఛారిటబుల్ ట్రస్ట్ స్కాలర్‌షిప్ పరీక్షకు ఎవరు దరఖాస్తు చేసుకోవచ్చు?",
  Faqans1:
    "ప్రస్తుతం 10 వ తరగతి చదువుతున్న తెలంగాణ ప్రభుత్వ పాఠశాలల విద్యార్థులు ఇంటర్మీడియట్, గ్రాడ్యుయేట్ కాలేజీ స్కాలర్‌షిప్ కోసం దరఖాస్తు చేసుకోవచ్చు. జిల్లా పరిషత్ హై స్కూల్, గవర్నమెంట్ హై స్కూల్, తెలంగాణ మోడల్ స్కూల్, తెలంగాణ మైనారిటీ రెసిడెన్షియల్ స్కూల్ (గురుకులం) మరియు మహాత్మా జ్యోతిబా ఫూలే పాఠశాలలు విద్యార్థులకు మాత్రమే మేధా స్కాలర్‌షిప్‌లు లభిస్తాయి. ప్రైవేట్ పాఠశాల విద్యార్థులు దరఖాస్తు చేయలేరు మరియు మేధా ఛారిటబుల్ ట్రస్ట్ స్కాలర్‌షిప్‌లు ఇవ్వరు.",
  Faq2: "నేను నా పాస్వర్డ్ను మర్చిపోయాను. నేను ఎలా లాగిన్ అవ్వాలి?",
  Faqans2:
    "పాస్‌వర్డ్ మర్చిపోయారా అనే దానిపై క్లిక్ చేసి, మీ పాస్‌వర్డ్‌ను మీ రిజిస్టర్డ్ మొబైల్ నంబర్‌లో ఎస్ఎంఎస్ ద్వారా స్వీకరించడానికి వివరాలను పూరించండి.",
  Faq3: "నేను ఒక మొబైల్ నంబర్ ఉన్న బహుళ విద్యార్థుల కోసం దరఖాస్తు చేయవచ్చా?",
  Faqans3:
    "1 మొబైల్ నంబర్‌తో 1 దరఖాస్తు మాత్రమే సమర్పించవచ్చు. అప్లికేషన్ స్థితికి సంబంధించి సందేశాలను పంపడానికి ఈ రిజిస్టర్డ్ మొబైల్ నంబర్ ఉపయోగించబడుతుంది.",
  Faq4:
    "గత సంవత్సరం దరఖాస్తు చేయడానికి మేము నా మొబైల్ నంబర్‌ను ఉపయోగించాము, ఈ సంవత్సరం మళ్లీ అదే నంబర్‌తో దరఖాస్తు చేయవచ్చా?",
  Faqans4:
    "అవును, మీరు గత సంవత్సరం ఉపయోగించిన అదే మొబైల్ నంబర్‌తో దరఖాస్తు చేసుకోవచ్చు.",
  Faq5: "నా పాఠశాల జాబితా చేయబడలేదు. నేనేం చేయాలి?",
  Faqans5:
    "జిల్లా పరిషత్ హై స్కూల్, గవర్నమెంట్ హై స్కూల్, తెలంగాణ మోడల్ స్కూల్, తెలంగాణ మైనారిటీ రెసిడెన్షియల్ స్కూల్ (గురుకులం) మరియు మహాత్మా జ్యోతిబా ఫూలే పాఠశాలలు విద్యార్థులకు మాత్రమే మేధా సంపూర్ణ సిక్ష స్కాలర్‌షిప్ లభిస్తుంది. మీ పాఠశాల పై వాటిలో ఒకటి అయితే, దరఖాస్తు రూపంలో, మీ పాఠశాల వివరాలను పూరించడానికి కొత్త పాఠశాలను జోడించుపై క్లిక్ చేయండి. మేము మీ పాఠశాలను ధృవీకరిస్తాము మరియు చేర్చుతాము.",
  Faq6: "నా పాఠశాల నుండి ఎంత మంది విద్యార్థులు దరఖాస్తు చేసుకోవచ్చు?",
  Faqans6:
    "ఎంతమంది విద్యార్థులు దరఖాస్తు చేసుకోవచ్చు కాని మేధా ఛారిటబుల్ ట్రస్ట్ గరిష్టంగా 10 మంది విద్యార్థులకు హాల్ టికెట్లను జారీ చేస్తుంది.",
  Faq7:
    "మా పాఠశాల బలం ఎక్కువగా ఉంది, ఎక్కువ మంది విద్యార్థులు దరఖాస్తు చేసుకోవచ్చు మరియు హాల్ టిక్కెట్లు పొందగలరా?",
  Faqans7:
    "పాఠశాల బలం మరియు పాఠశాల నుండి దరఖాస్తుల సంఖ్యతో సంబంధం లేకుండా మేధా ఛారిటబుల్ ట్రస్ట్ గరిష్టంగా 10 మంది విద్యార్థులకు హాల్ టికెట్లను జారీ చేస్తుంది.",
  Faq8:
    "అధిక బలం ఉన్న పాఠశాలలకు ఎక్కువ దరఖాస్తులు, తక్కువ బలం ఉన్న పాఠశాలలకు తక్కువ దరఖాస్తులు ఇవ్వడానికి మన మండలంలోని పాఠశాలలు కలిసి పనిచేయగలవా?",
  Faqans8:
    "మేధా ఛారిటబుల్ ట్రస్ట్ పాఠశాలలకు గరిష్టంగా 10 హాల్ టిక్కెట్లను జారీ చేస్తుంది మరియు విద్యార్థుల బలాన్ని మిళితం చేయడానికి పాఠశాలలను అనుమతించదు, ఒక పాఠశాలకు ఎక్కువ హాల్ టిక్కెట్లు మరియు ఇతరులకు తక్కువ.",
  Faq9: "నా జిల్లాకు ఏ పరీక్షా కేంద్రాన్ని ఎంచుకోవాలి?",
  Faqans9:
    "ఏదైనా విద్యార్థులు ఏ పరీక్షా కేంద్రం నుంచైనా పరీక్ష రాయవచ్చు. పరీక్షా కేంద్రం ఎంపికకు ఎటువంటి పరిమితులు లేవు.",
  Faq10: "ఏ పత్రాలను అప్‌లోడ్ చేయాలి?",
  Faqans10:
    "స్టూడెంట్ పాస్పోర్ట్ సైజు ఫోటో, స్టూడెంట్ ఐడెంటిఫికేషన్ ఆథరైజేషన్ ఫారం, 9 వ క్లాస్ మార్క్స్ మెమో ఫ్రంట్ సైడ్ మరియు బ్యాక్ సైడ్.",
  Faq11: "మేము పత్రాలను అప్‌లోడ్ చేయలేము. మనం ఏం చెయ్యాలి?",
  Faqans11:
    "విద్యార్థుల పాస్‌పోర్ట్ సైజు ఫోటో jpg, tiff లేదా png ఫార్మాట్లలో మాత్రమే ఉండాలి. స్టూడెంట్ ఐడెంటిఫికేషన్ ఆథరైజేషన్ ఫారం, 9 వ తరగతి మార్క్స్ మెమో - ఫ్రంట్ సైడ్ మరియు 9 వ క్లాస్ మార్కులు - బ్యాక్ సైడ్ జెపిజి, టిఫ్, పిఎన్జి లేదా పిడిఎఫ్ ఫార్మాట్లలో మాత్రమే ఉండాలి. అన్ని ఫైళ్లు గరిష్టంగా 500 KB పరిమాణంలో ఉండాలి.",
  Faq12:
    "మాకు పాఠశాలలో స్కానర్ లేదు, నేను చిత్రాన్ని తీయవచ్చు మరియు దరఖాస్తు చేయవచ్చా?",
  Faqans12: "చిత్ర నాణ్యత మంచిగా ఉంటే, మేము దానిని అంగీకరిస్తాము.",
  Faq13: "స్టూడెంట్ ఐడెంటిఫికేషన్ ఆథరైజేషన్ ఫారం ఎక్కడ ఉంది?",
  Faqans13:
    "ఎడమ మెనూలోని డౌన్‌లోడ్‌ల నుండి ఫైల్‌ను డౌన్‌లోడ్ చేయండి. అప్‌లోడ్ చేయడానికి ముందు ఈ ఫారమ్‌లో హెడ్‌మాస్టర్ సంతకం మరియు పాఠశాల స్టాంప్ అవసరం.",
  Faq14: "నాకు 9 వ తరగతి మార్కులు లేవు, నేను ఏమి చేయాలి?",
  Faqans14:
    "పాఠశాల ప్రధానోపాధ్యాయుడు విద్యార్థుల 9 వ తరగతి మార్కులను శ్వేతపత్రంపై వ్రాయవచ్చు. పాఠశాల ప్రధానోపాధ్యాయుడు సంతకం చేసి స్టాంప్ చేస్తే మేము దానిని అంగీకరిస్తాము.",
  Faq15:
    "నేను తప్పు వివరాలను నమోదు చేసాను మరియు తప్పు ఫైళ్ళను అప్‌లోడ్ చేసాను. నేనేం చేయాలి?",
  Faqans15:
    "చివరి తేదీ వరకు దరఖాస్తు సమర్పించే వరకు మీరు మీ వివరాలను లాగిన్ చేసి సరిదిద్దవచ్చు మరియు సరైన ఫైళ్ళను అప్‌లోడ్ చేయవచ్చు. మీరు మీ దరఖాస్తును సరిదిద్దవచ్చు అప్లికేషన్‌పై క్లిక్ చేసి, ఆపై ఎడమ మెనూ బార్‌లోని ఎడిట్ ఫారమ్‌లో క్లిక్ చేయండి. దరఖాస్తు చివరి తేదీ ముగిస్తే, మేధా ఛారిటబుల్ ట్రస్ట్ కార్యాలయాన్ని సంప్రదించండి.",
  Faq16: "దరఖాస్తు చివరి తేదీ పొడిగించబడుతుందా?",
  Faqans16:
    "చివరి తేదీని పొడిగించినట్లయితే, మేము దానిని మా వెబ్‌సైట్‌లో తెలియజేస్తాము.",
  Faq17: "దరఖాస్తు రూపంలో సాంకేతిక సమస్యలు ఉంటే నేను ఏమి చేయాలి?",
  Faqans17: "దయచేసి మేధా ఛారిటబుల్ ట్రస్ట్ కార్యాలయానికి కాల్ చేయండి.",
  Faq18: "స్కాలర్‌షిప్ కోసం నా దరఖాస్తు ఎందుకు తిరస్కరించబడింది?",
  Faqans18:
    "9 వ తరగతి మార్కులు లేదా ప్రధానోపాధ్యాయుల సిఫారసు ఆధారంగా స్కాలర్‌షిప్ పరీక్షకు ప్రతి పాఠశాలకు టాప్ 10 దరఖాస్తులు మాత్రమే ఎంపిక చేయబడతాయి, మిగిలిన దరఖాస్తులు తిరస్కరించబడ్డాయి. తప్పు వివరాలతో ఉన్న దరఖాస్తులు తిరస్కరించబడతాయి. అర్హత లేని పాఠశాలల నుండి వచ్చిన దరఖాస్తులు కూడా తిరస్కరించబడతాయి.",
  Faq19: "నా పరీక్షా హాల్ టికెట్ ఎక్కడ ఉంది?",
  Faqans19:
    "మీ దరఖాస్తు అంగీకరించబడితే, మీరు మీ రిజిస్టర్డ్ మొబైల్ నంబర్‌లో సందేశాన్ని అందుకుంటారు. మేధా ఛారిటబుల్ ట్రస్ట్ వెబ్‌సైట్‌లోకి లాగిన్ అవ్వండి మరియు ఎడమ హాల్ మెనూ బార్‌లోని డౌన్‌లోడ్ బటన్ నుండి మీ హాల్ టికెట్‌ను డౌన్‌లోడ్ చేసుకోండి.",

  FAQHeading2: "పరీక్షల తయారీ మరియు ప్రవేశ ప్రక్రియపై ప్రశ్నలు",

  Faq20: "పరీక్షా విధానం ఏమిటి?",
  Faqans20:
    "మేధా ఛారిటబుల్ ట్రస్ట్ స్కాలర్‌షిప్ పరీక్షలో 150 ఆబ్జెక్టివ్ టైప్ ప్రశ్నలు ఉన్నాయి. ప్రతి ప్రశ్నకు 4 జవాబు ఎంపికలు ఉంటాయి మరియు విద్యార్థి సరైన జవాబును జవాబు పత్రంలో గుర్తించాలి. ఎస్‌ఎస్‌సి స్థాయి, సాధారణ పరిజ్ఞానం ఉన్న 6 సబ్జెక్టులపై పరీక్ష ఉంటుంది. గణితంలో 40 ప్రశ్నలు, భౌతిక శాస్త్రంలో 40 ప్రశ్నలు, జీవశాస్త్రంలో 40 ప్రశ్నలు, సాంఘిక శాస్త్రాలలో 10 ప్రశ్నలు, ఆంగ్లంలో 10 ప్రశ్నలు మరియు ఆప్టిట్యూడ్‌లో 10 ప్రశ్నలు. ప్రతి ప్రశ్న 3 మార్కులను కలిగి ఉంటుంది మరియు తప్పు సమాధానం -1 (నెగటివ్) మార్కులను కలిగి ఉంటుంది మరియు ఒక ప్రశ్నకు సమాధానం ఇవ్వకపోవడం లేదా బహుళ సమాధానాలను గుర్తించడం 0 అవుతుంది.",
  Faq21:
    "పరీక్ష కోసం ప్రాక్టీస్ చేయడానికి మోడల్ ప్రశ్నపత్రాలు లేదా మునుపటి సంవత్సరం ప్రశ్నపత్రాలను పొందగలమా?",
  Faqans21:
    "10 ప్రశ్నలతో కూడిన మోడల్ ప్రశ్నపత్రం హాల్ టికెట్‌తో పాటు డౌన్‌లోడ్ చేసుకోవడానికి అందుబాటులో ఉంటుంది.",
  Faq22: "పరీక్ష కోసం మనం ఎలా ప్రాక్టీస్ చేయాలి?",
  Faqans22:
    "పరీక్షా ప్రశ్నలు తెలంగాణ పాఠశాలల ఎస్‌ఎస్‌సి స్థాయిపై ఆధారపడి ఉంటాయి. 10 వ తరగతి ఎస్‌ఎస్‌సి పాఠ్య పుస్తకాలను అధ్యయనం చేస్తే సరిపోతుంది.",
  Faq23: "పరీక్ష వ్యవధి ఎంత?",
  Faqans23:
    "150 ఆబ్జెక్టివ్ రకం ప్రశ్నలకు సమాధానం ఇవ్వడానికి 2 గంటల 30 నిమిషాల పాటు పరీక్ష ఉంటుంది.",
  Faq24: "పరీక్ష తేదీ ఎంత?",
  Faqans24:
    "ప్రతి సంవత్సరం జనవరిలో పరీక్ష తేదీని ప్రకటించి, తెలంగాణ ఎస్‌ఎస్‌సి పరీక్షల తర్వాత నిర్వహిస్తారు.",
  Faq25: "పరీక్ష ఏ సమయంలో ప్రారంభమవుతుంది?",
  Faqans25: "ఉదయం 9 గంటలకు పరీక్ష ప్రారంభమవుతుంది.",
  Faq26: "పరీక్షా కేంద్రానికి నాతో ఏమి తీసుకెళ్లగలను?",
  Faqans26:
    "ఎస్‌ఎస్‌సి ఎగ్జామ్ హాల్ టికెట్, మేధా ఛారిటబుల్ ట్రస్ట్ స్కాలర్‌షిప్ ఎగ్జామ్ హాల్ టికెట్ తీసుకురండి. పెన్నులు, పెన్సిల్స్, ఎరేజర్స్, పెన్సిల్ షార్పనర్ మరియు ఎగ్జామ్ ప్యాడ్ వంటి స్టేషనరీలను అనుమతిస్తారు. కాలిక్యులేటర్లు మరియు మొబైల్ ఫోన్ వంటి ఎలక్ట్రానిక్ పరికరాలు మరియు పాఠశాల సంచులు, హ్యాండ్‌బ్యాగులు, పర్స్, వాలెట్లు లేదా ప్లాస్టిక్ కవర్లు వంటి అన్ని వ్యక్తిగత వస్తువులను పరీక్షా కేంద్రం లోపల అనుమతించరు. మేము పరీక్షా కేంద్రం లోపల నిల్వ సౌకర్యాన్ని అందించము.",
  Faq27: "నేను పరీక్ష రాశాను కాని ఎంపిక కాలేదు. నా మార్కులు ఏమిటి?",
  Faqans27:
    "స్కాలర్‌షిప్‌లను అందించడానికి మేము తెలంగాణ రాష్ట్రంలోని టాపర్‌లను మాత్రమే ఎంచుకుంటాము. మేము ఏ విద్యార్థుల గుర్తులను వెల్లడించము.",
  Faq28: "నేను పరీక్షలో ఎంపికయ్యాను, ఇప్పుడు నేను ఏమి చేయాలి?",
  Faqans28: "తదుపరి ప్రవేశ దశల కోసం మేము మిమ్మల్ని సంప్రదిస్తాము.",
  Faq29: "మేధా ఛారిటబుల్ ట్రస్ట్ ఏ విద్యకు మద్దతు ఇస్తుంది?",
  Faqans29:
    "మేధా సంపూర్ణ సిక్షలో విద్యార్థులు ఇంటర్మీడియట్ కోసం ఎంపిసి, బిపిసి, ఎంఇసిలను ఎంచుకోవచ్చు. వారు ప్రవేశం పొందే చోట గ్రాడ్యుయేట్ ప్రోగ్రామ్ కోసం 20 కి పైగా కోర్సులను ఎంచుకోవచ్చు. ఎంబిబిఎస్, బివిఎంఎస్, బిడిఎస్, బిఎస్సి నర్సింగ్, బిఎస్సి అగ్రికల్చర్, బిఇ, బిటెక్, ఇంటిగ్రేటెడ్ కోర్సులు (బిటెక్ మరియు పిజి) బిఎస్సి, సిఎ వంటి విస్తృత గ్రాడ్యుయేట్ ప్రోగ్రామ్‌లను మేము స్పాన్సర్ చేస్తాము. సిపెట్ మరియు సిఐటిడిలో డిప్లొమా ప్రోగ్రామ్‌లకు కూడా మేము మద్దతు ఇస్తున్నాము.",
  Faq30: "",
  Faqans30: "",

  AddSchoolHeading:
    "తెలంగాణ రాష్ట్రంలోని జిల్లా పరిషత్ ఉన్నత పాఠశాలలు, ప్రభుత్వ ఉన్నత పాఠశాలలు, తెలంగాణ మోడల్ పాఠశాలలు, తెలంగాణ మైనారిటీ నివాస పాఠశాలలు (గురుకులం) మరియు మహాత్మా జ్యోతిబా ఫూలే పాఠశాలలు విద్యార్థులకు మేధా సంపూర్ణ సిక్షా స్కాలర్‌షిప్ అందుబాటులో ఉంది. మీ పాఠశాల ఈ జాబితాలో ఉంటే మరియు దరఖాస్తు రూపంలో జాబితా చేయకపోతే, దయచేసి మీ పాఠశాల వివరాలను నమోదు చేయండి. మీ పాఠశాల వివరాలను ధృవీకరించిన తర్వాత మేము మీకు తెలియజేస్తాము.",
  addSchoolSuccess:
    "మీ పాఠశాల వివరాలను సమర్పించినందుకు ధన్యవాదాలు. మీ పాఠశాల వివరాలు ధృవీకరించబడిన తర్వాత మేము మీకు తెలియజేస్తాము.",

  photoInstructions:
    "ఫైల్ jpeg, jpg, png లేదా pdf ఫార్మాట్లలో ఉండాలి మరియు గరిష్ట పరిమాణం 500 KB మాత్రమే ఉండాలి",
  photoHeadSign:
    "మార్క్స్ మెమోలో ప్రధానోపాధ్యాయుడి సంతకం మరియు పాఠశాల స్టాంప్ ఉండాలి",
  passportPhotoInst:
    "ఫైల్ jpeg, jpg లేదా png ఫార్మాట్లలో ఉండాలి మరియు గరిష్ట పరిమాణం 500 KB మాత్రమే ఉండాలి.",
  ninthMemoExtraInfo:
    "మార్క్స్ మెమోలో ప్రధానోపాధ్యాయుడి సంతకం మరియు పాఠశాల స్టాంప్ ఉండాలి. ఫైల్ jpeg, jpg, png లేదా pdf ఫార్మాట్లలో ఉండాలి మరియు గరిష్ట పరిమాణం 500 KB మాత్రమే ఉండాలి.",

  homePageAppComplete:
    "మీ దరఖాస్తు వసమర్పించబడింది. మీ దరఖాస్తును ప్రక్రియ (ప్రాసెస్) చేసిన తర్వాత మీ రిజిస్టర్డ్ మొబైల్ నంబర్‌కు మీ అప్లికేషన్ స్థితిని తెలియజేస్తాము.",
  homePageEditMsg:
    "ఎడమ మెనూ బార్‌లోని సంపూర్ణ సిక్షపై క్లిక్ చేసి, ఎడిట్ ఫారమ్ క్లిక్ చేయడం ద్వారా ఏదైనా తప్పులను సరిదిద్దడానికి మీరు మీ అప్లికేషన్‌ను సవరించవచ్చు.",
  homePageViewForm:
    "ఎడమ మెనూ బార్‌లోని సంపూర్ణ సిక్షపై క్లిక్ చేసి, వ్యూ ఫారమ్‌పై క్లిక్ చేయడం ద్వారా మీరు మీ దరఖాస్తును చూడవచ్చు.",

  appViewHeading: "మేధా ఛారిటబుల్ ట్రస్ట్ పాఠశాల పరీక్షా దరఖాస్తు వివరాలు",

  applicationNumber: "దరఖాస్తు సంఖ్య",
  applicationRejectMsg: (
    <p>
      మేధా ఛారిటబుల్ ట్రస్ట్ పరీక్షకు మీ దరఖాస్తు{" "}
      <strong>తిరస్కరించబడింది</strong>. మీ భవిష్యత్ ప్రయత్నాలకు మా
      శుభాకాంక్షలు.
    </p>
  ),

  hallTicketNumber: "హాల్ టికెట్ నంబర్",
  examDateAndTime: "పరీక్ష తేదీ మరియు సమయం",
  applicationEndDate: "దరఖాస్తు చివరి తేదీ",
  applicationApproveMsg: (
    <p>
      మేధా ఛారిటబుల్ ట్రస్ట్ పరీక్షకు మీ దరఖాస్తు <strong>ఆమోదించబడింది</strong>
      . దయచేసి మీ హాల్ టికెట్‌ను ఎడమ వైపున ఉన్న డౌన్‌లోడ్ మెను నుండి డౌన్‌లోడ్
      చేసుకోండి.
    </p>
  ),
  applicationnotOnTime: (
    <p>
      మేధా ఛారిటబుల్ ట్రస్ట్ పరీక్షకు మీ దరఖాస్తు నిర్ణీత తేదీకి ముందు
      సమర్పించబడలేదు, అందువల్ల మీ దరఖాస్తు <strong>తిరస్కరించబడింది</strong>. మీ
      భవిష్యత్ ప్రయత్నాలకు మా శుభాకాంక్షలు.
    </p>
  ),
  studentAttemtedExam:
    "మేధా ఛారిటబుల్ ట్రస్ట్ స్కాలర్‌షిప్ పరీక్షకు ప్రయత్నించినందుకు ధన్యవాదాలు. మీరు ఎంపిక చేయబడితే మేము మిమ్మల్ని సంప్రదిస్తాము",
  studentSelectedToMss:
    "మేధా ఛారిటబుల్ ట్రస్ట్ స్కాలర్‌షిప్ ఇవ్వడానికి మీరు ఎంపికయ్యారు. మరిన్ని వివరాలతో మేము మిమ్మల్ని సంప్రదిస్తాము.",
  incompleteForm: (
    <p>
      మేధా సంపూర్ణ సిక్ష దరఖాస్తు పూర్తి చేయడానికి{" "}
      <Link to="/sampurnaSiksha/apply"> ఇక్కడ క్లిక్ </Link> చేయండి.
    </p>
  ),
  EN: "English",
  TE: "Telugu",
};

import React from 'react';
import './App.css';
import { Layout } from 'antd';
import NavbarContainer from './base/containers/NavbarContainer';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import RouteWithSubRoutes from './router/RouteWithSubRoutes';
import { routes } from './router/routes';
import HeaderContainer from './base/containers/HeaderContainer';
import AuthContainer from './base/containers/AuthContainer';
import HomeContainer from './base/containers/HomeContainer';
import Footer from './base/components/Footer';
import ScrollToTop from './base/components/ScrollToTop';

function App() {
  return (
    <Router>
      <div className="App">
        <HeaderContainer></HeaderContainer>
        <Layout style={{ minHeight: '85vh' }}>
          <Layout id="main-container">
            <ScrollToTop></ScrollToTop>
            <Switch>
            <Route path="/" exact key="1">
                <HomeContainer />
              </Route>
              <Route path="/login" exact key="1">
                <AuthContainer />
              </Route>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </Layout>
        </Layout>
        <Footer>

        </Footer>
      </div>
    </Router>
  );
}

export default App;

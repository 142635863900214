import React from "react";
import { Button, Form, Input, Modal, Spin } from "antd";
import { SUB } from "../utils/const";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class AddSubject extends React.Component {
 
  saveSubject = (form) => {
    this.props.saveMasterTableData(SUB, form);
  };
  render() {
    const record = this.props.record;
    const fieldData = [
      {
        name: ["subjectId"],
        value: record.subjectId,
      },
      {
        name: ["code"],
        value: record.code,
      },
      {
        name: ["name"],
        value: record.name,
      },
      {
        name: ["notes"],
        value: record.notes,
      },
    ];
    const text = record.subjectId ? "Edit Subject" : "Add Subject";
    return (
      <div id="add-sub-modal">
        <Modal
          title={text}
          visible={this.props.masterTable.showModal === SUB}
          footer={null}
          onCancel={() => this.props.toogleAddModal("")}
        >
          <Spin spinning={this.props.masterTable.loading} delay={500}>
            <Form
              name="subject"
              className="login-form login-wrapper"
              onFinish={this.saveSubject}
              fields={fieldData}
            >
              <Form.Item {...formItemLayout} name="subjectId" hidden={true}>
                <Input />
              </Form.Item>

              <Form.Item {...formItemLayout} name="code" label="Subject Code">
                <Input maxLength="50" />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="name"
                label="Subject Name"
                rules={[
                  {
                    required: true,
                    pattern: /^\S/,
                    message: "Please enter Subject Name",
                  },
                ]}
              >
                <Input maxLength="200"/>
              </Form.Item>

              <Form.Item {...formItemLayout} name="notes" label="Description">
                <Input.TextArea maxLength="200"/>
              </Form.Item>
              <div className="modal-form-button-div">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="modal-form-submit-button"
                  disabled={this.props.masterTable.loading}
                >
                  Save
                </Button>
              </div>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default AddSubject;

import { connect } from "react-redux";
import Configure from "../components/Configure";
import {
  getSystemConfigs,
  saveConfig,
  saveSystemConfigs,
} from "../../base/actions/AppConfigActions";
import { RESET_MESSAGES } from "../utils/consts";
import { withRouter } from "react-router";
import { cleanup } from "../actions/AdminActions";

const mapDispatchToProps = (dispatch, props) => {
  return {
    getSystemConfig() {
      dispatch(getSystemConfigs());
    },
    saveSystemConfig(form) {
      dispatch(saveSystemConfigs(form));
    },
    saveConfig(form) {
      dispatch(saveConfig(form)).then((res) => {
        if(res.action.type.indexOf(`_FULFILLED`) > -1) {
          const that = this;
          setTimeout(() => {
            that.getSystemConfig();
          }, 5000);
        }
      });
    },

    cleanUp() {
      dispatch(cleanup());
    },

    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, AdminReducer, SSReducer, AppConfigReducer } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    sampurnaSiksha: SSReducer,
    appConfig: AppConfigReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Configure)
);

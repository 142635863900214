import { connect } from "react-redux";
import { getCurrentUserDetails } from "../actions/AdminActions";
import AdminDashboard from "../components/AdminDashboard";

const mapDispatchToProps = (dispatch, props) => {
    return {
      getCurrentUserDetails() {
        dispatch(getCurrentUserDetails());
      },
    };
  };

  const mapStateToProps = state => {
    const { AuthReducer, AdminReducer, SSReducer } = state;
    return {
      isLoading: AuthReducer.isLoading,
      profile: AuthReducer.profile,
      lang: AuthReducer.lang,
      role: AuthReducer.role,
      admin: AdminReducer,
      sampurnaSiksha: SSReducer
    }
  };

  export default connect(mapStateToProps,mapDispatchToProps)(AdminDashboard);
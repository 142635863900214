import { connect } from "react-redux";
import Achievements from "../components/Achievements";

const mapDispatchToProps = dispatch => {
    return {
    };
  };

  const mapStateToProps = state => {
    const { AuthReducer } = state;
    return {
      isLoading: AuthReducer.isLoading,
      profile: AuthReducer.profile,
      lang: AuthReducer.lang
    }
  }

  export default connect(mapStateToProps,mapDispatchToProps)(Achievements);
import { Col, Empty, Input } from "antd";
import React from "react";
import { toCurrency } from "../../../common/utils/util";
import { OCC_DTLS } from "../../utils/consts";
import ViewSurveyCard from "./ViewSurveyCard";

class OccupationDetailsView extends React.Component {
  updateOthersValue = (value, i) => {
    // console.log(i, "--------------------", value);
    let info = this.props.data;
    info.othersList[i].oNewAnnualIncome = value;
    this.setInfo(info);
  };

  updateValue = (value, key) => {
    // console.log(key, "--------------------", value);
    let info = this.props.data;
    info[key] = value;
    this.setInfo(info);
  };

  setInfo(info) {
    this.props.setCommitteeData(info, OCC_DTLS);
  }

  generateFieldData = (info) => {
    const html = [];
    const isSelComt = this.props.isSelComt;
    if (Array.isArray(info)) {
      info.forEach((j, i) => {
        html.push(
          <Col sm={14}>
            <label>Others Primary Occupation</label>:
          </Col>,

          <Col sm={10}>
            <span>{j.oPrimary}</span>
          </Col>,
          <Col sm={14}>
            <label>Others Secondary Occupation</label>:
          </Col>,

          <Col sm={10}>
            <span>{j.oSecondary}</span>
          </Col>,
          <Col sm={14}>
            <label>Others Annual Income</label>:
          </Col>,

          <Col sm={10}>
            <span>{toCurrency(j.oAnnualIncome)}</span>
            {isSelComt && (
              <Input
                maxLength="8"
                className="inline-input"
                value={j.oNewAnnualIncome}
                disabled={this.props.disableSelComt}
                onChange={(e) => {
                  let value = e.target.value;
                  if(isNaN(value)) {
                    value='';
                  } else if(value) {
                    value = Number(value);
                  }
                  if (value > 99999999) {
                    value = value.toString().slice(0, 8);
                  }
                  this.updateOthersValue(value, i);
                }}
              />
            )}
          </Col>,
          <Col sm={14}>
            <label>Others Remarks</label>:
          </Col>,

          <Col sm={10}>
            <span>{j.oRemarks}</span>
          </Col>
        );
      });
    }
    return html;
  };
  render() {
    let info = this.props.data;
    if(!info) {
      return (
        <ViewSurveyCard title="Occupation Details" {...this.props}>
          <Empty />
        </ViewSurveyCard>
      );
    }
    const isSelComt = this.props.isSelComt;
    return (
      <div>
        <ViewSurveyCard title="Occupation Details" {...this.props}>
          <Col sm={14}>
            <label>Father's Primary Occupation</label>:
          </Col>

          <Col sm={10}>
            <span>{info.fPrimary}</span>
          </Col>
          <Col sm={14}>
            <label>Father's Secondary Occupation</label>:
          </Col>

          <Col sm={10}>
            <span>{info.fSecondary}</span>
          </Col>
          <Col sm={14}>
            <label>Father's Annual Income</label>:
          </Col>

          <Col sm={10}>
            <span>{toCurrency(info.fAnnualIncome)}</span>
            {isSelComt && (
              <Input
                maxLength="8"
                className="inline-input"
                value={info.fNewAnnualIncome}
                disabled={this.props.disableSelComt}
                onChange={(e) => {
                  let value = e.target.value;
                  if(isNaN(value)) {
                    value='';
                  } else if(value) {
                    value = Number(value);
                  }
                  if (value > 99999999) {
                    value = value.toString().slice(0, 8);
                  }
                  this.updateValue(value, "fNewAnnualIncome");
                }}
              />
            )}
          </Col>
          <Col sm={14}>
            <label>Father's Remarks</label>:
          </Col>

          <Col sm={10}>
            <span>{info.fRemarks}</span>
          </Col>

          <Col sm={14}>
            <label>Mother's Primary Occupation</label>:
          </Col>

          <Col sm={10}>
            <span>{info.mPrimary}</span>
          </Col>
          <Col sm={14}>
            <label>Mother's Secondary Occupation</label>:
          </Col>

          <Col sm={10}>
            <span>{info.mSecondary}</span>
          </Col>
          <Col sm={14}>
            <label>Mother's Annual Income</label>:
          </Col>

          <Col sm={10}>
            <span>{toCurrency(info.mAnnualIncome)}</span>
            {isSelComt && (
              <Input
                className="inline-input"
                value={info.mNewAnnualIncome}
                disabled={this.props.disableSelComt}
                onChange={(e) => {
                  let value = e.target.value;
                  if(isNaN(value)) {
                    value='';
                  } else if(value) {
                    value = Number(value);
                  }
                  if (value > 99999999) {
                    value = value.toString().slice(0, 8);
                  }
                  this.updateValue(Number(value), "mNewAnnualIncome");
                }}
              />
            )}
          </Col>
          <Col sm={14}>
            <label>Mother's Remarks</label>:
          </Col>

          <Col sm={10}>
            <span>{info.mRemarks}</span>
          </Col>

          {this.generateFieldData(info.othersList)}
        </ViewSurveyCard>
      </div>
    );
  }
}

export default OccupationDetailsView;

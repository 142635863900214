import React from 'react';
import ShortList from './ShortList';

class StudentSelection extends React.Component {
    render() {
        return(
            <div className="mg-l">
                <ShortList {...this.props}></ShortList>
            </div>
        );
    }
}

export default StudentSelection;
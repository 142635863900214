import React from 'react';
import BOY_BLACK from '../../assets/images/boyblack2-1-2000x1638.jpg';
// import STUD_PNG from '../../assets/images/ConvocationCrop.png';
import STUD_PNG_MSS from '../../assets/images/MSSlong.png';
import STUD_PNG_MNN from '../../assets/images/MNNlong.png';
import STUD_PNG_MHH from '../../assets/images/MHHlong.png';


export const getBoytBlack = () => {
    return BOY_BLACK
}

// export const STUDENT_IMG = <img className="img-home-1" width="100%" src={STUD_PNG}></img>;
export const STUDENT_IMG_MSS = <img className="img-home-1" width="100%" src={STUD_PNG_MSS}></img>;
export const STUDENT_IMG_MNN = <img className="img-home-2" width="100%" src={STUD_PNG_MNN}></img>;
export const STUDENT_IMG_MHH = <img className="img-home-3" width="100%" src={STUD_PNG_MHH}></img>;
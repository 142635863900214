import {
  Button,
  Modal,
  Space,
  Spin,
  Tag,
  Tooltip,
  Form,
  Input,
  Row,
  Col,
  Table,
} from "antd";
import React from "react";
import { GRADE } from "../utils/const";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { sortNames, toCapital } from "../../sampoorna-siksha/utils/util";
import { formItemLayout } from "../../sampoorna-siksha/utils/consts";
import { exportFile } from "../../common/utils/export";

const GRADE_LIST_COLS = [
  {
    title: "Grade System",
    dataIndex: "name",
    key: "name",
    render: (text, record) => record.name,
    sorter: {
      compare: (a, b) => sortNames(a.name, b.name),
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text, record) => record.description,
    sorter: {
      compare: (a, b) => sortNames(a.description, b.description),
    },
  },
  {
    title: "Grade Points",
    dataIndex: "gradePoints",
    key: "gradePoints",
    render: (text, record) => {
      const html = [];
      for (const sc of record.gradePoints) {
        html.push(
          <Tag>
            {" "}
            {sc.grade} - {sc.point}{" "}
          </Tag>
        );
      }
      return html;
    },
  },
];

const GRADE_LIST_XL_COLS = [
  {
    title: "Grade System",
    dataIndex: "name",
    key: "name",
    render: (text, record) => record.name,
    sorter: {
      compare: (a, b) => sortNames(a.name, b.name),
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text, record) => record.description,
    sorter: {
      compare: (a, b) => sortNames(a.description, b.description),
    },
  },
  {
    title: "Grade Points",
    dataIndex: "gradePoints",
    key: "gradePoints",
    render: (text, record) => {
      const str = [];
      for (const sc of record.gradePoints) {
          str.push(`${sc.grade} - ${sc.point}`)
      }
      return str.join(', ');
    },
  },
];

const AddGradeSystem = (props) => {
  const [gradeSystem, setGradeSystem] = React.useState(props.masterTable.currentRecord);

  const [form] = Form.useForm();
  const closeModal = () => {
    props.toogleAddModal("");
  };

  const submitGrade = () => {
    if(!props.masterTable.loading) {
      form.submit();
    }
  };

  const saveGrade = (form) => {
    props.saveMasterTableData(GRADE, form);
  };

  return (
    <React.Fragment>
      <Modal
        title={
          props.record?.gradeSystemId
            ? "Edit Grading System"
            : "Add Grading System"
        }
        visible={props.masterTable.showModal === GRADE}
        onOk={submitGrade}
        okText="Save"
        onCancel={closeModal}
        maskClosable={false}
        width={800}
      >
        <Spin spinning={props.masterTable.loading} delay={500}>
          <Form
            name="curiculum"
            className="login-form login-wrapper"
            onFinish={saveGrade}
            // fields={fieldData}
            initialValues={gradeSystem}
            form={form}
          >
            <Form.Item {...formItemLayout} name="gradeSystemId" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="gradeSystemId"
              hidden={true}
            >
              <Input />
            </Form.Item>
            <Row gutter={[16, 8]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  {...formItemLayout}
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      pattern: /^\S/,
                      message: "Please enter a Name",
                    },
                  ]}
                >
                  <Input maxLength="200" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  {...formItemLayout}
                  name="description"
                  label="Description"
                >
                  <Input.TextArea maxLength="200" />
                </Form.Item>
              </Col>
            </Row>

            <Form.List
              name="gradePoints"
              rules={[
                {
                  validator: async (_, gradePoints) => {
                    if (!gradePoints || gradePoints.length < 1) {
                      return Promise.reject(
                        new Error("Please Add Grade points")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => {
                return (
                  <div>
                    {fields.map((field, idx) => (
                      <Row key={idx} gutter={[16, 8]}>
                        <Form.Item
                          {...formItemLayout}
                          name="gradePointId"
                          hidden={true}
                        >
                          <Input />
                        </Form.Item>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            {...formItemLayout}
                            name={[field.name, "grade"]}
                            fieldKey={[field.fieldKey, "grade"]}
                            label="Grade"
                            validateTrigger={["onChange", "onBlur"]}
                            getValueFromEvent={toCapital}
                            rules={[
                              {
                                required: true,
                                pattern: /[A-Z\-\+]+$/,
                                message: "Please enter Grade",
                              },
                            ]}
                          >
                            <Input maxLength="5" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={11}>
                          <Form.Item
                            {...formItemLayout}
                            name={[field.name, "point"]}
                            fieldKey={[field.fieldKey, "point"]}
                            label="Grade Point"
                            rules={[
                              {
                                required: true,
                                pattern: /^([0-9]*)$/,
                                message: "Please enter Grade Points",
                              },
                            ]}
                          >
                            <Input maxLength="2" />
                          </Form.Item>
                        </Col>
                        <Col sm={1}>
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    ))}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                      >
                        <PlusOutlined /> Add Grade Point
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </Form>
          <p>
            <b>Note:</b> Sub-Categories can not be removed if they are mapped to
            any student request.
          </p>
        </Spin>
      </Modal>
    </React.Fragment>
  );
};

const GradeSystem = (props) => {
  const [gradeSystem, setGradeSystem] = React.useState(null);

  React.useEffect(() => {
    props.fetchMasterTableData(GRADE);
  }, []);

  const handleAdd = () => {
    setGradeSystem({ gradePoints: [{}] });
    props.setCurrentRecord({ gradePoints: [{}] });
    props.toogleAddModal(GRADE);
  };

  const editGrade = (record, e) => {
    e.preventDefault();
    setGradeSystem(record);
    props.setCurrentRecord(record);
    props.toogleAddModal(GRADE);
  };

  const deleteGrade = (record, e) => {
    e.preventDefault();
    Modal.confirm({
      title: "Do you want to delete this Grading System?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        props.deleteMasterTableData(GRADE, record.gradeSystemId);
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  const exportData = () => {
    exportFile(GRADE_LIST_XL_COLS, props.masterTable.grades, "Grading System");
  };

  if (GRADE_LIST_COLS.length < 4) {
    GRADE_LIST_COLS.push({
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <a href="/" onClick={(e) => editGrade(record, e)}>
              <EditOutlined />
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <a href="/" onClick={(e) => deleteGrade(record, e)}>
              <DeleteOutlined />
            </a>
          </Tooltip>
        </Space>
      ),
    });
  }
  return (
    <React.Fragment>
      <div id="education-category-container">
        <h2>
          Grading System
          <Button
            className="add-cat-button"
            onClick={handleAdd}
            type="primary"
            style={{ float: "right" }}
            disabled={props.masterTable.loading}
          >
            Add Grade
          </Button>
          <Button
            className="add-cat-button"
            onClick={(e) => exportData(e)}
            style={{ float: "right", marginRight: "10px" }}
            disabled={props.masterTable.loading}
          >
            Export Excel
          </Button>
        </h2>
        <Spin spinning={props.masterTable.loading} delay={500}>
          <div>
            <Table
              columns={GRADE_LIST_COLS}
              dataSource={props.masterTable.grades}
              pagination={{
                showSizeChanger: true,
                showTotal: () => {
                  const total = props.masterTable.grades.length;
                  return `Total ${total}`;
                },
              }}
            />
          </div>
        </Spin>
        {props.masterTable.showModal === GRADE && (
          <AddGradeSystem record={gradeSystem} {...props} />
        )}
      </div>
    </React.Fragment>
  );
};

export default GradeSystem;

import { axiosInstance } from "../../Interceptor";
import { GET_USERS_LIST, ADD_USER, EDIT_USER, DELETE_USER, GET_USER_DETAILS, GET_STATUS_SUMMARY, GET_SYS_CONFIG, SAVE_SYS_CONFIG, GET_HT_DETAILS, ADMIN_UPLOAD, ADMIN_SHORT_LIST, SEND_ACCT_INFO, CLEAN_UP } from "../utils/consts";

export const getUsersList = () => {
    return {
        type: GET_USERS_LIST,
        payload: axiosInstance.get('/user/all')
    };
}

export const getUsersListByRole = (role) => {
    return {
        type: GET_USERS_LIST,
        payload: axiosInstance.get(`/user/role/${role}`)
    };
}

export const getAllUsersListByRole = (role) => {
    return {
        type: GET_USERS_LIST,
        payload: axiosInstance.get(`/user/role/all/${role}`)
    };
}

export const sendAcctInfo = (userIds) => {
    return {
        type: SEND_ACCT_INFO,
        payload: axiosInstance.post(`/user/studacct/sms`,userIds)
    }
}

export const addUser = (userData) => {
    const url = `/user/`;
    return {
        type: ADD_USER,
        payload: axiosInstance.post(url, userData)
    };
}

export const editUser = (userData) => {
    const url = `/user/`;
    return {
        type: EDIT_USER,
        payload: axiosInstance.put(url, userData)
    };
}

export const deleteUser = (userData) => {
    const url = `/user/${userData.userId}`;
    return {
        type: DELETE_USER,
        payload: axiosInstance.delete(url)
    };
}

export const enableUser = (userData) => {
    const url = `/user/activate/${userData.userId}`;
    return {
        type: DELETE_USER,
        payload: axiosInstance.get(url)
    };
}

export const getCurrentUserDetails = () => {
    return {
        type: GET_USER_DETAILS,
        payload: axiosInstance.get('/user/details')
    };
}

export const getAllHallTicketDetails = () => {
    return {
        type: GET_HT_DETAILS,
        payload: axiosInstance.get('/selection/halltickets')
    };
}

export const shortListApplications = (hallTickets) => {
    return {
        type: ADMIN_SHORT_LIST,
        payload: axiosInstance.post('/selection/shortList', hallTickets)
    };
}

export const uploadAdminDocs = (form) => {
    return {
        type: ADMIN_UPLOAD,
        payload: axiosInstance.post("/upload/admin", form),
    };
};

export const getAdminDocs = (path) => {
    return {
        type: ADMIN_UPLOAD,
        payload: axiosInstance.get("/upload/admin"),
    };
};

export const cleanup = (userData) => {
    return {
        type: CLEAN_UP,
        payload: axiosInstance.delete("application/cleanup")
    };
}
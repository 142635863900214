import { axiosInstance } from "../../Interceptor";
import { FETCH_ENROLL_DETAILS, SAVE_ENROLL_DETAILS } from "../utils/const";

export const fetchAllEnrollmentDetails = (year) => {
  return {
    type: FETCH_ENROLL_DETAILS,
    payload: axiosInstance.get("/stud/manage/enroll/" + year),
  };
};

export const saveEnrollmentDetails = (enrollDetails) => {
  return {
    type: SAVE_ENROLL_DETAILS,
    payload: axiosInstance.post("/stud/manage/enroll", enrollDetails),
  };
};

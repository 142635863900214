import React from 'react';
import { Row, Col } from 'antd';
import '../styles/footer.css';
import { connect } from "react-redux";

import { PhoneOutlined, GoogleOutlined, MailOutlined, HomeOutlined, FacebookOutlined, LinkedinOutlined, WhatsAppOutlined, InstagramOutlined, PushpinOutlined, CopyrightOutlined } from '@ant-design/icons';

class Footer extends React.Component {
    render() {
        return (
            <div>
                {!this.props.isLoggedIn &&
                    <div className="footer-container">
                        <Row>
                            <Col xs={24} sm={6}>
                                <Row>
                                    <Col xs={5} sm={24}>
                                        <img src="/MCT_logo.png" alt="Medha Charitable Trust" className="footer-logo"></img>
                                    </Col>
                                    <Col xs={19} sm={24}>
                                        <div className="footer-logo-text">
                                            <span  >A not-for-profit organisation working <br></br> to empower India through education</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={6}>
                                <div className="footer-address">
                                    <Row>
                                        <Col xs={5} sm={10}>
                                            <PhoneOutlined className="footer-phone-mail-logo" />
                                        </Col>
                                        <Col xs={19} sm={14}>
                                            <p className="footer-phno"> (+91) 9515334007</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={5} sm={10}>
                                            <MailOutlined className="footer-phone-mail-logo" />
                                        </Col>
                                        <Col xs={19} sm={14}>
                                            <p className="footer-phno"> info@medhatrust.org</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={24}>
                                            <div className="footer-social">
                                                <a href="https://www.facebook.com/medhacharitabletrust" alt="Facebool" target="_blank">  <FacebookOutlined style={{ fontSize: '30px', padding: '10px', color: '#ffff' }} /></a>
                                                <a href="https://www.instagram.com/medhacharitabletrust/" alt="Facebool" target="_blank"><InstagramOutlined style={{ fontSize: '30px', padding: '10px', color: '#ffff' }} /></a>
                                                <a href="https://www.linkedin.com/company/medha-charitable-trust" alt="Linkedin" target="_blank"> <LinkedinOutlined style={{ fontSize: '30px', padding: '10px', color: '#ffff' }} /></a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Row>
                                    <Col xs={5} sm={8}>
                                        <HomeOutlined className="address-section-logo" />
                                    </Col>
                                    <Col xs={19} sm={16}>
                                        <p className="address-section address ">
                                            P - 4/5B, IDA Nacharam,<br></br>
                                        Hyderabad 500 076<br></br>
                                        Telangana, India <br></br>
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={6}>
                                <div className="footer-fallow-us">
                                    <Row>
                                        <Col xs={24} sm={24}>
                                            <div className="map-location">
                                                <iframe title="medhaMap" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.5917699800816!2d78.55548721487695!3d17.43136808805228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9965f35cb583%3A0xd58ff1cec9e3ad6b!2sMedha%20Charitable%20Trust!5e0!3m2!1sen!2sin!4v1591700762881!5m2!1sen!2sin" ></iframe>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24}>
                                <p className="footer-copyright">
                                    <CopyrightOutlined /> Copyright 2020 to Medha Charitable Trust - All Rights Reserved
                                </p>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

const mapStateToProps = (state) => {
    const { AuthReducer } = state;
    return {
        isLoggedIn: AuthReducer.isLoggedIn,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

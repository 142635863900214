import React from "react";
import { Button, Modal, Space, Spin, Table, Tooltip } from "antd";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import AddCuricullum from "./AddCuricullum";
import { CUR } from "../utils/const";
import { exportFile } from "../../common/utils/export";

class Curicullum extends React.Component {
  state = {
    curicullum: {},
  };
  componentDidMount() {
    this.props.fetchMasterTableData(CUR);
    this.props.setCurrentRecord({});
  }

  handleAdd = () => {
    this.setState({ curicullum: {} });
    this.props.setCurrentRecord({});
    this.props.toogleAddModal(CUR);
  };

  edit = (record, e) => {
    e.preventDefault();
    this.setState({ curicullum: record });
    this.props.fetchMasterTableRecord(CUR, record.curriculumId);
    this.props.toogleAddModal(CUR);
  };

  delete = (record, e) => {
    e.preventDefault();
    const that = this;
    Modal.confirm({
      title: (<div>Do you want to delete this Curicullum?
        <p>Note: It can only be deleted if there are no students mapped to this Curicullum.</p>
      </div>),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        that.props.deleteMasterTableData(CUR, record.curriculumId);
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  exportData = (cols) => {
    exportFile(cols, this.props.masterTable.curicullums, "Curriculums");
  };

  render() {
    const CURC_LIST_COLS = [
      {
        title: "Curriculum",
        dataIndex: "name",
        key: "name",
        render: (text, record) => record.name,
        sorter: {
          compare: (a, b) => sortNames(a.name, b.name),
        },
      },
      {
        title: "Affiliation",
        dataIndex: "affiliation",
        key: "affiliation",
        render: (text, record) => record.affiliation.name,
        sorter: {
          compare: (a, b) => sortNames(a.affiliation.name, b.affiliation.name),
        },
      },
      {
        title: "Category",
        dataIndex: "educationCategory",
        key: "educationCategory",
        render: (text, record) => record.course?.educationCategory.categoryName,
        sorter: {
          compare: (a, b) =>
            sortNames(
              a.course?.educationCategory.categoryName,
              b.course?.educationCategory.categoryName
            ),
        },
      },
      {
        title: "Course",
        dataIndex: "course",
        key: "course",
        render: (text, record) => record.course.courseName,
        sorter: {
          compare: (a, b) => sortNames(a.course.courseName, b.course.courseName),
        },
      },
      {
        title: "Credit System",
        dataIndex: "credSystem",
        key: "credSystem",
        render: (text, record) =>
          record.affiliation.credSystem === 1 ? "Percentage" : "Grading",
        sorter: {
          compare: (a, b) =>
            sortNames(a.affiliation.credSystem, b.affiliation.credSystem),
        },
      },
      {
        title: "Effective Year",
        dataIndex: "effectiveYear",
        key: "effectiveYear",
        render: (text, record) => record.effectiveYear,
        sorter: {
          compare: (a, b) => a.effectiveYear - b.effectiveYear,
        },
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <Tooltip title="Edit">
              <a onClick={(e) => this.edit(record, e)}>
                <EditOutlined />
              </a>
            </Tooltip>
            <Tooltip title="Delete">
              <a onClick={(e) => this.delete(record, e)}>
                <DeleteOutlined />
              </a>
            </Tooltip>
          </Space>
        ),
      },
    ];
    return (
      <div id="affiliation-container">
        <h2>
          Curriculum
          <Button
            className="add-cat-button"
            onClick={this.handleAdd}
            type="primary"
            style={{ float: "right" }}
            disabled={this.props.masterTable.loading}
          >
            Add Curriculum
          </Button>
          <Button
            className="add-cat-button"
            onClick={(e) => this.exportData(CURC_LIST_COLS)}
            style={{ float: "right", marginRight: "10px" }}
            disabled={this.props.masterTable.loading}
          >
            Export Excel
          </Button>
        </h2>
        <Spin spinning={this.props.masterTable.loading} delay={500}>
          <div>
            <Table
              columns={CURC_LIST_COLS}
              dataSource={this.props.masterTable.curicullums}
              pagination={{
                showSizeChanger: true,
                showTotal: () => {
                  const total = this.props.masterTable.curicullums.length;
                  return `Total ${total}`;
                },
              }}
            />
          </div>
        </Spin>
        {this.props.masterTable.showModal === CUR && (
          <AddCuricullum record={this.state.curicullum} {...this.props} />
        )}
      </div>
    );
  }
}

export default Curicullum;

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { RESET_CURR_UPLD, RESET_MESSAGES } from "../../base/utils/const";
import {
  APRV,
  DEN,
  MON_EVENT,
  MON_REQ_APPRV,
  PEND,
  TASK_TYPES,
} from "../../common/utils/const";
import { MON_REQ, TOGGLE_ADD_MODAL } from "../../mastertables/utils/const";
import { formItemLayout } from "../../sampoorna-siksha/utils/consts";
import { sortNames, getLable } from "../../sampoorna-siksha/utils/util";
import moment from "moment";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { PAY_TO, PAY_MODE, ADD_TO_DEL_MNG_FILES, MON_REC, SET_CUR_REQ } from "../utils/const";
import {
  deleteMonRequests,
  getMonitoryRequests,
  saveMonitoryRequest,
} from "../actions/studentList";
import {
  getApprovalLevels,
  getTaskComments,
} from "../../common/actions/TaskActions";
import TaskComments from "../../common/components/detailTemplates/TaskComments";
import {
  fetchRequestCategory,
  fetchRequestSubCategory,
} from "../../mastertables/actions/masterTables";
import { canEditRequest, getStatusContent, toCurrency } from "../../common/utils/util";
import {
  approveDrafts,
  deleteDrafts,
  deleteFiles,
  draftReqImg,
  getUploadsByProfIdAndType,
  updateReqImageAngle,
} from "../actions/managementUploads";
import ImageViewer from "../../sampoorna-siksha/components/reviewSteps/ImageViewer";
import { ACCEX, MSSSTUD } from "../../base/utils/roles";
import { isValidDoc } from "../../base/utils/utils";
import { getCurrentUserRole } from "../../service/SessionStorageService";

const dateFormat = "DD/MM/YYYY";
const EDIT_STUD_MON = "EDIT_STUD_MON";
const STUD_MON_COLS = [
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text, record) =>
      record.createdAt ? moment(record.createdAt).format(dateFormat) : "",
    sorter: {
      compare: (a, b) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    render: (text, record) => record.requestCategory.name,
    sorter: {
      compare: (a, b) =>
        sortNames(a.requestCategory.name.toLowerCase(), b.requestCategory.name.toLowerCase()),
    },
  },
  {
    title: "Sub-Category",
    dataIndex: "subCategory",
    key: "subCategory",
    render: (text, record) => record.requestSubCategory.name,
    sorter: {
      compare: (a, b) =>
        sortNames(a.requestSubCategory.name.toLowerCase(), b.requestSubCategory.name.toLowerCase()),
    },
  },
  {
    title: "Mode of Payment",
    dataIndex: "modeOfPayment",
    key: "modeOfPayment",
    render: (text, record) => getLable(PAY_MODE, record.modeOfPayment),
    sorter: {
      compare: (a, b) =>
        sortNames(
          getLable(PAY_MODE, a.modeOfPayment),
          getLable(PAY_MODE, b.modeOfPayment)
        ),
    },
  },
  {
    title: "Transfer To",
    dataIndex: "transferTo",
    key: "transferTo",
    render: (text, record) => getLable(PAY_TO, record.transferTo),
    sorter: {
      compare: (a, b) =>
        sortNames(
          getLable(PAY_TO, a.transferTo),
          getLable(PAY_TO, b.transferTo)
        ),
    },
  },
  {
    title: "Details",
    dataIndex: "details",
    key: "details",
    render: (text, record) => record.details,
    sorter: {
      compare: (a, b) => sortNames(a.details, b.details),
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (text, record) => toCurrency(record.amount),
    sorter: {
      compare: (a, b) => sortNames(a.amount - b.amount),
    },
  },
];

const MonitoryRequestForm = (props) => {
  const [formUpdated, setFormUpdated] = useState(false);
  const [subCatList, setSubCatList] = useState([]);
  const [form] = Form.useForm();
  const [uploadedFiles, setUploadedFiles] = useState([]);

  // useEffect(() => {
  //   props.resetUploads();
  // }, []);

  useEffect(() => {
    if (props.manage.currentRequest?.category && props.masterTable.reqCat.length > 0) {
      onCatChanged(props.manage.currentRequest.category, props.manage.currentRequest.subCategory);
    }
  }, [props.manage.currentRequest, props.masterTable.reqCat]);

  useEffect(() => {
    const uploadedFiles = [];
    for (const up of props.mgUploads.newAttach) {
      if (
        up.type === MON_REC &&
        ((up.status === "P" && !up.entityId) ||
          up.entityId === props.manage.currentRequest.monitoryRequestID)
      ) {
        uploadedFiles.push(up);
      }
    }
    const uniqueUploads = Array.from(
      new Set(uploadedFiles.map((a) => a.managementUploadId))
    ).map((id) => {
      return uploadedFiles.find((a) => a.managementUploadId === id);
    });
    setUploadedFiles(uniqueUploads);
  }, [props.mgUploads.newAttach]);

  const onFinish = (form) => {
    console.log("-------", form);
    const studentProfileId = props.manage.currentProfile.studentProfileId;
    form.studentProfileId = studentProfileId;

    if (props.manage.currentRequest.monitoryRequestID) {
      form.createdAt = moment(props.manage.currentRequest.createdAt);
    }

    if (props.role === MSSSTUD && uploadedFiles.length === 0) {
        message.error("Please upload valid file less than 500 KB.");
        return;
    } 

    const newFiles = uploadedFiles.find(up => up.status === "P" && !up.entityId);
    props.saveMonitoryRequest({
      monitoryRequest: form,
      studentProfileId: studentProfileId,
      uploadId: newFiles?.managementUploadId,
      comment: form.comment,
    });
  };

  const onCancel = () => {
    form.resetFields();
    props.toogleAddModal("");
  };

  const onValueChange = () => {
    setFormUpdated(true);
  };

  const onCatChanged = (cat, subCat) => {
    setSubCatList(
      props.masterTable.reqCat?.find((c) => c.requestCategoryId === cat)
        .requestSubCategories
    );
    form.setFieldsValue({
      subCategory: subCat,
    });
  };

  const setFiles = (e) => {
    const file = document.getElementById("monReqFileUpload").files[0];
    if (!file) {
      return;
    }

    if (!isValidDoc(file)) {
      message.error("Please upload valid file less than 500 KB.");
      document.getElementById("monReqFileUpload").value = "";
      return;
    }

    const currentProfile = props.manage.currentProfile;

    // Save the new image uploaded as a draft.
    props.draftReqImg(
      currentProfile.studentProfileId,
      file,
      MON_REC,
      currentProfile.batch
    );
    document.getElementById("monReqFileUpload").value = "";
  };

  const handleUpload = () => {
    document.getElementById("monReqFileUpload").click();
  };

  return (
    <React.Fragment>
      <Modal
        visible={props.manage.showModal === EDIT_STUD_MON}
        footer={null}
        onCancel={() => onCancel()}
        width="80%"
      >
        <div>
          <Spin tip="Loading..." spinning={props.masterTable.loading}>
            <Card
              title={
                props.manage.currentRequest.monitoryRequestID
                  ? "Edit Monetary Request"
                  : "Add Monetary Request"
              }
            >
              <div>
                <Form
                  initialValues={props.manage.currentRequest}
                  onFinish={onFinish}
                  form={form}
                  onValuesChange={(e) => onValueChange(e)}
                >
                  <Form.Item
                    {...formItemLayout}
                    name="monitoryRequestID"
                    hidden={true}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    name="studentProfileId"
                    hidden={true}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item {...formItemLayout} name="createdAt" hidden={true}>
                    <Input />
                  </Form.Item>
                  <Row gutter={[16, 8]}>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="category"
                        label="Category"
                        rules={[
                          {
                            required: true,
                            message: "Please select Category",
                          },
                        ]}
                      >
                        <Select
                          className="full-width"
                          onSelect={(e) => onCatChanged(e, "")}
                        >
                          {props.masterTable.reqCat.map((typ) => (
                            <Select.Option
                              key={typ.requestCategoryId}
                              value={typ.requestCategoryId}
                            >
                              {typ.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="subCategory"
                        label="Sub-Category"
                        rules={[
                          {
                            required: true,
                            message: "Please select Sub-Category",
                          },
                        ]}
                      >
                        <Select className="full-width">
                          {subCatList.map((typ, i) => (
                            <Select.Option
                              key={typ.requestSubCategoryId}
                              value={typ.requestSubCategoryId}
                            >
                              {typ.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="modeOfPayment"
                        label="Mode of Payment"
                        rules={[
                          {
                            required: true,
                            message: "Please select Mode of Payment",
                          },
                        ]}
                      >
                        <Select className="full-width">
                          {PAY_MODE.map((typ, i) => (
                            <Select.Option key={"item-" + i} value={typ.key}>
                              {typ.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="transferTo"
                        label="Transfer to"
                        rules={[
                          {
                            required: true,
                            message: "Please select Transfer to",
                          },
                        ]}
                      >
                        <Select className="full-width">
                          {PAY_TO.map((typ, i) => (
                            <Select.Option key={"item-" + i} value={typ.key}>
                              {typ.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="details"
                        label="Details"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Details",
                          },
                        ]}
                      >
                        <Input.TextArea maxLength="1000" />
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="amount"
                        label="Amount"
                        rules={[
                          {
                            required: true,
                            pattern: /^[1-9][0-9]*$/,
                            message: "Please enter valid Amount",
                          },
                        ]}
                      >
                        <Input maxLength="10"/>
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      {props.manage.currentRequest.monitoryRequestID && (
                        <Form.Item
                          {...formItemLayout}
                          name="comment"
                          label="Comments"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please add comments related to request update.",
                            },
                          ]}
                        >
                          <Input.TextArea maxLength="1000" />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>

                      <Form.Item name="upload" label="Upload file" {...formItemLayout}>
                        <Button
                          icon={<UploadOutlined />}
                          onClick={(e) => handleUpload(e)}
                        >
                          Click to Upload file
                        </Button>
                      </Form.Item>

                      <input
                        type="file"
                        id="monReqFileUpload"
                        style={{ display: "none" }}
                        onChange={setFiles}
                      ></input>
                      <ImageViewer
                        imageList={uploadedFiles}
                        showActions={false}
                        loading={props.manage.loading}
                        year={props.manage.currentProfile?.batch}
                        {...props}
                      />

                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button mg-l"
                      disabled={props.manage.loading}
                    >
                      Submit
                    </Button>
                    <Button
                      className="login-form-button mg-l"
                      onClick={() => onCancel()}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </Card>
          </Spin>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const MonitoryRequestComments = (props) => {
  return (
    <div>
      <Spin spinning={props.commentsLoading}>
        <TaskComments comments={props.comments} />
      </Spin>
    </div>
  );
};

const MonetaryRequests = (props) => {
  const [currentRecord, setCurrentRecord] = useState({});
  const [approvalLevels, setApprovalLevels] = useState(
    props.tasks.approvalLevels
  );
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    if (props.tasks.approvalLevels.length === 0) {
      props.getApprovalLevels();
    }
    props.getReqCats(MON_REQ);
     return () => {
      props.setCurrentRequest({});
    }
  }, []);

  useEffect(() => {
    if (props.manage.currentProfile.studentProfileId) {
      props.getMonitoryRequests(props.manage.currentProfile.studentProfileId);
    }
    // return () => {
    //   second
    // }
  }, [props.manage.currentProfile.studentProfileId]);

  useEffect(() => {
    if (props.tasks.approvalLevels.length > 0) {
      setApprovalLevels(props.tasks.approvalLevels);
    }
  }, [props.tasks.approvalLevels]);

  useEffect(() => {
    if (props.manage.currentProfile.monitoryRequests?.length > 0) {
      const req = props.manage.currentProfile.monitoryRequests;
      for (const r of req) {
        if (props.mgUploads.attachments.length > 0) {
          r.attach = props.mgUploads.attachments.filter(
            (at) => at.entityId === r.monitoryRequestID && at.type === MON_REC
          );
        } else {
          r.attach = [];
        }
        r.batch = props.manage.currentProfile.batch;
      }
      setRequests(req);
    }
  }, [
    props.manage.currentProfile.monitoryRequests,
    props.mgUploads.attachments,
    props.manage.currentProfile.batch,
  ]);

  const hasAccess = (currentLevel) => {
    if(!currentLevel) {
      if(props.role === MSSSTUD) {
        return true;
      }
      if (props.role && props.role !== ACCEX) {
        const taskTypes = TASK_TYPES[props.role];
        if (taskTypes) {
          return taskTypes.indexOf(MON_REQ_APPRV) > -1;
        }
      }
      return false;
    }
    if(props.role === MSSSTUD) {
      return canEditRequest(currentLevel - 1, props.role, MON_REQ_APPRV.value);
    }
    return canEditRequest(currentLevel, props.role, MON_REQ_APPRV.value);
  };

  const addRecord = () => {
    props.setCurrentRequest({});
    props.toogleAddModal(EDIT_STUD_MON);
  };

  const editRecord = (record, e) => {
    e.preventDefault();
    record.createdAt = moment(record.createdAt);
    props.setCurrentRequest(record);
    props.toogleAddModal(EDIT_STUD_MON);
  };

  const deleteRecord = (record, e) => {
    e.preventDefault();
    Modal.confirm({
      title: "Do you want to delete this request?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        props.deleteMonRequest(
          record.monitoryRequestID,
          props.manage.currentProfile.studentProfileId
        );
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  if (STUD_MON_COLS.length < 8 && approvalLevels.length > 0) {
    STUD_MON_COLS.push({
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) =>
        getStatusContent(
          approvalLevels,
          record.assignedLevel,
          record.status,
          MON_REQ_APPRV.value
        ),
      sorter: {
        compare: (a, b) => {
          if (a.assignedLevel !== b.assignedLevel) {
            return a.assignedLevel - b.assignedLevel;
          } else if (
            a.assignedLevel === b.assignedLevel &&
            a.status !== b.status
          ) {
            if (a.status === APRV || a.status === DEN) {
              return 1;
            } else if (b.status === APRV || b.status === DEN) {
              return -1;
            }
          } else if (
            a.assignedLevel === b.assignedLevel &&
            a.status === b.status
          ) {
            return 0;
          } else {
            return 0;
          }
        },
      },
    });
    STUD_MON_COLS.push({
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Space size="middle">
            {record.attach && (
              <ImageViewer
                imageList={record.attach}
                showActions={false}
                showPin={true}
                year={record.batch}
                {...props}
              />
            )}
            {hasAccess(record.assignedLevel) && (
              <a href="/" onClick={(e) => editRecord(record, e)}>
                <EditOutlined />
              </a>
            )}
            {hasAccess(record.assignedLevel) && (
              <a href="/" onClick={(e) => deleteRecord(record, e)}>
                <DeleteOutlined />
              </a>
            )}
          </Space>
        </>
      ),
    });
  }
  return (
    <React.Fragment>
      <React.StrictMode>
      <Card>
        <h2>
          Monetary Requests
          <Button onClick={addRecord} type="primary" style={{ float: "right" }} disabled={!hasAccess()}>
            Add
          </Button>
        </h2>
        <Spin spinning={props.manage.loading} delay={500}>
          <div className="overflow-table">
            {approvalLevels.length > 0 && (
              <Table
                columns={STUD_MON_COLS}
                dataSource={requests}
                rowKey={(record) => record.monitoryRequestID}
                pagination={{ showSizeChanger: true }}
                expandable={{
                  expandedRowRender: (record) => (
                    <MonitoryRequestComments
                      request={record}
                      comments={record.comments}
                      commentsLoading={props.tasks.loading}
                    />
                  ),
                  // rowExpandable: record => true,
                  onExpand: (expanded, record) => {
                    if (expanded) {
                      props.getTaskComments(
                        record.monitoryRequestID,
                        MON_EVENT
                      );
                    }
                  },
                }}
              />
            )}
          </div>
        </Spin>
      </Card>
      {props.manage.showModal === EDIT_STUD_MON && (
        <MonitoryRequestForm {...props} />
      )}
      {props.onClose && (
        <div style={{ textAlign: "right", marginTop: "10px" }}>
          <Button onClick={(e) => props.onClose(e)} style={{ marginRight: 8 }}>
            Close
          </Button>
        </div>
      )}
      </React.StrictMode>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // offlineSearch(searchText, profiles) {
    //   if (searchText && searchText.length > 1) {
    //     const result = profiles.filter((profile) => {
    //       return studProfPredicate(profile, searchText);
    //     });
    //     dispatch({
    //       type: SET_FILTERED_PROFILES,
    //       payload: result,
    //     });
    //   } else if (!searchText) {
    //     dispatch({
    //       type: SET_FILTERED_PROFILES,
    //       payload: profiles,
    //     });
    //   }
    // },

    getApprovalLevels() {
      dispatch(getApprovalLevels());
    },

    getMonitoryRequests(id) {
      dispatch(getUploadsByProfIdAndType(id, MON_REC)).then((res) => {
        dispatch(getMonitoryRequests(id));
      });
    },

    getTaskComments(entityId, entityType) {
      dispatch(getTaskComments(entityId, entityType));
    },

    getReqCats(id) {
      dispatch(fetchRequestCategory(id));
    },

    getReqSubCats(id) {
      dispatch(fetchRequestSubCategory(id));
    },

    saveMonitoryRequest(form) {
      dispatch(saveMonitoryRequest(form)).then((res) => {
        this.getMonitoryRequests(form.studentProfileId);
      });
    },

    deleteMonRequest(id, profileId) {
      dispatch(deleteMonRequests(id)).then((res) => {
        this.getMonitoryRequests(profileId);
      });
    },

    updateImageAngle(angle, uploadId) {
      dispatch(updateReqImageAngle(uploadId, angle));
    },

    draftReqImg(studentProfileId, file, type, batch) {
      dispatch(draftReqImg(studentProfileId, file, type, batch));
    },

    deleteDraft(studentProfileId) {
      dispatch(deleteDrafts(studentProfileId));
    },

    deleteFiles(filesList) {
      dispatch(deleteFiles(filesList));
    },

    addToDeleteFiles(filesList) {
      dispatch({
        type: ADD_TO_DEL_MNG_FILES,
        payload: filesList,
      });
    },

    approveDrafts(studentProfileId, filesList) {
      dispatch(approveDrafts(studentProfileId, filesList));
    },

    toogleAddModal(modal) {
      dispatch({
        type: TOGGLE_ADD_MODAL,
        payload: modal,
      });
    },

    setCurrentRequest(data) {
      dispatch({
        type: SET_CUR_REQ,
        payload: data,
      });
    },

    resetUploads() {
      dispatch({
        type: RESET_CURR_UPLD,
      });
    },

    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    ManagementReducer,
    MasterTablesReducer,
    AppConfigReducer,
    ManagementUploadReducer,
    TaskReducer,
  } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    manage: ManagementReducer,
    masterTable: MasterTablesReducer,
    appConfig: AppConfigReducer,
    mgUploads: ManagementUploadReducer,
    comments: TaskReducer.taskComments,
    tasks: TaskReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MonetaryRequests)
);

import moment from "moment";
import { CREATE_TASK, RESET_MESSAGES } from "../../base/utils/const";
import {
  CHANGE_TASK_STATUS,
  GET_NOTIF,
  READ_NOTIF,
} from "../utils/const";

const initialState = {
  notifications: [],
  loading: false,
  successMessage: "",
  errorMessage: "",
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_NOTIF}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${GET_NOTIF}_FULFILLED`: {
      const notif = action.payload.sort((a,b) => {
        if(a.createdDate && b.createdDate) {
          return moment(b.createdDate).unix() - moment(a.createdDate).unix();
        } else if (a.createdDate && ! b.createdDate) {
          return  moment(a.createdDate).unix()
        } else if (!a.createdDate && b.createdDate) {
          moment(b.createdDate).unix()
        } else {
          return -1;
        }
         });
      return {
        ...state,
        loading: false,
        notifications: notif,
      };
    }

    case `${GET_NOTIF}_REJECTED`: {
      return {
        ...state,
        loading: true,
        errorMessage: action.payload.data,
      };
    }

    case `${READ_NOTIF}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${READ_NOTIF}_FULFILLED`: {
      return {
        ...state,
        loading: false,
      };
    }

    case `${READ_NOTIF}_REJECTED`: {
      return {
        ...state,
        loading: true,
        errorMessage: action.payload.data,
      };
    }

    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    }

    default:
      return state;
  }
};

export default NotificationReducer;

import { connect } from "react-redux";
import {
  TOGGLE_ADD_USER_MODAL,
  EDIT_USER,
  RESET_MESSAGES,
  EMPTY_USER_DETAILS,
  SET_FILTERED_USERS,
} from "../../admin/utils/consts";
import StudAccounts from "../components/StudAccounts";
import { deleteUser, editUser, enableUser, getAllUsersListByRole, sendAcctInfo } from "../../admin/actions/AdminActions";
import { studAcctsPredicate } from "../utils/utils";

const mapDispatchToProps = (dispatch, props) => {
  return {
    getUsersList(role) {
      dispatch(getAllUsersListByRole(role));
    },

    sendAcctInfo(studUserIds) {
      dispatch(sendAcctInfo(studUserIds));
    },

    offlineSearch(searchText, usersList) {
      if (searchText && searchText.length > 1) {
        const result = usersList.filter((examCenter) => {
          return studAcctsPredicate(examCenter, searchText);
        });
        dispatch({
          type: SET_FILTERED_USERS,
          payload: result,
        });
      } else if (!searchText) {
        dispatch({
          type: SET_FILTERED_USERS,
          payload: usersList,
        });
      }
    },

    resetMessages() {
      dispatch({
        type: RESET_MESSAGES,
        payload: {},
      });
    },

    toggleAddUserModal(showAddUserModal) {
      dispatch({
        type: EDIT_USER,
        payload: EMPTY_USER_DETAILS,
      });
      dispatch({
        type: TOGGLE_ADD_USER_MODAL,
        payload: showAddUserModal,
      });
    },

    editUser(userData, curProf) {
      if (curProf.userId) {
        userData.username = curProf.user.username;
        userData.userId = curProf.userId;
      }
      dispatch(editUser(userData)).then((response) => {
        this.getUsersList('MSSSTUD');
      });
    },

    deleteUser(userData) {
      dispatch(deleteUser(userData)).then((response) => {
        this.getUsersList('MSSSTUD');
      });
    },

    enableUser(userData) {
      dispatch(enableUser(userData)).then((response) => {
        this.getUsersList('MSSSTUD');
      });
    },


    setEditData(userData) {
      dispatch({
        type: EDIT_USER,
        payload: userData,
      });
    },

    showEditModal(userData, showEditUserModal) {
      dispatch({
        type: EDIT_USER,
        payload: userData,
      });
      dispatch({
        type: TOGGLE_ADD_USER_MODAL,
        payload: showEditUserModal,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, AdminReducer, AppConfigReducer } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    appConfig: AppConfigReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudAccounts);

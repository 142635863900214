import { Col, Empty } from "antd";
import React from "react";
import ViewSurveyCard from "./ViewSurveyCard";

class VolunteerCommentsView extends React.Component {
  generateFieldData = (data) => {
    const html = [];
    if (Array.isArray(data)) {
      for (const j of data) {
        for (const i in j) {
          html.push(
            <Col sm={6}>
              <label>{i}</label>:
            </Col>,
            <Col sm={6}>
              {" "}
              <span>{j[i]}</span>
            </Col>
          );
        }
      }
    } else {
      for (const i in data) {
        if (!Array.isArray(data[i])) {
          html.push(
            <Col sm={24}>
              <label>{i}</label>:
            </Col>,
            <Col sm={24}>
              {" "}
              <span>{data[i]}</span>
            </Col>
          );
        }
      }
    }

    return html;
  };
  render() {
    let info = this.props.data;
    if (!info) {
      return (
        <ViewSurveyCard title="Volunteer’s Comments" {...this.props}>
          <Empty />
        </ViewSurveyCard>
      );
    }
    return (
      <div>
        <ViewSurveyCard title="Volunteer’s Comments" {...this.props}>
          <Col sm={24}>
            <label>Please describe why student requires MCT scholarship</label>:
          </Col>
          <Col sm={24}>
            <span>{info.whyRequireMCT}</span>
          </Col>
          <Col sm={24}>
            <label>
              Is the student active and can the student adjust to lifestyle away
              from home
            </label>
            :
          </Col>
          <Col sm={24}>
            <span>{info.isStudentActive}</span>
          </Col>
          <Col sm={24}>
            <label>
              Were the student's family members truthful to you in all their
              responses
            </label>
            :
          </Col>
          <Col sm={24}>
            <span>{info.isParentsTruthful}</span>
          </Col>
          <Col sm={24}>
            <label>
              Please list any final observation and comments about the student
              family.
            </label>
            :
          </Col>
          <Col sm={24}>
            <span>{info.finalObservation}</span>
          </Col>
        </ViewSurveyCard>
      </div>
    );
  }
}

export default VolunteerCommentsView;

import { Modal } from "antd";
import React from "react";
import { APP_URI } from "../../../config/env";
import DocViewer, {PDFRenderer} from "react-doc-viewer";

const DocViewModal = (props) => {
  return (
    <React.Fragment>
       <Modal
        visible={true}
        title={props.file.fileName}
        onCancel={props.closeModal}
        footer={null}
        width={800}
      >
         {/* <FileViewer
        fileType={props.file.extension}
        filePath={APP_URI + "/upload/download?path=" + props.file.src}
      />  */}
        {/* <iframe
          title={props.file.fileName}
          src={APP_URI + "/upload/download?path=" + props.file.src}
          width="760"
          height="600"
        ></iframe> */}
        <DocViewer pluginRenderers={[PDFRenderer]} documents={[{uri:APP_URI + "/upload/download?path=" + props.file.src}]} 
           config={{
            header: {
             disableHeader: true,
             disableFileName: true,
             retainURLParams: false
            }
           }} />
      </Modal> 
      
    </React.Fragment>
  );
};

export default DocViewModal;

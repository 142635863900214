import { connect } from "react-redux";
import { withRouter } from "react-router";
import { RESET_MESSAGES } from "../../base/utils/const";
import {
  approveDrafts,
  deleteDrafts,
  draftImg,
  draftReqImg,
  getUploadsByProfIdAndType,
} from "../../management/actions/managementUploads";
import {
  fetchStudentProfileById,
  saveStudentMarks,
} from "../../management/actions/studentList";
import { SAVE_STUD_ACAD, SET_CUR_REQ, SET_EDIT_FORM } from "../../management/utils/const";
import { fetchMasterTableData } from "../../mastertables/actions/masterTables";
import { TOGGLE_ADD_MODAL } from "../../mastertables/utils/const";
import { getMarksConfig } from "../../sampoorna-siksha/actions/SSActions";
import { fetchMyStudentProfile } from "../actions/studAcctActions";
import AcademicProfile from "../components/AcademicProfile";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMasterTableData(dataType) {
      dispatch(fetchMasterTableData(dataType));
    },

    fetchMyStudentProfile(username) {
      dispatch(fetchMyStudentProfile(username));
    },

    fetchStudentProfileById(id) {
      dispatch(fetchStudentProfileById(id));
    },

    getMarksConfig() {
      dispatch(getMarksConfig());
    },

    saveStudentMarks(marksList, profileId) {
      dispatch(saveStudentMarks(marksList)).then((res) => {
        this.fetchStudentProfileById(profileId);
        if (res.action.type === `${SAVE_STUD_ACAD}_FULFILLED`) {
          this.approveDrafts(profileId, [marksList.uploadId]);
        }
      });
    },

    draftReqImg(studentProfileId, file, type, batch) {
      dispatch(draftReqImg(studentProfileId, file, type, batch));
    },

    setCurrentRequest(data) {
      dispatch({
        type: SET_CUR_REQ,
        payload: data,
      });
    },

    deleteDraft(studentProfileId) {
      dispatch(deleteDrafts(studentProfileId));
    },

    approveDrafts(studentProfileId, filesList) {
      dispatch(approveDrafts(studentProfileId, filesList));
    },

    getUploadsByProfIdAndType(profId, type) {
      dispatch(getUploadsByProfIdAndType(profId, type));
    },

    setEditForm(formName) {
      dispatch({
        type: SET_EDIT_FORM,
        payload: formName,
      });
    },

    toogleAddModal(modal) {
      dispatch({
        type: TOGGLE_ADD_MODAL,
        payload: modal,
      });
    },

    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    ManagementReducer,
    MasterTablesReducer,
    AppConfigReducer,
    ManagementUploadReducer,
  } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    manage: ManagementReducer,
    masterTable: MasterTablesReducer,
    appConfig: AppConfigReducer,
    mgUploads: ManagementUploadReducer,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AcademicProfile));

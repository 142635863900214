import { connect } from "react-redux";
import Auth from "../components/Auth";
import { withRouter } from "react-router";

const mapDispatchToProps = dispatch => {
    return {
     
    };
  };

  const mapStateToProps = state => {
    const { AuthReducer, AppConfigReducer } = state;
    return {
      isLoggedIn: AuthReducer.isLoggedIn,
      isLoading: AuthReducer.isLoading,
      isSuccess: AuthReducer.isSuccess,
      errorMessage: AuthReducer.errorMessage,
      successMessage: AuthReducer.successMessage,
      userContext: AuthReducer.userContext,
      lang: AuthReducer.lang,
      activeTab: AuthReducer.activeTab,
      role: AuthReducer.role,
      showLogin: AuthReducer.showLogin,
      appConfig: AppConfigReducer
    }
  }

  export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Auth));
import { connect } from "react-redux";
import EditProfileComponent from "../components/EditProfileComponent";
import { getCurrentUserDetails, editUser } from "../actions/AdminActions";
import { RESET_MESSAGES } from "../utils/consts";
import { withRouter } from "react-router";

const mapDispatchToProps = (dispatch, props) => {
    return {
      getCurrentUserDetails() {
        dispatch(getCurrentUserDetails());
      },
      editUser(userData, curProf) {
        if(curProf.userId) {
          userData.username = curProf.user.username;
          userData.userId = curProf.userId;
        }
        dispatch(editUser(userData)).then( response => {
          this.getCurrentUserDetails();
        });
      },
      resetMessages() {
        dispatch({
          type: RESET_MESSAGES,
          payload: {}
         });
       },
    };
  };

  const mapStateToProps = state => {
    const { AuthReducer, AdminReducer } = state;
    return {
      isLoading: AuthReducer.isLoading,
      profile: AuthReducer.profile,
      lang: AuthReducer.lang,
      role: AuthReducer.role,
      userProfile: AuthReducer.profile,
      admin: AdminReducer
    }
  };

  export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EditProfileComponent));
import React from "react";
import {
  Card,
  Collapse,
  Form,
  Col,
  Select,
  Row,
  Button,
  Spin,
  Table,
  Input,
  Drawer,
  notification,
  Modal,
} from "antd";
import { translate } from "../../base/actions/translate";
import { generateAppNum } from "../../student/util/util";
import {
  ACCPT,
  ALL_STATUS_LIST,
  INCOMP,
  REJECC,
  REJECD,
  WORKFLOW,
} from "../utils/consts";
import ApplicationDetails from "./ApplicationDetails";
import { ALL } from "../../base/utils/roles";
import { getStatusCode } from "../utils/util";
import moment from "moment";
// import XLSX from 'xlsx';

const { Panel } = Collapse;
const { Option } = Select;
const { Search, TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class ApplicationsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldData: {
        distId: "",
        mandalId: "",
        schoolId: "",
        currentPage: 1,
        filters: {},
        sorter: {},
      },
      currentDataSource: [],
      activePanels: "",
      currentRecord: {},
      showDetails: false,
      disableFields: true,
      reasonsList: [],
      showReasonModal: false,
      reason: {
        reason: "",
        otherReason: "",
        status: "",
        errorMsg: "",
      },
      currentIndex: 0,
      selectedRowKeys: [],
    };

    this.formRef = React.createRef();
    this.searchFormRef = React.createRef();
    props.getDistricts();
    props.setSearchApplication([]);
    props.setSearchFilters({});
  }

  onSelectChange = (selectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  toCapital = (e) => {
    if (e.target.value) {
      return e.target.value.toUpperCase();
    }
    return e.target.value;
  };

  populateMandals = (value) => {
    this.props.getMandalsList(value);
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.distId = value;
    fieldData.mandalId = "";
    fieldData.schoolId = "";
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
  };

  populateSchools = (value) => {
    this.props.getSchoolsList(value);
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.mandalId = value;
    fieldData.schoolId = "";
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
  };

  searchApplications = (form) => {
    // console.log("-------", form);
    form.currentPage = 1;
    // form.pageSize = 10;
    // form.sortOrder = 'asc';
    // form.sortField = 'applicationId';
    form.filters = {};
    form.sorter = {};
    // form.sorter = this.state.fieldData?.sorter;
    form.searchText = "";
    this.setState({ fieldData: form, activePanels: "", currentDataSource: [] });
    this.props.searchApplications(form);
    this.props.setSearchFilters(form);
  };

  resetSearchForm = () => {
    this.searchFormRef.current.resetFields();
    this.searchFormRef.current.submit();
  };

  showDetails = (record, index, e) => {
    // console.log("-------------", record, e);
    for (
      let i = 0;
      i < this.props.sampurnaSiksha.searchedApplications.length;
      i++
    ) {
      if (
        record.applicationId ===
        this.props.sampurnaSiksha.searchedApplications[i].applicationId
      ) {
        index = i;
        break;
      }
    }
    this.setState({
      currentRecord: record,
      showDetails: true,
      currentIndex: index,
    });
    this.props.toggleDetails(true);
    this.props.getApplicationDetails(record);
    this.props.getAppHistory(record.applicationId);
  };

  showNext = () => {
    const { currentIndex, currentRecord, currentDataSource } = this.state;
    this.props.deleteDraft(currentRecord.applicationId);
    const dataSource =
      currentDataSource && currentDataSource.length > 0
        ? currentDataSource
        : this.props.sampurnaSiksha.searchedApplications;
    const currentRec = dataSource[currentIndex + 1];
    // console.log('------------', currentRec.status);
    this.setState({
      currentRecord: currentRec,
      currentIndex: currentIndex + 1,
    });
    this.props.getApplicationDetails(currentRec);
    this.props.getAppHistory(currentRec.applicationId);
  };

  showPrevious = () => {
    const { currentIndex, currentDataSource } = this.state;
    this.props.deleteDraft(this.state.currentRecord.applicationId);
    const dataSource =
      currentDataSource && currentDataSource.length > 0
        ? currentDataSource
        : this.props.sampurnaSiksha.searchedApplications;
    const currentRecord = dataSource[currentIndex - 1];
    this.setState({
      currentRecord: currentRecord,
      currentIndex: currentIndex - 1,
    });
    this.props.getApplicationDetails(currentRecord);
    this.props.getAppHistory(currentRecord.applicationId);
  };

  exportToExcel = () => {
    this.props.exportToExcel(this.props.sampurnaSiksha.ssSearchFilters);
  };

  onClose = () => {
    this.setState({ showDetails: false, disableFields: true });
    this.props.toggleDetails(false);
    // console.log("form ref.... : ", this.formRef);
    this.props.getDistricts();
    this.props.deleteDraft(this.state.currentRecord.applicationId);
  };

  enableFields = () => {
    this.setState({ disableFields: false });
  };

  reasonSelected = (value) => {
    const reason = this.state.reason;
    reason.reason = value;
    this.setState({ reason: reason });
  };

  otherReasonAdded = (e) => {
    // if(e.target.value && e.target.value.trim()) {
    const otherReason =
      e.target.value.trim().length > 0 ? e.target.value : e.target.value.trim();
    const reason = this.state.reason;
    reason.otherReason = otherReason;
    this.setState({ reason: reason });
    // }
  };

  renderReasonModal = (nextStep) => {
    const reason = {
      reason: "",
      otherReason: "",
      status: nextStep.step,
      errorMsg: "",
    };
    // console.log("Reasons ------ : ", nextStep);
    this.setState({
      reasonsList: nextStep.reasons,
      showReasonModal: true,
      reason: reason,
    });
    this.props.toggleReasonModal(true);
  };

  closeReasonModal = () => {
    const reason = {
      reason: "",
      otherReason: "",
      status: "",
      errorMsg: "",
    };
    this.setState({ reasonsList: [], showReasonModal: false, reason: reason });
    this.props.toggleReasonModal(false);
  };

  validateReason = () => {
    const reason = this.state.reason;
    const reasonView = {
      status: reason.status,
      applicationId: this.state.currentRecord.applicationId,
    };
    if (reason.reason && reason.reason.indexOf("Other") === -1) {
      reasonView.reason = reason.reason;
    } else if (reason.reason && reason.reason.indexOf("Other") > -1) {
      reasonView.reason = reason.otherReason;
    }

    if (!reasonView.reason) {
      reason.errorMsg = "Reason is mandatory...";
      this.setState({ reason: reason });
    } else {
      this.props.changeStatus(
        reasonView,
        this.props.sampurnaSiksha.ssSearchFilters,
        this.state.currentRecord
      );
    }
  };

  showUploadFileMsg = () => {
    const msg = [];
    const { studentImage, studentIdProof, ninthMemoBack, ninthMemoFront } =
      this.props.student;
    if (!studentImage.url) {
      msg.push("Please upload all required files");
    } else if (!studentIdProof.url) {
      msg.push("Please upload all required files");
    } else if (!ninthMemoFront.url) {
      msg.push("Please upload all required files");
    } else if (!ninthMemoBack.url) {
      msg.push("Please upload all required files");
    }
    notification["error"]({
      message: "Error Notification",
      description: msg,
    });
  };

  changeStatusTo = (nextStep) => {
    debugger;
    if (
      this.props.student.uploadFieldData.length < 4 &&
      nextStep.step != REJECD &&
      nextStep.step != REJECC
    ) {
      notification["error"]({
        message: "Error Notification",
        description: "Please upload all required files",
      });
      return;
    }
    if (nextStep.requireReason) {
      this.renderReasonModal(nextStep);
    } else {
      // console.log("Next status ........ : ", nextStep);
      const reasonView = {
        status: nextStep.step,
        applicationId: this.state.currentRecord.applicationId,
        reason: "",
      };
      this.props.changeStatus(
        reasonView,
        this.props.sampurnaSiksha.ssSearchFilters,
        this.state.currentRecord
      );
    }
  };

  bulkApproveApplications = () => {
    this.props.applicationBulkApprove(
      this.state.selectedRowKeys,
      this.props.sampurnaSiksha.ssSearchFilters
    );
    this.setState({ selectedRowKeys: [] });
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.currentPage = 1;
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
  };

  bulkRejectApplications = (draft) => {
    debugger;
    if (draft) {
      this.props.applicationBulkRejectD(
        this.state.selectedRowKeys,
        this.props.sampurnaSiksha.ssSearchFilters
      );
    } else {
      this.props.applicationBulkReject(
        this.state.selectedRowKeys,
        this.props.sampurnaSiksha.ssSearchFilters
      );
    }
    this.setState({ selectedRowKeys: [] });
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.currentPage = 1;
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
  };

  renderWorkflowButtons = (steps) => {
    const buttons = [];

    if (!steps) {
      return;
    }
    for (const step of steps) {
      buttons.push(
        <span>
          {(step.user.indexOf(this.props.role) > -1 ||
            step.user.indexOf(ALL) > -1) && (
            <Button
              onClick={(e) => this.changeStatusTo(step)}
              type="primary"
              style={{ marginRight: 8 }}
            >
              {" "}
              {translate(getStatusCode(step.step).key)}{" "}
            </Button>
          )}
        </span>
      );
    }

    return buttons;
  };

  isAfterExamDate = () => {
    let examDate = "";
    const sysConfig = this.props.appConfig.systemConfig;
    for (const config of sysConfig) {
      if (config.configCd === "EXAM_DT") {
        examDate = moment(config.configVal);
      }
    }

    return examDate && moment().isSameOrAfter(examDate);
  };

  generateActionButtons = () => {
    const statusId = this.props.student.applicationForm.status
      ? this.props.student.applicationForm.status
      : this.state.currentRecord.status;
    const status = getStatusCode(statusId)?.key;
    return (
      <div style={{ textAlign: "right" }}>
        {((statusId < 8 && !this.isAfterExamDate()) ||
          "ADMIN" === this.props.role) && (
          <Button
            onClick={(e) => {
              this.formRef.current.submit();
            }}
            type="primary"
            style={{ marginRight: 8 }}
          >
            {" "}
            Save{" "}
          </Button>
        )}
        {status &&
          !this.isAfterExamDate() &&
          this.renderWorkflowButtons(WORKFLOW[status])}
        <Button onClick={this.onClose} style={{ marginRight: 8 }}>
          Cancel
        </Button>
      </div>
    );
  };

  saveApplication = (form, application, username, uploadCatList) => {
    if (uploadCatList.length > 0) {
      this.props.saveDraftFiles(
        form,
        application,
        username,
        this.props.sampurnaSiksha.ssSearchFilters,
        uploadCatList
      );
    } else {
      this.props.saveMssApplicationForm(
        form,
        application,
        username,
        this.props.sampurnaSiksha.ssSearchFilters
      );
    }
    this.setState({ disableFields: true });
  };

  showNotifications = () => {
    let message = "";
    let placement = "topRight";
    if (this.props.sampurnaSiksha.showDetails) {
      placement = "topLeft";
    }

    if (this.props.sampurnaSiksha.successMessage) {
      message += this.props.sampurnaSiksha.successMessage;
    }

    if (message) {
      notification.success({
        message: "Success",
        description: message,
        duration: 1,
        placement,
      });
      this.props.resetMessage();
    }
  };

  sortNames = (a, b) => {
    const alphabet = "*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyz";
    var index_a = alphabet.indexOf(a[0]),
      index_b = alphabet.indexOf(b[0]);

    if (index_a === index_b) {
      // same first character, sort regular
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return index_a - index_b;
    }
  };

  offlineSearchApplication = (value) => {
    const filters = this.props.sampurnaSiksha.ssSearchFilters;
    filters.searchText = value.toUpperCase();
    this.props.offlineSearchApplication(
      filters,
      this.props.sampurnaSiksha.applications
    );
    this.setState({ fieldData: filters });
  };

  onFiltersChange = (pagination, filters, sorter, extra) => {
    // console.log(
    //   "on filter change ------ : ",
    //   pagination,
    //   filters,
    //   sorter,
    //   extra
    // );
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.filters = filters;
    fieldData.sorter = sorter;
    this.setState({ fieldData, currentDataSource: extra.currentDataSource });
    this.props.setSearchFilters(fieldData);
    this.props.setSearchApplication(extra.currentDataSource);
  };

  onPageChange = (page, pageSize) => {
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.currentPage = page;
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
  };

  formChange = (val, all) => {
    console.log(val, all);
    const searchFilters = this.props.sampurnaSiksha.ssSearchFilters;
    for (const i of Object.keys(val)) {
      searchFilters[i] = val[i];
    }
    this.props.setSearchFilters(searchFilters);
  };

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const searchFilters = this.props.sampurnaSiksha.ssSearchFilters;
    const sortedInfo = searchFilters.sorter || {};
    const fieldData = [];
    for (let i in this.props.sampurnaSiksha.ssSearchFilters) {
      fieldData.push({
        name: [i],
        value: this.props.sampurnaSiksha.ssSearchFilters[i],
      });
    }

    const dataSource = this.props.sampurnaSiksha.searchedApplications;

    const USERS_LIST_COLS = [
      {
        title: "APPLICATION NO",
        dataIndex: "applicationId",
        key: "applicationId",
        render: (text, record) => generateAppNum(record),
        sorter: {
          compare: (a, b) =>
            this.sortNames(generateAppNum(a), generateAppNum(b)),
        },
        sortOrder: sortedInfo.columnKey === "applicationId" && sortedInfo.order,
      },
      {
        title: "STUDENT NAME",
        dataIndex: "studentName",
        key: "studentName",
        render: (text, record) =>
          record.studentName + " " + record.studentSurName,
        sorter: {
          compare: (a, b) =>
            this.sortNames(
              a.studentName + " " + a.studentSurName,
              b.studentName + " " + b.studentSurName
            ),
        },
        sortOrder: sortedInfo.columnKey === "studentName" && sortedInfo.order,
      },
      {
        title: "DISTRICT",
        dataIndex: "districtId",
        key: "districtId",
        render: (text, record) => record.school.parent?.parent?.configVal,
        sorter: {
          compare: (a, b) =>
            this.sortNames(
              a.school.parent.parent.configVal,
              b.school.parent.parent.configVal
            ),
        },
        sortOrder: sortedInfo.columnKey === "districtId" && sortedInfo.order,
      },
      {
        title: "MANDAL",
        dataIndex: "mandalId",
        key: "mandalId",
        render: (text, record) => record.school.parent.configVal,
        sorter: {
          compare: (a, b) =>
            this.sortNames(
              a.school.parent.configVal,
              b.school.parent.configVal
            ),
        },
        sortOrder: sortedInfo.columnKey === "mandalId" && sortedInfo.order,
      },
      {
        title: "SCHOOL",
        dataIndex: "schoolId",
        key: "schoolId",
        render: (text, record) => record.school.configVal,
        sorter: {
          compare: (a, b) =>
            this.sortNames(a.school.configVal, b.school.configVal),
        },
        sortOrder: sortedInfo.columnKey === "schoolId" && sortedInfo.order,
      },
      {
        title: "9th CLASS GRADE",
        dataIndex: "ninthGrade",
        key: "ninthGrade",
        sorter: {
          compare: (a, b) => this.sortNames(a.ninthGrade, b.ninthGrade),
        },
        sortOrder: sortedInfo.columnKey === "ninthGrade" && sortedInfo.order,
      },
      {
        title: "MEDIUM",
        dataIndex: "medium",
        key: "medium",
        render: (text, record) => translate(record.medium),
        sorter: {
          compare: (a, b) => this.sortNames(a.medium, b.medium),
        },
        sortOrder: sortedInfo.columnKey === "medium" && sortedInfo.order,
      },
      {
        title: "CASTE",
        dataIndex: "casteId",
        key: "casteId",
        render: (text, record) => record.caste.configVal,
        sorter: {
          compare: (a, b) =>
            this.sortNames(a.caste.configVal, b.caste.configVal),
        },
        sortOrder: sortedInfo.columnKey === "casteId" && sortedInfo.order,
      },
      {
        title: "STATUS",
        dataIndex: "status",
        key: "status",
        render: (text, record) => translate(getStatusCode(record.status).key),
        sorter: {
          compare: (a, b) => a.status - b.status,
        },
        sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
      },
      {
        title: "HALL TICKET",
        dataIndex: "hallTicketId",
        key: "hallTicketId",
        render: (text, record) => record.hallTicket?.hallTicketNo,
        sorter: {
          compare: (a, b) =>
            a.hallTicket?.hallTicketNo - b.hallTicket?.hallTicketNo,
        },
        sortOrder: sortedInfo.columnKey === "hallTicketId" && sortedInfo.order,
      },
    ];
    return (
      <div>
        <Card>
          <Collapse>
            <Panel header="Application Search Filters" key="1">
              <Form
                {...layout}
                onFinish={this.searchApplications}
                className="app-search-form"
                fields={fieldData}
                onValuesChange={(val, allVal) => this.formChange(val, allVal)}
                // initialValues={searchFilters}
                ref={this.searchFormRef}
              >
                <Row>
                  <Col xs={24} sm={8}>
                    <Form.Item
                      {...formItemLayout}
                      name="distId"
                      label={translate("district")}
                    >
                      <Select
                        className="full-width"
                        onChange={this.populateMandals}
                      >
                        {this.props.districtsList.map((district) => (
                          <Option key={district.id} value={district.id}>
                            {district.configVal}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8}>
                    <Form.Item
                      {...formItemLayout}
                      name="mandalId"
                      label={translate("mandal")}
                    >
                      <Select
                        className="full-width"
                        onChange={this.populateSchools}
                      >
                        {this.props.mandalsList.map((mandal) => (
                          <Option key={mandal.id} value={mandal.id}>
                            {mandal.configVal}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8}>
                    <Form.Item
                      {...formItemLayout}
                      name="schoolId"
                      label={translate("school")}
                    >
                      <Select onChange={this.checkSchool}>
                        {this.props.schoolsList.map((config) => (
                          <Option key={config.id} value={config.id}>
                            {config.configVal}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8}>
                    <Form.Item {...formItemLayout} name="status" label="Status">
                      <Select className="full-width" mode="multiple">
                        {ALL_STATUS_LIST.map((statusCd) => (
                          <Option key={statusCd.key} value={statusCd.id}>
                            {translate(statusCd.key)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8}>
                    <Form.Item
                      {...formItemLayout}
                      name="applicationNo"
                      label="Application Number"
                      getValueFromEvent={this.toCapital}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8}>
                    <Button
                      onClick={this.exportToExcel}
                      className="mg-l-l"
                      loading={this.props.sampurnaSiksha.isExporting}
                    >
                      Export Excel
                    </Button>
                    <Button
                      onClick={(e) => this.resetSearchForm()}
                      className="mg-l-l"
                    >
                      Reset
                    </Button>
                    <Button type="primary" htmlType="submit" className="mg-l-l">
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Card>
        <Card>
          <Search
            placeholder="Search"
            value={this.props.sampurnaSiksha.ssSearchFilters.searchText}
            onChange={(e) => this.offlineSearchApplication(e.target.value)}
            onSearch={(value) => this.offlineSearchApplication(value)}
            enterButton
            style={{ marginBottom: "10px" }}
          />
          {searchFilters.status &&
            searchFilters.status.length === 1 &&
            searchFilters.status.indexOf(ACCPT) > -1 &&
            "ADMIN" === this.props.role && (
              <Button
                type="primary"
                onClick={(e) => this.bulkApproveApplications()}
                disabled={this.state.selectedRowKeys.length === 0}
              >
                Approve
              </Button>
            )}
          {searchFilters.status &&
            searchFilters.status.length === 1 &&
            searchFilters.status.indexOf(REJECD) > -1 &&
            "ADMIN" === this.props.role && (
              <Button
                type="primary"
                onClick={(e) => this.bulkRejectApplications(false)}
                disabled={this.state.selectedRowKeys.length === 0}
              >
                Rejected C
              </Button>
            )}
          {searchFilters.status &&
            searchFilters.status.length === 1 &&
            searchFilters.status.indexOf(INCOMP) > -1 &&
            "ADMIN" === this.props.role && (
              <Button
                type="primary"
                onClick={(e) => this.bulkRejectApplications(true)}
                disabled={this.state.selectedRowKeys.length === 0}
              >
                Rejected D
              </Button>
            )}
          <Spin
            spinning={
              this.props.sampurnaSiksha.loading &&
              !this.props.sampurnaSiksha.showDetails
            }
            delay={500}
          >
            {searchFilters.status &&
              searchFilters.status.length === 1 &&
              (searchFilters.status.indexOf(ACCPT) > -1 ||
                searchFilters.status.indexOf(REJECD) > -1 ||
                searchFilters.status.indexOf(INCOMP) > -1) &&
              "ADMIN" === this.props.role && (
                <Table
                  columns={USERS_LIST_COLS}
                  className="row-selection"
                  rowSelection={rowSelection}
                  rowKey={(record) => record.applicationId}
                  onChange={this.onFiltersChange}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (e) => {
                        this.showDetails(record, rowIndex, e);
                      }, // click row
                    };
                  }}
                  dataSource={this.props.sampurnaSiksha.searchedApplications}
                  pagination={{
                    current: searchFilters.currentPage,
                    onChange: this.onPageChange,
                  }}
                />
              )}
            {(!searchFilters.status ||
              searchFilters.status.length !== 1 ||
              (searchFilters.status.indexOf(ACCPT) === -1 &&
                searchFilters.status.indexOf(REJECD) === -1 &&
                searchFilters.status.indexOf(INCOMP)) ||
              "ADMIN" !== this.props.role) && (
              <Table
                columns={USERS_LIST_COLS}
                className="row-selection"
                rowKey={(record) => record.applicationId}
                onChange={this.onFiltersChange}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      this.showDetails(record, rowIndex, e);
                    }, // click row
                  };
                }}
                dataSource={this.props.sampurnaSiksha.searchedApplications}
                pagination={{
                  current: searchFilters.currentPage,
                  onChange: this.onPageChange,
                  showSizeChanger: true,
                }}
              />
            )}
          </Spin>
        </Card>

        <Drawer
          title={
            <div>
              <h4>
                {this.state.currentRecord.applicationId && (
                  <strong>
                    Application Number:{" "}
                    {generateAppNum(this.state.currentRecord)}
                  </strong>
                )}
                <strong style={{ marginLeft: "10px", color: "#2388e7" }}>
                  Status:{" "}
                  {translate(
                    getStatusCode(this.props.student.applicationForm?.status)
                      ?.key
                  )}
                </strong>
                <strong style={{ marginLeft: "10px", marginRight: "20px" }}>
                  Registered Mobile Number: {this.state.currentRecord.username}
                </strong>
                {/* <Button onClick={(e) => this.enableFields()} style={{ marginRight: 8, float: "right" }}>
                                Edit
                        </Button> */}
                {this.state.currentIndex < dataSource.length - 1 && (
                  <Button
                    onClick={(e) => this.showNext()}
                    style={{ marginRight: "10px", float: "right" }}
                  >
                    Next
                  </Button>
                )}
                {this.state.currentIndex > 0 && (
                  <Button
                    onClick={(e) => this.showPrevious()}
                    style={{ marginRight: 8, float: "right" }}
                  >
                    Previous
                  </Button>
                )}
              </h4>
            </div>
          }
          footer={this.generateActionButtons()}
          width="100%"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.props.sampurnaSiksha.showDetails}
        >
          {this.props.student.applicationForm.applicationId && (
            <ApplicationDetails
              formRef={this.formRef}
              currentRecord={this.state.currentRecord}
              disableFields={this.state.disableFields}
              saveApplication={this.saveApplication}
              {...this.props}
            ></ApplicationDetails>
          )}
        </Drawer>
        {(this.props.student.successMessage ||
          this.props.sampurnaSiksha.successMessage) &&
          this.showNotifications()}

        <Modal
          title="Give a reason"
          visible={this.props.sampurnaSiksha.showReasonModal}
          onOk={this.validateReason}
          onCancel={this.closeReasonModal}
          maskClosable={false}
        >
          <div>
            <Spin spinning={this.props.sampurnaSiksha.loading} delay={500}>
              <div style={{ margin: "8px" }}>
                <label>Reason</label>
                <Select
                  className="full-width"
                  onChange={this.reasonSelected}
                  value={this.state.reason.reason}
                >
                  {this.state.reasonsList.map((reason) => (
                    <Option key={reason} value={reason}>
                      {reason}
                    </Option>
                  ))}
                </Select>
              </div>
              {this.state.reason.reason.indexOf("Other") > -1 && (
                <div style={{ margin: "8px" }}>
                  <label>Other Reason</label>
                  <TextArea
                    maxLength="200"
                    value={this.state.reason.otherReason}
                    onChange={this.otherReasonAdded}
                  />
                </div>
              )}
            </Spin>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ApplicationsList;

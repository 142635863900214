import { connect } from "react-redux";
import {
  fetchCasteCategory,
  fetchConfigByTypeAndParentId,
  fetchDistricts,
  fetchMandals,
  fetchOldDistricts,
  fetchSchools,
  getSystemConfigs,
  saveSystemConfigs,
} from "../../base/actions/AppConfigActions";
import {
  RESET_MESSAGES,
  TOGGLE_ADHOC_SMS,
  TOGGLE_EDIT_SMS,
  TOGGLE_SEND_SMS,
  TOGGLE_SMS_LOGS,
} from "../utils/consts";
import SMSTemplates from "../components/SMSTemplates";
import {
  getAllSMSLogs,
  getAllSMSTemplates,
  saveSMSTemplate,
  sendBulkSMS,
  sendSMS,
} from "../actions/SMSActions";

const mapDispatchToProps = (dispatch, props) => {
  return {
    getAllSMSTemplates() {
      dispatch(getAllSMSTemplates());
    },
    saveSMSTemplate(form) {
      dispatch(saveSMSTemplate(form)).then(
        res => {
          this.getAllSMSTemplates();
        }
      );
    },
    sendSMS(form) {
      dispatch(sendSMS(form));
    },
    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
    toggleSMSEdit() {
      dispatch({
        type: TOGGLE_EDIT_SMS,
      });
    },
    toggleSendSMS() {
      dispatch({
        type: TOGGLE_SEND_SMS,
      });
    },
    toggleSMSLogs() {
      dispatch({
        type: TOGGLE_SMS_LOGS,
      });
    },
    toggleAdhocSMS() {
      dispatch({
        type: TOGGLE_ADHOC_SMS,
      });
    },

    getOldDistricts() {
      dispatch(fetchOldDistricts());
    },

    getDistricts() {
      dispatch(fetchDistricts());
    },

    getMandalsList(districtId) {
      dispatch(fetchMandals(districtId));
    },

    getSchoolsList(mandalId) {
      dispatch(fetchSchools(mandalId));
    },

    getCastes() {
      dispatch(fetchCasteCategory());
    },

    fetchConfigByTypeAndParentId(type, parentId) {
      dispatch(fetchConfigByTypeAndParentId(type, parentId));
    },
    sendBulkSMS(form) {
      dispatch(sendBulkSMS(form));
    },
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    SSReducer,
    AppConfigReducer,
    SMSReducer,
  } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    sampurnaSiksha: SSReducer,
    appConfig: AppConfigReducer,
    sms: SMSReducer,
    oldDistrictsList: AppConfigReducer.oldDistrictsList,
    districtsList: AppConfigReducer.districtsList,
    mandalsList: AppConfigReducer.mandalsList,
    schoolsList: AppConfigReducer.schoolsList,
    castesList: AppConfigReducer.castesList,
    examCenters: AppConfigReducer.examCenters,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SMSTemplates);

import { Spin } from "antd";
import React from "react";
import StudentAcademics from "../../management/components/academics/StudentAcademics";
import { INST } from "../../mastertables/utils/const";

class AcademicProfile extends React.Component {
  componentDidMount() {
    this.props.getMarksConfig();
    this.props.fetchMasterTableData(INST);
    if (this.props.profile.username) {
      this.props.fetchMyStudentProfile(this.props.profile.username);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile.username !== this.props.profile.username) {
      this.props.fetchMyStudentProfile(this.props.profile.username);
    }
  }

  render() {
    return (
      <div>
        <Spin spinning={!this.props.manage.currentProfile.studentProfileId}>
          {this.props.manage.currentProfile.studentProfileId && (
            <StudentAcademics {...this.props} />
          )}
        </Spin>
      </div>
    );
  }
}

export default AcademicProfile;

import React from "react";
import { Row, Col, Form, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { translate } from "../../base/actions/translate";
import {
  STUD_IMG,
  MEMO_NINTH_FRONT,
  STUD_ID,
  MEMO_NINTH_BACK,
  IMAGE_TYPES,
  TYPE_PDF,
} from "../util/const";
import PDF_ICON from "../../assets/images/pdfIcon.png";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};
class UploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      previewVisible: false,
      previewImage: "",
      uploadErrors: {
        studentImage: "",
        studentId: "",
        ninthMemoFront: "",
        ninthMemoBack: "",
      },
    };

    this.uploadStudentImg = this.uploadStudentImg.bind(this);
    this.uploadStudentIdProof = this.uploadStudentIdProof.bind(this);
    this.uploadNinthMemoFront = this.uploadNinthMemoFront.bind(this);
    this.uploadNinthMemoBack = this.uploadNinthMemoBack.bind(this);

    this.beforeUploadStudentImg = this.beforeUploadStudentImg.bind(this);
    this.beforeUploadStudentIdProof = this.beforeUploadStudentIdProof.bind(
      this
    );
    this.beforeUploadNinthMemoFront = this.beforeUploadNinthMemoFront.bind(
      this
    );
    this.beforeUploadNinthMemoBack = this.beforeUploadNinthMemoBack.bind(this);
  }

  componentDidMount() {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 500);
  }

  uploadStudentImg(file) {
    if (this.isValidImg(file)) {
      this.props.uploadStudentDoc(
        this.props.studentData.applicationForm.applicationId,
        file,
        STUD_IMG
      );
    }
  }

  uploadStudentIdProof(file) {
    if (this.isValidDoc(file))
      this.props.uploadStudentDoc(
        this.props.studentData.applicationForm.applicationId,
        file,
        STUD_ID
      );
  }

  uploadNinthMemoFront(file) {
    if (this.isValidDoc(file))
      this.props.uploadStudentDoc(
        this.props.studentData.applicationForm.applicationId,
        file,
        MEMO_NINTH_FRONT
      );
  }

  uploadNinthMemoBack(file) {
    if (this.isValidDoc(file))
      this.props.uploadStudentDoc(
        this.props.studentData.applicationForm.applicationId,
        file,
        MEMO_NINTH_BACK
      );
  }

  normFile = (e) => {
    // console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.file;
  };

  isValidDoc(fileObj) {
    const file = fileObj.file;
    const isJpgOrPngOrPdf =
      IMAGE_TYPES.includes(file.type) || file.type === "application/pdf";
    const isLt500KB = file.size / 1024 < 500;
    return isJpgOrPngOrPdf && isLt500KB;
  }

  isValidImg(fileObj) {
    const file = fileObj.file;
    const isJpgOrPng = IMAGE_TYPES.includes(file.type);
    const isLt500KB = file.size / 1024 < 500;
    return isJpgOrPng && isLt500KB;
  }

  beforeUploadStudentImg(file) {
    this.beforeUpload(file, "studentImage");
  }

  beforeUploadStudentIdProof(file) {
    this.beforeUploadDoc(file, "studentId");
  }

  beforeUploadNinthMemoFront(file) {
    this.beforeUploadDoc(file, "ninthMemoFront");
  }

  beforeUploadNinthMemoBack(file) {
    this.beforeUploadDoc(file, "ninthMemoBack");
  }

  beforeUploadDoc(file, fieldName) {
    const uploadErrors = this.state.uploadErrors;
    if (!this.isValidDoc({ file })) {
      uploadErrors[fieldName] = translate("photoInstructions");
    } else {
      uploadErrors[fieldName] = "";
    }
    this.setState({ uploadErrors });
    return this.isValidDoc({ file });
  }

  beforeUpload(file, fieldName) {
    const uploadErrors = this.state.uploadErrors;
    if (!this.isValidImg({ file })) {
      uploadErrors[fieldName] = translate("passportPhotoInst");
    } else {
      uploadErrors[fieldName] = "";
    }
    this.setState({ uploadErrors });
    return this.isValidImg({ file });
  }

  handleDisplayImage = (file, hasNoError) => {
    if (hasNoError && file.url) {
      if (file.type === "application/pdf") {
        return (
          <img
            src={PDF_ICON}
            alt="avatar"
            style={{ width: "100%", height: "150px" }}
          />
        );
      } else {
        return (
          <img
            src={file.url}
            alt="avatar"
            style={{ width: "100%", height: "150px" }}
          />
        );
      }
    } else {
      return (
        <div>
          {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div className="ant-upload-text">Upload</div>
        </div>
      );
    }
  };

  getImage = (img) => {
    //{(studentIdProof.url && !this.state.uploadErrors.studentId) ? ({ (studentIdProof.type === TYPE_PDF) ? <img className="pdf-img" src="/images/pdfIcon.png" alt="PDF" />:<img src={studentIdProof.url} alt="avatar" style={{ width: '100%' }} />}) : uploadButton}
    if (img.url && !this.state.uploadErrors.studentId) {
      if (img.type === TYPE_PDF) {
        return <img className="pdf-img" src={PDF_ICON} alt="PDF" />;
      } else {
        return (
          <img
            src={img.url}
            alt="avatar"
            style={{ width: "100%", height: "150px" }}
          />
        );
      }
    } else {
      return (
        <div>
          {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div className="ant-upload-text">Upload</div>
        </div>
      );
    }
  };

  render() {
    const {
      studentImage,
      studentIdProof,
      ninthMemoBack,
      ninthMemoFront,
      uploadErrors,
    } = this.props.studentData;
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div>
        <div className="inline-block uploadDocs">
          <h3>{translate("uploadHeading")}</h3>
        </div>
        <Row>
          <Col xs={24} sm={20}>
            <Row justify="space-between">
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="studentImage"
                  className="psport"
                  label={translate("studentPassportPhoto")}
                  valuePropName="file"
                  getValueFromEvent={this.normFile}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value) {
                          const isJpgOrPngOrPdf = IMAGE_TYPES.includes(
                            value.type
                          );
                          const isLt500KB = value.size / 1024 < 500;
                          if (isJpgOrPngOrPdf && isLt500KB) {
                            return Promise.resolve();
                          }
                        }
                        return Promise.reject(
                          translate("studentPassportPhotoError")
                        );
                      },
                    }),
                  ]}
                  extra={translate("passportPhotoInst")}
                >
                  <Upload
                    name="studentImage"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={this.uploadStudentImg}
                    beforeUpload={this.beforeUploadStudentImg}
                    // onChange={this.handleChange}
                  >
                    {studentImage.url &&
                    !this.state.uploadErrors.studentImage ? (
                      <img
                        src={studentImage.url}
                        alt="avatar"
                        style={{ width: "100%", height: "150px" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
                {uploadErrors.studentImage && (
                  <div className="img-instructions">
                    {translate("passportPhotoInst")}
                  </div>
                )}
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  className="id-proof"
                  name="studentIdProof"
                  label={translate("StudentIdentificationForm")}
                  valuePropName="file"
                  getValueFromEvent={this.normFile}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value) {
                          const isJpgOrPngOrPdf =
                            IMAGE_TYPES.includes(value.type) ||
                            value.type === "application/pdf";
                          const isLt500KB = value.size / 1024 < 500;
                          if (isJpgOrPngOrPdf && isLt500KB) {
                            return Promise.resolve();
                          }
                        }
                        return Promise.reject(
                          translate("StudentIdentificationFormError")
                        );
                      },
                    }),
                  ]}
                  extra={translate("photoInstructions")}
                >
                  <Upload
                    name="studentId"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={this.uploadStudentIdProof}
                    beforeUpload={this.beforeUploadStudentIdProof}
                    // onChange={this.handleChange}
                  >
                    {this.getImage(studentIdProof)}
                  </Upload>
                </Form.Item>
                {uploadErrors.studentId && (
                  <div className="img-instructions">
                    {translate("photoInstructions")}
                  </div>
                )}
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="ninthMemoFront"
                  label={translate("ninthMarksMemoFront")}
                  valuePropName="file"
                  getValueFromEvent={this.normFile}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value) {
                          const isJpgOrPngOrPdf =
                            IMAGE_TYPES.includes(value.type) ||
                            value.type === "application/pdf";
                          const isLt500KB = value.size / 1024 < 500;
                          if (isJpgOrPngOrPdf && isLt500KB) {
                            return Promise.resolve();
                          }
                        }
                        return Promise.reject(
                          translate("ninthMarksMemoFrontError")
                        );
                      },
                    }),
                  ]}
                  extra={translate("ninthMemoExtraInfo")}
                >
                  <Upload
                    name="ninthMemoFront"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={this.uploadNinthMemoFront}
                    beforeUpload={this.beforeUploadNinthMemoFront}
                    // onChange={this.handleChange}
                  >
                    {this.handleDisplayImage(
                      ninthMemoFront,
                      !this.state.uploadErrors.ninthMemoFront
                    )}
                  </Upload>
                </Form.Item>
                {uploadErrors.ninthMemoFront && (
                  <div className="img-instructions">
                    {translate("photoHeadSign")}
                  </div>
                )}
              </Col>
              <Col xs={24} sm={12} className="pd-l">
                <Form.Item
                  {...formItemLayout}
                  name="ninthMemoBack"
                  label={translate("ninthMarksMemoBack")}
                  valuePropName="file"
                  getValueFromEvent={this.normFile}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value) {
                          const isJpgOrPngOrPdf =
                            IMAGE_TYPES.includes(value.type) ||
                            value.type === "application/pdf";
                          const isLt500KB = value.size / 1024 < 500;
                          if (isJpgOrPngOrPdf && isLt500KB) {
                            return Promise.resolve();
                          }
                        }
                        return Promise.reject(
                          translate("ninthMarksMemoBackError")
                        );
                      },
                    }),
                  ]}
                  extra={translate("photoInstructions")}
                >
                  <Upload
                    name="ninthMemoBack"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={this.uploadNinthMemoBack}
                    beforeUpload={this.beforeUploadNinthMemoBack}
                    // onChange={this.handleChange}
                  >
                    {this.handleDisplayImage(
                      ninthMemoBack,
                      !this.state.uploadErrors.ninthMemoBack
                    )}
                  </Upload>
                </Form.Item>
                {uploadErrors.ninthMemoBack && (
                  <div className="img-instructions">
                    {translate("photoInstructions")}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UploadForm;

import React from 'react';
import StatusSummaryContainer from '../containers/StatusSummaryContainer';
import ApplicationsListContainer from '../containers/ApplicationsListContainer';


class SampoornaSiksha extends React.Component {
    render() {
        return (
            <div>
                <StatusSummaryContainer></StatusSummaryContainer>
                <ApplicationsListContainer></ApplicationsListContainer>
            </div>
        );
    }
}

export default SampoornaSiksha;
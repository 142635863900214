import { Button, Modal, Space, Spin, Table, Tooltip } from "antd";
import React from "react";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import AddCourseModal from "./AddCourseModal";
import { exportFile } from "../../common/utils/export";

class Courses extends React.Component {
  state = {
    course: {},
  };

  componentDidMount() {
    this.props.fetchCourses();
  }

  handleAdd = () => {
    this.setState({ course: {} });
    this.props.toggleAddCourseModal(true);
  };

  edit = (record, e) => {
    e.preventDefault();
    this.setState({ course: record });
    this.props.toggleAddCourseModal(true);
  };

  delete = (record, e) => {
    e.preventDefault();
    const that = this;
    Modal.confirm({
      title: (<div>Do you want to delete this Course?
        <p>Note: It can only be deleted if there are no Institutes mapped to this Course.</p>
      </div>),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        that.props.deleteCourses(record.courseId);
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  exportData = (cols) => {
    exportFile(cols, this.props.masterTable.courses, "Courses");
  };

  render() {
    const COURSE_LIST_COLS = [
      {
        title: "Course Name",
        dataIndex: "courseName",
        key: "courseName",
        render: (text, record) => record.courseName,
        sorter: {
          compare: (a, b) => sortNames(a.courseName, b.courseName),
        },
      },
      {
        title: "Category",
        dataIndex: "categoryName",
        key: "categoryName",
        render: (text, record) => record.educationCategory.categoryName,
        sorter: {
          compare: (a, b) =>
            sortNames(
              a.educationCategory.categoryName,
              b.educationCategory.categoryName
            ),
        },
      },
      {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
        render: (text, record) => record.notes,
        sorter: {
          compare: (a, b) => sortNames(a.notes, b.notes),
        },
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <Tooltip title="Edit">
              <a href="/" onClick={(e) => this.edit(record, e)}>
                <EditOutlined />
              </a>
            </Tooltip>
            <Tooltip title="Delete">
              <a href="/" onClick={(e) => this.delete(record, e)}>
                <DeleteOutlined />
              </a>
            </Tooltip>
          </Space>
        ),
      },
    ];
    return (
      <div id="course-master-container">
        <h2>
          Courses
          <Button
            className="add-cat-button"
            onClick={this.handleAdd}
            type="primary"
            style={{ float: "right" }}
            disabled={this.props.masterTable.loading}
          >
            Add Course
          </Button>
          <Button
            className="add-cat-button"
            onClick={(e) => this.exportData(COURSE_LIST_COLS)}
            style={{ float: "right", marginRight: "10px" }}
            disabled={this.props.masterTable.loading}
          >
            Export Excel
          </Button>
        </h2>
        <Spin spinning={this.props.masterTable.loading} delay={500}>
          <div>
            <Table
              columns={COURSE_LIST_COLS}
              dataSource={this.props.masterTable.courses}
              pagination={{
                showSizeChanger: true,
                showTotal: () => {
                  const total = this.props.masterTable.courses.length;
                  return `Total ${total}`;
                },
              }}
            />
          </div>
        </Spin>
        {this.props.masterTable.showAddCourse && (
          <AddCourseModal record={this.state.course} {...this.props} />
        )}
      </div>
    );
  }
}

export default Courses;

import {  Col, Empty } from "antd";
import React from "react";
import { translate } from "../../../base/actions/translate";
import ViewSurveyCard from "./ViewSurveyCard";

class ConsumerItemsView extends React.Component {
  getConsumerItems = (itemsList) => {
    const list = [];
    for(const i of itemsList) {
      list.push(translate(i));
    }

    return list.join(', ');
  }
  render() {
    let info = this.props.data;
    if(!info) {
      return (
        <ViewSurveyCard title="Consumer Items" {...this.props}>
          <Empty />
        </ViewSurveyCard>
      );
    }
    return (
      <div>
        <ViewSurveyCard title="Consumer Items" {...this.props}>
          <Col sm={24}>
          <label>Consumer Items</label>:
        </Col>
        <Col sm={24}>
          <span>{this.getConsumerItems(info?.consumerItemsList)}</span>
          {/* <span>{translate(data[i])}</span> */}
        </Col>
        </ViewSurveyCard>
      </div>
    );
  }
}

export default ConsumerItemsView;

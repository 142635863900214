import { connect } from "react-redux";
import Navbar from "../components/Navbar";
import { LOGOUT, TOGGLE_LOGIN_MODAL } from "../utils/const";
import { withRouter } from "react-router";
import { fetchConfigByCode } from "../actions/AppConfigActions";
import Student_Identification from "../../assets/forms/Student_Identification.pdf";

const mapDispatchToProps = dispatch => {
    return {
      fetchConfigByCode(code) {
        dispatch(fetchConfigByCode(code));
      },
      performMenuActions(item,key,keyPath, domEvent) {
        // console.log('Menu item clicked --------------- ', item, key, keyPath, domEvent);
        if(key === 'download-application') {
          window.open(Student_Identification,'');
        } else if(key === 'logout') {
          sessionStorage.clear();
          dispatch({
            type: LOGOUT,
            payload: 'You are logged out successfully.'
          });
          
            // console.log('..........loggedout.........');
            window.location = '/login';
        }
      },
      logout() {
        sessionStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: 'You are logged out successfully.'
        });
        
          // console.log('..........loggedout.........');
          window.location = '/login';
        
      },

      toggleLoginModal(showLogin) {
        dispatch({
          type: TOGGLE_LOGIN_MODAL,
          payload: { showLogin },
        });
      },
    };
  };

  const mapStateToProps = state => {
    // console.log(state);
    const {AuthReducer, StudentReducer, AppConfigReducer} = state;
    return {
      isLoggedIn: AuthReducer.isLoggedIn,
      role: AuthReducer.role,
      studentData: StudentReducer,
      appConfig: AppConfigReducer,
    }
  };

  export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Navbar));
import {
  Button,
  Input,
  Modal,
  Space,
  Spin,
  Table,
  Form,
  Tooltip,
  notification,
  Radio,
  message,
  Upload,
} from "antd";
import React from "react";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import {
  EditOutlined,
  MailOutlined,
  InfoCircleOutlined,
  UploadOutlined
} from "@ant-design/icons";
import StudentFilterFrom from "../../base/components/StudentFilterForm";
import { STATUS_LIST_ADMIN } from "../utils/consts";
import { formItemLayout, STATUS_LIST } from "../../sampoorna-siksha/utils/consts";

class SMSTemplates extends React.Component {
  state = {
    currentRecord: {},
    fileList: []
  };

  adFormRef = React.createRef();

  componentDidMount = () => {
    this.props.getAllSMSTemplates();
    this.getConfig('ODIST', -1);
    this.getConfig('CASTE', -1);
    this.getConfig('EXAM_CENTER', -1);
  };

  getConfig(type, parentId) {
    this.props.fetchConfigByTypeAndParentId(type, parentId);
  }

  showNotifications = () => {
    let message = "";
    if (this.props.sms.successMessage) {
      message = this.props.sms.successMessage;
    }

    if (message) {
      notification.success({
        message: "Success",
        description: message,
      });
      this.props.resetMessage();
    }
  };

  onSave = (form) => {
    this.props.saveSMSTemplate(form);
  };

  add = () => {
    this.setState({ currentRecord: {} });
    this.props.toggleSMSEdit();
  };

  edit = (record, e) => {
    e.preventDefault();
    this.setState({ currentRecord: record });
    this.props.toggleSMSEdit();
  };

  showSendSmsForm = (record, e) => {
    e.preventDefault();
    this.setState({ currentRecord: record });
    this.props.toggleSendSMS();
  };

  showBulkSmsForm = () => {
    const that = this;
    setTimeout(() => {
      if(that.adFormRef.current) {
        that.adFormRef.current.resetFields();
      }
    }, 300);
    
    this.setState({currentRecord: {}, fileList: []});
    this.props.toggleAdhocSMS();
  }

  sendSms = (form) => {
    form.smsTemplateId = this.state.currentRecord.smsTemplateId;
    this.props.sendSMS(form);
  };

  sendBulkSMS = (form) => {
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('file', file);
    });
    formData.append('campaignName', form.campaignName);
    formData.append('smsTemplateValue', form.smsTemplateValue);
    formData.append('templateId', form.templateId);
    this.props.sendBulkSMS(formData);
  }

  showHelp = () => {
    Modal.info({
      title: "Placeholders",
      width: "500px",
      content: (
        <div>
          <p>Password - {"{password}"}.</p>
          <p>Student Registered mobile number - {"{username}"}</p>
          <p>Student full name - {"{studentSurName} {studentName}"}</p>
          <p>Student username / Staff username - {"{username}"}</p>
        </div>
      ),
      onOk() {},
    });
  };

  showTotal = () => {
    const total = this.props.sms.smsTemplateList.length;
    return `Total ${total} Templates`;
  };

  isExcel = (file) => {
    const ext = file.name.split('.').pop();
        if (ext !== 'xls' && ext !== 'xlsx') {
            return false;
        }
    return true;
  }

  normFile = (e) => {
    // console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    if(e.fileList[0] && !this.isExcel(e.fileList[0])) {
        return null;
    }
    return e && e.fileList[0];
  };

  render() {
    const formData = [];
    const { fileList } = this.state;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        if (!this.isExcel(file)) {
            message.error(`${file.name} is not a excel file`);
            return false;
        }
        this.setState((state) => ({
          fileList: [file],
        }));
        return false;
      },
      fileList,
    };

    for (const i in this.state.currentRecord) {
      formData.push({
        name: [i],
        value: this.state.currentRecord[i],
      });
    }

    const SMS_LIST_COLS = [
      {
        title: "Name",
        dataIndex: "campaignName",
        key: "campaignName",
        render: (text, record) => record.campaignName,
        sorter: {
          compare: (a, b) => sortNames(a.campaignName, b.campaignName),
        },
        width: '20%'
      },
      {
        title: "Template",
        dataIndex: "smsTemplateValue",
        key: "smsTemplateValue",
        render: (text, record) => record.smsTemplateValue,
        sorter: {
          compare: (a, b) => sortNames(a.smsTemplateValue, b.smsTemplateValue),
        },
        width: '50%'
      },
      {
        title: "Template ID",
        dataIndex: "templateId",
        key: "templateId",
        render: (text, record) => record.templateId,
        sorter: {
          compare: (a, b) => sortNames(a.templateId, b.templateId),
        },
        width: '20%'
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <Tooltip title="Edit">
              <a onClick={(e) => this.edit(record, e)}>
                <EditOutlined />
              </a>
            </Tooltip>
            {(!record.smsTemplateCode || record.smsTemplateCode > 4) && (
              <Tooltip title="Send SMS">
                <a onClick={(e) => this.showSendSmsForm(record, e)}>
                  <MailOutlined />
                </a>
              </Tooltip>
            )}
            <Tooltip title="Hint">
              <a onClick={(e) => this.showHelp(record, e)}>
                <InfoCircleOutlined />
              </a>
            </Tooltip>
          </Space>
        ),
      },
    ];
    return (
      <div>
        <Button onClick={e => this.showBulkSmsForm()}>Send Bulk SMS</Button>
        <Button type="primary" onClick={e => this.add()}>Add New Template</Button>
        <Spin spinning={this.props.sms.loading} delay={500}>
          <Table
            columns={SMS_LIST_COLS}
            dataSource={this.props.sms.smsTemplateList}
            pagination={{
              showSizeChanger: true,
              showTotal: this.showTotal,
            }}
          />
        </Spin>
        {/* Edit SMS Template Modal */}
        <Modal
          visible={this.props.sms.showEditModal}
          footer={null}
          onCancel={() => this.props.toggleSMSEdit(false)}
          title="SMS Template"
          width="400px"
        >
          <div className="card-container">
            <Spin tip="Loading..." spinning={this.props.sms.loading}>
              <Form
                name="normal_login"
                className="login-form login-wrapper"
                onFinish={this.onSave}
                fields={formData}
              >
                <Form.Item name="smsTemplateId" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item name="smsTemplateCode" hidden={true}>
                  <Input />
                </Form.Item>
                <div className="content-wrapper">
                  <Form.Item
                    name="campaignName"
                    rules={[
                      {
                        required: true,
                        message: "Please provide the campaign name!",
                      },
                    ]}
                  >
                    <Input maxLength={100} />
                  </Form.Item>
                  <Form.Item
                    name="smsTemplateValue"
                    rules={[
                      {
                        required: true,
                        message: "Please provide the template!",
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} maxLength="1000" />
                  </Form.Item>

                  <Form.Item name="templateId" label="Template Id">
                  <Input />
                </Form.Item>

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </Spin>
          </div>
        </Modal>

        {/* Adhoc SMS Modal */}
        <Modal
          visible={this.props.sms.showAdhocModal}
          footer={null}
          onCancel={() => this.props.toggleAdhocSMS()}
          title="Bulk SMS Template"
          width="400px"
        >
          <div className="card-container">
            <Spin tip="Loading..." spinning={this.props.sms.loading}>
              <Form
                name="adhoc-sms"
                className="login-form login-wrapper"
                onFinish={this.sendBulkSMS}
                fields={formData}
                ref={this.adFormRef}
              >
                <Form.Item
                  name="file"
                  valuePropName="file"
                getValueFromEvent={this.normFile}
                label="Phone Numbers Excel"
                  rules={[
                    {
                      required: true,
                      message: "Please an excel file with phone numbers!",
                    },
                  ]}
                >
                  <Upload {...props}>
                    <Button icon={<UploadOutlined />}> Select Excel File (.xlsx) only</Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  name="campaignName"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the campaign name!",
                    },
                  ]}
                >
                  <Input maxLength={100}  placeholder="Please enter a campaign name" />
                </Form.Item>
                <div className="content-wrapper">
                  <Form.Item
                    name="smsTemplateValue" 
                    rules={[
                      {
                        required: true,
                        message: "Please provide the template!",
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} maxLength="1000" placeholder= "Please enter SMS message" />
                  </Form.Item>

                  <Form.Item name="templateId" label="Template Id">
                  <Input />
                </Form.Item>

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Send SMS
                  </Button>
                </div>
              </Form>
            </Spin>
          </div>
        </Modal>

        {/* Send SMS Modal */}
        <Modal
          visible={this.props.sms.showSendModal}
          footer={null}
          onCancel={() => this.props.toggleSendSMS(false)}
          title="SMS Template"
          width="80%"
        >
          <div className="card-container">
            <Spin tip="Loading..." spinning={this.props.sms.loading}>
              <Form
                name="normal_login"
                className="login-form login-wrapper"
                onFinish={this.sendSms}
              >
                <StudentFilterFrom
                  statusList={STATUS_LIST}
                  {...this.props}
                ></StudentFilterFrom>
                <Form.Item label="Which Number?" name="numberOption">
                  <Radio.Group value={formItemLayout} defaultValue={"STUD"}>
                    <Radio.Button value="STUD">Student</Radio.Button>
                    <Radio.Button value="HEAD">Headmaster</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Send SMS
                </Button>
              </Form>
            </Spin>
          </div>
        </Modal>

        {this.props.sms.successMessage && this.showNotifications()}
      </div>
    );
  }
}

export default SMSTemplates;

import { notification, Spin, Tabs } from "antd";
import React from "react";
import "../styles/manage.css";
import StudentAcademics from "./academics/StudentAcademics";
import StudentProfile from "./StudentProfile";
import StudHostelDetails from "./StudHostelDetails";
import InteractionsContainer from "../../common/containers/InteractionsContainer";
import MedicalRequests from "./MedicalRequests";
import WelfareRequests from "./WelfareRequests";
import MonetaryRequests from "./MonetaryRequests";
import Expenses from "./Expenses";
import StudentNotes from "./StudentNotes";

const { TabPane } = Tabs;

class StudentDetails extends React.Component {
  state = {
    activeKey: "1",
  };
  componentDidMount() {
    this.props.fetchStudentProfileById(this.props.record.studentProfileId);
    this.props.getCastes();
    this.props.getMarksConfig();
    this.props.getIncharge(this.props.record.studentProfileId);
    if (this.props.activeTab) {
      this.setState({ activeKey: this.props.activeTab });
    }
  }

  callback = (key) => {
    console.log(key);
    this.setState({ activeKey: key });
  };

  showSuccessNotifications = () => {
    let message = "";
    if (this.props.manage.successMessage) {
      message += this.props.manage.successMessage;
    }

    if (message) {
      notification.success({
        message: "Success",
        description: message,
      });
      this.props.resetMessage();
    }
  };

  showErrorNotifications = () => {
    let message = "";

    if (this.props.manage.errorMessage) {
      message += this.props.manage.errorMessage;
    }

    if (message) {
      notification.error({
        message: "Error",
        description: message,
      });
      this.props.resetMessage();
    }
  };

  render() {
    return (
      <div id="stud_details_container">
        <Spin spinning={!this.props.manage.currentProfile.studentProfileId}>
          <Tabs activeKey={this.state.activeKey} onChange={this.callback}>
            <TabPane tab="Profile" key="1">
              {this.props.manage.currentProfile.studentProfileId && (
                <StudentProfile {...this.props}></StudentProfile>
              )}
            </TabPane>
            <TabPane tab="Academics" key="2">
              {this.state.activeKey === "2" && (
                <StudentAcademics {...this.props} />
              )}
            </TabPane>
            <TabPane tab="Hostel" key="3">
              {this.state.activeKey === "3" && (
                <StudHostelDetails {...this.props} />
              )}
            </TabPane>
            <TabPane tab="Medical" key="4">
              {this.state.activeKey === "4" && (
                <MedicalRequests onClose={this.props.onClose} />
              )}
            </TabPane>
            <TabPane tab="Welfare" key="5">
              {this.state.activeKey === "5" && (
                <WelfareRequests onClose={this.props.onClose} />
              )}
            </TabPane>
            <TabPane tab="Interactions" key="6">
              {this.state.activeKey === "6" && (
                <InteractionsContainer {...this.props} />
              )}
            </TabPane>
            <TabPane tab="Monetary" key="7">
              {this.state.activeKey === "7" && (
                <MonetaryRequests onClose={this.props.onClose} />
              )}
            </TabPane>
            <TabPane tab="Expense" key="8">
              {this.state.activeKey === "8" && (
                <Expenses
                  studentProfileId={
                    this.props.manage.currentProfile.studentProfileId
                  }
                  onClose={this.props.onClose}
                />
              )}
            </TabPane>
            <TabPane tab="Notes" key="9">
              {this.state.activeKey === "9" && <StudentNotes {...this.props} />}
            </TabPane>
          </Tabs>
        </Spin>
        {this.props.manage.successMessage && this.showSuccessNotifications()}
        {this.props.manage.errorMessage && this.showErrorNotifications()}
      </div>
    );
  }
}

export default StudentDetails;

import React from "react";
import { Button, Card, Col, Row, Spin } from "antd";
import {
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  DeleteOutlined,
  FullscreenOutlined,
  RightOutlined,
  LeftOutlined,
  PaperClipOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileExcelOutlined,
  FileWordOutlined
} from "@ant-design/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import IconButton from "@material-ui/core/IconButton";
import { CloseOutlined } from "@ant-design/icons";
import { APP_URI } from "../../../config/env";
import { TYPE_PDF } from "../../../student/util/const";
import { translate } from "../../../base/actions/translate";
import { getUploadPath } from "../../../student/util/util";
import PDF_ICON from "../../../assets/images/pdfIcon.png";
import DocViewer, { PDFRenderer } from "react-doc-viewer";
import { Slide } from "@material-ui/core";
import "../../styles/imageViewer.css";
import { DOC_EXTNS, OFC_EXTNS, PPT_EXTNS, XSL_EXTNS } from "../../../common/utils/const";
const { Meta } = Card;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ImageViewer extends React.Component {
  state = {
    fileType: "",
    fileUrl: "",
    showFile: false,
    showDragableFile: false,
    fileCategory: "",
    zoom: 1,
    file: {},
    rotateStyle: 0,
    index: null,
    loadingImg: false,
  };
  showNext = () => {
    const { index, file } = this.state;
    const filesList = this.props.imageList;
    if (filesList.length > index + 1) {
      const nfile = filesList[index + 1];
      const loading = nfile.filename !== file.filename || nfile.type !== file.type;
      this.setState({ loadingImg: loading, file: nfile, index: index + 1, fileCategory: nfile.type });
    }
  };
  showPrev = () => {
    const { index, file } = this.state;
    const filesList = this.props.imageList;
    if (index > 0) {
      const nfile = filesList[index - 1];
      const loading = nfile.filename !== file.filename || nfile.type !== file.type;
      this.setState({ loadingImg: loading, file: nfile, index: index - 1, fileCategory: nfile.type });
    }
  };
  showFile = (file, fileCategory, index) => {
    if(OFC_EXTNS.includes(file.extension)) {
      return;
    }
    this.setState({
      loadingImg : file.extension == "pdf"? false:true,
      file: file,
      rotateStyle: file.angle,
      zoom: 1,
      // showFile: true,
      showDragableFile: true,
      fileCategory: fileCategory,
      index: index,
    });
  };
  hideFile = (reason) => {
    console.log("reason for close", reason);
    this.setState({
      fileType: "",
      fileUrl: "",
      showFile: false,
      showDragableFile: false,
      fileCategory: "",
    });
  };

  rotateLeft = (file) => {
    let angle = this.state.rotateStyle;
    if (!angle || angle === 0) {
      angle = 360;
    }
    angle = angle - 90;
    this.setState({ rotateStyle: angle });
    this.props.updateImageAngle(
      angle,
      file.uploadId ? file.uploadId : file.managementUploadId
    );
  };

  rotateRight = (file) => {
    let angle = this.state.rotateStyle;
    if (!angle || angle === 360) {
      angle = 0;
    }
    angle = angle + 90;
    this.setState({ rotateStyle: angle });
    this.props.updateImageAngle(
      angle,
      file.uploadId ? file.uploadId : file.managementUploadId
    );
  };

  zoomIn = () => {
    const zoom = this.state.zoom;
    this.setState({ zoom: zoom + 0.5 });
  };

  zoomOut = () => {
    const zoom = this.state.zoom;
    if (zoom > 0.5) {
      this.setState({ zoom: zoom - 0.5 });
    }
  };

  onLoadHandler = (e) => {
    console.log("on img load", e);
    const { file } = this.state;
    this.setState({ loadingImg: false, rotateStyle: file.angle });
  };

  PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  getActions = (i, j) => {
    if (this.props.showActions) {
      return [
        <Button
          icon={<FullscreenOutlined />}
          size="small"
          onClick={(e) => this.showFile(i, i.type, j)}
        />,
        <Button
          icon={<DeleteOutlined />}
          size="small"
          onClick={(e) => this.props.addToDeleteFiles([i])}
        />,
      ];
    } else {
      return [];
    }
  };

  generatePins = () => {
    const filesList = this.props.imageList;
    const images = [];
    for (let i = 0; i < filesList.length; i++) {
      const attach = filesList[i];
      images.push(
        <Button
        className="image-pin"
        key={"pin" + i}
          type="link"
          onClick={(e) => this.showFile(attach, attach.type, i)}
          title={attach.filename}
        >
          <PaperClipOutlined />
        </Button>
      );
      return images;
    }
  }

  generateImages = () => {
    const filesList = this.props.imageList;
    const images = [];
    filesList.forEach((i, j) => {
      images.push(
        <Col
          key={
            "Col" +
            (i.uploadId
              ? i.uploadId
              : i.managementUploadId
              ? i.managementUploadId
              : j)
          }
        >
          <div style={{ width: 180 }}></div>
          {!this.props.hideMeta && (
            <Card
              hoverable
              style={{ width: 180 }}
              cover={
                <>
                  {i.extension === "pdf" && (
                     <FilePdfOutlined onClick={(e) => this.showFile(i, i.type, j)} className="ofc-icon"/>
                  )}
                  {DOC_EXTNS.includes(i.extension) && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    APP_URI +
                    "/upload/download?path=" +
                    getUploadPath(i, this.props.year)
                  }
                >
                  <FileWordOutlined className="ofc-icon"/>
                </a>
              )}
              {XSL_EXTNS.includes(i.extension) && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    APP_URI +
                    "/upload/download?path=" +
                    getUploadPath(i, this.props.year)
                  }
                >
                  <FileExcelOutlined className="ofc-icon"/>
                </a>
              )}
              {PPT_EXTNS.includes(i.extension) && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    APP_URI +
                    "/upload/download?path=" +
                    getUploadPath(i, this.props.year)
                  }
                >
                  <FilePptOutlined className="ofc-icon"/>
                </a>
              )}
                  {(i.extension !== "pdf") && !OFC_EXTNS.includes(i.extension) && (
                    <img
                      alt={translate(this.state.file.type)}
                      src={
                        APP_URI +
                        "/upload/download?path=" +
                        getUploadPath(i, this.props.year)
                      }
                      style={{
                        height: "100%",
                        transform: "rotate(" + (i.angle ? i.angle : 0) + "deg)",
                        width: "auto",
                        margin: "auto",
                      }}
                      onClick={(e) => this.showFile(i, i.type, j)}
                    ></img>
                  )}
                </>
              }
              actions={this.getActions(i, j)}
            >
              <Meta
                title={translate(i.type)}
                description={i.filename}
                onClick={(e) => this.showFile(i, i.type, j)}
              />
            </Card>
          )}
          {this.props.hideMeta && (
            <Card style={{ width: 200, height: 200 }}>
              {i.extension === "pdf" && (
                <FilePdfOutlined onClick={(e) => this.showFile(i, i.type, j)} className="ofc-icon"/>
              )}
              {DOC_EXTNS.includes(i.extension) && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    APP_URI +
                    "/upload/download?path=" +
                    getUploadPath(i, this.props.year)
                  }
                >
                  <FileWordOutlined className="ofc-icon"/>
                </a>
              )}
              {XSL_EXTNS.includes(i.extension) && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    APP_URI +
                    "/upload/download?path=" +
                    getUploadPath(i, this.props.year)
                  }
                >
                  <FileExcelOutlined className="ofc-icon"/>
                </a>
              )}
              {PPT_EXTNS.includes(i.extension) && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    APP_URI +
                    "/upload/download?path=" +
                    getUploadPath(i, this.props.year)
                  }
                >
                  <FilePptOutlined className="ofc-icon"/>
                </a>
              )}
              {i.extension !== "pdf"  && !OFC_EXTNS.includes(i.extension) && (
                <img
                  alt={translate(this.state.file.type)}
                  src={
                    APP_URI +
                    "/upload/download?path=" +
                    getUploadPath(i, this.props.year)
                  }
                  style={{
                    height: "180px",
                    width: "180px",
                    transform: "rotate(" + (i.angle ? i.angle : 0) + "deg)",
                  }}
                  onClick={(e) => this.showFile(i, i.type, j)}
                ></img>
              )}
            </Card>
          )}
        </Col>
      );
    });
    return images;
  };

  render() {
    const filesList = this.props.imageList;
    const { index } = this.state;
    return (
      <React.Fragment>
      <div className={this.props.showPin ? "attch-viewer" : "image-viewer"}>
      {this.props.showPin && (
          <div className="pins-list">{this.generatePins()}</div>
        )}
        {!this.props.showPin && (
          <div className="image-list">
            <Row gutter={[16, 8]}>{this.generateImages()}</Row>
          </div>
        )}
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          open={this.state.showDragableFile}
          onClose={this.hideFile}
          // PaperComponent={this.PaperComponent}
          aria-labelledby="draggable-dialog-title"
          hideBackdrop={true}
          // disableBackdropClick={true}
          maxWidth="xl"
        >
          <DialogTitle
            style={{ padding: "5px 24px" }}
            id="draggable-dialog-title"
          >
            {translate(this.state.fileCategory)}
            <div className="image-view-btns">
              {this.state.file.type !== TYPE_PDF &&
              this.state.file.extension !== "pdf"
                ? [
                    <IconButton
                      key="zoomIn"
                      color="primary"
                      title="Zoom In"
                      edge="start"
                      aria-label="zoom in"
                      onClick={(e) => this.zoomIn()}
                    >
                      <ZoomInOutlined />
                    </IconButton>,
                    <IconButton
                      color="primary"
                      title="Zoom Out"
                      key="zoomOut"
                      edge="start"
                      aria-label="zoom out"
                      onClick={(e) => this.zoomOut()}
                    >
                      <ZoomOutOutlined />
                    </IconButton>,
                    <IconButton
                      color="primary"
                      title="Rotate Left"
                      key="rotateL"
                      edge="start"
                      aria-label="rotate left"
                      onClick={(e) => this.rotateLeft(this.state.file)}
                    >
                      <RotateLeftOutlined />
                    </IconButton>,
                    <IconButton
                      color="primary"
                      title="Rotate Right"
                      key="rotateR"
                      edge="start"
                      aria-label="rotate right"
                      onClick={(e) => this.rotateRight(this.state.file)}
                    >
                      <RotateRightOutlined />
                    </IconButton>,
                  ]
                : ""}
              <IconButton
                edge="start"
                color="secondary"
                onClick={this.hideFile}
                aria-label="close"
              >
                <CloseOutlined />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            {index > 0 ? (
              <div className="prev-btn">
                <a
                  href="/"
                  title="Previous"
                  onClick={(e) => {
                    e.preventDefault();
                    this.showPrev();
                  }}
                >
                  <LeftOutlined />
                </a>
              </div>
            ) : (
              ""
            )}
            <Spin spinning={this.state.loadingImg} delay={500}>
              {this.state.file.type !== TYPE_PDF &&
              this.state.file.extension !== "pdf" ? (
                <img
                  alt={translate(this.state.file.type)}
                  src={
                    APP_URI +
                    "/upload/download?path=" +
                    getUploadPath(this.state.file, this.props.year)
                  }
                  onLoad={(e) => this.onLoadHandler(e)}
                  className={`rotate-${
                    this.state.rotateStyle != null ? this.state.rotateStyle : 0
                  }`}
                  style={{
                    transform:
                      "rotate(" +
                      (this.state.rotateStyle != null
                        ? this.state.rotateStyle
                        : 0) +
                      "deg)",
                    zoom: this.state.zoom,
                    MozTransform: `scale(${this.state.zoom})`,
                    WebkitTransform:
                      "rotate(" +
                      (this.state.rotateStyle != null
                        ? this.state.rotateStyle
                        : 0) +
                      "deg)",
                  }}
                ></img>
              ) : (
                ""
              )}
              {(this.state.file.type === TYPE_PDF ||
                this.state.file.extension === "pdf") && (
                <div>
                  <DocViewer
                    pluginRenderers={[PDFRenderer]}
                    documents={[
                      {
                        uri:
                          APP_URI +
                          "/upload/download?path=" +
                          getUploadPath(this.state.file, this.props.year),
                      },
                    ]}
                    config={{
                      header: {
                        disableHeader: true,
                        disableFileName: true,
                        retainURLParams: false,
                      },
                    }}
                  />
                </div>
              )}
            </Spin>
            {filesList.length > index + 1 ? (
              <div className="next-btn">
                <a
                  href="/"
                  title="Next"
                  onClick={(e) => {
                    e.preventDefault();
                    this.showNext();
                  }}
                >
                  <RightOutlined />
                </a>
              </div>
            ) : (
              ""
            )}
          </DialogContent>
        </Dialog>
      </div>
      </React.Fragment>
    );
  }
}

export default ImageViewer;

import { axiosInstance } from "../../Interceptor";
import { GET_NOTES, LOAD_MORE_NOTES, NOTES_APPROVED_DRAFTS, NOTES_DEL_DRAFT, NOTES_DEL_FILES, NOTES_DRAFT_IMG, NOTES_ROTATE_IMG, SAVE_NOTES } from "../utils/const";

export const saveNotes = (interaction) => {
  return {
    type: SAVE_NOTES,
    payload: axiosInstance.post("/stud/manage/studentnotes", interaction),
  };
};

export const getNotes = (profileId, interactionId) => {
  return {
    type: GET_NOTES,
    payload: axiosInstance.get(`/stud/manage/studentnotes/${profileId}/${interactionId}`),
  };
};

export const loadMoreNotes = (profileId, interactionId) => {
  return {
    type: LOAD_MORE_NOTES,
    payload: axiosInstance.get(`/stud/manage/studentnotes/${profileId}/${interactionId}`),
  };
};

export const deleteFiles = (filesList) => {
    return {
      type: NOTES_DEL_FILES,
      payload: axiosInstance.post(`/upload/studentnotes/delete`, filesList),
    };
  };
  
  export const approveDrafts = (studentProfileId, filesList) => {
    return {
      type: NOTES_APPROVED_DRAFTS,
      payload: axiosInstance.post(`/upload/studentnotes/approve/${studentProfileId}`, filesList),
    };
  };
  
  export const draftImg = (studentProfileId, file, type, batch) => {
    const url = `/upload/studentnotes/draft`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("entityId", studentProfileId);
    formData.append("type", type);
    formData.append("year", batch);
    return {
      type: NOTES_DRAFT_IMG,
      payload: axiosInstance.post(url, formData),
    };
  };

  export const updateImageAngle = (uploadId, angle) => {
    const data = {
      managementUploadId: uploadId,
      angle: angle
    };
    return {
      type: NOTES_ROTATE_IMG,
      payload: axiosInstance.post(`/upload/studentnotes/rotate`, data),
    };
  };

  export const deleteDrafts = (studentProfileId) => {
    return {
      type: NOTES_DEL_DRAFT,
      payload: axiosInstance.delete(`/upload/studentnotes/draft/${studentProfileId}`),
    };
  };

import { axiosInstance } from "../../Interceptor";
import { GET_MSG, LOAD_MORE_MSGS, SAVE_MSG } from "../utils/const";

export const saveInteractions = (interaction) => {
  return {
    type: SAVE_MSG,
    payload: axiosInstance.post("/interaction", interaction),
  };
};

export const getInteractions = (profileId, interactionId) => {
  return {
    type: GET_MSG,
    payload: axiosInstance.get(`/interaction/${profileId}/${interactionId}`),
  };
};

export const loadMoreInteractions = (profileId, interactionId) => {
  return {
    type: LOAD_MORE_MSGS,
    payload: axiosInstance.get(`/interaction/${profileId}/${interactionId}`),
  };
};
import { connect } from "react-redux";
import StudentDashboard from "../components/StudentDashboard";
import { getApplication, getAddSchoolRequests } from "../actions/StudentActions";

const mapDispatchToProps = dispatch => {
    return {
      getApplication(username, year) {
        dispatch(getApplication(year, username));
      },

      getAddSchoolRequests(userId) {
        dispatch(getAddSchoolRequests(userId))
      },
    };
  };

  const mapStateToProps = state => {
    const { AuthReducer, StudentReducer, AppConfigReducer } = state;
    return {
      isLoading: AuthReducer.isLoading,
      profile: AuthReducer.profile,
      lang: AuthReducer.lang,
      studentData: StudentReducer,
      appConfig: AppConfigReducer,
    }
  }

  export default connect(mapStateToProps,mapDispatchToProps)(StudentDashboard);
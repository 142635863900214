import { Button, Col, Form, Input, Row } from "antd";
import React from "react";
import { formItemLayout, NGHB_DTLS } from "../../utils/consts";
import ReviewStep from "./ReviewStep";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { prepareReviewersFieldData } from "../../utils/util";

class NeighbourReference extends React.Component {
  onFinish = (form) => {
    // console.log("Received values of form:", form);
    this.props.saveDetails(NGHB_DTLS, form);
  };
  render() {
    let reviewData = this.props.review.reviewFormData[NGHB_DTLS];
    if(!reviewData) {
      reviewData = {};
    } 
    const fieldData = prepareReviewersFieldData(reviewData);
    return (
      <div className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Neighbour’s Reference"
          onFinish={(e) => this.onFinish(e)}
          {...this.props}
        >
          <Form.List name="neighboursList">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, idx) => (
                    <Row key={idx} gutter={[8, 16]}>
                      <Col sm={1}>
                        <span>{idx + 1}</span>
                      </Col>
                      <Col sm={22}>
                        <Row key={idx} gutter={[16, 8]}>
                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "income"]}
                              fieldKey={[field.fieldKey, "income"]}
                              label="Annual Income"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^[0-9][0-9]*$/,//#627
                                  message: "Please enter income details",
                                },
                              ]}
                            >
                              <Input maxLength="8" />
                            </Form.Item>
                          </Col>

                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "propertyDetails"]}
                              fieldKey={[field.fieldKey, "propertyDetails"]}
                              label="Property Value"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^[1-9][0-9]*$/,
                                  message: "Please enter property details",
                                },
                              ]}
                            >
                              <Input maxLength="10" />
                            </Form.Item>
                          </Col>
                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "house"]}
                              fieldKey={[field.fieldKey, "house"]}
                              label="House Value"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^[1-9][0-9]*$/,
                                  message: "Please enter house details",
                                },
                              ]}
                            >
                              <Input maxLength="10" />
                            </Form.Item>
                          </Col>
                          <Col sm={6}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "others"]}
                              fieldKey={[field.fieldKey, "others"]}
                              label="Other Income"
                              rules={[
                                {
                                  pattern: /^[0-9][0-9]*$/,//#627
                                  message: "Please enter other details",
                                },
                              ]}
                            >
                              <Input maxLength="10" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={1} style={{ textAlign: "center" }}>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add Neighbour
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </ReviewStep>
      </div>
    );
  }
}

export default NeighbourReference;

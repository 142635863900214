import AuthContainer from '../base/containers/AuthContainer';
import HomeContainer from '../base/containers/HomeContainer';
import { ALL, STUDE, ADMIN, SUP1, SUP2, MGR, MSSPC, MSSCC, MWCO, ACCEX, DSTIN, SELCOMT, MSSSTUD } from '../base/utils/roles';
import ApplicationFormContainer from '../student/containers/ApplicationFormContainer';
import AddSchoolContainer from '../student/containers/AddSchoolContainer';
import FaqsContainer from '../base/containers/FaqsContainer';
import AboutContainer from '../base/containers/AboutContainer';
import ViewApplicationContainer from '../student/containers/ViewApplicationContainer';
import AddSchoolSuccessContainer from '../student/containers/AddSchoolSuccessContainer';
import Unauthorized from '../base/components/Unauthorized';
import UsersListContainer from '../admin/containers/UsersListContainer';
import EditProfileContainer from '../admin/containers/EditProfileContainer';
import SampoornaSikshaContainer from '../sampoorna-siksha/containers/SampoornaSikshaContainer';
import MedhaSampurnaSiksha from '../base/components/MedhaSampurnaSiksha';
import MedhaNaipunyaNirmana from '../base/components/MedhaNaipunyaNirmana';
import MedhaHelpingHands from '../base/components/MedhaHelpingHands';
import AchievementsContainer from'../base/containers/AchievementsContainer';
import HallTicketContainer from '../student/containers/HallTicketContainer';
import ConfigureContainer from '../admin/containers/ConfigureContainer';
import ExamCenterContainer from '../sampoorna-siksha/containers/ExamCenterContainer';
import ExamDocumentsContainer from '../sampoorna-siksha/containers/ExamDocumentsContainer';
import StudentSelectionContainer from '../sampoorna-siksha/containers/StudentSelectionContainer';
import AdminShortListContainer from '../admin/containers/AdminShortListContainer';
import SelectionCommitteeContainer from '../sampoorna-siksha/containers/SelectionCommitteeContainer';
import EnrollmentContainer from '../sampoorna-siksha/containers/EnrollmentContainer';
import MasterTablesContainer from '../mastertables/containers/MasterTablesContainer';
import JoiningContainer from '../management/containers/JoiningContainer';
import StudentListContainer from '../management/containers/StudentListContainer';
import StudAccountContainer from '../management/containers/StudAccountContainer';
import AcademicProfileContainer from '../stud-acct/containers/AcademicProfileContainer';
import AddSchoolRequestsContainer from '../sampoorna-siksha/containers/AddSchoolRequestsContainer';
import TaskListContainer from '../common/containers/TaskListContainer';
import NotificationContainer from '../common/containers/NotificationsContainer';
import StudentInteractions from '../stud-acct/components/StudentInteractions';
import Requests from '../stud-acct/components/Requests';
import BulkRequestActions from '../management/components/BulkRequestActions';
import BulkRequestCreation from '../management/components/BulkRequestCreation';
import DataMigration from '../admin/components/DataMigration';
import ReportsDashboard from '../reports/components/ReportsDashboard';

export const routes = [

  {
    path: "/login",
    component: AuthContainer,
    role: [ALL]
  },
  {
    path: '/home',
    component: HomeContainer,
    role: [ALL]
  },
  // {
  //   path: '/exam2020',
  //   component: TempHallTickets,
  //   role: [ALL]
  // },
  {
    path: "/sampurnaSiksha/apply",
    component: ApplicationFormContainer,
    role: [STUDE],
  },
  {
    path: "/sampurnaSiksha/view",
    component: ViewApplicationContainer,
    role: [STUDE],
  },
  {
    path: "/SampurnaSiksha/hallticket",
    component: HallTicketContainer,
    role: [STUDE],
  },
  {
    path: '/addschool',
    component: AddSchoolContainer,
    role: [STUDE]
  },
  {
    path: '/addschoolSuccess',
    component: AddSchoolSuccessContainer,
    role: [STUDE]
  },
  {
    path: '/faqs',
    component: FaqsContainer,
    role: [STUDE]
  },
  {
    path: '/users',
    component: UsersListContainer,
    role: [ADMIN]
  },
  {
    path: '/add-school-requests',
    component: AddSchoolRequestsContainer,
    role: [ADMIN,  MGR, MSSPC, MSSCC, MWCO, ACCEX]
  },
  {
    path: '/config/:key',
    component: ConfigureContainer,
    role: [ADMIN]
  },
  {
    path: '/joining',
    component: JoiningContainer,
    role: [ADMIN, MSSPC, MGR]
  },
  {
    path: '/studentlist',
    component: StudentListContainer,
    role: [ADMIN, MSSCC, MSSPC, MGR, MWCO]
  },
  {
    path: '/student-acct',
    component: StudAccountContainer,
    role: [ADMIN, MSSPC, MGR]
  },
  {
    path: '/mastertable/:key',
    component: MasterTablesContainer,
    role: [ADMIN, MSSCC, MSSPC, MGR]
  },
  {
    path: '/examCenters',
    component: ExamCenterContainer,
    role: [ADMIN, MGR]
  },
  {
    path: '/examDocs',
    component: ExamDocumentsContainer,
    role: [ADMIN, MGR, MSSPC, MSSCC, MWCO, ACCEX, DSTIN]
  },
  {
    path: '/admin/shortlist',
    component: AdminShortListContainer,
    role: [ ADMIN ]
  },
  {
    path: '/enrollment',
    component: EnrollmentContainer,
    role: [ ADMIN, MGR ]
  },
  {
    path: '/survey',
    component: StudentSelectionContainer,
    role: [ ADMIN, MGR, MSSPC, MSSCC, MWCO, ACCEX, DSTIN]
  },
  {
    path: '/selection-committee',
    component: SelectionCommitteeContainer,
    role: [ ADMIN, SELCOMT, MGR]
  },
  {
    path: '/editProfile',
    component: EditProfileContainer,
    role: [ADMIN, SUP1, SUP2, MGR, MSSPC, MSSCC, MWCO, ACCEX, DSTIN, SELCOMT, MSSSTUD]
  },
  {
    path: '/sampurnaSiksha/application-processing',
    component: SampoornaSikshaContainer,
    role: [ADMIN, SUP1, SUP2, MGR, MSSPC, MSSCC, MWCO, ACCEX]
  },
  {
    path: '/myacademics',
    component: AcademicProfileContainer,
    role: [MSSSTUD]
  },
  {
    path: '/interactions',
    component: StudentInteractions,
    role: [MSSSTUD]
  },
  {
    path: '/requests',
    component: Requests,
    role: [MSSSTUD]
  },
  {
    path: '/notifications',
    component: NotificationContainer,
    role: [ALL]
  },
  {
    path: '/tasks',
    component: TaskListContainer,
    role: [ALL]
  },
  {
    path: '/bulk-request',
    component: BulkRequestCreation,
    role: [ADMIN, MGR, MSSPC, MSSCC, MWCO]
  },
  {
    path: '/bulk-action',
    component: BulkRequestActions,
    role: [ADMIN]
  },
  {
    path: '/about',
    component: AboutContainer,
    role: [ALL]
  },
  {
    path: '/achievements',
    component: AchievementsContainer,
    role: [ALL]
  },
  {
    path: '/mss',
    component: MedhaSampurnaSiksha,
    role: [ALL]
  },
  // {
  //   path: '/mvu',
  //   component: MedhaVidyaUnnati,
  //   role: [ALL]
  // },
  {
    path: '/mnn',
    component: MedhaNaipunyaNirmana,
    role: [ALL]
  },
  {
    path: '/mhh',
    component: MedhaHelpingHands,
    role: [ALL]
  },
  {
    path: '/data-migration',
    component: DataMigration,
    role: [ADMIN, MGR, MSSPC, MSSCC, MWCO, ACCEX]
  },
  {
    path: '/reports/:key',
    component: ReportsDashboard,
    role: [ADMIN, MGR, MSSPC, MSSCC, MWCO, ACCEX]
  },
  {
    path: '*',
    component: Unauthorized,
    role: [ALL]
  },
];
import AddSchoolComponent from "../components/AddSchoolComponent";
import { connect } from "react-redux";
import { fetchDistricts, fetchMandals } from "../../base/actions/AppConfigActions";
import { requestAddSchool } from "../actions/StudentActions";

const mapDispatchToProps = dispatch => {
    return {

      requestAddSchool(formValues) {
        dispatch(requestAddSchool(formValues));
      },

      getDistricts(){
        dispatch(fetchDistricts());
      },

      getMandalsList(districtId) {
        dispatch(fetchMandals(districtId));
      }
     
    };
  };

  const mapStateToProps = state => {
    const { AuthReducer, StudentReducer, AppConfigReducer } = state;
    return {
      isLoading: AuthReducer.isLoading,
      profile: AuthReducer.profile,
      lang: AuthReducer.lang,
      districtsList: AppConfigReducer.districtsList,
      mandalsList: AppConfigReducer.mandalsList,
      studentData: StudentReducer
    }
  }

  export default connect(mapStateToProps,mapDispatchToProps)(AddSchoolComponent);
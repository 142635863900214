import { Modal, notification, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
  INTERACTION,
  MARKS_APPRV,
  MED_REQ_APPRV,
  MON_REQ_APPRV,
  WEL_REQ_APPRV,
} from "../utils/const";
import { getEventType, getStatusString } from "../utils/util";
import InteractionTemplate from "./detailTemplates/InteractionTemplate";
import MarksApproval from "./detailTemplates/MarksApproval";
import MedicalRequestDetails from "./detailTemplates/MedicalRequestDetails";
import WelfareRequestDetails from "./detailTemplates/WelfareRequestDetails";
import MonetaryRequestDetails from "./detailTemplates/MonetaryRequestDetails";

const TaskDetails = (props) => {
  const [currentTask, setCurrentTask] = useState(props.record);
  const [eligibleLevel, setEligibleLevel] = useState(
    props.record?.eligibleLevel?.split(",")
  );

  const [editModal, setEditModal] = useState("");
  const [record, setRecord] = useState({});

  useEffect(() => {
    const taskList = props.tasks.taskList.filter(
      (t) => t.taskId === props.record.taskId
    );
    if (taskList.length > 0) {
      setCurrentTask(taskList[0]);
    } else {
      props.onClose();
    }
  }, [props.tasks.taskList]);

  useEffect(() => {
    if (props.record?.eligibleLevel) {
      setEligibleLevel(props.record.eligibleLevel.split(","));
    }
  }, [props.record]);

  const showSuccessNotifications = () => {
    let message = "";
    if (props.tasks.successMessage) {
      message += props.tasks.successMessage;
    }

    if (message) {
      notification.success({
        message: "Success",
        description: message,
      });
      props.resetMessage();
    }
  };

  const renderTaskDetails = () => {
    switch (currentTask.eventType) {
      case MARKS_APPRV.value:
        return <MarksApproval currentTask={currentTask} {...props} />;
      case INTERACTION.value:
        return <InteractionTemplate currentTask={currentTask} {...props} />;
      case MED_REQ_APPRV.value:
        return (
          <MedicalRequestDetails
            currentTask={currentTask}
            eligibleLevel={eligibleLevel}
            showEdit={true}
            {...props}
          />
        );
      case WEL_REQ_APPRV.value:
        return (
          // <DetailTemplate
          //   currentTask={currentTask}
          //   eligibleLevel={eligibleLevel}
          //   {...props}
          // >
          <WelfareRequestDetails
            currentTask={currentTask}
            eligibleLevel={eligibleLevel}
            showEdit={true}
            {...props}
          />
        );
      case MON_REQ_APPRV.value:
        return (
          // <DetailTemplate
          //   currentTask={currentTask}
          //   eligibleLevel={eligibleLevel}
          //   {...props}
          // >
          <MonetaryRequestDetails
            currentTask={currentTask}
            eligibleLevel={eligibleLevel}
            showEdit={true}
            {...props}
          />
          // </DetailTemplate>
        );
      default:
        return "";
    }
  };

  return (
    <div>
      {/* <Drawer
        title={
          currentTask?.username +
          " " +
          getEventType(currentTask?.eventType) +
          " Status: " +
          getStatusString(currentTask?.status)
        }
        placement="right"
        closable={false}
        onClose={props.onClose}
        visible={props.showDetails}
        width="100%"
        id="taskDetailsDrawer"
      >
        <Spin spinning={props.tasks.loading} delay={500}>
          {renderTaskDetails()}
        </Spin>
      </Drawer> */}

      <Modal
        visible={props.showDetails}
        title={
          (props.tasks.currentTask?.entity && Array.isArray(props.tasks.currentTask?.entity)
            ? props.tasks.currentTask?.entity[0].studentProfile.firstName +
              " " +
              props.tasks.currentTask?.entity[0].studentProfile.lastName
            : (props.tasks.currentTask?.entity ? props.tasks.currentTask?.entity.studentProfile.firstName +
              " " +
              props.tasks.currentTask?.entity.studentProfile.lastName : '')) +
          " " +
          getEventType(currentTask.eventType) +
          " Status: " +
          getStatusString(currentTask.status)
        }
        footer={null}
        onCancel={props.onClose}
        width="80%"
      >
        <Spin spinning={props.tasks.loading || !currentTask}>
          {renderTaskDetails()}
        </Spin>
      </Modal>
      {showSuccessNotifications()}
    </div>
  );
};

export default TaskDetails;

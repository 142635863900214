import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Spin,
  Table,
  Select,
  Form,
  Radio,
} from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { ADMIN } from "../../base/utils/roles";
import { exportFile } from "../../common/utils/export";
import { toCurrency } from "../../common/utils/util";
import { searchBulkRequests } from "../../management/actions/bulkRequests";
import { fetchAllAvailableBatchList } from "../../management/actions/studentList";
import { REQ_TYPES } from "../../management/utils/const";
import { fetchMasterTableData } from "../../mastertables/actions/masterTables";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import { FIN_RPT } from "../utils/const";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const FinancialReports = (props) => {
  const [form] = Form.useForm();
  const [reqCat, setReqCat] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [cols, setCols] = React.useState([
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, record) => record.category,
      sorter: {
        compare: (a, b) => sortNames(a.category, b.category),
      },
    },
  ]);

  React.useEffect(() => {
    props.getReqCats();
    props.fetchAllAvailableBatchList();
    searchRequestsList({});
  }, []);

  React.useEffect(() => {
    if (
      props.reports.financialReport &&
      props.reports.financialReport.length > 0
    ) {
      processReport(props.reports.financialReport);
    }
  }, [props.reports.financialReport]);

  const processReport = (report) => {
    const data = [];
    let years = [];
    for (const r of report) {
      const row = {
        category: r.cat?.name,
      };
      if (years.length === 0) {
        years = r.years;
      }
      for (const y of r.years) {
        let am = 0;
        for (const l of r.requests) {
          const createdAt = moment(l.createdAt);
          console.log(createdAt.month());
          console.log(createdAt.year());
          if (
            ((createdAt.month() > 4 && createdAt.year() === y) ||
              (createdAt.month() < 4 && createdAt.year() - 1 === y)) &&
            l.amount
          ) {
            am += l.amount;
          }
        }
        row[y] = am;
      }
      data.push(row);
    }
    const cols = [
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        fixed: "left",
        render: (text, record) => record.category,
        sorter: {
          compare: (a, b) => sortNames(a.category, b.category),
        },
      },
    ];
    for (const y of years) {
      cols.push({
        title: y,
        dataIndex: String(y),
        key: String(y),
        render: (text, record) => toCurrency(record[y]),
        sorter: {
          compare: (a, b) => a[y] - b[y],
        },
      });
    }
    // setYears(years);
    setTableData(data);
    setCols(cols);
  };

  const searchRequestsList = (form) => {
    props.fetchReports(FIN_RPT, form);
  };

  const onReset = () => {
    form.resetFields();
    form.submit();
  };

  const onChange = (reqType) => {
    // setRequestType(reqType);
    if (props.masterTable.reqCat.length > 0) {
      const cat = props.masterTable.reqCat.filter((c) =>
        reqType.includes(c.type)
      );
      setReqCat(cat);
      form.setFieldsValue({
        category: [],
        subCategory: [],
      });
    }
  };

  const onCatChanged = (cat) => {
    const fCats = props.masterTable.reqCat?.filter(
      (c) =>
        cat.includes(c.requestCategoryId) && c.requestSubCategories.length > 0
    );
    let sCats = [];
    for (const s of fCats) {
      if (s.requestSubCategories && s.requestSubCategories.length > 0) {
        sCats = sCats.concat(s.requestSubCategories);
      }
    }
    // setSubCatList(sCats);
    form.setFieldsValue({
      subCategory: [],
    });
  };

  const exportData = () => {
    exportFile(cols, tableData, "Financial Report");
  };

  return (
    <React.Fragment>
      <Card>
        <Form onFinish={searchRequestsList} name="filter" form={form}>
          <Row gutter={[16, 8]}>
            <Col xs={24} sm={6}>
              <Form.Item
                {...formItemLayout}
                name="entityTypes"
                label="Task Category"
              >
                <Select onChange={(e) => onChange(e)} mode="multiple">
                  {REQ_TYPES[ADMIN].map((type) => (
                    <Select.Option key={type.key} value={type.key}>
                      {type.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={6} xs={24}>
              <Form.Item {...formItemLayout} name="categories" label="Category">
                <Select
                  className="full-width"
                  mode="multiple"
                  onChange={(e) => onCatChanged(e)}
                >
                  {reqCat.map((typ) => (
                    <Select.Option
                      key={typ.requestCategoryId}
                      value={typ.requestCategoryId}
                    >
                      {typ.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={4}>
                <Form.Item name="years" label="Batch" {...formItemLayout}>
                  <Select mode="multiple">
                    {props.manage.batches
                    .sort((a, b) => sortNames(b, a)) //#637
                    .map((batch) => (
                      <Select.Option key={batch} value={batch}>
                        {batch}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

            {/* <Col sm={6} xs={24}>
              <Form.Item
                {...formItemLayout}
                name="subCategory"
                label="Sub-Category"
              >
                <Select className="full-width" mode="multiple">
                  {subCatList.map((typ, i) => (
                    <Select.Option
                      key={typ.requestSubCategoryId}
                      value={typ.requestSubCategoryId}
                    >
                      {typ.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
          </Row>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Space>
              <Button type="primary" onClick={(e) => exportData()}>
                Export
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="modal-form-submit-button"
              >
                Search
              </Button>
              <Button htmlType="button" onClick={(e) => onReset(e)}>
                Reset
              </Button>
            </Space>
          </div>
        </Form>
      </Card>
      <Card>
        <Spin spinning={props.reports.loading} delay={500}>
          <div>
            <Table
              className="row-selection"
              columns={cols}
              rowKey={(record) => record.taskId}
              dataSource={tableData}
              pagination={{
                showSizeChanger: true,
                showTotal: () => {
                  const total = tableData.length;
                  return `Total ${total}`;
                },
              }}
            />
          </div>
        </Spin>
      </Card>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReqCats() {
      dispatch(fetchMasterTableData("reqCat"));
    },
    searchBulkRequests(form) {
      dispatch(searchBulkRequests(form));
    },
    fetchAllAvailableBatchList() {
      dispatch(fetchAllAvailableBatchList());
    },
  };
};

const mapStateToProps = (state) => {
  const {
    MasterTablesReducer,
    ManagementReducer,
    ReportsReducer,
    TaskReducer,
  } = state;
  return {
    masterTable: MasterTablesReducer,
    manage: ManagementReducer,
    reports: ReportsReducer,
    tasks: TaskReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReports);

import React from "react";
import {
  Card,
  Form,
  Col,
  Select,
  Row,
  Button,
  Spin,
  Table,
  Input,
  Drawer,
  notification,
} from "antd";
import { translate } from "../../base/actions/translate";
import { createSurveyFormData, sortNames } from "../utils/util";
import { ADMIN } from "../../base/utils/roles";
import {
  INC_SUM,
  OCC_DTLS,
  REVIEW_STEPS,
  SEL_STATUS_LIST,
  SMSS,
} from "../utils/consts";
import IncomeSummaryView from "./viewSurvey/IncomeSummaryView";
import SelectionCommitteeForm from "./SelectionCommitteeForm";
import SurveyComparision from "./SurveyComparision";
import { toCurrency } from "../../common/utils/util";
import "../styles/selectionCommittee.css";
// import XLSX from 'xlsx';

const { Option } = Select;
const { Search } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class SelectionCommittee extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldData: {},
      activePanels: "",
      currentRecord: {},
      showDetails: false,
      disableFields: true,
      reasonsList: [],
      showReasonModal: false,
      reason: {
        reason: "",
        otherReason: "",
        status: "",
        errorMsg: "",
      },
      currentIndex: 0,
      selectedRowKeys: [],
    };

    this.formRef = React.createRef();
  }

  componentWillMount() {
    this.props.getOldDistricts();
    this.props.getMarksConfig();
    this.props.setFormSteps(REVIEW_STEPS);

    // this.props.toggleCompareReview(false);
    // this.props.searchApplications({});
  }

  componentWillUnmount() {
    this.props.toggleDetails(false);
    this.props.toggleCompare(false);
  }

  onSelectChange = (selectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  toCapital = (e) => {
    if (e.target.value) {
      return e.target.value.toUpperCase();
    }
    return e.target.value;
  };

  searchApplications = (form) => {
    // form.pageSize = 10;
    // form.sortOrder = 'asc';
    // form.sortField = 'applicationId';
    form.searchText = "";
    this.setState({ fieldData: form, activePanels: "" });
    this.props.searchApplications(form);
    this.props.setSearchFilters(form);
  };

  showDetails = (record, index) => {
    this.setState({
      currentRecord: record,
      showDetails: true,
      currentIndex: index,
    });
    this.props.toggleDetails(true);
    // this.props.setStep(0);
    this.props.getApplicationReviewDetails(record.applicationId);
  };

  showNext = () => {
    const { currentIndex } = this.state;
    const dataSource = this.props.review.searchedApplications;
    const currentRec = dataSource[currentIndex + 1];
    this.setState({
      currentRecord: currentRec,
      currentIndex: currentIndex + 1,
    });
    this.props.getApplicationReviewDetails(currentRec.applicationId);
    this.props.setStep(0);
  };

  showPrevious = () => {
    const { currentIndex } = this.state;
    const dataSource = this.props.review.searchedApplications;
    const currentRecord = dataSource[currentIndex - 1];
    this.setState({
      currentRecord: currentRecord,
      currentIndex: currentIndex - 1,
    });
    this.props.getApplicationReviewDetails(currentRecord.applicationId);
    this.props.setStep(0);
  };

  exportToExcel = () => {
    this.props.exportToExcel(this.props.sampurnaSiksha.ssSearchFilters);
  };

  onClose = () => {
    this.setState({ showDetails: false, disableFields: true });
    this.props.toggleDetails(false);
    this.props.getOldDistricts();
  };

  enableFields = () => {
    this.setState({ disableFields: false });
  };

  bulkApproveApplications = () => {
    this.props.applicationBulkApprove(
      this.state.selectedRowKeys,
      this.props.sampurnaSiksha.ssSearchFilters
    );
    this.setState({ selectedRowKeys: [] });
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.currentPage = 1;
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
  };

  saveStatusInPreview = (val) => {
    this.props.setStatusInPreview(val);
  };

  saveFeedbackInPreview = (e) => {
    this.props.setFeedbackInPreview(e.target.value);
  };

  getYear = () => {
    const year = new Date().getFullYear();
    const opts = [];
    for(let i = 2010; i <= year; i++) {
      opts.push(<Option value = {i}>{i}</Option>);
    }

    return opts;
  }

  generateActionButtons = () => {
    const record = this.props.review.reviewDetails.application;
    return (
      <div style={{ textAlign: "right" }}>
        <label htmlFor="status" style={{ marginRight: "8px" }}>
          Feedback :
        </label>
        <Input.TextArea
          value={record ? this.getFeedback(record) : ""}
          onChange={(e) => this.saveFeedbackInPreview(e)}
          style={{ width: "300px", marginRight: "8px", textAlign: "left" }}
          disabled={new Date().getYear() > record?.year || record?.status > SMSS}
        ></Input.TextArea>
        <label htmlFor="status" style={{ marginRight: "8px" }}>
          Status :
        </label>
        <Select
          id="status"
          className="full-width"
          onChange={(e) => this.saveStatusInPreview(e)}
          value={record ? record.selectionStatus : ""}
          style={{ width: "120px", marginRight: "8px", textAlign: "left" }}
          disabled={new Date().getYear() > record?.year || record?.status > SMSS}
        >
          <Option key="SUBMIT" value="SUBMIT">
            Please select
          </Option>
          {SEL_STATUS_LIST.map((status) => (
            <Option key={status} value={status}>
              {translate(status)}
            </Option>
          ))}
        </Select>

        <Button
          onClick={(e) => {
            this.saveSurveyData();
          }}
          type="primary"
          style={{ marginRight: 8 }}
          disabled={this.props.review.loading}
        >
          Save
        </Button>
        <Button onClick={this.onClose} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
    );
  };

  saveApplication = (form, step) => {
    this.props.saveApplicationReview(form, step, this.state.fieldData);
  };

  showNotifications = () => {
    let message = "";

    if (this.props.review.successMessage) {
      message += this.props.review.successMessage;
    }

    if (message) {
      notification.success({
        message: "Success",
        description: message,
      });
      this.props.resetMessage();
    }
  };

  offlineSearchApplication = (value) => {
    const filters = this.props.sampurnaSiksha.ssSearchFilters;
    filters.searchText = value.toUpperCase();
    this.props.offlineSearchApplication(
      filters,
      this.props.review.surveyedList
    );
    this.setState({ fieldData: filters });
  };

  onFiltersChange = (pagination, filters, sorter, extra) => {
    // console.log(
    //   "on filter change ------ : ",
    //   pagination,
    //   filters,
    //   sorter,
    //   extra
    // );
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.filters = filters;
    fieldData.sorter = sorter;
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
    this.props.setSearchApplication(extra.currentDataSource);
  };

  onPageChange = (page, pageSize) => {
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.currentPage = page;
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
  };

  showTotal = () => {
    const total = this.props.review.searchedApplications.length;
    return `Total ${total} Applicants`;
  };

  saveSurveyData = () => {
    this.props.updateSurveyData(
      this.props.review.surveyData,
      this.props.review.reviewDetails.surveyList,
      this.state.fieldData
    );
    const record = this.props.review.reviewDetails.application;
    this.props.updateCommitteeFeedback({ applications: [record] });
    this.saveStatus(record.selectionStatus, this.state.currentRecord);
  };

  generateFieldData = (personalInfo) => {
    const html = [];
    if (Array.isArray(personalInfo)) {
      for (const j of personalInfo) {
        for (const i in j) {
          html.push(
            <Col sm={6}>
              <label>{i}</label>:
            </Col>,
            <Col sm={6}>
              {" "}
              <span>{j[i]}</span>
            </Col>
          );
        }
      }
    } else {
      for (const i in personalInfo) {
        if (!Array.isArray(personalInfo[i])) {
          html.push(
            <Col sm={6}>
              <label>{i}</label>:
            </Col>,
            <Col sm={6}>
              {" "}
              <span>{personalInfo[i]}</span>
            </Col>
          );
        }
      }
    }
  };
  generateSurveyDetails = (surveyDetails, surveyKey, key) => {
    const data = createSurveyFormData(surveyDetails)[surveyKey];
    for (const i in data) {
      if (i === key) {
        return data[i];
      }
    }
    return "";
  };

  generateIncomeDetails = (surveyDetails) => {
    return (
      <IncomeSummaryView
        data={createSurveyFormData(surveyDetails)[INC_SUM]}
        cardLess={true}
        {...this.props}
      ></IncomeSummaryView>
    );
  };

  calculateTotalScore = (hallTicket) => {
    const { marksConfig } = this.props.sampurnaSiksha;
    const config = {};
    for (const mc of marksConfig) {
      config[mc.configCd] = mc.configVal;
    }
    const mct =
      (hallTicket.mctMarks /
        (config["MCT_TOTAL"] ? config["MCT_TOTAL"] : 100)) *
      (config["MCT_PERCENT"] ? config["MCT_PERCENT"] : 100);
    const ssc =
      (hallTicket.sscMarks /
        (config["SSC_TOTAL"] ? config["SSC_TOTAL"] : 100)) *
      (config["SSC_PERCENT"] ? config["SSC_PERCENT"] : 100);
    return (mct + ssc).toFixed(1) + "%";
  };

  saveStatus = (e, appl) => {
    const { surveyedList, searchedApplications } = this.props.review;

    for (const i of surveyedList) {
      if (i.applicationId === appl.applicationId) {
        i.selectionStatus = e;
      }
    }

    for (const i of searchedApplications) {
      if (i.applicationId === appl.applicationId) {
        i.selectionStatus = e;
      }
    }
    this.props.setApplicationsList(surveyedList, searchedApplications);
  };

  saveFeedback = (e, appl) => {
    const { surveyedList, searchedApplications } = this.props.review;

    for (const i of surveyedList) {
      if (i.applicationId === appl.applicationId) {
        let isExists = false;
        for (const c of i.comments) {
          if (c.entityType === "APPL" && c.eventType === "STUD_SEL") {
            c.comment = e.target.value;
            isExists = true;
          }
        }

        if (!isExists) {
          i.comments.push({
            comment: e.target.value,
            entityId: i.applicationId,
            entityType: "APPL",
            eventType: "STUD_SEL",
          });
        }
      }
    }

    for (const i of searchedApplications) {
      if (i.applicationId === appl.applicationId) {
        let isExists = false;
        for (const c of i.comments) {
          if (c.entityType === "APPL" && c.eventType === "STUD_SEL") {
            c.comment = e.target.value;
            isExists = true;
          }
        }

        if (!isExists) {
          i.comments.push({
            comment: e.target.value,
            entityId: i.applicationId,
            entityType: "APPL",
            eventType: "STUD_SEL",
          });
        }
      }
    }

    this.props.setApplicationsList(surveyedList, searchedApplications);
  };

  updateSelection = () => {
    const { searchedApplications } = this.props.review;
    const selKeys = this.state.selectedRowKeys;
    const data = searchedApplications.filter((appl) =>
      selKeys.includes(appl.applicationId)
    );

    // console.log("-------selection committee--------", data);
    if (data.length > 0) {
      this.props.updateCommitteeFeedback({ applications: data });
      this.setState({ selectedRowKeys: [] });
    } else {
      notification.error({
        message: "Error",
        description: "Select atleast one record to save.",
      });
    }
  };

  compareApplications = () => {
    const { searchedApplications } = this.props.review;
    const selKeys = this.state.selectedRowKeys;
    const data = searchedApplications.filter((appl) =>
      selKeys.includes(appl.applicationId)
    );
    let msg = "";
    if (data.length > 3) {
      msg = "Maximum three records can be compared";
    } else if (data.length < 2) {
      msg = "Select atleast two records to compare";
    }

    if (msg.length > 0) {
      notification.info({
        message: msg,
      });
    } else {
      this.props.setCompareList(data);
    }
  };

  closeCompare = () => {
    this.props.toggleCompare(false);
  };

  getNamesOfCompared = () => {
    const compareList = this.props.review.compareList;
    const html = [];
    for (const appl of compareList) {
      let str = appl.studentName + " " + appl.studentSurName;
      if (appl.selectionStatus !== "SUBMIT") {
        str += `(${translate(appl.selectionStatus)})`;
      }
      html.push(str);
    }

    return html.join(" & ");
  };

  getFeedback = (appl) => {
    const comments = appl.comments;
    let feedback = "";
    for (const c of comments) {
      if (c.entityType === "APPL" && c.eventType === "STUD_SEL") {
        feedback = c.comment;
      }
    }
    return feedback;
  };

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const searchFilters = this.props.sampurnaSiksha.ssSearchFilters;
    // const sortedInfo = searchFilters.sorter || {};
    const fieldData = [];
    for (let i in this.props.sampurnaSiksha.ssSearchFilters) {
      if (i === "oldDistId" && !this.props.sampurnaSiksha.ssSearchFilters[i]) {
        fieldData.push({
          name: [i],
          value: [],
        });
      } else {
        fieldData.push({
          name: [i],
          value: this.props.sampurnaSiksha.ssSearchFilters[i],
        });
      }
    }

    const dataSource = this.props.review.searchedApplications;

    const APPL_LIST_COLS = [
      {
        title: "Student's Name",
        dataIndex: "studentName",
        key: "studentName",
        render: (text, record) =>
          record.studentName + " " + record.studentSurName,
        sorter: {
          compare: (a, b) =>
            sortNames(
              a.studentName + " " + a.studentSurName,
              b.studentName + " " + b.studentSurName
            ),
        },
        // sortOrder: sortedInfo.columnKey === "studentName" && sortedInfo.order,
        defaultSortOrder: "ascend",
        onCell: (record, rowIndex) => {
          return {
            onClick: (e) => {
              this.showDetails(record, rowIndex, e);
            }, // click row
          };
        },
      },
      {
        title: "Occupation Details",
        children: [
          {
            title: "Father's Primary Occupation",
            dataIndex: "fPrimay",
            key: "fPrimary",
            render: (text, record) =>
              this.generateSurveyDetails(
                record.surveyDetails,
                OCC_DTLS,
                "fPrimary"
              ),
            sorter: {
              compare: (a, b) =>
                sortNames(
                  this.generateSurveyDetails(
                    a.surveyDetails,
                    OCC_DTLS,
                    "fPrimary"
                  ),
                  this.generateSurveyDetails(
                    b.surveyDetails,
                    OCC_DTLS,
                    "fPrimary"
                  )
                ),
            },
            width: "100px",
            // sortOrder: sortedInfo.columnKey === "fPrimary" && sortedInfo.order,
            onCell: (record, rowIndex) => {
              return {
                onClick: (e) => {
                  this.showDetails(record, rowIndex, e);
                }, // click row
              };
            },
          },
          {
            title: "Mother's Primary Occupation",
            dataIndex: "mPrimary",
            key: "mPrimary",
            render: (text, record) =>
              this.generateSurveyDetails(
                record.surveyDetails,
                OCC_DTLS,
                "mPrimary"
              ),
            sorter: {
              compare: (a, b) =>
                sortNames(
                  this.generateSurveyDetails(
                    a.surveyDetails,
                    OCC_DTLS,
                    "mPrimary"
                  ),
                  this.generateSurveyDetails(
                    b.surveyDetails,
                    OCC_DTLS,
                    "mPrimary"
                  )
                ),
            },
            width: "100px",
            // sortOrder: sortedInfo.columnKey === "mPrimary" && sortedInfo.order,
            onCell: (record, rowIndex) => {
              return {
                onClick: (e) => {
                  this.showDetails(record, rowIndex, e);
                }, // click row
              };
            },
          },
        ],
      },
      {
        title: "Income Details",
        children: [
          {
            title: "Total Annual Income",
            dataIndex: "totalAnnualIncome",
            key: "totalAnnualIncome",
            render: (text, record) =>
              toCurrency(
                this.generateSurveyDetails(
                  record.surveyDetails,
                  INC_SUM,
                  "newTotalAnnualIncome"
                )
              ),
            sorter: {
              compare: (a, b) =>
                sortNames(
                  this.generateSurveyDetails(
                    a.surveyDetails,
                    INC_SUM,
                    "newTotalAnnualIncome"
                  ),
                  this.generateSurveyDetails(
                    b.surveyDetails,
                    INC_SUM,
                    "newTotalAnnualIncome"
                  )
                ),
            },
            // sortOrder:
            //   sortedInfo.columnKey === "totalAnnualIncome" && sortedInfo.order,
            onCell: (record, rowIndex) => {
              return {
                onClick: (e) => {
                  this.showDetails(record, rowIndex, e);
                }, // click row
              };
            },
          },
          {
            title: "Total Asset Value",
            dataIndex: "totalPropertyValue",
            key: "totalPropertyValue",
            render: (text, record) =>
              toCurrency(
                this.generateSurveyDetails(
                  record.surveyDetails,
                  INC_SUM,
                  "newTotalPropertyValue"
                )
              ),
            sorter: {
              compare: (a, b) =>
                sortNames(
                  this.generateSurveyDetails(
                    a.surveyDetails,
                    INC_SUM,
                    "newTotalPropertyValue"
                  ),
                  this.generateSurveyDetails(
                    b.surveyDetails,
                    INC_SUM,
                    "newTotalPropertyValue"
                  )
                ),
            },
            // sortOrder:
            //   sortedInfo.columnKey === "totalPropertyValue" && sortedInfo.order,
            onCell: (record, rowIndex) => {
              return {
                onClick: (e) => {
                  this.showDetails(record, rowIndex, e);
                }, // click row
              };
            },
          },
        ],
      },
      {
        title: "SSC GPA",
        dataIndex: "sscMarks",
        key: "sscMarks",
        render: (text, record) => record.hallTicket?.sscMarks,
        sorter: {
          compare: (a, b) => a.hallTicket.sscMarks - b.hallTicket.sscMarks,
        },
        // sortOrder: sortedInfo.columnKey === "sscMarks" && sortedInfo.order,
        onCell: (record, rowIndex) => {
          return {
            onClick: (e) => {
              this.showDetails(record, rowIndex, e);
            }, // click row
          };
        },
      },
      {
        title: "MSS Score",
        dataIndex: "mctMarks",
        key: "mctMarks",
        render: (text, record) => record.hallTicket?.mctMarks,
        sorter: {
          compare: (a, b) => a.hallTicket.mctMarks - b.hallTicket.mctMarks,
        },
        // sortOrder: sortedInfo.columnKey === "mctMarks" && sortedInfo.order,
        onCell: (record, rowIndex) => {
          return {
            onClick: (e) => {
              this.showDetails(record, rowIndex, e);
            }, // click row
          };
        },
      },
      {
        title: "Combined Score",
        dataIndex: "totalScore",
        key: "totalScore",
        render: (text, record) => this.calculateTotalScore(record.hallTicket),
        sorter: {
          compare: (a, b) => {
            const { marksConfig } = this.props.sampurnaSiksha;
            const config = {};
            for (const mc of marksConfig) {
              config[mc.configCd] = mc.configVal;
            }
            const mct =
              (a.hallTicket.mctMarks /
                (config["MCT_TOTAL"] ? config["MCT_TOTAL"] : 100)) *
              (config["MCT_PERCENT"] ? config["MCT_PERCENT"] : 100);
            const ssc =
              (a.hallTicket.sscMarks /
                (config["SSC_TOTAL"] ? config["SSC_TOTAL"] : 100)) *
              (config["SSC_PERCENT"] ? config["SSC_PERCENT"] : 100);
              const mct2 =
              (b.hallTicket.mctMarks /
                (config["MCT_TOTAL"] ? config["MCT_TOTAL"] : 100)) *
              (config["MCT_PERCENT"] ? config["MCT_PERCENT"] : 100);
            const ssc2 =
              (b.hallTicket.sscMarks /
                (config["SSC_TOTAL"] ? config["SSC_TOTAL"] : 100)) *
              (config["SSC_PERCENT"] ? config["SSC_PERCENT"] : 100);
            return (mct + ssc) - (mct2 + ssc2);
          }
            // this.calculateTotalScore(a.hallTicket) -
            // this.calculateTotalScore(b.hallTicket),
        },
        // sortOrder: sortedInfo.columnKey === "totalScore" && sortedInfo.order,
        onCell: (record, rowIndex) => {
          return {
            onClick: (e) => {
              this.showDetails(record, rowIndex, e);
            }, // click row
          };
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
          <Select
            className="full-width"
            onChange={(e) => this.saveStatus(e, record)}
            value={record.selectionStatus}
            disabled={new Date().getYear() > record.year || record.status > SMSS}
          >
            <Option key="SUBMIT" value="SUBMIT">
              Please select
            </Option>
            {SEL_STATUS_LIST.map((status) => (
              <Option key={status} value={status}>
                {translate(status)}
              </Option>
            ))}
          </Select>
        ),
        sorting: false,
        width: "140px",
      },
      {
        title: "Feedback",
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
          <Input.TextArea
            value={this.getFeedback(record)}
            onChange={(e) => this.saveFeedback(e, record)}
            disabled={new Date().getYear() > record.year || record.status > SMSS}
          ></Input.TextArea>
        ),
        sorting: false,
        width: "350px",
      },
    ];
    return (
      <div id="selection-committee-container">
        <Card title="Selection of Students">
          <Form
            {...layout}
            onFinish={this.searchApplications}
            className="app-search-form"
            // fields={fieldData}
          >
            <Row>
            <Col xs={24} sm={6}>
                <Form.Item
                  {...formItemLayout}
                  name="year"
                  label="Year"
                >
                  <Select className="full-width">
                    {this.getYear()}
                  </Select>
                </Form.Item>
              </Col>
              {this.props.role === ADMIN && (
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="oldDistId"
                    label={translate("district")}
                  >
                    <Select className="full-width" mode="multiple">
                      <Option key={0} value={0}>
                        All
                      </Option>
                      {this.props.oldDistrictsList.map((district) => (
                        <Option key={district.id} value={district.id}>
                          {district.configVal}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} sm={6}>
                <Form.Item
                  {...formItemLayout}
                  name="selStatus"
                  label={translate("status")}
                >
                  <Select className="full-width" mode="multiple">
                    <Option key={"ALL"} value={"ALL"}>
                      All
                    </Option>
                    {SEL_STATUS_LIST.map((status) => (
                      <Option key={status} value={status}>
                        {translate(status)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "10px" }}
                  disabled={this.props.review.loading}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>

        <Card>
          <Search
            placeholder="Search"
            value={this.props.sampurnaSiksha.ssSearchFilters.searchText}
            onChange={(e) => this.offlineSearchApplication(e.target.value)}
            onSearch={(value) => this.offlineSearchApplication(value)}
            enterButton
            style={{ marginBottom: "10px", width: "75%", marginRight: "10px" }}
          />
          <Button
            onClick={this.updateSelection}
            className="mg-l-l"
            type="primary"
            loading={this.props.sampurnaSiksha.isExporting}
          >
            Save
          </Button>
          <Button
            onClick={this.compareApplications}
            className="mg-l-l"
            type="primary"
            loading={this.props.sampurnaSiksha.isExporting}
          >
            Compare
          </Button>
          {/* <Button
            onClick={this.exportToExcel}
            className="mg-l-l"
            loading={this.props.sampurnaSiksha.isExporting}
          >
            Export Excel
          </Button> */}
          <Spin
            spinning={
              this.props.review.loading &&
              !this.props.sampurnaSiksha.showDetails
            }
            delay={500}
          >
            <Table
              bordered
              columns={APPL_LIST_COLS}
              className="row-selection"
              rowSelection={rowSelection}
              rowKey={(record) => record.applicationId}
              onChange={this.onFiltersChange}
              dataSource={this.props.review.searchedApplications}
              pagination={{
                current: searchFilters.currentPage,
                onChange: this.onPageChange,
                showSizeChanger: true,
                showTotal: this.showTotal,
              }}
            />
          </Spin>
        </Card>

        <Drawer
          id="reviewers-details"
          title={
            <div>
              {this.props.review.reviewDetails.application ? (
                <h4>
                  <strong style={{ marginLeft: "10px" }}>
                    Name:{" "}
                    {(this.props.review.reviewDetails.application?.studentName ||
                      "") +
                        " " +
                        (this.props.review.reviewDetails.application
                          ?.studentSurName ||
                      "")}
                  </strong>
                  <strong style={{ marginLeft: "50px" }}>
                    Registered Mobile Number:{" "}
                    {this.props.review.reviewDetails.application?.username}
                  </strong>
                  {this.state.currentIndex < dataSource.length - 1 && (
                    <Button
                      onClick={(e) => this.showNext()}
                      style={{ marginRight: 8, float: "right" }}
                    >
                      Next
                    </Button>
                  )}
                  {this.state.currentIndex > 0 && (
                    <Button
                      onClick={(e) => this.showPrevious()}
                      style={{ marginRight: 8, float: "right" }}
                    >
                      Previous
                    </Button>
                  )}
                </h4>
              ) : (
                ""
              )}
            </div>
          }
          footer={this.generateActionButtons()}
          width="100%"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.props.sampurnaSiksha.showDetails}
        >
          <Spin spinning={this.props.review.loading} tip="Loading">
          {this.props.review.reviewDetails.application?.applicationId && (
            <SelectionCommitteeForm
              formRef={this.formRef}
              currentRecord={this.state.currentRecord}
              saveApplication={this.saveApplication}
              {...this.props}
            ></SelectionCommitteeForm>
          )}
          </Spin>
        </Drawer>

        {this.props.review.showCompare ? (
          <SurveyComparision
            formRef={this.formRef}
            saveApplication={this.saveApplication}
            saveSurveyData={this.saveSurveyData}
            closeCompare={this.closeCompare}
            {...this.props}
          ></SurveyComparision>
        ) : (
          ""
        )}

        {(this.props.review.successMessage ||
          this.props.sampurnaSiksha.successMessage) &&
          this.showNotifications()}
      </div>
    );
  }
}

export default SelectionCommittee;

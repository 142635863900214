import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  message,
  notification,
  Radio,
  Row,
  Select,
  Modal,
  Card,
} from "antd";
import React from "react";
import { formItemLayout } from "../../sampoorna-siksha/utils/consts";
import moment from "moment";
import { translate } from "../../base/actions/translate";
import ImageViewer from "../../sampoorna-siksha/components/reviewSteps/ImageViewer";
import {
  BLOOD_GROUPS,
  COMPL,
  DROP,
  INCH_MODAL,
  INC_PROF,
  PAN,
  PROFILE_IMGS,
  SP_FORM,
  STATUS_MODAL,
  STUD_IMG,
  STUD_PROF_FILE_TYPES,
} from "../utils/const";
import Text from "antd/lib/typography/Text";
import { IMAGE_TYPES } from "../../student/util/const";
import StatusUpdateModal from "./StatusUpdateModal";
import { toCapital } from "../../sampoorna-siksha/utils/util";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ADMIN, MSSPC, MGR } from "../../base/utils/roles";
import InchargeUpdateModal from "./InchargeUpdateModal";
import { getFutureStatusString, getStatusString } from "../utils/utils";

const dateFormat = "DD/MM/YYYY";

class StudentProfile extends React.Component {
  formRef = React.createRef();

  state = {
    cat: "",
    file: {},
    showValidation: false,
    pending: [],
    activePannels: ["1", "2"],
    formUpdated: false,
  };

  isValidImg(file) {
    const isJpgOrPng = IMAGE_TYPES.includes(file.type);
    const isLt500KB = file.size / 1024 < 500;
    return isJpgOrPng && isLt500KB;
  }

  isValidDoc(file) {
    const isJpgOrPngOrPdf =
      IMAGE_TYPES.includes(file.type) || file.type === "application/pdf";
    const isLt500KB = file.size / 1024 < 500;
    return isJpgOrPngOrPdf && isLt500KB;
  }

  isValid = () => {
    const currentUploads = this.props.mgUploads.currentUploads;
    const pending = [];
    let isValid = true;
    for (const i of STUD_PROF_FILE_TYPES) {
      if(i !== PAN && i !== INC_PROF) {
        const u = currentUploads.filter((cu) => cu.type === i);
        if (u.length === 0) {
          isValid = false;
          pending.push(translate(i));
        }
      }
    }

    if (!isValid) {
      notification["error"]({
        message:
          "Please upload photos for the following category : " +
          pending.join(", "),
      });
    }

    return isValid;
  };

  setFiles = (e) => {
    console.log("files", this.files);
    const file = document.getElementById("myFileB").files[0];
    if (!file) {
      this.setState({ cat: "" });
      return;
    }
    const isValid =
      this.state.cat === STUD_IMG
        ? this.isValidImg(file)
        : this.isValidDoc(file);
    if (!isValid) {
      message.error("Please upload valid file less than 500 KB.");
      this.setState({ cat: "" });
      document.getElementById("myFileB").value = "";
      return;
    }

    const currentProfile = this.props.manage.currentProfile;
    const { cat } = this.state;

    // Save the new image uploaded as a draft.
    this.props.draftUpload(
      currentProfile.studentProfileId,
      file,
      cat,
      currentProfile.batch
    );
    this.setState((state) => ({
      cat: "",
    }));
    document.getElementById("myFileB").value = "";
  };

  handleUpload = (value) => {
    console.log("---------", value);
    if (value) {
      this.setState({ cat: value });
      document.getElementById("myFileB").click();
    }
  };

  saveProfile = (form) => {
    console.log("profile", form);
    if (!this.isValid()) {
      return;
    }
    this.props.saveStudentProfile(form);
    this.saveImages(form);
  };

  saveImages = (form) => {
    if (!this.isValid()) {
      return;
    }
    const { deletedImages, currentUploads } = this.props.mgUploads;
    const draftIds = [];
    for (const img of currentUploads) {
      if (img.status === "P") {
        draftIds.push(img.managementUploadId);
      }
    }
    const delIds = [];
    for (const img of deletedImages) {
      delIds.push(img.managementUploadId);
    }
    if (draftIds.length > 0) {
      this.props.approveDrafts(form.studentProfileId, draftIds);
    }

    if (delIds.length > 0) {
      this.props.deleteFiles(delIds);
    }
    this.setState({ formUpdated: false });
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return (
      current.year() < moment().subtract(18, "years").year() ||
      current.year() > moment().subtract(13, "years").year()
    );
  };

  getDefaultPickerValue = () => {
    if (this.props.manage.currentProfile.dateOfBirth) {
      return moment(this.props.manage.currentProfile.dateOfBirth);
    } else {
      return moment().subtract(18, "years");
    }
  };

  disableField = () => {
    return this.props.manage.editForm !== SP_FORM;
  };

  onValueChange = () => {
    this.setState({ formUpdated: true });
  };

  onClose = (e) => {
    if (this.state.formUpdated) {
      const that = this;
      Modal.confirm({
        title:
          "There are some unsaved changes. Do you want to proceed with out saving?",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          that.props.onClose(e);
          that.setState({ formUpdated: false });
          that.props.setEditForm("");
        },
        okText: "Yes",
        cancelText: "No",
        onCancel() {},
      });
    } else {
      this.props.onClose(e);
      this.props.setEditForm("");
    }
  };

  getCurrentHostelName = () => {
    let val = "";
    if (this.props.manage.currentProfile.studentHostelDetails?.length > 0) {
      for (const i of this.props.manage.currentProfile.studentHostelDetails) {
        if (i.status === 1) {
          if (val.length > 0) {
            val += ", ";
          }
          val += i.hostel?.hostelName;
        }
      }
    }
    return val;
  };

  getCurrentHostelLocation = () => {
    let val = "";
    if (this.props.manage.currentProfile.studentHostelDetails?.length > 0) {
      for (const i of this.props.manage.currentProfile.studentHostelDetails) {
        if (i.status === 1) {
          if (val.length > 0) {
            val += ", ";
          }
          val += i.hostel?.location;
        }
      }
    }
    return val;
  };

  getCurrentCourse = () => {
    let clg = "";
    if (this.props.manage.currentProfile.studentAcademics?.length > 0) {
      for (const i of this.props.manage.currentProfile.studentAcademics) {
        if (i.status === 1) {
          clg = i.course.courseName;
        }
      }
    }
    return clg;
  };

  getCurrentCollege = () => {
    let clg = "";
    if (this.props.manage.currentProfile.studentAcademics?.length > 0) {
      for (const i of this.props.manage.currentProfile.studentAcademics) {
        if (i.status === 1) {
          clg = i.institute.name;
        }
      }
    }
    return clg;
  };

  generateStatusHistory = () => {
    const statuses = this.props.manage.currentProfile?.studentStatus;
    const html = [];
    if (statuses) {
      for (const i of statuses) {
        if (i.info) {
          const data = JSON.parse(i.info);
          const p = [];
          for (const key of Object.keys(data)) {
            let val = data[key];
            if (key === "futureStatus") {
              val = getFutureStatusString(Number(val));
            } else if (key === "status") {
              val = getStatusString(Number(val));
            } else if(key==="date") {
              val = moment(val).format("DD-MM-YYYY");
            }
            p.push(
              <Col sm={6} xs={24}>
                <label>{key === "date" ? "Date" : translate(key)} : </label>
                <span>{val}</span>
              </Col>
            );
          }
          html.push(<Col sm={24}><Row gutter={[16, 8]}>{p}</Row> <hr></hr></Col>);
        }
      }
    }
    return html;
  };

  render() {
    const initialValues = this.props.manage.currentProfile;
    initialValues.dateOfBirth = moment(initialValues.dateOfBirth);
    const uploadedFiles = [];
    let studImg = [];
    for (const up of this.props.mgUploads.currentUploads) {
      if (PROFILE_IMGS.includes(up.type)) {
        if (up.type !== STUD_IMG) {
          uploadedFiles.push(up);
        } else {
          studImg.push(up);
        }
      }
    }
    return (
      <div id="profile-container">
        <Form
          initialValues={initialValues}
          onFinish={this.saveProfile}
          ref={this.formRef}
          onValuesChange={(e) => this.onValueChange(e)}
        >
          <Form.Item name="studentId" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name="studentProfileId" hidden={true}>
            <Input />
          </Form.Item>
          <Row gutter={[16, 8]}>
            <Col sm={18}>
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="firstName"
                    label="Student Name"
                    rules={[
                      {
                        required: true,
                        pattern: /^\S[A-Za-z\s]+$/,
                        message: "Please enter Student Name",
                      },
                    ]}
                  >
                    <Input disabled={this.disableField()} maxLength="50" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="lastName"
                    label="Student Sur Name"
                    rules={[
                      {
                        required: true,
                        pattern: /^\S[A-Za-z\s]+$/,
                        message: "Please enter Student Sur Name",
                      },
                    ]}
                  >
                    <Input disabled={this.disableField()} maxLength="30" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="studentIdVal"
                    label="Student ID"
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid Student ID",
                      },
                    ]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="batch"
                    label="Batch"
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid batch",
                      },
                    ]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="emailAddress"
                    label="Email Address"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email address.",
                      },
                    ]}
                  >
                    <Input disabled={this.disableField()} maxLength="50" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="mobileNumber"
                    label="Mobile Number"
                    rules={[
                      {
                        required: true,
                        pattern: /^\d{10}$/,
                        message: "Please enter a valid phone number.",
                      },
                    ]}
                  >
                    <Input disabled={this.disableField()} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    {...formItemLayout}
                    name="alternateNumber"
                    label="Alternate Mobile Number"
                    rules={[
                      {
                        pattern: /^\d{10}$/,
                        message: "Please enter a valid phone number.",
                      },
                    ]}
                  >
                    <Input disabled={this.disableField()} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-12">
                      <label
                        htmlFor="collegeName"
                        className="ant-form-item-required"
                        title="Current College Name"
                      >
                        Current College Name
                      </label>
                    </div>
                    <div
                      className="ant-col ant-form-item-control"
                      style={{ padding: "8px" }}
                    >
                      {this.getCurrentCollege()}
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-12">
                      <label
                        htmlFor="collegeName"
                        className="ant-form-item-required"
                        title="Current College Name"
                      >
                        Current Course Name
                      </label>
                    </div>
                    <div
                      className="ant-col ant-form-item-control"
                      style={{ padding: "8px" }}
                    >
                      {this.getCurrentCourse()}
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-12">
                      <label
                        htmlFor="collegeName"
                        className="ant-form-item-required"
                        title="Current College Name"
                      >
                        Current Hostel Name
                      </label>
                    </div>
                    <div
                      className="ant-col ant-form-item-control"
                      style={{ padding: "8px" }}
                    >
                      {this.getCurrentHostelName()}
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-12">
                      <label
                        htmlFor="collegeName"
                        className="ant-form-item-required"
                        title="Current College Name"
                      >
                        Current Hostel Location
                      </label>
                    </div>
                    <div
                      className="ant-col ant-form-item-control"
                      style={{ padding: "8px" }}
                    >
                      {this.getCurrentHostelLocation()}
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-12">
                      <label
                        htmlFor="collegeName"
                        className="ant-form-item-required"
                        title="Current College Name"
                      >
                        Incharge
                      </label>
                    </div>
                    <div
                      className="ant-col ant-form-item-control"
                      style={{ padding: "8px" }}
                    >
                      {this.props.manage.incharge
                        ? this.props.manage.incharge.firstName +
                          " " +
                          this.props.manage.incharge.lastName
                        : ""}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <ImageViewer
                imageList={studImg}
                hideMeta={true}
                showActions={false}
                loading={this.props.manage.loading}
                year={initialValues.batch}
                {...this.props}
              />
            </Col>
          </Row>

          <Collapse
            bordered={false}
            defaultActiveKey={this.state.activePannels}
          >
            <Collapse.Panel header="Personal details" key="1">
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="dateOfBirth"
                    label={translate("dateOfBirth")}
                    rules={[
                      {
                        required: true,
                        message: "Please select a Date of Birth",
                      },
                    ]}
                  >
                    <DatePicker
                      format={dateFormat}
                      className="full-width"
                      showToday={false}
                      disabledDate={this.disabledDate}
                      defaultPickerValue={this.getDefaultPickerValue()}
                      disabled={this.disableField()}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="bloodGroup"
                    label="Blood Group"
                    // rules={[{ required: true, message: "Please select a Blood Group" }]}
                  >
                    <Select disabled={this.disableField()}>
                      {BLOOD_GROUPS.map((bg) => (
                        <Select.Option key={bg} value={bg}>
                          {bg}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="gender"
                    label={translate("gender")}
                    rules={[
                      { required: true, message: translate("genderError") },
                    ]}
                  >
                    <Radio.Group disabled={this.disableField()}>
                      <Radio value="M">Male</Radio>
                      <Radio value="F">Female</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="casteId"
                    label={translate("casteId")}
                    rules={[
                      {
                        required: true,
                        message: translate("castCategoryError"),
                      },
                    ]}
                  >
                    <Select
                      className="full-width"
                      disabled={this.disableField()}
                    >
                      {this.props.appConfig.castesList.map((caste) => (
                        <Select.Option key={caste.id} value={caste.id}>
                          {caste.configVal}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="panNumber"
                    label="PAN Card Number"
                    getValueFromEvent={(e) => toCapital(e)}
                    rules={[
                      {
                        pattern: /^\S[A-Za-z0-9\s]{9}$/,
                        message: "Please senter a valid pan card number.",
                      },
                    ]}
                  >
                    <Input disabled={this.disableField()} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="adhaarNumber"
                    label="Aadhaar Card Number"
                    rules={[
                      {
                        // required: true,
                        pattern: /^\d{12}$/,
                        message: "Please enter a valid Aadhaar Card Number.",
                      },
                    ]}
                  >
                    <Input disabled={this.disableField()} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="annualIncome"
                    label="Annual Income"
                    rules={[
                      {
                        // required: true,
                        pattern: /^[1-9][0-9]*$/,
                        message: "Please enter a valid annual income.",
                      },
                    ]}
                  >
                    <Input maxLength="8" disabled={this.disableField()} />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Parents Details" key="2">
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="fatherName"
                    label="Father's Name"
                    getValueFromEvent={(e) => toCapital(e)}
                    rules={[
                      {
                        required: true,
                        min: 3,
                        pattern: /^\S[A-Za-z\s]+$/,
                        message: "Please enter a valid father's name.",
                      },
                    ]}
                  >
                    <Input disabled={this.disableField()} maxLength="50" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="motherName"
                    label="Mother's Name"
                    getValueFromEvent={(e) => toCapital(e)}
                    rules={[
                      {
                        required: true,
                        min: 3,
                        pattern: /^\S[A-Za-z\s]+$/,
                        message: "Please enter a valid mother's name.",
                      },
                    ]}
                  >
                    <Input disabled={this.disableField()} maxLength="50" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="guardianName"
                    label="Guardian’s Name"
                    getValueFromEvent={(e) => toCapital(e)}
                    rules={[
                      {
                        min: 3,
                        pattern: /^\S[A-Za-z\s]+$/,
                        message: "Please enter a valid guardian's name.",
                      },
                    ]}
                  >
                    <Input disabled={this.disableField()} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="parentPhoneNumber"
                    label="Parent's Phone"
                    rules={[
                      {
                        required: true,
                        pattern: /^\d{10}$/,
                        message: "Please enter a valid phone number.",
                      },
                    ]}
                  >
                    <Input disabled={this.disableField()} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="parentAltPhoneNumber"
                    label="Parent’s Alternate Phone"
                    rules={[
                      {
                        pattern: /^\d{10}$/,
                        message: "Please enter a valid phone number.",
                      },
                    ]}
                  >
                    <Input disabled={this.disableField()} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="homeAddress"
                    label="Home Address"
                    rules={[
                      {
                        required: true,
                        min: 10,
                        message: translate("studentHomeAddressError"),
                      },
                    ]}
                  >
                    <Input.TextArea
                      maxLength="50"
                      disabled={this.disableField()}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
          {this.props.manage.editForm === SP_FORM && (
            <Row className="mg-l" style={{ marginBottom: "30px" }}>
              <Col sm={12}>
                <label style={{ fontWeight: "bold" }}>
                  Select Upload Category :{" "}
                </label>
                <Select
                  placeholder="Select Category"
                  onSelect={(v) => this.handleUpload(v)}
                  value={this.state.cat}
                  style={{ width: "60%" }}
                >
                  <Select.Option key="0" value="">
                    Select a category
                  </Select.Option>
                  {STUD_PROF_FILE_TYPES.map((i) => (
                    <Select.Option key={i} value={i}>
                      {translate(i)}
                    </Select.Option>
                  ))}
                </Select>
                {this.state.showValidation && (
                  //  <Text type="danger">Please upload {pending.join(',')}.</Text>
                  <Text type="danger">
                    Please upload all the required images.
                  </Text>
                )}
              </Col>
            </Row>
          )}
          <input
            type="file"
            id="myFileB"
            style={{ display: "none" }}
            onChange={this.setFiles}
          ></input>
          <ImageViewer
            imageList={uploadedFiles}
            showActions={false}
            loading={this.props.manage.loading}
            year={initialValues.batch}
            {...this.props}
          />
          
            {(this.props.manage.currentProfile?.studentStatus?.length > 0) ? (
              <Card title="Status History">
              <Row gutter={[16, 8]}>{this.generateStatusHistory()}</Row>
              </Card>
            ) : (
              ""
            )}
          

          <div style={{ textAlign: "right" }}>
            {(this.props.role === ADMIN ||
              this.props.role === MSSPC ||
              this.props.role === MGR ||
              this.props.profile?.id ===
                this.props.manage.incharge?.userId) && (
              <>
                {this.props.manage.editForm !== SP_FORM && (
                  <Button
                    type="primary"
                    onClick={(e) => {
                      this.props.setEditForm(SP_FORM);
                    }}
                    style={{ marginRight: 8 }}
                  >
                    Edit
                  </Button>
                )}

                {this.props.manage.editForm === SP_FORM && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: 8 }}
                  >
                    Save
                  </Button>
                )}

                {(this.props.role === ADMIN ||
                  this.props.role === MSSPC ||
                  this.props.role === MGR ||
                  this.props.profile?.id ===
                    this.props.manage.incharge?.userId) &&
                    this.props.manage.currentProfile.status !== COMPL &&
                    this.props.manage.currentProfile.parentPhoneNumber && (
                    <Button
                      onClick={(e) => {
                        this.props.toogleAddModal(STATUS_MODAL);
                      }}
                      style={{ marginRight: 8 }}
                    >
                      Update Status
                    </Button>
                  )}
                {(this.props.role === ADMIN ||
                  this.props.role === MSSPC ||
                  this.props.role === MGR ||
                  this.props.profile?.id ===
                    this.props.manage.incharge?.userId) &&
                    [DROP, COMPL].indexOf(this.props.manage.currentProfile.status) === -1 &&
                    this.props.manage.currentProfile.parentPhoneNumber && (
                    <Button
                      onClick={(e) => {
                        this.props.toogleAddModal(INCH_MODAL);
                      }}
                      style={{ marginRight: 8 }}
                    >
                      Update Incharge
                    </Button>
                  )}
              </>
            )}
            <Button onClick={(e) => this.onClose(e)} style={{ marginRight: 8 }}>
              Close
            </Button>
          </div>
        </Form>
        {this.props.manage.showModal === STATUS_MODAL && (
          <StatusUpdateModal {...this.props} />
        )}

        {this.props.manage.showModal === INCH_MODAL && (
          <InchargeUpdateModal
            inchargeId={this.props.manage.incharge?.userId}
            {...this.props}
          />
        )}
      </div>
    );
  }
}

export default StudentProfile;

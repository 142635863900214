import React from "react";
import {
  Card,
  Form,
  Col,
  Select,
  Row,
  Button,
  Spin,
  Table,
  Input,
  notification,
  Checkbox,
} from "antd";
import { translate } from "../../base/actions/translate";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import { APPRV } from "../../sampoorna-siksha/utils/consts";

const { Option } = Select;
const { Search } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class AdminShortList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldData: {
        oldDistId: "",
        mandalId: "",
        schoolId: "",
        currentPage: 1,
        filters: {},
        sorter: {columnKey: 'mctMarks'},
      },
      activePanels: "",
      currentRecord: {},
      showDetails: false,
      disableFields: true,
      reasonsList: [],
      showReasonModal: false,
      reason: {
        reason: "",
        otherReason: "",
        status: "",
        errorMsg: "",
      },
      currentIndex: 0,
      selectedRowKeys: [],
      shortListed: [],
    };

    this.formRef = React.createRef();
  }

  componentWillMount() {
    this.props.getOldDistricts();
    this.searchApplications({ oldDistId: null });
  }

  onSelectChange = (selectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  toCapital = (e) => {
    if (e.target.value) {
      return e.target.value.toUpperCase();
    }
    return e.target.value;
  };

  searchApplications = (form) => {
    // console.log("-------", form);
    form.currentPage = 1;
    // form.pageSize = 10;
    // form.sortOrder = 'asc';
    // form.sortField = 'applicationId';
    form.filters = {};
    form.sorter = {};
    form.searchText = "";
    this.setState({ fieldData: form, activePanels: "" });
    this.props.searchApplications(form);
    this.props.setSearchFilters(form);
  };

  showDetails = (record, index) => {
    // console.log("-------------", record);
    this.setState({
      currentRecord: record,
      showDetails: true,
      currentIndex: index,
    });
    this.props.toggleDetails(true);
    this.props.getApplicationDetails(record);
    this.props.getAppHistory(record.applicationId);
  };

  exportToExcel = () => {
    this.props.exportToExcel(this.props.sampurnaSiksha.ssSearchFilters);
  };

  onClose = () => {
    this.setState({ showDetails: false, disableFields: true });
    this.props.toggleDetails(false);
    // console.log("form ref.... : ", this.formRef);
    this.props.getOldDistricts();
    this.props.deleteDraft(this.state.currentRecord.applicationId);
  };

  enableFields = () => {
    this.setState({ disableFields: false });
  };

  bulkApproveApplications = () => {
    this.props.shortListApplications(
      this.state.shortListed,
      this.props.sampurnaSiksha.ssSearchFilters
    );
    this.setState({ selectedRowKeys: [], shortListed: [] });
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.currentPage = 1;
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
  };

  generateActionButtons = () => {
    const status = this.props.student.applicationForm.status
      ? this.props.student.applicationForm.status
      : this.state.currentRecord.status;
    return (
      <div style={{ textAlign: "right" }}>
        {(status !== APPRV || "ADMIN" === this.props.role) && (
          <Button
            onClick={(e) => {
              this.formRef.current.submit();
            }}
            type="primary"
            style={{ marginRight: 8 }}
          >
            {" "}
            Save{" "}
          </Button>
        )}
        <Button onClick={this.onClose} style={{ marginRight: 8 }}>
          Cancel
        </Button>
      </div>
    );
  };

  saveApplication = (form, application, username, uploadCatList) => {
    if (uploadCatList.length > 0) {
      this.props.saveDraftFiles(
        form,
        application,
        username,
        this.props.sampurnaSiksha.ssSearchFilters,
        uploadCatList
      );
    } else {
      this.props.saveMssApplicationForm(
        form,
        application,
        username,
        this.props.sampurnaSiksha.ssSearchFilters
      );
    }
    this.setState({ disableFields: true });
  };

  showNotifications = () => {
    let message = "";

    if (this.props.admin.successMessage) {
      message += this.props.admin.successMessage;
    }

    if (message) {
      notification.success({
        message: "Success",
        description: message,
      });
      this.props.resetMessage();
    }
  };

  offlineSearchApplication = (value) => {
    const filters = this.props.sampurnaSiksha.ssSearchFilters;
    filters.searchText = value.toUpperCase();
    this.props.offlineSearchApplication(
      filters,
      this.props.sampurnaSiksha.applications
    );
    this.setState({ fieldData: filters });
  };

  onFiltersChange = (pagination, filters, sorter, extra) => {
    // console.log(
    //   "on filter change ------ : ",
    //   pagination,
    //   filters,
    //   sorter,
    //   extra
    // );
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.filters = filters;
    fieldData.sorter = sorter;
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
    this.props.setSearchApplication(extra.currentDataSource);
  };

  onPageChange = (page, pageSize) => {
    const fieldData = this.props.sampurnaSiksha.ssSearchFilters;
    fieldData.currentPage = page;
    this.setState({ fieldData });
    this.props.setSearchFilters(fieldData);
  };

  showTotal = () => {
    const total = this.props.sampurnaSiksha.searchedApplications.length;
    return `Total ${total} Applicants`;
  };

  updateSelection = (e, ht) => {
    ht.shortListed = e.target.checked ? "Y" : "N";
    const selectedRowKeys = this.state.selectedRowKeys;
    const shortListed = this.state.shortListed;
    const index = selectedRowKeys.indexOf(ht.hallTicketId);
    if (index > -1) {
      selectedRowKeys.splice(index, 1);
      shortListed.splice(index, 1);
    }

    selectedRowKeys.push(ht.hallTicketId);
    shortListed.push(ht);

    this.setState({ shortListed, selectedRowKeys });
  };

  render() {
    const searchFilters = this.props.sampurnaSiksha.ssSearchFilters;
    // const sortedInfo = searchFilters.sorter || {columnKey:'mctMarks'};
    
    const dataSource = this.props.sampurnaSiksha.searchedApplications;

    const USERS_LIST_COLS = [
      // {
      //   title: "S.No",
      //   dataIndex: "applicationId",
      //   key: "applicationId",
      //   render: (text, record, index) => index + 1,
      //   sorter: {
      //     compare: (a, b) => a.applicationId > b.applicationId,
      //   },
      //   sortOrder: sortedInfo.columnKey === "applicationId" && sortedInfo.order,
      // },
      {
        title: "Old District",
        dataIndex: "districtId",
        key: "districtId",
        render: (text, record) => record.school.parent.parent.parent.configVal,
        sorter: {
          compare: (a, b) =>
            sortNames(
              a.school.parent.parent.parent.configVal,
              b.school.parent.parent.parent.configVal
            ),
        },
        // sortOrder: sortedInfo.columnKey === "districtId" && sortedInfo.order,
      },
      {
        title: "Student’s Name",
        dataIndex: "studentName",
        key: "studentName",
        render: (text, record) =>
          record.studentName + " " + record.studentSurName,
        sorter: {
          compare: (a, b) =>
            sortNames(
              a.studentName + " " + a.studentSurName,
              b.studentName + " " + b.studentSurName
            ),
        },
        // sortOrder: sortedInfo.columnKey === "studentName" && sortedInfo.order,
      },
      {
        title: "Caste",
        dataIndex: "casteId",
        key: "casteId",
        render: (text, record) => record.caste.configVal,
        sorter: {
          compare: (a, b) => sortNames(a.caste.configVal, b.caste.configVal),
        },
        // sortOrder: sortedInfo.columnKey === "casteId" && sortedInfo.order,
      },
      {
        title: "MCT Exam Marks",
        dataIndex: "mctExam",
        key: "mctMarks",
        render: (text, record) => record.hallTicket?.mctMarks,
        sorter: {
          compare: (a, b) => a.hallTicket.mctMarks - b.hallTicket.mctMarks,
        },
        // sortOrder: sortedInfo.columnKey === "mctMarks" && sortedInfo.order,
        defaultSortOrder: 'descend',
      },
      {
        title: "Shortlist MSS",
        dataIndex: "shortListed",
        key: "shortListed",
        render: (text, record) => {
          if (record.status === 11) {
            return (
              <Checkbox
                onChange={(e) => this.updateSelection(e, record.hallTicket)}
                defaultChecked
              ></Checkbox>
            );
          } else {
            return (
              <Checkbox
                onChange={(e) => this.updateSelection(e, record.hallTicket)}
              ></Checkbox>
            );
          }
        },
        sorter: {
          compare: (a, b) => a.status - b.status,
        },
        // sortOrder: sortedInfo.columnKey === "shortListed" && sortedInfo.order,
      },
    ];
    return (
      <div className="mg-l">
        <Card title="Shortlist MSS Candidates">
          <Form
            {...layout}
            onFinish={this.searchApplications}
            className="app-search-form"
          >
            <Row>
              <Col xs={24} sm={8}>
                <Form.Item
                  {...formItemLayout}
                  name="oldDistId"
                  label={translate("district")}
                >
                  <Select className="full-width">
                    <Option value=" ">All</Option>
                    {this.props.oldDistrictsList.map((district) => (
                      <Option key={district.id} value={district.id}>
                        {district.configVal}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "10px" }}
                  disabled={this.props.sampurnaSiksha.loading}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card>
          <Search
            placeholder="Search"
            value={this.props.sampurnaSiksha.ssSearchFilters.searchText}
            onChange={(e) => this.offlineSearchApplication(e.target.value)}
            onSearch={(value) => this.offlineSearchApplication(value)}
            enterButton
            style={{ marginBottom: "10px", width: "85%", marginRight: "10px" }}
          />
          <Button
            type="primary"
            onClick={(e) => this.bulkApproveApplications()}
            disabled={this.state.shortListed.length === 0}
          >
            Save Selection
          </Button>
          <Spin
            spinning={
              this.props.sampurnaSiksha.loading &&
              !this.props.sampurnaSiksha.showDetails
            }
            delay={500}
          >
            <Table
              columns={USERS_LIST_COLS}
              className="row-selection"
              rowKey={(record) => record.applicationId}
              onChange={this.onFiltersChange}
              dataSource={dataSource}
              pagination={{
                current: searchFilters.currentPage,
                onChange: this.onPageChange,
                showSizeChanger: true,
                showTotal: this.showTotal,
              }}
            />
          </Spin>
        </Card>
        {this.props.admin.successMessage && this.showNotifications()}
      </div>
    );
  }
}

export default AdminShortList;

import { Button, Form, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { sortNames } from "../../../sampoorna-siksha/utils/util";
import moment from "moment";
import { INST } from "../../../mastertables/utils/const";
import StudentAcademicForm from "./StudentAcademicForm";
import { MSSSTUD } from "../../../base/utils/roles";

const dateTimeFormat = "DD/MM/YYYY";

const StudentInstitutionDetails = (props) => {
  const [institutions, setInstitutions] = useState([]);
  const [currentRec, setCurrentRec] = useState();
  const [form] = Form.useForm();
  useEffect(() => {
    props.fetchMasterTableData(INST);
  }, []);

  const generateInstitutions = () => {
    const inst = [];
    if (!props.currentProfile?.school) {
      return inst;
    }
    inst.push({
      id: 0,
      edCat: "SSC",
      institutionName: props.currentProfile?.school.configVal,
      location: props.currentProfile?.school.parent.configVal,
      courseName: "10th Class",
      startYear: props.currentProfile?.batch
        ? props.currentProfile.batch - 1
        : "",
      endYear: props.currentProfile?.batch,
    });
    for (const ac of props.currentProfile.studentAcademics) {
      const { institute, course } = ac;
      inst.push({
        id: ac.studentAcadId,
        edCat: institute.educationCategory.educationCategory.name,
        institutionName: institute.name,
        location: institute.details[0]?.location,
        courseName: course.courseName,
        startYear: ac.startYear,
        endYear: ac.endYear,
        studentAcademics: ac,
      });
    }
    setInstitutions(inst);
  };

  useEffect(() => {
    if (props.currentProfile?.school) {
      generateInstitutions();
    }
  }, [props.currentProfile.studentAcademics, props.currentProfile.school]);

  const editRecord = (record, e) => {
    e.preventDefault();
    if(record.studentAcademics.startYear) {
      record.studentAcademics.startYear = moment(
        record.studentAcademics.startYear
      );
    }
    if(record.studentAcademics.endYear) {
      record.studentAcademics.endYear = moment(
        record.studentAcademics.endYear
      );
    }
    setCurrentRec(record.studentAcademics);

    form.setFieldsValue(record.studentAcademics);
    props.toogleAddModal("EDIT_ACAD");
  };

  const deleteRecord = (record, e) => {
    e.preventDefault();
    props.deleteStudentAcademics(record.studentAcademics);
  };

  const onFinish = (form) => {
    console.log("-------", form);
    form.studentProfileId = props.currentProfile.studentProfileId;
    props.saveStudentAcademics(form);
  };

  const onCancel = () => {
    form.resetFields();
    props.toogleAddModal("");
  };

  const STUD_INST_COLS = [
    {
      title: "Education Category",
      dataIndex: "educationCategory",
      key: "educationCategory",
      render: (text, record) => record.edCat,
      sorter: {
        compare: (a, b) => sortNames(a.edCat, b.edCat),
      },
    },
    {
      title: "Institution Name",
      dataIndex: "institutionName",
      key: "institutionName",
      render: (text, record) => record.institutionName,
      sorter: {
        compare: (a, b) => sortNames(a.institutionName, b.institutionName),
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text, record) => record.location,
      sorter: {
        compare: (a, b) => sortNames(a.location - b.location),
      },
    },
    {
      title: "Course Name",
      dataIndex: "courseName",
      key: "courseName",
      render: (text, record) => record.courseName,
      sorter: {
        compare: (a, b) => sortNames(a.courseName, b.courseName),
      },
    },
    {
      title: "Start Year",
      dataIndex: "startYear",
      key: "startYear",
      render: (text, record) =>
        record.edCat !== "SSC"
          ? record.startYear
            ? moment(record.startYear).format(dateTimeFormat)
            : ""
          : record.startYear,
      sorter: {
        compare: (a, b) => sortNames(a.startYear, b.startYear),
      },
    },
    {
      title: "End Year",
      dataIndex: "endYear",
      key: "endYear",
      render: (text, record) =>
        record.edCat !== "SSC"
          ? record.endYear
            ? moment(record.endYear).format(dateTimeFormat)
            : ""
          : record.endYear,
      sorter: {
        compare: (a, b) => sortNames(a.endYear, b.endYear),
      },
    },
  ];

  if (props.role !== MSSSTUD) {
    STUD_INST_COLS.push({
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {record.edCat !== "SSC" && (
            <>
              <a href="/" onClick={(e) => editRecord(record, e)}>
                Edit
              </a>
              |
              <a href="/" onClick={(e) => deleteRecord(record, e)}>
                Delete
              </a>
            </>
          )}
        </Space>
      ),
    });
  }

  return (
    <>
      {props.role !== MSSSTUD && (
        <Button
          type="primary"
          style={{ float: "right" }}
          onClick={(e) => {
            setCurrentRec({});
            form.resetFields();
            props.toogleAddModal("EDIT_ACAD");
          }}
        >
          Add
        </Button>
      )}
      <div style={{overflowX: 'auto'}}>
      <Table
        className="row-selection"
        columns={STUD_INST_COLS}
        dataSource={institutions}
        pagination={{ showSizeChanger: true }}
      />
      </div>
      {props.manage.showModal === "EDIT_ACAD" && (
        <StudentAcademicForm
          onCancel={onCancel}
          onFinish={onFinish}
          currentRec={currentRec}
          {...props}
        />
      )}
    </>
  );
};

export default StudentInstitutionDetails;

import { connect } from "react-redux";
import { RESET_MESSAGES } from "../../base/utils/const";
import { getNotifications, setReadNotifications } from "../actions/NotificationActions";
import Notifications from "../components/Notifications";

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications() {
      dispatch(getNotifications());
    },

    setReadNotifications() {
      dispatch(setReadNotifications());
    },
    
    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    NotificationReducer
  } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    notif: NotificationReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Switch,
  Table,
} from "antd";
import React from "react";
import {
  APRV,
  CLOS,
  COMP,
  DEN,
  MARKS_APPRV,
  MED_REQ_APPRV,
  MON_REQ_APPRV,
  PEND,
  TASK_STATUSES,
  TASK_TYPES,
  WEL_REQ_APPRV,
} from "../utils/const";
import { translate } from "../../base/actions/translate";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import { getAccessLevel, getEventType } from "../utils/util";
import TaskDetails from "./TaskDetails";
import "../styles/tasks.css";
import moment from "moment";
import { exportFile } from "../utils/export";
import { MED_REQ, MED_REQ_TYP } from "../../mastertables/utils/const";
import { ACCEX } from "../../base/utils/roles";

const TASK_LIST_EXL_COLS = [
  {
    title: "Task ID",
    dataIndex: "taskId",
    key: "taskId",
    render: (text, record) => record.taskId,
  },
  {
    title: "Category",
    dataIndex: "eventType",
    key: "eventType",
    render: (text, record) => getEventType(record.eventType),
  },
  {
    title: "Requested By",
    dataIndex: "requestedBy",
    key: "requestedBy",
    render: (text, record) => record.firstName + " " + record.lastName,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text, record) => {
      if (record.status === APRV) {
        return "Approved";
      } else if (record.status === COMP) {
        return "Completed";
      } else if (record.status === DEN) {
        return "Denied";
      } else if (record.status === CLOS) {
        return "Closed";
      } else {
        return "Pending";
      }
    },
  },
  {
    title: "Updated Date",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (text, record) =>
      moment(record.updatedAt).format("DD-MM-YYYY hh:mm A"),
  },
];

class TaskList extends React.Component {
  state = {
    taskFilter: {
      eventTypes: [],
      taskStatus: [],
      assignedLevel: true,
    },
    eventTypes: [],
    showDetails: false,
    record: {},
    statuses: TASK_STATUSES,
    assignedLevel: true,
    selectedRowKeys: [],
    status: "",
    showReasonModal: false,
  };

  componentDidMount() {
    this.props.getApprovalLevels();
    if (this.props.role) {
      this.searchTasks(this.state.taskFilter);
    }
    if (this.props.role) {
      this.setState({ eventTypes: TASK_TYPES[this.props.role] });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.role !== this.props.role) {
      this.setState({
        eventTypes: TASK_TYPES[this.props.role],
        assignedLevel: getAccessLevel(this.props.role),
      });
      this.searchTasks(this.state.taskFilter);
    }
  }

  onChange = (checked) => {
    console.log(`switch to ${checked}`);
    if (checked) {
      this.setState({ assignedLevel: getAccessLevel(this.props.role) });
    } else {
      this.setState({ assignedLevel: "" });
    }
  };

  export = (cols, list) => {
    if (!cols || cols.length <= 0 || !list || list.length <= 0) {
      return;
    }
    exportFile(cols, list, "Task List");
  };

  searchTasks = (form) => {
    if (form.assignedLevel) {
      form.assignedLevel = getAccessLevel(this.props.role);
    } else {
      form.assignedLevel = "";
    }
    this.props.searchTasks(form);
    this.setState({ taskFilter: form, selectedRowKeys: [] });
  };

  getTaskDetails = (row) => {
    this.props.getTaskDetails(row);
  };

  showDetails = (row) => {
    console.log("SHow student details : ", row);
    this.setState({
      record: row,
      showDetails: true,
    });
    this.getTaskDetails(row);
  };

  changeStatus = (taskId, status, record) => {
    this.props.changeTaskStatus(taskId, status, record, this.state.taskFilter);
  };

  changeStatusWithComment = (taskStatusForm, record) => {
    this.props.changeTaskStatusWithComment(
      taskStatusForm,
      record,
      this.state.taskFilter
    );
  };

  changeBulkTaskStatus = (form) => {
    const { selectedRowKeys, status } = this.state;
    this.props.changeTaskStatusInBulk(
      {
        taskIds: selectedRowKeys,
        status: status,
        comment: form.comment,
      },
      this.state.taskFilter
    );
    this.setState({ status: "", showReasonModal: false, selectedRowKeys: [] });
  };

  changeBulkStatus = (status) => {
    if (this.state.selectedRowKeys.length === 0) {
      notification.error({
        message: "Error",
        description: "Select atleast one record.",
      });
      return;
    }
    this.setState({ status: status, showReasonModal: true });
  };

  handleCancel = () => {
    this.setState({ status: "", showReasonModal: false });
  };

  onClose = () => {
    this.setState({ showDetails: false });
  };

  onSelectChange = (selectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  getStatusContent = (currentLevel, status, taskType) => {
    const html = [];
    let totalLevels = 0;
    for (const al of this.props.tasks.approvalLevels) {
      if (al.taskType === taskType) {
        totalLevels = al.levels;
      }
    }
    for (let i = 1; i <= totalLevels; i++) {
      if (i < currentLevel || (i < currentLevel && status === APRV)) {
        html.push(<span className="dot aprv"></span>);
      } else if (
        i === currentLevel &&
        status === COMP &&
        ![
          MED_REQ_APPRV.value,
          WEL_REQ_APPRV.value,
          MON_REQ_APPRV.value,
        ].includes(taskType)
      ) {
        html.push(<span className="dot aprv"></span>);
      } else if (taskType === MARKS_APPRV.value && status === APRV) {
        html.push(<span className="dot aprv"></span>);
      } else if (i === currentLevel && status === CLOS) {
        html.push(<span className="dot aprv"></span>);
      } else if (i === currentLevel && status === DEN) {
        html.push(<span className="dot den"></span>);
      } else {
        html.push(<span className="dot"></span>);
      }
    }
    return <div>{html}</div>;
  };

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const TASK_LIST_COLS = [
      {
        title: "Task ID",
        dataIndex: "taskId",
        key: "taskId",
        render: (text, record) => record.taskId,
        sorter: {
          compare: (a,b) => a.taskId - b.taskId
        }
      },
      {
        title: "Category",
        dataIndex: "eventType",
        key: "eventType",
        render: (text, record) => getEventType(record.eventType),
        sorter: {
          compare: (a, b) =>
            sortNames(getEventType(a.eventType), getEventType(b.eventType)),
        },
      },
      {
        title: "Requested By",
        dataIndex: "requestedBy",
        key: "requestedBy",
        render: (text, record) => record.firstName + " " + record.lastName,
        sorter: {
          compare: (a, b) => sortNames(a.firstName, b.firstName),
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) =>
          this.getStatusContent(
            record.assignedLevel,
            record.status,
            record.eventType
          ),
        sorter: {
          compare: (a, b) => {
            if (a.assignedLevel !== b.assignedLevel) {
              return a.assignedLevel - b.assignedLevel;
            } else if (
              a.assignedLevel === b.assignedLevel &&
              a.status !== b.status
            ) {
              if (a.status === APRV || a.status === DEN) {
                return 1;
              } else if (b.status === APRV || b.status === DEN) {
                return -1;
              }
            } else if (
              a.assignedLevel === b.assignedLevel &&
              a.status === b.status
            ) {
              return 0;
            } else {
              return 0;
            }
          },
        },
      },
      {
        title: "Updated Date",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (text, record) =>
          moment(record.updatedAt).format("DD-MM-YYYY hh:mm A"),
        sorter: {
          compare: (a, b) =>
            moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
        },
      },
    ];

    return (
      <div id="taskListContainer">
        <Card title="Tasks List">
          <Form
            onFinish={this.searchTasks}
            ref={this.formRef}
            initialValues={{ taskStatus: [], assignedLevel: true }}
          >
            <Row gutter={[16, 8]}>
              <Col xs={24} sm={8}>
                <Form.Item name="eventTypes" label="Task Category">
                  <Select mode="multiple">
                    {this.state.eventTypes.map((type) => (
                      <Select.Option key={type.value} value={type.value}>
                        {translate(type.label)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item name="taskStatus" label="Task Status">
                  <Select mode="multiple">
                    {this.state.statuses.map((type) => (
                      <Select.Option key={type.value} value={type.value}>
                        {translate(type.label)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Assigned" name="assignedLevel">
                  <Switch
                    className="toggle-show-hide"
                    checkedChildren="Me"
                    unCheckedChildren="All"
                    defaultChecked
                    disabled={this.props.role === ACCEX}
                    onChange={(e) => this.onChange(e)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div
              style={{
                textAlign: "right",
              }}
            >
              {this.state.selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  className="modal-form-submit-button"
                  onClick={(e) => this.changeBulkStatus(APRV)}
                >
                  Approve
                </Button>
              )}
              {this.state.selectedRowKeys.length > 0 && (
                <Button
                  className="modal-form-submit-button"
                  onClick={(e) => this.changeBulkStatus(DEN)}
                >
                  Deny
                </Button>
              )}
              <Button
                type="primary"
                htmlType="submit"
                className="modal-form-submit-button"
              >
                Search
              </Button>
              <Button
                type="primary"
                onClick={(e) =>
                  this.export(TASK_LIST_EXL_COLS, this.props.tasks.taskList)
                }
              >
                Export
              </Button>
            </div>
          </Form>
        </Card>
        <Card>
          <Spin spinning={this.props.tasks.loading} delay={500}>
            <div style={{ overflowY: "auto" }}>
              {this.state.taskFilter.taskStatus &&
              this.state.taskFilter.taskStatus.length === 1 &&
              this.state.taskFilter.taskStatus[0] === PEND &&
              this.state.taskFilter.eventTypes &&
              this.state.taskFilter.eventTypes.length === 1 &&
              "ADMIN" === this.props.role ? (
                <Table
                  className="row-selection"
                  columns={TASK_LIST_COLS}
                  rowSelection={rowSelection}
                  rowKey={(record) => record.taskId}
                  dataSource={this.props.tasks.taskList}
                  pagination={{
                    showSizeChanger: true,
                    showTotal: () => {
                      const total = this.props.tasks.taskList.length;
                      return `Total ${total}`;
                    },
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (e) => {
                        this.showDetails(record, rowIndex, e);
                      }, // click row
                    };
                  }}
                />
              ) : (
                <Table
                  className="row-selection"
                  columns={TASK_LIST_COLS}
                  dataSource={this.props.tasks.taskList}
                  pagination={{
                    showSizeChanger: true,
                    showTotal: () => {
                      const total = this.props.tasks.taskList.length;
                      return `Total ${total}`;
                    },
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (e) => {
                        this.showDetails(record, rowIndex, e);
                      }, // click row
                    };
                  }}
                />
              )}
            </div>
          </Spin>
        </Card>
        {this.state.showDetails && (
          <TaskDetails
            record={this.state.record}
            showDetails={this.state.showDetails}
            onClose={this.onClose}
            changeStatus={this.changeStatus}
            changeStatusWithComment={this.changeStatusWithComment}
            taskFilter={this.state.taskFilter}
            {...this.props}
          ></TaskDetails>
        )}
        {this.state.showReasonModal && (
          <Modal
            title="Basic Modal"
            visible={this.state.showReasonModal}
            footer={null}
            onCancel={this.handleCancel}
          >
            <Form onFinish={(e) => this.changeBulkTaskStatus(e)}>
              <Form.Item
                label="Reason"
                name="comment"
                rules={[
                  { required: true, message: "Please enter the Comment" },
                ]}
              >
                <Input.TextArea rows={2} />
              </Form.Item>
              <div className="modal-form-button-div">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="modal-form-submit-button"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Modal>
        )}
      </div>
    );
  }
}

export default TaskList;

import React from 'react';
import { Col } from 'antd';
import moment from 'moment';
import { translate } from '../../base/actions/translate';
import { STATUS_LIST } from '../utils/consts';
import '../styles/ss.css';
import SummaryCount from './SummaryCount';

class StatusSummary extends React.Component {
    componentWillMount() {
        this.loadSummary();
    }

    loadSummary = () => {
        if(!this.props.sampurnaSiksha.loading) {
            this.props.getStatusSummary(moment().year());
        }
        const that = this;
        setTimeout(function () {
            if(window.location.href.indexOf("sampurnaSiksha") > -1) {
                that.loadSummary();
            }
        }, 30000);
    }

    renderStatusChips = () => {
        const that = this;
        const statusList = [];
        for (const status of STATUS_LIST) {
            statusList.push(
                <a onClick={e => that.searchApplication(status)}>
                <Col className={["status-chip", status.toLowerCase()]} key={status} >
                    <div className="status-count">{this.getStatusCount(status)}</div>
                    <div className="status-cd">{translate(status)}</div>
                </Col>
                </a>
            );
        }

        return statusList;
    }

    getStatusCount(statusCd) {
        let count = 0;
        for (const summ of this.props.sampurnaSiksha.statusSummary) {
            if (summ.status === statusCd) {
                count = summ.count;
            }
        }
        return count;
    }

    searchApplication = (status) => {
        const form = {
            applicationNo: '',
            oldDistId: '',
            mandalId: '',
            schoolId: '',
            currentPage: 1,
            filters: {},
            sorter: {},
            // pageNumber: 0,
            // pageSize: 10,
            // sortOrder: 'asc',
            // sortField: 'applicationId',
            status: [],
            searchText: ''
        };

        if (status) {
            form.status = [status];
        }

        this.props.searchApplications(form);
        this.props.setSearchFilters(form);
    }

    render() {

        return (
            <div className="status-summary-container" >
                <SummaryCount loading={this.props.sampurnaSiksha.loading} 
                summaryList={this.props.sampurnaSiksha.statusSummary}
                statusClicked={this.searchApplication} isActionItem={true}/>

            </div>
        );
    }
}

export default StatusSummary;
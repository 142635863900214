import { connect } from "react-redux";
import { fetchOldDistricts } from "../../base/actions/AppConfigActions";
import {
  exportShortListedApplications,
  deleteDrafts,
  getMarksConfig,
} from "../actions/SSActions";
import {
  SS_SEARCH_FILTERS,
  SHOW_DETAILS,
  SET_STEP,
  ADD_TO_DEL_FILES,
  SHOW_PREVIEW_AFTER_SAVE,
  SET_APPL,
  SET_STEP_BY_KEY,
  SET_FORM_STEPS,
  SET_COMMITTEE_DATA,
  SET_COMPARE_LIST,
  TOGGLE_COMPARE,
  TOGGLE_COMPARE_REVIEW,
  SET_STATUS_IN_PREVIEW,
  SEARCH_SURVEYED_LIST,
  SET_FEEDBACK_IN_PREVIEW,
} from "../utils/consts";
import { isSurveyListContains } from "../utils/util";
import { updateImageAngle } from "../../student/actions/StudentActions";
import { RESET_MESSAGES } from "../../base/utils/const";
import {
  approveDrafts,
  complateSurvey,
  deleteFiles,
  draftSurveyImg,
  getApplicationReviewDetails,
  getSurveyedList,
  saveApplicationReview,
  updateCommitteeFeedback,
} from "../actions/ReviewActions";
import SelectionCommittee from "../components/SelectionCommittee";

const mapDispatchToProps = (dispatch, props) => {
  return {
    getOldDistricts() {
      dispatch(fetchOldDistricts());
    },

    searchApplications(form) {
      dispatch(getSurveyedList(form));
    },

    setSearchFilters(form) {
      dispatch({
        type: SS_SEARCH_FILTERS,
        payload: form,
      });
    },

    offlineSearchApplication(form, allApplications) {
      this.setSearchFilters(form);
      if (form.searchText && form.searchText.length > 1) {
        const result = allApplications.filter((appl) => {
          return isSurveyListContains(appl, form.searchText);
        });
        this.setSearchApplication(result);
      } else if (!form.searchText) {
        this.setSearchApplication(allApplications);
      }
    },

    setSearchApplication(applications) {
      dispatch({
        type: SEARCH_SURVEYED_LIST,
        payload: applications,
      });
    },

    exportToExcel(filters) {
      dispatch(exportShortListedApplications(filters));
    },

    getApplicationReviewDetails(applicationId) {
      dispatch(getApplicationReviewDetails(applicationId)).then((res) => {
        this.setStep(7);
      });
    },

    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },

    saveApplicationReview(reviewForm, step, filters) {
      dispatch(saveApplicationReview(reviewForm)).then((res) => {
        this.searchApplications(filters);
      });;
    },

    toggleDetails(flag) {
      dispatch({
        type: SHOW_DETAILS,
        payload: flag,
      });
    },

    setStep(step) {
      dispatch({
        type: SET_STEP,
        payload: step,
      });
    },

    setStepByKey(key) {
      dispatch({
        type: SET_STEP_BY_KEY,
        payload: key,
      });
    },

    setFormSteps(stepsList) {
      dispatch({
        type: SET_FORM_STEPS,
        payload: stepsList,
      });
    },

    setCommitteeData(data, key) {
      dispatch({
        type: SET_COMMITTEE_DATA,
        payload: { key, data },
      });
    },

    updateSurveyData(surveyData, surveyList, filter) {
      for (const s of surveyList) {
        s.surveyDetails = JSON.stringify(surveyData[s.surveyKey]);
      }
      this.saveApplicationReview(surveyList, 0, filter);
    },

    showPreviewAfterSave(flag) {
      dispatch({
        type: SHOW_PREVIEW_AFTER_SAVE,
        payload: flag,
      });
    },

    updateImageAngle(angle, uploadId) {
      dispatch(updateImageAngle(uploadId, angle));
    },

    draftUpload(applicationId, file, type) {
      dispatch(draftSurveyImg(applicationId, file, type));
    },

    deleteDraft(applicationId) {
      dispatch(deleteDrafts(applicationId));
    },

    deleteFiles(filesList) {
      dispatch(deleteFiles(filesList));
    },

    addToDeleteFiles(filesList) {
      dispatch({
        type: ADD_TO_DEL_FILES,
        payload: filesList,
      });
    },

    approveDrafts(applicationId, filesList) {
      dispatch(approveDrafts(applicationId, filesList));
    },

    complateSurvey(applicationId) {
      dispatch(complateSurvey(applicationId));
    },

    getMarksConfig() {
      dispatch(getMarksConfig()).then((res) => {
        this.searchApplications({});
      });
    },

    setApplicationsList(surveyedList, searchedApplications) {
      dispatch({
        type: SET_APPL,
        payload: { surveyedList, searchedApplications },
      });
    },

    setStatusInPreview(status) {
      dispatch({
        type: SET_STATUS_IN_PREVIEW,
        payload: status,
      });
    },

    setFeedbackInPreview(feedback) {
      dispatch({
        type: SET_FEEDBACK_IN_PREVIEW,
        payload: feedback,
      });
    },

    updateCommitteeFeedback(feedbackList) {
      dispatch(updateCommitteeFeedback(feedbackList));
    },

    setCompareList(list) {
      dispatch({
        type: SET_COMPARE_LIST,
        payload: list,
      });
    },

    toggleCompare(flag) {
      dispatch({
        type: TOGGLE_COMPARE,
        payload: flag,
      });
    },

    toggleCompareReview(flag) {
      dispatch({
        type: TOGGLE_COMPARE_REVIEW,
        payload: flag,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    SSReducer,
    AppConfigReducer,
    StudentReducer,
    ReviewReducer,
  } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    sampurnaSiksha: SSReducer,
    appConfig: AppConfigReducer,
    oldDistrictsList: AppConfigReducer.oldDistrictsList,
    student: StudentReducer,
    review: ReviewReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectionCommittee);

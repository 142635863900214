import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select } from "antd";
import ReviewStep from "./ReviewStep";
import {
  formItemLayout,
  HOUSE_OWN,
  HOUSE_TYPES,
  OWNERSHIP_TYPE,
} from "../../utils/consts";
import { prepareReviewersFieldData } from "../../utils/util";
import { SELCOMT } from "../../../base/utils/roles";

const { Option } = Select;

class HouseForm extends React.Component {
  onFinish = (form) => {
    // console.log("Received values of form:", form);
    this.props.saveDetails(HOUSE_OWN, form);
  };
  render() {
    let reviewData = this.props.review.reviewFormData[HOUSE_OWN];
    if (!reviewData) {
      reviewData = {};
    }
    const isSelComt = this.props.isSelCommit;
    const fieldData = prepareReviewersFieldData(reviewData);
    return (
      <div id="house-form" className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Houses Owned"
          onFinish={(e) => this.onFinish(e)}
          {...this.props}
        >
          <Form.List name="housesList">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, idx) => (
                    <Row key={idx} gutter={[8, 16]}>
                      <Col sm={1}>
                        <span>{idx + 1}</span>
                      </Col>
                      <Col sm={22}>
                        <Row key={idx + "_i"} gutter={[16, 8]}>
                          <Col sm={8}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "area"]}
                              fieldKey={[field.fieldKey, "area"]}
                              label="Area"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^[1-9][0-9]*$/,
                                  message: "Please enter house area",
                                },
                              ]}
                            >
                              <Input maxLength="5" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          <Col sm={8}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "uom"]}
                              fieldKey={[field.fieldKey, "uom"]}
                              label="UOM"
                              rules={[
                                {
                                  required: true,
                                  message: "Select Units of Measurement",
                                },
                              ]}
                            >
                              <Select placeholder="Select" disabled={isSelComt}>
                                <Option key="Acres" value="Acres">
                                  Acres
                                </Option>
                                <Option key="SqYd." value="SqYd.">
                                  SqYd.
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col sm={8}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "constructedArea"]}
                              fieldKey={[field.fieldKey, "constructedArea"]}
                              label="Constructed Area"
                              rules={[
                                {
                                  required: true,
                                  pattern: /^\S[A-Za-z0-9\s]+$/,
                                  message:
                                    "Please enter constructed area details",
                                },
                              ]}
                            >
                              <Input maxLength="15" disabled={isSelComt} />
                            </Form.Item>
                          </Col>
                          <Col sm={8}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "houseType"]}
                              fieldKey={[field.fieldKey, "houseType"]}
                              label="Type of House"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select type of house",
                                },
                              ]}
                            >
                              <Select placeholder="Select" disabled={isSelComt} className="house-types">
                                {HOUSE_TYPES.map((i) => (
                                  <Option key={i} value={i}>
                                    {i}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col sm={8}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "ownership"]}
                              fieldKey={[field.fieldKey, "ownership"]}
                              label="Ownership"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select house ownership",
                                },
                              ]}
                            >
                              <Select placeholder="Select" disabled={isSelComt}>
                                {OWNERSHIP_TYPE.map((i) => (
                                  <Option key={i} value={i}>
                                    {i}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues.housesList[idx]?.ownership !==
                              currentValues.housesList[idx]?.ownership
                            }
                          >
                            {({ getFieldValue }) => {
                              const ownerType = getFieldValue([
                                "housesList",
                                field.name,
                                "ownership",
                              ]);
                              return ownerType !== "Rented" ? (
                                <Col sm={8}>
                                  <Form.Item
                                    {...formItemLayout}
                                    name={[field.name, "propertyValue"]}
                                    fieldKey={[field.fieldKey, "propertyValue"]}
                                    label="Asset Value"
                                    rules={[
                                      {
                                        required: true,
                                        pattern: /^[1-9][0-9]*$/,
                                        message: "Please enter asset value",
                                      },
                                    ]}
                                  >
                                    <Input
                                      maxLength="10"
                                      disabled={isSelComt}
                                    />
                                  </Form.Item>
                                </Col>
                              ) : null;
                            }}
                          </Form.Item>

                          {isSelComt && (
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) =>
                                prevValues.housesList[idx]?.ownership !==
                                currentValues.housesList[idx]?.ownership
                              }
                            >
                              {({ getFieldValue }) => {
                                const ownerType = getFieldValue([
                                  "housesList",
                                  field.name,
                                  "ownership",
                                ]);
                                return ownerType !== "Rented" ? (
                                  <Col sm={8}>
                                    <Form.Item
                                      {...formItemLayout}
                                      name={[field.name, "newPropertyValue"]}
                                      fieldKey={[
                                        field.fieldKey,
                                        "newPropertyValue",
                                      ]}
                                      label="New Asset Value"
                                    >
                                      <Input maxLength="8" />
                                    </Form.Item>
                                  </Col>
                                ) : null;
                              }}
                            </Form.Item>
                          )}

                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues.housesList[idx]?.ownership !==
                              currentValues.housesList[idx]?.ownership
                            }
                          >
                            {({ getFieldValue }) => {
                              const ownerType = getFieldValue([
                                "housesList",
                                field.name,
                                "ownership",
                              ]);
                              return ownerType !== "Let Out" ? (
                                <Col sm={8}>
                                  <Form.Item
                                    {...formItemLayout}
                                    name={[field.name, "numberOfRooms"]}
                                    fieldKey={[field.fieldKey, "numberOfRooms"]}
                                    label="# of Rooms"
                                    rules={[
                                      {
                                        required: true,
                                        pattern: /^[1-9][0-9]*$/,
                                        message: "Please enter no. of rooms",
                                      },
                                    ]}
                                  >
                                    <Input maxLength="2" disabled={isSelComt} />
                                  </Form.Item>
                                </Col>
                              ) : null;
                            }}
                          </Form.Item>

                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues.housesList[idx]?.ownership !==
                              currentValues.housesList[idx]?.ownership
                            }
                          >
                            {({ getFieldValue }) => {
                              const ownerType = getFieldValue([
                                "housesList",
                                field.name,
                                "ownership",
                              ]);
                              return ownerType !== "Let Out" ? (
                                <Col sm={8}>
                                  <Form.Item
                                    {...formItemLayout}
                                    name={[field.name, "numberOfBedRooms"]}
                                    fieldKey={[
                                      field.fieldKey,
                                      "numberOfBedRooms",
                                    ]}
                                    label="# of Bed Rooms"
                                    rules={[
                                      {
                                        required: true,
                                        pattern: /^[1-9][0-9]*$/,
                                        message: "Please enter no. of bedrooms",
                                      },
                                    ]}
                                  >
                                    <Input maxLength="2" disabled={isSelComt} />
                                  </Form.Item>
                                </Col>
                              ) : null;
                            }}
                          </Form.Item>

                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues.housesList[idx]?.ownership !==
                              currentValues.housesList[idx]?.ownership
                            }
                          >
                            {({ getFieldValue }) => {
                              const ownerType = getFieldValue([
                                "housesList",
                                field.name,
                                "ownership",
                              ]);
                              return ownerType !== "Own" ? (
                                <Col sm={8}>
                                  <Form.Item
                                    {...formItemLayout}
                                    name={[field.name, "monthlyRent"]}
                                    fieldKey={[field.fieldKey, "monthlyRent"]}
                                    label="Monthly Rent"
                                    rules={[
                                      {
                                        required: true,
                                        pattern: /^[0-9]{1,6}$/,
                                        message: "Please enter monthly rent",
                                      },
                                    ]}
                                  >
                                    <Input disabled={isSelComt} />
                                  </Form.Item>
                                </Col>
                              ) : null;
                            }}
                          </Form.Item>

                          {isSelComt && (
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) =>
                                prevValues.housesList[idx]?.ownership !==
                                currentValues.housesList[idx]?.ownership
                              }
                            >
                              {({ getFieldValue }) => {
                                const ownerType = getFieldValue([
                                  "housesList",
                                  field.name,
                                  "ownership",
                                ]);
                                return ownerType !== "Own" ? (
                                  <Col sm={8}>
                                    <Form.Item
                                      {...formItemLayout}
                                      name={[field.name, "newMonthlyRent"]}
                                      fieldKey={[
                                        field.fieldKey,
                                        "newMonthlyRent",
                                      ]}
                                      label="New Monthly Rent"
                                      rules={[
                                        {
                                          pattern: /^[0-9]{1,6}$/,
                                          message: "Please enter monthly rent",
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                ) : null;
                              }}
                            </Form.Item>
                          )}

                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues.housesList[idx]?.ownership !==
                              currentValues.housesList[idx]?.ownership
                            }
                          >
                            {({ getFieldValue }) => {
                              const ownerType = getFieldValue([
                                "housesList",
                                field.name,
                                "ownership",
                              ]);
                              return ownerType !== "Let Out" ? (
                                <Col sm={8}>
                                  <Form.Item
                                    {...formItemLayout}
                                    name={[field.name, "houseDescription"]}
                                    fieldKey={[
                                      field.fieldKey,
                                      "houseDescription",
                                    ]}
                                    label="Describe House"
                                    rules={[
                                      {
                                        required: true,
                                        pattern: /^\S[A-Za-z0-9\s]+$/,
                                        message: "Please describe house.",
                                      },
                                    ]}
                                  >
                                    <Input.TextArea
                                      maxLength="100"
                                      disabled={isSelComt}
                                    />
                                  </Form.Item>
                                </Col>
                              ) : null;
                            }}
                          </Form.Item>

                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues.housesList[idx]?.ownership !==
                              currentValues.housesList[idx]?.ownership
                            }
                          >
                            {({ getFieldValue }) => {
                              const ownerType = getFieldValue([
                                "housesList",
                                field.name,
                                "ownership",
                              ]);
                              return ownerType !== "Let Out" ? (
                                <Col sm={8}>
                                  <Form.Item
                                    {...formItemLayout}
                                    name={[field.name, "neighbourDescription"]}
                                    fieldKey={[
                                      field.fieldKey,
                                      "neighbourDescription",
                                    ]}
                                    label="Describe Neighbourhood"
                                    rules={[
                                      {
                                        required: true,
                                        pattern: /^\S[A-Za-z0-9\s]+$/,
                                        message:
                                          "Please describe neighbourhood.",
                                      },
                                    ]}
                                  >
                                    <Input.TextArea
                                      maxLength="100"
                                      disabled={isSelComt}
                                    />
                                  </Form.Item>
                                </Col>
                              ) : null;
                            }}
                          </Form.Item>
                          <Col sm={8}>
                            <Form.Item
                              {...formItemLayout}
                              name={[field.name, "remarks"]}
                              fieldKey={[field.fieldKey, "remarks"]}
                              label="Remarks"
                            >
                              <Input maxLength="100" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={1} style={{ textAlign: "center" }}>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add Houses
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </ReviewStep>
      </div>
    );
  }
}

export default HouseForm;

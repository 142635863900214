import { RESET_MESSAGES } from "../../base/utils/const";
import {
  GET_MSG,
  INIT_LOAD,
  LOAD_MORE,
  LOAD_MORE_MSGS,
  MSG_MAX_LIMIT,
  SAVE_MSG,
} from "../utils/const";

const initialState = {
  messages: [],
  loading: false,
  successMessage: "",
  errorMessage: "",
  loadType: INIT_LOAD,
  hasMore: false
};

const InteractionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOAD_MORE_MSGS}_PENDING`:
    case `${GET_MSG}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${GET_MSG}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        messages: action.payload.sort(
          (a, b) => a.interactionId - b.interactionId
        ),
        loadType: INIT_LOAD,
        hasMore: MSG_MAX_LIMIT === action.payload?.length
      };
    }

    case `${LOAD_MORE_MSGS}_REJECTED`:
    case `${GET_MSG}_REJECTED`: {
      return {
        ...state,
        loading: true,
        errorMessage: action.payload.data,
      };
    }

    case `${LOAD_MORE_MSGS}_FULFILLED`: {
      const newMsgs = action.payload.sort(
        (a, b) => a.interactionId - b.interactionId
      );
      const totalMsgs = newMsgs.concat(state.messages);
      return {
        ...state,
        loading: false,
        messages: totalMsgs,
        loadType: LOAD_MORE,
        hasMore: MSG_MAX_LIMIT === action.payload?.length
      };
    }

    case `${SAVE_MSG}_PENDING`: {
      return {
        ...state,
        loading: true,
        successMessage: "",
        errorMessage: "",
      };
    }

    case `${SAVE_MSG}_FULFILLED`: {
      return {
        ...state,
        loading: false,
      };
    }

    case `${SAVE_MSG}_REJECTED`: {
      return {
        ...state,
        loading: true,
        errorMessage: action.payload.data,
      };
    }

    case RESET_MESSAGES: {
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    }

    default:
      return state;
  }
};

export default InteractionsReducer;

import {
  Button,
  Card,
  Divider,
  Spin,
  Form,
  Row,
  Col,
  Upload,
  message,
  List,
} from "antd";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../Interceptor";
import Item from "antd/lib/list/Item";

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const DataMigration = (props) => {
  const [loading, setLoading] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const normFile = (e) => {
    // console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList[0] && !isExcel(e.fileList[0])) {
      return null;
    }
    return e && e.fileList[0];
  };

  const isExcel = (file) => {
    const ext = file.name.split(".").pop();
    if (ext !== "xls" && ext !== "xlsx") {
      return false;
    }
    return true;
  };
  const uploadProps = {
    beforeUpload: (file) => {
      if (!isExcel(file)) {
        message.error(`${file.name} is not a excel file`);
        return false;
      }
      setFilesList([file]);
      return false;
    },
    filesList,
    maxCount: 1,
  };

  const uploadFile = (form) => {
    console.log(form);
    if (!filesList || filesList.length === 0) {
      return;
    }
    const formData = new FormData();
    formData.append("file", filesList[0]);
    setLoading(true);
    axiosInstance
      .post("/datamigrate/profiles", formData, config)
      .then((res) => {
        console.log(res);
        message.success("Migration successful.");
      })
      .catch((error) => {
        console.log(error);
        if (error.data.message.length > 0) {
          message.error("Migration failed: \n" + error.data.message,6);
        } else {
          message.error(
            "Error in migrating data. Please check the excel data and upload again."
          );
        }
      })
      .finally((e) => {
        setFilesList([]);
        setLoading(false);
      });
  };

  const uploadFile_new = (form) => {
    console.log(form);
    if (!filesList || filesList.length === 0) {
      return;
    }
    const formData = new FormData();
    formData.append("file", filesList[0]);
    setLoading(true);
    axiosInstance
      .post("/datamigrate/profiles_new", formData,  { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(res);
        // window.open(url, '_blank');
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = "Result";
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally((e) => {
        setFilesList([]);
        setLoading(false);
      });
  };

  const downloadSample = () => {
    setLoading(true);
    axiosInstance
      .get("/datamigrate/downloadSample",  { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(res);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = "Sample";
        a.click();
        window.URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      
  };

  return (
    <React.Fragment>
      <Card title="Data Migration">
        <Spin spinning={loading}>
        <Divider orientation="left">
              <h4>Student Profiles Excel Upload</h4>
            </Divider>
          <div id="ssc-marks-container">
            <Form name="marksSheetData" onFinish={uploadFile_new}>
              <Row>
                <Col>
                  <Form.Item
                    name="excelSheet"
                    valuePropName="file"
                    getValueFromEvent={normFile}
                    label="Excel Sheet"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please upload .xlsx file only.",
                      },
                    ]}
                  >
                    <Upload {...uploadProps}>
                      <Button>
                        <UploadOutlined /> Select Excel File (.xlsx) only
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
             
              <a href="#"   onClick={(e) => downloadSample()}> Download Sample Template
            
          </a>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: "right" }}
              >
                Upload
              </Button>
            </Form>
          </div>
        </Spin>
        <div hidden>
          <Divider orientation="left">
            <h4>Instructions for uploading</h4>
          </Divider>
          <List className="notesCss">
            <Item>
              <h4>1. Upload .xslx file only.</h4>
            </Item>
            <Item>
              <h4>
                2. Ensure all the sheets exists with relevent sheet names.
              </h4>
            </Item>
            <Item>
              <h4>3. Ensure that there are no extra spaces at begining/between/ending of words.</h4>
            </Item>
            <Item>
              <h4>4. Entered Institute/Course/Hostel/Incharge names should match with database master data.</h4>
            </Item>
          </List>
        </div>
      </Card>
     
    </React.Fragment>
  );
};

export default DataMigration;

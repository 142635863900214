import { connect } from "react-redux";
import { getNotifications } from "../../common/actions/NotificationActions";
import StudAcctDashBoard from "../components/StudAcctDashBoard";

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications() {
      dispatch(getNotifications());
    }
  };
};

const mapStateToProps = (state) => {
  const { AuthReducer, NotificationReducer } = state;
  return {
    isLoading: AuthReducer.isLoading,
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    notif: NotificationReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudAcctDashBoard);

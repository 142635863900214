import { axiosInstance } from "../../Interceptor";
import { AVAILABLE_BATCHES, CREATE_BULK_REQ, DEL_MED_REQ, DEL_MON_REQ, DEL_STUD_HSTL, DEL_STUD_HSTL_CMNTS, DEL_WEL_REQ, GET_EXP, GET_INCHARGE, GET_MED_REQ, GET_MON_REQ, GET_STUD_HSTL_CMNTS, GET_WEL_REQ, SAVE_MED_REQ, SAVE_MON_REQ, SAVE_STUD_ACAD, SAVE_STUD_HSTL, SAVE_STUD_HSTL_CMNTS, SAVE_STUD_PROFILE, SAVE_WEL_REQ, SEARCH_STUDENT_PROF, SET_INCHARGE, STUD_ACAD, STUD_HSTL, STUD_PROFILE, STUD_STATUS } from "../utils/const";

export const searchStudentProfiles = (form) => {
  return {
    type: SEARCH_STUDENT_PROF,
    payload: axiosInstance.post("/stud/manage/profile/search", form),
  };
};

export const fetchAllAvailableBatchList = () => {
  return {
    type: AVAILABLE_BATCHES,
    payload: axiosInstance.get("/stud/manage/availablebatchs"),
  };
};

export const fetchStudentProfileById = (id) => {
  return {
    type: STUD_PROFILE,
    payload: axiosInstance.get(`/stud/manage/profile/${id}`),
  };
};

export const fetchStudentAcadById = (id) => {
  return {
    type: STUD_ACAD,
    payload: axiosInstance.get(`/stud/manage/academic/${id}`),
  };
};

export const fetchStudentHostelById = (id) => {
  return {
    type: STUD_HSTL,
    payload: axiosInstance.get(`/stud/manage/hostel/${id}`),
  };
};

export const updateStudentStatus = (studentStatusInfo) => {
  return {
    type: STUD_STATUS,
    payload: axiosInstance.post(`/stud/manage/profile/status`, studentStatusInfo),
  }
}

export const saveStudentProfile = (form) => {
  return {
    type: SAVE_STUD_PROFILE,
    payload: axiosInstance.post(`/stud/manage/profile`, form),
  };
};

export const saveStudentAcademics = (form) => {
  return {
    type: SAVE_STUD_ACAD,
    payload: axiosInstance.post(`/stud/manage/stud-academics`, form),
  };
};

export const deleteStudentAcademics = (id) => {
  return {
    type: SAVE_STUD_ACAD,
    payload: axiosInstance.delete(`/stud/manage/stud-academics/${id}`),
  };
};

export const saveStudentMarks = (marks) => {
  return {
    type: SAVE_STUD_ACAD,
    payload: axiosInstance.post(`/stud/manage/stud-marks`, marks),
  };
};

export const deleteStudentMarks = (semId, attempt) => {
  return {
    type: SAVE_STUD_ACAD,
    payload: axiosInstance.delete(`/stud/manage/stud-marks/${semId}/${attempt}`),
  };
};

// Hostel related api calls
export const saveStudHostelDetails = (form) => {
  return {
    type: SAVE_STUD_HSTL,
    payload: axiosInstance.post(`/stud/manage/hostel`, form),
  };
}

export const deleteStudHostelDetails = (studHostelId) => {
  return {
    type: DEL_STUD_HSTL,
    payload: axiosInstance.delete(`/stud/manage/hostel/${studHostelId}`),
  };
}

export const getStudHostelComments = (studHostelId) => {
  return {
    type: GET_STUD_HSTL_CMNTS,
    payload: axiosInstance.get(`/stud/manage/hostel/comments/${studHostelId}`),
  };
}

export const saveStudHostelComments = (form) => {
  return {
    type: SAVE_STUD_HSTL_CMNTS,
    payload: axiosInstance.post(`/stud/manage/hostel/comments`, form),
  };
}

export const deleteStudHostelComments = (studHostelCmntId) => {
  return {
    type: DEL_STUD_HSTL_CMNTS,
    payload: axiosInstance.delete(`/stud/manage/hostel/comments/${studHostelCmntId}`),
  };
}

export const setIncharge = (profileId,inchargeId) => {
  return {
    type: SET_INCHARGE,
    payload: axiosInstance.get(`/stud/manage/set-incharge/${profileId}/${inchargeId}`),
  };
}

export const getIncharge = (profileId) => {
  return {
    type: GET_INCHARGE,
    payload: axiosInstance.get(`/stud/manage/incharge/${profileId}`),
  };
}

export const getMedicalRequests = (profileId) => {
  return {
    type: GET_MED_REQ,
    payload: axiosInstance.get(`/stud/manage/medical/${profileId}`),
  };
}

export const saveMedicalRequest = (form) => {
  return {
    type: SAVE_MED_REQ,
    payload: axiosInstance.post(`/stud/manage/medical`, form),
  };
}

export const deleteMedRequest = (medReqId) => {
  return {
    type: DEL_MED_REQ,
    payload: axiosInstance.delete(`/stud/manage/medical/${medReqId}`),
  };
}

export const getWelfareRequests = (profileId) => {
  return {
    type: GET_WEL_REQ,
    payload: axiosInstance.get(`/stud/manage/welfare/${profileId}`),
  };
}

export const saveWelfareRequest = (form) => {
  return {
    type: SAVE_WEL_REQ,
    payload: axiosInstance.post(`/stud/manage/welfare`, form),
  };
}

export const deleteWelfareRequest = (welfReqId) => {
  return {
    type: DEL_WEL_REQ,
    payload: axiosInstance.delete(`/stud/manage/welfare/${welfReqId}`),
  };
}
  
export const getMonitoryRequests = (profileId) => {
  return {
    type: GET_MON_REQ,
    payload: axiosInstance.get(`/stud/manage/monetary/${profileId}`),
  };
}

export const saveMonitoryRequest = (form) => {
  return {
    type: SAVE_MON_REQ,
    payload: axiosInstance.post(`/stud/manage/monetary`, form),
  };
}

export const deleteMonRequests = (monReqId) => {
  return {
    type: DEL_MON_REQ,
    payload: axiosInstance.delete(`/stud/manage/monetary/${monReqId}`),
  };
}

export const getStudentExpenses = (profileId) => {
  return {
    type: GET_EXP,
    payload: axiosInstance.get(`/stud/manage/monetary/expenses/${profileId}`),
  };
}

export const createBulkRequest = (reqData) => {
  return {
    type: CREATE_BULK_REQ,
    payload: axiosInstance.post(`/stud/manage/bulk/create`, reqData),
  };
}
import { LOGIN, REGISTER, LANG_CHANGE, FETCH_PROFILE, LOGOUT, TOGGLE_LOGIN_MODAL, TOGGLE_FORGOT_PWD, FORGOT_PASSWORD, RESET_MESSAGES } from "../utils/const";

const initialState = {
    isLoggedIn: false,
    role: '',
    isLoading: false,
    userContext: {},
    isSuccess: true,
    errorMessage: '',
    successMessage: '',
    lang: 'en',
    activeTab: 'login',
    showLogin: false,
    showForgot: false,
    profile: {}
};
const AuthReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOGIN: {
            return {
                ...state,
                isLoggedIn: action.payload,
                errorMessage: ''
            };
        }

        case REGISTER: 
            return {
                ...state,
                activeTab: action.payload,
                errorMessage: ''
            };

        case LOGOUT: {
            return {
                userContext: '',
                isLoggedIn: false,
                profile: {},
                role: ''
            }
        }

        case `${LOGIN}_PENDING`: {
            return {
                ...state,
                isLoading: true
            };
        }

        case `${LOGIN}_REJECTED`: {
            return {
                ...state,
                isLoading: false,
                successMessage: '',
                errorMessage: "The username or password is incorrect."
            };
        }

        case `${LOGIN}_FULFILLED`: {
            sessionStorage.setItem('x-auth-token',action.payload.accessToken);
            sessionStorage.setItem('uc', JSON.stringify(action.payload));
            return {
                ...state,
                userContext: action.payload,
                isLoading: false,
                isLoggedIn: true,
                showLogin: false,
            };
        }

        case `${REGISTER}_PENDING`: {
            return {
                ...state,
                isLoading: true
            };
        }

        case `${REGISTER}_REJECTED`: {
            return {
                ...state,
                isLoading: false,
                successMessage: '',
                errorMessage: action.payload.data.message
            };
        }

        case `${REGISTER}_FULFILLED`: {
            return {
                ...state,
                successMessage: 'Password has been sent to your registered mobile number.',
                errorMessage: '',
                isLoading: false,
                activeTab: 'login'
            };
        }

        case LANG_CHANGE:
            return {
                ...state,
                lang: action.payload
            };

        case `${FETCH_PROFILE}_PENDING`: 
            return {
                ...state,
                isLoading: true
            };

        case `${FETCH_PROFILE}_FULFILLED`: 
            const role = action.payload.authorities[0].authority;
            return {
                ...state,
                isLoading: false,
                profile: action.payload,
                role: role
            };

        case `${FETCH_PROFILE}_REJECTED`: 
            return {
                ...state,
                isLoading: false,
                successMessage: '',
                errorMessage: action.payload.message
            };
        case TOGGLE_LOGIN_MODAL: 
            return {
                ...state,
                showLogin: action.payload.showLogin,
                errorMessage: '',
                successMessage: ''
            };
        case TOGGLE_FORGOT_PWD: 
            return {
                ...state,
                showLogin: !action.payload.showFgtPwd,
                showForgot: action.payload.showFgtPwd,
                errorMessage: '',
                successMessage: '',
            }
        case `${FORGOT_PASSWORD}_PENDING`:{
            return {
                ...state,
                successMessage: '',
                isLoading: true,
                errorMessage: '',
            };
        }

        case `${FORGOT_PASSWORD}_REJECTED`:{
            return {
                ...state,
                successMessage: '',
                showForgot: false,
                showLogin: true,
                errorMessage: "Mobile number doesn't match our records. Please try again.",
                isLoading: false,
            };
        }

        case `${FORGOT_PASSWORD}_FULFILLED`:{
            return {
                ...state,
                successMessage: 'Password has been sent to your registered mobile number',
                showForgot: false,
                showLogin: true,
                errorMessage: '',
                isLoading: false,
            };
        }
        case RESET_MESSAGES: {
            return {
                ...state,
                successMessage: '',
                errorMessage: ''
            };
        }

        default: 
            return state;
    }
};

export default AuthReducer;
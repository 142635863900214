import React from "react";
import "../styles/addSchoolComponent.css";
import {
  Form,
  Input,
  Select,
  Spin,
  Button,
  Col,
  notification,
  Card,
} from "antd";
import { translate } from "../../base/actions/translate";
import { Redirect } from "react-router";
import { getLoggedInUser } from "../../service/SessionStorageService";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const { Option } = Select;
class AddSchoolComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: getLoggedInUser(),
    };
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.props.getDistricts();
    this.formRef = React.createRef();
  }

  populateMandals = (value) => {
    this.props.getMandalsList(value);
    this.formRef.current.setFieldsValue({
      mandal: "",
      schoolName: "",
    });
  };

  showSuccessMessage = () => {
    notification.success({
      message: "Your request to add school is successfully submitted. ",
      description: "",
    });
  };

  submitForm = (form) => {
    // console.log("---------", form);
    this.props.requestAddSchool(form);
  };

  toCapital = (e) => {
    if (e.target.value) {
      return e.target.value.toUpperCase();
    }
    return e.target.value;
  };

  render() {
    if (!this.props.profile.username) {
      return <Redirect to="/home" />;
    }
    const username = this.props.profile.username;
    return (
      <div className="newSchoolForm">
        <Card>
          <Spin spinning={this.props.studentData.loading} delay={500}>
            {!this.props.studentData.workflow && (
              <div>
                <div className="add-school-heading">
                  <span>{translate("AddSchoolHeading")}</span>
                </div>
                <Form
                  {...layout}
                  name="add-school"
                  // onFieldsChange={this.props.handleApplicationFormFiels}
                  // onValuesChange={this.props.handleApplicationFormFiels}
                  onFinish={this.submitForm}
                  ref={this.formRef}
                >
                  <Col xs={24} sm={12} className="pd-l">
                    <Form.Item
                      name="district"
                      label={translate("district")}
                      rules={[
                        {
                          required: true,
                          message: translate("schoolDistrictError"),
                        },
                      ]}
                    >
                      <Select className="" onChange={this.populateMandals}>
                        {this.props.districtsList.map((district) => (
                          <Option key={district.id}>
                            {district.configVal}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="pd-l">
                    <Form.Item
                      name="mandal"
                      label={translate("mandal")}
                      rules={[
                        {
                          required: true,
                          message: translate("schoolMandalError"),
                        },
                      ]}
                    >
                      <Select className="" onChange={this.populateSchools}>
                        {this.props.mandalsList.map((mandal) => (
                          <Option key={mandal.id}>{mandal.configVal}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="pd-l">
                    <Form.Item
                      name="schoolName"
                      label={translate("school")}
                      getValueFromEvent={this.toCapital}
                      rules={[
                        {
                          required: true,
                          message: translate("schoolNameError"),
                        },
                      ]}
                    >
                      <Input maxLength="100"/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="pd-l">
                    <Form.Item
                      name="headmasterName"
                      label={translate("headmasterName")}
                      getValueFromEvent={this.toCapital}
                      rules={[
                        {
                          required: true,
                          message: translate("headmasterNameError"),
                        },
                        {
                          pattern: /^[A-Za-z\s]+$/,
                          message: translate("headmasterNameError"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="headmasterPhone"
                      label={translate("headmasterPhone")}
                      rules={[
                        {
                          required: true,
                          message: translate("headmasterPhoneNoError"),
                        },
                        {
                          pattern: /^\d{10}$/,
                          message: translate("headmasterPhoneNoError"),
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || username !== value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(translate("altNumHeadMNum"));
                          },
                        }),
                      ]}
                    >
                      <Input className="" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="addSchoolSubmit"
                      disabled={this.props.studentData.loading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Form>
              </div>
            )}
            {this.props.studentData.workflow && (
              <div>
                {this.showSuccessMessage()}
                <Redirect to="/home" />
                Your request to add School is successfully submitted.
              </div>
            )}
          </Spin>
        </Card>
      </div>
    );
  }
}

export default AddSchoolComponent;

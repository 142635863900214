import React from "react";
//import LogoWithoutText from './assets/MCT-logo-without-text';

import { Drawer, Layout, Menu } from "antd";
import {
  FormOutlined,
  HomeOutlined,
  UserOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import "../styles/base.css";
import { DSTIN, SELCOMT } from "../utils/roles";

const { Sider } = Layout;
const { SubMenu } = Menu;

class StudAcctNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
    this.navigate = this.navigate.bind(this);
  }

  navigate(item) {
    this.props.toggleMainMenu();
    if (item.key === "home") {
      this.props.history.push("/home");
    } else if (item.key === "editProfile") {
      this.props.history.push("/editProfile");
    } else if (item.key === "ssProcess") {
      this.props.history.push("/sampurnaSiksha/application-processing");
    } else if (item.key) {
      this.props.history.push(`/${item.key}`);
    }
  }

  render() {
    if (!this.props.isLoggedIn) {
      return "";
    }
    return (
      <div>
        {this.props.isLoggedIn && (
          <Drawer
            id="navbar"
            className="nav-bar"
            placement="left"
            closable={false}
            visible={this.props.expandMenu}
            onClose={this.props.toggleMainMenu}
          >
            <div className="logo sidebar-top">{/* <LogoWithoutText/> */}</div>
            <Menu
              theme="dark"
              defaultSelectedKeys={["1"]}
              mode="inline"
              onClick={this.props.performMenuActions}
            >
              {/* <Menu.Item key="1">
                  <img className="menu-item" src='/images/MSS_Web_logo.png' alt="mss"></img>
                    <span>Medha Sampoorna Siksha</span>
                  </Menu.Item> */}
              <Menu.Item key="home" onClick={this.navigate}>
                <HomeOutlined />
                <span>Home</span>
              </Menu.Item>
              
              {/* <Menu.Item key="smm" onClick={this.navigate}><FormOutlined /> <span>Sampurna Siksha</span></Menu.Item> */}
              <Menu.Item key="editProfile" onClick={this.navigate}>
                <UserOutlined />
                <span>Edit Profile</span>
              </Menu.Item>
              <Menu.Item key="logout" onClick={this.props.logout}>
                <PoweroffOutlined />
                <span>Logout</span>
              </Menu.Item>
            </Menu>
          </Drawer>
        )}
      </div>
    );
  }
}

export default StudAcctNav;

import { Col, Form, Input, Row } from "antd";
import React from "react";
import { formItemLayout, INC_SUM } from "../../utils/consts";
import { prepareReviewersFieldData } from "../../utils/util";
import ReviewStep from "./ReviewStep";

class PropertiesOwned extends React.Component {
  onFinish = (form) => {
    // console.log("Received values of form:", form);
    this.props.saveDetails(INC_SUM, form);
  };

  render() {
    let reviewData = this.props.review.reviewFormData[INC_SUM];
    if (!reviewData) {
      reviewData = {};
    }
    const fieldData = prepareReviewersFieldData(reviewData);
    const isSelComt = this.props.isSelCommit;
    return (
      <div className="reviewer-form">
        <ReviewStep
          fieldData={fieldData}
          title="Income Summary"
          onFinish={(e) => this.onFinish(e)}
          {...this.props}
        >
          <Row gutter={[16, 8]}>
            <Col sm={12}>
              <Form.Item
                {...formItemLayout}
                name="numberOfPeople"
                label="Total number of people at home"
                rules={[
                  {
                    required: true,
                    pattern: /^([1-9]|1[0-5])$/,
                    message: "Please enter total number of people at home.",
                  },
                ]}
              >
                <Input maxLength="2" disabled={isSelComt} />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                {...formItemLayout}
                name="numberOfDependents"
                label="Number of dependents in the family"
                rules={[
                  {
                    required: true,
                    pattern: /^([1-9]|1[0-5])$/,
                    message: "Please enter number of dependents in the family.",
                  },
                ]}
              >
                <Input maxLength="2" disabled={isSelComt} />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                {...formItemLayout}
                name="totalAnnualIncome"
                label="Total Family Annual Income"
                rules={[
                  {
                    pattern: /^[0-9][0-9]*$/,//#627
                    message: "Please enter valid Total Family Annual Income",
                  },
                ]}
              >
                <Input maxLength="8" disabled={true} />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                {...formItemLayout}
                name="totalPropertyValue"
                label="Total Properties Value"
              >
                <Input maxLength="8" disabled={true} />
              </Form.Item>
            </Col>
            {isSelComt && (
              <Col sm={12}>
                <Form.Item
                  {...formItemLayout}
                  name="newTotalAnnualIncome"
                  label="New Total Family Annual Income"
                >
                  <Input maxLength="8" disabled={true} />
                </Form.Item>
              </Col>
            )}
            {isSelComt && (
              <Col sm={12}>
                <Form.Item
                  {...formItemLayout}
                  name="newTotalPropertyValue"
                  label="New Total Assets Value"
                >
                  <Input maxLength="8" disabled={true} />
                </Form.Item>
              </Col>
            )}
          </Row>
        </ReviewStep>
      </div>
    );
  }
}

export default PropertiesOwned;
